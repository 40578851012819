import { from, of, switchMap, take } from 'rxjs';
import { Actions, ofType } from '@ngrx/effects';
import { UserFacade } from '@kiq/client/data-access/user';
import { Auth, authState, getAuth } from '@angular/fire/auth';
import { AppAuthenticationService, AuthenticationService } from 'authentication';

export function initializeApplication(actions: Actions, facade: UserFacade, auth: Auth, authenticatioService: AuthenticationService | AppAuthenticationService) {
  return () => {
    return new Promise((resolve, reject) => {
      authenticatioService.token$
        .pipe(
          switchMap((token) => {
            if (!token) {
              return of(null);
            }
            facade.getBackendUser();
            return actions.pipe(ofType(facade.getBackendUserSuccess, facade.getBackendUserFail), take(1));
          }),
          take(1),
        )
        .subscribe(() => {
          resolve(true);
        });
    });
  };
}
