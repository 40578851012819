import { inject, Injectable } from '@angular/core';
import { BACKEND_TOKEN } from '@kiq/client/util/config';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Trophy } from '@kiq/shared/types';

@Injectable()
export class TrophyService {
  private readonly http = inject(HttpClient);
  private readonly BACKEND_BASE_URL = inject(BACKEND_TOKEN);
  private readonly BACKEND_TROPHY_URL = `${this.BACKEND_BASE_URL}/api/v1/user/trophies`;

  private static getHeader(jwtToken?: string, withoutContentType = false): { headers: HttpHeaders } {
    const headers = {
      headers: new HttpHeaders({}),
    };

    if (jwtToken) {
      headers.headers = headers.headers.append('Authorization', `Bearer ${jwtToken}`);
    }
    if (!withoutContentType) {
      headers.headers = headers.headers.append('Content-Type', 'application/json');
    }
    return headers;
  }

  getTrophies$(userId: string): Observable<Array<Trophy>> {
    const httpOptions = TrophyService.getHeader();
    const url = `${this.BACKEND_TROPHY_URL}/${userId}`;

    return this.http.get<Array<Trophy>>(url, { ...httpOptions });
  }
}
