import { ImmerComponentStore } from 'ngrx-immer/component-store';
import { Injectable, inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BACKEND_TOKEN } from '@kiq/client/util/config';
import { UserFacade } from '@kiq/client/data-access/user';
import { concatMap, Observable, of, pipe, tap, withLatestFrom } from 'rxjs';
import { tapResponse } from '@ngrx/component-store';
import { FootballTeam, FootballTeamColor } from '@kiq/shared/types';

export interface ClubSearchState {
  allClubsLoading: boolean;
  allClubs: FootballTeam[] | null;
  filteredClubs: FootballTeam[] | null;
  query: string | null;
}

const initialState: ClubSearchState = {
  allClubsLoading: false,
  allClubs: null,
  filteredClubs: null,
  query: null,
};

@Injectable()
export class ClubSearchComponentStore extends ImmerComponentStore<ClubSearchState> {
  private readonly BACKEND_BASE_URL = inject(BACKEND_TOKEN);
  private readonly httpClient = inject(HttpClient);
  private readonly userFacade = inject(UserFacade);

  color: FootballTeamColor = {primary: '#123455', secondary: '#123456'}
  club1: FootballTeam = {
    id: '1',
    name: 'Borussia Dortmund',
    color: this.color
  }
  club2: FootballTeam = {
    id: '2',
    name: 'Bayern München',
    color: this.color
  }
  club3: FootballTeam = {
    id: '3',
    name: 'Hamburger SV',
    color: this.color
  }
  clubList = [this.club1, this.club2, this.club3]

  private readonly BACKEND_URL = `${this.BACKEND_BASE_URL}/api/v1`;

  constructor() {
    super(initialState);
  }


  private getHeader(jwtToken?: string, withoutContentType = false): { headers: HttpHeaders } {
    const headers = {
      headers: new HttpHeaders({}),
    };

    if (jwtToken) {
      headers.headers = headers.headers.append('Authorization', `Bearer ${jwtToken}`);
    }
    if (!withoutContentType) {
      headers.headers = headers.headers.append('Content-Type', 'application/json');
    }
    return headers;
  }

  private getAllClubs$() {
    const httpOptions = this.getHeader();
    const url = this.BACKEND_URL + '/search/footballTeam';

    // let params = new HttpParams();
    // params = params.append('query', query);

    // return of(this.clubList);
    return this.httpClient.get<Array<FootballTeam>>(url, { ...httpOptions });
  }

  // private getFilteredClubs$(query: string) {
  //   return this.allClubs$.pipe(take(1)).subscribe(clubs => {
  //     return of(clubs);
  //   })
  // }

  readonly allClubsLoading$ = this.select((state) => state.allClubsLoading);
  readonly allClubs$ = this.select((state) => state.allClubs);
  readonly filteredClubs$ = this.select((state) => state.filteredClubs);
  readonly query$ = this.select((state) => state.query);
  readonly vm$ = this.select((state) => state);

  readonly getAllClubs = this.effect<void>(
    pipe(
      tap(() => this.patchState({ allClubsLoading: true })),
      concatMap(() => {
        return this.getAllClubs$().pipe(
          tapResponse({
            next: (allClubs) => this.patchState({allClubs, allClubsLoading: false}),
            error: error => {
              console.error(error);
              this.patchState({allClubsLoading: false})
            }
          })
        )
      })
    )
  )


  readonly getFilteredClubs = this.effect((query$: Observable<string>) => {
    return query$.pipe(
      tap((query) => this.patchState({ query, allClubsLoading: true })),
      withLatestFrom(this.allClubs$),
      concatMap(([query, allClubs]) => {
        console.log('all clubs first name: ' + allClubs?.[0]?.name);
        return of(allClubs?.filter((club) => {
          return club.name.toLowerCase().includes(query.toLowerCase());
        })).pipe(
          tapResponse({
            next: (filteredClubs) => this.patchState({filteredClubs, allClubsLoading: false}),
            error: error => {
              console.error(error);
              this.patchState({allClubsLoading: false})
            }
          })
        )
      }),
    );
  });
}
