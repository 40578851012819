export enum WiiGameHintType {
  LEAGUE = 'league',
  CLUB = 'club',
  POSITION = 'position',
  DATE_OF_BIRTH = 'dateOfBirth',
  NATIONALITY = 'nationality',
  HEIGHT = 'height',
  FOOT = 'foot',
  JERSEY_NUMBER = 'jerseyNumber',
  TEAM_MEMBER_SINCE = 'teamMemberSince',
  PREVIOUS_CLUB = 'previousClub',
}
