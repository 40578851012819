import { AbstractControl } from '@angular/forms';

export class PasswordValidators {
  static passwordMatch(control: AbstractControl) {
    const newPassword = control.get('newPassword')?.value;
    const confirmPassword = control.get('confirmPassword')?.value;
    if (!newPassword || !confirmPassword) {
      return null;
    }

    if (newPassword === confirmPassword) {
      return null;
    } else {
      return { passwordMismatch: true };
    }
  }
}
