import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { clientFeatureShellRoutes } from './lib.routes';
import { ClientDataAccessGamesOverviewModule } from '@kiq/client/data-access/games-overview';
import { ClientDataAccessConfigModule } from '@kiq/client/data-access/config';
import { SharedDataAccessTrophyModule } from 'shared/data-access/trophy';
import { SharedDataAccessOnlineStatisticsModule } from '@kiq/shared/data-access/online-statistics';

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(clientFeatureShellRoutes),
    ClientDataAccessGamesOverviewModule,
    ClientDataAccessConfigModule,
    SharedDataAccessTrophyModule,
    SharedDataAccessOnlineStatisticsModule,
  ],
})
export class ClientFeatureShellModule {}
