import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  ElementRef,
  inject,
  Injector,
  OnInit,
  signal,
  ViewChild,
  WritableSignal,
} from '@angular/core';
import { wordpressRoutes } from '@kiq/client/util/routing';
import { FormBuilder, Validators } from '@angular/forms';
import { UserFacade } from '@kiq/client/data-access/user';
import { Router } from '@angular/router';
import { UsernameValidators } from '@kiq/shared/validators';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FootballTeam, InGameModalConfig, ModalConfig } from '@kiq/shared/types';
import { InGameModalService, MODAL_CONFIG, MODAL_REF, ModalService } from '@kiq/shared/util/modal';
import { Actions, ofType } from '@ngrx/effects';
import { ClubSearchComponent } from 'client/feature/club-search';
import { ConfigFacade, CustomTitleStrategyService } from '@kiq/client/data-access/config';
import { LoginComponent } from '../login/login.component';
import { NativeAppsService } from '@kiq/shared/data-access/native-apps';

@Component({
  selector: 'kiq-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RegisterComponent implements OnInit {
  private readonly router = inject(Router);
  private readonly fb = inject(FormBuilder);
  private readonly userFacade = inject(UserFacade);
  private readonly destroyRef = inject(DestroyRef);
  private readonly modalRef = inject(MODAL_REF);
  private readonly modalConfig$ = inject(MODAL_CONFIG);
  private readonly modalService = inject(ModalService);
  private readonly actions = inject(Actions);
  private readonly titleService = inject(CustomTitleStrategyService);
  private readonly inGameModalService: InGameModalService | null;
  protected readonly WP_ROUTES = wordpressRoutes;
  protected readonly GOOGLE_LOGO = 'assets/icons/google-logo.svg';
  loading$ = this.userFacade.loading$;

  @ViewChild('userName') userNameInput?: ElementRef;

  focus = signal(0);
  focusOut = signal(false);
  favouriteClub: WritableSignal<FootballTeam | null> = signal(null);

  registerForm = this.fb.group({
    email: ['', [Validators.required, Validators.email]],
    password: ['', [Validators.required, Validators.minLength(6)]],
    username: [
      '',
      [Validators.required, UsernameValidators.username, Validators.minLength(3), Validators.maxLength(32)],
    ],
    favouriteClub: ['', [Validators.required]],
    privacyPolicy: [false, Validators.requiredTrue],
  });
  private configFacade = inject(ConfigFacade);
  private nativeAppsService = inject(NativeAppsService);

  constructor(injector: Injector) {
    this.inGameModalService = injector.get(InGameModalService, null);
  }

  ngOnInit() {
    this.titleService.setTitle('Registrieren Dialog');

    this.actions
      .pipe(ofType(this.userFacade.createNewBackendUserSuccess), takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.closeDialog();
      });
  }

  register() {
    const { email, password, username, favouriteClub } = this.registerForm.value;

    if (!this.registerForm.valid || !email || !password || !username || !favouriteClub || !this.favouriteClub()) {
      return;
    }

    if (this.favouriteClub() !== null) {
      this.userFacade.createNewUser(email, password, username, this.favouriteClub()!);
    }
  }

  closeDialog() {
    this.modalRef.close();
  }

  registerWithGoogle() {
    this.userFacade.createUserWithGoogle();
  }

  openLogin() {
    if (this.inGameModalService?.hasModalOpen) {
      const inGameModalConfig: InGameModalConfig = {
        component: LoginComponent,
        isFullModalHeight: true,
      };

      this.inGameModalService?.updateInGameModal(inGameModalConfig);
    } else {
      const modalConfig: ModalConfig = {
        component: LoginComponent,
        useFullscreenModal: this.configFacade.useFullscreenModal(),
      };

      this.modalService.openNestedModal(modalConfig);
    }

  }

  openClubSearch() {
    if (this.inGameModalService) {
      const inGameModalConfig: InGameModalConfig = {
        component: ClubSearchComponent,
        isFullModalHeight: true,
      };
      const modalRef = this.inGameModalService.openInGameModal<FootballTeam, unknown>(inGameModalConfig);

      modalRef.afterClosed$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((club) => {
        if (club) {
          this.registerForm.controls.favouriteClub.setValue(club.name);
          this.favouriteClub.set(club);
        }
      });
    }
  }
}
