import { GamePlayerGuessingHint } from './game-player-guessing-hint';
import { GamePlayerGuessingConfig } from './game-player-guessing-config';

export type GamePlayerGuessingState = {
  config: GamePlayerGuessingConfig;
  gameId: string;
  maxNumberOfHints: number;
  receivedHints: GamePlayerGuessingHint[];
  gameTimeoutTimestamp: Date;
  gameEnded: boolean;
  userDidWin: boolean;
  footballPlayerName: string | null;
  userScore: number;
  maxGameDurationSeconds: number;
};
