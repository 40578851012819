<div class="sm:rounded-2xl relative wrapper overflow-hidden flex flex-col animation h-full sm:border border-color" #container>
  <ng-template #componentPortalOutlet cdkPortalOutlet></ng-template>

  <div class="header-container flex items-center" *ngIf="!modalConfig?.withoutHeader">
    <div class="flex justify-between items-center w-full">
      @if (modalConfig?.header) {
        <ng-container *ngTemplateOutlet="modalConfig?.header ?? null"></ng-container>
      } @else {
        <h3>
          {{ modalConfig?.headline ?? "" }}
        </h3>
      }
    </div>
  </div>

  <div class="content-container overflow-y-auto flex flex-col h-full w-full">
    <div class="h-full">
      <ng-template dynamicChildLoader></ng-template>

      @if (modalConfig?.templateRef) {
        <ng-container *ngTemplateOutlet="modalConfig?.templateRef ?? null"></ng-container>
      }
    </div>
  </div>
</div>
