import { Injectable } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { filter, map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class ViewportService {
  // (max-width: 599.98px)
  isMobile$ = this.breakpointObserver.observe([Breakpoints.XSmall]).pipe(
    map((result) => result.matches),
    filter((matches) => matches === true),
  );

  // (min-width: 600px) and (max-width: 839.98px) and (orientation: portrait), (min-width: 960px) and (max-width: 1279.98px) and (orientation: landscape)
  isTablet$ = this.breakpointObserver.observe([Breakpoints.Tablet]).pipe(
    map((result) => result.matches),
    filter((matches) => matches === true),
  );

  // (min-width: 840px) and (orientation: portrait), (min-width: 1280px) and (orientation: landscape)
  isDesktop$ = this.breakpointObserver.observe([Breakpoints.Web]).pipe(
    map((result) => result.matches),
    filter((matches) => matches === true),
  );

  isLandscape$ = this.breakpointObserver.observe(['(max-height: 559px)']).pipe(
    map((result) => result.matches),
    filter((matches) => matches === true),
  );
  isPortrait$ = this.breakpointObserver.observe(['(min-height: 560px)']).pipe(
    map((result) => result.matches),
    filter((matches) => matches === true),
  );
  useFullscreenModal$ = this.breakpointObserver.observe(['(max-width: 680px)']).pipe(
    map((result) => result.matches),
    // filter((matches) => matches === true),
  )

  constructor(private breakpointObserver: BreakpointObserver) {}
}
