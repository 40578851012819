import { inject, Injectable, Signal } from '@angular/core';
import { ConfigStore } from './config.store';
import { NativeAppType, ViewSize } from '@kiq/shared/enums';

@Injectable({ providedIn: 'root' })
export class ConfigFacade {
  private readonly store = inject(ConfigStore);

  readonly viewSize: Signal<ViewSize> = this.store.viewSize;
  readonly headerVisible: Signal<boolean> = this.store.headerVisible;
  readonly isMobile: Signal<boolean> = this.store.isMobile;
  readonly isTablet: Signal<boolean> = this.store.isTablet;
  readonly isDesktop: Signal<boolean> = this.store.isDesktop;
  readonly isLandscape: Signal<boolean> = this.store.isLandscape;
  readonly useFullscreenModal: Signal<boolean> = this.store.useFullscreenModal;
  readonly partner = this.store.partner;
  readonly tttFeatureFlag = this.store.tttFeature;
  readonly wiiFeatureFlag = this.store.wiiFeature;
  readonly topscorerFeatureFlag = this.store.topscorerFeature;
  readonly mixedZoneFeatureFlag = this.store.mixedZoneFeature;
  readonly fakeOrFactFeatureFlag = this.store.fakeOrFactFeature;
  readonly adLoadingScreenFeatureFlag = this.store.adLoadingScreenFeature;
  readonly adGamesOverviewFeatureFlag = this.store.adGamesOverviewFeature;
  readonly nationsFeatureFlag = this.store.nationsFeature;
  readonly nativeAppType: Signal<NativeAppType | null> = this.store.nativeAppType;
  readonly buliLicenseFeatureFlag = this.store.buliLicenseFeature;
  readonly trophiesFeatureFlag = this.store.trophiesFeature;
  readonly asynchroneFeatureFlag = this.store.asynchroneFeature;

  setHeaderVisibility(visible: boolean) {
    this.store.setHeaderVisibility(visible);
  }

  setPartner(partner?: string) {
    this.store.setPartner(partner);
  }
}
