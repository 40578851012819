import { TrophyRank } from '@kiq/shared/enums';

export type Trophy = {
  id: string;
  title?: string;
  description?: string;
  rank?: TrophyRank;
  rankDistances?: Map<TrophyRank, number>;
  currentRankCount?: number;
  imageUrl?: string;
  dateBadge?: string;

  // not yet clear whether and how this will be implemented or required
  sponsor?: string;
  trophyIdByRank?: Map<TrophyRank, string>;
  ownedTrophyRanks?: Array<Trophy>;
  comingSoon?: boolean;
};
