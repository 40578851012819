import { GameTttState } from './game-ttt-state';
import { GameTttActionType } from './game-ttt-action-type';

export type GameTttAction = {
  currentState: GameTttState;
  type: GameTttActionType;

  x?: number;
  y?: number;
  footballPlayerId?: string;
  gameTimeoutExceeded?: boolean;
};
