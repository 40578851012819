import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { clientFeatureShellRoutes } from './lib.routes';
import { ClientDataAccessGamesOverviewModule } from '@kiq/client/data-access/games-overview';
import { ClientDataAccessConfigModule } from '@kiq/client/data-access/config';

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(clientFeatureShellRoutes),
    ClientDataAccessGamesOverviewModule,
    ClientDataAccessConfigModule,
  ],
})
export class ClientFeatureShellModule {}
