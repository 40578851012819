<div class="selection-trigger" #selectionButton [cdkMenuTriggerFor]="selection"
  >{{ currentlySelectedOption?.label ?? placeholder }}
</div>
<ng-template #selection>
  <ul class="selection-menu" [ngStyle]="{ width: selectionButton.offsetWidth + 'px' }" cdkMenu>
    <li
      class="selection-option list-none"
      *ngFor="let option of options"
      (click)="optionSelected(option)"
      cdkMenuItem
      >{{ option.label }}</li
    >
  </ul>
</ng-template>
