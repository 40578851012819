<div class="background-stripes with-dark-gradient bg-cover bg-no-repeat" id="register">
  <div class="bg-primary border-b border-surfaceSecondary absolute top-0 w-full">
    <div class="p-4 h-12 max-w-default flex items-center">
      <button class="h-6 w-6" (click)="closeDialog()">
        <shared-icon class="icon-wrapper" name="close-round"></shared-icon>
      </button>
      <p class="text-textPrimary mx-auto brand text-base">Registrieren</p>
    </div>
  </div>

  <div class="relative overflow-y-auto px-4 py-4 mt-[48px] bg-no-repeat bg-cover bg-center custom-height">
    <h1 class="text-textPrimary brand mb-4 sm:text-center uppercase">Zeig dein geballtes<span
      class="text-surfaceBrand normal-case"> FUßBALLWISSEN</span>
    </h1>
    <form [formGroup]="registerForm">
      <div>
        <div class="flex flex-col gap-y-2 w-full">
          <kiq-text-input
            (focusin)="focus.set(1)"
            (keydown.enter)="focus.set(2)"
            class="w-full"
            [formControlName]="'email'"
            [inputType]="'email'"
            autocomplete="username"
            placeholder="E-Mail-Adresse*"></kiq-text-input>
          <kiq-text-input
            (focusin)="focus.set(2)"
            (keydown.enter)="focus.set(3)"
            [shouldFocus]="focus() === 2"
            class="w-full"
            [formControlName]="'password'"
            [inputType]="'password'"
            autocomplete="new-password"
            placeholder="Passwort*"></kiq-text-input>
          <kiq-text-input
            #userName class="w-full"
            [formControlName]="'username'"
            placeholder="Benutzername*"
            (keydown.enter)="focusOut.set(true); focus.set(4)"
            [shouldFocus]="focus() === 3"
            (focusin)="focusOut.set(false)">
          </kiq-text-input>
          <kiq-text-input
            #favouriteClub class="w-full"
            [formControlName]="'favouriteClub'"
            placeholder="Dein Lieblingsverein*"
            (keydown.enter)="focusOut.set(true); focus.set(0)"
            [shouldFocus]="focus() === 4"
            [blur]="focusOut()"
            (focusin)="focusOut.set(false); openClubSearch()">
          </kiq-text-input>
          <div class="flex gap-4 items-center">
            <kiq-checkbox formControlName="privacyPolicy"></kiq-checkbox>
            <div>
              <p class="text-white py-2"
              >Ich akzeptiere die
                <span class="cursor-pointer text-white underline"><a class="text-white" [href]="[WP_ROUTES.AGB]">Allgemeinen Geschäftsbedingungen</a></span>
                und habe die
                <span class="cursor-pointer text-white underline"><a class="text-white"
                                                                     [href]="[WP_ROUTES.PRIVACY_POLICY]">Datenschutzrichtlinien</a></span>
                gelesen.
              </p>
            </div>
          </div>
          <div class="w-full h-14">
            <client-button
              [loading]="(loading$ | async) ?? undefined"
              [buttonType]="'primary'"
              [disabled]="registerForm.invalid"
              (click)="register()"
            ><p class="large">Registrieren</p>
            </client-button>
          </div>
        </div>
      </div>
    </form>
    <div class="flex flex-col text-white mt-2 items-center gap-y-4 justify-center">
      <p>oder weiter mit</p>
      <div class="w-full h-14">
        <client-button [buttonType]="'secondary'" (click)="registerWithGoogle()">
          <div class="flex items-center gap-x-2">
            <img class="h-6 w-6 object-contain" [src]="GOOGLE_LOGO"/>
            <p class="large">Google</p>
          </div>
        </client-button>
      </div>
      <p>Bereits registriert? <a class="underline pl-2 cursor-pointer" (click)="openLogin()">Einloggen</a></p>
    </div>
  </div>
</div>
