<!--<svg-->
<!--  id="previous-club-icon"-->
<!--  [style.width]="iconSize"-->
<!--  [style.height]="iconSize"-->
<!--  viewBox="0 0 104 81"-->
<!--  fill="none"-->
<!--  xmlns="http://www.w3.org/2000/svg">-->
<!--  <g clip-path="url(#clip0_1_11)">-->
<!--    <path-->
<!--      [style.fill]="'#' + fillColor2"-->
<!--      d="M7.5 36.0586V26.1602C7.5 25.3778 8.10137 24.7268 8.88133 24.6649L9.23421 24.6369C13.8096 24.2738 18.3102 23.2631 22.6015 21.6349L23.4679 21.3062C23.8107 21.1761 24.1893 21.1761 24.5321 21.3062L25.3985 21.6349C29.6898 23.2631 34.1904 24.2738 38.7658 24.6369L39.1187 24.6649C39.8986 24.7268 40.5 25.3778 40.5 26.1602V36.0586C40.5 39.7029 39.4909 43.2759 37.5844 46.3818C34.8809 50.7862 31.2971 54.585 27.0574 57.5403L24.2541 59.4944C24.1014 59.6008 23.8986 59.6008 23.7459 59.4944L20.9426 57.5403C16.7029 54.585 13.1191 50.7862 10.4156 46.3818C8.50915 43.2759 7.5 39.7029 7.5 36.0586Z"-->
<!--      stroke="#999999"-->
<!--      stroke-width="3" />-->
<!--  </g>-->
<!--  <g clip-path="url(#clip1_1_11)">-->
<!--    <path-->
<!--      [style.fill]="'#' + fillColor1"-->
<!--      d="M40.5 36.0586V26.1602C40.5 25.3778 39.8986 24.7268 39.1187 24.6649L38.7658 24.6369C34.1904 24.2738 29.6898 23.2631 25.3985 21.6349L24.5321 21.3062C24.1893 21.1761 23.8107 21.1761 23.4679 21.3062L22.6015 21.6349C18.3102 23.2631 13.8096 24.2738 9.23421 24.6369L8.88132 24.6649C8.10137 24.7268 7.5 25.3778 7.5 26.1602V36.0586C7.5 39.7029 8.50915 43.2759 10.4156 46.3818C13.1191 50.7862 16.7029 54.585 20.9426 57.5403L23.7459 59.4944C23.8986 59.6008 24.1014 59.6008 24.2541 59.4944L27.0574 57.5403C31.2971 54.585 34.8809 50.7862 37.5844 46.3818C39.4909 43.2759 40.5 39.7029 40.5 36.0586Z"-->
<!--      stroke="#999999"-->
<!--      stroke-width="3" />-->
<!--  </g>-->
<!--  <g clip-path="url(#clip2_1_11)">-->
<!--    <path-->
<!--      d="M35.5 34.3372V14.7528C35.5 13.9704 36.1014 13.3194 36.8813 13.2575L43.7676 12.711C48.3429 12.3479 52.8436 11.3371 57.1348 9.709L63.4679 7.30621C63.8107 7.17615 64.1893 7.17615 64.5321 7.30621L70.8652 9.709C75.1564 11.3371 79.6571 12.3479 84.2325 12.711L91.1187 13.2575C91.8986 13.3194 92.5 13.9704 92.5 14.7528V34.3372C92.5 39.7851 90.9914 45.1267 88.1414 49.7697L86.6118 52.2615C82.6151 58.7728 77.317 64.3888 71.0493 68.7577L64.2859 73.4722C64.1141 73.592 63.8859 73.592 63.7141 73.4722L56.9507 68.7577C50.683 64.3888 45.3849 58.7728 41.3882 52.2615L39.8586 49.7696C37.0086 45.1267 35.5 39.7852 35.5 34.3372Z"-->
<!--      fill="#292929"-->
<!--      stroke="#474747"-->
<!--      stroke-width="3" />-->
<!--  </g>-->
<!--  <g clip-path="url(#clip3_1_11)">-->
<!--    <path-->
<!--      d="M92.5 34.3372V14.7528C92.5 13.9704 91.8986 13.3194 91.1187 13.2575L84.2324 12.711C79.6571 12.3479 75.1564 11.3371 70.8652 9.709L64.5321 7.30621C64.1893 7.17615 63.8107 7.17615 63.4679 7.30621L57.1348 9.709C52.8436 11.3371 48.3429 12.3479 43.7675 12.711L36.8813 13.2575C36.1014 13.3194 35.5 13.9704 35.5 14.7528V34.3372C35.5 39.7851 37.0086 45.1267 39.8586 49.7697L41.3882 52.2615C45.3849 58.7728 50.683 64.3888 56.9507 68.7577L63.7141 73.4722C63.8859 73.592 64.1141 73.592 64.2859 73.4722L71.0493 68.7577C77.317 64.3888 82.6151 58.7728 86.6118 52.2615L88.1414 49.7696C90.9914 45.1267 92.5 39.7852 92.5 34.3372Z"-->
<!--      fill="#292929"-->
<!--      stroke="#474747"-->
<!--      stroke-width="3" />-->
<!--  </g>-->
<!--  <path-->
<!--    d="M64 25.8125C61.5895 25.8125 59.2332 26.5273 57.229 27.8665C55.2248 29.2056 53.6627 31.1091 52.7402 33.336C51.8178 35.563 51.5764 38.0135 52.0467 40.3777C52.5169 42.7418 53.6777 44.9134 55.3821 46.6179C57.0866 48.3223 59.2582 49.4831 61.6223 49.9533C63.9865 50.4236 66.437 50.1822 68.664 49.2598C70.8909 48.3373 72.7944 46.7752 74.1335 44.771C75.4727 42.7668 76.1875 40.4105 76.1875 38C76.1841 34.7687 74.899 31.6708 72.6141 29.3859C70.3292 27.101 67.2313 25.8159 64 25.8125ZM64.9375 30.4273L67.8426 28.4293C69.5059 29.0993 70.9668 30.1895 72.0824 31.5934L71.1449 34.7504C71.1215 34.7504 71.0969 34.7621 71.0734 34.7703L68.3992 35.6387C68.3591 35.6515 68.3199 35.6672 68.282 35.6855L64.9375 33.3852V30.4273ZM60.1609 28.4293L63.0625 30.4273V33.3852L59.7156 35.6902C59.6777 35.6719 59.6386 35.6562 59.5984 35.6434L56.9242 34.775C56.9008 34.7668 56.8762 34.7609 56.8527 34.7551L55.9152 31.598C57.0319 30.1917 58.495 29.0997 60.1609 28.4293ZM58.6375 43.557H55.3164C54.3443 42.0455 53.7869 40.3048 53.7004 38.5098L56.2785 36.5316C56.3007 36.5414 56.3234 36.5501 56.3465 36.5574L59.0219 37.427C59.0576 37.4378 59.094 37.4464 59.1309 37.4527L60.3953 41.1348C60.3777 41.1559 60.3602 41.177 60.3438 41.1992L58.6914 43.4738C58.6719 43.5006 58.6539 43.5283 58.6375 43.557ZM66.659 47.9609C64.9167 48.425 63.0833 48.425 61.341 47.9609L60.1621 44.6328C60.1773 44.6141 60.1937 44.5965 60.2078 44.5766L61.8613 42.3008C61.8808 42.2744 61.8988 42.2471 61.9152 42.2187H66.0848C66.1012 42.2471 66.1192 42.2744 66.1387 42.3008L67.7922 44.5766C67.8062 44.5965 67.8227 44.6141 67.8379 44.6328L66.659 47.9609ZM69.3625 43.5535C69.346 43.5248 69.3281 43.497 69.3086 43.4703L67.6551 41.1992C67.6387 41.177 67.6211 41.1559 67.6035 41.1348L68.868 37.4527C68.9048 37.4464 68.9412 37.4378 68.977 37.427L71.6523 36.5574C71.6754 36.5501 71.6981 36.5414 71.7203 36.5316L74.2984 38.5098C74.2119 40.3048 73.6546 42.0455 72.6824 43.557L69.3625 43.5535Z"-->
<!--    fill="#474747" />-->
<!--  <defs>-->
<!--    <clipPath id="clip0_1_11">-->
<!--      <rect width="18" height="42" fill="white" transform="translate(24 19.5)" />-->
<!--    </clipPath>-->
<!--    <clipPath id="clip1_1_11">-->
<!--      <rect width="18" height="42" fill="white" transform="matrix(-1 0 0 1 24 19.5)" />-->
<!--    </clipPath>-->
<!--    <clipPath id="clip2_1_11">-->
<!--      <rect width="30" height="70" fill="white" transform="translate(64 5.5)" />-->
<!--    </clipPath>-->
<!--    <clipPath id="clip3_1_11">-->
<!--      <rect width="30" height="70" fill="white" transform="matrix(-1 0 0 1 64 5.5)" />-->
<!--    </clipPath>-->
<!--  </defs>-->
<!--</svg>-->

<!--<svg-->
<!--  id="club-icon"-->
<!--  [style.width]="iconSize"-->
<!--  [style.height]="iconSize"-->
<!--  viewBox="0 0 60 71"-->
<!--  fill="none"-->
<!--  xmlns="http://www.w3.org/2000/svg">-->
<!--  <g clip-path="url(#clip0_2701_9838)">-->
<!--    <path-->
<!--      [style.fill]="'#' + fillColor2"-->
<!--      d="M1.5 9.75282C1.5 8.97042 2.10137 8.31942 2.88133 8.25752L9.76755 7.711C14.3429 7.34787 18.8436 6.33712 23.1348 4.709L29.4679 2.30621C29.8107 2.17615 30.1893 2.17615 30.5321 2.30621L36.8652 4.709C41.1564 6.33713 45.6571 7.34787 50.2325 7.711L57.1187 8.25752C57.8986 8.31942 58.5 8.97041 58.5 9.75282V29.3372C58.5 34.7851 56.9914 40.1267 54.1414 44.7697L52.6118 47.2615C48.6151 53.7728 43.317 59.3888 37.0493 63.7577L30.2859 68.4722C30.1141 68.592 29.8859 68.592 29.7141 68.4722L22.9507 63.7577C16.683 59.3888 11.3849 53.7728 7.38816 47.2615L5.85859 44.7696C3.0086 40.1267 1.5 34.7852 1.5 29.3372V9.75282Z"-->
<!--      stroke="#999999"-->
<!--      stroke-width="3" />-->
<!--  </g>-->
<!--  <g clip-path="url(#clip1_2701_9838)">-->
<!--    <path-->
<!--      [style.fill]="'#' + fillColor1"-->
<!--      d="M58.5 9.75282C58.5 8.97042 57.8986 8.31942 57.1187 8.25752L50.2324 7.711C45.6571 7.34787 41.1564 6.33712 36.8652 4.709L30.5321 2.30621C30.1893 2.17615 29.8107 2.17615 29.4679 2.30621L23.1348 4.709C18.8436 6.33713 14.3429 7.34787 9.76755 7.711L2.88132 8.25752C2.10137 8.31942 1.5 8.97041 1.5 9.75282V29.3372C1.5 34.7851 3.00861 40.1267 5.85859 44.7697L7.38816 47.2615C11.3849 53.7728 16.683 59.3888 22.9507 63.7577L29.7141 68.4722C29.8859 68.592 30.1141 68.592 30.2859 68.4722L37.0493 63.7577C43.317 59.3888 48.6151 53.7728 52.6118 47.2615L54.1414 44.7696C56.9914 40.1267 58.5 34.7852 58.5 29.3372V9.75282Z"-->
<!--      stroke="#999999"-->
<!--      stroke-width="3" />-->
<!--  </g>-->
<!--  <defs>-->
<!--    <clipPath id="clip0_2701_9838">-->
<!--      <rect width="30" height="70" fill="white" transform="translate(30 0.5)" />-->
<!--    </clipPath>-->
<!--    <clipPath id="clip1_2701_9838">-->
<!--      <rect width="30" height="70" fill="white" transform="matrix(-1 0 0 1 30 0.5)" />-->
<!--    </clipPath>-->
<!--  </defs>-->
<!--</svg>-->

<svg width="132" height="81" viewBox="0 0 132 81" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g clip-path="url(#clip0_2509_1295)">
    <path
      d="M44.4053 11.6644L44.3737 11.6535L44.3414 11.6455C41.2281 10.8701 39.6521 10.8584 38.0437 11.0613C37.6786 11.1073 37.3205 11.1626 36.9468 11.2203C35.7256 11.409 34.3385 11.6232 32.0001 11.6232C29.6594 11.6232 28.2107 11.4083 26.9385 11.2196C26.5498 11.162 26.1776 11.1068 25.8015 11.0609C24.1475 10.8591 22.5692 10.8687 19.6467 11.6486L19.6205 11.6556L19.5949 11.6644L3.73071 17.1522C2.01262 17.7465 0.968283 19.4876 1.25286 21.2831L2.77686 30.8989C3.04379 32.5832 4.41348 33.8752 6.11041 34.0436L13.2339 34.7503C13.3251 34.7619 13.4005 34.7715 13.4662 34.7807C13.4673 34.8529 13.4674 34.9363 13.4674 35.0385V35.0762L13.4712 35.1138C14.6531 46.8311 14.5286 53.7016 13.043 64.3399C12.7598 66.3679 14.1392 68.2835 16.1859 68.5929C28.9842 70.5274 34.9685 70.5402 47.7428 68.6057C49.8248 68.2904 51.2064 66.3175 50.8758 64.2643C49.1451 53.516 49.1247 46.6663 50.5273 35.129L50.5342 35.0727L50.5325 35.0159C50.5298 34.9278 50.5277 34.8549 50.5265 34.7913C50.5977 34.7786 50.6796 34.7652 50.7793 34.749L57.8898 34.0436C59.5867 33.8752 60.9564 32.5832 61.2233 30.8989L62.7473 21.2831C63.0319 19.4876 61.9876 17.7465 60.2695 17.1522L44.4053 11.6644ZM13.6873 34.8215C13.6873 34.8215 13.6856 34.821 13.6827 34.8199C13.686 34.8209 13.6874 34.8215 13.6873 34.8215ZM13.454 34.5615C13.4532 34.5583 13.4529 34.5565 13.453 34.5565C13.453 34.5564 13.4534 34.558 13.454 34.5615ZM50.5318 34.5676C50.5318 34.5675 50.5317 34.569 50.5311 34.5716C50.5315 34.5689 50.5318 34.5676 50.5318 34.5676ZM50.3132 34.8386C50.3102 34.8399 50.3084 34.8405 50.3082 34.8405C50.308 34.8405 50.3096 34.8398 50.3132 34.8386Z"
      fill="#F5F5F5"
      stroke="#707070"
      stroke-width="1.5" />
    <g clip-path="url(#clip1_2509_1295)">
      <mask
        id="mask0_2509_1295"
        style="mask-type: alpha"
        maskUnits="userSpaceOnUse"
        x="1"
        y="11"
        width="62"
        height="59">
        <path
          d="M44.1598 12.2669C38.0798 10.7469 38.2331 12.2669 31.9998 12.2669C25.7665 12.2669 25.5144 10.7469 19.8397 12.2669L3.97025 17.7771C2.59819 18.2535 1.76447 19.6444 1.99099 21.079L3.51838 30.7525C3.73119 32.1002 4.82666 33.1346 6.18439 33.2698L13.3176 33.9802C14.2144 34.0942 14.2169 34.0942 14.2169 35.0176C15.4051 46.8402 15.2797 53.798 13.7834 64.5478C13.5564 66.1787 14.6659 67.7065 16.2938 67.9535C29.0226 69.885 34.9311 69.8975 47.6343 67.9664C49.2897 67.7147 50.4028 66.1411 50.1374 64.4879C48.3918 53.6122 48.372 46.6642 49.7826 35.0176C49.756 34.1309 49.756 34.1309 50.6819 33.9802L57.8151 33.2698C59.1729 33.1346 60.2683 32.1002 60.4812 30.7525L62.0085 21.079C62.2351 19.6444 61.4013 18.2535 60.0293 17.7771L44.1598 12.2669Z"
          fill="#F5F5F5" />
      </mask>
      <g mask="url(#mask0_2509_1295)">
        <path [style.fill]="hexFillColor1 ?? 'primary'" d="M-1.43994 9.72021H8.18797V89.7202H-1.43994V9.72021Z" />
        <path [style.fill]="hexFillColor2 ?? 'secondary'" d="M8.18799 9.72021H14.9914V89.7202H8.18799V9.72021Z" />
        <path [style.fill]="hexFillColor1 ?? 'primary'" d="M14.9912 9.72021H21.7947V89.7202H14.9912V9.72021Z" />
        <path [style.fill]="hexFillColor2 ?? 'secondary'" d="M21.7949 9.72021H28.5984V89.7202H21.7949V9.72021Z" />
        <path [style.fill]="hexFillColor1 ?? 'primary'" d="M28.5981 9.72021H35.4016V89.7202H28.5981V9.72021Z" />
        <path [style.fill]="hexFillColor2 ?? 'secondary'" d="M35.4019 9.72021H42.2053V89.7202H35.4019V9.72021Z" />
        <path [style.fill]="hexFillColor1 ?? 'primary'" d="M42.2051 9.72021H49.0085V89.7202H42.2051V9.72021Z" />
        <path [style.fill]="hexFillColor2 ?? 'secondary'" d="M49.0088 9.72021H55.8122V89.7202H49.0088V9.72021Z" />
        <path [style.fill]="hexFillColor1 ?? 'primary'" d="M55.812 9.72021H65.4399V89.7202H55.812V9.72021Z" />
      </g>
    </g>
    <rect width="27.3566" height="8.90377" transform="translate(18.335 32.8999)" fill="#0C0C0C" />
    <path
      d="M37.8335 39.4036L37.923 38.9264L40.2194 36.284H38.5314L38.7044 35.2998H42.492L42.4085 35.771L40.1061 38.4194H41.985L41.812 39.4036H37.8335Z"
      fill="#F5F5F5" />
    <path
      d="M33.3164 39.4036L34.0441 35.2998H35.3623L35.0641 36.9759L36.77 35.2998H38.458L36.6865 36.9878L37.7602 39.4036H36.2511L35.6427 37.8587L34.7837 38.5983L34.6346 39.4036H33.3164Z"
      fill="#F5F5F5" />
    <path
      d="M28.4673 39.4036L29.195 35.2998H30.5132L30.215 36.9759L31.9209 35.2998H33.6089L31.8374 36.9878L32.911 39.4036H31.402L30.7935 37.8587L29.9346 38.5983L29.7855 39.4036H28.4673Z"
      fill="#F5F5F5" />
    <path d="M26.3848 39.4036L27.1125 35.2998H28.4307L27.703 39.4036H26.3848Z" fill="#F5F5F5" />
    <path
      d="M21.5352 39.4036L22.2629 35.2998H23.5811L23.2828 36.9759L24.9888 35.2998H26.6768L24.9053 36.9878L25.9789 39.4036H24.4698L23.8614 37.8587L23.0025 38.5983L22.8534 39.4036H21.5352Z"
      fill="#F5F5F5" />
    <rect width="6.39814" height="2.53954" transform="translate(39.8398 22.1001)" fill="#0C0C0C" />
    <path
      d="M44.3711 23.8394L44.3916 23.7302L44.9173 23.1252H44.5309L44.5705 22.8999H45.4376L45.4185 23.0078L44.8914 23.6141H45.3215L45.2819 23.8394H44.3711Z"
      fill="#F5F5F5" />
    <path
      d="M43.3369 23.8394L43.5035 22.8999H43.8053L43.737 23.2836L44.1276 22.8999H44.514L44.1085 23.2864L44.3543 23.8394H44.0088L43.8695 23.4857L43.6728 23.6551L43.6387 23.8394H43.3369Z"
      fill="#F5F5F5" />
    <path
      d="M42.2271 23.8394L42.3937 22.8999H42.6954L42.6272 23.2836L43.0177 22.8999H43.4042L42.9986 23.2864L43.2444 23.8394H42.8989L42.7596 23.4857L42.563 23.6551L42.5288 23.8394H42.2271Z"
      fill="#F5F5F5" />
    <path d="M41.75 23.8394L41.9166 22.8999H42.2184L42.0518 23.8394H41.75Z" fill="#F5F5F5" />
    <path
      d="M40.6396 23.8394L40.8062 22.8999H41.108L41.0398 23.2836L41.4303 22.8999H41.8168L41.4112 23.2864L41.657 23.8394H41.3115L41.1722 23.4857L40.9756 23.6551L40.9414 23.8394H40.6396Z"
      fill="#F5F5F5" />
  </g>
  <g clip-path="url(#clip2_2509_1295)">
    <path
      d="M107.527 4.39622L107.485 4.38168L107.442 4.37092C103.544 3.40017 101.566 3.38461 99.5467 3.63931C99.0886 3.69709 98.6399 3.76639 98.1722 3.83863C96.6462 4.07432 94.9178 4.34128 92 4.34128C89.0791 4.34128 87.2736 4.07349 85.684 3.83771C85.1974 3.76555 84.7311 3.69638 84.2593 3.63881C82.1833 3.38553 80.2024 3.39834 76.5422 4.37509L76.5072 4.38441L76.4731 4.39622L56.0489 11.4614C54.2162 12.0953 53.1023 13.9525 53.4058 15.8677L55.4998 29.08C55.7846 30.8765 57.2456 32.2547 59.0556 32.4343L68.5352 33.3748C68.625 33.3861 68.7023 33.396 68.7708 33.4052C68.7715 33.4822 68.7716 33.5697 68.7716 33.6729V33.7232L68.7766 33.7732C70.2805 48.682 70.0921 57.3101 68.1377 71.0281C67.8291 73.1946 69.3129 75.2326 71.4913 75.5663C88.2564 78.1345 95.6979 78.1504 112.439 75.5791C114.656 75.2386 116.14 73.1391 115.779 70.9466C113.495 57.0797 113.436 48.4747 115.221 33.7936L115.23 33.7184L115.228 33.6428C115.225 33.5565 115.223 33.4822 115.222 33.4162C115.297 33.4032 115.382 33.3893 115.482 33.3731L124.944 32.4343C126.754 32.2547 128.215 30.8765 128.5 29.08L130.594 15.8677C130.898 13.9525 129.784 12.0953 127.951 11.4614L107.527 4.39622ZM69.0899 33.461C69.0898 33.4611 69.0874 33.4604 69.083 33.4588C69.0878 33.4602 69.09 33.461 69.0899 33.461ZM68.7562 33.0896C68.7551 33.0848 68.7546 33.0821 68.7547 33.082C68.7548 33.0819 68.7554 33.0843 68.7562 33.0896ZM115.226 33.0938C115.226 33.0939 115.226 33.096 115.225 33.0998C115.226 33.0957 115.226 33.0938 115.226 33.0938ZM114.914 33.4812C114.909 33.4832 114.907 33.4841 114.906 33.4841C114.906 33.484 114.909 33.483 114.914 33.4812Z"
      fill="#C2C2C2"
      stroke="#333333"
      stroke-width="2" />
    <g clip-path="url(#clip3_2509_1295)">
      <mask
        id="mask1_2509_1295"
        style="mask-type: alpha"
        maskUnits="userSpaceOnUse"
        x="54"
        y="4"
        width="76"
        height="73">
        <path
          d="M107.2 5.20871C99.5997 3.30869 99.7913 5.20871 91.9997 5.20871C84.2081 5.20871 83.893 3.3087 76.7996 5.20871L56.3701 12.3023C54.9981 12.7787 54.1644 14.1695 54.3909 15.6042L56.4883 28.8877C56.7011 30.2354 57.7966 31.2698 59.1543 31.405L68.647 32.3504C69.768 32.4929 69.7712 32.4929 69.7712 33.6471C71.2834 48.6946 71.0934 57.4376 69.1253 71.2992C68.8938 72.9294 70.0112 74.4554 71.6387 74.7057C88.3084 77.269 95.6473 77.2847 112.291 74.7185C113.946 74.4634 115.065 72.8916 114.794 71.2394C112.491 57.2076 112.433 48.4729 114.228 33.6471C114.195 32.5388 114.195 32.5388 115.352 32.3504L124.845 31.405C126.203 31.2698 127.298 30.2354 127.511 28.8877L129.609 15.6042C129.835 14.1695 129.001 12.7787 127.629 12.3023L107.2 5.20871Z"
          fill="#F5F5F5" />
      </mask>
      <g mask="url(#mask1_2509_1295)">
        <path d="M50.2002 2.0249H59.8281V82.0249H50.2002V2.0249Z" fill="#474747" />
        <path d="M59.8281 2.0249H69.0201V82.0249H59.8281V2.0249Z" fill="#5C5C5C" />
        <path d="M69.02 2.0249H78.212V82.0249H69.02V2.0249Z" fill="#474747" />
        <path d="M78.2119 2.0249H87.4039V82.0249H78.2119V2.0249Z" fill="#5C5C5C" />
        <path d="M87.4043 2.0249H96.5963V82.0249H87.4043V2.0249Z" fill="#474747" />
        <path d="M96.5962 2.0249H105.788V82.0249H96.5962V2.0249Z" fill="#5C5C5C" />
        <path d="M105.788 2.0249H114.98V82.0249H105.788V2.0249Z" fill="#474747" />
        <path d="M114.98 2.0249H124.172V82.0249H114.98V2.0249Z" fill="#5C5C5C" />
        <path d="M124.172 2.0249H133.8V82.0249H124.172V2.0249Z" fill="#474747" />
      </g>
    </g>
    <rect width="34.1958" height="11.1297" transform="translate(74.9185 31)" fill="#333333" />
    <path
      d="M99.291 39.1297L99.4029 38.5332L102.273 35.2302H100.163L100.38 34H105.114L105.01 34.589L102.132 37.8995H104.48L104.264 39.1297H99.291Z"
      fill="#C2C2C2" />
    <path
      d="M93.645 39.1297L94.5546 34H96.2024L95.8296 36.0951L97.962 34H100.072L97.8576 36.11L99.1997 39.1297H97.3134L96.5528 37.1986L95.4792 38.1232L95.2928 39.1297H93.645Z"
      fill="#C2C2C2" />
    <path
      d="M87.5835 39.1297L88.4931 34H90.1409L89.7681 36.0951L91.9005 34H94.0105L91.7961 36.11L93.1382 39.1297H91.2518L90.4913 37.1986L89.4177 38.1232L89.2313 39.1297H87.5835Z"
      fill="#C2C2C2" />
    <path d="M84.9805 39.1297L85.8901 34H87.5379L86.6282 39.1297H84.9805Z" fill="#C2C2C2" />
    <path
      d="M78.9185 39.1297L79.8281 34H81.4759L81.1031 36.0951L83.2355 34H85.3455L83.1311 36.11L84.4732 39.1297H82.5868L81.8263 37.1986L80.7526 38.1232L80.5662 39.1297H78.9185Z"
      fill="#C2C2C2" />
    <rect width="7.99767" height="3.17442" transform="translate(101.8 17.5)" fill="#333333" />
    <path
      d="M107.464 19.6744L107.49 19.5378L108.147 18.7817H107.664L107.714 18.5H108.798L108.774 18.6349L108.115 19.3927H108.652L108.603 19.6744H107.464Z"
      fill="#C2C2C2" />
    <path
      d="M106.171 19.6744L106.379 18.5H106.756L106.671 18.9797L107.159 18.5H107.642L107.135 18.9831L107.443 19.6744H107.011L106.837 19.2323L106.591 19.444L106.548 19.6744H106.171Z"
      fill="#C2C2C2" />
    <path
      d="M104.784 19.6744L104.992 18.5H105.369L105.284 18.9797L105.772 18.5H106.255L105.748 18.9831L106.055 19.6744H105.624L105.449 19.2323L105.204 19.444L105.161 19.6744H104.784Z"
      fill="#C2C2C2" />
    <path d="M104.188 19.6744L104.396 18.5H104.773L104.565 19.6744H104.188Z" fill="#C2C2C2" />
    <path
      d="M102.8 19.6744L103.008 18.5H103.385L103.3 18.9797L103.788 18.5H104.271L103.764 18.9831L104.071 19.6744H103.64L103.466 19.2323L103.22 19.444L103.177 19.6744H102.8Z"
      fill="#C2C2C2" />
  </g>
  <defs>
    <clipPath id="clip0_2509_1295">
      <rect width="64" height="64" fill="white" transform="translate(0 8.5)" />
    </clipPath>
    <clipPath id="clip1_2509_1295">
      <rect width="60.8" height="60.8" fill="white" transform="translate(1.6001 10.1001)" />
    </clipPath>
    <clipPath id="clip2_2509_1295">
      <rect width="80" height="80" fill="white" transform="translate(52 0.5)" />
    </clipPath>
    <clipPath id="clip3_2509_1295">
      <rect width="76" height="76" fill="white" transform="translate(54 2.5)" />
    </clipPath>
  </defs>
</svg>
