<button
  class="btn flex gap-2"
  [ngClass]="{
    primary: buttonType === 'primary',
    secondary: buttonType === 'secondary',
    tertiary: buttonType === 'tertiary',
    quaternary: buttonType === 'quaternary',
    comingSoon: buttonType === 'comingSoon',
    playNow: buttonType === 'playNow',
    smallWide: size === 'small-wide',
    small: size === 'small',
    medium: size === 'medium',
    big: size === 'big',
    'box-shadow': withShadow
  }"
  [disabled]="disabled">
  @if (!loading) {
    <ng-content></ng-content>
  }
  <ng-container *ngIf="loading">
    <kiq-loader></kiq-loader>
  </ng-container>
</button>
