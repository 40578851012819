import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { userFeature } from './+state/user.reducer';
import { UserEffects } from './+state/user.effects';
import { HttpClientModule } from '@angular/common/http';
import { UserBackendService } from './service/user-backend.service';
import { MatSnackBarModule } from '@angular/material/snack-bar';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    MatSnackBarModule,
    StoreModule.forFeature(userFeature),
    EffectsModule.forFeature([UserEffects]),
  ],
  providers: [UserBackendService],
})
export class ClientDataAccessUserModule {}
