import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  ElementRef,
  HostBinding,
  inject,
  Injector,
  OnInit,
  RendererFactory2,
  signal,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { ModalConfig, ModalRef } from '@kiq/shared/types';
import { DynamicChildLoaderDirective } from '../../directives/load-child.directive';
import { CommonModule } from '@angular/common';
import { CdkPortalOutlet } from '@angular/cdk/portal';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { slideInAndOut } from '@kiq/shared/ui/animations';
import { InGameModalService } from '../../service/in-game-modal.service';
import { MODAL_CONFIG, MODAL_DATA, MODAL_REF } from '@kiq/shared/util/modal';

@Component({
  selector: 'game-modal-wrapper',
  templateUrl: './game-modal-wrapper.component.html',
  styleUrls: ['./game-modal-wrapper.component.scss'],
  animations: [slideInAndOut],
  standalone: true,
  imports: [CommonModule, DynamicChildLoaderDirective, CdkPortalOutlet],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GameModalWrapperComponent implements OnInit, AfterViewInit {
  @HostBinding('@slideInAndOut') get hasAnimation() {
    return this.withAnimation();
  }

  @HostBinding('@.disabled') get hasAnimationDisabled() {
    return this.animationDisabled();
  }

  private animationDisabled = signal(true);
  private withAnimation = signal(false);

  @ViewChild(DynamicChildLoaderDirective, { static: true }) dynamicChildLoader!: DynamicChildLoaderDirective;
  @ViewChild('componentPortalOutlet', { static: true, read: CdkPortalOutlet }) componentPortalOutlet!: CdkPortalOutlet;
  @ViewChild('container', { static: true }) backdrop!: ElementRef<unknown>;

  private readonly injector = inject(Injector);
  private readonly cdr = inject(ChangeDetectorRef);
  private readonly destroyRef = inject(DestroyRef);
  private readonly rendererFactory = inject(RendererFactory2);
  private readonly viewContainerRef = inject(ViewContainerRef);
  private readonly modalRef: ModalRef<unknown> = inject(MODAL_REF);
  modalConfig: ModalConfig | undefined;

  modalConfig$ = inject(MODAL_CONFIG);

  ngOnInit() {
    this.modalConfig$
      .asObservable()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((config) => {
        this.modalConfig = config;

        if (this.modalConfig.useFullscreenModal) {
          this.withAnimation.set(true);
          this.animationDisabled.set(false);
        }

        if (config?.component) {
          this.createComponent(config);
        }
      });
  }

  ngAfterViewInit() {
    this.withAnimation.set(true);
    this.animationDisabled.set(false);
  }

  close() {
    if (!this.modalConfig?.useFullscreenModal) {
      this.animationDisabled.set(true);
    }

    this.modalRef.close();
  }

  createComponent(config: ModalConfig) {
    if (!config) return;

    this.dynamicChildLoader?.viewContainerRef.clear();

    const injector = Injector.create({
      parent: this.injector,
      providers: [
        {
          provide: MODAL_DATA,
          useValue: config?.data,
        },
        {
          provide: InGameModalService,
          useFactory: () => new InGameModalService(this.rendererFactory, this.componentPortalOutlet, this.backdrop),
        },
      ],
    });
    const componentRef = this.dynamicChildLoader.viewContainerRef.createComponent(config.component!, {
      injector,
    });

    // Pass inputs to the component. Ths is useful if the component requires inputs. You can pass them in the modal config.
    // Inputs are not required for the modal to work. You can also pass data via the data injection token.
    if (config?.inputs) {
      Object.keys(config.inputs).forEach((key) => {
        componentRef.setInput(key, config.inputs![key]);
      });
    }

    this.cdr.detectChanges();
  }
}
