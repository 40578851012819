import {Player} from '@kiq/shared/interfaces';

export const playersData: Array<Player> = [
  {
    jerseyNumber: 1,
    name: 'Daniel Heuer Fernandes',
    dateOfBirth: '13.11.1992',
    nationality: 'Portugal',
    height: 1.88,
    foot: 'rechts',
    teamMemberSince: '01.07.2019',
    club: 'Hamburger SV',
    previousClub: 'SV Darmstadt 98',
    uid: 'd8c92d38-cc9f-4b57-bcad-597ee7bb8df9',
    position: 'Torwart',
    league: '2. Bundesliga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 19,
    name: 'Matheo Raab',
    dateOfBirth: '18.12.1998',
    nationality: 'Deutschland',
    height: 1.87,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'Hamburger SV',
    previousClub: '1.FC Kaiserslautern',
    uid: '882ee33e-61bd-404d-8765-3f9faac8b00c',
    position: 'Torwart',
    league: '2. Bundesliga',
    marketValue: 400000,
  },
  {
    jerseyNumber: 40,
    name: 'Leo Oppermann',
    dateOfBirth: '28.08.2001',
    nationality: 'Deutschland',
    height: 1.91,
    foot: 'rechts',
    teamMemberSince: '01.07.2021',
    club: 'Hamburger SV',
    previousClub: 'Hamburger SV II',
    uid: '91e4e5b1-cfa5-400b-b211-7fa9b82361b0',
    position: 'Torwart',
    league: '2. Bundesliga',
    marketValue: 200000,
  },
  {
    jerseyNumber: 12,
    name: 'Tom Mickel',
    dateOfBirth: '19.04.1989',
    nationality: 'Deutschland',
    height: 1.85,
    foot: 'rechts',
    teamMemberSince: '01.07.2016',
    club: 'Hamburger SV',
    previousClub: ': Ablöse -',
    uid: '9b5d5da0-a504-4266-b16f-6fd96a227f12',
    position: 'Torwart',
    league: '2. Bundesliga',
    marketValue: 150000,
  },
  {
    jerseyNumber: 4,
    name: 'Sebastian Schonlau',
    dateOfBirth: '05.08.1994',
    nationality: 'Deutschland',
    height: 1.88,
    foot: 'beidfüßig',
    teamMemberSince: '01.07.2021',
    club: 'Hamburger SV',
    previousClub: 'SC Paderborn 07',
    uid: 'a10a8be8-1fcb-4200-b44a-9b4b6add1ba8',
    position: 'Innenverteidiger',
    league: '2. Bundesliga',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 16,
    name: 'Javi Montero',
    dateOfBirth: '14.01.1999',
    nationality: 'Spanien',
    height: 1.85,
    foot: 'links',
    teamMemberSince: '15.01.2023',
    club: 'Hamburger SV',
    previousClub: 'Besiktas JK',
    uid: '259fef35-9843-45c1-90d2-924e04a045f2',
    position: 'Innenverteidiger',
    league: '2. Bundesliga',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 34,
    name: 'Jonas David',
    dateOfBirth: '08.03.2000',
    nationality: 'Deutschland',
    height: 1.9,
    foot: 'rechts',
    teamMemberSince: '01.07.2018',
    club: 'Hamburger SV',
    previousClub: ': Ablöse -',
    uid: '1951118a-c170-4c65-9973-d0e88394c95d',
    position: 'Innenverteidiger',
    league: '2. Bundesliga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 37,
    name: 'Valon Zumberi',
    dateOfBirth: '24.11.2002',
    nationality: 'Kosovo',
    height: 1.87,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'Hamburger SV',
    previousClub: 'Hamburger SV II',
    uid: '07c733aa-5892-4da3-907d-8afdab8cd271',
    position: 'Innenverteidiger',
    league: '2. Bundesliga',
    marketValue: 150000,
  },
  {
    jerseyNumber: 28,
    name: 'Miro Muheim',
    dateOfBirth: '24.03.1998',
    nationality: 'Schweiz',
    height: 1.82,
    foot: 'links',
    teamMemberSince: '01.07.2022',
    club: 'Hamburger SV',
    previousClub: 'FC St. Gallen 1879',
    uid: '9a9e2d0d-2e1e-4d3c-b3da-32e988f144a5',
    position: 'Linker Verteidiger',
    league: '2. Bundesliga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 33,
    name: 'Noah Katterbach',
    dateOfBirth: '13.04.2001',
    nationality: 'Deutschland',
    height: 1.8,
    foot: 'links',
    teamMemberSince: '17.01.2023',
    club: 'Hamburger SV',
    previousClub: '1.FC Köln',
    uid: '9b4e6e63-1d02-456f-ad48-f89c4bef81a0',
    position: 'Linker Verteidiger',
    league: '2. Bundesliga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 43,
    name: 'Bent Andresen',
    dateOfBirth: '07.01.2003',
    nationality: 'Deutschland',
    height: 1.82,
    foot: 'links',
    teamMemberSince: '01.07.2022',
    club: 'Hamburger SV',
    previousClub: 'Hamburger SV II',
    uid: '7abb2668-7b56-4d66-b2d1-0035c9f2055b',
    position: 'Linker Verteidiger',
    league: '2. Bundesliga',
    marketValue: 150000,
  },
  {
    jerseyNumber: 3,
    name: 'Moritz Heyer',
    dateOfBirth: '04.04.1995',
    nationality: 'Deutschland',
    height: 1.85,
    foot: 'rechts',
    teamMemberSince: '17.09.2020',
    club: 'Hamburger SV',
    previousClub: 'VfL Osnabrück',
    uid: 'b7391992-cc5c-43c5-a055-d9a31f1d8f86',
    position: 'Rechter Verteidiger',
    league: '2. Bundesliga',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 2,
    name: 'William Mikelbrencis',
    dateOfBirth: '25.02.2004',
    nationality: 'Frankreich',
    height: 1.76,
    foot: 'rechts',
    teamMemberSince: '31.08.2022',
    club: 'Hamburger SV',
    previousClub: 'FC Metz',
    uid: 'cd6aa778-c5e3-4b59-b1c9-57ab5798b785',
    position: 'Rechter Verteidiger',
    league: '2. Bundesliga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 23,
    name: 'Jonas Meffert',
    dateOfBirth: '04.09.1994',
    nationality: 'Deutschland',
    height: 1.86,
    foot: 'rechts',
    teamMemberSince: '01.07.2021',
    club: 'Hamburger SV',
    previousClub: 'Holstein Kiel',
    uid: 'a48aa04e-14b8-4ead-ab3b-ec1d4c89aa59',
    position: 'Defensives Mittelfeld',
    league: '2. Bundesliga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 14,
    name: 'Ludovit Reis',
    dateOfBirth: '01.06.2000',
    nationality: 'Niederlande',
    height: 1.78,
    foot: 'rechts',
    teamMemberSince: '01.07.2021',
    club: 'Hamburger SV',
    previousClub: 'FC Barcelona B',
    uid: 'ab8fa51f-226f-485f-8a62-98542603d00b',
    position: 'Zentrales Mittelfeld',
    league: '2. Bundesliga',
    marketValue: 6000000,
  },
  {
    jerseyNumber: 8,
    name: 'László Bénes',
    dateOfBirth: '09.09.1997',
    nationality: 'Slowakei',
    height: 1.81,
    foot: 'links',
    teamMemberSince: '01.07.2022',
    club: 'Hamburger SV',
    previousClub: 'Borussia Mönchengladbach',
    uid: '8c5e5aef-a8f5-4290-970c-7c105af0c4de',
    position: 'Zentrales Mittelfeld',
    league: '2. Bundesliga',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 36,
    name: 'Anssi Suhonen',
    dateOfBirth: '14.01.2001',
    nationality: 'Finnland',
    height: 1.7,
    foot: 'beidfüßig',
    teamMemberSince: '01.07.2021',
    club: 'Hamburger SV',
    previousClub: 'Hamburger SV II',
    uid: '75599991-42be-431c-b27a-bc1ee67763e9',
    position: 'Zentrales Mittelfeld',
    league: '2. Bundesliga',
    marketValue: 900000,
  },
  {
    jerseyNumber: 27,
    name: 'Jean-Luc Dompé',
    dateOfBirth: '12.08.1995',
    nationality: 'Frankreich',
    height: 1.7,
    foot: 'rechts',
    teamMemberSince: '18.08.2022',
    club: 'Hamburger SV',
    previousClub: 'SV Zulte Waregem',
    uid: '612d40b9-a1ea-424e-9cd6-0a5885ab1ba1',
    position: 'Linksaußen',
    league: '2. Bundesliga',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 10,
    name: 'Sonny Kittel',
    dateOfBirth: '06.01.1993',
    nationality: 'Deutschland',
    height: 1.79,
    foot: 'beidfüßig',
    teamMemberSince: '01.07.2019',
    club: 'Hamburger SV',
    previousClub: 'FC Ingolstadt 04',
    uid: 'c1ff5dc8-862c-49fd-b57a-3226809012bb',
    position: 'Linksaußen',
    league: '2. Bundesliga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 7,
    name: 'Filip Bilbija',
    dateOfBirth: '24.04.2000',
    nationality: 'Deutschland',
    height: 1.89,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'Hamburger SV',
    previousClub: 'FC Ingolstadt 04',
    uid: 'cc696b74-6bc1-4ca8-8c86-5a28c2229ddd',
    position: 'Linksaußen',
    league: '2. Bundesliga',
    marketValue: 600000,
  },
  {
    jerseyNumber: 11,
    name: 'Ransford-Yeboah Königsdörffer',
    dateOfBirth: '13.09.2001',
    nationality: 'Ghana',
    height: 1.83,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'Hamburger SV',
    previousClub: 'SG Dynamo Dresden',
    uid: '4e6a8b08-4e77-4713-a41a-27a919bc07c5',
    position: 'Rechtsaußen',
    league: '2. Bundesliga',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 18,
    name: 'Bakery Jatta',
    dateOfBirth: '06.06.1998',
    nationality: 'Gambia',
    height: 1.84,
    foot: 'rechts',
    teamMemberSince: '01.07.2016',
    club: 'Hamburger SV',
    previousClub: 'Vereinslos',
    uid: '1312b84d-ac0b-42db-b771-23a94f132cc4',
    position: 'Rechtsaußen',
    league: '2. Bundesliga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 17,
    name: 'Xavier Amaechi',
    dateOfBirth: '05.01.2001',
    nationality: 'England',
    height: 1.79,
    foot: 'links',
    teamMemberSince: '28.07.2019',
    club: 'Hamburger SV',
    previousClub: 'FC Arsenal U23',
    uid: 'b9785f5b-b88c-4bb8-bc04-08a75a4b4cbc',
    position: 'Rechtsaußen',
    league: '2. Bundesliga',
    marketValue: 500000,
  },
  {
    jerseyNumber: 42,
    name: 'Ogechika Heil',
    dateOfBirth: '27.11.2000',
    nationality: 'Deutschland',
    height: 1.68,
    foot: 'rechts',
    teamMemberSince: '01.01.2021',
    club: 'Hamburger SV',
    previousClub: 'Hamburger SV II',
    uid: 'a86d8a0a-a0d2-4eb5-a636-40ce3205bc5f',
    position: 'Rechtsaußen',
    league: '2. Bundesliga',
    marketValue: 300000,
  },
  {
    jerseyNumber: 9,
    name: 'Robert Glatzel',
    dateOfBirth: '08.01.1994',
    nationality: 'Deutschland',
    height: 1.93,
    foot: 'beidfüßig',
    teamMemberSince: '01.07.2021',
    club: 'Hamburger SV',
    previousClub: 'Cardiff City',
    uid: '4fe93d70-cb38-464f-8a54-a3a29912a093',
    position: 'Mittelstürmer',
    league: '2. Bundesliga',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 20,
    name: 'András Németh',
    dateOfBirth: '09.11.2002',
    nationality: 'Ungarn',
    height: 1.87,
    foot: 'rechts',
    teamMemberSince: '27.01.2023',
    club: 'Hamburger SV',
    previousClub: 'KRC Genk',
    uid: '801102b4-10a8-422b-bde4-954a56f85dc3',
    position: 'Mittelstürmer',
    league: '2. Bundesliga',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 33,
    name: 'Florian Kastenmeier',
    dateOfBirth: '28.06.1997',
    nationality: 'Deutschland',
    height: 1.92,
    foot: 'rechts',
    teamMemberSince: '01.07.2019',
    club: 'Fortuna Düsseldorf',
    previousClub: 'VfB Stuttgart II',
    uid: '53ad869c-e8ab-4639-9603-0f61bec8be7c',
    position: 'Torwart',
    league: '2. Bundesliga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 1,
    name: 'Raphael Wolf',
    dateOfBirth: '06.06.1988',
    nationality: 'Deutschland',
    height: 1.9,
    foot: 'rechts',
    teamMemberSince: '01.07.2017',
    club: 'Fortuna Düsseldorf',
    previousClub: 'SV Werder Bremen',
    uid: '097addd4-f3c4-46c5-b6f3-864e3c65178e',
    position: 'Torwart',
    league: '2. Bundesliga',
    marketValue: 200000,
  },
  {
    jerseyNumber: 5,
    name: 'Christoph Klarer',
    dateOfBirth: '14.06.2000',
    nationality: 'Österreich',
    height: 1.91,
    foot: 'rechts',
    teamMemberSince: '05.10.2020',
    club: 'Fortuna Düsseldorf',
    previousClub: 'FC Southampton B',
    uid: '796c5842-a6ee-4375-af61-77b057b98703',
    position: 'Innenverteidiger',
    league: '2. Bundesliga',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 30,
    name: 'Jordy de Wijs',
    dateOfBirth: '08.01.1995',
    nationality: 'Niederlande',
    height: 1.89,
    foot: 'links',
    teamMemberSince: '01.07.2022',
    club: 'Fortuna Düsseldorf',
    previousClub: 'Queens Park Rangers',
    uid: 'bfc06c77-ec5e-4926-9da3-b77048768d89',
    position: 'Innenverteidiger',
    league: '2. Bundesliga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 3,
    name: 'Andre Hoffmann',
    dateOfBirth: '28.02.1993',
    nationality: 'Deutschland',
    height: 1.9,
    foot: 'rechts',
    teamMemberSince: '01.07.2017',
    club: 'Fortuna Düsseldorf',
    previousClub: ': Ablöse 250 Tsd. €',
    uid: '3d09d498-0989-4484-bd6e-efed3df34fdd',
    position: 'Innenverteidiger',
    league: '2. Bundesliga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 15,
    name: 'Tim Oberdorf',
    dateOfBirth: '16.08.1996',
    nationality: 'Deutschland',
    height: 1.86,
    foot: 'rechts',
    teamMemberSince: '01.01.2022',
    club: 'Fortuna Düsseldorf',
    previousClub: 'Fortuna Düsseldorf II',
    uid: '146efde3-ef45-4424-ba56-3db0f7c9a4f4',
    position: 'Innenverteidiger',
    league: '2. Bundesliga',
    marketValue: 800000,
  },
  {
    jerseyNumber: 8,
    name: 'Michal Karbownik',
    dateOfBirth: '13.03.2001',
    nationality: 'Polen',
    height: 1.75,
    foot: 'rechts',
    teamMemberSince: '04.08.2022',
    club: 'Fortuna Düsseldorf',
    previousClub: 'Brighton & Hove Albion',
    uid: 'f7c5522f-fd7f-4d95-ae34-b94492d00e0a',
    position: 'Linker Verteidiger',
    league: '2. Bundesliga',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 34,
    name: 'Nicolas Gavory',
    dateOfBirth: '16.02.1995',
    nationality: 'Frankreich',
    height: 1.83,
    foot: 'links',
    teamMemberSince: '12.01.2022',
    club: 'Fortuna Düsseldorf',
    previousClub: 'Standard Lüttich',
    uid: 'a314f0d1-5c88-4be5-b25e-1ddc79679640',
    position: 'Linker Verteidiger',
    league: '2. Bundesliga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 22,
    name: 'Benjamin Böckle',
    dateOfBirth: '17.06.2002',
    nationality: 'Österreich',
    height: 1.85,
    foot: 'links',
    teamMemberSince: '01.07.2022',
    club: 'Fortuna Düsseldorf',
    previousClub: 'FC Liefering',
    uid: '4bdd1d6b-72cd-44bd-a5a3-51967a92ce16',
    position: 'Linker Verteidiger',
    league: '2. Bundesliga',
    marketValue: 200000,
  },
  {
    jerseyNumber: 25,
    name: 'Matthias Zimmermann',
    dateOfBirth: '16.06.1992',
    nationality: 'Deutschland',
    height: 1.77,
    foot: 'rechts',
    teamMemberSince: '16.07.2018',
    club: 'Fortuna Düsseldorf',
    previousClub: 'VfB Stuttgart',
    uid: '208fc0dc-04fd-4d99-964e-f5ae97d0d8c8',
    position: 'Rechter Verteidiger',
    league: '2. Bundesliga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 41,
    name: 'Takashi Uchino',
    dateOfBirth: '07.03.2001',
    nationality: 'Japan',
    height: 1.77,
    foot: 'beidfüßig',
    teamMemberSince: '01.07.2022',
    club: 'Fortuna Düsseldorf',
    previousClub: 'Fortuna Düsseldorf II',
    uid: '4cd2a9b3-97d1-4b46-a44b-963b6d683907',
    position: 'Rechter Verteidiger',
    league: '2. Bundesliga',
    marketValue: 150000,
  },
  {
    jerseyNumber: 29,
    name: 'Jorrit Hendrix',
    dateOfBirth: '06.02.1995',
    nationality: 'Niederlande',
    height: 1.81,
    foot: 'links',
    teamMemberSince: '11.08.2022',
    club: 'Fortuna Düsseldorf',
    previousClub: 'Spartak Moskau',
    uid: 'fdce5765-0571-4489-a9bf-091add73c095',
    position: 'Defensives Mittelfeld',
    league: '2. Bundesliga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 31,
    name: 'Marcel Sobottka',
    dateOfBirth: '25.04.1994',
    nationality: 'Deutschland',
    height: 1.85,
    foot: 'rechts',
    teamMemberSince: '17.07.2015',
    club: 'Fortuna Düsseldorf',
    previousClub: 'FC Schalke 04',
    uid: '4dbbefcf-f981-4dfa-a626-13513f35a40d',
    position: 'Defensives Mittelfeld',
    league: '2. Bundesliga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 13,
    name: 'Adam Bodzek',
    dateOfBirth: '07.09.1985',
    nationality: 'Polen',
    height: 1.84,
    foot: 'rechts',
    teamMemberSince: '01.01.2011',
    club: 'Fortuna Düsseldorf',
    previousClub: ': Ablöse 250 Tsd. €',
    uid: 'a7549d92-b8fa-40c8-bc62-6b828b2c573b',
    position: 'Defensives Mittelfeld',
    league: '2. Bundesliga',
    marketValue: 200000,
  },
  {
    jerseyNumber: 4,
    name: 'Ao Tanaka',
    dateOfBirth: '10.09.1998',
    nationality: 'Japan',
    height: 1.8,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'Fortuna Düsseldorf',
    previousClub: 'Kawasaki Frontale',
    uid: 'ce0dee84-25a6-4954-ae72-d8aa77e148aa',
    position: 'Zentrales Mittelfeld',
    league: '2. Bundesliga',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 23,
    name: 'Shinta Appelkamp',
    dateOfBirth: '01.11.2000',
    nationality: 'Deutschland',
    height: 1.75,
    foot: 'rechts',
    teamMemberSince: '15.07.2020',
    club: 'Fortuna Düsseldorf',
    previousClub: 'Fortuna Düsseldorf II',
    uid: 'ea93a612-9b12-4778-94a1-641951e7769a',
    position: 'Offensives Mittelfeld',
    league: '2. Bundesliga',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 7,
    name: 'Kristoffer Peterson',
    dateOfBirth: '28.11.1994',
    nationality: 'Schweden',
    height: 1.85,
    foot: 'rechts',
    teamMemberSince: '05.10.2020',
    club: 'Fortuna Düsseldorf',
    previousClub: ': Ablöse 450 Tsd. €',
    uid: '119066be-4c66-4516-bfb0-b2cc101876f5',
    position: 'Linksaußen',
    league: '2. Bundesliga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 14,
    name: 'Kwadwo Baah',
    dateOfBirth: '27.01.2003',
    nationality: 'Deutschland',
    height: 1.82,
    foot: 'rechts',
    teamMemberSince: '29.07.2022',
    club: 'Fortuna Düsseldorf',
    previousClub: 'FC Watford',
    uid: 'a997c524-d677-4d51-8419-c3056d632b62',
    position: 'Linksaußen',
    league: '2. Bundesliga',
    marketValue: 400000,
  },
  {
    jerseyNumber: 27,
    name: 'Nana Ampomah',
    dateOfBirth: '02.01.1996',
    nationality: 'Ghana',
    height: 1.75,
    foot: 'rechts',
    teamMemberSince: '11.07.2019',
    club: 'Fortuna Düsseldorf',
    previousClub: ': Ablöse 2,80 Mio. €',
    uid: '9eb58452-4c61-462a-b365-885b3dbaf8be',
    position: 'Linksaußen',
    league: '2. Bundesliga',
    marketValue: 250000,
  },
  {
    jerseyNumber: 11,
    name: 'Felix Klaus',
    dateOfBirth: '13.09.1992',
    nationality: 'Deutschland',
    height: 1.79,
    foot: 'rechts',
    teamMemberSince: '01.07.2021',
    club: 'Fortuna Düsseldorf',
    previousClub: 'VfL Wolfsburg',
    uid: 'ac7fef46-a5d1-4162-b88e-dd85e7ced4e8',
    position: 'Rechtsaußen',
    league: '2. Bundesliga',
    marketValue: 800000,
  },
  {
    jerseyNumber: 9,
    name: 'Dawid Kownacki',
    dateOfBirth: '14.03.1997',
    nationality: 'Polen',
    height: 1.86,
    foot: 'rechts',
    teamMemberSince: '01.01.2020',
    club: 'Fortuna Düsseldorf',
    previousClub: 'Sampdoria Genua',
    uid: '64f9e5ac-f238-40a6-9931-6848e5a64065',
    position: 'Mittelstürmer',
    league: '2. Bundesliga',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 19,
    name: 'Emmanuel Iyoha',
    dateOfBirth: '11.10.1997',
    nationality: 'Deutschland',
    height: 1.91,
    foot: 'rechts',
    teamMemberSince: '01.07.2016',
    club: 'Fortuna Düsseldorf',
    previousClub: 'Fortuna Düsseldorf U19',
    uid: '63347bb7-61a6-4c99-adef-4b3143d2be83',
    position: 'Mittelstürmer',
    league: '2. Bundesliga',
    marketValue: 650000,
  },
  {
    jerseyNumber: 10,
    name: 'Daniel Ginczek',
    dateOfBirth: '13.04.1991',
    nationality: 'Deutschland',
    height: 1.91,
    foot: 'rechts',
    teamMemberSince: '29.01.2022',
    club: 'Fortuna Düsseldorf',
    previousClub: 'VfL Wolfsburg',
    uid: '41e30b1b-b72f-4506-9e6c-4c7a8f22270c',
    position: 'Mittelstürmer',
    league: '2. Bundesliga',
    marketValue: 600000,
  },
  {
    jerseyNumber: 28,
    name: 'Rouwen Hennings',
    dateOfBirth: '28.08.1987',
    nationality: 'Deutschland',
    height: 1.8,
    foot: 'links',
    teamMemberSince: '07.07.2017',
    club: 'Fortuna Düsseldorf',
    previousClub: 'FC Burnley',
    uid: 'c9dbe1e5-4f2b-401b-8792-8d49f6e01dec',
    position: 'Mittelstürmer',
    league: '2. Bundesliga',
    marketValue: 400000,
  },
  {
    jerseyNumber: 39,
    name: 'Jona Niemiec',
    dateOfBirth: '19.09.2001',
    nationality: 'Deutschland',
    height: 1.88,
    foot: 'beidfüßig',
    teamMemberSince: '01.01.2023',
    club: 'Fortuna Düsseldorf',
    previousClub: 'Fortuna Düsseldorf II',
    uid: 'ca7bbbef-c9a9-4516-9896-2b6312395011',
    position: 'Mittelstürmer',
    league: '2. Bundesliga',
    marketValue: 250000,
  },
  {
    jerseyNumber: 30,
    name: 'Peter Vindahl Jensen',
    dateOfBirth: '16.02.1998',
    nationality: 'Dänemark',
    height: 1.95,
    foot: 'rechts',
    teamMemberSince: '01.01.2023',
    club: '1.FC Nürnberg',
    previousClub: 'AZ Alkmaar',
    uid: '2d485c26-75c5-4dc0-82b0-2eeab7f82ce2',
    position: 'Torwart',
    league: '2. Bundesliga',
    marketValue: 650000,
  },
  {
    jerseyNumber: 26,
    name: 'Christian Mathenia',
    dateOfBirth: '31.03.1992',
    nationality: 'Deutschland',
    height: 1.89,
    foot: 'rechts',
    teamMemberSince: '01.07.2018',
    club: '1.FC Nürnberg',
    previousClub: ': Ablöse 500 Tsd. €',
    uid: '5ae7bf8d-eb41-45a6-ba15-9ddfa1eb99d6',
    position: 'Torwart',
    league: '2. Bundesliga',
    marketValue: 600000,
  },
  {
    jerseyNumber: 1,
    name: 'Carl Klaus',
    dateOfBirth: '16.01.1994',
    nationality: 'Deutschland',
    height: 1.89,
    foot: 'rechts',
    teamMemberSince: '01.07.2021',
    club: '1.FC Nürnberg',
    previousClub: 'SV Darmstadt 98',
    uid: 'bb212f69-13d9-4f64-8fe0-b9b1ff23477d',
    position: 'Torwart',
    league: '2. Bundesliga',
    marketValue: 200000,
  },
  {
    jerseyNumber: 3,
    name: 'Sadik Fofana',
    dateOfBirth: '16.05.2003',
    nationality: 'Deutschland',
    height: 1.92,
    foot: 'rechts',
    teamMemberSince: '02.07.2022',
    club: '1.FC Nürnberg',
    previousClub: 'Bayer 04 Leverkusen',
    uid: '0dfded6a-670d-4a00-b25e-7db370f52d0c',
    position: 'Innenverteidiger',
    league: '2. Bundesliga',
    marketValue: 800000,
  },
  {
    jerseyNumber: 4,
    name: 'James Lawrence',
    dateOfBirth: '22.08.1992',
    nationality: 'Wales',
    height: 1.88,
    foot: 'links',
    teamMemberSince: '22.07.2022',
    club: '1.FC Nürnberg',
    previousClub: 'FC St. Pauli',
    uid: '7327cd1a-9d6e-4a42-a5b0-d5156a8ba018',
    position: 'Innenverteidiger',
    league: '2. Bundesliga',
    marketValue: 800000,
  },
  {
    jerseyNumber: 16,
    name: 'Christopher Schindler',
    dateOfBirth: '29.04.1990',
    nationality: 'Deutschland',
    height: 1.88,
    foot: 'rechts',
    teamMemberSince: '01.07.2021',
    club: '1.FC Nürnberg',
    previousClub: 'Huddersfield Town',
    uid: '863e49c9-6227-4124-aea9-1b0359fa7890',
    position: 'Innenverteidiger',
    league: '2. Bundesliga',
    marketValue: 700000,
  },
  {
    jerseyNumber: 19,
    name: 'Florian Hübner',
    dateOfBirth: '01.03.1991',
    nationality: 'Deutschland',
    height: 1.93,
    foot: 'rechts',
    teamMemberSince: '01.07.2021',
    club: '1.FC Nürnberg',
    previousClub: '1.FC Union Berlin',
    uid: 'aa4563ee-47bf-4290-8a16-067d9dc79fff',
    position: 'Innenverteidiger',
    league: '2. Bundesliga',
    marketValue: 400000,
  },
  {
    jerseyNumber: 32,
    name: 'Louis Breunig',
    dateOfBirth: '14.11.2003',
    nationality: 'Deutschland',
    height: 1.88,
    foot: 'beidfüßig',
    teamMemberSince: '01.07.2022',
    club: '1.FC Nürnberg',
    previousClub: 'Würzburger Kickers',
    uid: '8c2b3c92-9cd2-4eb4-8262-dc2d2d2b3ffc',
    position: 'Innenverteidiger',
    league: '2. Bundesliga',
    marketValue: 150000,
  },
  {
    jerseyNumber: 29,
    name: 'Tim Handwerker',
    dateOfBirth: '19.05.1998',
    nationality: 'Deutschland',
    height: 1.82,
    foot: 'links',
    teamMemberSince: '04.07.2019',
    club: '1.FC Nürnberg',
    previousClub: '1.FC Köln',
    uid: '9f47b3f3-22f5-4a02-b940-b9d9033355ef',
    position: 'Linker Verteidiger',
    league: '2. Bundesliga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 38,
    name: 'Jannes Horn',
    dateOfBirth: '06.02.1997',
    nationality: 'Deutschland',
    height: 1.86,
    foot: 'links',
    teamMemberSince: '07.01.2023',
    club: '1.FC Nürnberg',
    previousClub: 'VfL Bochum',
    uid: 'bcec0bc8-ea42-41f6-b6b8-033737296039',
    position: 'Linker Verteidiger',
    league: '2. Bundesliga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 13,
    name: 'Erik Wekesser',
    dateOfBirth: '03.07.1997',
    nationality: 'Deutschland',
    height: 1.86,
    foot: 'links',
    teamMemberSince: '01.07.2022',
    club: '1.FC Nürnberg',
    previousClub: 'SSV Jahn Regensburg',
    uid: '0ccb3b57-3ddb-451c-8ee8-44f03fa2b9b5',
    position: 'Linker Verteidiger',
    league: '2. Bundesliga',
    marketValue: 800000,
  },
  {
    jerseyNumber: 35,
    name: 'Nathaniel Brown',
    dateOfBirth: '16.06.2003',
    nationality: 'Deutschland',
    height: 1.8,
    foot: 'links',
    teamMemberSince: '01.07.2022',
    club: '1.FC Nürnberg',
    previousClub: '1.FC Nürnberg U19',
    uid: '536d5f6c-3617-4ae9-835a-a6575a6becdb',
    position: 'Linker Verteidiger',
    league: '2. Bundesliga',
    marketValue: 200000,
  },
  {
    jerseyNumber: 28,
    name: 'Jan Gyamerah',
    dateOfBirth: '18.06.1995',
    nationality: 'Ghana',
    height: 1.84,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: '1.FC Nürnberg',
    previousClub: 'Hamburger SV',
    uid: '252c85bd-d724-454f-82bd-44121d85ccc6',
    position: 'Rechter Verteidiger',
    league: '2. Bundesliga',
    marketValue: 800000,
  },
  {
    jerseyNumber: 22,
    name: 'Enrico Valentini',
    dateOfBirth: '20.02.1989',
    nationality: 'Deutschland',
    height: 1.82,
    foot: 'rechts',
    teamMemberSince: '01.07.2017',
    club: '1.FC Nürnberg',
    previousClub: 'Karlsruher SC',
    uid: '88818f06-2fbb-4fff-ae7b-08bb2b6372bc',
    position: 'Rechter Verteidiger',
    league: '2. Bundesliga',
    marketValue: 300000,
  },
  {
    jerseyNumber: 21,
    name: 'Florian Flick',
    dateOfBirth: '01.05.2000',
    nationality: 'Deutschland',
    height: 1.9,
    foot: 'beidfüßig',
    teamMemberSince: '01.01.2023',
    club: '1.FC Nürnberg',
    previousClub: 'FC Schalke 04',
    uid: 'be241ec8-cd5a-450f-a688-4f3841103157',
    position: 'Defensives Mittelfeld',
    league: '2. Bundesliga',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 5,
    name: 'Johannes Geis',
    dateOfBirth: '17.08.1993',
    nationality: 'Deutschland',
    height: 1.81,
    foot: 'rechts',
    teamMemberSince: '05.08.2019',
    club: '1.FC Nürnberg',
    previousClub: '1.FC Köln',
    uid: 'c996abcf-bde3-436e-8d39-c30d4a4eaace',
    position: 'Defensives Mittelfeld',
    league: '2. Bundesliga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 6,
    name: 'Lino Tempelmann',
    dateOfBirth: '02.02.1999',
    nationality: 'Deutschland',
    height: 1.74,
    foot: 'rechts',
    teamMemberSince: '01.07.2021',
    club: '1.FC Nürnberg',
    previousClub: 'SC Freiburg',
    uid: 'ee16c737-1dc9-4a40-910a-02f74c100fe6',
    position: 'Zentrales Mittelfeld',
    league: '2. Bundesliga',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 24,
    name: 'Gustavo Puerta',
    dateOfBirth: '23.07.2003',
    nationality: 'Kolumbien',
    height: 1.71,
    foot: 'rechts',
    teamMemberSince: '31.01.2023',
    club: '1.FC Nürnberg',
    previousClub: 'Bayer 04 Leverkusen',
    uid: 'a02600b6-027d-473a-b67b-15229159ef79',
    position: 'Zentrales Mittelfeld',
    league: '2. Bundesliga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 15,
    name: 'Fabian Nürnberger',
    dateOfBirth: '28.07.1999',
    nationality: 'Deutschland',
    height: 1.81,
    foot: 'links',
    teamMemberSince: '01.07.2019',
    club: '1.FC Nürnberg',
    previousClub: '1.FC Nürnberg II',
    uid: 'a6489d44-0c8b-4afb-a573-6bfebea13495',
    position: 'Zentrales Mittelfeld',
    league: '2. Bundesliga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 8,
    name: 'Taylan Duman',
    dateOfBirth: '30.07.1997',
    nationality: 'Deutschland',
    height: 1.8,
    foot: 'rechts',
    teamMemberSince: '01.07.2021',
    club: '1.FC Nürnberg',
    previousClub: 'Borussia Dortmund II',
    uid: 'b30f11db-ef24-482d-acdd-9df2319d77de',
    position: 'Zentrales Mittelfeld',
    league: '2. Bundesliga',
    marketValue: 700000,
  },
  {
    jerseyNumber: 17,
    name: 'Jens Castrop',
    dateOfBirth: '29.07.2003',
    nationality: 'Deutschland',
    height: 1.78,
    foot: 'rechts',
    teamMemberSince: '07.01.2022',
    club: '1.FC Nürnberg',
    previousClub: '1.FC Köln II',
    uid: 'f5e34762-6888-42c2-a316-7b1c38a7e5f2',
    position: 'Rechtes Mittelfeld',
    league: '2. Bundesliga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 10,
    name: 'Mats Möller Daehli',
    dateOfBirth: '02.03.1995',
    nationality: 'Norwegen',
    height: 1.72,
    foot: 'rechts',
    teamMemberSince: '01.07.2021',
    club: '1.FC Nürnberg',
    previousClub: 'KRC Genk',
    uid: '86fb8b77-11c8-42c6-9726-ff9b7c89438c',
    position: 'Offensives Mittelfeld',
    league: '2. Bundesliga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 9,
    name: 'Danny Blum',
    dateOfBirth: '07.01.1991',
    nationality: 'Deutschland',
    height: 1.84,
    foot: 'links',
    teamMemberSince: '20.01.2023',
    club: '1.FC Nürnberg',
    previousClub: 'APOEL Nikosia',
    uid: '261aeeb3-877f-4223-a1fb-8ed3a59e6ef7',
    position: 'Linksaußen',
    league: '2. Bundesliga',
    marketValue: 500000,
  },
  {
    jerseyNumber: 14,
    name: 'Benjamin Goller',
    dateOfBirth: '01.01.1999',
    nationality: 'Deutschland',
    height: 1.8,
    foot: 'rechts',
    teamMemberSince: '01.01.2023',
    club: '1.FC Nürnberg',
    previousClub: 'SV Werder Bremen',
    uid: 'c8153de2-5916-4021-96c1-fd26010d5775',
    position: 'Rechtsaußen',
    league: '2. Bundesliga',
    marketValue: 450000,
  },
  {
    jerseyNumber: 25,
    name: 'Shawn Blum',
    dateOfBirth: '14.01.2003',
    nationality: 'Deutschland',
    height: 1.82,
    foot: 'beidfüßig',
    teamMemberSince: '01.07.2022',
    club: '1.FC Nürnberg',
    previousClub: '1.FC Kaiserslautern U19',
    uid: 'b865064d-6589-4a60-a383-818985f42f0d',
    position: 'Rechtsaußen',
    league: '2. Bundesliga',
    marketValue: 150000,
  },
  {
    jerseyNumber: 36,
    name: 'Lukas Schleimer',
    dateOfBirth: '09.12.1999',
    nationality: 'Deutschland',
    height: 1.84,
    foot: 'rechts',
    teamMemberSince: '30.03.2022',
    club: '1.FC Nürnberg',
    previousClub: '1.FC Nürnberg II',
    uid: '84db44f6-fae3-46ff-90b6-3e5534fbb263',
    position: 'Hängende Spitze',
    league: '2. Bundesliga',
    marketValue: 500000,
  },
  {
    jerseyNumber: 23,
    name: 'Kwadwo Duah',
    dateOfBirth: '24.02.1997',
    nationality: 'Schweiz',
    height: 1.85,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: '1.FC Nürnberg',
    previousClub: 'FC St. Gallen 1879',
    uid: '79506eb0-e9e0-41ee-81ab-6f222fd867b0',
    position: 'Mittelstürmer',
    league: '2. Bundesliga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 11,
    name: 'Erik Shuranov',
    dateOfBirth: '22.02.2002',
    nationality: 'Deutschland',
    height: 1.84,
    foot: 'beidfüßig',
    teamMemberSince: '01.07.2021',
    club: '1.FC Nürnberg',
    previousClub: '1.FC Nürnberg II',
    uid: '4f533f20-a58c-4d91-a3dd-74d8c1f3ce88',
    position: 'Mittelstürmer',
    league: '2. Bundesliga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 33,
    name: 'Christoph Daferner',
    dateOfBirth: '12.01.1998',
    nationality: 'Deutschland',
    height: 1.89,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: '1.FC Nürnberg',
    previousClub: 'SG Dynamo Dresden',
    uid: 'd7b15bfd-e7ea-48c9-864a-c013ee2037cf',
    position: 'Mittelstürmer',
    league: '2. Bundesliga',
    marketValue: 900000,
  },
  {
    jerseyNumber: 20,
    name: 'Pascal Köpke',
    dateOfBirth: '03.09.1995',
    nationality: 'Deutschland',
    height: 1.78,
    foot: 'rechts',
    teamMemberSince: '29.08.2020',
    club: '1.FC Nürnberg',
    previousClub: 'Hertha BSC',
    uid: 'fb2dfc1e-869d-4ec5-a33e-33871e594162',
    position: 'Mittelstürmer',
    league: '2. Bundesliga',
    marketValue: 550000,
  },
  {
    jerseyNumber: 7,
    name: 'Felix Lohkemper',
    dateOfBirth: '26.01.1995',
    nationality: 'Deutschland',
    height: 1.8,
    foot: 'rechts',
    teamMemberSince: '01.07.2019',
    club: '1.FC Nürnberg',
    previousClub: '1.FC Magdeburg',
    uid: '58a2f66b-58ea-40d8-a786-6de05becdd47',
    position: 'Mittelstürmer',
    league: '2. Bundesliga',
    marketValue: 450000,
  },
  {
    jerseyNumber: 27,
    name: 'Jermain Nischalke',
    dateOfBirth: '26.03.2003',
    nationality: 'Angola',
    height: 1.93,
    foot: 'rechts',
    teamMemberSince: '15.12.2022',
    club: '1.FC Nürnberg',
    previousClub: '1.FC Nürnberg II',
    uid: '77973938-2f1b-4e2a-800c-59025cc76519',
    position: 'Mittelstürmer',
    league: '2. Bundesliga',
    marketValue: 400000,
  },
  {
    jerseyNumber: 1,
    name: 'Marcel Schuhen',
    dateOfBirth: '13.01.1993',
    nationality: 'Deutschland',
    height: 1.88,
    foot: 'beidfüßig',
    teamMemberSince: '01.07.2019',
    club: 'SV Darmstadt 98',
    previousClub: ': Ablöse ablösefrei',
    uid: '5e380013-a053-4044-bb19-7b00e8d2f175',
    position: 'Torwart',
    league: '2. Bundesliga',
    marketValue: 700000,
  },
  {
    jerseyNumber: 30,
    name: 'Alexander Brunst',
    dateOfBirth: '07.07.1995',
    nationality: 'Deutschland',
    height: 1.95,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'SV Darmstadt 98',
    previousClub: 'Vejle Boldklub',
    uid: '3eaa8390-0e23-4e0a-908d-19052996be1b',
    position: 'Torwart',
    league: '2. Bundesliga',
    marketValue: 200000,
  },
  {
    jerseyNumber: 21,
    name: 'Steve Kroll',
    dateOfBirth: '07.05.1997',
    nationality: 'Deutschland',
    height: 1.91,
    foot: 'rechts',
    teamMemberSince: '01.07.2021',
    club: 'SV Darmstadt 98',
    previousClub: 'SpVgg Unterhaching',
    uid: '5503362d-bf4d-4dda-9f42-c105b6d2e3a0',
    position: 'Torwart',
    league: '2. Bundesliga',
    marketValue: 125000,
  },
  {
    jerseyNumber: 5,
    name: 'Patric Pfeiffer',
    dateOfBirth: '20.08.1999',
    nationality: 'Ghana',
    height: 1.96,
    foot: 'rechts',
    teamMemberSince: '01.07.2019',
    club: 'SV Darmstadt 98',
    previousClub: ': Ablöse 250 Tsd. €',
    uid: '06b4b8d0-dbb3-4583-8a47-e4d76022b6d1',
    position: 'Innenverteidiger',
    league: '2. Bundesliga',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 4,
    name: 'Christoph Zimmermann',
    dateOfBirth: '12.01.1993',
    nationality: 'Deutschland',
    height: 1.94,
    foot: 'rechts',
    teamMemberSince: '20.07.2022',
    club: 'SV Darmstadt 98',
    previousClub: 'Norwich City',
    uid: 'f5c39156-1e21-4f9b-a650-19cf7fb40db8',
    position: 'Innenverteidiger',
    league: '2. Bundesliga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 3,
    name: 'Thomas Isherwood',
    dateOfBirth: '28.01.1998',
    nationality: 'Schweden',
    height: 1.95,
    foot: 'links',
    teamMemberSince: '03.01.2021',
    club: 'SV Darmstadt 98',
    previousClub: 'Östersunds FK',
    uid: '49af8589-eb90-4f19-9689-4d8e242a1ef9',
    position: 'Innenverteidiger',
    league: '2. Bundesliga',
    marketValue: 900000,
  },
  {
    jerseyNumber: 38,
    name: 'Clemens Riedel',
    dateOfBirth: '19.07.2003',
    nationality: 'Deutschland',
    height: 1.86,
    foot: 'rechts',
    teamMemberSince: '01.07.2021',
    club: 'SV Darmstadt 98',
    previousClub: 'SV Darmstadt 98 U19',
    uid: 'fc8a9162-17f9-43ea-8ba8-bf5e5541cd5c',
    position: 'Innenverteidiger',
    league: '2. Bundesliga',
    marketValue: 900000,
  },
  {
    jerseyNumber: 20,
    name: 'Jannik Müller',
    dateOfBirth: '18.01.1994',
    nationality: 'Deutschland',
    height: 1.87,
    foot: 'rechts',
    teamMemberSince: '01.07.2021',
    club: 'SV Darmstadt 98',
    previousClub: 'DAC Dunajska Streda',
    uid: '0a817760-ddd2-4c33-9d84-07fffa0b0d0c',
    position: 'Innenverteidiger',
    league: '2. Bundesliga',
    marketValue: 700000,
  },
  {
    jerseyNumber: 32,
    name: 'Fabian Holland',
    dateOfBirth: '11.07.1990',
    nationality: 'Deutschland',
    height: 1.72,
    foot: 'links',
    teamMemberSince: '05.07.2015',
    club: 'SV Darmstadt 98',
    previousClub: ': Ablöse 500 Tsd. €',
    uid: '86ec15dc-60ae-477d-b40d-c948956f1187',
    position: 'Linker Verteidiger',
    league: '2. Bundesliga',
    marketValue: 600000,
  },
  {
    jerseyNumber: 19,
    name: 'Emir Karic',
    dateOfBirth: '09.06.1997',
    nationality: 'Österreich',
    height: 1.84,
    foot: 'links',
    teamMemberSince: '01.07.2021',
    club: 'SV Darmstadt 98',
    previousClub: 'SCR Altach',
    uid: '426984f5-34ff-4acb-be62-ef18eef248cc',
    position: 'Linker Verteidiger',
    league: '2. Bundesliga',
    marketValue: 550000,
  },
  {
    jerseyNumber: 26,
    name: 'Matthias Bader',
    dateOfBirth: '17.06.1997',
    nationality: 'Deutschland',
    height: 1.76,
    foot: 'rechts',
    teamMemberSince: '31.01.2020',
    club: 'SV Darmstadt 98',
    previousClub: '1.FC Köln',
    uid: 'b9f90507-660e-4511-b368-494fe3f5de8c',
    position: 'Rechter Verteidiger',
    league: '2. Bundesliga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 17,
    name: 'Frank Ronstadt',
    dateOfBirth: '21.07.1997',
    nationality: 'Deutschland',
    height: 1.79,
    foot: 'rechts',
    teamMemberSince: '01.07.2021',
    club: 'SV Darmstadt 98',
    previousClub: 'Würzburger Kickers',
    uid: 'ed69ffe7-30aa-4016-a708-683ba60f3b7d',
    position: 'Rechter Verteidiger',
    league: '2. Bundesliga',
    marketValue: 550000,
  },
  {
    jerseyNumber: 23,
    name: 'Klaus Gjasula',
    dateOfBirth: '14.12.1989',
    nationality: 'Albanien',
    height: 1.9,
    foot: 'beidfüßig',
    teamMemberSince: '11.08.2021',
    club: 'SV Darmstadt 98',
    previousClub: 'Hamburger SV',
    uid: 'c75a295f-bc79-4e7a-b9bb-088825c49f27',
    position: 'Defensives Mittelfeld',
    league: '2. Bundesliga',
    marketValue: 500000,
  },
  {
    jerseyNumber: 28,
    name: 'Yassin Ben Balla',
    dateOfBirth: '24.02.1996',
    nationality: 'Frankreich',
    height: 1.8,
    foot: 'rechts',
    teamMemberSince: '02.07.2022',
    club: 'SV Darmstadt 98',
    previousClub: 'FC Ingolstadt 04',
    uid: 'c4bfc71d-47e1-429f-9414-7d77db0a993b',
    position: 'Defensives Mittelfeld',
    league: '2. Bundesliga',
    marketValue: 400000,
  },
  {
    jerseyNumber: 8,
    name: 'Fabian Schnellhardt',
    dateOfBirth: '12.01.1994',
    nationality: 'Deutschland',
    height: 1.8,
    foot: 'links',
    teamMemberSince: '01.07.2019',
    club: 'SV Darmstadt 98',
    previousClub: ': Ablöse ?',
    uid: '0e0d8e3b-b2a1-4abd-a8a9-21750fb3a83c',
    position: 'Zentrales Mittelfeld',
    league: '2. Bundesliga',
    marketValue: 750000,
  },
  {
    jerseyNumber: 11,
    name: 'Tobias Kempe',
    dateOfBirth: '27.06.1989',
    nationality: 'Deutschland',
    height: 1.84,
    foot: 'rechts',
    teamMemberSince: '07.07.2017',
    club: 'SV Darmstadt 98',
    previousClub: '1.FC Nürnberg',
    uid: '428ea009-94c2-473b-a180-f1798f6b4f88',
    position: 'Zentrales Mittelfeld',
    league: '2. Bundesliga',
    marketValue: 500000,
  },
  {
    jerseyNumber: 16,
    name: 'Keanan Bennetts',
    dateOfBirth: '09.03.1999',
    nationality: 'England',
    height: 1.81,
    foot: 'links',
    teamMemberSince: '21.10.2022',
    club: 'SV Darmstadt 98',
    previousClub: 'Vereinslos',
    uid: '8d900c04-d1da-4838-be07-fa81217f962e',
    position: 'Linkes Mittelfeld',
    league: '2. Bundesliga',
    marketValue: 600000,
  },
  {
    jerseyNumber: 6,
    name: 'Marvin Mehlem',
    dateOfBirth: '11.09.1997',
    nationality: 'Deutschland',
    height: 1.74,
    foot: 'rechts',
    teamMemberSince: '01.07.2017',
    club: 'SV Darmstadt 98',
    previousClub: 'Karlsruher SC',
    uid: 'ba10e47b-5c0c-436a-b434-9dc65277b556',
    position: 'Offensives Mittelfeld',
    league: '2. Bundesliga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 18,
    name: 'Mathias Honsak',
    dateOfBirth: '20.12.1996',
    nationality: 'Österreich',
    height: 1.88,
    foot: 'links',
    teamMemberSince: '05.07.2019',
    club: 'SV Darmstadt 98',
    previousClub: 'Red Bull Salzburg',
    uid: '0a21e69a-9db4-48f0-a16e-0089490646d3',
    position: 'Linksaußen',
    league: '2. Bundesliga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 14,
    name: 'Magnus Warming',
    dateOfBirth: '08.06.2000',
    nationality: 'Dänemark',
    height: 1.88,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'SV Darmstadt 98',
    previousClub: 'FC Turin',
    uid: '326e931f-00f4-4b8c-8de9-d466a137e5fb',
    position: 'Linksaußen',
    league: '2. Bundesliga',
    marketValue: 800000,
  },
  {
    jerseyNumber: 9,
    name: 'Phillip Tietz',
    dateOfBirth: '09.07.1997',
    nationality: 'Deutschland',
    height: 1.9,
    foot: 'rechts',
    teamMemberSince: '01.07.2021',
    club: 'SV Darmstadt 98',
    previousClub: 'SV Wehen Wiesbaden',
    uid: '93a717a0-b73d-4fe4-8f08-5301b1cd7e51',
    position: 'Mittelstürmer',
    league: '2. Bundesliga',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 29,
    name: 'Oscar Vilhelmsson',
    dateOfBirth: '02.10.2003',
    nationality: 'Schweden',
    height: 1.88,
    foot: 'links',
    teamMemberSince: '12.07.2022',
    club: 'SV Darmstadt 98',
    previousClub: 'IFK Göteborg',
    uid: '14bba4d6-5fdc-4375-acf6-a7d613509ee3',
    position: 'Mittelstürmer',
    league: '2. Bundesliga',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 40,
    name: 'Filip Stojilkovic',
    dateOfBirth: '04.01.2000',
    nationality: 'Schweiz',
    height: 1.86,
    foot: 'rechts',
    teamMemberSince: '31.01.2023',
    club: 'SV Darmstadt 98',
    previousClub: 'FC Sion',
    uid: '6afd0615-5cfe-4a0f-89da-d96efea1d284',
    position: 'Mittelstürmer',
    league: '2. Bundesliga',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 7,
    name: 'Braydon Manu',
    dateOfBirth: '28.03.1997',
    nationality: 'Ghana',
    height: 1.7,
    foot: 'beidfüßig',
    teamMemberSince: '01.07.2019',
    club: 'SV Darmstadt 98',
    previousClub: 'Hallescher FC',
    uid: 'e54a4c74-8b2c-429b-a3a8-cfe79e8fef3b',
    position: 'Mittelstürmer',
    league: '2. Bundesliga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 22,
    name: 'Aaron Seydel',
    dateOfBirth: '07.02.1996',
    nationality: 'Deutschland',
    height: 1.99,
    foot: 'links',
    teamMemberSince: '06.08.2020',
    club: 'SV Darmstadt 98',
    previousClub: '1.FSV Mainz 05',
    uid: '84cea911-aa2b-499b-96fc-90b18620f196',
    position: 'Mittelstürmer',
    league: '2. Bundesliga',
    marketValue: 600000,
  },
  {
    jerseyNumber: 42,
    name: 'Fabio Torsiello',
    dateOfBirth: '02.02.2005',
    nationality: 'Deutschland',
    height: 1.77,
    foot: 'rechts',
    teamMemberSince: '06.12.2022',
    club: 'SV Darmstadt 98',
    previousClub: 'SV Darmstadt 98 U19',
    uid: 'afa6af92-c343-488f-ad34-903d514d4a85',
    position: 'Mittelstürmer',
    league: '2. Bundesliga',
    marketValue: 200000,
  },
  {
    jerseyNumber: 22,
    name: 'Nikola Vasilj',
    dateOfBirth: '02.12.1995',
    nationality: 'Bosnien-Herzegowina',
    height: 1.93,
    foot: 'rechts',
    teamMemberSince: '01.07.2021',
    club: 'FC St. Pauli',
    previousClub: ': Ablöse ablösefrei',
    uid: '0434052c-ea02-4b66-a663-f57278f58ba1',
    position: 'Torwart',
    league: '2. Bundesliga',
    marketValue: 800000,
  },
  {
    jerseyNumber: 1,
    name: 'Dennis Smarsch',
    dateOfBirth: '14.01.1999',
    nationality: 'Deutschland',
    height: 1.95,
    foot: 'beidfüßig',
    teamMemberSince: '02.08.2020',
    club: 'FC St. Pauli',
    previousClub: 'Hertha BSC',
    uid: '99fc0cea-87a3-4d3c-9196-e141144694bc',
    position: 'Torwart',
    league: '2. Bundesliga',
    marketValue: 200000,
  },
  {
    jerseyNumber: 30,
    name: 'Sascha Burchert',
    dateOfBirth: '30.10.1989',
    nationality: 'Deutschland',
    height: 1.87,
    foot: 'rechts',
    teamMemberSince: '08.08.2022',
    club: 'FC St. Pauli',
    previousClub: 'SpVgg Greuther Fürth',
    uid: '22ff6c5d-e5db-4d4d-ad8a-4e0703f2f945',
    position: 'Torwart',
    league: '2. Bundesliga',
    marketValue: 200000,
  },
  {
    jerseyNumber: 28,
    name: 'Sören Ahlers',
    dateOfBirth: '09.09.1997',
    nationality: 'Deutschland',
    height: 1.94,
    foot: 'beidfüßig',
    teamMemberSince: '31.08.2021',
    club: 'FC St. Pauli',
    previousClub: 'FC Schalke 04 II',
    uid: 'aa74c523-7249-4ab2-a266-5303d92996b1',
    position: 'Torwart',
    league: '2. Bundesliga',
    marketValue: 50000,
  },
  {
    jerseyNumber: 4,
    name: 'David Nemeth',
    dateOfBirth: '18.03.2001',
    nationality: 'Österreich',
    height: 1.91,
    foot: 'beidfüßig',
    teamMemberSince: '01.07.2022',
    club: 'FC St. Pauli',
    previousClub: '1.FSV Mainz 05',
    uid: '19edc05c-d892-475b-866c-8627a742b97a',
    position: 'Innenverteidiger',
    league: '2. Bundesliga',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 18,
    name: 'Jakov Medic',
    dateOfBirth: '07.09.1998',
    nationality: 'Kroatien',
    height: 1.93,
    foot: 'rechts',
    teamMemberSince: '01.07.2021',
    club: 'FC St. Pauli',
    previousClub: 'SV Wehen Wiesbaden',
    uid: 'a2c6f3af-d2b1-4369-b8cc-bcf68b663bc0',
    position: 'Innenverteidiger',
    league: '2. Bundesliga',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 5,
    name: 'Betim Fazliji',
    dateOfBirth: '25.04.1999',
    nationality: 'Kosovo',
    height: 1.86,
    foot: 'rechts',
    teamMemberSince: '15.07.2022',
    club: 'FC St. Pauli',
    previousClub: 'FC St. Gallen 1879',
    uid: '972acc4f-fb57-4824-a4b5-efe6fba1d312',
    position: 'Innenverteidiger',
    league: '2. Bundesliga',
    marketValue: 800000,
  },
  {
    jerseyNumber: 3,
    name: 'Karol Mets',
    dateOfBirth: '16.05.1993',
    nationality: 'Estland',
    height: 1.91,
    foot: 'links',
    teamMemberSince: '05.01.2023',
    club: 'FC St. Pauli',
    previousClub: 'FC Zürich',
    uid: 'ba59905b-48ca-4c76-a68c-bf7302dbeffd',
    position: 'Innenverteidiger',
    league: '2. Bundesliga',
    marketValue: 700000,
  },
  {
    jerseyNumber: 15,
    name: 'Marcel Beifus',
    dateOfBirth: '27.10.2002',
    nationality: 'Deutschland',
    height: 1.87,
    foot: 'rechts',
    teamMemberSince: '30.08.2021',
    club: 'FC St. Pauli',
    previousClub: 'VfL Wolfsburg',
    uid: 'b48d7996-58a8-44ad-8d81-ea5e7f12fae6',
    position: 'Innenverteidiger',
    league: '2. Bundesliga',
    marketValue: 400000,
  },
  {
    jerseyNumber: 25,
    name: 'Adam Dzwigala',
    dateOfBirth: '25.09.1995',
    nationality: 'Polen',
    height: 1.85,
    foot: 'rechts',
    teamMemberSince: '17.12.2020',
    club: 'FC St. Pauli',
    previousClub: 'Vereinslos',
    uid: '1aa169a6-748c-49a5-8578-ee0230410533',
    position: 'Innenverteidiger',
    league: '2. Bundesliga',
    marketValue: 400000,
  },
  {
    jerseyNumber: 6,
    name: 'Christopher Avevor',
    dateOfBirth: '11.02.1992',
    nationality: 'Deutschland',
    height: 1.85,
    foot: 'rechts',
    teamMemberSince: '01.07.2016',
    club: 'FC St. Pauli',
    previousClub: 'Fortuna Düsseldorf',
    uid: '0c7d6b0f-2e96-4a03-ab46-fbfcd4d6f6df',
    position: 'Innenverteidiger',
    league: '2. Bundesliga',
    marketValue: 100000,
  },
  {
    jerseyNumber: 23,
    name: 'Leart Paqarada',
    dateOfBirth: '08.10.1994',
    nationality: 'Kosovo',
    height: 1.84,
    foot: 'links',
    teamMemberSince: '02.08.2020',
    club: 'FC St. Pauli',
    previousClub: 'SV Sandhausen',
    uid: 'f7b6fd1a-162c-41f3-98cd-768f98eb3b72',
    position: 'Linker Verteidiger',
    league: '2. Bundesliga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 21,
    name: 'Lars Ritzka',
    dateOfBirth: '07.05.1998',
    nationality: 'Deutschland',
    height: 1.85,
    foot: 'links',
    teamMemberSince: '01.07.2021',
    club: 'FC St. Pauli',
    previousClub: 'SC Verl',
    uid: '844d9821-4cf3-46a9-8141-7cb57d647add',
    position: 'Linker Verteidiger',
    league: '2. Bundesliga',
    marketValue: 300000,
  },
  {
    jerseyNumber: 2,
    name: 'Manolis Saliakas',
    dateOfBirth: '12.09.1996',
    nationality: 'Griechenland',
    height: 1.77,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'FC St. Pauli',
    previousClub: 'PAS Giannina',
    uid: 'f0033799-696c-4f09-b840-8d53722a0b00',
    position: 'Rechter Verteidiger',
    league: '2. Bundesliga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 19,
    name: 'Luca Zander',
    dateOfBirth: '09.08.1995',
    nationality: 'Deutschland',
    height: 1.83,
    foot: 'rechts',
    teamMemberSince: '01.07.2019',
    club: 'FC St. Pauli',
    previousClub: 'SV Werder Bremen II',
    uid: '62cdf3fd-ff2b-47b7-8d3d-b950b4b2df4f',
    position: 'Rechter Verteidiger',
    league: '2. Bundesliga',
    marketValue: 500000,
  },
  {
    jerseyNumber: 32,
    name: 'Jannes Wieckhoff',
    dateOfBirth: '02.08.2000',
    nationality: 'Deutschland',
    height: 1.88,
    foot: 'rechts',
    teamMemberSince: '30.07.2020',
    club: 'FC St. Pauli',
    previousClub: 'FC St. Pauli II',
    uid: 'f90951f6-1cac-49fc-be5a-f3c6dbc20e79',
    position: 'Rechter Verteidiger',
    league: '2. Bundesliga',
    marketValue: 200000,
  },
  {
    jerseyNumber: 8,
    name: 'Eric Smith',
    dateOfBirth: '08.01.1997',
    nationality: 'Schweden',
    height: 1.92,
    foot: 'rechts',
    teamMemberSince: '01.07.2021',
    club: 'FC St. Pauli',
    previousClub: 'KAA Gent',
    uid: 'f200df27-d4cf-4932-8a85-1d27f76ea693',
    position: 'Defensives Mittelfeld',
    league: '2. Bundesliga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 20,
    name: 'Afeez Aremu',
    dateOfBirth: '03.10.1999',
    nationality: 'Nigeria',
    height: 1.81,
    foot: 'rechts',
    teamMemberSince: '24.08.2020',
    club: 'FC St. Pauli',
    previousClub: ': Ablöse 300 Tsd. €',
    uid: 'c56b4ba6-d87d-4de8-a46b-7ecb71187ba1',
    position: 'Defensives Mittelfeld',
    league: '2. Bundesliga',
    marketValue: 900000,
  },
  {
    jerseyNumber: 24,
    name: 'Connor Metcalfe',
    dateOfBirth: '05.11.1999',
    nationality: 'Australien',
    height: 1.83,
    foot: 'links',
    teamMemberSince: '01.07.2022',
    club: 'FC St. Pauli',
    previousClub: 'Melbourne City FC',
    uid: '6139aa3b-fc38-4034-bce2-22d7ac8dbee3',
    position: 'Defensives Mittelfeld',
    league: '2. Bundesliga',
    marketValue: 900000,
  },
  {
    jerseyNumber: 7,
    name: 'Jackson Irvine',
    dateOfBirth: '07.03.1993',
    nationality: 'Australien',
    height: 1.89,
    foot: 'rechts',
    teamMemberSince: '05.07.2021',
    club: 'FC St. Pauli',
    previousClub: 'Hibernian FC',
    uid: '83e73d5b-b8b2-49ae-b6cf-df0820510598',
    position: 'Zentrales Mittelfeld',
    league: '2. Bundesliga',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 10,
    name: 'Marcel Hartel',
    dateOfBirth: '19.01.1996',
    nationality: 'Deutschland',
    height: 1.77,
    foot: 'beidfüßig',
    teamMemberSince: '10.08.2021',
    club: 'FC St. Pauli',
    previousClub: 'Arminia Bielefeld',
    uid: 'c5a88016-142c-4e6d-beee-def23ae5f299',
    position: 'Zentrales Mittelfeld',
    league: '2. Bundesliga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 16,
    name: 'Carlo Boukhalfa',
    dateOfBirth: '03.05.1999',
    nationality: 'Deutschland',
    height: 1.86,
    foot: 'links',
    teamMemberSince: '01.07.2022',
    club: 'FC St. Pauli',
    previousClub: 'SC Freiburg II',
    uid: '74a74144-fdbf-4f54-a588-7d38e6aeb15e',
    position: 'Zentrales Mittelfeld',
    league: '2. Bundesliga',
    marketValue: 500000,
  },
  {
    jerseyNumber: 29,
    name: 'Niklas Jessen',
    dateOfBirth: '04.09.2003',
    nationality: 'Deutschland',
    height: 1.82,
    foot: 'beidfüßig',
    teamMemberSince: '01.07.2022',
    club: 'FC St. Pauli',
    previousClub: 'FC St. Pauli II',
    uid: '40211c3f-842d-4bf4-ae5f-946777ac74be',
    position: 'Zentrales Mittelfeld',
    league: '2. Bundesliga',
    marketValue: 250000,
  },
  {
    jerseyNumber: 31,
    name: 'Franz Roggow',
    dateOfBirth: '14.07.2002',
    nationality: 'Deutschland',
    height: 1.86,
    foot: 'links',
    teamMemberSince: '01.07.2021',
    club: 'FC St. Pauli',
    previousClub: 'FC St. Pauli U19',
    uid: '4cdb3ab5-9c34-4d75-9792-363f7c68ad44',
    position: 'Zentrales Mittelfeld',
    league: '2. Bundesliga',
    marketValue: 150000,
  },
  {
    jerseyNumber: 13,
    name: 'Lukas Daschner',
    dateOfBirth: '01.10.1998',
    nationality: 'Deutschland',
    height: 1.85,
    foot: 'beidfüßig',
    teamMemberSince: '14.08.2020',
    club: 'FC St. Pauli',
    previousClub: ': Ablöse 350 Tsd. €',
    uid: '7d7bd33e-597e-452e-a0ca-ad7b8571da4b',
    position: 'Offensives Mittelfeld',
    league: '2. Bundesliga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 17,
    name: 'Oladapo Afolayan',
    dateOfBirth: '11.09.1997',
    nationality: 'England',
    height: 1.8,
    foot: 'rechts',
    teamMemberSince: '19.01.2023',
    club: 'FC St. Pauli',
    previousClub: 'Bolton Wanderers',
    uid: '778f02a5-c5f3-49d8-a764-96b19d5baa6e',
    position: 'Linksaußen',
    league: '2. Bundesliga',
    marketValue: 800000,
  },
  {
    jerseyNumber: 11,
    name: 'Johannes Eggestein',
    dateOfBirth: '08.05.1998',
    nationality: 'Deutschland',
    height: 1.83,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'FC St. Pauli',
    previousClub: 'Royal Antwerpen FC',
    uid: '55ed0dbb-6221-4201-97b7-5484a62c67c2',
    position: 'Mittelstürmer',
    league: '2. Bundesliga',
    marketValue: 800000,
  },
  {
    jerseyNumber: 9,
    name: 'Maurides',
    dateOfBirth: '10.03.1994',
    nationality: 'Brasilien',
    height: 1.89,
    foot: 'links',
    teamMemberSince: '01.01.2023',
    club: 'FC St. Pauli',
    previousClub: 'Radomiak Radom',
    uid: 'ba17e2e3-164b-4671-b050-66d2bff75250',
    position: 'Mittelstürmer',
    league: '2. Bundesliga',
    marketValue: 650000,
  },
  {
    jerseyNumber: 14,
    name: 'Etienne Amenyido',
    dateOfBirth: '01.03.1998',
    nationality: 'Togo',
    height: 1.8,
    foot: 'rechts',
    teamMemberSince: '01.07.2021',
    club: 'FC St. Pauli',
    previousClub: 'VfL Osnabrück',
    uid: '37cc4cd3-70c7-4f6b-aa27-752415439b96',
    position: 'Mittelstürmer',
    league: '2. Bundesliga',
    marketValue: 600000,
  },
  {
    jerseyNumber: 27,
    name: 'David Otto',
    dateOfBirth: '03.03.1999',
    nationality: 'Deutschland',
    height: 1.85,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'FC St. Pauli',
    previousClub: 'TSG 1899 Hoffenheim',
    uid: '017fb7e4-3549-4995-838f-a84fc8d3b855',
    position: 'Mittelstürmer',
    league: '2. Bundesliga',
    marketValue: 500000,
  },
  {
    jerseyNumber: 1,
    name: 'Ron-Robert Zieler',
    dateOfBirth: '12.02.1989',
    nationality: 'Deutschland',
    height: 1.88,
    foot: 'rechts',
    teamMemberSince: '01.07.2019',
    club: 'Hannover 96',
    previousClub: 'VfB Stuttgart',
    uid: '66a654c3-310a-45fc-ac26-95ae61423c19',
    position: 'Torwart',
    league: '2. Bundesliga',
    marketValue: 700000,
  },
  {
    jerseyNumber: 30,
    name: 'Leo Weinkauf',
    dateOfBirth: '07.07.1996',
    nationality: 'Deutschland',
    height: 1.96,
    foot: 'rechts',
    teamMemberSince: '01.07.2018',
    club: 'Hannover 96',
    previousClub: 'FC Bayern München II',
    uid: '6657e9dc-aa1f-4719-9920-3420a8c2fb11',
    position: 'Torwart',
    league: '2. Bundesliga',
    marketValue: 300000,
  },
  {
    jerseyNumber: 33,
    name: 'Toni Stahl',
    dateOfBirth: '17.09.1999',
    nationality: 'Deutschland',
    height: 1.94,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'Hannover 96',
    previousClub: 'FC Energie Cottbus',
    uid: '5148ef07-23cf-460e-b601-5e9347049330',
    position: 'Torwart',
    league: '2. Bundesliga',
    marketValue: 125000,
  },
  {
    jerseyNumber: 5,
    name: 'Phil Neumann',
    dateOfBirth: '08.07.1997',
    nationality: 'Deutschland',
    height: 1.92,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'Hannover 96',
    previousClub: 'Holstein Kiel',
    uid: 'a7d0abee-90cc-4dfd-ac63-828708aaa519',
    position: 'Innenverteidiger',
    league: '2. Bundesliga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 4,
    name: 'Bright Arrey-Mbi',
    dateOfBirth: '26.03.2003',
    nationality: 'Deutschland',
    height: 1.87,
    foot: 'links',
    teamMemberSince: '30.08.2022',
    club: 'Hannover 96',
    previousClub: 'FC Bayern München',
    uid: '3fd23546-8a02-4679-b994-6d24057f3ecb',
    position: 'Innenverteidiger',
    league: '2. Bundesliga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 31,
    name: 'Julian Börner',
    dateOfBirth: '21.01.1991',
    nationality: 'Deutschland',
    height: 1.88,
    foot: 'links',
    teamMemberSince: '02.08.2021',
    club: 'Hannover 96',
    previousClub: 'Sheffield Wednesday',
    uid: 'aca2b238-9487-47c7-a52d-cf1c75474f70',
    position: 'Innenverteidiger',
    league: '2. Bundesliga',
    marketValue: 600000,
  },
  {
    jerseyNumber: 32,
    name: 'Luka Krajnc',
    dateOfBirth: '19.09.1994',
    nationality: 'Slowenien',
    height: 1.87,
    foot: 'links',
    teamMemberSince: '24.08.2021',
    club: 'Hannover 96',
    previousClub: 'Frosinone Calcio',
    uid: 'bc06be82-cf4c-4633-86ef-dc21cc6a6a3e',
    position: 'Innenverteidiger',
    league: '2. Bundesliga',
    marketValue: 600000,
  },
  {
    jerseyNumber: 18,
    name: 'Derrick Köhn',
    dateOfBirth: '04.02.1999',
    nationality: 'Deutschland',
    height: 1.8,
    foot: 'links',
    teamMemberSince: '01.07.2022',
    club: 'Hannover 96',
    previousClub: 'Willem II Tilburg',
    uid: '70f17302-2c1a-42e4-a04e-a1b9f29de802',
    position: 'Linker Verteidiger',
    league: '2. Bundesliga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 3,
    name: 'Ekin Celebi',
    dateOfBirth: '06.06.2000',
    nationality: 'Deutschland',
    height: 1.83,
    foot: 'links',
    teamMemberSince: '01.07.2022',
    club: 'Hannover 96',
    previousClub: 'VfB Stuttgart II',
    uid: 'bea81e8a-f034-435c-8d5c-5f16f9e458cb',
    position: 'Linker Verteidiger',
    league: '2. Bundesliga',
    marketValue: 200000,
  },
  {
    jerseyNumber: 21,
    name: 'Sei Muroya',
    dateOfBirth: '05.04.1994',
    nationality: 'Japan',
    height: 1.76,
    foot: 'rechts',
    teamMemberSince: '17.08.2020',
    club: 'Hannover 96',
    previousClub: 'FC Tokyo',
    uid: '3bcea3a7-30e0-4321-a025-76b34a185144',
    position: 'Rechter Verteidiger',
    league: '2. Bundesliga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 20,
    name: 'Jannik Dehm',
    dateOfBirth: '02.05.1996',
    nationality: 'Deutschland',
    height: 1.81,
    foot: 'rechts',
    teamMemberSince: '19.07.2021',
    club: 'Hannover 96',
    previousClub: 'Holstein Kiel',
    uid: '6ea8d626-ad0e-46a7-be92-bb40fbbd7de9',
    position: 'Rechter Verteidiger',
    league: '2. Bundesliga',
    marketValue: 600000,
  },
  {
    jerseyNumber: 6,
    name: 'Fabian Kunze',
    dateOfBirth: '14.06.1998',
    nationality: 'Deutschland',
    height: 1.91,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'Hannover 96',
    previousClub: 'Arminia Bielefeld',
    uid: '88c0562e-4539-4116-9b4a-daa0e0fadeec',
    position: 'Defensives Mittelfeld',
    league: '2. Bundesliga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 29,
    name: 'Gaël Ondoua',
    dateOfBirth: '04.11.1995',
    nationality: 'Kamerun',
    height: 1.85,
    foot: 'links',
    teamMemberSince: '26.08.2021',
    club: 'Hannover 96',
    previousClub: 'Servette FC',
    uid: 'a3f55629-2335-413c-a3bd-c8743b1ac076',
    position: 'Defensives Mittelfeld',
    league: '2. Bundesliga',
    marketValue: 600000,
  },
  {
    jerseyNumber: 27,
    name: 'Tim Walbrecht',
    dateOfBirth: '18.09.2001',
    nationality: 'Deutschland',
    height: 1.87,
    foot: 'rechts',
    teamMemberSince: '01.07.2020',
    club: 'Hannover 96',
    previousClub: ': Ablöse -',
    uid: 'bf9969e5-039f-4914-a0b6-b0f4091bb3c8',
    position: 'Defensives Mittelfeld',
    league: '2. Bundesliga',
    marketValue: 250000,
  },
  {
    jerseyNumber: 7,
    name: 'Max Besuschkow',
    dateOfBirth: '31.05.1997',
    nationality: 'Deutschland',
    height: 1.87,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'Hannover 96',
    previousClub: 'SSV Jahn Regensburg',
    uid: 'a8da8623-3ad5-4167-98e7-1fe2aba52e4f',
    position: 'Zentrales Mittelfeld',
    league: '2. Bundesliga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 8,
    name: 'Enzo Leopold',
    dateOfBirth: '23.07.2000',
    nationality: 'Deutschland',
    height: 1.74,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'Hannover 96',
    previousClub: 'SC Freiburg II',
    uid: 'a740e8c8-a6d4-4ec8-bbf9-f220389a1536',
    position: 'Zentrales Mittelfeld',
    league: '2. Bundesliga',
    marketValue: 800000,
  },
  {
    jerseyNumber: 24,
    name: 'Antonio Foti',
    dateOfBirth: '03.11.2003',
    nationality: 'Zypern',
    height: 1.8,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'Hannover 96',
    previousClub: 'Eintracht Frankfurt',
    uid: '344a3bac-f484-4803-9eb5-fe5d456edf0a',
    position: 'Zentrales Mittelfeld',
    league: '2. Bundesliga',
    marketValue: 600000,
  },
  {
    jerseyNumber: 11,
    name: 'Louis Schaub',
    dateOfBirth: '29.12.1994',
    nationality: 'Österreich',
    height: 1.77,
    foot: 'links',
    teamMemberSince: '01.07.2022',
    club: 'Hannover 96',
    previousClub: '1.FC Köln',
    uid: 'e4e0e7d5-2ccc-421b-9ec1-48104b22dc40',
    position: 'Offensives Mittelfeld',
    league: '2. Bundesliga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 10,
    name: 'Sebastian Ernst',
    dateOfBirth: '04.03.1995',
    nationality: 'Deutschland',
    height: 1.79,
    foot: 'links',
    teamMemberSince: '01.07.2021',
    club: 'Hannover 96',
    previousClub: 'SpVgg Greuther Fürth',
    uid: '9ef69577-8651-4584-96bd-a62a467e575f',
    position: 'Offensives Mittelfeld',
    league: '2. Bundesliga',
    marketValue: 800000,
  },
  {
    jerseyNumber: 37,
    name: 'Sebastian Kerk',
    dateOfBirth: '17.04.1994',
    nationality: 'Deutschland',
    height: 1.84,
    foot: 'links',
    teamMemberSince: '01.07.2021',
    club: 'Hannover 96',
    previousClub: 'VfL Osnabrück',
    uid: 'dffe397f-7fab-429d-a01b-79989d3b0b3f',
    position: 'Offensives Mittelfeld',
    league: '2. Bundesliga',
    marketValue: 800000,
  },
  {
    jerseyNumber: 22,
    name: 'Sebastian Stolze',
    dateOfBirth: '29.01.1995',
    nationality: 'Deutschland',
    height: 1.82,
    foot: 'rechts',
    teamMemberSince: '01.07.2021',
    club: 'Hannover 96',
    previousClub: 'SSV Jahn Regensburg',
    uid: '8079ce95-ad8e-47f3-bb9a-36a4592c7e6f',
    position: 'Rechtsaußen',
    league: '2. Bundesliga',
    marketValue: 500000,
  },
  {
    jerseyNumber: 38,
    name: 'Monju Momuluh',
    dateOfBirth: '18.02.2002',
    nationality: 'Deutschland',
    height: 1.81,
    foot: 'rechts',
    teamMemberSince: '28.11.2022',
    club: 'Hannover 96',
    previousClub: 'Hannover 96 II',
    uid: '7ff3e927-d5d9-47a0-b759-c46f59dddcff',
    position: 'Rechtsaußen',
    league: '2. Bundesliga',
    marketValue: 300000,
  },
  {
    jerseyNumber: 14,
    name: 'Maximilian Beier',
    dateOfBirth: '17.10.2002',
    nationality: 'Deutschland',
    height: 1.85,
    foot: 'rechts',
    teamMemberSince: '19.08.2021',
    club: 'Hannover 96',
    previousClub: 'TSG 1899 Hoffenheim',
    uid: 'b1a3174f-ae3d-4fc7-999d-96e3bc337cd4',
    position: 'Mittelstürmer',
    league: '2. Bundesliga',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 16,
    name: 'Havard Nielsen',
    dateOfBirth: '15.07.1993',
    nationality: 'Norwegen',
    height: 1.87,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'Hannover 96',
    previousClub: 'SpVgg Greuther Fürth',
    uid: '44cbfd75-40d5-471e-9cf3-4d724bac5560',
    position: 'Mittelstürmer',
    league: '2. Bundesliga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 36,
    name: 'Cedric Teuchert',
    dateOfBirth: '14.01.1997',
    nationality: 'Deutschland',
    height: 1.82,
    foot: 'rechts',
    teamMemberSince: '02.01.2022',
    club: 'Hannover 96',
    previousClub: '1.FC Union Berlin',
    uid: 'b039d4c1-8644-4c99-9418-b8610b7c76d0',
    position: 'Mittelstürmer',
    league: '2. Bundesliga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 23,
    name: 'Nicolò Tresoldi',
    dateOfBirth: '20.08.2004',
    nationality: 'Deutschland',
    height: 1.83,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'Hannover 96',
    previousClub: 'Hannover 96 U19',
    uid: 'af9c8ed7-3d7d-4ea3-abaf-5a2b5ab3b33a',
    position: 'Mittelstürmer',
    league: '2. Bundesliga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 9,
    name: 'Hendrik Weydandt',
    dateOfBirth: '16.07.1995',
    nationality: 'Deutschland',
    height: 1.95,
    foot: 'rechts',
    teamMemberSince: '03.09.2018',
    club: 'Hannover 96',
    previousClub: ': Ablöse -',
    uid: '1f085d66-9a04-4943-b28e-2157b197ba82',
    position: 'Mittelstürmer',
    league: '2. Bundesliga',
    marketValue: 450000,
  },
  {
    jerseyNumber: 33,
    name: 'Martin Fraisl',
    dateOfBirth: '10.05.1993',
    nationality: 'Österreich',
    height: 1.88,
    foot: 'rechts',
    teamMemberSince: '24.08.2022',
    club: 'Arminia Bielefeld',
    previousClub: 'FC Schalke 04',
    uid: '327d5a35-9661-4dbb-8c90-e8ca95b67b73',
    position: 'Torwart',
    league: '2. Bundesliga',
    marketValue: 600000,
  },
  {
    jerseyNumber: 31,
    name: 'Armin Gremsl',
    dateOfBirth: '13.08.1994',
    nationality: 'Österreich',
    height: 1.9,
    foot: 'links',
    teamMemberSince: '27.01.2023',
    club: 'Arminia Bielefeld',
    previousClub: 'SCR Altach',
    uid: '7a06d7dc-0394-46be-9823-fb74df17666b',
    position: 'Torwart',
    league: '2. Bundesliga',
    marketValue: 100000,
  },
  {
    jerseyNumber: 3,
    name: 'Guilherme Ramos',
    dateOfBirth: '11.08.1997',
    nationality: 'Portugal',
    height: 1.91,
    foot: 'rechts',
    teamMemberSince: '01.07.2021',
    club: 'Arminia Bielefeld',
    previousClub: 'CD Feirense',
    uid: '5aa63556-ed62-4192-9c3a-f0ae70f5ede1',
    position: 'Innenverteidiger',
    league: '2. Bundesliga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 30,
    name: 'Andrés Andrade',
    dateOfBirth: '16.10.1998',
    nationality: 'Panama',
    height: 1.87,
    foot: 'links',
    teamMemberSince: '01.07.2022',
    club: 'Arminia Bielefeld',
    previousClub: 'LASK',
    uid: '92fd8f2e-c12b-4ce9-8800-b7d9a110858a',
    position: 'Innenverteidiger',
    league: '2. Bundesliga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 4,
    name: 'Frederik Jäkel',
    dateOfBirth: '07.03.2001',
    nationality: 'Deutschland',
    height: 1.94,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'Arminia Bielefeld',
    previousClub: 'RasenBallsport Leipzig',
    uid: '84fa1a4e-4138-41f6-adde-5e6398007ae2',
    position: 'Innenverteidiger',
    league: '2. Bundesliga',
    marketValue: 900000,
  },
  {
    jerseyNumber: 6,
    name: 'Oliver Hüsing',
    dateOfBirth: '17.02.1993',
    nationality: 'Deutschland',
    height: 1.93,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'Arminia Bielefeld',
    previousClub: '1.FC Heidenheim 1846',
    uid: '705e51c9-9b71-4a2c-88e7-653a3b532193',
    position: 'Innenverteidiger',
    league: '2. Bundesliga',
    marketValue: 700000,
  },
  {
    jerseyNumber: 24,
    name: 'George Bello',
    dateOfBirth: '22.01.2002',
    nationality: 'Vereinigte Staaten',
    height: 1.7,
    foot: 'links',
    teamMemberSince: '31.01.2022',
    club: 'Arminia Bielefeld',
    previousClub: 'Atlanta United FC',
    uid: 'c2d7e058-7304-418c-b031-06619cbd6667',
    position: 'Linker Verteidiger',
    league: '2. Bundesliga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 5,
    name: 'Bastian Oczipka',
    dateOfBirth: '12.01.1989',
    nationality: 'Deutschland',
    height: 1.85,
    foot: 'links',
    teamMemberSince: '22.07.2022',
    club: 'Arminia Bielefeld',
    previousClub: '1.FC Union Berlin',
    uid: 'af733d59-2484-4c10-b928-e2b4f5abc7da',
    position: 'Linker Verteidiger',
    league: '2. Bundesliga',
    marketValue: 400000,
  },
  {
    jerseyNumber: 2,
    name: 'Lukas Klünter',
    dateOfBirth: '26.05.1996',
    nationality: 'Deutschland',
    height: 1.84,
    foot: 'rechts',
    teamMemberSince: '05.08.2022',
    club: 'Arminia Bielefeld',
    previousClub: 'Hertha BSC',
    uid: '3401d399-618f-4a67-957d-d806faf8e137',
    position: 'Rechter Verteidiger',
    league: '2. Bundesliga',
    marketValue: 800000,
  },
  {
    jerseyNumber: 22,
    name: 'Silvan Sidler',
    dateOfBirth: '07.07.1998',
    nationality: 'Schweiz',
    height: 1.85,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'Arminia Bielefeld',
    previousClub: 'FC Luzern',
    uid: 'e68f7f33-e840-4ccb-877d-cb29188f89aa',
    position: 'Rechter Verteidiger',
    league: '2. Bundesliga',
    marketValue: 400000,
  },
  {
    jerseyNumber: 13,
    name: 'Ivan Lepinjica',
    dateOfBirth: '09.07.1999',
    nationality: 'Kroatien',
    height: 1.9,
    foot: 'links',
    teamMemberSince: '12.08.2022',
    club: 'Arminia Bielefeld',
    previousClub: 'HNK Rijeka',
    uid: '1ff9c0c2-a875-47bf-a1db-9769447f44e5',
    position: 'Defensives Mittelfeld',
    league: '2. Bundesliga',
    marketValue: 600000,
  },
  {
    jerseyNumber: 19,
    name: 'Manuel Prietl',
    dateOfBirth: '03.08.1991',
    nationality: 'Österreich',
    height: 1.87,
    foot: 'rechts',
    teamMemberSince: '01.07.2016',
    club: 'Arminia Bielefeld',
    previousClub: 'SV Mattersburg',
    uid: '13a61dc4-47a4-409d-8496-f82a47a7971a',
    position: 'Defensives Mittelfeld',
    league: '2. Bundesliga',
    marketValue: 600000,
  },
  {
    jerseyNumber: 39,
    name: 'Sebastian Vasiliadis',
    dateOfBirth: '04.10.1997',
    nationality: 'Griechenland',
    height: 1.77,
    foot: 'rechts',
    teamMemberSince: '01.07.2021',
    club: 'Arminia Bielefeld',
    previousClub: 'SC Paderborn 07',
    uid: '327fc3f2-7f74-4b99-8d52-84c6814d5842',
    position: 'Zentrales Mittelfeld',
    league: '2. Bundesliga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 14,
    name: 'Jomaine Consbruch',
    dateOfBirth: '26.01.2002',
    nationality: 'Deutschland',
    height: 1.74,
    foot: 'rechts',
    teamMemberSince: '01.07.2019',
    club: 'Arminia Bielefeld',
    previousClub: 'Arminia Bielefeld U17',
    uid: '975b9b52-5847-4ea0-a311-a9110fee1ebe',
    position: 'Zentrales Mittelfeld',
    league: '2. Bundesliga',
    marketValue: 900000,
  },
  {
    jerseyNumber: 17,
    name: 'Burak Ince',
    dateOfBirth: '20.01.2004',
    nationality: 'Türkei',
    height: 1.81,
    foot: 'links',
    teamMemberSince: '20.01.2022',
    club: 'Arminia Bielefeld',
    previousClub: 'Altinordu FK',
    uid: '6e197ca0-605e-4756-bde0-e59c75fb1e8c',
    position: 'Zentrales Mittelfeld',
    league: '2. Bundesliga',
    marketValue: 650000,
  },
  {
    jerseyNumber: 37,
    name: 'Benjamin Kanuric',
    dateOfBirth: '26.02.2003',
    nationality: 'Österreich',
    height: 1.91,
    foot: 'rechts',
    teamMemberSince: '06.07.2022',
    club: 'Arminia Bielefeld',
    previousClub: 'SK Rapid Wien',
    uid: 'cf033222-81e0-4967-a888-dc06b485fa31',
    position: 'Zentrales Mittelfeld',
    league: '2. Bundesliga',
    marketValue: 450000,
  },
  {
    jerseyNumber: 16,
    name: 'Marc Rzatkowski',
    dateOfBirth: '02.03.1990',
    nationality: 'Deutschland',
    height: 1.72,
    foot: 'links',
    teamMemberSince: '05.07.2022',
    club: 'Arminia Bielefeld',
    previousClub: 'FC Schalke 04',
    uid: '67ed7bd0-221d-4d9a-8142-90e02e08edc2',
    position: 'Zentrales Mittelfeld',
    league: '2. Bundesliga',
    marketValue: 300000,
  },
  {
    jerseyNumber: 11,
    name: 'Masaya Okugawa',
    dateOfBirth: '14.04.1996',
    nationality: 'Japan',
    height: 1.77,
    foot: 'beidfüßig',
    teamMemberSince: '01.07.2021',
    club: 'Arminia Bielefeld',
    previousClub: 'Red Bull Salzburg',
    uid: '6ded1900-07a3-462a-ba3c-362a24170208',
    position: 'Offensives Mittelfeld',
    league: '2. Bundesliga',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 21,
    name: 'Robin Hack',
    dateOfBirth: '27.08.1998',
    nationality: 'Deutschland',
    height: 1.76,
    foot: 'rechts',
    teamMemberSince: '09.08.2021',
    club: 'Arminia Bielefeld',
    previousClub: '1.FC Nürnberg',
    uid: '112de299-ed42-402f-bdd1-dfedf2d46a35',
    position: 'Linksaußen',
    league: '2. Bundesliga',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 20,
    name: 'Theo Corbeanu',
    dateOfBirth: '17.05.2002',
    nationality: 'Kanada',
    height: 1.9,
    foot: 'links',
    teamMemberSince: '20.01.2023',
    club: 'Arminia Bielefeld',
    previousClub: 'Wolverhampton Wanderers U21',
    uid: '310c6edb-c733-4531-827f-3c1d5d0308e8',
    position: 'Rechtsaußen',
    league: '2. Bundesliga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 7,
    name: 'Christian Gebauer',
    dateOfBirth: '20.12.1993',
    nationality: 'Österreich',
    height: 1.87,
    foot: 'rechts',
    teamMemberSince: '15.07.2020',
    club: 'Arminia Bielefeld',
    previousClub: 'SCR Altach',
    uid: '19ad4e4d-a9b1-4b11-8c8d-12d9d711d6e9',
    position: 'Rechtsaußen',
    league: '2. Bundesliga',
    marketValue: 500000,
  },
  {
    jerseyNumber: 23,
    name: 'Janni Serra',
    dateOfBirth: '13.03.1998',
    nationality: 'Deutschland',
    height: 1.93,
    foot: 'links',
    teamMemberSince: '01.07.2021',
    club: 'Arminia Bielefeld',
    previousClub: 'Holstein Kiel',
    uid: 'b63f725e-61db-418f-8f77-40368d58eb03',
    position: 'Mittelstürmer',
    league: '2. Bundesliga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 10,
    name: 'Bryan Lasme',
    dateOfBirth: '14.11.1998',
    nationality: 'Frankreich',
    height: 1.94,
    foot: 'rechts',
    teamMemberSince: '01.07.2021',
    club: 'Arminia Bielefeld',
    previousClub: 'FC Sochaux-Montbéliard',
    uid: 'cd195bf0-4986-4089-8da3-057ff5b5a367',
    position: 'Mittelstürmer',
    league: '2. Bundesliga',
    marketValue: 800000,
  },
  {
    jerseyNumber: 9,
    name: 'Fabian Klos',
    dateOfBirth: '02.12.1987',
    nationality: 'Deutschland',
    height: 1.94,
    foot: 'rechts',
    teamMemberSince: '01.07.2011',
    club: 'Arminia Bielefeld',
    previousClub: 'VfL Wolfsburg II',
    uid: 'fa2026f6-3cf4-4895-9113-dc6a1a8c6a8b',
    position: 'Mittelstürmer',
    league: '2. Bundesliga',
    marketValue: 400000,
  },
  {
    jerseyNumber: 18,
    name: 'Christopher Schepp',
    dateOfBirth: '14.04.2000',
    nationality: 'Deutschland',
    height: 1.92,
    foot: 'beidfüßig',
    teamMemberSince: '04.01.2023',
    club: 'Arminia Bielefeld',
    previousClub: 'Blau-Weiß Lohne',
    uid: 'c1c0765d-b4cc-443e-8d09-cc0994c86acd',
    position: 'Mittelstürmer',
    league: '2. Bundesliga',
    marketValue: 200000,
  },
  {
    jerseyNumber: 1,
    name: 'Andreas Linde',
    dateOfBirth: '24.07.1993',
    nationality: 'Schweden',
    height: 1.96,
    foot: 'rechts',
    teamMemberSince: '10.01.2022',
    club: 'SpVgg Greuther Fürth',
    previousClub: 'Molde FK',
    uid: '7db3952c-bd46-4e25-b0ee-80fcc15ec37a',
    position: 'Torwart',
    league: '2. Bundesliga',
    marketValue: 600000,
  },
  {
    jerseyNumber: 25,
    name: 'Leon Schaffran',
    dateOfBirth: '31.07.1998',
    nationality: 'Deutschland',
    height: 1.86,
    foot: 'rechts',
    teamMemberSince: '01.07.2018',
    club: 'SpVgg Greuther Fürth',
    previousClub: 'Hertha BSC II',
    uid: 'f6cc0f7f-0f46-4cae-947b-3f6b8a40eff5',
    position: 'Torwart',
    league: '2. Bundesliga',
    marketValue: 200000,
  },
  {
    jerseyNumber: 41,
    name: 'Lasse Schulz',
    dateOfBirth: '29.03.2003',
    nationality: 'Finnland',
    height: 1.98,
    foot: 'rechts',
    teamMemberSince: '01.07.2021',
    club: 'SpVgg Greuther Fürth',
    previousClub: 'SpVgg Greuther Fürth U19',
    uid: '2b507d2d-90e3-4089-9736-37488793fc1f',
    position: 'Torwart',
    league: '2. Bundesliga',
    marketValue: 125000,
  },
  {
    jerseyNumber: 4,
    name: 'Damian Michalski',
    dateOfBirth: '17.05.1998',
    nationality: 'Polen',
    height: 1.89,
    foot: 'rechts',
    teamMemberSince: '29.08.2022',
    club: 'SpVgg Greuther Fürth',
    previousClub: 'Wisla Plock',
    uid: 'c20fbf4e-1511-42c8-9490-729d527c562c',
    position: 'Innenverteidiger',
    league: '2. Bundesliga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 5,
    name: 'Oussama Haddadi',
    dateOfBirth: '28.01.1992',
    nationality: 'Tunesien',
    height: 1.85,
    foot: 'links',
    teamMemberSince: '01.07.2022',
    club: 'SpVgg Greuther Fürth',
    previousClub: 'Vereinslos',
    uid: '2e3a829e-94d3-47e3-ba66-79335bc30803',
    position: 'Innenverteidiger',
    league: '2. Bundesliga',
    marketValue: 800000,
  },
  {
    jerseyNumber: 22,
    name: 'Sebastian Griesbeck',
    dateOfBirth: '03.10.1990',
    nationality: 'Deutschland',
    height: 1.89,
    foot: 'rechts',
    teamMemberSince: '31.08.2021',
    club: 'SpVgg Greuther Fürth',
    previousClub: '1.FC Union Berlin',
    uid: '3769be80-0bea-43be-a81e-1e5c7903bd68',
    position: 'Innenverteidiger',
    league: '2. Bundesliga',
    marketValue: 600000,
  },
  {
    jerseyNumber: 23,
    name: 'Gideon Jung',
    dateOfBirth: '12.09.1994',
    nationality: 'Deutschland',
    height: 1.89,
    foot: 'rechts',
    teamMemberSince: '01.07.2021',
    club: 'SpVgg Greuther Fürth',
    previousClub: 'Hamburger SV',
    uid: '54e71119-8338-49fe-b62b-acae6a930187',
    position: 'Innenverteidiger',
    league: '2. Bundesliga',
    marketValue: 500000,
  },
  {
    jerseyNumber: 19,
    name: 'Oliver Fobassam',
    dateOfBirth: '06.04.2003',
    nationality: 'Deutschland',
    height: 1.9,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'SpVgg Greuther Fürth',
    previousClub: 'SpVgg Greuther Fürth U19',
    uid: 'bb35f1a6-2542-44a1-9775-eb74d8b451ce',
    position: 'Innenverteidiger',
    league: '2. Bundesliga',
    marketValue: 200000,
  },
  {
    jerseyNumber: 24,
    name: 'Marco John',
    dateOfBirth: '02.04.2002',
    nationality: 'Deutschland',
    height: 1.84,
    foot: 'links',
    teamMemberSince: '26.08.2022',
    club: 'SpVgg Greuther Fürth',
    previousClub: 'TSG 1899 Hoffenheim',
    uid: 'a9bb0b24-1256-43f8-bb95-3f170f602064',
    position: 'Linker Verteidiger',
    league: '2. Bundesliga',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 27,
    name: 'Luca Itter',
    dateOfBirth: '05.01.1999',
    nationality: 'Deutschland',
    height: 1.83,
    foot: 'links',
    teamMemberSince: '01.07.2022',
    club: 'SpVgg Greuther Fürth',
    previousClub: 'SC Freiburg',
    uid: 'b318cf78-5851-42b2-9a25-46b680b71bda',
    position: 'Linker Verteidiger',
    league: '2. Bundesliga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 2,
    name: 'Simon Asta',
    dateOfBirth: '25.01.2001',
    nationality: 'Deutschland',
    height: 1.78,
    foot: 'rechts',
    teamMemberSince: '05.10.2020',
    club: 'SpVgg Greuther Fürth',
    previousClub: 'FC Augsburg',
    uid: 'f999b844-662b-447c-aa82-5f335db74d22',
    position: 'Rechter Verteidiger',
    league: '2. Bundesliga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 18,
    name: 'Marco Meyerhöfer',
    dateOfBirth: '18.11.1995',
    nationality: 'Deutschland',
    height: 1.79,
    foot: 'rechts',
    teamMemberSince: '01.07.2019',
    club: 'SpVgg Greuther Fürth',
    previousClub: 'SV Waldhof Mannheim',
    uid: 'caba05ec-eaed-445a-b7a3-bb8477c01dc0',
    position: 'Rechter Verteidiger',
    league: '2. Bundesliga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 3,
    name: 'Oualid Mhamdi',
    dateOfBirth: '20.05.2003',
    nationality: 'Marokko',
    height: 1.86,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'SpVgg Greuther Fürth',
    previousClub: 'FC Viktoria Köln U19',
    uid: 'abbdb6ff-1176-43dc-b858-7ae49b607805',
    position: 'Rechter Verteidiger',
    league: '2. Bundesliga',
    marketValue: 300000,
  },
  {
    jerseyNumber: 13,
    name: 'Max Christiansen',
    dateOfBirth: '25.09.1996',
    nationality: 'Deutschland',
    height: 1.85,
    foot: 'rechts',
    teamMemberSince: '01.07.2021',
    club: 'SpVgg Greuther Fürth',
    previousClub: 'SV Waldhof Mannheim',
    uid: '3bfcf7cc-ed4b-439f-9f2b-1be7c8cba269',
    position: 'Defensives Mittelfeld',
    league: '2. Bundesliga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 20,
    name: 'Tobias Raschl',
    dateOfBirth: '21.02.2000',
    nationality: 'Deutschland',
    height: 1.77,
    foot: 'rechts',
    teamMemberSince: '31.01.2022',
    club: 'SpVgg Greuther Fürth',
    previousClub: 'Borussia Dortmund',
    uid: 'a3ab2adb-3bc7-483c-bd05-d2460d06c46d',
    position: 'Zentrales Mittelfeld',
    league: '2. Bundesliga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 37,
    name: 'Julian Green',
    dateOfBirth: '06.06.1995',
    nationality: 'Vereinigte Staaten',
    height: 1.73,
    foot: 'rechts',
    teamMemberSince: '01.07.2018',
    club: 'SpVgg Greuther Fürth',
    previousClub: 'VfB Stuttgart',
    uid: '04033ed9-b3e7-4e92-818b-32dd03c63d28',
    position: 'Zentrales Mittelfeld',
    league: '2. Bundesliga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 6,
    name: 'Sidney Raebiger',
    dateOfBirth: '17.04.2005',
    nationality: 'Deutschland',
    height: 1.76,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'SpVgg Greuther Fürth',
    previousClub: 'RasenBallsport Leipzig',
    uid: 'd3d3f73f-d8b9-46ef-8eda-424146f64a15',
    position: 'Zentrales Mittelfeld',
    league: '2. Bundesliga',
    marketValue: 750000,
  },
  {
    jerseyNumber: 8,
    name: 'Nils Seufert',
    dateOfBirth: '03.02.1997',
    nationality: 'Deutschland',
    height: 1.82,
    foot: 'beidfüßig',
    teamMemberSince: '01.07.2021',
    club: 'SpVgg Greuther Fürth',
    previousClub: 'Arminia Bielefeld',
    uid: 'd2131467-75cb-4f2c-95a3-0da6d1b7e3ba',
    position: 'Zentrales Mittelfeld',
    league: '2. Bundesliga',
    marketValue: 400000,
  },
  {
    jerseyNumber: 17,
    name: 'Lucien Littbarski',
    dateOfBirth: '08.05.2003',
    nationality: 'Japan',
    height: 1.82,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'SpVgg Greuther Fürth',
    previousClub: 'VfL Wolfsburg U19',
    uid: 'b6450e57-92dc-4707-b9ca-9d91d26f92f1',
    position: 'Zentrales Mittelfeld',
    league: '2. Bundesliga',
    marketValue: 150000,
  },
  {
    jerseyNumber: 31,
    name: 'Devin Angleberger',
    dateOfBirth: '05.03.2003',
    nationality: 'Deutschland',
    height: 1.79,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'SpVgg Greuther Fürth',
    previousClub: 'SpVgg Greuther Fürth II',
    uid: 'bb6a52a1-64af-40e1-ac26-a59195633cd3',
    position: 'Zentrales Mittelfeld',
    league: '2. Bundesliga',
    marketValue: 150000,
  },
  {
    jerseyNumber: 16,
    name: 'Lukas Petkov',
    dateOfBirth: '01.11.2000',
    nationality: 'Deutschland',
    height: 1.83,
    foot: 'rechts',
    teamMemberSince: '31.01.2023',
    club: 'SpVgg Greuther Fürth',
    previousClub: 'FC Augsburg',
    uid: 'fd45468d-bdde-46aa-a6fe-a0ab55ef25f4',
    position: 'Offensives Mittelfeld',
    league: '2. Bundesliga',
    marketValue: 800000,
  },
  {
    jerseyNumber: 30,
    name: 'Armindo Sieb',
    dateOfBirth: '17.02.2003',
    nationality: 'Deutschland',
    height: 1.75,
    foot: 'beidfüßig',
    teamMemberSince: '01.07.2022',
    club: 'SpVgg Greuther Fürth',
    previousClub: 'FC Bayern München II',
    uid: 'c8317f0d-2186-41e2-a42b-d11ed9355d9e',
    position: 'Hängende Spitze',
    league: '2. Bundesliga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 10,
    name: 'Branimir Hrgota',
    dateOfBirth: '12.01.1993',
    nationality: 'Schweden',
    height: 1.85,
    foot: 'links',
    teamMemberSince: '07.08.2019',
    club: 'SpVgg Greuther Fürth',
    previousClub: 'Eintracht Frankfurt',
    uid: 'e297a7d8-61b5-440a-bc7f-0ee9984b1a0f',
    position: 'Mittelstürmer',
    league: '2. Bundesliga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 39,
    name: 'Ragnar Ache',
    dateOfBirth: '28.07.1998',
    nationality: 'Deutschland',
    height: 1.83,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'SpVgg Greuther Fürth',
    previousClub: 'Eintracht Frankfurt',
    uid: '5f105371-8c31-47ed-a918-aa36fe3f4b33',
    position: 'Mittelstürmer',
    league: '2. Bundesliga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 11,
    name: 'Dickson Abiama',
    dateOfBirth: '03.11.1998',
    nationality: 'Nigeria',
    height: 1.84,
    foot: 'rechts',
    teamMemberSince: '01.07.2020',
    club: 'SpVgg Greuther Fürth',
    previousClub: 'SC Eltersdorf',
    uid: '57446901-4cf0-42af-bea0-7120232a5435',
    position: 'Mittelstürmer',
    league: '2. Bundesliga',
    marketValue: 700000,
  },
  {
    jerseyNumber: 9,
    name: 'Afimico Pululu',
    dateOfBirth: '23.03.1999',
    nationality: 'Angola',
    height: 1.75,
    foot: 'links',
    teamMemberSince: '07.01.2022',
    club: 'SpVgg Greuther Fürth',
    previousClub: 'FC Basel 1893',
    uid: 'bfdcad43-08d2-466f-953a-5a01ecf1e3bb',
    position: 'Mittelstürmer',
    league: '2. Bundesliga',
    marketValue: 600000,
  },
  {
    jerseyNumber: 7,
    name: 'Robin Kehr',
    dateOfBirth: '22.02.2000',
    nationality: 'Deutschland',
    height: 1.89,
    foot: 'links',
    teamMemberSince: '01.07.2019',
    club: 'SpVgg Greuther Fürth',
    previousClub: 'Borussia Dortmund U19',
    uid: 'cfac7520-9810-431b-be95-514a959b9ac5',
    position: 'Mittelstürmer',
    league: '2. Bundesliga',
    marketValue: 100000,
  },
  {
    jerseyNumber: 1,
    name: 'Kevin Müller',
    dateOfBirth: '15.03.1991',
    nationality: 'Deutschland',
    height: 1.9,
    foot: 'rechts',
    teamMemberSince: '01.07.2015',
    club: '1.FC Heidenheim 1846',
    previousClub: 'VfB Stuttgart II',
    uid: '0ce28f6f-9b5f-4abd-aff5-d1d57671c61b',
    position: 'Torwart',
    league: '2. Bundesliga',
    marketValue: 600000,
  },
  {
    jerseyNumber: 22,
    name: 'Vitus Eicher',
    dateOfBirth: '05.11.1990',
    nationality: 'Deutschland',
    height: 1.91,
    foot: 'links',
    teamMemberSince: '17.01.2017',
    club: '1.FC Heidenheim 1846',
    previousClub: 'TSV 1860 München',
    uid: '90ccf46b-2349-47ce-a566-632b83c9dd1f',
    position: 'Torwart',
    league: '2. Bundesliga',
    marketValue: 250000,
  },
  {
    jerseyNumber: 34,
    name: 'Paul Tschernuth',
    dateOfBirth: '20.01.2002',
    nationality: 'Österreich',
    height: 1.86,
    foot: 'links',
    teamMemberSince: '01.07.2021',
    club: '1.FC Heidenheim 1846',
    previousClub: '1.FC Heidenheim 1846 U19',
    uid: '948ea077-4cfd-46c5-8131-1e090882dbc7',
    position: 'Torwart',
    league: '2. Bundesliga',
    marketValue: 100000,
  },
  {
    jerseyNumber: 6,
    name: 'Patrick Mainka',
    dateOfBirth: '06.11.1994',
    nationality: 'Deutschland',
    height: 1.94,
    foot: 'rechts',
    teamMemberSince: '01.07.2018',
    club: '1.FC Heidenheim 1846',
    previousClub: 'Borussia Dortmund II',
    uid: '228949db-c09d-4b81-9b04-a8003c2c2602',
    position: 'Innenverteidiger',
    league: '2. Bundesliga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 4,
    name: 'Tim Siersleben',
    dateOfBirth: '09.03.2000',
    nationality: 'Deutschland',
    height: 1.87,
    foot: 'links',
    teamMemberSince: '01.07.2021',
    club: '1.FC Heidenheim 1846',
    previousClub: 'VfL Wolfsburg',
    uid: 'aa8c34cc-c219-4de7-b50d-92a6e4a143ea',
    position: 'Innenverteidiger',
    league: '2. Bundesliga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 27,
    name: 'Thomas Keller',
    dateOfBirth: '05.08.1999',
    nationality: 'Deutschland',
    height: 1.87,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: '1.FC Heidenheim 1846',
    previousClub: 'FC Ingolstadt 04',
    uid: 'ee1f4729-0a30-4336-b027-b8e8252d7cef',
    position: 'Innenverteidiger',
    league: '2. Bundesliga',
    marketValue: 350000,
  },
  {
    jerseyNumber: 19,
    name: 'Jonas Föhrenbach',
    dateOfBirth: '26.01.1996',
    nationality: 'Deutschland',
    height: 1.84,
    foot: 'links',
    teamMemberSince: '01.07.2019',
    club: '1.FC Heidenheim 1846',
    previousClub: 'SC Freiburg',
    uid: '68834614-0bde-44b1-81f3-a8690795a7e5',
    position: 'Linker Verteidiger',
    league: '2. Bundesliga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 2,
    name: 'Marnon Busch',
    dateOfBirth: '08.12.1994',
    nationality: 'Deutschland',
    height: 1.82,
    foot: 'rechts',
    teamMemberSince: '01.07.2017',
    club: '1.FC Heidenheim 1846',
    previousClub: 'SV Werder Bremen',
    uid: '4bdacd37-989b-492c-a988-42bc8e89f9da',
    position: 'Rechter Verteidiger',
    league: '2. Bundesliga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 18,
    name: 'Marvin Rittmüller',
    dateOfBirth: '07.03.1999',
    nationality: 'Deutschland',
    height: 1.77,
    foot: 'beidfüßig',
    teamMemberSince: '19.07.2020',
    club: '1.FC Heidenheim 1846',
    previousClub: '1.FC Köln II',
    uid: '55588387-1a3d-4455-bd04-a96a4d333236',
    position: 'Rechter Verteidiger',
    league: '2. Bundesliga',
    marketValue: 350000,
  },
  {
    jerseyNumber: 33,
    name: 'Lennard Maloney',
    dateOfBirth: '08.10.1999',
    nationality: 'Vereinigte Staaten',
    height: 1.87,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: '1.FC Heidenheim 1846',
    previousClub: 'Borussia Dortmund II',
    uid: '2dd6aab4-cc1b-4a8d-94d5-d51dc099e4da',
    position: 'Defensives Mittelfeld',
    league: '2. Bundesliga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 20,
    name: 'Dzenis Burnic',
    dateOfBirth: '22.05.1998',
    nationality: 'Deutschland',
    height: 1.81,
    foot: 'links',
    teamMemberSince: '03.08.2020',
    club: '1.FC Heidenheim 1846',
    previousClub: 'Borussia Dortmund',
    uid: '3c764932-cb13-4281-b152-10a96702fb1d',
    position: 'Defensives Mittelfeld',
    league: '2. Bundesliga',
    marketValue: 650000,
  },
  {
    jerseyNumber: 8,
    name: 'Andreas Geipl',
    dateOfBirth: '21.04.1992',
    nationality: 'Deutschland',
    height: 1.78,
    foot: 'rechts',
    teamMemberSince: '01.07.2020',
    club: '1.FC Heidenheim 1846',
    previousClub: 'SSV Jahn Regensburg',
    uid: 'db725873-3aea-4840-97bd-4504b79fc2d0',
    position: 'Defensives Mittelfeld',
    league: '2. Bundesliga',
    marketValue: 500000,
  },
  {
    jerseyNumber: 30,
    name: 'Norman Theuerkauf',
    dateOfBirth: '24.01.1987',
    nationality: 'Deutschland',
    height: 1.83,
    foot: 'links',
    teamMemberSince: '01.07.2015',
    club: '1.FC Heidenheim 1846',
    previousClub: ': Ablöse ablösefrei',
    uid: '50971a45-33dd-402b-a756-3bf3a645051f',
    position: 'Defensives Mittelfeld',
    league: '2. Bundesliga',
    marketValue: 300000,
  },
  {
    jerseyNumber: 3,
    name: 'Jan Schöppner',
    dateOfBirth: '12.06.1999',
    nationality: 'Deutschland',
    height: 1.9,
    foot: 'rechts',
    teamMemberSince: '05.08.2020',
    club: '1.FC Heidenheim 1846',
    previousClub: 'SC Verl',
    uid: '732698b3-7e11-4f40-8ee1-eeb32e01b859',
    position: 'Zentrales Mittelfeld',
    league: '2. Bundesliga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 31,
    name: 'Mert Arslan',
    dateOfBirth: '12.08.2003',
    nationality: 'Deutschland',
    height: 1.72,
    foot: 'beidfüßig',
    teamMemberSince: '01.07.2022',
    club: '1.FC Heidenheim 1846',
    previousClub: '1.FC Heidenheim 1846 U19',
    uid: 'e26ec2a2-1cb7-4e3b-9cea-dd89b27609bf',
    position: 'Zentrales Mittelfeld',
    league: '2. Bundesliga',
    marketValue: 50000,
  },
  {
    jerseyNumber: 21,
    name: 'Adrian Beck',
    dateOfBirth: '09.06.1997',
    nationality: 'Deutschland',
    height: 1.86,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: '1.FC Heidenheim 1846',
    previousClub: 'SSV Ulm 1846',
    uid: 'cdfc6543-7705-4b96-8e48-40426bfb78cc',
    position: 'Offensives Mittelfeld',
    league: '2. Bundesliga',
    marketValue: 650000,
  },
  {
    jerseyNumber: 11,
    name: 'Denis Thomalla',
    dateOfBirth: '16.08.1992',
    nationality: 'Deutschland',
    height: 1.86,
    foot: 'rechts',
    teamMemberSince: '01.07.2016',
    club: '1.FC Heidenheim 1846',
    previousClub: 'Lech Posen',
    uid: 'dcc522ac-8682-441b-b969-2f7f59f1342b',
    position: 'Offensives Mittelfeld',
    league: '2. Bundesliga',
    marketValue: 600000,
  },
  {
    jerseyNumber: 23,
    name: 'Merveille Biankadi',
    dateOfBirth: '09.05.1995',
    nationality: 'Deutschland',
    height: 1.81,
    foot: 'beidfüßig',
    teamMemberSince: '18.07.2019',
    club: '1.FC Heidenheim 1846',
    previousClub: 'FC Hansa Rostock',
    uid: 'c7fd943d-5537-47f5-a1b7-1828e84ff934',
    position: 'Offensives Mittelfeld',
    league: '2. Bundesliga',
    marketValue: 300000,
  },
  {
    jerseyNumber: 37,
    name: 'Jan-Niklas Beste',
    dateOfBirth: '04.01.1999',
    nationality: 'Deutschland',
    height: 1.74,
    foot: 'links',
    teamMemberSince: '01.07.2022',
    club: '1.FC Heidenheim 1846',
    previousClub: 'SV Werder Bremen',
    uid: '29fbb899-66d1-4de1-8bd8-10fce36acb0b',
    position: 'Linksaußen',
    league: '2. Bundesliga',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 17,
    name: 'Florian Pick',
    dateOfBirth: '08.09.1995',
    nationality: 'Deutschland',
    height: 1.76,
    foot: 'rechts',
    teamMemberSince: '01.09.2020',
    club: '1.FC Heidenheim 1846',
    previousClub: '1.FC Kaiserslautern',
    uid: 'a4186192-da9b-4dee-aff5-c9c7fa1e02ca',
    position: 'Linksaußen',
    league: '2. Bundesliga',
    marketValue: 600000,
  },
  {
    jerseyNumber: 26,
    name: 'Tim Köther',
    dateOfBirth: '22.02.2001',
    nationality: 'Deutschland',
    height: 1.75,
    foot: 'links',
    teamMemberSince: '01.07.2022',
    club: '1.FC Heidenheim 1846',
    previousClub: 'Fortuna Düsseldorf II',
    uid: '47304d59-b092-4428-acb0-5649d4361339',
    position: 'Linksaußen',
    league: '2. Bundesliga',
    marketValue: 200000,
  },
  {
    jerseyNumber: 10,
    name: 'Tim Kleindienst',
    dateOfBirth: '31.08.1995',
    nationality: 'Deutschland',
    height: 1.94,
    foot: 'rechts',
    teamMemberSince: '01.07.2021',
    club: '1.FC Heidenheim 1846',
    previousClub: 'KAA Gent',
    uid: '73587c77-d0b6-488f-acae-33c933776769',
    position: 'Mittelstürmer',
    league: '2. Bundesliga',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 24,
    name: 'Christian Kühlwetter',
    dateOfBirth: '21.04.1996',
    nationality: 'Deutschland',
    height: 1.82,
    foot: 'beidfüßig',
    teamMemberSince: '01.09.2020',
    club: '1.FC Heidenheim 1846',
    previousClub: '1.FC Kaiserslautern',
    uid: 'd2ecfffd-64be-4efd-9f4f-4f3913e01a6c',
    position: 'Mittelstürmer',
    league: '2. Bundesliga',
    marketValue: 700000,
  },
  {
    jerseyNumber: 9,
    name: 'Stefan Schimmer',
    dateOfBirth: '28.04.1994',
    nationality: 'Deutschland',
    height: 1.85,
    foot: 'rechts',
    teamMemberSince: '06.08.2019',
    club: '1.FC Heidenheim 1846',
    previousClub: 'SpVgg Unterhaching',
    uid: '366dc190-8b3d-4da5-86cd-67bdfa484f5d',
    position: 'Mittelstürmer',
    league: '2. Bundesliga',
    marketValue: 400000,
  },
  {
    jerseyNumber: 44,
    name: 'Elidon Qenaj',
    dateOfBirth: '22.05.2003',
    nationality: 'Kosovo',
    height: 1.98,
    foot: 'beidfüßig',
    teamMemberSince: '01.07.2022',
    club: '1.FC Heidenheim 1846',
    previousClub: '1.FC Heidenheim 1846 U19',
    uid: '36951969-f2eb-416a-a111-3a99879d700f',
    position: 'Mittelstürmer',
    league: '2. Bundesliga',
    marketValue: 50000,
  },
  {
    jerseyNumber: 35,
    name: 'Marius Gersbeck',
    dateOfBirth: '20.06.1995',
    nationality: 'Deutschland',
    height: 1.87,
    foot: 'links',
    teamMemberSince: '01.07.2019',
    club: 'Karlsruher SC',
    previousClub: 'Hertha BSC',
    uid: 'dc6f2d8c-f740-4073-b8b4-11add209bf0f',
    position: 'Torwart',
    league: '2. Bundesliga',
    marketValue: 800000,
  },
  {
    jerseyNumber: 1,
    name: 'Kai Eisele',
    dateOfBirth: '25.06.1995',
    nationality: 'Deutschland',
    height: 1.9,
    foot: 'beidfüßig',
    teamMemberSince: '01.07.2022',
    club: 'Karlsruher SC',
    previousClub: 'Fortuna Düsseldorf',
    uid: '90f8a16a-21df-4bdc-b452-e961672dae0a',
    position: 'Torwart',
    league: '2. Bundesliga',
    marketValue: 200000,
  },
  {
    jerseyNumber: 15,
    name: 'Stephan Ambrosius',
    dateOfBirth: '18.12.1998',
    nationality: 'Ghana',
    height: 1.83,
    foot: 'rechts',
    teamMemberSince: '16.08.2022',
    club: 'Karlsruher SC',
    previousClub: 'Hamburger SV',
    uid: 'bcfc22c0-045b-41fe-8880-7b2e124b9a9c',
    position: 'Innenverteidiger',
    league: '2. Bundesliga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 28,
    name: 'Marcel Franke',
    dateOfBirth: '05.04.1993',
    nationality: 'Deutschland',
    height: 1.93,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'Karlsruher SC',
    previousClub: 'Hannover 96',
    uid: '85b2ae7a-2f11-4f22-a5c7-aabdf23ef303',
    position: 'Innenverteidiger',
    league: '2. Bundesliga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 22,
    name: 'Christoph Kobald',
    dateOfBirth: '18.08.1997',
    nationality: 'Österreich',
    height: 1.86,
    foot: 'beidfüßig',
    teamMemberSince: '10.07.2018',
    club: 'Karlsruher SC',
    previousClub: ': Ablöse ablösefrei',
    uid: '807f69be-aafe-49ac-af01-39aea97d27a8',
    position: 'Innenverteidiger',
    league: '2. Bundesliga',
    marketValue: 700000,
  },
  {
    jerseyNumber: 5,
    name: "Daniel O'Shaughnessy",
    dateOfBirth: '14.09.1994',
    nationality: 'Finnland',
    height: 1.9,
    foot: 'links',
    teamMemberSince: '01.01.2022',
    club: 'Karlsruher SC',
    previousClub: 'HJK Helsinki',
    uid: '7ffbed06-02c6-4063-a451-3134ee2bbefc',
    position: 'Innenverteidiger',
    league: '2. Bundesliga',
    marketValue: 500000,
  },
  {
    jerseyNumber: 4,
    name: 'Florian Ballas',
    dateOfBirth: '08.01.1993',
    nationality: 'Deutschland',
    height: 1.96,
    foot: 'beidfüßig',
    teamMemberSince: '01.07.2022',
    club: 'Karlsruher SC',
    previousClub: 'FC Erzgebirge Aue',
    uid: 'b5aed497-8ebb-4fbe-9e2c-bbe70e80c7a5',
    position: 'Innenverteidiger',
    league: '2. Bundesliga',
    marketValue: 350000,
  },
  {
    jerseyNumber: 40,
    name: 'Lazar Mirkovic',
    dateOfBirth: '27.07.2002',
    nationality: 'Serbien',
    height: 1.94,
    foot: 'links',
    teamMemberSince: '01.07.2021',
    club: 'Karlsruher SC',
    previousClub: 'Karlsruher SC U19',
    uid: '7f8c83ea-438d-4d38-bef7-12b8386c3ca6',
    position: 'Innenverteidiger',
    league: '2. Bundesliga',
    marketValue: 100000,
  },
  {
    jerseyNumber: 16,
    name: 'Philip Heise',
    dateOfBirth: '20.06.1991',
    nationality: 'Deutschland',
    height: 1.84,
    foot: 'links',
    teamMemberSince: '01.07.2021',
    club: 'Karlsruher SC',
    previousClub: ': Ablöse ablösefrei',
    uid: 'e921c036-253a-48a9-b84f-fad288d559c0',
    position: 'Linker Verteidiger',
    league: '2. Bundesliga',
    marketValue: 700000,
  },
  {
    jerseyNumber: 21,
    name: 'Marco Thiede',
    dateOfBirth: '20.05.1992',
    nationality: 'Deutschland',
    height: 1.78,
    foot: 'rechts',
    teamMemberSince: '25.09.2017',
    club: 'Karlsruher SC',
    previousClub: 'Vereinslos',
    uid: '8cdc5e33-a4be-4c54-9e30-2d624da9f1b3',
    position: 'Rechter Verteidiger',
    league: '2. Bundesliga',
    marketValue: 500000,
  },
  {
    jerseyNumber: 2,
    name: 'Sebastian Jung',
    dateOfBirth: '22.06.1990',
    nationality: 'Deutschland',
    height: 1.79,
    foot: 'rechts',
    teamMemberSince: '22.09.2020',
    club: 'Karlsruher SC',
    previousClub: ': Ablöse ablösefrei',
    uid: 'f1c5e882-96e9-43e8-bb8b-be6b6a5f6511',
    position: 'Rechter Verteidiger',
    league: '2. Bundesliga',
    marketValue: 300000,
  },
  {
    jerseyNumber: 18,
    name: 'Daniel Brosinski',
    dateOfBirth: '17.07.1988',
    nationality: 'Deutschland',
    height: 1.78,
    foot: 'rechts',
    teamMemberSince: '03.01.2023',
    club: 'Karlsruher SC',
    previousClub: 'Vereinslos',
    uid: 'ffd8f422-1cab-486c-9129-2a3d5008aeeb',
    position: 'Rechter Verteidiger',
    league: '2. Bundesliga',
    marketValue: 300000,
  },
  {
    jerseyNumber: 38,
    name: 'Tim Breithaupt',
    dateOfBirth: '07.02.2002',
    nationality: 'Deutschland',
    height: 1.92,
    foot: 'rechts',
    teamMemberSince: '26.01.2021',
    club: 'Karlsruher SC',
    previousClub: 'Karlsruher SC U19',
    uid: 'e7cf5ee7-a0af-49ab-9aeb-f1665783ca7e',
    position: 'Defensives Mittelfeld',
    league: '2. Bundesliga',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 10,
    name: 'Marvin Wanitzek',
    dateOfBirth: '07.05.1993',
    nationality: 'Deutschland',
    height: 1.79,
    foot: 'rechts',
    teamMemberSince: '01.07.2017',
    club: 'Karlsruher SC',
    previousClub: 'VfB Stuttgart II',
    uid: 'b402abe8-473d-4e05-a4c2-2526f101b181',
    position: 'Zentrales Mittelfeld',
    league: '2. Bundesliga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 8,
    name: 'Jérôme Gondorf',
    dateOfBirth: '26.06.1988',
    nationality: 'Deutschland',
    height: 1.76,
    foot: 'rechts',
    teamMemberSince: '01.07.2020',
    club: 'Karlsruher SC',
    previousClub: 'SC Freiburg',
    uid: 'df75aecb-6fec-4211-95fb-0cbc3ce30b70',
    position: 'Zentrales Mittelfeld',
    league: '2. Bundesliga',
    marketValue: 400000,
  },
  {
    jerseyNumber: 6,
    name: 'Leon Jensen',
    dateOfBirth: '19.05.1997',
    nationality: 'Deutschland',
    height: 1.75,
    foot: 'beidfüßig',
    teamMemberSince: '01.07.2021',
    club: 'Karlsruher SC',
    previousClub: 'FSV Zwickau',
    uid: '05392ca9-c944-4648-8535-8d1e620d043b',
    position: 'Zentrales Mittelfeld',
    league: '2. Bundesliga',
    marketValue: 350000,
  },
  {
    jerseyNumber: 11,
    name: 'Kyoung-rok Choi',
    dateOfBirth: '15.03.1995',
    nationality: 'Südkorea',
    height: 1.76,
    foot: 'beidfüßig',
    teamMemberSince: '01.07.2018',
    club: 'Karlsruher SC',
    previousClub: 'FC St. Pauli',
    uid: '925bed67-10d7-4ed6-b40b-8b19f05ca6a6',
    position: 'Offensives Mittelfeld',
    league: '2. Bundesliga',
    marketValue: 800000,
  },
  {
    jerseyNumber: 17,
    name: 'Lucas Cueto',
    dateOfBirth: '24.03.1996',
    nationality: 'Deutschland',
    height: 1.75,
    foot: 'rechts',
    teamMemberSince: '01.07.2021',
    club: 'Karlsruher SC',
    previousClub: 'FC Viktoria Köln',
    uid: 'f322f60d-a14c-4c1e-b84e-15c3e270a752',
    position: 'Linksaußen',
    league: '2. Bundesliga',
    marketValue: 350000,
  },
  {
    jerseyNumber: 31,
    name: 'Tim Rossmann',
    dateOfBirth: '11.11.2003',
    nationality: 'Deutschland',
    height: 1.83,
    foot: 'links',
    teamMemberSince: '01.07.2022',
    club: 'Karlsruher SC',
    previousClub: 'Karlsruher SC U19',
    uid: '596d1126-1619-4de1-8357-618f28d883f5',
    position: 'Linksaußen',
    league: '2. Bundesliga',
    marketValue: 300000,
  },
  {
    jerseyNumber: 26,
    name: 'Paul Nebel',
    dateOfBirth: '10.10.2002',
    nationality: 'Deutschland',
    height: 1.69,
    foot: 'beidfüßig',
    teamMemberSince: '01.07.2022',
    club: 'Karlsruher SC',
    previousClub: '1.FSV Mainz 05',
    uid: 'f2e972dd-b2ca-4119-ac36-11ee1f225f8d',
    position: 'Rechtsaußen',
    league: '2. Bundesliga',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 14,
    name: 'Mikkel Kaufmann',
    dateOfBirth: '03.01.2001',
    nationality: 'Dänemark',
    height: 1.9,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'Karlsruher SC',
    previousClub: 'FC Kopenhagen',
    uid: '3f21cb34-d529-4913-8914-515adce34f72',
    position: 'Mittelstürmer',
    league: '2. Bundesliga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 19,
    name: 'Budu Zivzivadze',
    dateOfBirth: '10.03.1994',
    nationality: 'Georgien',
    height: 1.89,
    foot: 'beidfüßig',
    teamMemberSince: '31.01.2023',
    club: 'Karlsruher SC',
    previousClub: 'MOL Fehérvár FC',
    uid: '111d6479-1501-444b-adcf-5961bc4f4b11',
    position: 'Mittelstürmer',
    league: '2. Bundesliga',
    marketValue: 700000,
  },
  {
    jerseyNumber: 24,
    name: 'Fabian Schleusener',
    dateOfBirth: '24.10.1991',
    nationality: 'Deutschland',
    height: 1.86,
    foot: 'beidfüßig',
    teamMemberSince: '01.07.2021',
    club: 'Karlsruher SC',
    previousClub: '1.FC Nürnberg',
    uid: 'a3181075-7582-4d97-bc1a-b79700fc54ee',
    position: 'Mittelstürmer',
    league: '2. Bundesliga',
    marketValue: 600000,
  },
  {
    jerseyNumber: 9,
    name: 'Malik Batmaz',
    dateOfBirth: '17.03.2000',
    nationality: 'Türkei',
    height: 1.8,
    foot: 'rechts',
    teamMemberSince: '15.10.2018',
    club: 'Karlsruher SC',
    previousClub: 'Karlsruher SC U19',
    uid: '536484c7-f092-4931-9d09-78485dc8ca25',
    position: 'Mittelstürmer',
    league: '2. Bundesliga',
    marketValue: 500000,
  },
  {
    jerseyNumber: 7,
    name: 'Simone Rapp',
    dateOfBirth: '01.10.1992',
    nationality: 'Schweiz',
    height: 1.93,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'Karlsruher SC',
    previousClub: 'FC Vaduz',
    uid: '49b4872a-4a2a-40b2-b459-42feabe01301',
    position: 'Mittelstürmer',
    league: '2. Bundesliga',
    marketValue: 400000,
  },
  {
    jerseyNumber: 21,
    name: 'Jannik Huth',
    dateOfBirth: '15.04.1994',
    nationality: 'Deutschland',
    height: 1.85,
    foot: 'rechts',
    teamMemberSince: '01.07.2019',
    club: 'SC Paderborn 07',
    previousClub: '1.FSV Mainz 05',
    uid: 'ffaaeda8-62bc-4a3e-80d1-8711c575c4b3',
    position: 'Torwart',
    league: '2. Bundesliga',
    marketValue: 700000,
  },
  {
    jerseyNumber: 17,
    name: 'Leopold Zingerle',
    dateOfBirth: '10.04.1994',
    nationality: 'Deutschland',
    height: 1.85,
    foot: 'rechts',
    teamMemberSince: '01.07.2017',
    club: 'SC Paderborn 07',
    previousClub: '1.FC Magdeburg',
    uid: '67949f3e-8516-436d-8cca-4c20bfb85826',
    position: 'Torwart',
    league: '2. Bundesliga',
    marketValue: 400000,
  },
  {
    jerseyNumber: 16,
    name: 'Pelle Boevink',
    dateOfBirth: '06.01.1998',
    nationality: 'Niederlande',
    height: 1.9,
    foot: 'beidfüßig',
    teamMemberSince: '26.07.2022',
    club: 'SC Paderborn 07',
    previousClub: 'VfB Oldenburg',
    uid: 'd26bc901-3262-4b59-bf0f-b76acd88605f',
    position: 'Torwart',
    league: '2. Bundesliga',
    marketValue: 225000,
  },
  {
    jerseyNumber: 3,
    name: 'Bashir Humphreys',
    dateOfBirth: '15.03.2003',
    nationality: 'England',
    height: 1.86,
    foot: 'links',
    teamMemberSince: '27.01.2023',
    club: 'SC Paderborn 07',
    previousClub: 'FC Chelsea U21',
    uid: '1941467a-768f-4d8a-861e-f5edd387a5fe',
    position: 'Innenverteidiger',
    league: '2. Bundesliga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 24,
    name: 'Jannis Heuer',
    dateOfBirth: '29.07.1999',
    nationality: 'Deutschland',
    height: 1.89,
    foot: 'rechts',
    teamMemberSince: '01.07.2021',
    club: 'SC Paderborn 07',
    previousClub: 'VfL Wolfsburg II (- 2021)',
    uid: '598ce3fe-2897-4481-8439-3d003eef9660',
    position: 'Innenverteidiger',
    league: '2. Bundesliga',
    marketValue: 800000,
  },
  {
    jerseyNumber: 33,
    name: 'Marcel Hoffmeier',
    dateOfBirth: '15.07.1999',
    nationality: 'Deutschland',
    height: 1.82,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'SC Paderborn 07',
    previousClub: 'Preußen Münster',
    uid: '51fed1dc-c022-4931-971c-82c5dcccdd9c',
    position: 'Innenverteidiger',
    league: '2. Bundesliga',
    marketValue: 800000,
  },
  {
    jerseyNumber: 4,
    name: 'Jasper van der Werff',
    dateOfBirth: '09.12.1998',
    nationality: 'Schweiz',
    height: 1.88,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'SC Paderborn 07',
    previousClub: 'Red Bull Salzburg',
    uid: 'efe8ac43-84d8-45d2-965a-cfc68a55a425',
    position: 'Innenverteidiger',
    league: '2. Bundesliga',
    marketValue: 700000,
  },
  {
    jerseyNumber: 31,
    name: 'Maximilian Rohr',
    dateOfBirth: '27.06.1995',
    nationality: 'Deutschland',
    height: 1.93,
    foot: 'beidfüßig',
    teamMemberSince: '31.08.2022',
    club: 'SC Paderborn 07',
    previousClub: 'Hamburger SV',
    uid: 'f83d6a73-9349-4fb9-adc7-b315e0b00add',
    position: 'Innenverteidiger',
    league: '2. Bundesliga',
    marketValue: 600000,
  },
  {
    jerseyNumber: 15,
    name: 'Tobias Müller',
    dateOfBirth: '08.07.1994',
    nationality: 'Deutschland',
    height: 1.88,
    foot: 'beidfüßig',
    teamMemberSince: '01.07.2022',
    club: 'SC Paderborn 07',
    previousClub: '1.FC Magdeburg',
    uid: '6e1c0f81-644e-4be1-be0c-c82ec3840a5f',
    position: 'Innenverteidiger',
    league: '2. Bundesliga',
    marketValue: 400000,
  },
  {
    jerseyNumber: 2,
    name: 'Uwe Hünemeier',
    dateOfBirth: '09.01.1986',
    nationality: 'Deutschland',
    height: 1.89,
    foot: 'rechts',
    teamMemberSince: '01.07.2018',
    club: 'SC Paderborn 07',
    previousClub: 'Brighton & Hove Albion',
    uid: '04489425-cf47-4664-a370-79d18d5e1a4d',
    position: 'Innenverteidiger',
    league: '2. Bundesliga',
    marketValue: 300000,
  },
  {
    jerseyNumber: 28,
    name: 'Jonas Carls',
    dateOfBirth: '25.03.1997',
    nationality: 'Deutschland',
    height: 1.79,
    foot: 'links',
    teamMemberSince: '01.07.2021',
    club: 'SC Paderborn 07',
    previousClub: 'FC Schalke 04',
    uid: '55e5f980-9ab7-4eb6-af67-bcf497a737a2',
    position: 'Linker Verteidiger',
    league: '2. Bundesliga',
    marketValue: 300000,
  },
  {
    jerseyNumber: 8,
    name: 'Ron Schallenberg',
    dateOfBirth: '06.10.1998',
    nationality: 'Deutschland',
    height: 1.85,
    foot: 'rechts',
    teamMemberSince: '01.07.2017',
    club: 'SC Paderborn 07',
    previousClub: 'SC Paderborn 07 II',
    uid: 'e7adbae4-73fe-4b7f-ac17-84dec08a8b26',
    position: 'Defensives Mittelfeld',
    league: '2. Bundesliga',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 6,
    name: 'Marco Schuster',
    dateOfBirth: '10.10.1995',
    nationality: 'Deutschland',
    height: 1.79,
    foot: 'rechts',
    teamMemberSince: '01.07.2021',
    club: 'SC Paderborn 07',
    previousClub: 'SV Waldhof Mannheim',
    uid: '3f3a67d8-020f-477a-9edf-42822a942a7d',
    position: 'Defensives Mittelfeld',
    league: '2. Bundesliga',
    marketValue: 650000,
  },
  {
    jerseyNumber: 27,
    name: 'Kai Klefisch',
    dateOfBirth: '03.12.1999',
    nationality: 'Deutschland',
    height: 1.88,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'SC Paderborn 07',
    previousClub: 'FC Viktoria Köln',
    uid: '7104fda9-eef8-44d0-a9e7-6ce7a89568e7',
    position: 'Defensives Mittelfeld',
    league: '2. Bundesliga',
    marketValue: 400000,
  },
  {
    jerseyNumber: 23,
    name: 'Raphael Obermair',
    dateOfBirth: '01.04.1996',
    nationality: 'Philippinen',
    height: 1.82,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'SC Paderborn 07',
    previousClub: '1.FC Magdeburg',
    uid: '4bf3dac0-ddef-495f-93ef-c23dc1919d5a',
    position: 'Linkes Mittelfeld',
    league: '2. Bundesliga',
    marketValue: 700000,
  },
  {
    jerseyNumber: 10,
    name: 'Julian Justvan',
    dateOfBirth: '02.04.1998',
    nationality: 'Deutschland',
    height: 1.77,
    foot: 'links',
    teamMemberSince: '15.09.2020',
    club: 'SC Paderborn 07',
    previousClub: 'VfL Wolfsburg II',
    uid: '3b7dd04a-f131-429d-ad91-7b06c207b02b',
    position: 'Offensives Mittelfeld',
    league: '2. Bundesliga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 30,
    name: 'Florent Muslija',
    dateOfBirth: '06.07.1998',
    nationality: 'Kosovo',
    height: 1.72,
    foot: 'rechts',
    teamMemberSince: '02.01.2022',
    club: 'SC Paderborn 07',
    previousClub: 'Hannover 96',
    uid: '2b96a729-1a6d-45ca-bbf5-b14316b8c8d2',
    position: 'Offensives Mittelfeld',
    league: '2. Bundesliga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 26,
    name: 'Sebastian Klaas',
    dateOfBirth: '30.06.1998',
    nationality: 'Deutschland',
    height: 1.8,
    foot: 'beidfüßig',
    teamMemberSince: '01.07.2022',
    club: 'SC Paderborn 07',
    previousClub: 'VfL Osnabrück',
    uid: '7b02b51a-f99e-40fd-8d84-056552cb2bf6',
    position: 'Offensives Mittelfeld',
    league: '2. Bundesliga',
    marketValue: 400000,
  },
  {
    jerseyNumber: 40,
    name: 'Niclas Nadj',
    dateOfBirth: '24.12.2000',
    nationality: 'Deutschland',
    height: 1.78,
    foot: 'rechts',
    teamMemberSince: '18.01.2023',
    club: 'SC Paderborn 07',
    previousClub: 'SC Weiche Flensburg 08',
    uid: '8f83d3c1-5496-44eb-b128-72602d9d503f',
    position: 'Offensives Mittelfeld',
    league: '2. Bundesliga',
    marketValue: 200000,
  },
  {
    jerseyNumber: 13,
    name: 'Robert Leipertz',
    dateOfBirth: '01.02.1993',
    nationality: 'Deutschland',
    height: 1.83,
    foot: 'links',
    teamMemberSince: '01.07.2022',
    club: 'SC Paderborn 07',
    previousClub: '1.FC Heidenheim 1846',
    uid: 'b90e1378-d0b4-4511-9723-2bd9ac637ca6',
    position: 'Rechtsaußen',
    league: '2. Bundesliga',
    marketValue: 800000,
  },
  {
    jerseyNumber: 11,
    name: 'Sirlord Conteh',
    dateOfBirth: '09.07.1996',
    nationality: 'Deutschland',
    height: 1.78,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'SC Paderborn 07',
    previousClub: '1.FC Magdeburg',
    uid: '4224a7a1-972a-45ad-ac00-e6db14a82094',
    position: 'Rechtsaußen',
    league: '2. Bundesliga',
    marketValue: 700000,
  },
  {
    jerseyNumber: 9,
    name: 'Marvin Pieringer',
    dateOfBirth: '04.10.1999',
    nationality: 'Deutschland',
    height: 1.91,
    foot: 'rechts',
    teamMemberSince: '28.07.2022',
    club: 'SC Paderborn 07',
    previousClub: 'FC Schalke 04',
    uid: '7820c446-7245-49e5-bfc3-8d76e845d414',
    position: 'Mittelstürmer',
    league: '2. Bundesliga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 36,
    name: 'Felix Platte',
    dateOfBirth: '11.02.1996',
    nationality: 'Deutschland',
    height: 1.9,
    foot: 'rechts',
    teamMemberSince: '01.07.2021',
    club: 'SC Paderborn 07',
    previousClub: 'SV Darmstadt 98',
    uid: '0c410545-053d-4a0f-a65e-c82b5ed9d319',
    position: 'Mittelstürmer',
    league: '2. Bundesliga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 18,
    name: 'Dennis Srbeny',
    dateOfBirth: '05.05.1994',
    nationality: 'Deutschland',
    height: 1.9,
    foot: 'rechts',
    teamMemberSince: '08.01.2020',
    club: 'SC Paderborn 07',
    previousClub: ': Ablöse 200 Tsd. €',
    uid: '980ec7fb-25fa-43da-a393-7aa6f4f31839',
    position: 'Mittelstürmer',
    league: '2. Bundesliga',
    marketValue: 800000,
  },
  {
    jerseyNumber: 7,
    name: 'Richmond Tachie',
    dateOfBirth: '21.04.1999',
    nationality: 'Deutschland',
    height: 1.77,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'SC Paderborn 07',
    previousClub: 'Borussia Dortmund II',
    uid: 'e3400ff9-4a54-4225-bd23-9cecfc07ceec',
    position: 'Mittelstürmer',
    league: '2. Bundesliga',
    marketValue: 350000,
  },
  {
    jerseyNumber: 1,
    name: 'Ron-Thorben Hoffmann',
    dateOfBirth: '04.04.1999',
    nationality: 'Deutschland',
    height: 1.92,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'Eintracht Braunschweig',
    previousClub: 'FC Bayern München',
    uid: '83f22bc9-e77d-4f3c-8135-eb8cd1bc46c7',
    position: 'Torwart',
    league: '2. Bundesliga',
    marketValue: 400000,
  },
  {
    jerseyNumber: 16,
    name: 'Jasmin Fejzic',
    dateOfBirth: '15.05.1986',
    nationality: 'Bosnien-Herzegowina',
    height: 1.98,
    foot: 'rechts',
    teamMemberSince: '07.01.2019',
    club: 'Eintracht Braunschweig',
    previousClub: '1.FC Magdeburg',
    uid: '2ff9cf70-3b02-48e7-acd0-8adc8cb2731c',
    position: 'Torwart',
    league: '2. Bundesliga',
    marketValue: 250000,
  },
  {
    jerseyNumber: 44,
    name: 'Linus Gechter',
    dateOfBirth: '27.02.2004',
    nationality: 'Deutschland',
    height: 1.9,
    foot: 'rechts',
    teamMemberSince: '01.01.2023',
    club: 'Eintracht Braunschweig',
    previousClub: 'Hertha BSC',
    uid: '7a6a05d7-7a6b-4282-a4d1-dc27c0d91d16',
    position: 'Innenverteidiger',
    league: '2. Bundesliga',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 18,
    name: 'Filip Benkovic',
    dateOfBirth: '13.07.1997',
    nationality: 'Kroatien',
    height: 1.94,
    foot: 'rechts',
    teamMemberSince: '01.09.2022',
    club: 'Eintracht Braunschweig',
    previousClub: 'Udinese Calcio',
    uid: '19ec0aee-9bd6-4766-ac4d-45b1ba89151d',
    position: 'Innenverteidiger',
    league: '2. Bundesliga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 15,
    name: 'Nathan de Medina',
    dateOfBirth: '08.10.1997',
    nationality: 'Belgien',
    height: 1.83,
    foot: 'rechts',
    teamMemberSince: '01.09.2022',
    club: 'Eintracht Braunschweig',
    previousClub: 'Arminia Bielefeld',
    uid: '13b2bbce-23f1-4760-9ce0-c4a5d80c805b',
    position: 'Innenverteidiger',
    league: '2. Bundesliga',
    marketValue: 900000,
  },
  {
    jerseyNumber: 29,
    name: 'Hasan Kurucay',
    dateOfBirth: '31.08.1997',
    nationality: 'Türkei',
    height: 1.87,
    foot: 'rechts',
    teamMemberSince: '24.01.2023',
    club: 'Eintracht Braunschweig',
    previousClub: 'Hamarkameratene',
    uid: '2562d68d-3304-4ce0-89e9-7f9ed72bb85d',
    position: 'Innenverteidiger',
    league: '2. Bundesliga',
    marketValue: 800000,
  },
  {
    jerseyNumber: 30,
    name: 'Brian Behrendt',
    dateOfBirth: '24.10.1991',
    nationality: 'Deutschland',
    height: 1.87,
    foot: 'rechts',
    teamMemberSince: '01.01.2021',
    club: 'Eintracht Braunschweig',
    previousClub: 'Arminia Bielefeld',
    uid: '35be26d2-2724-4043-92ad-82886d208036',
    position: 'Innenverteidiger',
    league: '2. Bundesliga',
    marketValue: 450000,
  },
  {
    jerseyNumber: 3,
    name: 'Saulo Decarli',
    dateOfBirth: '04.02.1992',
    nationality: 'Schweiz',
    height: 1.88,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'Eintracht Braunschweig',
    previousClub: 'VfL Bochum',
    uid: '315effa5-cee5-4558-90bb-bdd0b07e8fd3',
    position: 'Innenverteidiger',
    league: '2. Bundesliga',
    marketValue: 300000,
  },
  {
    jerseyNumber: 5,
    name: 'Philipp Strompf',
    dateOfBirth: '23.04.1998',
    nationality: 'Deutschland',
    height: 1.94,
    foot: 'links',
    teamMemberSince: '31.08.2021',
    club: 'Eintracht Braunschweig',
    previousClub: 'TSG 1899 Hoffenheim II',
    uid: 'cb418172-0e43-483f-aeee-fe6f948573e3',
    position: 'Innenverteidiger',
    league: '2. Bundesliga',
    marketValue: 200000,
  },
  {
    jerseyNumber: 19,
    name: 'Anton Donkor',
    dateOfBirth: '11.11.1997',
    nationality: 'Deutschland',
    height: 1.86,
    foot: 'beidfüßig',
    teamMemberSince: '01.07.2022',
    club: 'Eintracht Braunschweig',
    previousClub: 'SV Waldhof Mannheim',
    uid: 'f16ef5e8-c55d-4465-9b42-0bc0e62ed1f1',
    position: 'Linker Verteidiger',
    league: '2. Bundesliga',
    marketValue: 500000,
  },
  {
    jerseyNumber: 27,
    name: 'Niko Kijewski',
    dateOfBirth: '28.03.1996',
    nationality: 'Deutschland',
    height: 1.76,
    foot: 'links',
    teamMemberSince: '01.07.2015',
    club: 'Eintracht Braunschweig',
    previousClub: 'Eintracht Braunschweig U19',
    uid: 'bbf27494-923a-40d4-bdfd-c3110d31f391',
    position: 'Linker Verteidiger',
    league: '2. Bundesliga',
    marketValue: 300000,
  },
  {
    jerseyNumber: 26,
    name: 'Jan-Hendrik Marx',
    dateOfBirth: '26.04.1995',
    nationality: 'Deutschland',
    height: 1.73,
    foot: 'beidfüßig',
    teamMemberSince: '01.01.2022',
    club: 'Eintracht Braunschweig',
    previousClub: 'FC Ingolstadt 04',
    uid: 'fbd4be96-0847-4a67-84f5-b93bc66420a1',
    position: 'Rechter Verteidiger',
    league: '2. Bundesliga',
    marketValue: 350000,
  },
  {
    jerseyNumber: 4,
    name: 'Jannis Nikolaou',
    dateOfBirth: '31.07.1993',
    nationality: 'Deutschland',
    height: 1.87,
    foot: 'beidfüßig',
    teamMemberSince: '15.07.2020',
    club: 'Eintracht Braunschweig',
    previousClub: 'SG Dynamo Dresden',
    uid: 'e1e1f35a-9985-4f4e-b6c2-bdafe4c089c7',
    position: 'Defensives Mittelfeld',
    league: '2. Bundesliga',
    marketValue: 600000,
  },
  {
    jerseyNumber: 39,
    name: 'Robin Krauße',
    dateOfBirth: '02.04.1994',
    nationality: 'Deutschland',
    height: 1.79,
    foot: 'rechts',
    teamMemberSince: '01.07.2021',
    club: 'Eintracht Braunschweig',
    previousClub: 'FC Ingolstadt 04',
    uid: '5cde20f5-4335-4c9b-89a4-95bf08bda822',
    position: 'Defensives Mittelfeld',
    league: '2. Bundesliga',
    marketValue: 500000,
  },
  {
    jerseyNumber: 23,
    name: 'Danilo Wiebe',
    dateOfBirth: '22.03.1994',
    nationality: 'Deutschland',
    height: 1.87,
    foot: 'rechts',
    teamMemberSince: '03.07.2019',
    club: 'Eintracht Braunschweig',
    previousClub: 'Preußen Münster',
    uid: 'a678eee9-3335-4969-b9f1-75f626bc7ed0',
    position: 'Defensives Mittelfeld',
    league: '2. Bundesliga',
    marketValue: 300000,
  },
  {
    jerseyNumber: 6,
    name: 'Bryan Henning',
    dateOfBirth: '16.03.1995',
    nationality: 'Deutschland',
    height: 1.72,
    foot: 'rechts',
    teamMemberSince: '01.07.2021',
    club: 'Eintracht Braunschweig',
    previousClub: 'VfL Osnabrück',
    uid: '046b91ae-6c2c-4280-b68f-83fd6f6fcfed',
    position: 'Zentrales Mittelfeld',
    league: '2. Bundesliga',
    marketValue: 450000,
  },
  {
    jerseyNumber: 37,
    name: 'Fabio Kaufmann',
    dateOfBirth: '08.09.1992',
    nationality: 'Deutschland',
    height: 1.81,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'Eintracht Braunschweig',
    previousClub: 'Karlsruher SC',
    uid: '810c1b1e-96b5-4a89-8087-cd6aefb26eef',
    position: 'Rechtes Mittelfeld',
    league: '2. Bundesliga',
    marketValue: 400000,
  },
  {
    jerseyNumber: 10,
    name: 'Immanuel Pherai',
    dateOfBirth: '25.04.2001',
    nationality: 'Niederlande',
    height: 1.75,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'Eintracht Braunschweig',
    previousClub: 'Borussia Dortmund II',
    uid: '2c869671-7699-4451-8471-9c7bdcaeaf7b',
    position: 'Offensives Mittelfeld',
    league: '2. Bundesliga',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 21,
    name: 'Keita Endo',
    dateOfBirth: '22.11.1997',
    nationality: 'Japan',
    height: 1.75,
    foot: 'rechts',
    teamMemberSince: '19.07.2022',
    club: 'Eintracht Braunschweig',
    previousClub: '1.FC Union Berlin',
    uid: '6ac7dfde-5826-467b-a697-030dc0662de1',
    position: 'Offensives Mittelfeld',
    league: '2. Bundesliga',
    marketValue: 700000,
  },
  {
    jerseyNumber: 22,
    name: 'Enrique Peña Zauner',
    dateOfBirth: '04.03.2000',
    nationality: 'Venezuela',
    height: 1.77,
    foot: 'beidfüßig',
    teamMemberSince: '05.08.2021',
    club: 'Eintracht Braunschweig',
    previousClub: 'SV Sandhausen',
    uid: '10fbf43d-1d86-4a76-88ea-3013e3d2b180',
    position: 'Linksaußen',
    league: '2. Bundesliga',
    marketValue: 275000,
  },
  {
    jerseyNumber: 8,
    name: 'Mehmet Ibrahimi',
    dateOfBirth: '09.02.2003',
    nationality: 'Deutschland',
    height: 1.82,
    foot: 'rechts',
    teamMemberSince: '02.07.2022',
    club: 'Eintracht Braunschweig',
    previousClub: 'RasenBallsport Leipzig',
    uid: '294028d6-c020-420c-b590-b8de04fa2ce5',
    position: 'Linksaußen',
    league: '2. Bundesliga',
    marketValue: 150000,
  },
  {
    jerseyNumber: 7,
    name: 'Maurice Multhaup',
    dateOfBirth: '15.12.1996',
    nationality: 'Deutschland',
    height: 1.73,
    foot: 'rechts',
    teamMemberSince: '01.07.2021',
    club: 'Eintracht Braunschweig',
    previousClub: 'VfL Osnabrück',
    uid: '4b73502b-9cc7-4310-befd-2a5cac33960a',
    position: 'Rechtsaußen',
    league: '2. Bundesliga',
    marketValue: 400000,
  },
  {
    jerseyNumber: 13,
    name: 'Tarsis Bonga',
    dateOfBirth: '10.01.1997',
    nationality: 'Deutschland',
    height: 1.97,
    foot: 'rechts',
    teamMemberSince: '24.01.2023',
    club: 'Eintracht Braunschweig',
    previousClub: 'VfL Bochum',
    uid: 'bb399b63-e847-49bd-87a0-4d2cc5801eb4',
    position: 'Rechtsaußen',
    league: '2. Bundesliga',
    marketValue: 300000,
  },
  {
    jerseyNumber: 9,
    name: 'Manuel Wintzheimer',
    dateOfBirth: '10.01.1999',
    nationality: 'Deutschland',
    height: 1.8,
    foot: 'beidfüßig',
    teamMemberSince: '07.01.2023',
    club: 'Eintracht Braunschweig',
    previousClub: '1.FC Nürnberg',
    uid: '63e46961-75df-4c69-b32d-bef3eb307130',
    position: 'Mittelstürmer',
    league: '2. Bundesliga',
    marketValue: 700000,
  },
  {
    jerseyNumber: 14,
    name: 'Anthony Ujah',
    dateOfBirth: '14.10.1990',
    nationality: 'Nigeria',
    height: 1.8,
    foot: 'rechts',
    teamMemberSince: '02.08.2022',
    club: 'Eintracht Braunschweig',
    previousClub: '1.FC Union Berlin',
    uid: '3c0083f0-ab1b-4dce-8cea-ec1da2aa4265',
    position: 'Mittelstürmer',
    league: '2. Bundesliga',
    marketValue: 600000,
  },
  {
    jerseyNumber: 20,
    name: 'Lion Lauberbach',
    dateOfBirth: '15.02.1998',
    nationality: 'Deutschland',
    height: 1.94,
    foot: 'links',
    teamMemberSince: '22.08.2021',
    club: 'Eintracht Braunschweig',
    previousClub: 'Holstein Kiel',
    uid: '1daeb697-c9c3-4529-99c3-10999f85d4d7',
    position: 'Mittelstürmer',
    league: '2. Bundesliga',
    marketValue: 450000,
  },
  {
    jerseyNumber: 11,
    name: 'Luc Ihorst',
    dateOfBirth: '07.03.2000',
    nationality: 'Deutschland',
    height: 1.9,
    foot: 'beidfüßig',
    teamMemberSince: '01.07.2022',
    club: 'Eintracht Braunschweig',
    previousClub: 'SV Werder Bremen II',
    uid: 'a498d12a-39e1-4115-9077-b4b8fa77edd3',
    position: 'Mittelstürmer',
    league: '2. Bundesliga',
    marketValue: 350000,
  },
  {
    jerseyNumber: 1,
    name: 'Tim Schreiber',
    dateOfBirth: '24.04.2002',
    nationality: 'Deutschland',
    height: 1.91,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'Holstein Kiel',
    previousClub: 'RasenBallsport Leipzig',
    uid: 'e2dc64f3-b100-40c5-96b2-c5f8ae21c32e',
    position: 'Torwart',
    league: '2. Bundesliga',
    marketValue: 600000,
  },
  {
    jerseyNumber: 21,
    name: 'Thomas Dähne',
    dateOfBirth: '04.01.1994',
    nationality: 'Deutschland',
    height: 1.93,
    foot: 'beidfüßig',
    teamMemberSince: '01.07.2020',
    club: 'Holstein Kiel',
    previousClub: 'Wisla Plock',
    uid: 'b89e278a-1584-457d-824c-541e8748a8a6',
    position: 'Torwart',
    league: '2. Bundesliga',
    marketValue: 600000,
  },
  {
    jerseyNumber: 35,
    name: 'Robin Himmelmann',
    dateOfBirth: '05.02.1989',
    nationality: 'Deutschland',
    height: 1.87,
    foot: 'rechts',
    teamMemberSince: '23.01.2023',
    club: 'Holstein Kiel',
    previousClub: 'Vereinslos',
    uid: '3e3658ca-899f-4574-b3e9-55d8b66a4957',
    position: 'Torwart',
    league: '2. Bundesliga',
    marketValue: 225000,
  },
  {
    jerseyNumber: 33,
    name: 'Timon Weiner',
    dateOfBirth: '18.01.1999',
    nationality: 'Deutschland',
    height: 1.91,
    foot: 'beidfüßig',
    teamMemberSince: '01.07.2018',
    club: 'Holstein Kiel',
    previousClub: 'FC Schalke 04 U19',
    uid: '430559d2-55e1-41a9-8f5d-a4cbe9144445',
    position: 'Torwart',
    league: '2. Bundesliga',
    marketValue: 100000,
  },
  {
    jerseyNumber: 24,
    name: 'Hauke Wahl',
    dateOfBirth: '15.04.1994',
    nationality: 'Deutschland',
    height: 1.89,
    foot: 'rechts',
    teamMemberSince: '11.07.2018',
    club: 'Holstein Kiel',
    previousClub: 'FC Ingolstadt 04',
    uid: '50b0bc45-c867-4a44-a3c7-7b2fdc1eb566',
    position: 'Innenverteidiger',
    league: '2. Bundesliga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 3,
    name: 'Marco Komenda',
    dateOfBirth: '26.11.1996',
    nationality: 'Deutschland',
    height: 1.84,
    foot: 'links',
    teamMemberSince: '15.07.2020',
    club: 'Holstein Kiel',
    previousClub: 'SV Meppen',
    uid: 'b383394d-c3b6-41da-bfed-d3b935161f2c',
    position: 'Innenverteidiger',
    league: '2. Bundesliga',
    marketValue: 600000,
  },
  {
    jerseyNumber: 17,
    name: 'Timo Becker',
    dateOfBirth: '25.03.1997',
    nationality: 'Deutschland',
    height: 1.9,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'Holstein Kiel',
    previousClub: 'FC Schalke 04',
    uid: '60455833-a0ad-4450-9d78-3b1c2ccfbb8c',
    position: 'Innenverteidiger',
    league: '2. Bundesliga',
    marketValue: 600000,
  },
  {
    jerseyNumber: 19,
    name: 'Simon Lorenz',
    dateOfBirth: '30.03.1997',
    nationality: 'Deutschland',
    height: 1.87,
    foot: 'rechts',
    teamMemberSince: '01.07.2020',
    club: 'Holstein Kiel',
    previousClub: 'VfL Bochum',
    uid: 'de06e5b4-1ee2-4004-884c-9f78e675c9b2',
    position: 'Innenverteidiger',
    league: '2. Bundesliga',
    marketValue: 600000,
  },
  {
    jerseyNumber: 5,
    name: 'Stefan Thesker',
    dateOfBirth: '11.04.1991',
    nationality: 'Deutschland',
    height: 1.9,
    foot: 'links',
    teamMemberSince: '01.07.2018',
    club: 'Holstein Kiel',
    previousClub: 'FC Twente Enschede',
    uid: '7f310adb-f5f5-4803-b035-71a7b5026b9b',
    position: 'Innenverteidiger',
    league: '2. Bundesliga',
    marketValue: 300000,
  },
  {
    jerseyNumber: 2,
    name: 'Mikkel Kirkeskov',
    dateOfBirth: '05.09.1991',
    nationality: 'Dänemark',
    height: 1.85,
    foot: 'links',
    teamMemberSince: '01.01.2021',
    club: 'Holstein Kiel',
    previousClub: 'Piast Gliwice',
    uid: 'fd3d50ba-8fe7-4e27-b31c-d7413a818125',
    position: 'Linker Verteidiger',
    league: '2. Bundesliga',
    marketValue: 400000,
  },
  {
    jerseyNumber: 23,
    name: 'Julian Korb',
    dateOfBirth: '21.03.1992',
    nationality: 'Deutschland',
    height: 1.77,
    foot: 'rechts',
    teamMemberSince: '01.07.2021',
    club: 'Holstein Kiel',
    previousClub: 'Vereinslos',
    uid: 'e69dbe2a-c10e-464c-a5e9-deeec4c74b43',
    position: 'Rechter Verteidiger',
    league: '2. Bundesliga',
    marketValue: 400000,
  },
  {
    jerseyNumber: 25,
    name: 'Marvin Schulz',
    dateOfBirth: '15.01.1995',
    nationality: 'Deutschland',
    height: 1.86,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'Holstein Kiel',
    previousClub: 'FC Luzern',
    uid: '96e1b0df-1576-46a0-a43d-025ef767aabe',
    position: 'Defensives Mittelfeld',
    league: '2. Bundesliga',
    marketValue: 800000,
  },
  {
    jerseyNumber: 4,
    name: 'Patrick Erras',
    dateOfBirth: '21.01.1995',
    nationality: 'Deutschland',
    height: 1.96,
    foot: 'rechts',
    teamMemberSince: '02.07.2021',
    club: 'Holstein Kiel',
    previousClub: 'SV Werder Bremen',
    uid: '027f4225-ebf6-4ce0-8d5f-bfd11a83dc4e',
    position: 'Defensives Mittelfeld',
    league: '2. Bundesliga',
    marketValue: 700000,
  },
  {
    jerseyNumber: 22,
    name: 'Aleksandar Ignjovski',
    dateOfBirth: '27.01.1991',
    nationality: 'Serbien',
    height: 1.75,
    foot: 'rechts',
    teamMemberSince: '21.07.2019',
    club: 'Holstein Kiel',
    previousClub: '1.FC Magdeburg',
    uid: '23f7a22e-0d47-43e0-89bb-a1bdfb8dd31d',
    position: 'Defensives Mittelfeld',
    league: '2. Bundesliga',
    marketValue: 300000,
  },
  {
    jerseyNumber: 6,
    name: 'Marcel Benger',
    dateOfBirth: '02.07.1998',
    nationality: 'Deutschland',
    height: 1.88,
    foot: 'rechts',
    teamMemberSince: '01.07.2021',
    club: 'Holstein Kiel',
    previousClub: 'Borussia Mönchengladbach II',
    uid: '288265cd-2ee2-4114-97d5-73e398dbc3fb',
    position: 'Defensives Mittelfeld',
    league: '2. Bundesliga',
    marketValue: 250000,
  },
  {
    jerseyNumber: 8,
    name: 'Alexander Mühling',
    dateOfBirth: '05.09.1992',
    nationality: 'Deutschland',
    height: 1.86,
    foot: 'rechts',
    teamMemberSince: '01.07.2016',
    club: 'Holstein Kiel',
    previousClub: ': Ablöse ablösefrei',
    uid: '9a5ea19a-8cc0-4bfe-9c28-e045f051b931',
    position: 'Zentrales Mittelfeld',
    league: '2. Bundesliga',
    marketValue: 800000,
  },
  {
    jerseyNumber: 16,
    name: 'Philipp Sander',
    dateOfBirth: '21.02.1998',
    nationality: 'Deutschland',
    height: 1.85,
    foot: 'beidfüßig',
    teamMemberSince: '01.07.2018',
    club: 'Holstein Kiel',
    previousClub: 'Holstein Kiel II',
    uid: 'c5f5b4e8-cf17-499b-974c-e4526f243ec6',
    position: 'Zentrales Mittelfeld',
    league: '2. Bundesliga',
    marketValue: 650000,
  },
  {
    jerseyNumber: 10,
    name: 'Lewis Holtby',
    dateOfBirth: '18.09.1990',
    nationality: 'Deutschland',
    height: 1.76,
    foot: 'links',
    teamMemberSince: '17.08.2021',
    club: 'Holstein Kiel',
    previousClub: 'Blackburn Rovers',
    uid: 'f031ee0d-73e2-4d25-87db-66d991b8980e',
    position: 'Zentrales Mittelfeld',
    league: '2. Bundesliga',
    marketValue: 500000,
  },
  {
    jerseyNumber: 27,
    name: 'Finn Porath',
    dateOfBirth: '23.02.1997',
    nationality: 'Deutschland',
    height: 1.8,
    foot: 'rechts',
    teamMemberSince: '01.07.2019',
    club: 'Holstein Kiel',
    previousClub: ': Ablöse ?',
    uid: '59479126-29c4-4cb8-8adf-d8bccfe3bf0d',
    position: 'Offensives Mittelfeld',
    league: '2. Bundesliga',
    marketValue: 600000,
  },
  {
    jerseyNumber: 11,
    name: 'Fabian Reese',
    dateOfBirth: '29.11.1997',
    nationality: 'Deutschland',
    height: 1.87,
    foot: 'rechts',
    teamMemberSince: '03.01.2020',
    club: 'Holstein Kiel',
    previousClub: 'FC Schalke 04',
    uid: 'e62af602-66b5-47dd-b6fb-13508edf9d6d',
    position: 'Linksaußen',
    league: '2. Bundesliga',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 30,
    name: 'Marvin Obuz',
    dateOfBirth: '25.01.2002',
    nationality: 'Deutschland',
    height: 1.82,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'Holstein Kiel',
    previousClub: '1.FC Köln',
    uid: '80e46332-1e37-4218-aec9-b1483c7fae2f',
    position: 'Linksaußen',
    league: '2. Bundesliga',
    marketValue: 300000,
  },
  {
    jerseyNumber: 31,
    name: 'Fin Bartels',
    dateOfBirth: '07.02.1987',
    nationality: 'Deutschland',
    height: 1.76,
    foot: 'rechts',
    teamMemberSince: '05.08.2020',
    club: 'Holstein Kiel',
    previousClub: 'SV Werder Bremen',
    uid: 'c219a2ff-3f96-4599-bb13-44eb7de6d473',
    position: 'Rechtsaußen',
    league: '2. Bundesliga',
    marketValue: 400000,
  },
  {
    jerseyNumber: 7,
    name: 'Steven Skrzybski',
    dateOfBirth: '18.11.1992',
    nationality: 'Deutschland',
    height: 1.74,
    foot: 'rechts',
    teamMemberSince: '05.07.2021',
    club: 'Holstein Kiel',
    previousClub: 'FC Schalke 04',
    uid: '13ee870f-1686-4270-849f-a2d5073aa3b4',
    position: 'Mittelstürmer',
    league: '2. Bundesliga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 9,
    name: 'Benedikt Pichler',
    dateOfBirth: '20.07.1997',
    nationality: 'Österreich',
    height: 1.87,
    foot: 'rechts',
    teamMemberSince: '30.08.2021',
    club: 'Holstein Kiel',
    previousClub: 'FK Austria Wien',
    uid: 'e96376c2-4a99-4df1-827a-bbef952211b0',
    position: 'Mittelstürmer',
    league: '2. Bundesliga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 18,
    name: 'Kwasi Wriedt',
    dateOfBirth: '10.07.1994',
    nationality: 'Ghana',
    height: 1.88,
    foot: 'links',
    teamMemberSince: '20.01.2022',
    club: 'Holstein Kiel',
    previousClub: ': Ablöse 500 Tsd. €',
    uid: 'd0211757-d1f3-4225-8793-c5f75dd1be5e',
    position: 'Mittelstürmer',
    league: '2. Bundesliga',
    marketValue: 750000,
  },
  {
    jerseyNumber: 20,
    name: 'Fiete Arp',
    dateOfBirth: '06.01.2000',
    nationality: 'Deutschland',
    height: 1.86,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'Holstein Kiel',
    previousClub: 'FC Bayern München II',
    uid: '5ae798d6-ab57-4af0-b142-c529c5c2e34b',
    position: 'Mittelstürmer',
    league: '2. Bundesliga',
    marketValue: 500000,
  },
  {
    jerseyNumber: 36,
    name: 'Hólmbert Aron Fridjónsson',
    dateOfBirth: '19.04.1993',
    nationality: 'Island',
    height: 1.95,
    foot: 'links',
    teamMemberSince: '01.07.2021',
    club: 'Holstein Kiel',
    previousClub: 'Brescia Calcio',
    uid: 'b62e83db-5327-459d-9a2a-5bbc604c432a',
    position: 'Mittelstürmer',
    league: '2. Bundesliga',
    marketValue: 300000,
  },
  {
    jerseyNumber: 28,
    name: 'Noah Awuku',
    dateOfBirth: '09.01.2000',
    nationality: 'Deutschland',
    height: 1.82,
    foot: 'rechts',
    teamMemberSince: '01.07.2017',
    club: 'Holstein Kiel',
    previousClub: 'Holstein Kiel U17',
    uid: '2dd4fae1-6605-4f13-81c5-d968e782bab0',
    position: 'Mittelstürmer',
    league: '2. Bundesliga',
    marketValue: 100000,
  },
  {
    jerseyNumber: 1,
    name: 'Patrick Drewes',
    dateOfBirth: '04.02.1993',
    nationality: 'Deutschland',
    height: 1.94,
    foot: 'rechts',
    teamMemberSince: '01.07.2021',
    club: 'SV Sandhausen',
    previousClub: 'VfL Bochum',
    uid: '99edc214-d122-40ae-bf59-ab42414fc70b',
    position: 'Torwart',
    league: '2. Bundesliga',
    marketValue: 600000,
  },
  {
    jerseyNumber: 33,
    name: 'Nikolai Rehnen',
    dateOfBirth: '04.02.1997',
    nationality: 'Deutschland',
    height: 1.91,
    foot: 'rechts',
    teamMemberSince: '15.09.2021',
    club: 'SV Sandhausen',
    previousClub: 'Vereinslos',
    uid: '17d8820e-ce24-4efe-8a1c-6fe477da6813',
    position: 'Torwart',
    league: '2. Bundesliga',
    marketValue: 200000,
  },
  {
    jerseyNumber: 21,
    name: 'Timo Königsmann',
    dateOfBirth: '05.04.1997',
    nationality: 'Deutschland',
    height: 1.86,
    foot: 'rechts',
    teamMemberSince: '21.10.2022',
    club: 'SV Sandhausen',
    previousClub: 'Vereinslos',
    uid: '688c02a6-680a-4b2a-8965-1609814dd7ce',
    position: 'Torwart',
    league: '2. Bundesliga',
    marketValue: 175000,
  },
  {
    jerseyNumber: 40,
    name: 'Benedikt Grawe',
    dateOfBirth: '21.02.2000',
    nationality: 'Deutschland',
    height: 1.9,
    foot: 'rechts',
    teamMemberSince: '01.07.2020',
    club: 'SV Sandhausen',
    previousClub: 'SV Sandhausen II',
    uid: '70ab9932-498f-4ff7-b6d9-05ad9e8b189b',
    position: 'Torwart',
    league: '2. Bundesliga',
    marketValue: 50000,
  },
  {
    jerseyNumber: 3,
    name: 'Dario Dumic',
    dateOfBirth: '30.01.1992',
    nationality: 'Bosnien-Herzegowina',
    height: 1.93,
    foot: 'rechts',
    teamMemberSince: '04.01.2022',
    club: 'SV Sandhausen',
    previousClub: 'FC Twente Enschede',
    uid: '8363b605-e5df-4dc0-ad92-fa72219aa665',
    position: 'Innenverteidiger',
    league: '2. Bundesliga',
    marketValue: 800000,
  },
  {
    jerseyNumber: 2,
    name: 'Aleksandr Zhirov',
    dateOfBirth: '24.01.1991',
    nationality: 'Russland',
    height: 1.93,
    foot: 'links',
    teamMemberSince: '01.07.2018',
    club: 'SV Sandhausen',
    previousClub: 'Enisey Krasnoyarsk',
    uid: '031aeadd-5182-434b-9f35-7696d01207a3',
    position: 'Innenverteidiger',
    league: '2. Bundesliga',
    marketValue: 600000,
  },
  {
    jerseyNumber: 15,
    name: 'Immanuel Höhn',
    dateOfBirth: '23.12.1991',
    nationality: 'Deutschland',
    height: 1.83,
    foot: 'rechts',
    teamMemberSince: '01.07.2021',
    club: 'SV Sandhausen',
    previousClub: 'SV Darmstadt 98',
    uid: '35dde4b5-963f-4b43-b405-8c966a5d73b7',
    position: 'Innenverteidiger',
    league: '2. Bundesliga',
    marketValue: 500000,
  },
  {
    jerseyNumber: 25,
    name: 'Oumar Diakhite',
    dateOfBirth: '09.12.1993',
    nationality: 'Senegal',
    height: 1.9,
    foot: 'rechts',
    teamMemberSince: '01.07.2021',
    club: 'SV Sandhausen',
    previousClub: 'Eintracht Braunschweig',
    uid: '98c87fc7-22f5-4c9d-a0a4-86919ff784c0',
    position: 'Innenverteidiger',
    league: '2. Bundesliga',
    marketValue: 300000,
  },
  {
    jerseyNumber: 36,
    name: 'Chima Okoroji',
    dateOfBirth: '19.04.1997',
    nationality: 'Deutschland',
    height: 1.8,
    foot: 'links',
    teamMemberSince: '01.07.2021',
    club: 'SV Sandhausen',
    previousClub: 'SC Freiburg',
    uid: '7206657f-cabe-4946-b860-63bf686650b6',
    position: 'Linker Verteidiger',
    league: '2. Bundesliga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 20,
    name: 'Kerim Calhanoglu',
    dateOfBirth: '26.08.2002',
    nationality: 'Deutschland',
    height: 1.83,
    foot: 'links',
    teamMemberSince: '01.01.2023',
    club: 'SV Sandhausen',
    previousClub: 'FC Schalke 04',
    uid: 'bb364306-5b2c-42ac-a5f9-6636cea9dbf3',
    position: 'Linker Verteidiger',
    league: '2. Bundesliga',
    marketValue: 800000,
  },
  {
    jerseyNumber: 27,
    name: 'Arne Sicker',
    dateOfBirth: '17.04.1997',
    nationality: 'Deutschland',
    height: 1.84,
    foot: 'links',
    teamMemberSince: '01.07.2021',
    club: 'SV Sandhausen',
    previousClub: ': Ablöse ablösefrei',
    uid: '2e92c0eb-f289-4530-950a-6a15d86e7c8f',
    position: 'Linker Verteidiger',
    league: '2. Bundesliga',
    marketValue: 300000,
  },
  {
    jerseyNumber: 32,
    name: 'Raphael Framberger',
    dateOfBirth: '06.09.1995',
    nationality: 'Deutschland',
    height: 1.79,
    foot: 'rechts',
    teamMemberSince: '11.01.2023',
    club: 'SV Sandhausen',
    previousClub: 'FC Augsburg',
    uid: 'b1411b38-acb6-4612-8ba4-2ebbe25546bc',
    position: 'Rechter Verteidiger',
    league: '2. Bundesliga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 19,
    name: 'Bashkim Ajdini',
    dateOfBirth: '10.12.1992',
    nationality: 'Deutschland',
    height: 1.78,
    foot: 'beidfüßig',
    teamMemberSince: '01.07.2021',
    club: 'SV Sandhausen',
    previousClub: 'VfL Osnabrück',
    uid: '81ad6865-741c-4199-8284-971a58ed8c9d',
    position: 'Rechter Verteidiger',
    league: '2. Bundesliga',
    marketValue: 550000,
  },
  {
    jerseyNumber: 18,
    name: 'Dennis Diekmeier',
    dateOfBirth: '20.10.1989',
    nationality: 'Deutschland',
    height: 1.88,
    foot: 'rechts',
    teamMemberSince: '03.01.2019',
    club: 'SV Sandhausen',
    previousClub: 'Vereinslos',
    uid: '6110df63-baf6-4dbf-90c4-f88739dd5b49',
    position: 'Rechter Verteidiger',
    league: '2. Bundesliga',
    marketValue: 300000,
  },
  {
    jerseyNumber: 17,
    name: 'Erik Zenga',
    dateOfBirth: '18.01.1993',
    nationality: 'Deutschland',
    height: 1.81,
    foot: 'rechts',
    teamMemberSince: '01.07.2015',
    club: 'SV Sandhausen',
    previousClub: ': Ablöse ablösefrei',
    uid: 'b7995631-67c3-4c2d-925d-e810cb94c987',
    position: 'Defensives Mittelfeld',
    league: '2. Bundesliga',
    marketValue: 500000,
  },
  {
    jerseyNumber: 10,
    name: 'David Kinsombi',
    dateOfBirth: '12.12.1995',
    nationality: 'Deutschland',
    height: 1.83,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'SV Sandhausen',
    previousClub: 'Hamburger SV',
    uid: '36660e57-1880-4a25-96d3-9947689d0733',
    position: 'Zentrales Mittelfeld',
    league: '2. Bundesliga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 24,
    name: 'Merveille Papela',
    dateOfBirth: '18.01.2001',
    nationality: 'Deutschland',
    height: 1.74,
    foot: 'rechts',
    teamMemberSince: '24.08.2022',
    club: 'SV Sandhausen',
    previousClub: '1.FSV Mainz 05',
    uid: '75cf5ebc-5bc4-43f0-bd4a-f6dc0cd19c4b',
    position: 'Zentrales Mittelfeld',
    league: '2. Bundesliga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 26,
    name: 'Janik Bachmann',
    dateOfBirth: '06.05.1996',
    nationality: 'Deutschland',
    height: 1.96,
    foot: 'rechts',
    teamMemberSince: '28.01.2021',
    club: 'SV Sandhausen',
    previousClub: '1.FC Kaiserslautern',
    uid: 'd5e3144d-8541-43c2-b864-9f6f790634f8',
    position: 'Zentrales Mittelfeld',
    league: '2. Bundesliga',
    marketValue: 650000,
  },
  {
    jerseyNumber: 22,
    name: 'Marcel Ritzmaier',
    dateOfBirth: '22.04.1993',
    nationality: 'Österreich',
    height: 1.8,
    foot: 'links',
    teamMemberSince: '05.08.2021',
    club: 'SV Sandhausen',
    previousClub: 'FC Barnsley',
    uid: '52649eb4-f15a-41e5-b84c-0fadcaa752c8',
    position: 'Zentrales Mittelfeld',
    league: '2. Bundesliga',
    marketValue: 500000,
  },
  {
    jerseyNumber: 5,
    name: 'Marcel Mehlem',
    dateOfBirth: '01.03.1995',
    nationality: 'Deutschland',
    height: 1.76,
    foot: 'rechts',
    teamMemberSince: '31.01.2023',
    club: 'SV Sandhausen',
    previousClub: 'SC Paderborn 07',
    uid: 'c0de1f04-9158-4cbe-ad14-0d842d7c790f',
    position: 'Zentrales Mittelfeld',
    league: '2. Bundesliga',
    marketValue: 450000,
  },
  {
    jerseyNumber: 37,
    name: 'Josef Ganda',
    dateOfBirth: '10.03.1997',
    nationality: 'Israel',
    height: 1.86,
    foot: 'beidfüßig',
    teamMemberSince: '04.07.2022',
    club: 'SV Sandhausen',
    previousClub: 'FC Admira Wacker Mödling',
    uid: 'a72398da-b8e6-4193-86cd-83d94dbb91ef',
    position: 'Rechtes Mittelfeld',
    league: '2. Bundesliga',
    marketValue: 300000,
  },
  {
    jerseyNumber: 8,
    name: 'Christian Kinsombi',
    dateOfBirth: '24.08.1999',
    nationality: 'Deutschland',
    height: 1.77,
    foot: 'beidfüßig',
    teamMemberSince: '01.07.2021',
    club: 'SV Sandhausen',
    previousClub: 'KFC Uerdingen 05',
    uid: 'cabed6e6-a20c-45cc-81d4-ccaf185d57ad',
    position: 'Linksaußen',
    league: '2. Bundesliga',
    marketValue: 650000,
  },
  {
    jerseyNumber: 38,
    name: 'Franck Evina',
    dateOfBirth: '05.07.2000',
    nationality: 'Kamerun',
    height: 1.8,
    foot: 'rechts',
    teamMemberSince: '01.01.2023',
    club: 'SV Sandhausen',
    previousClub: 'Hannover 96 II',
    uid: 'd4208ebd-8af8-4724-b99c-3efac63cfd1a',
    position: 'Linksaußen',
    league: '2. Bundesliga',
    marketValue: 400000,
  },
  {
    jerseyNumber: 11,
    name: 'Philipp Ochs',
    dateOfBirth: '17.04.1997',
    nationality: 'Deutschland',
    height: 1.75,
    foot: 'links',
    teamMemberSince: '01.07.2022',
    club: 'SV Sandhausen',
    previousClub: 'Hannover 96',
    uid: 'fb27caa8-d4e5-48f9-a195-70da6a019b74',
    position: 'Linksaußen',
    league: '2. Bundesliga',
    marketValue: 350000,
  },
  {
    jerseyNumber: 6,
    name: 'Abu-Bekir Ömer El-Zein',
    dateOfBirth: '18.02.2003',
    nationality: 'Deutschland',
    height: 1.8,
    foot: 'rechts',
    teamMemberSince: '04.07.2022',
    club: 'SV Sandhausen',
    previousClub: 'Borussia Dortmund U19',
    uid: '0e91206c-40c0-4419-ae03-db3e1c2c0f21',
    position: 'Linksaußen',
    league: '2. Bundesliga',
    marketValue: 150000,
  },
  {
    jerseyNumber: 30,
    name: 'Alexander Esswein',
    dateOfBirth: '25.03.1990',
    nationality: 'Deutschland',
    height: 1.82,
    foot: 'rechts',
    teamMemberSince: '09.10.2020',
    club: 'SV Sandhausen',
    previousClub: 'Vereinslos',
    uid: '9619a358-15cb-4a5e-9e38-07400e121216',
    position: 'Rechtsaußen',
    league: '2. Bundesliga',
    marketValue: 400000,
  },
  {
    jerseyNumber: 9,
    name: 'Matej Pulkrab',
    dateOfBirth: '23.05.1997',
    nationality: 'Tschechien',
    height: 1.85,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'SV Sandhausen',
    previousClub: 'AC Sparta Prag',
    uid: 'be00aa1c-a42f-477c-bf93-dc43001f6172',
    position: 'Mittelstürmer',
    league: '2. Bundesliga',
    marketValue: 600000,
  },
  {
    jerseyNumber: 23,
    name: 'Ahmed Kutucu',
    dateOfBirth: '01.03.2000',
    nationality: 'Türkei',
    height: 1.81,
    foot: 'rechts',
    teamMemberSince: '12.01.2022',
    club: 'SV Sandhausen',
    previousClub: 'Basaksehir FK',
    uid: 'd9b1da22-d730-4944-b78f-cfb7505b6af6',
    position: 'Mittelstürmer',
    league: '2. Bundesliga',
    marketValue: 600000,
  },
  {
    jerseyNumber: 29,
    name: 'Kemal Ademi',
    dateOfBirth: '23.01.1996',
    nationality: 'Schweiz',
    height: 1.95,
    foot: 'rechts',
    teamMemberSince: '16.08.2022',
    club: 'SV Sandhausen',
    previousClub: 'FK Khimki',
    uid: '13df01a0-77cb-450a-b739-cf7908de58f7',
    position: 'Mittelstürmer',
    league: '2. Bundesliga',
    marketValue: 500000,
  },
  {
    jerseyNumber: 14,
    name: 'Hamadi Al Ghaddioui',
    dateOfBirth: '22.09.1990',
    nationality: 'Marokko',
    height: 1.9,
    foot: 'rechts',
    teamMemberSince: '26.01.2023',
    club: 'SV Sandhausen',
    previousClub: 'Pafos FC',
    uid: '10f2beaf-cca7-4f2e-b8d9-4cdf3a7126ed',
    position: 'Mittelstürmer',
    league: '2. Bundesliga',
    marketValue: 400000,
  },
  {
    jerseyNumber: 40,
    name: 'Jonas Urbig',
    dateOfBirth: '08.08.2003',
    nationality: 'Deutschland',
    height: 1.89,
    foot: 'beidfüßig',
    teamMemberSince: '06.01.2023',
    club: 'SSV Jahn Regensburg',
    previousClub: '1.FC Köln',
    uid: 'dda29e85-93bf-4d8a-b4c0-e42711d23866',
    position: 'Torwart',
    league: '2. Bundesliga',
    marketValue: 800000,
  },
  {
    jerseyNumber: 1,
    name: 'Dejan Stojanovic',
    dateOfBirth: '19.07.1993',
    nationality: 'Österreich',
    height: 1.96,
    foot: 'links',
    teamMemberSince: '01.07.2022',
    club: 'SSV Jahn Regensburg',
    previousClub: 'FC Middlesbrough',
    uid: '37194b25-3544-491f-b78a-325c89b51a73',
    position: 'Torwart',
    league: '2. Bundesliga',
    marketValue: 400000,
  },
  {
    jerseyNumber: 21,
    name: 'Thorsten Kirschbaum',
    dateOfBirth: '20.04.1987',
    nationality: 'Deutschland',
    height: 1.95,
    foot: 'links',
    teamMemberSince: '01.07.2021',
    club: 'SSV Jahn Regensburg',
    previousClub: 'VVV-Venlo',
    uid: '38f0e9da-e827-442f-a750-2df169223633',
    position: 'Torwart',
    league: '2. Bundesliga',
    marketValue: 200000,
  },
  {
    jerseyNumber: 32,
    name: 'Alexander Weidinger',
    dateOfBirth: '18.06.1997',
    nationality: 'Deutschland',
    height: 1.97,
    foot: 'rechts',
    teamMemberSince: '01.07.2018',
    club: 'SSV Jahn Regensburg',
    previousClub: 'SSV Jahn Regensburg II',
    uid: 'e41361dd-159b-47ea-83da-0527489f8cee',
    position: 'Torwart',
    league: '2. Bundesliga',
    marketValue: 125000,
  },
  {
    jerseyNumber: 33,
    name: 'Jan Elvedi',
    dateOfBirth: '30.09.1996',
    nationality: 'Schweiz',
    height: 1.87,
    foot: 'rechts',
    teamMemberSince: '24.07.2020',
    club: 'SSV Jahn Regensburg',
    previousClub: 'SC Kriens',
    uid: '47fc7eff-4f22-40ec-9d24-57aa6bfbc3af',
    position: 'Innenverteidiger',
    league: '2. Bundesliga',
    marketValue: 750000,
  },
  {
    jerseyNumber: 23,
    name: 'Steve Breitkreuz',
    dateOfBirth: '18.01.1992',
    nationality: 'Deutschland',
    height: 1.88,
    foot: 'beidfüßig',
    teamMemberSince: '01.07.2021',
    club: 'SSV Jahn Regensburg',
    previousClub: 'FC Erzgebirge Aue',
    uid: '411d942f-f332-4b20-b122-29ea1c7cf430',
    position: 'Innenverteidiger',
    league: '2. Bundesliga',
    marketValue: 700000,
  },
  {
    jerseyNumber: 24,
    name: 'Scott Kennedy',
    dateOfBirth: '31.03.1997',
    nationality: 'Kanada',
    height: 1.93,
    foot: 'links',
    teamMemberSince: '18.08.2020',
    club: 'SSV Jahn Regensburg',
    previousClub: 'SK Austria Klagenfurt',
    uid: 'f2b3a358-1455-411a-b190-92da753b9499',
    position: 'Innenverteidiger',
    league: '2. Bundesliga',
    marketValue: 600000,
  },
  {
    jerseyNumber: 28,
    name: 'Sebastian Nachreiner',
    dateOfBirth: '23.11.1988',
    nationality: 'Deutschland',
    height: 1.87,
    foot: 'rechts',
    teamMemberSince: '01.07.2010',
    club: 'SSV Jahn Regensburg',
    previousClub: 'FC Dingolfing',
    uid: '70d60f93-ef4e-450f-9a4f-2b1d893afd83',
    position: 'Innenverteidiger',
    league: '2. Bundesliga',
    marketValue: 200000,
  },
  {
    jerseyNumber: 27,
    name: 'Lasse Günther',
    dateOfBirth: '21.03.2003',
    nationality: 'Deutschland',
    height: 1.83,
    foot: 'links',
    teamMemberSince: '10.08.2022',
    club: 'SSV Jahn Regensburg',
    previousClub: 'FC Augsburg',
    uid: 'bc8e8774-ac25-4321-9c2e-07d69c0f6097',
    position: 'Linker Verteidiger',
    league: '2. Bundesliga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 20,
    name: 'Leon Guwara',
    dateOfBirth: '28.06.1996',
    nationality: 'Gambia',
    height: 1.85,
    foot: 'links',
    teamMemberSince: '01.07.2021',
    club: 'SSV Jahn Regensburg',
    previousClub: 'FC Utrecht',
    uid: '14c303db-9ba8-454b-995e-4aeb07ceb767',
    position: 'Linker Verteidiger',
    league: '2. Bundesliga',
    marketValue: 600000,
  },
  {
    jerseyNumber: 6,
    name: 'Benedikt Saller',
    dateOfBirth: '22.09.1992',
    nationality: 'Deutschland',
    height: 1.79,
    foot: 'rechts',
    teamMemberSince: '10.08.2016',
    club: 'SSV Jahn Regensburg',
    previousClub: ': Ablöse ablösefrei',
    uid: '5798bca8-7672-42f4-9eb2-dab242b8dcbf',
    position: 'Rechter Verteidiger',
    league: '2. Bundesliga',
    marketValue: 650000,
  },
  {
    jerseyNumber: 11,
    name: 'Konrad Faber',
    dateOfBirth: '04.11.1997',
    nationality: 'Deutschland',
    height: 1.76,
    foot: 'rechts',
    teamMemberSince: '01.07.2021',
    club: 'SSV Jahn Regensburg',
    previousClub: 'SC Freiburg II',
    uid: '2a159908-f615-45e6-9709-93d1184a9ea6',
    position: 'Rechter Verteidiger',
    league: '2. Bundesliga',
    marketValue: 550000,
  },
  {
    jerseyNumber: 5,
    name: 'Benedikt Gimber',
    dateOfBirth: '19.02.1997',
    nationality: 'Deutschland',
    height: 1.87,
    foot: 'links',
    teamMemberSince: '27.08.2019',
    club: 'SSV Jahn Regensburg',
    previousClub: 'FC Ingolstadt 04',
    uid: 'e2ac4576-19b7-4dc5-80cc-978200f6ce11',
    position: 'Defensives Mittelfeld',
    league: '2. Bundesliga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 8,
    name: 'Maximilian Thalhammer',
    dateOfBirth: '10.07.1997',
    nationality: 'Deutschland',
    height: 1.91,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'SSV Jahn Regensburg',
    previousClub: 'SC Paderborn 07',
    uid: '31e03565-3caa-41d2-bf6a-e8f03de1ccf4',
    position: 'Defensives Mittelfeld',
    league: '2. Bundesliga',
    marketValue: 750000,
  },
  {
    jerseyNumber: 30,
    name: 'Christian Viet',
    dateOfBirth: '27.03.1999',
    nationality: 'Deutschland',
    height: 1.83,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'SSV Jahn Regensburg',
    previousClub: 'FC St. Pauli',
    uid: '3c91237d-e0f6-435a-82cf-1509ba44c805',
    position: 'Zentrales Mittelfeld',
    league: '2. Bundesliga',
    marketValue: 500000,
  },
  {
    jerseyNumber: 15,
    name: 'Sarpreet Singh',
    dateOfBirth: '20.02.1999',
    nationality: 'Neuseeland',
    height: 1.75,
    foot: 'links',
    teamMemberSince: '27.07.2022',
    club: 'SSV Jahn Regensburg',
    previousClub: 'FC Bayern München II',
    uid: '8e9ca314-2231-455c-ba5d-a05c8ed4cfab',
    position: 'Offensives Mittelfeld',
    league: '2. Bundesliga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 14,
    name: 'Blendi Idrizi',
    dateOfBirth: '02.05.1998',
    nationality: 'Kosovo',
    height: 1.79,
    foot: 'rechts',
    teamMemberSince: '30.08.2022',
    club: 'SSV Jahn Regensburg',
    previousClub: 'FC Schalke 04',
    uid: '090d1540-0aa2-4063-940e-a0f0a24fefc3',
    position: 'Offensives Mittelfeld',
    league: '2. Bundesliga',
    marketValue: 600000,
  },
  {
    jerseyNumber: 10,
    name: 'Kaan Caliskaner',
    dateOfBirth: '03.11.1999',
    nationality: 'Deutschland',
    height: 1.92,
    foot: 'beidfüßig',
    teamMemberSince: '15.07.2020',
    club: 'SSV Jahn Regensburg',
    previousClub: '1.FC Köln II',
    uid: '0e075e2e-ffa4-4290-b08d-ffec3321b0e3',
    position: 'Offensives Mittelfeld',
    league: '2. Bundesliga',
    marketValue: 450000,
  },
  {
    jerseyNumber: 29,
    name: 'Joshua Mees',
    dateOfBirth: '15.04.1996',
    nationality: 'Deutschland',
    height: 1.8,
    foot: 'beidfüßig',
    teamMemberSince: '01.07.2022',
    club: 'SSV Jahn Regensburg',
    previousClub: 'Holstein Kiel',
    uid: '7f186d18-3728-4848-9042-679c9187c4ee',
    position: 'Linksaußen',
    league: '2. Bundesliga',
    marketValue: 500000,
  },
  {
    jerseyNumber: 26,
    name: 'Charalampos Makridis',
    dateOfBirth: '05.07.1996',
    nationality: 'Deutschland',
    height: 1.8,
    foot: 'beidfüßig',
    teamMemberSince: '27.01.2020',
    club: 'SSV Jahn Regensburg',
    previousClub: 'Borussia Mönchengladbach II',
    uid: '273da0a4-cccc-41e6-a7f1-f93781eef0b1',
    position: 'Linksaußen',
    league: '2. Bundesliga',
    marketValue: 350000,
  },
  {
    jerseyNumber: 22,
    name: 'Minos Gouras',
    dateOfBirth: '07.06.1998',
    nationality: 'Deutschland',
    height: 1.75,
    foot: 'beidfüßig',
    teamMemberSince: '01.07.2022',
    club: 'SSV Jahn Regensburg',
    previousClub: '1.FC Saarbrücken',
    uid: '848e4ddd-b661-4cd1-b2a1-8b96111d81a3',
    position: 'Linksaußen',
    league: '2. Bundesliga',
    marketValue: 300000,
  },
  {
    jerseyNumber: 16,
    name: 'Oscar Schönfelder',
    dateOfBirth: '05.02.2001',
    nationality: 'Deutschland',
    height: 1.81,
    foot: 'links',
    teamMemberSince: '01.07.2022',
    club: 'SSV Jahn Regensburg',
    previousClub: 'SV Werder Bremen',
    uid: 'a81564c9-8cb0-4990-9943-6be98026ec2f',
    position: 'Linksaußen',
    league: '2. Bundesliga',
    marketValue: 250000,
  },
  {
    jerseyNumber: 7,
    name: 'Nicklas Shipnoski',
    dateOfBirth: '01.01.1998',
    nationality: 'Deutschland',
    height: 1.84,
    foot: 'rechts',
    teamMemberSince: '31.01.2022',
    club: 'SSV Jahn Regensburg',
    previousClub: 'Fortuna Düsseldorf',
    uid: 'd994e52d-7d62-4653-b097-5533e43b3501',
    position: 'Rechtsaußen',
    league: '2. Bundesliga',
    marketValue: 500000,
  },
  {
    jerseyNumber: 18,
    name: 'Aygün Yildirim',
    dateOfBirth: '04.04.1995',
    nationality: 'Deutschland',
    height: 1.8,
    foot: 'rechts',
    teamMemberSince: '01.07.2021',
    club: 'SSV Jahn Regensburg',
    previousClub: 'SC Verl',
    uid: 'aec3ff3e-5159-4c51-89fb-17e0d68fe4cd',
    position: 'Rechtsaußen',
    league: '2. Bundesliga',
    marketValue: 350000,
  },
  {
    jerseyNumber: 12,
    name: 'Dario Vizinger',
    dateOfBirth: '06.06.1998',
    nationality: 'Kroatien',
    height: 1.8,
    foot: 'rechts',
    teamMemberSince: '01.09.2022',
    club: 'SSV Jahn Regensburg',
    previousClub: 'Wolfsberger AC',
    uid: '694f0442-7b07-4ac2-8f9d-ab16188e9362',
    position: 'Mittelstürmer',
    league: '2. Bundesliga',
    marketValue: 600000,
  },
  {
    jerseyNumber: 19,
    name: 'Andreas Albers',
    dateOfBirth: '23.03.1990',
    nationality: 'Dänemark',
    height: 1.93,
    foot: 'rechts',
    teamMemberSince: '03.07.2019',
    club: 'SSV Jahn Regensburg',
    previousClub: 'Viborg FF',
    uid: '02882ecc-5bbc-4f5a-8af0-c1cb675aaaeb',
    position: 'Mittelstürmer',
    league: '2. Bundesliga',
    marketValue: 550000,
  },
  {
    jerseyNumber: 9,
    name: 'Prince Osei Owusu',
    dateOfBirth: '07.01.1997',
    nationality: 'Deutschland',
    height: 1.9,
    foot: 'links',
    teamMemberSince: '01.07.2022',
    club: 'SSV Jahn Regensburg',
    previousClub: 'FC Erzgebirge Aue',
    uid: '816f0d5b-d623-40f4-9d50-6d9162d9ce05',
    position: 'Mittelstürmer',
    league: '2. Bundesliga',
    marketValue: 400000,
  },
  {
    jerseyNumber: 1,
    name: 'Dominik Reimann',
    dateOfBirth: '18.06.1997',
    nationality: 'Deutschland',
    height: 1.86,
    foot: 'rechts',
    teamMemberSince: '01.07.2021',
    club: '1.FC Magdeburg',
    previousClub: 'Holstein Kiel',
    uid: '589c6415-8863-4cfe-8a9e-866a51e93f6e',
    position: 'Torwart',
    league: '2. Bundesliga',
    marketValue: 500000,
  },
  {
    jerseyNumber: 28,
    name: 'Tim Boss',
    dateOfBirth: '28.06.1993',
    nationality: 'Deutschland',
    height: 1.86,
    foot: 'beidfüßig',
    teamMemberSince: '01.07.2022',
    club: '1.FC Magdeburg',
    previousClub: 'SV Wehen Wiesbaden',
    uid: 'd54fc3ad-d02d-4f01-8e06-62e4bb55c6e9',
    position: 'Torwart',
    league: '2. Bundesliga',
    marketValue: 200000,
  },
  {
    jerseyNumber: 30,
    name: 'Noah Kruth',
    dateOfBirth: '24.06.2003',
    nationality: 'Deutschland',
    height: 1.86,
    foot: 'rechts',
    teamMemberSince: '09.01.2022',
    club: '1.FC Magdeburg',
    previousClub: '1.FC Magdeburg U19',
    uid: '5d5e6a3d-a468-4e92-981e-ee795a386e9b',
    position: 'Torwart',
    league: '2. Bundesliga',
    marketValue: 100000,
  },
  {
    jerseyNumber: 2,
    name: 'Cristiano Piccini',
    dateOfBirth: '26.09.1992',
    nationality: 'Italien',
    height: 1.89,
    foot: 'rechts',
    teamMemberSince: '06.09.2022',
    club: '1.FC Magdeburg',
    previousClub: 'Vereinslos',
    uid: '1e682ff5-9574-4239-be89-0ffa8b330721',
    position: 'Innenverteidiger',
    league: '2. Bundesliga',
    marketValue: 800000,
  },
  {
    jerseyNumber: 5,
    name: 'Jamie Lawrence',
    dateOfBirth: '10.11.2002',
    nationality: 'Deutschland',
    height: 2.01,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: '1.FC Magdeburg',
    previousClub: 'FC Bayern München II',
    uid: 'efa015e9-2ada-4f8e-9946-05b5ba431611',
    position: 'Innenverteidiger',
    league: '2. Bundesliga',
    marketValue: 800000,
  },
  {
    jerseyNumber: 25,
    name: 'Silas Gnaka',
    dateOfBirth: '18.12.1998',
    nationality: 'Elfenbeinküste',
    height: 1.79,
    foot: 'links',
    teamMemberSince: '01.07.2022',
    club: '1.FC Magdeburg',
    previousClub: 'KAS Eupen',
    uid: '624642a2-06e7-49f9-811e-28c404d6f3d1',
    position: 'Innenverteidiger',
    league: '2. Bundesliga',
    marketValue: 600000,
  },
  {
    jerseyNumber: 15,
    name: 'Daniel Heber',
    dateOfBirth: '04.07.1994',
    nationality: 'Deutschland',
    height: 1.82,
    foot: 'rechts',
    teamMemberSince: '24.01.2023',
    club: '1.FC Magdeburg',
    previousClub: 'Rot-Weiss Essen',
    uid: 'c5771b40-c65b-4394-a506-a5f1517dcc70',
    position: 'Innenverteidiger',
    league: '2. Bundesliga',
    marketValue: 400000,
  },
  {
    jerseyNumber: 27,
    name: 'Malcolm Cacutalua',
    dateOfBirth: '15.11.1994',
    nationality: 'Deutschland',
    height: 1.88,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: '1.FC Magdeburg',
    previousClub: 'FC Erzgebirge Aue',
    uid: '223db081-c5ec-43f4-80ce-c76eda86a873',
    position: 'Innenverteidiger',
    league: '2. Bundesliga',
    marketValue: 250000,
  },
  {
    jerseyNumber: 12,
    name: 'Belal Halbouni',
    dateOfBirth: '29.12.1999',
    nationality: 'Syrien',
    height: 1.89,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: '1.FC Magdeburg',
    previousClub: 'SV Werder Bremen II',
    uid: '1a79a9fd-529c-4dca-9878-8c39f8405b67',
    position: 'Innenverteidiger',
    league: '2. Bundesliga',
    marketValue: 200000,
  },
  {
    jerseyNumber: 24,
    name: 'Alexander Bittroff',
    dateOfBirth: '19.09.1988',
    nationality: 'Deutschland',
    height: 1.86,
    foot: 'rechts',
    teamMemberSince: '07.10.2020',
    club: '1.FC Magdeburg',
    previousClub: 'KFC Uerdingen 05',
    uid: 'f102ec87-96b3-4bed-bdc4-9337a0301481',
    position: 'Innenverteidiger',
    league: '2. Bundesliga',
    marketValue: 200000,
  },
  {
    jerseyNumber: 21,
    name: 'Tim Stappmann',
    dateOfBirth: '17.07.1999',
    nationality: 'Deutschland',
    height: 1.87,
    foot: 'beidfüßig',
    teamMemberSince: '01.07.2022',
    club: '1.FC Magdeburg',
    previousClub: 'Rot-Weiß Oberhausen',
    uid: '01988d07-8bf0-4acd-b964-a4d7b966b373',
    position: 'Innenverteidiger',
    league: '2. Bundesliga',
    marketValue: 150000,
  },
  {
    jerseyNumber: 22,
    name: 'Tim Sechelmann',
    dateOfBirth: '15.01.1999',
    nationality: 'Deutschland',
    height: 1.86,
    foot: 'links',
    teamMemberSince: '01.07.2021',
    club: '1.FC Magdeburg',
    previousClub: '1.FC Köln II',
    uid: 'a12747d5-1edb-4381-a148-5485122fd9e9',
    position: 'Innenverteidiger',
    league: '2. Bundesliga',
    marketValue: 150000,
  },
  {
    jerseyNumber: 33,
    name: 'Leon Schmökel',
    dateOfBirth: '09.06.2002',
    nationality: 'Deutschland',
    height: 1.96,
    foot: 'rechts',
    teamMemberSince: '01.07.2021',
    club: '1.FC Magdeburg',
    previousClub: '1.FC Magdeburg U19',
    uid: 'b3bd6c35-7405-473d-85d8-733fee08de63',
    position: 'Innenverteidiger',
    league: '2. Bundesliga',
    marketValue: 75000,
  },
  {
    jerseyNumber: 31,
    name: 'Maximilian Ullmann',
    dateOfBirth: '17.06.1996',
    nationality: 'Österreich',
    height: 1.8,
    foot: 'links',
    teamMemberSince: '25.01.2023',
    club: '1.FC Magdeburg',
    previousClub: 'Venezia FC',
    uid: 'f7f83c67-6928-45fa-a4c6-cca4febf55fe',
    position: 'Linker Verteidiger',
    league: '2. Bundesliga',
    marketValue: 900000,
  },
  {
    jerseyNumber: 19,
    name: 'Leon Bell Bell',
    dateOfBirth: '06.09.1996',
    nationality: 'Deutschland',
    height: 1.8,
    foot: 'links',
    teamMemberSince: '01.07.2019',
    club: '1.FC Magdeburg',
    previousClub: '1.FSV Mainz 05 II',
    uid: '46fd5f11-e96e-4c7b-944a-71842decfa65',
    position: 'Linker Verteidiger',
    league: '2. Bundesliga',
    marketValue: 450000,
  },
  {
    jerseyNumber: 7,
    name: 'Herbert Bockhorn',
    dateOfBirth: '31.01.1995',
    nationality: 'Deutschland',
    height: 1.76,
    foot: 'rechts',
    teamMemberSince: '06.09.2022',
    club: '1.FC Magdeburg',
    previousClub: 'Vereinslos',
    uid: '8be13a87-6859-47a2-bb57-1a092b058f25',
    position: 'Rechter Verteidiger',
    league: '2. Bundesliga',
    marketValue: 600000,
  },
  {
    jerseyNumber: 4,
    name: 'Eldin Dzogovic',
    dateOfBirth: '08.06.2003',
    nationality: 'Luxemburg',
    height: 1.8,
    foot: 'rechts',
    teamMemberSince: '09.01.2022',
    club: '1.FC Magdeburg',
    previousClub: '1.FC Magdeburg U19',
    uid: '60106efb-e442-440a-b35e-d326902e4d53',
    position: 'Rechter Verteidiger',
    league: '2. Bundesliga',
    marketValue: 75000,
  },
  {
    jerseyNumber: 16,
    name: 'Andreas Müller',
    dateOfBirth: '20.07.2000',
    nationality: 'Deutschland',
    height: 1.73,
    foot: 'rechts',
    teamMemberSince: '15.07.2020',
    club: '1.FC Magdeburg',
    previousClub: 'FC-Astoria Walldorf II',
    uid: 'b8b59283-ef4f-45c2-a71c-89509a8f69ee',
    position: 'Defensives Mittelfeld',
    league: '2. Bundesliga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 6,
    name: 'Daniel Elfadli',
    dateOfBirth: '06.04.1997',
    nationality: 'Libyen',
    height: 1.88,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: '1.FC Magdeburg',
    previousClub: 'VfR Aalen',
    uid: 'ff58a300-cbdf-4f52-bce1-e2e08c04b6cc',
    position: 'Defensives Mittelfeld',
    league: '2. Bundesliga',
    marketValue: 350000,
  },
  {
    jerseyNumber: 20,
    name: 'Julian Rieckmann',
    dateOfBirth: '01.08.2000',
    nationality: 'Deutschland',
    height: 1.86,
    foot: 'rechts',
    teamMemberSince: '01.07.2021',
    club: '1.FC Magdeburg',
    previousClub: 'SV Werder Bremen II',
    uid: '24069026-03da-4de3-822c-bc3757af27c5',
    position: 'Defensives Mittelfeld',
    league: '2. Bundesliga',
    marketValue: 225000,
  },
  {
    jerseyNumber: 29,
    name: 'Amara Condé',
    dateOfBirth: '06.01.1997',
    nationality: 'Deutschland',
    height: 1.73,
    foot: 'rechts',
    teamMemberSince: '01.07.2021',
    club: '1.FC Magdeburg',
    previousClub: 'Rot-Weiss Essen',
    uid: 'b3ea7acb-20a5-43e8-9b8d-c32212a38804',
    position: 'Zentrales Mittelfeld',
    league: '2. Bundesliga',
    marketValue: 600000,
  },
  {
    jerseyNumber: 13,
    name: 'Connor Krempicki',
    dateOfBirth: '14.09.1994',
    nationality: 'Deutschland',
    height: 1.78,
    foot: 'beidfüßig',
    teamMemberSince: '01.07.2021',
    club: '1.FC Magdeburg',
    previousClub: ': Ablöse ablösefrei',
    uid: '835d2f6f-d2f0-40c0-8f11-dba77435a283',
    position: 'Zentrales Mittelfeld',
    league: '2. Bundesliga',
    marketValue: 350000,
  },
  {
    jerseyNumber: 8,
    name: 'Moritz-Broni Kwarteng',
    dateOfBirth: '28.04.1998',
    nationality: 'Deutschland',
    height: 1.75,
    foot: 'beidfüßig',
    teamMemberSince: '21.01.2022',
    club: '1.FC Magdeburg',
    previousClub: 'Vereinslos',
    uid: '47261de1-8be8-412f-bef0-9d4082b4af3e',
    position: 'Offensives Mittelfeld',
    league: '2. Bundesliga',
    marketValue: 900000,
  },
  {
    jerseyNumber: 23,
    name: 'Baris Atik',
    dateOfBirth: '09.01.1995',
    nationality: 'Türkei',
    height: 1.69,
    foot: 'rechts',
    teamMemberSince: '05.01.2021',
    club: '1.FC Magdeburg',
    previousClub: 'Vereinslos',
    uid: '3bd3b91d-8dac-4fe6-b8b1-0c3748453e90',
    position: 'Linksaußen',
    league: '2. Bundesliga',
    marketValue: 900000,
  },
  {
    jerseyNumber: 37,
    name: 'Tatsuya Ito',
    dateOfBirth: '26.06.1997',
    nationality: 'Japan',
    height: 1.63,
    foot: 'links',
    teamMemberSince: '21.01.2022',
    club: '1.FC Magdeburg',
    previousClub: 'VV St. Truiden',
    uid: '7a311db0-4024-49e0-878a-02a58d657533',
    position: 'Linksaußen',
    league: '2. Bundesliga',
    marketValue: 600000,
  },
  {
    jerseyNumber: 17,
    name: 'Léo Scienza',
    dateOfBirth: '13.09.1998',
    nationality: 'Brasilien',
    height: 1.75,
    foot: 'beidfüßig',
    teamMemberSince: '01.07.2022',
    club: '1.FC Magdeburg',
    previousClub: 'FC Schalke 04 II',
    uid: 'a8084cc5-0d25-4cb2-961f-bde829de64ec',
    position: 'Linksaußen',
    league: '2. Bundesliga',
    marketValue: 250000,
  },
  {
    jerseyNumber: 18,
    name: 'Florian Kath',
    dateOfBirth: '21.10.1994',
    nationality: 'Deutschland',
    height: 1.86,
    foot: 'links',
    teamMemberSince: '01.07.2021',
    club: '1.FC Magdeburg',
    previousClub: 'SC Freiburg',
    uid: '3eed8211-9030-482e-824a-8babb8b2d9db',
    position: 'Linksaußen',
    league: '2. Bundesliga',
    marketValue: 225000,
  },
  {
    jerseyNumber: 11,
    name: 'Mohammed El Hankouri',
    dateOfBirth: '01.07.1997',
    nationality: 'Marokko',
    height: 1.76,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: '1.FC Magdeburg',
    previousClub: 'FC Groningen',
    uid: 'a7ecf4ac-1cca-4684-9fd8-d73414eb29fd',
    position: 'Rechtsaußen',
    league: '2. Bundesliga',
    marketValue: 800000,
  },
  {
    jerseyNumber: 10,
    name: 'Jason Ceka',
    dateOfBirth: '10.11.1999',
    nationality: 'Deutschland',
    height: 1.69,
    foot: 'beidfüßig',
    teamMemberSince: '01.07.2021',
    club: '1.FC Magdeburg',
    previousClub: 'FC Schalke 04 II',
    uid: 'ce961d11-8c11-4a49-82e8-83fdc6e8c021',
    position: 'Rechtsaußen',
    league: '2. Bundesliga',
    marketValue: 600000,
  },
  {
    jerseyNumber: 14,
    name: 'Maximilian Franzke',
    dateOfBirth: '05.03.1999',
    nationality: 'Deutschland',
    height: 1.84,
    foot: 'beidfüßig',
    teamMemberSince: '01.07.2021',
    club: '1.FC Magdeburg',
    previousClub: 'FC St. Pauli',
    uid: 'cd61dec1-9de5-40ad-85d1-a7a294c26417',
    position: 'Rechtsaußen',
    league: '2. Bundesliga',
    marketValue: 150000,
  },
  {
    jerseyNumber: 26,
    name: 'Luca Schuler',
    dateOfBirth: '22.03.1999',
    nationality: 'Deutschland',
    height: 1.9,
    foot: 'rechts',
    teamMemberSince: '01.07.2021',
    club: '1.FC Magdeburg',
    previousClub: 'FC Schalke 04 II',
    uid: '04151d14-073e-47bb-a438-0dc1386272bb',
    position: 'Mittelstürmer',
    league: '2. Bundesliga',
    marketValue: 450000,
  },
  {
    jerseyNumber: 3,
    name: 'Luc Castaignos',
    dateOfBirth: '27.09.1992',
    nationality: 'Niederlande',
    height: 1.88,
    foot: 'rechts',
    teamMemberSince: '18.01.2023',
    club: '1.FC Magdeburg',
    previousClub: 'Vereinslos',
    uid: '0eb0d7ab-4a84-4455-8874-89da2c40c3e4',
    position: 'Mittelstürmer',
    league: '2. Bundesliga',
    marketValue: 300000,
  },
  {
    jerseyNumber: 9,
    name: 'Kai Brünker',
    dateOfBirth: '10.06.1994',
    nationality: 'Deutschland',
    height: 1.9,
    foot: 'rechts',
    teamMemberSince: '15.07.2020',
    club: '1.FC Magdeburg',
    previousClub: 'SG Sonnenhof Großaspach',
    uid: 'c060bd0c-17b5-40d8-ad90-e2591be04cad',
    position: 'Mittelstürmer',
    league: '2. Bundesliga',
    marketValue: 200000,
  },
  {
    jerseyNumber: 14,
    name: 'Svante Ingelsson',
    dateOfBirth: '14.06.1998',
    nationality: 'Schweden',
    height: 1.89,
    foot: 'links',
    teamMemberSince: '01.07.2021',
    club: 'FC Hansa Rostock',
    previousClub: 'Udinese Calcio',
    uid: '30be88a8-6505-4829-97b3-30bffd3a43d3',
    position: 'Mittelfeld',
    league: '2. Bundesliga',
    marketValue: 900000,
  },
  {
    jerseyNumber: 1,
    name: 'Markus Kolke',
    dateOfBirth: '18.08.1990',
    nationality: 'Deutschland',
    height: 1.87,
    foot: 'rechts',
    teamMemberSince: '01.07.2019',
    club: 'FC Hansa Rostock',
    previousClub: 'SV Wehen Wiesbaden',
    uid: '9971ac8b-66fb-429e-a8cd-fd9d9ae33683',
    position: 'Torwart',
    league: '2. Bundesliga',
    marketValue: 500000,
  },
  {
    jerseyNumber: 23,
    name: 'Nils-Jonathan Körber',
    dateOfBirth: '13.11.1996',
    nationality: 'Deutschland',
    height: 1.89,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'FC Hansa Rostock',
    previousClub: 'Hertha BSC',
    uid: '526ebfa0-96d4-44d7-bc1a-005445623539',
    position: 'Torwart',
    league: '2. Bundesliga',
    marketValue: 300000,
  },
  {
    jerseyNumber: 30,
    name: 'Max Hagemoser',
    dateOfBirth: '07.01.2003',
    nationality: 'Deutschland',
    height: 1.9,
    foot: 'links',
    teamMemberSince: '01.07.2022',
    club: 'FC Hansa Rostock',
    previousClub: '1.FC Köln U19',
    uid: '509ac5cb-fc93-441d-9c6d-28fb960fc716',
    position: 'Torwart',
    league: '2. Bundesliga',
    marketValue: 100000,
  },
  {
    jerseyNumber: 5,
    name: 'Rick van Drongelen',
    dateOfBirth: '20.12.1998',
    nationality: 'Niederlande',
    height: 1.88,
    foot: 'links',
    teamMemberSince: '01.09.2022',
    club: 'FC Hansa Rostock',
    previousClub: '1.FC Union Berlin',
    uid: '468e7b11-084f-44da-8568-3b9457250925',
    position: 'Innenverteidiger',
    league: '2. Bundesliga',
    marketValue: 800000,
  },
  {
    jerseyNumber: 4,
    name: 'Damian Roßbach',
    dateOfBirth: '27.02.1993',
    nationality: 'Deutschland',
    height: 1.87,
    foot: 'links',
    teamMemberSince: '22.07.2020',
    club: 'FC Hansa Rostock',
    previousClub: 'Karlsruher SC',
    uid: 'f49155e9-0910-4bbc-aaa8-bb2d0490f2c4',
    position: 'Innenverteidiger',
    league: '2. Bundesliga',
    marketValue: 500000,
  },
  {
    jerseyNumber: 16,
    name: 'Ryan Malone',
    dateOfBirth: '11.08.1992',
    nationality: 'Vereinigte Staaten',
    height: 1.88,
    foot: 'rechts',
    teamMemberSince: '20.07.2021',
    club: 'FC Hansa Rostock',
    previousClub: 'VfB Lübeck',
    uid: 'e7ec1769-12b4-4e6e-9417-16972afa163d',
    position: 'Innenverteidiger',
    league: '2. Bundesliga',
    marketValue: 500000,
  },
  {
    jerseyNumber: 25,
    name: 'Thomas Meißner',
    dateOfBirth: '26.03.1991',
    nationality: 'Deutschland',
    height: 1.91,
    foot: 'rechts',
    teamMemberSince: '01.07.2021',
    club: 'FC Hansa Rostock',
    previousClub: 'Puskás Akadémia FC',
    uid: '68388b9b-c984-43a9-8ca0-3e653bfb29f9',
    position: 'Innenverteidiger',
    league: '2. Bundesliga',
    marketValue: 300000,
  },
  {
    jerseyNumber: 32,
    name: 'Benno Dietze',
    dateOfBirth: '15.07.2003',
    nationality: 'Deutschland',
    height: 1.83,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'FC Hansa Rostock',
    previousClub: 'FC Hansa Rostock U19',
    uid: 'dc740bc8-aa15-4b63-aa9b-011abcdbedb0',
    position: 'Innenverteidiger',
    league: '2. Bundesliga',
    marketValue: 125000,
  },
  {
    jerseyNumber: 21,
    name: 'Anderson Lucoqui',
    dateOfBirth: '06.07.1997',
    nationality: 'Angola',
    height: 1.8,
    foot: 'links',
    teamMemberSince: '01.09.2022',
    club: 'FC Hansa Rostock',
    previousClub: '1.FSV Mainz 05',
    uid: 'bb3fc068-a760-42d8-a978-2420d7ca3265',
    position: 'Linker Verteidiger',
    league: '2. Bundesliga',
    marketValue: 750000,
  },
  {
    jerseyNumber: 20,
    name: 'Lukas Scherff',
    dateOfBirth: '14.07.1996',
    nationality: 'Deutschland',
    height: 1.75,
    foot: 'links',
    teamMemberSince: '01.01.2015',
    club: 'FC Hansa Rostock',
    previousClub: 'FC Hansa Rostock U19',
    uid: '94ebe95a-ae2b-4304-9c52-8c4084b86e47',
    position: 'Linker Verteidiger',
    league: '2. Bundesliga',
    marketValue: 250000,
  },
  {
    jerseyNumber: 31,
    name: 'Felix Ruschke',
    dateOfBirth: '08.03.2003',
    nationality: 'Deutschland',
    height: 1.85,
    foot: 'links',
    teamMemberSince: '01.07.2022',
    club: 'FC Hansa Rostock',
    previousClub: 'FC Hansa Rostock U19',
    uid: '0903f4a6-2652-415e-b4b2-c7762e2c09be',
    position: 'Linker Verteidiger',
    league: '2. Bundesliga',
    marketValue: 50000,
  },
  {
    jerseyNumber: 27,
    name: 'Frederic Ananou',
    dateOfBirth: '20.09.1997',
    nationality: 'Togo',
    height: 1.83,
    foot: 'rechts',
    teamMemberSince: '03.07.2022',
    club: 'FC Hansa Rostock',
    previousClub: 'SC Paderborn 07',
    uid: 'c5e7f6b3-2acb-4ebf-8155-93c5cac7fa38',
    position: 'Rechter Verteidiger',
    league: '2. Bundesliga',
    marketValue: 650000,
  },
  {
    jerseyNumber: 7,
    name: 'Nico Neidhart',
    dateOfBirth: '27.09.1994',
    nationality: 'Deutschland',
    height: 1.77,
    foot: 'beidfüßig',
    teamMemberSince: '01.07.2019',
    club: 'FC Hansa Rostock',
    previousClub: 'FC Emmen',
    uid: '03514df3-85c0-44f4-a732-b16bfcc5ef4d',
    position: 'Rechter Verteidiger',
    league: '2. Bundesliga',
    marketValue: 600000,
  },
  {
    jerseyNumber: 24,
    name: 'John-Patrick Strauß',
    dateOfBirth: '28.01.1996',
    nationality: 'Philippinen',
    height: 1.78,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'FC Hansa Rostock',
    previousClub: 'FC Erzgebirge Aue',
    uid: '59736624-8302-463d-b6c8-f6b528832c51',
    position: 'Rechter Verteidiger',
    league: '2. Bundesliga',
    marketValue: 350000,
  },
  {
    jerseyNumber: 8,
    name: 'Simon Rhein',
    dateOfBirth: '18.05.1998',
    nationality: 'Deutschland',
    height: 1.8,
    foot: 'links',
    teamMemberSince: '01.01.2021',
    club: 'FC Hansa Rostock',
    previousClub: '1.FC Nürnberg',
    uid: 'eb098c2e-46b3-42f8-86f7-63be1be20c23',
    position: 'Defensives Mittelfeld',
    league: '2. Bundesliga',
    marketValue: 800000,
  },
  {
    jerseyNumber: 34,
    name: 'Lukas Fröde',
    dateOfBirth: '23.01.1995',
    nationality: 'Deutschland',
    height: 1.92,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'FC Hansa Rostock',
    previousClub: 'Karlsruher SC',
    uid: '16be869a-72ff-4aa7-8e36-0a8cd3aada8d',
    position: 'Defensives Mittelfeld',
    league: '2. Bundesliga',
    marketValue: 750000,
  },
  {
    jerseyNumber: 6,
    name: 'Dennis Dressel',
    dateOfBirth: '26.10.1998',
    nationality: 'Deutschland',
    height: 1.86,
    foot: 'links',
    teamMemberSince: '01.07.2022',
    club: 'FC Hansa Rostock',
    previousClub: 'TSV 1860 München',
    uid: 'b15a24e3-4bbe-4349-94e3-01eb07a351e0',
    position: 'Zentrales Mittelfeld',
    league: '2. Bundesliga',
    marketValue: 750000,
  },
  {
    jerseyNumber: 29,
    name: 'Sebastien Thill',
    dateOfBirth: '29.12.1993',
    nationality: 'Luxemburg',
    height: 1.78,
    foot: 'links',
    teamMemberSince: '01.07.2022',
    club: 'FC Hansa Rostock',
    previousClub: 'FC Progrès Niederkorn',
    uid: '867219a0-27a5-4f5f-b6a6-44473e488302',
    position: 'Zentrales Mittelfeld',
    league: '2. Bundesliga',
    marketValue: 500000,
  },
  {
    jerseyNumber: 11,
    name: 'Morris Schröter',
    dateOfBirth: '20.08.1995',
    nationality: 'Deutschland',
    height: 1.83,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'FC Hansa Rostock',
    previousClub: 'SG Dynamo Dresden',
    uid: '0eb8ba4e-606b-4f66-bee7-0974d2ed7870',
    position: 'Rechtes Mittelfeld',
    league: '2. Bundesliga',
    marketValue: 350000,
  },
  {
    jerseyNumber: 17,
    name: 'Dong-gyeong Lee',
    dateOfBirth: '20.09.1997',
    nationality: 'Südkorea',
    height: 1.76,
    foot: 'links',
    teamMemberSince: '01.09.2022',
    club: 'FC Hansa Rostock',
    previousClub: 'Ulsan Hyundai',
    uid: 'd8729c4a-d5c3-4cf8-a5bf-7cd6723b46e9',
    position: 'Offensives Mittelfeld',
    league: '2. Bundesliga',
    marketValue: 500000,
  },
  {
    jerseyNumber: 15,
    name: 'Nils Fröling',
    dateOfBirth: '20.04.2000',
    nationality: 'Schweden',
    height: 1.81,
    foot: 'rechts',
    teamMemberSince: '01.01.2022',
    club: 'FC Hansa Rostock',
    previousClub: 'Kalmar FF',
    uid: 'aa0c569c-3338-48ef-85aa-aa5f42911fcb',
    position: 'Linksaußen',
    league: '2. Bundesliga',
    marketValue: 600000,
  },
  {
    jerseyNumber: 10,
    name: 'Haris Duljevic',
    dateOfBirth: '16.11.1993',
    nationality: 'Bosnien-Herzegowina',
    height: 1.85,
    foot: 'rechts',
    teamMemberSince: '10.09.2021',
    club: 'FC Hansa Rostock',
    previousClub: 'Vereinslos',
    uid: '760e2eff-8a27-42c8-9c38-120760051530',
    position: 'Linksaußen',
    league: '2. Bundesliga',
    marketValue: 500000,
  },
  {
    jerseyNumber: 13,
    name: 'Kevin Schumacher',
    dateOfBirth: '24.12.1997',
    nationality: 'Deutschland',
    height: 1.82,
    foot: 'links',
    teamMemberSince: '02.07.2021',
    club: 'FC Hansa Rostock',
    previousClub: 'TSV Havelse',
    uid: '93281853-6721-4f82-b645-5a39007fe62a',
    position: 'Linksaußen',
    league: '2. Bundesliga',
    marketValue: 300000,
  },
  {
    jerseyNumber: 28,
    name: 'Maurice Litka',
    dateOfBirth: '02.01.1996',
    nationality: 'Deutschland',
    height: 1.8,
    foot: 'links',
    teamMemberSince: '03.08.2020',
    club: 'FC Hansa Rostock',
    previousClub: 'Preußen Münster',
    uid: '5682df12-5452-4b9d-b406-3fd55e1938e4',
    position: 'Linksaußen',
    league: '2. Bundesliga',
    marketValue: 100000,
  },
  {
    jerseyNumber: 19,
    name: 'Kai Pröger',
    dateOfBirth: '15.05.1992',
    nationality: 'Deutschland',
    height: 1.77,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'FC Hansa Rostock',
    previousClub: 'SC Paderborn 07',
    uid: 'a9147b61-c8c2-4db7-bdec-8c447f80303e',
    position: 'Rechtsaußen',
    league: '2. Bundesliga',
    marketValue: 800000,
  },
  {
    jerseyNumber: 18,
    name: 'John Verhoek',
    dateOfBirth: '25.03.1989',
    nationality: 'Niederlande',
    height: 1.88,
    foot: 'rechts',
    teamMemberSince: '19.07.2019',
    club: 'FC Hansa Rostock',
    previousClub: ': Ablöse ablösefrei',
    uid: 'e7a5b1c0-8c59-4b2c-80fa-ee754cd79803',
    position: 'Mittelstürmer',
    league: '2. Bundesliga',
    marketValue: 500000,
  },
  {
    jerseyNumber: 22,
    name: 'Lukas Hinterseer',
    dateOfBirth: '28.03.1991',
    nationality: 'Österreich',
    height: 1.92,
    foot: 'rechts',
    teamMemberSince: '21.07.2022',
    club: 'FC Hansa Rostock',
    previousClub: 'Hannover 96',
    uid: 'a90ea295-6b53-44f1-ad06-f8f6b9927530',
    position: 'Mittelstürmer',
    league: '2. Bundesliga',
    marketValue: 450000,
  },
  {
    jerseyNumber: 39,
    name: 'Pascal Breier',
    dateOfBirth: '02.02.1992',
    nationality: 'Deutschland',
    height: 1.85,
    foot: 'rechts',
    teamMemberSince: '01.01.2018',
    club: 'FC Hansa Rostock',
    previousClub: 'VfB Stuttgart II',
    uid: '614d153d-cf72-49f2-a45b-3e3b82ae6cfa',
    position: 'Mittelstürmer',
    league: '2. Bundesliga',
    marketValue: 250000,
  },
  {
    jerseyNumber: 9,
    name: 'Ridge Munsy',
    dateOfBirth: '09.07.1989',
    nationality: 'Schweiz',
    height: 1.88,
    foot: 'rechts',
    teamMemberSince: '01.07.2021',
    club: 'FC Hansa Rostock',
    previousClub: 'Würzburger Kickers',
    uid: 'ee49f5d5-11c1-427c-9ac4-e7bddafc672a',
    position: 'Mittelstürmer',
    league: '2. Bundesliga',
    marketValue: 225000,
  },
  {
    jerseyNumber: 1,
    name: 'Andreas Luthe',
    dateOfBirth: '10.03.1987',
    nationality: 'Deutschland',
    height: 1.95,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: '1.FC Kaiserslautern',
    previousClub: '1.FC Union Berlin',
    uid: '090ec7cd-41c9-4b5d-b178-010e711d1ff2',
    position: 'Torwart',
    league: '2. Bundesliga',
    marketValue: 500000,
  },
  {
    jerseyNumber: 18,
    name: 'Julian Krahl',
    dateOfBirth: '22.01.2000',
    nationality: 'Deutschland',
    height: 1.94,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: '1.FC Kaiserslautern',
    previousClub: 'FC Viktoria 1889 Berlin',
    uid: 'e26d82b3-a1f1-48e6-915d-160a30a30b11',
    position: 'Torwart',
    league: '2. Bundesliga',
    marketValue: 200000,
  },
  {
    jerseyNumber: 30,
    name: 'Avdo Spahic',
    dateOfBirth: '12.02.1997',
    nationality: 'Bosnien-Herzegowina',
    height: 1.9,
    foot: 'rechts',
    teamMemberSince: '05.07.2019',
    club: '1.FC Kaiserslautern',
    previousClub: 'FC Energie Cottbus',
    uid: 'f29c10ef-79c1-4e35-ae1a-e43c744a4032',
    position: 'Torwart',
    league: '2. Bundesliga',
    marketValue: 200000,
  },
  {
    jerseyNumber: 33,
    name: 'Jonas Weyand',
    dateOfBirth: '03.12.2000',
    nationality: 'Deutschland',
    height: 1.9,
    foot: 'rechts',
    teamMemberSince: '23.07.2020',
    club: '1.FC Kaiserslautern',
    previousClub: '1.FC Kaiserslautern II',
    uid: '965bb5e4-3f3d-4eed-b78e-7ff7cee678ba',
    position: 'Torwart',
    league: '2. Bundesliga',
    marketValue: 50000,
  },
  {
    jerseyNumber: 2,
    name: 'Boris Tomiak',
    dateOfBirth: '11.09.1998',
    nationality: 'Deutschland',
    height: 1.93,
    foot: 'rechts',
    teamMemberSince: '01.07.2021',
    club: '1.FC Kaiserslautern',
    previousClub: 'Fortuna Düsseldorf II',
    uid: '0c408602-3ce1-46ab-a745-80868dde1ee2',
    position: 'Innenverteidiger',
    league: '2. Bundesliga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 32,
    name: 'Robin Bormuth',
    dateOfBirth: '19.09.1995',
    nationality: 'Deutschland',
    height: 1.9,
    foot: 'rechts',
    teamMemberSince: '22.08.2022',
    club: '1.FC Kaiserslautern',
    previousClub: 'SC Paderborn 07',
    uid: '8b931e8b-8e40-4c4b-9bb1-fffda1ad6d10',
    position: 'Innenverteidiger',
    league: '2. Bundesliga',
    marketValue: 650000,
  },
  {
    jerseyNumber: 5,
    name: 'Kevin Kraus',
    dateOfBirth: '12.08.1992',
    nationality: 'Deutschland',
    height: 1.9,
    foot: 'rechts',
    teamMemberSince: '01.07.2018',
    club: '1.FC Kaiserslautern',
    previousClub: '1.FC Heidenheim 1846',
    uid: '00f2b6a4-08aa-4610-a84c-59614116850e',
    position: 'Innenverteidiger',
    league: '2. Bundesliga',
    marketValue: 500000,
  },
  {
    jerseyNumber: 22,
    name: 'Lars Bünning',
    dateOfBirth: '27.02.1998',
    nationality: 'Deutschland',
    height: 1.88,
    foot: 'links',
    teamMemberSince: '01.07.2022',
    club: '1.FC Kaiserslautern',
    previousClub: 'SV Meppen',
    uid: 'a9d4c89f-43ef-4ffb-a6d3-af2d6cae3165',
    position: 'Innenverteidiger',
    league: '2. Bundesliga',
    marketValue: 300000,
  },
  {
    jerseyNumber: 21,
    name: 'Hendrick Zuck',
    dateOfBirth: '21.07.1990',
    nationality: 'Deutschland',
    height: 1.76,
    foot: 'links',
    teamMemberSince: '01.07.2018',
    club: '1.FC Kaiserslautern',
    previousClub: 'Eintracht Braunschweig',
    uid: 'e5b6e383-a793-4c5d-b823-e79d70901f5d',
    position: 'Linker Verteidiger',
    league: '2. Bundesliga',
    marketValue: 400000,
  },
  {
    jerseyNumber: 37,
    name: 'Erik Durm',
    dateOfBirth: '12.05.1992',
    nationality: 'Deutschland',
    height: 1.83,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: '1.FC Kaiserslautern',
    previousClub: 'Eintracht Frankfurt',
    uid: '8813380b-e87d-46c5-ba95-77f4c0f2e213',
    position: 'Rechter Verteidiger',
    league: '2. Bundesliga',
    marketValue: 800000,
  },
  {
    jerseyNumber: 8,
    name: 'Jean Zimmer',
    dateOfBirth: '06.12.1993',
    nationality: 'Deutschland',
    height: 1.73,
    foot: 'rechts',
    teamMemberSince: '01.07.2021',
    club: '1.FC Kaiserslautern',
    previousClub: 'Fortuna Düsseldorf',
    uid: '7c9331a5-3358-4db0-bad4-5d9dc0e6247f',
    position: 'Rechter Verteidiger',
    league: '2. Bundesliga',
    marketValue: 600000,
  },
  {
    jerseyNumber: 20,
    name: 'Dominik Schad',
    dateOfBirth: '04.03.1997',
    nationality: 'Deutschland',
    height: 1.76,
    foot: 'rechts',
    teamMemberSince: '01.07.2018',
    club: '1.FC Kaiserslautern',
    previousClub: 'SpVgg Greuther Fürth',
    uid: '07d070c1-d12e-4c92-882e-7191a3123aa0',
    position: 'Rechter Verteidiger',
    league: '2. Bundesliga',
    marketValue: 225000,
  },
  {
    jerseyNumber: 16,
    name: 'Julian Niehues',
    dateOfBirth: '17.04.2001',
    nationality: 'Deutschland',
    height: 1.95,
    foot: 'rechts',
    teamMemberSince: '01.07.2021',
    club: '1.FC Kaiserslautern',
    previousClub: 'Borussia Mönchengladbach II',
    uid: '0890a9d8-db13-4ed2-a414-102be0fb6611',
    position: 'Defensives Mittelfeld',
    league: '2. Bundesliga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 4,
    name: 'Nicolai Rapp',
    dateOfBirth: '13.12.1996',
    nationality: 'Deutschland',
    height: 1.86,
    foot: 'rechts',
    teamMemberSince: '02.01.2023',
    club: '1.FC Kaiserslautern',
    previousClub: 'SV Werder Bremen',
    uid: '22a2b735-619f-4b17-88f6-ad1c68951768',
    position: 'Defensives Mittelfeld',
    league: '2. Bundesliga',
    marketValue: 800000,
  },
  {
    jerseyNumber: 7,
    name: 'Marlon Ritter',
    dateOfBirth: '15.10.1994',
    nationality: 'Deutschland',
    height: 1.73,
    foot: 'rechts',
    teamMemberSince: '12.09.2020',
    club: '1.FC Kaiserslautern',
    previousClub: 'SC Paderborn 07',
    uid: '5011dff3-feb8-4ea8-9dce-efaecafb22a6',
    position: 'Zentrales Mittelfeld',
    league: '2. Bundesliga',
    marketValue: 700000,
  },
  {
    jerseyNumber: 23,
    name: 'Philipp Hercher',
    dateOfBirth: '21.03.1996',
    nationality: 'Deutschland',
    height: 1.82,
    foot: 'rechts',
    teamMemberSince: '08.07.2019',
    club: '1.FC Kaiserslautern',
    previousClub: 'SG Sonnenhof Großaspach',
    uid: '46fd9788-2bb8-4420-8a0c-139cb4dbf20d',
    position: 'Rechtes Mittelfeld',
    league: '2. Bundesliga',
    marketValue: 450000,
  },
  {
    jerseyNumber: 10,
    name: 'Philipp Klement',
    dateOfBirth: '09.09.1992',
    nationality: 'Deutschland',
    height: 1.75,
    foot: 'links',
    teamMemberSince: '25.08.2022',
    club: '1.FC Kaiserslautern',
    previousClub: 'VfB Stuttgart',
    uid: 'ddba6b3f-dda2-4114-a0a1-5f72a4fc9182',
    position: 'Offensives Mittelfeld',
    league: '2. Bundesliga',
    marketValue: 850000,
  },
  {
    jerseyNumber: 11,
    name: 'Kenny Prince Redondo',
    dateOfBirth: '29.08.1994',
    nationality: 'Deutschland',
    height: 1.8,
    foot: 'links',
    teamMemberSince: '05.10.2020',
    club: '1.FC Kaiserslautern',
    previousClub: 'SpVgg Greuther Fürth',
    uid: 'a7478cd2-256f-4b61-a3a4-b2d750b1d459',
    position: 'Linksaußen',
    league: '2. Bundesliga',
    marketValue: 750000,
  },
  {
    jerseyNumber: 25,
    name: 'Aaron Opoku',
    dateOfBirth: '28.03.1999',
    nationality: 'Deutschland',
    height: 1.85,
    foot: 'rechts',
    teamMemberSince: '31.08.2022',
    club: '1.FC Kaiserslautern',
    previousClub: 'Hamburger SV',
    uid: 'fe5173ad-f783-459f-93d5-be6ed5fddc15',
    position: 'Linksaußen',
    league: '2. Bundesliga',
    marketValue: 600000,
  },
  {
    jerseyNumber: 35,
    name: 'Angelos Stavridis',
    dateOfBirth: '22.07.2003',
    nationality: 'Deutschland',
    height: 1.73,
    foot: 'rechts',
    teamMemberSince: '21.11.2022',
    club: '1.FC Kaiserslautern',
    previousClub: '1.FC Kaiserslautern II',
    uid: 'b6f330ae-b06d-4f42-9b70-ab149e0910fb',
    position: 'Linksaußen',
    league: '2. Bundesliga',
    marketValue: 100000,
  },
  {
    jerseyNumber: 31,
    name: 'Ben Zolinski',
    dateOfBirth: '03.05.1992',
    nationality: 'Deutschland',
    height: 1.81,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: '1.FC Kaiserslautern',
    previousClub: 'FC Erzgebirge Aue',
    uid: '454beafc-51e5-40c4-b396-b08f2bb591a0',
    position: 'Rechtsaußen',
    league: '2. Bundesliga',
    marketValue: 450000,
  },
  {
    jerseyNumber: 13,
    name: 'Terrence Boyd',
    dateOfBirth: '16.02.1991',
    nationality: 'Vereinigte Staaten',
    height: 1.88,
    foot: 'rechts',
    teamMemberSince: '22.01.2022',
    club: '1.FC Kaiserslautern',
    previousClub: 'Hallescher FC',
    uid: 'a74135f2-b590-4881-ae42-39e044fa1fe9',
    position: 'Mittelstürmer',
    league: '2. Bundesliga',
    marketValue: 750000,
  },
  {
    jerseyNumber: 28,
    name: 'Nicolas de Préville',
    dateOfBirth: '08.01.1991',
    nationality: 'Frankreich',
    height: 1.82,
    foot: 'rechts',
    teamMemberSince: '31.01.2023',
    club: '1.FC Kaiserslautern',
    previousClub: 'Vereinslos',
    uid: '56ab7a90-0fda-4dd4-8f76-0a2eb7cd5d94',
    position: 'Mittelstürmer',
    league: '2. Bundesliga',
    marketValue: 650000,
  },
  {
    jerseyNumber: 27,
    name: 'Lex-Tyger Lobinger',
    dateOfBirth: '22.02.1999',
    nationality: 'Deutschland',
    height: 1.92,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: '1.FC Kaiserslautern',
    previousClub: 'Fortuna Düsseldorf',
    uid: '68f968cc-3a68-45ac-8bb2-ff104104e8f8',
    position: 'Mittelstürmer',
    league: '2. Bundesliga',
    marketValue: 350000,
  },
  {
    jerseyNumber: 19,
    name: 'Daniel Hanslik',
    dateOfBirth: '06.10.1996',
    nationality: 'Deutschland',
    height: 1.86,
    foot: 'links',
    teamMemberSince: '01.07.2021',
    club: '1.FC Kaiserslautern',
    previousClub: 'Holstein Kiel',
    uid: 'a35690fd-359c-4a69-bdcc-4f736d864740',
    position: 'Mittelstürmer',
    league: '2. Bundesliga',
    marketValue: 325000,
  },
  {
    jerseyNumber: 9,
    name: 'Muhammed Kiprit',
    dateOfBirth: '09.07.1999',
    nationality: 'Türkei',
    height: 1.84,
    foot: 'beidfüßig',
    teamMemberSince: '02.07.2021',
    club: '1.FC Kaiserslautern',
    previousClub: 'KFC Uerdingen 05',
    uid: '1264b5a9-7cf0-4fac-9cc1-20a641fc62c7',
    position: 'Mittelstürmer',
    league: '2. Bundesliga',
    marketValue: 275000,
  },
  {
    jerseyNumber: 99,
    name: 'Gianluigi Donnarumma',
    dateOfBirth: '25.02.1999',
    nationality: 'Italien',
    height: 1.96,
    foot: 'rechts',
    teamMemberSince: '14.07.2021',
    club: 'FC Paris Saint-Germain',
    previousClub: 'AC Mailand',
    uid: '733df953-dffa-405c-897a-7131ba3f2a16',
    position: 'Torwart',
    league: 'Ligue 1',
    marketValue: 50000000,
  },
  {
    jerseyNumber: 16,
    name: 'Sergio Rico',
    dateOfBirth: '01.09.1993',
    nationality: 'Spanien',
    height: 1.96,
    foot: 'rechts',
    teamMemberSince: '05.09.2020',
    club: 'FC Paris Saint-Germain',
    previousClub: 'FC Sevilla',
    uid: '2dfeb287-e97d-463d-b5dc-3176dd881c4e',
    position: 'Torwart',
    league: 'Ligue 1',
    marketValue: 4000000,
  },
  {
    jerseyNumber: 90,
    name: 'Alexandre Letellier',
    dateOfBirth: '11.12.1990',
    nationality: 'Frankreich',
    height: 1.93,
    foot: 'rechts',
    teamMemberSince: '25.09.2020',
    club: 'FC Paris Saint-Germain',
    previousClub: 'US Orléans',
    uid: '6ce58155-9088-4ba2-a1e2-b225bf40bf1b',
    position: 'Torwart',
    league: 'Ligue 1',
    marketValue: 400000,
  },
  {
    jerseyNumber: 5,
    name: 'Marquinhos',
    dateOfBirth: '14.05.1994',
    nationality: 'Brasilien',
    height: 1.83,
    foot: 'rechts',
    teamMemberSince: '19.07.2013',
    club: 'FC Paris Saint-Germain',
    previousClub: ': Ablöse 31,40 Mio. €',
    uid: '5e43ae07-a42f-4720-b6c5-0a0b3732ef2d',
    position: 'Innenverteidiger',
    league: 'Ligue 1',
    marketValue: 70000000,
  },
  {
    jerseyNumber: 3,
    name: 'Presnel Kimpembe',
    dateOfBirth: '13.08.1995',
    nationality: 'Frankreich',
    height: 1.83,
    foot: 'links',
    teamMemberSince: '01.07.2015',
    club: 'FC Paris Saint-Germain',
    previousClub: 'FC Paris Saint-Germain B',
    uid: '78aa618f-ed9f-4856-87c3-75d9e51cb8db',
    position: 'Innenverteidiger',
    league: 'Ligue 1',
    marketValue: 35000000,
  },
  {
    jerseyNumber: 31,
    name: 'El Chadaille Bitshiabu',
    dateOfBirth: '16.05.2005',
    nationality: 'Frankreich',
    height: 1.96,
    foot: 'links',
    teamMemberSince: '01.07.2022',
    club: 'FC Paris Saint-Germain',
    previousClub: 'FC Paris Saint-Germain U19',
    uid: '819ba6a9-3006-4f83-a50e-5c3ee0a1dc67',
    position: 'Innenverteidiger',
    league: 'Ligue 1',
    marketValue: 7000000,
  },
  {
    jerseyNumber: 4,
    name: 'Sergio Ramos',
    dateOfBirth: '30.03.1986',
    nationality: 'Spanien',
    height: 1.84,
    foot: 'rechts',
    teamMemberSince: '08.07.2021',
    club: 'FC Paris Saint-Germain',
    previousClub: 'Real Madrid',
    uid: '9fccb027-b393-48ef-be7d-3f8796536841',
    position: 'Innenverteidiger',
    league: 'Ligue 1',
    marketValue: 6000000,
  },
  {
    jerseyNumber: 25,
    name: 'Nuno Mendes',
    dateOfBirth: '19.06.2002',
    nationality: 'Portugal',
    height: 1.83,
    foot: 'links',
    teamMemberSince: '01.07.2022',
    club: 'FC Paris Saint-Germain',
    previousClub: 'Sporting Lissabon',
    uid: '8de0c040-4437-47aa-af5d-8404e164cf4d',
    position: 'Linker Verteidiger',
    league: 'Ligue 1',
    marketValue: 65000000,
  },
  {
    jerseyNumber: 14,
    name: 'Juan Bernat',
    dateOfBirth: '01.03.1993',
    nationality: 'Spanien',
    height: 1.7,
    foot: 'links',
    teamMemberSince: '31.08.2018',
    club: 'FC Paris Saint-Germain',
    previousClub: 'FC Bayern München',
    uid: 'b45618a6-1545-4a96-9448-793dfcaab6b6',
    position: 'Linker Verteidiger',
    league: 'Ligue 1',
    marketValue: 12000000,
  },
  {
    jerseyNumber: 2,
    name: 'Achraf Hakimi',
    dateOfBirth: '04.11.1998',
    nationality: 'Marokko',
    height: 1.81,
    foot: 'rechts',
    teamMemberSince: '06.07.2021',
    club: 'FC Paris Saint-Germain',
    previousClub: 'Inter Mailand',
    uid: '9952dbae-92b2-40b7-b0a4-be57db3ecf40',
    position: 'Rechter Verteidiger',
    league: 'Ligue 1',
    marketValue: 70000000,
  },
  {
    jerseyNumber: 26,
    name: 'Nordi Mukiele',
    dateOfBirth: '01.11.1997',
    nationality: 'Frankreich',
    height: 1.87,
    foot: 'rechts',
    teamMemberSince: '26.07.2022',
    club: 'FC Paris Saint-Germain',
    previousClub: 'RasenBallsport Leipzig',
    uid: '9dc4262c-b3e4-42e2-a211-7e15f67a8bb7',
    position: 'Rechter Verteidiger',
    league: 'Ligue 1',
    marketValue: 20000000,
  },
  {
    jerseyNumber: 29,
    name: 'Timothée Pembélé',
    dateOfBirth: '09.09.2002',
    nationality: 'Frankreich',
    height: 1.83,
    foot: 'rechts',
    teamMemberSince: '01.07.2020',
    club: 'FC Paris Saint-Germain',
    previousClub: 'FC Paris Saint-Germain U19',
    uid: 'ce838b32-f75c-4b5c-b612-ad58340fd99e',
    position: 'Rechter Verteidiger',
    league: 'Ligue 1',
    marketValue: 5000000,
  },
  {
    jerseyNumber: 15,
    name: 'Danilo Pereira',
    dateOfBirth: '09.09.1991',
    nationality: 'Portugal',
    height: 1.88,
    foot: 'rechts',
    teamMemberSince: '01.07.2021',
    club: 'FC Paris Saint-Germain',
    previousClub: 'FC Porto',
    uid: 'c753d744-4ced-462b-b76e-02f183f0f3bd',
    position: 'Defensives Mittelfeld',
    league: 'Ligue 1',
    marketValue: 12000000,
  },
  {
    jerseyNumber: 6,
    name: 'Marco Verratti',
    dateOfBirth: '05.11.1992',
    nationality: 'Italien',
    height: 1.65,
    foot: 'rechts',
    teamMemberSince: '18.07.2012',
    club: 'FC Paris Saint-Germain',
    previousClub: ': Ablöse 12,00 Mio. €',
    uid: 'fcf89947-1741-47ae-9982-d563dab92e96',
    position: 'Zentrales Mittelfeld',
    league: 'Ligue 1',
    marketValue: 50000000,
  },
  {
    jerseyNumber: 17,
    name: 'Vitinha',
    dateOfBirth: '13.02.2000',
    nationality: 'Portugal',
    height: 1.72,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'FC Paris Saint-Germain',
    previousClub: 'FC Porto',
    uid: '81681204-109c-4860-b3c2-5120f2d9476c',
    position: 'Zentrales Mittelfeld',
    league: 'Ligue 1',
    marketValue: 42000000,
  },
  {
    jerseyNumber: 8,
    name: 'Fabián Ruiz',
    dateOfBirth: '03.04.1996',
    nationality: 'Spanien',
    height: 1.89,
    foot: 'links',
    teamMemberSince: '30.08.2022',
    club: 'FC Paris Saint-Germain',
    previousClub: 'SSC Neapel',
    uid: 'e274f195-9ccf-4cd9-b009-ba2a84e3cf9e',
    position: 'Zentrales Mittelfeld',
    league: 'Ligue 1',
    marketValue: 38000000,
  },
  {
    jerseyNumber: 28,
    name: 'Carlos Soler',
    dateOfBirth: '02.01.1997',
    nationality: 'Spanien',
    height: 1.8,
    foot: 'rechts',
    teamMemberSince: '01.09.2022',
    club: 'FC Paris Saint-Germain',
    previousClub: 'FC Valencia',
    uid: '141efd5b-0532-453f-aeeb-0713278bffa0',
    position: 'Zentrales Mittelfeld',
    league: 'Ligue 1',
    marketValue: 30000000,
  },
  {
    jerseyNumber: 18,
    name: 'Renato Sanches',
    dateOfBirth: '18.08.1997',
    nationality: 'Portugal',
    height: 1.76,
    foot: 'rechts',
    teamMemberSince: '04.08.2022',
    club: 'FC Paris Saint-Germain',
    previousClub: 'LOSC Lille',
    uid: 'af745095-4309-4d5f-8d90-5e149935fee3',
    position: 'Zentrales Mittelfeld',
    league: 'Ligue 1',
    marketValue: 20000000,
  },
  {
    jerseyNumber: 33,
    name: 'Warren Zaïre-Emery',
    dateOfBirth: '08.03.2006',
    nationality: 'Frankreich',
    height: 1.78,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'FC Paris Saint-Germain',
    previousClub: 'FC Paris Saint-Germain U19',
    uid: '09ad9a91-0ef9-40b2-8eb6-70fd8cdc43fe',
    position: 'Zentrales Mittelfeld',
    league: 'Ligue 1',
    marketValue: 18000000,
  },
  {
    jerseyNumber: 35,
    name: 'Ismaël Gharbi',
    dateOfBirth: '10.04.2004',
    nationality: 'Spanien',
    height: 1.73,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'FC Paris Saint-Germain',
    previousClub: 'FC Paris Saint-Germain U19',
    uid: '8d7fa511-42b9-43ea-8cbd-b175e8304b40',
    position: 'Offensives Mittelfeld',
    league: 'Ligue 1',
    marketValue: 5000000,
  },
  {
    jerseyNumber: 10,
    name: 'Neymar',
    dateOfBirth: '05.02.1992',
    nationality: 'Brasilien',
    height: 1.75,
    foot: 'rechts',
    teamMemberSince: '03.08.2017',
    club: 'FC Paris Saint-Germain',
    previousClub: 'FC Barcelona',
    uid: '32129b58-122a-4a58-beee-2d8dd18e6a80',
    position: 'Linksaußen',
    league: 'Ligue 1',
    marketValue: 70000000,
  },
  {
    jerseyNumber: 30,
    name: 'Lionel Messi',
    dateOfBirth: '24.06.1987',
    nationality: 'Argentinien',
    height: 1.7,
    foot: 'links',
    teamMemberSince: '10.08.2021',
    club: 'FC Paris Saint-Germain',
    previousClub: 'FC Barcelona',
    uid: '88570c87-5ee4-4ae0-9bb2-726666244535',
    position: 'Rechtsaußen',
    league: 'Ligue 1',
    marketValue: 45000000,
  },
  {
    jerseyNumber: 7,
    name: 'Kylian Mbappé',
    dateOfBirth: '20.12.1998',
    nationality: 'Frankreich',
    height: 1.78,
    foot: 'rechts',
    teamMemberSince: '01.07.2018',
    club: 'FC Paris Saint-Germain',
    previousClub: 'AS Monaco',
    uid: '79d5b67d-08f2-4f35-9765-9cf1fe0ba565',
    position: 'Mittelstürmer',
    league: 'Ligue 1',
    marketValue: 180000000,
  },
  {
    jerseyNumber: 44,
    name: 'Hugo Ekitiké',
    dateOfBirth: '20.06.2002',
    nationality: 'Frankreich',
    height: 1.89,
    foot: 'rechts',
    teamMemberSince: '16.07.2022',
    club: 'FC Paris Saint-Germain',
    previousClub: 'Stade Reims',
    uid: '8f4e0755-5ecd-49f8-9d0c-7f7c5f63f319',
    position: 'Mittelstürmer',
    league: 'Ligue 1',
    marketValue: 25000000,
  },
  {
    jerseyNumber: 16,
    name: 'Alexander Nübel',
    dateOfBirth: '30.09.1996',
    nationality: 'Deutschland',
    height: 1.93,
    foot: 'rechts',
    teamMemberSince: '01.07.2021',
    club: 'AS Monaco',
    previousClub: 'FC Bayern München',
    uid: '98f54130-6cd2-4cdc-bd95-5db4c17337d7',
    position: 'Torwart',
    league: 'Ligue 1',
    marketValue: 10000000,
  },
  {
    jerseyNumber: 30,
    name: 'Thomas Didillon',
    dateOfBirth: '28.11.1995',
    nationality: 'Frankreich',
    height: 1.93,
    foot: 'links',
    teamMemberSince: '20.07.2022',
    club: 'AS Monaco',
    previousClub: 'Cercle Brügge',
    uid: 'fb022d0c-66a5-4e43-88e7-5a2d476a91c9',
    position: 'Torwart',
    league: 'Ligue 1',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 6,
    name: 'Axel Disasi',
    dateOfBirth: '11.03.1998',
    nationality: 'Frankreich',
    height: 1.9,
    foot: 'rechts',
    teamMemberSince: '07.08.2020',
    club: 'AS Monaco',
    previousClub: 'Stade Reims',
    uid: '4557ec85-1a4d-4e46-ad43-bf078ea3cdff',
    position: 'Innenverteidiger',
    league: 'Ligue 1',
    marketValue: 25000000,
  },
  {
    jerseyNumber: 3,
    name: 'Guillermo Maripán',
    dateOfBirth: '06.05.1994',
    nationality: 'Chile',
    height: 1.9,
    foot: 'rechts',
    teamMemberSince: '24.08.2019',
    club: 'AS Monaco',
    previousClub: 'Deportivo Alavés',
    uid: 'b350f109-5db2-4961-bce3-5bf2037237e8',
    position: 'Innenverteidiger',
    league: 'Ligue 1',
    marketValue: 13000000,
  },
  {
    jerseyNumber: 23,
    name: 'Malang Sarr',
    dateOfBirth: '23.01.1999',
    nationality: 'Frankreich',
    height: 1.83,
    foot: 'links',
    teamMemberSince: '10.08.2022',
    club: 'AS Monaco',
    previousClub: 'FC Chelsea',
    uid: 'a8502d9b-1f47-4444-b809-b8ba9ecc20ef',
    position: 'Innenverteidiger',
    league: 'Ligue 1',
    marketValue: 8000000,
  },
  {
    jerseyNumber: 34,
    name: 'Chrislain Matsima',
    dateOfBirth: '15.05.2002',
    nationality: 'Frankreich',
    height: 1.93,
    foot: 'rechts',
    teamMemberSince: '01.07.2020',
    club: 'AS Monaco',
    previousClub: 'AS Monaco B',
    uid: '6f5c763f-feab-49d4-a24f-b92c248703bf',
    position: 'Innenverteidiger',
    league: 'Ligue 1',
    marketValue: 4000000,
  },
  {
    jerseyNumber: 12,
    name: 'Caio Henrique',
    dateOfBirth: '31.07.1997',
    nationality: 'Brasilien',
    height: 1.78,
    foot: 'links',
    teamMemberSince: '27.08.2020',
    club: 'AS Monaco',
    previousClub: 'Atlético Madrid',
    uid: '58272bba-2137-4c34-8b50-1a850dac878a',
    position: 'Linker Verteidiger',
    league: 'Ligue 1',
    marketValue: 20000000,
  },
  {
    jerseyNumber: 14,
    name: 'Ismail Jakobs',
    dateOfBirth: '17.08.1999',
    nationality: 'Senegal',
    height: 1.84,
    foot: 'links',
    teamMemberSince: '12.07.2021',
    club: 'AS Monaco',
    previousClub: '1.FC Köln',
    uid: 'a16fe80f-e594-420a-8710-9bfdee88181d',
    position: 'Linker Verteidiger',
    league: 'Ligue 1',
    marketValue: 7000000,
  },
  {
    jerseyNumber: 2,
    name: 'Vanderson',
    dateOfBirth: '21.06.2001',
    nationality: 'Brasilien',
    height: 1.73,
    foot: 'rechts',
    teamMemberSince: '01.01.2022',
    club: 'AS Monaco',
    previousClub: 'Grêmio Porto Alegre',
    uid: '60954343-1351-487d-8c54-f2abfedc9bf6',
    position: 'Rechter Verteidiger',
    league: 'Ligue 1',
    marketValue: 18000000,
  },
  {
    jerseyNumber: 26,
    name: 'Ruben Aguilar',
    dateOfBirth: '26.04.1993',
    nationality: 'Frankreich',
    height: 1.7,
    foot: 'rechts',
    teamMemberSince: '06.08.2019',
    club: 'AS Monaco',
    previousClub: 'HSC Montpellier',
    uid: '38d8cd48-7a72-4c11-9041-61e438cb2c0f',
    position: 'Rechter Verteidiger',
    league: 'Ligue 1',
    marketValue: 10000000,
  },
  {
    jerseyNumber: 4,
    name: 'Mohamed Camara',
    dateOfBirth: '06.01.2000',
    nationality: 'Mali',
    height: 1.73,
    foot: 'rechts',
    teamMemberSince: '14.08.2022',
    club: 'AS Monaco',
    previousClub: 'Red Bull Salzburg',
    uid: '8f9c8493-568c-4684-a151-227d1abb26c0',
    position: 'Defensives Mittelfeld',
    league: 'Ligue 1',
    marketValue: 28000000,
  },
  {
    jerseyNumber: 41,
    name: 'Soungoutou Magassa',
    dateOfBirth: '08.10.2003',
    nationality: 'Frankreich',
    height: 1.88,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'AS Monaco',
    previousClub: 'AS Monaco U21',
    uid: '8f2dfe37-7b25-4447-a445-405ccaf91431',
    position: 'Defensives Mittelfeld',
    league: 'Ligue 1',
    marketValue: 500000,
  },
  {
    jerseyNumber: 19,
    name: 'Youssouf Fofana',
    dateOfBirth: '10.01.1999',
    nationality: 'Frankreich',
    height: 1.85,
    foot: 'rechts',
    teamMemberSince: '29.01.2020',
    club: 'AS Monaco',
    previousClub: 'RC Straßburg Alsace',
    uid: '9194f4bd-4113-4455-a99a-d53b7b01636e',
    position: 'Zentrales Mittelfeld',
    league: 'Ligue 1',
    marketValue: 25000000,
  },
  {
    jerseyNumber: 11,
    name: 'Jean Lucas',
    dateOfBirth: '22.06.1998',
    nationality: 'Brasilien',
    height: 1.79,
    foot: 'rechts',
    teamMemberSince: '02.08.2021',
    club: 'AS Monaco',
    previousClub: ': Ablöse 11,00 Mio. €',
    uid: 'fb55b240-ade5-4af3-8330-bff1b55a6191',
    position: 'Zentrales Mittelfeld',
    league: 'Ligue 1',
    marketValue: 7000000,
  },
  {
    jerseyNumber: 15,
    name: 'Eliot Matazo',
    dateOfBirth: '15.02.2002',
    nationality: 'Belgien',
    height: 1.7,
    foot: 'rechts',
    teamMemberSince: '01.07.2020',
    club: 'AS Monaco',
    previousClub: 'AS Monaco B',
    uid: 'c36d1060-6b0c-4cb1-805e-f7e6462ab9b1',
    position: 'Zentrales Mittelfeld',
    league: 'Ligue 1',
    marketValue: 7000000,
  },
  {
    jerseyNumber: 17,
    name: 'Aleksandr Golovin',
    dateOfBirth: '30.05.1996',
    nationality: 'Russland',
    height: 1.78,
    foot: 'rechts',
    teamMemberSince: '27.07.2018',
    club: 'AS Monaco',
    previousClub: 'ZSKA Moskau',
    uid: 'ea5a2f34-ceec-40fa-af05-8c0e22d48ec5',
    position: 'Offensives Mittelfeld',
    league: 'Ligue 1',
    marketValue: 25000000,
  },
  {
    jerseyNumber: 21,
    name: 'Maghnes Akliouche',
    dateOfBirth: '25.02.2002',
    nationality: 'Frankreich',
    height: 1.83,
    foot: 'links',
    teamMemberSince: '01.07.2022',
    club: 'AS Monaco',
    previousClub: 'AS Monaco U21',
    uid: 'b2b58fe8-8532-4cc2-bec3-519aba457a57',
    position: 'Offensives Mittelfeld',
    league: 'Ligue 1',
    marketValue: 6000000,
  },
  {
    jerseyNumber: 18,
    name: 'Takumi Minamino',
    dateOfBirth: '16.01.1995',
    nationality: 'Japan',
    height: 1.72,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'AS Monaco',
    previousClub: 'FC Liverpool',
    uid: '4b7df823-8536-4168-91d0-36abdd292f7c',
    position: 'Linksaußen',
    league: 'Ligue 1',
    marketValue: 8000000,
  },
  {
    jerseyNumber: 27,
    name: 'Krépin Diatta',
    dateOfBirth: '25.02.1999',
    nationality: 'Senegal',
    height: 1.75,
    foot: 'rechts',
    teamMemberSince: '21.01.2021',
    club: 'AS Monaco',
    previousClub: 'FC Brügge',
    uid: 'a683d946-d31f-4a56-8ea4-9685698b4027',
    position: 'Rechtsaußen',
    league: 'Ligue 1',
    marketValue: 15000000,
  },
  {
    jerseyNumber: 77,
    name: 'Gelson Martins',
    dateOfBirth: '11.05.1995',
    nationality: 'Portugal',
    height: 1.7,
    foot: 'rechts',
    teamMemberSince: '01.07.2019',
    club: 'AS Monaco',
    previousClub: 'Atlético Madrid',
    uid: 'ee94a406-add9-456c-b1e6-c6b9747c6c31',
    position: 'Rechtsaußen',
    league: 'Ligue 1',
    marketValue: 8000000,
  },
  {
    jerseyNumber: 36,
    name: 'Breel Embolo',
    dateOfBirth: '14.02.1997',
    nationality: 'Schweiz',
    height: 1.87,
    foot: 'rechts',
    teamMemberSince: '15.07.2022',
    club: 'AS Monaco',
    previousClub: 'Borussia Mönchengladbach',
    uid: '0f6311c2-ddff-465c-84b6-d77678194e07',
    position: 'Mittelstürmer',
    league: 'Ligue 1',
    marketValue: 22000000,
  },
  {
    jerseyNumber: 10,
    name: 'Wissam Ben Yedder',
    dateOfBirth: '12.08.1990',
    nationality: 'Frankreich',
    height: 1.7,
    foot: 'beidfüßig',
    teamMemberSince: '14.08.2019',
    club: 'AS Monaco',
    previousClub: 'FC Sevilla',
    uid: 'a9b322fb-7022-49f2-a323-14329b1a2160',
    position: 'Mittelstürmer',
    league: 'Ligue 1',
    marketValue: 20000000,
  },
  {
    jerseyNumber: 31,
    name: 'Kevin Volland',
    dateOfBirth: '30.07.1992',
    nationality: 'Deutschland',
    height: 1.79,
    foot: 'links',
    teamMemberSince: '02.09.2020',
    club: 'AS Monaco',
    previousClub: 'Bayer 04 Leverkusen',
    uid: 'e64bb824-9e35-405c-949c-6fa7d221bc60',
    position: 'Mittelstürmer',
    league: 'Ligue 1',
    marketValue: 10000000,
  },
  {
    jerseyNumber: 9,
    name: 'Myron Boadu',
    dateOfBirth: '14.01.2001',
    nationality: 'Niederlande',
    height: 1.81,
    foot: 'rechts',
    teamMemberSince: '04.08.2021',
    club: 'AS Monaco',
    previousClub: 'AZ Alkmaar',
    uid: '0e5917e0-95ae-4e56-8bc2-b5a443020f54',
    position: 'Mittelstürmer',
    league: 'Ligue 1',
    marketValue: 8000000,
  },
  {
    jerseyNumber: 1,
    name: 'Dogan Alemdar',
    dateOfBirth: '29.10.2002',
    nationality: 'Türkei',
    height: 1.92,
    foot: 'rechts',
    teamMemberSince: '27.08.2021',
    club: 'FC Stade Rennes',
    previousClub: 'Kayserispor',
    uid: '0b228a59-e2b4-4481-a041-39b67fcb04a5',
    position: 'Torwart',
    league: 'Ligue 1',
    marketValue: 4000000,
  },
  {
    jerseyNumber: 30,
    name: 'Steve Mandanda',
    dateOfBirth: '28.03.1985',
    nationality: 'Frankreich',
    height: 1.87,
    foot: 'rechts',
    teamMemberSince: '06.07.2022',
    club: 'FC Stade Rennes',
    previousClub: 'Olympique Marseille',
    uid: '6eada4f3-8f26-49f0-96c0-eb5b2fc33713',
    position: 'Torwart',
    league: 'Ligue 1',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 89,
    name: 'Romain Salin',
    dateOfBirth: '29.07.1984',
    nationality: 'Frankreich',
    height: 1.86,
    foot: 'links',
    teamMemberSince: '01.07.2019',
    club: 'FC Stade Rennes',
    previousClub: 'Sporting Lissabon',
    uid: '684adf27-4542-4c5c-9072-bcf9063a6d00',
    position: 'Torwart',
    league: 'Ligue 1',
    marketValue: 300000,
  },
  {
    jerseyNumber: 50,
    name: 'Elias Damergy',
    dateOfBirth: '17.10.2002',
    nationality: 'Tunesien',
    height: 1.86,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'FC Stade Rennes',
    previousClub: 'FC Stade Rennes B',
    uid: '329f80f6-d601-4480-97a3-cec019a52e5c',
    position: 'Torwart',
    league: 'Ligue 1',
    marketValue: 200000,
  },
  {
    jerseyNumber: 5,
    name: 'Arthur Theate',
    dateOfBirth: '25.05.2000',
    nationality: 'Belgien',
    height: 1.85,
    foot: 'links',
    teamMemberSince: '29.07.2022',
    club: 'FC Stade Rennes',
    previousClub: 'FC Bologna',
    uid: 'b92bd81e-aa9f-43f2-9c29-524682eb7f7a',
    position: 'Innenverteidiger',
    league: 'Ligue 1',
    marketValue: 20000000,
  },
  {
    jerseyNumber: 2,
    name: 'Joe Rodon',
    dateOfBirth: '22.10.1997',
    nationality: 'Wales',
    height: 1.93,
    foot: 'rechts',
    teamMemberSince: '01.08.2022',
    club: 'FC Stade Rennes',
    previousClub: 'Tottenham Hotspur',
    uid: '51e20e49-df91-4eb8-98bf-1f148aee3f01',
    position: 'Innenverteidiger',
    league: 'Ligue 1',
    marketValue: 8000000,
  },
  {
    jerseyNumber: 23,
    name: 'Warmed Omari',
    dateOfBirth: '23.04.2000',
    nationality: 'Frankreich',
    height: 1.88,
    foot: 'rechts',
    teamMemberSince: '01.07.2021',
    club: 'FC Stade Rennes',
    previousClub: 'FC Stade Rennes B',
    uid: '3046f555-4360-41d2-8161-86d8143f3177',
    position: 'Innenverteidiger',
    league: 'Ligue 1',
    marketValue: 8000000,
  },
  {
    jerseyNumber: 15,
    name: 'Christopher Wooh',
    dateOfBirth: '18.09.2001',
    nationality: 'Kamerun',
    height: 1.91,
    foot: 'rechts',
    teamMemberSince: '01.09.2022',
    club: 'FC Stade Rennes',
    previousClub: 'RC Lens',
    uid: 'cb19f859-6710-4b16-a774-792b59390b90',
    position: 'Innenverteidiger',
    league: 'Ligue 1',
    marketValue: 6000000,
  },
  {
    jerseyNumber: 18,
    name: 'Jeanuël Belocian',
    dateOfBirth: '17.02.2005',
    nationality: 'Frankreich',
    height: 1.82,
    foot: 'links',
    teamMemberSince: '01.07.2022',
    club: 'FC Stade Rennes',
    previousClub: 'FC Stade Rennes U19',
    uid: 'a7d084ee-89e8-4922-9d54-7810957111a0',
    position: 'Innenverteidiger',
    league: 'Ligue 1',
    marketValue: 4000000,
  },
  {
    jerseyNumber: 3,
    name: 'Adrien Truffert',
    dateOfBirth: '20.11.2001',
    nationality: 'Frankreich',
    height: 1.76,
    foot: 'links',
    teamMemberSince: '01.07.2020',
    club: 'FC Stade Rennes',
    previousClub: 'FC Stade Rennes B',
    uid: '429c2f78-9c93-41a7-a76f-a1e7041615bc',
    position: 'Linker Verteidiger',
    league: 'Ligue 1',
    marketValue: 18000000,
  },
  {
    jerseyNumber: 25,
    name: 'Birger Meling',
    dateOfBirth: '17.12.1994',
    nationality: 'Norwegen',
    height: 1.73,
    foot: 'links',
    teamMemberSince: '20.07.2021',
    club: 'FC Stade Rennes',
    previousClub: 'Nîmes Olympique',
    uid: '4258a303-1318-4d78-b8b3-059e2eef13cd',
    position: 'Linker Verteidiger',
    league: 'Ligue 1',
    marketValue: 5000000,
  },
  {
    jerseyNumber: 90,
    name: 'Djed Spence',
    dateOfBirth: '09.08.2000',
    nationality: 'England',
    height: 1.84,
    foot: 'rechts',
    teamMemberSince: '31.01.2023',
    club: 'FC Stade Rennes',
    previousClub: 'Tottenham Hotspur',
    uid: '6d7cb2a8-b2ba-4c99-90d5-1f9062948d75',
    position: 'Rechter Verteidiger',
    league: 'Ligue 1',
    marketValue: 13000000,
  },
  {
    jerseyNumber: 27,
    name: 'Hamari Traoré',
    dateOfBirth: '27.01.1992',
    nationality: 'Mali',
    height: 1.75,
    foot: 'rechts',
    teamMemberSince: '01.07.2017',
    club: 'FC Stade Rennes',
    previousClub: ': Ablöse 2,40 Mio. €',
    uid: '7babab50-906d-4996-9c92-4faa6c25f25d',
    position: 'Rechter Verteidiger',
    league: 'Ligue 1',
    marketValue: 10000000,
  },
  {
    jerseyNumber: 22,
    name: 'Lorenz Assignon',
    dateOfBirth: '22.06.2000',
    nationality: 'Frankreich',
    height: 1.81,
    foot: 'rechts',
    teamMemberSince: '01.07.2021',
    club: 'FC Stade Rennes',
    previousClub: 'FC Stade Rennes B',
    uid: '4ebf832c-4e5d-4bec-859d-35a693fdffb0',
    position: 'Rechter Verteidiger',
    league: 'Ligue 1',
    marketValue: 4000000,
  },
  {
    jerseyNumber: 31,
    name: 'Guéla Doué',
    dateOfBirth: '17.10.2002',
    nationality: 'Elfenbeinküste',
    height: 1.86,
    foot: 'beidfüßig',
    teamMemberSince: '01.07.2022',
    club: 'FC Stade Rennes',
    previousClub: 'FC Stade Rennes B',
    uid: '9d6a135f-1443-43ad-897f-bdabe3bacec0',
    position: 'Rechter Verteidiger',
    league: 'Ligue 1',
    marketValue: 300000,
  },
  {
    jerseyNumber: 8,
    name: 'Baptiste Santamaria',
    dateOfBirth: '09.03.1995',
    nationality: 'Frankreich',
    height: 1.83,
    foot: 'rechts',
    teamMemberSince: '17.08.2021',
    club: 'FC Stade Rennes',
    previousClub: 'SC Freiburg',
    uid: 'f8ab3af8-0f34-41cf-a1a6-443b34e2185d',
    position: 'Defensives Mittelfeld',
    league: 'Ligue 1',
    marketValue: 15000000,
  },
  {
    jerseyNumber: 6,
    name: 'Lesley Ugochukwu',
    dateOfBirth: '26.03.2004',
    nationality: 'Frankreich',
    height: 1.91,
    foot: 'rechts',
    teamMemberSince: '01.07.2021',
    club: 'FC Stade Rennes',
    previousClub: 'FC Stade Rennes B',
    uid: '581cfd4f-1026-41a1-877a-1b136bdd6acf',
    position: 'Defensives Mittelfeld',
    league: 'Ligue 1',
    marketValue: 8000000,
  },
  {
    jerseyNumber: 80,
    name: 'Xeka',
    dateOfBirth: '10.11.1994',
    nationality: 'Portugal',
    height: 1.85,
    foot: 'beidfüßig',
    teamMemberSince: '21.09.2022',
    club: 'FC Stade Rennes',
    previousClub: 'Vereinslos',
    uid: 'd4eac59c-491d-491c-ba31-cfba5bb66c5e',
    position: 'Zentrales Mittelfeld',
    league: 'Ligue 1',
    marketValue: 9000000,
  },
  {
    jerseyNumber: 20,
    name: 'Flavien Tait',
    dateOfBirth: '02.02.1993',
    nationality: 'Frankreich',
    height: 1.76,
    foot: 'rechts',
    teamMemberSince: '09.07.2019',
    club: 'FC Stade Rennes',
    previousClub: ': Ablöse 9,00 Mio. €',
    uid: 'a47b523d-f4dc-4142-8273-148af2b5a0d3',
    position: 'Zentrales Mittelfeld',
    league: 'Ligue 1',
    marketValue: 7000000,
  },
  {
    jerseyNumber: 14,
    name: 'Benjamin Bourigeaud',
    dateOfBirth: '14.01.1994',
    nationality: 'Frankreich',
    height: 1.78,
    foot: 'rechts',
    teamMemberSince: '01.07.2017',
    club: 'FC Stade Rennes',
    previousClub: 'RC Lens',
    uid: 'd9bff65b-5a99-42e8-b2c1-c30c84abba58',
    position: 'Rechtes Mittelfeld',
    league: 'Ligue 1',
    marketValue: 18000000,
  },
  {
    jerseyNumber: 21,
    name: 'Lovro Majer',
    dateOfBirth: '17.01.1998',
    nationality: 'Kroatien',
    height: 1.78,
    foot: 'links',
    teamMemberSince: '26.08.2021',
    club: 'FC Stade Rennes',
    previousClub: 'GNK Dinamo Zagreb',
    uid: '11a54f74-6ae6-4b64-b988-6bac4d7b024a',
    position: 'Offensives Mittelfeld',
    league: 'Ligue 1',
    marketValue: 20000000,
  },
  {
    jerseyNumber: 33,
    name: 'Désiré Doué',
    dateOfBirth: '03.06.2005',
    nationality: 'Frankreich',
    height: 1.81,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'FC Stade Rennes',
    previousClub: 'FC Stade Rennes U19',
    uid: '8c04c66d-b7fc-4524-932e-a14427896e29',
    position: 'Offensives Mittelfeld',
    league: 'Ligue 1',
    marketValue: 15000000,
  },
  {
    jerseyNumber: 7,
    name: 'Martin Terrier',
    dateOfBirth: '04.03.1997',
    nationality: 'Frankreich',
    height: 1.84,
    foot: 'rechts',
    teamMemberSince: '06.07.2020',
    club: 'FC Stade Rennes',
    previousClub: ': Ablöse 12,00 Mio. €',
    uid: 'c4a622ef-61b7-40fe-b9fc-a7c61e38062a',
    position: 'Linksaußen',
    league: 'Ligue 1',
    marketValue: 35000000,
  },
  {
    jerseyNumber: 17,
    name: 'Karl Toko Ekambi',
    dateOfBirth: '14.09.1992',
    nationality: 'Kamerun',
    height: 1.82,
    foot: 'rechts',
    teamMemberSince: '26.01.2023',
    club: 'FC Stade Rennes',
    previousClub: 'Olympique Lyon',
    uid: 'e739bd97-c593-465c-acbe-b5323c1ed296',
    position: 'Linksaußen',
    league: 'Ligue 1',
    marketValue: 10000000,
  },
  {
    jerseyNumber: 34,
    name: 'Ibrahim Salah',
    dateOfBirth: '30.08.2001',
    nationality: 'Marokko',
    height: 1.85,
    foot: 'rechts',
    teamMemberSince: '31.01.2023',
    club: 'FC Stade Rennes',
    previousClub: 'KAA Gent',
    uid: '2fd41f51-8330-4c95-be5a-098f7d25972d',
    position: 'Linksaußen',
    league: 'Ligue 1',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 10,
    name: 'Jérémy Doku',
    dateOfBirth: '27.05.2002',
    nationality: 'Belgien',
    height: 1.73,
    foot: 'rechts',
    teamMemberSince: '05.10.2020',
    club: 'FC Stade Rennes',
    previousClub: 'RSC Anderlecht',
    uid: 'a6e322db-0724-48fc-8518-1750fe9e4077',
    position: 'Rechtsaußen',
    league: 'Ligue 1',
    marketValue: 18000000,
  },
  {
    jerseyNumber: 19,
    name: 'Amine Gouiri',
    dateOfBirth: '16.02.2000',
    nationality: 'Frankreich',
    height: 1.81,
    foot: 'rechts',
    teamMemberSince: '01.09.2022',
    club: 'FC Stade Rennes',
    previousClub: 'OGC Nizza',
    uid: '0e7f328b-9220-40a1-97db-2427ffda6f95',
    position: 'Mittelstürmer',
    league: 'Ligue 1',
    marketValue: 35000000,
  },
  {
    jerseyNumber: 9,
    name: 'Arnaud Kalimuendo',
    dateOfBirth: '20.01.2002',
    nationality: 'Frankreich',
    height: 1.75,
    foot: 'rechts',
    teamMemberSince: '11.08.2022',
    club: 'FC Stade Rennes',
    previousClub: 'FC Paris Saint-Germain',
    uid: 'c609d521-4915-41b8-8a12-56c4745864c2',
    position: 'Mittelstürmer',
    league: 'Ligue 1',
    marketValue: 25000000,
  },
  {
    jerseyNumber: 16,
    name: 'Pau López',
    dateOfBirth: '13.12.1994',
    nationality: 'Spanien',
    height: 1.89,
    foot: 'links',
    teamMemberSince: '01.07.2022',
    club: 'Olympique Marseille',
    previousClub: 'AS Rom',
    uid: 'ed8fc4bb-6987-43a8-9a29-2a9480dbeac1',
    position: 'Torwart',
    league: 'Ligue 1',
    marketValue: 14000000,
  },
  {
    jerseyNumber: 36,
    name: 'Rubén Blanco',
    dateOfBirth: '25.07.1995',
    nationality: 'Spanien',
    height: 1.88,
    foot: 'rechts',
    teamMemberSince: '20.07.2022',
    club: 'Olympique Marseille',
    previousClub: 'Celta Vigo',
    uid: '7039a385-394a-4d7c-9695-679b66d269ce',
    position: 'Torwart',
    league: 'Ligue 1',
    marketValue: 4000000,
  },
  {
    jerseyNumber: 1,
    name: 'Simon Ngapandouetnbu',
    dateOfBirth: '12.04.2003',
    nationality: 'Kamerun',
    height: 1.86,
    foot: 'rechts',
    teamMemberSince: '11.10.2019',
    club: 'Olympique Marseille',
    previousClub: 'Olympique Marseille B',
    uid: '2c6b7647-1fd8-4c99-a60b-d954dc913b68',
    position: 'Torwart',
    league: 'Ligue 1',
    marketValue: 400000,
  },
  {
    jerseyNumber: 99,
    name: 'Chancel Mbemba',
    dateOfBirth: '08.08.1994',
    nationality: 'DR Kongo',
    height: 1.82,
    foot: 'rechts',
    teamMemberSince: '15.07.2022',
    club: 'Olympique Marseille',
    previousClub: 'FC Porto',
    uid: '0a0aacbb-81a8-4f1b-9c29-a43499daf45e',
    position: 'Innenverteidiger',
    league: 'Ligue 1',
    marketValue: 20000000,
  },
  {
    jerseyNumber: 4,
    name: 'Samuel Gigot',
    dateOfBirth: '12.10.1993',
    nationality: 'Frankreich',
    height: 1.87,
    foot: 'rechts',
    teamMemberSince: '29.01.2022',
    club: 'Olympique Marseille',
    previousClub: ': Ablöse 500 Tsd. €',
    uid: '24d35ebb-f1a7-4138-bc37-6d513a75ed1f',
    position: 'Innenverteidiger',
    league: 'Ligue 1',
    marketValue: 10000000,
  },
  {
    jerseyNumber: 5,
    name: 'Leonardo Balerdi',
    dateOfBirth: '26.01.1999',
    nationality: 'Argentinien',
    height: 1.87,
    foot: 'rechts',
    teamMemberSince: '03.07.2021',
    club: 'Olympique Marseille',
    previousClub: 'Borussia Dortmund',
    uid: '005e52b7-8879-42be-a2f6-83fbc2c0c430',
    position: 'Innenverteidiger',
    league: 'Ligue 1',
    marketValue: 10000000,
  },
  {
    jerseyNumber: 3,
    name: 'Eric Bailly',
    dateOfBirth: '12.04.1994',
    nationality: 'Elfenbeinküste',
    height: 1.87,
    foot: 'rechts',
    teamMemberSince: '24.08.2022',
    club: 'Olympique Marseille',
    previousClub: 'Manchester United',
    uid: '17241446-a756-433d-acb4-1a0a186293f3',
    position: 'Innenverteidiger',
    league: 'Ligue 1',
    marketValue: 8000000,
  },
  {
    jerseyNumber: 30,
    name: 'Nuno Tavares',
    dateOfBirth: '26.01.2000',
    nationality: 'Portugal',
    height: 1.83,
    foot: 'links',
    teamMemberSince: '30.07.2022',
    club: 'Olympique Marseille',
    previousClub: 'FC Arsenal',
    uid: '3dd1978e-d375-45eb-8742-558f1114f681',
    position: 'Linker Verteidiger',
    league: 'Ligue 1',
    marketValue: 18000000,
  },
  {
    jerseyNumber: 23,
    name: 'Sead Kolasinac',
    dateOfBirth: '20.06.1993',
    nationality: 'Bosnien-Herzegowina',
    height: 1.83,
    foot: 'links',
    teamMemberSince: '18.01.2022',
    club: 'Olympique Marseille',
    previousClub: 'FC Arsenal',
    uid: '57bdb5a6-a212-4331-abcf-ebbde221fba1',
    position: 'Linker Verteidiger',
    league: 'Ligue 1',
    marketValue: 5000000,
  },
  {
    jerseyNumber: 7,
    name: 'Jonathan Clauss',
    dateOfBirth: '25.09.1992',
    nationality: 'Frankreich',
    height: 1.78,
    foot: 'rechts',
    teamMemberSince: '20.07.2022',
    club: 'Olympique Marseille',
    previousClub: 'RC Lens',
    uid: 'f6dbee98-730c-4d91-b958-9b5fcfb64295',
    position: 'Rechter Verteidiger',
    league: 'Ligue 1',
    marketValue: 15000000,
  },
  {
    jerseyNumber: 29,
    name: 'Issa Kaboré',
    dateOfBirth: '12.05.2001',
    nationality: 'Burkina Faso',
    height: 1.8,
    foot: 'rechts',
    teamMemberSince: '17.08.2022',
    club: 'Olympique Marseille',
    previousClub: 'Manchester City',
    uid: '3b39d905-c127-48cb-9db2-feb988660642',
    position: 'Rechter Verteidiger',
    league: 'Ligue 1',
    marketValue: 5000000,
  },
  {
    jerseyNumber: 27,
    name: 'Jordan Veretout',
    dateOfBirth: '01.03.1993',
    nationality: 'Frankreich',
    height: 1.77,
    foot: 'rechts',
    teamMemberSince: '05.08.2022',
    club: 'Olympique Marseille',
    previousClub: 'AS Rom',
    uid: '458b29e0-f145-40be-9ff6-b1828db99569',
    position: 'Defensives Mittelfeld',
    league: 'Ligue 1',
    marketValue: 14000000,
  },
  {
    jerseyNumber: 6,
    name: 'Mattéo Guendouzi',
    dateOfBirth: '14.04.1999',
    nationality: 'Frankreich',
    height: 1.85,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'Olympique Marseille',
    previousClub: 'FC Arsenal',
    uid: '59d8ad56-add3-4f59-ace6-0b165e643966',
    position: 'Zentrales Mittelfeld',
    league: 'Ligue 1',
    marketValue: 25000000,
  },
  {
    jerseyNumber: 21,
    name: 'Valentin Rongier',
    dateOfBirth: '07.12.1994',
    nationality: 'Frankreich',
    height: 1.72,
    foot: 'rechts',
    teamMemberSince: '03.09.2019',
    club: 'Olympique Marseille',
    previousClub: 'FC Nantes',
    uid: '3d0b74fa-ede5-48d1-8dcd-4de4d4b3159c',
    position: 'Zentrales Mittelfeld',
    league: 'Ligue 1',
    marketValue: 18000000,
  },
  {
    jerseyNumber: 8,
    name: 'Azzedine Ounahi',
    dateOfBirth: '19.04.2000',
    nationality: 'Marokko',
    height: 1.82,
    foot: 'rechts',
    teamMemberSince: '29.01.2023',
    club: 'Olympique Marseille',
    previousClub: 'Angers SCO',
    uid: 'd6801f93-7e7c-456b-b72d-ea2d9b341f49',
    position: 'Zentrales Mittelfeld',
    league: 'Ligue 1',
    marketValue: 15000000,
  },
  {
    jerseyNumber: 18,
    name: 'Ruslan Malinovskyi',
    dateOfBirth: '04.05.1993',
    nationality: 'Ukraine',
    height: 1.81,
    foot: 'links',
    teamMemberSince: '09.01.2023',
    club: 'Olympique Marseille',
    previousClub: 'Atalanta Bergamo',
    uid: '9ca92feb-03fb-4556-b9a7-4c7b4a28ec2b',
    position: 'Offensives Mittelfeld',
    league: 'Ligue 1',
    marketValue: 18000000,
  },
  {
    jerseyNumber: 77,
    name: 'Amine Harit',
    dateOfBirth: '18.06.1997',
    nationality: 'Marokko',
    height: 1.8,
    foot: 'rechts',
    teamMemberSince: '01.09.2022',
    club: 'Olympique Marseille',
    previousClub: 'FC Schalke 04',
    uid: '7b17c911-4683-4ace-acd0-1f8ba861c158',
    position: 'Offensives Mittelfeld',
    league: 'Ligue 1',
    marketValue: 14000000,
  },
  {
    jerseyNumber: 10,
    name: 'Dimitri Payet',
    dateOfBirth: '29.03.1987',
    nationality: 'Frankreich',
    height: 1.75,
    foot: 'rechts',
    teamMemberSince: '30.01.2017',
    club: 'Olympique Marseille',
    previousClub: 'West Ham United',
    uid: '5bf6d7be-0d2d-4a6d-b980-9d320f2bf510',
    position: 'Offensives Mittelfeld',
    league: 'Ligue 1',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 17,
    name: 'Cengiz Ünder',
    dateOfBirth: '14.07.1997',
    nationality: 'Türkei',
    height: 1.73,
    foot: 'links',
    teamMemberSince: '01.07.2022',
    club: 'Olympique Marseille',
    previousClub: 'AS Rom',
    uid: '36c82174-ca0d-4392-b631-9eb158587d9a',
    position: 'Rechtsaußen',
    league: 'Ligue 1',
    marketValue: 17000000,
  },
  {
    jerseyNumber: 9,
    name: 'Vitinha',
    dateOfBirth: '15.03.2000',
    nationality: 'Portugal',
    height: 1.78,
    foot: 'rechts',
    teamMemberSince: '31.01.2023',
    club: 'Olympique Marseille',
    previousClub: 'SC Braga',
    uid: 'b64b870b-c691-4ffa-9395-45dfd92e6b7b',
    position: 'Mittelstürmer',
    league: 'Ligue 1',
    marketValue: 22000000,
  },
  {
    jerseyNumber: 70,
    name: 'Alexis Sánchez',
    dateOfBirth: '19.12.1988',
    nationality: 'Chile',
    height: 1.69,
    foot: 'rechts',
    teamMemberSince: '10.08.2022',
    club: 'Olympique Marseille',
    previousClub: 'Inter Mailand',
    uid: '3509615d-7fb0-48e8-9bf6-9817a48c2040',
    position: 'Mittelstürmer',
    league: 'Ligue 1',
    marketValue: 4000000,
  },
  {
    jerseyNumber: 1,
    name: 'Kasper Schmeichel',
    dateOfBirth: '05.11.1986',
    nationality: 'Dänemark',
    height: 1.89,
    foot: 'rechts',
    teamMemberSince: '03.08.2022',
    club: 'OGC Nizza',
    previousClub: 'Leicester City',
    uid: '5a257ea4-f03d-4b56-b9f4-2b44129c4730',
    position: 'Torwart',
    league: 'Ligue 1',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 90,
    name: 'Marcin Bulka',
    dateOfBirth: '04.10.1999',
    nationality: 'Polen',
    height: 1.99,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'OGC Nizza',
    previousClub: 'FC Paris Saint-Germain',
    uid: '713b667c-24b5-4f64-b346-9d5004ffd16f',
    position: 'Torwart',
    league: 'Ligue 1',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 25,
    name: 'Jean-Clair Todibo',
    dateOfBirth: '30.12.1999',
    nationality: 'Frankreich',
    height: 1.9,
    foot: 'rechts',
    teamMemberSince: '01.07.2021',
    club: 'OGC Nizza',
    previousClub: 'FC Barcelona',
    uid: '0e4c72d6-8177-4608-8da3-febd74e73a47',
    position: 'Innenverteidiger',
    league: 'Ligue 1',
    marketValue: 30000000,
  },
  {
    jerseyNumber: 55,
    name: 'Youssouf Ndayishimiye',
    dateOfBirth: '27.10.1998',
    nationality: 'Burundi',
    height: 1.83,
    foot: 'rechts',
    teamMemberSince: '25.01.2023',
    club: 'OGC Nizza',
    previousClub: 'Basaksehir FK',
    uid: '4087904b-c253-4c78-af92-17bd5f85d51b',
    position: 'Innenverteidiger',
    league: 'Ligue 1',
    marketValue: 12000000,
  },
  {
    jerseyNumber: 42,
    name: 'Mattia Viti',
    dateOfBirth: '24.01.2002',
    nationality: 'Italien',
    height: 1.9,
    foot: 'links',
    teamMemberSince: '03.08.2022',
    club: 'OGC Nizza',
    previousClub: 'FC Empoli',
    uid: '68638feb-1876-4e6c-b0cd-88fe59b5edde',
    position: 'Innenverteidiger',
    league: 'Ligue 1',
    marketValue: 10000000,
  },
  {
    jerseyNumber: 4,
    name: 'Dante',
    dateOfBirth: '18.10.1983',
    nationality: 'Brasilien',
    height: 1.88,
    foot: 'links',
    teamMemberSince: '23.08.2016',
    club: 'OGC Nizza',
    previousClub: 'VfL Wolfsburg',
    uid: '75129e47-afad-4a0b-88e2-df5ceb90064d',
    position: 'Innenverteidiger',
    league: 'Ligue 1',
    marketValue: 700000,
  },
  {
    jerseyNumber: 26,
    name: 'Melvin Bard',
    dateOfBirth: '06.11.2000',
    nationality: 'Frankreich',
    height: 1.73,
    foot: 'links',
    teamMemberSince: '16.07.2021',
    club: 'OGC Nizza',
    previousClub: ': Ablöse 3,00 Mio. €',
    uid: 'f19db46c-22a3-4641-b532-baf43b58e617',
    position: 'Linker Verteidiger',
    league: 'Ligue 1',
    marketValue: 8000000,
  },
  {
    jerseyNumber: 15,
    name: 'Joe Bryan',
    dateOfBirth: '17.09.1993',
    nationality: 'England',
    height: 1.7,
    foot: 'links',
    teamMemberSince: '31.08.2022',
    club: 'OGC Nizza',
    previousClub: 'FC Fulham',
    uid: '93191b39-6352-4042-8130-0341f3d648cd',
    position: 'Linker Verteidiger',
    league: 'Ligue 1',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 38,
    name: 'Ayoub Amraoui',
    dateOfBirth: '14.05.2004',
    nationality: 'Marokko',
    height: 1.86,
    foot: 'links',
    teamMemberSince: '01.01.2023',
    club: 'OGC Nizza',
    previousClub: 'OGC Nizza B',
    uid: '40ac7aae-3e8f-4f81-b71d-d49db1ba1cf4',
    position: 'Linker Verteidiger',
    league: 'Ligue 1',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 20,
    name: 'Youcef Atal',
    dateOfBirth: '17.05.1996',
    nationality: 'Algerien',
    height: 1.76,
    foot: 'beidfüßig',
    teamMemberSince: '17.07.2018',
    club: 'OGC Nizza',
    previousClub: 'KV Kortrijk',
    uid: '20a7af85-3468-4896-85b2-a1956714164f',
    position: 'Rechter Verteidiger',
    league: 'Ligue 1',
    marketValue: 10000000,
  },
  {
    jerseyNumber: 23,
    name: 'Jordan Lotomba',
    dateOfBirth: '29.09.1998',
    nationality: 'Schweiz',
    height: 1.77,
    foot: 'rechts',
    teamMemberSince: '03.08.2020',
    club: 'OGC Nizza',
    previousClub: 'BSC Young Boys',
    uid: '43b73a41-2ce5-42c0-bd02-0b68910625be',
    position: 'Rechter Verteidiger',
    league: 'Ligue 1',
    marketValue: 6000000,
  },
  {
    jerseyNumber: 33,
    name: 'Antoine Mendy',
    dateOfBirth: '27.05.2004',
    nationality: 'Frankreich',
    height: 1.87,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'OGC Nizza',
    previousClub: 'OGC Nizza B',
    uid: '718d6021-7a3b-4270-8e84-e198887aae49',
    position: 'Rechter Verteidiger',
    league: 'Ligue 1',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 19,
    name: 'Khéphren Thuram',
    dateOfBirth: '26.03.2001',
    nationality: 'Frankreich',
    height: 1.92,
    foot: 'rechts',
    teamMemberSince: '06.07.2019',
    club: 'OGC Nizza',
    previousClub: 'AS Monaco',
    uid: '9a56ede5-2ba7-4cea-911a-bb4eccd45425',
    position: 'Defensives Mittelfeld',
    league: 'Ligue 1',
    marketValue: 32000000,
  },
  {
    jerseyNumber: 8,
    name: 'Pablo Rosario',
    dateOfBirth: '07.01.1997',
    nationality: 'Niederlande',
    height: 1.85,
    foot: 'rechts',
    teamMemberSince: '27.07.2021',
    club: 'OGC Nizza',
    previousClub: 'PSV Eindhoven',
    uid: 'd59d1725-4273-4618-8021-badac5e1d736',
    position: 'Defensives Mittelfeld',
    league: 'Ligue 1',
    marketValue: 7000000,
  },
  {
    jerseyNumber: 21,
    name: 'Alexis Beka Beka',
    dateOfBirth: '29.03.2001',
    nationality: 'Frankreich',
    height: 1.78,
    foot: 'rechts',
    teamMemberSince: '01.08.2022',
    club: 'OGC Nizza',
    previousClub: 'Lokomotiv Moskau',
    uid: 'ebdbd0fa-934f-4984-b2d4-5c636afbc121',
    position: 'Defensives Mittelfeld',
    league: 'Ligue 1',
    marketValue: 6000000,
  },
  {
    jerseyNumber: 11,
    name: 'Ross Barkley',
    dateOfBirth: '05.12.1993',
    nationality: 'England',
    height: 1.89,
    foot: 'rechts',
    teamMemberSince: '04.09.2022',
    club: 'OGC Nizza',
    previousClub: 'Vereinslos',
    uid: '1cbf6b34-eb70-4b71-9a2e-df4a6be67aa3',
    position: 'Zentrales Mittelfeld',
    league: 'Ligue 1',
    marketValue: 9000000,
  },
  {
    jerseyNumber: 28,
    name: 'Hicham Boudaoui',
    dateOfBirth: '23.09.1999',
    nationality: 'Algerien',
    height: 1.75,
    foot: 'rechts',
    teamMemberSince: '02.09.2019',
    club: 'OGC Nizza',
    previousClub: 'Paradou AC',
    uid: '65eedaf0-6817-43a6-85aa-fced22fc3ac2',
    position: 'Zentrales Mittelfeld',
    league: 'Ligue 1',
    marketValue: 7000000,
  },
  {
    jerseyNumber: 16,
    name: 'Aaron Ramsey',
    dateOfBirth: '26.12.1990',
    nationality: 'Wales',
    height: 1.78,
    foot: 'rechts',
    teamMemberSince: '01.08.2022',
    club: 'OGC Nizza',
    previousClub: 'Juventus Turin',
    uid: '5cafab55-a77b-43e0-97de-6b4062ca6637',
    position: 'Zentrales Mittelfeld',
    league: 'Ligue 1',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 10,
    name: 'Sofiane Diop',
    dateOfBirth: '09.06.2000',
    nationality: 'Frankreich',
    height: 1.75,
    foot: 'rechts',
    teamMemberSince: '29.08.2022',
    club: 'OGC Nizza',
    previousClub: 'AS Monaco',
    uid: '9e917742-1995-4087-bfe9-464f079620ae',
    position: 'Linksaußen',
    league: 'Ligue 1',
    marketValue: 18000000,
  },
  {
    jerseyNumber: 14,
    name: 'Billal Brahimi',
    dateOfBirth: '14.03.2000',
    nationality: 'Algerien',
    height: 1.83,
    foot: 'links',
    teamMemberSince: '28.01.2022',
    club: 'OGC Nizza',
    previousClub: 'Angers SCO',
    uid: 'af67ae64-5b45-4d84-bf44-aa9f8410fccb',
    position: 'Linksaußen',
    league: 'Ligue 1',
    marketValue: 7000000,
  },
  {
    jerseyNumber: 29,
    name: 'Nicolas Pépé',
    dateOfBirth: '29.05.1995',
    nationality: 'Elfenbeinküste',
    height: 1.83,
    foot: 'links',
    teamMemberSince: '25.08.2022',
    club: 'OGC Nizza',
    previousClub: 'FC Arsenal',
    uid: 'c96fad0e-dfac-474d-a2bd-2d68cc0a19af',
    position: 'Rechtsaußen',
    league: 'Ligue 1',
    marketValue: 22000000,
  },
  {
    jerseyNumber: 35,
    name: 'Badredine Bouanani',
    dateOfBirth: '08.12.2004',
    nationality: 'Algerien',
    height: 1.77,
    foot: 'links',
    teamMemberSince: '07.07.2022',
    club: 'OGC Nizza',
    previousClub: 'LOSC Lille B',
    uid: 'edbcc20b-f45e-4461-9527-18fe5b5876c1',
    position: 'Rechtsaußen',
    league: 'Ligue 1',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 9,
    name: 'Terem Moffi',
    dateOfBirth: '25.05.1999',
    nationality: 'Nigeria',
    height: 1.88,
    foot: 'links',
    teamMemberSince: '31.01.2023',
    club: 'OGC Nizza',
    previousClub: 'FC Lorient',
    uid: '588fcce0-074d-431e-8539-13a292860317',
    position: 'Mittelstürmer',
    league: 'Ligue 1',
    marketValue: 20000000,
  },
  {
    jerseyNumber: 24,
    name: 'Gaëtan Laborde',
    dateOfBirth: '03.05.1994',
    nationality: 'Frankreich',
    height: 1.81,
    foot: 'links',
    teamMemberSince: '01.09.2022',
    club: 'OGC Nizza',
    previousClub: 'FC Stade Rennes',
    uid: 'a502bd9d-f0d5-4704-8c4c-e7a5d3b00cb6',
    position: 'Mittelstürmer',
    league: 'Ligue 1',
    marketValue: 14000000,
  },
  {
    jerseyNumber: 1,
    name: 'Anthony Lopes',
    dateOfBirth: '01.10.1990',
    nationality: 'Portugal',
    height: 1.84,
    foot: 'links',
    teamMemberSince: '01.07.2011',
    club: 'Olympique Lyon',
    previousClub: 'Olympique Lyon B',
    uid: '2a9276ba-2b3f-4776-96a9-f0ba4bbf0909',
    position: 'Torwart',
    league: 'Ligue 1',
    marketValue: 9000000,
  },
  {
    jerseyNumber: 35,
    name: 'Rémy Riou',
    dateOfBirth: '06.08.1987',
    nationality: 'Frankreich',
    height: 1.91,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'Olympique Lyon',
    previousClub: 'SM Caen',
    uid: '47cb1da7-ceb5-4d56-821b-ddeff755e77f',
    position: 'Torwart',
    league: 'Ligue 1',
    marketValue: 400000,
  },
  {
    jerseyNumber: 40,
    name: 'Kayne Bonnevie',
    dateOfBirth: '22.07.2001',
    nationality: 'Frankreich',
    height: 1.91,
    foot: 'links',
    teamMemberSince: '01.07.2022',
    club: 'Olympique Lyon',
    previousClub: 'Olympique Lyon B',
    uid: 'fec6fed1-9cfe-4b34-b78b-7c3ba72fcec0',
    position: 'Torwart',
    league: 'Ligue 1',
    marketValue: 200000,
  },
  {
    jerseyNumber: 4,
    name: 'Castello Lukeba',
    dateOfBirth: '17.12.2002',
    nationality: 'Frankreich',
    height: 1.84,
    foot: 'links',
    teamMemberSince: '01.07.2021',
    club: 'Olympique Lyon',
    previousClub: 'Olympique Lyon B',
    uid: '1bc14f83-b8ca-4ce5-9d99-0428523fe22f',
    position: 'Innenverteidiger',
    league: 'Ligue 1',
    marketValue: 20000000,
  },
  {
    jerseyNumber: 2,
    name: 'Sinaly Diomandé',
    dateOfBirth: '09.04.2001',
    nationality: 'Elfenbeinküste',
    height: 1.84,
    foot: 'rechts',
    teamMemberSince: '01.07.2020',
    club: 'Olympique Lyon',
    previousClub: 'Olympique Lyon B',
    uid: '1b37a0d6-786a-4d96-b879-6201bdfbd3b6',
    position: 'Innenverteidiger',
    league: 'Ligue 1',
    marketValue: 7000000,
  },
  {
    jerseyNumber: 5,
    name: 'Dejan Lovren',
    dateOfBirth: '05.07.1989',
    nationality: 'Kroatien',
    height: 1.88,
    foot: 'rechts',
    teamMemberSince: '02.01.2023',
    club: 'Olympique Lyon',
    previousClub: 'Zenit St. Petersburg',
    uid: 'fb26a99e-311f-452e-a0cb-c617e2cc0294',
    position: 'Innenverteidiger',
    league: 'Ligue 1',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 17,
    name: 'Jérôme Boateng',
    dateOfBirth: '03.09.1988',
    nationality: 'Deutschland',
    height: 1.92,
    foot: 'rechts',
    teamMemberSince: '01.09.2021',
    club: 'Olympique Lyon',
    previousClub: 'FC Bayern München',
    uid: '5c2baf10-9946-4713-827f-4ad765510207',
    position: 'Innenverteidiger',
    league: 'Ligue 1',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 3,
    name: 'Nicolás Tagliafico',
    dateOfBirth: '31.08.1992',
    nationality: 'Argentinien',
    height: 1.72,
    foot: 'links',
    teamMemberSince: '23.07.2022',
    club: 'Olympique Lyon',
    previousClub: 'Ajax Amsterdam',
    uid: 'd4d22728-0b14-4fa2-9fc8-197634b32a8a',
    position: 'Linker Verteidiger',
    league: 'Ligue 1',
    marketValue: 9000000,
  },
  {
    jerseyNumber: 12,
    name: 'Henrique Silva',
    dateOfBirth: '25.04.1994',
    nationality: 'Brasilien',
    height: 1.73,
    foot: 'links',
    teamMemberSince: '01.07.2021',
    club: 'Olympique Lyon',
    previousClub: 'Vasco da Gama Rio de Janeiro',
    uid: 'c50ad071-3ac5-4e5f-a44e-a8d266113cab',
    position: 'Linker Verteidiger',
    league: 'Ligue 1',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 27,
    name: 'Malo Gusto',
    dateOfBirth: '19.05.2003',
    nationality: 'Frankreich',
    height: 1.79,
    foot: 'rechts',
    teamMemberSince: '30.01.2023',
    club: 'Olympique Lyon',
    previousClub: 'FC Chelsea',
    uid: 'e28af6b7-150c-4184-9f34-d1df945616b2',
    position: 'Rechter Verteidiger',
    league: 'Ligue 1',
    marketValue: 25000000,
  },
  {
    jerseyNumber: 20,
    name: 'Saël Kumbedi',
    dateOfBirth: '26.03.2005',
    nationality: 'Frankreich',
    height: 1.75,
    foot: 'rechts',
    teamMemberSince: '31.08.2022',
    club: 'Olympique Lyon',
    previousClub: 'AC Le Havre',
    uid: '75e166b7-e2b7-4960-af45-717fb2ce8d43',
    position: 'Rechter Verteidiger',
    league: 'Ligue 1',
    marketValue: 8000000,
  },
  {
    jerseyNumber: 24,
    name: 'Johann Lepenant',
    dateOfBirth: '22.10.2002',
    nationality: 'Frankreich',
    height: 1.76,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'Olympique Lyon',
    previousClub: 'SM Caen',
    uid: '8c9efd03-fe0e-495d-91c3-bdae08a69678',
    position: 'Defensives Mittelfeld',
    league: 'Ligue 1',
    marketValue: 10000000,
  },
  {
    jerseyNumber: 23,
    name: 'Thiago Mendes',
    dateOfBirth: '15.03.1992',
    nationality: 'Brasilien',
    height: 1.77,
    foot: 'rechts',
    teamMemberSince: '03.07.2019',
    club: 'Olympique Lyon',
    previousClub: 'LOSC Lille',
    uid: 'a3c4f5df-7685-45fa-9469-6197df856320',
    position: 'Defensives Mittelfeld',
    league: 'Ligue 1',
    marketValue: 6000000,
  },
  {
    jerseyNumber: 6,
    name: 'Maxence Caqueret',
    dateOfBirth: '15.02.2000',
    nationality: 'Frankreich',
    height: 1.74,
    foot: 'rechts',
    teamMemberSince: '14.12.2018',
    club: 'Olympique Lyon',
    previousClub: 'Olympique Lyon B',
    uid: '76fc2405-74bd-4bed-a333-acc06fb6214d',
    position: 'Zentrales Mittelfeld',
    league: 'Ligue 1',
    marketValue: 25000000,
  },
  {
    jerseyNumber: 8,
    name: 'Houssem Aouar',
    dateOfBirth: '30.06.1998',
    nationality: 'Algerien',
    height: 1.75,
    foot: 'rechts',
    teamMemberSince: '01.07.2017',
    club: 'Olympique Lyon',
    previousClub: 'Olympique Lyon B',
    uid: '09da429c-43d7-4c27-9493-266db1f69289',
    position: 'Zentrales Mittelfeld',
    league: 'Ligue 1',
    marketValue: 15000000,
  },
  {
    jerseyNumber: 88,
    name: 'Corentin Tolisso',
    dateOfBirth: '03.08.1994',
    nationality: 'Frankreich',
    height: 1.81,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'Olympique Lyon',
    previousClub: 'FC Bayern München',
    uid: '69d540cf-55a4-4904-8e79-03d27b550be7',
    position: 'Zentrales Mittelfeld',
    league: 'Ligue 1',
    marketValue: 14000000,
  },
  {
    jerseyNumber: 38,
    name: 'Mohamed El Arouch',
    dateOfBirth: '06.04.2004',
    nationality: 'Frankreich',
    height: 1.66,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'Olympique Lyon',
    previousClub: 'Olympique Lyon B',
    uid: '029c7fe0-5242-4c81-9d6f-c1bd8542425a',
    position: 'Zentrales Mittelfeld',
    league: 'Ligue 1',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 18,
    name: 'Rayan Cherki',
    dateOfBirth: '17.08.2003',
    nationality: 'Frankreich',
    height: 1.77,
    foot: 'beidfüßig',
    teamMemberSince: '01.01.2020',
    club: 'Olympique Lyon',
    previousClub: 'Olympique Lyon B',
    uid: '43af6a4b-a929-4c2c-a573-7fa5fea76a59',
    position: 'Offensives Mittelfeld',
    league: 'Ligue 1',
    marketValue: 27000000,
  },
  {
    jerseyNumber: 47,
    name: 'Jeffinho',
    dateOfBirth: '30.12.1999',
    nationality: 'Brasilien',
    height: 1.76,
    foot: 'rechts',
    teamMemberSince: '31.01.2023',
    club: 'Olympique Lyon',
    previousClub: 'Botafogo Rio de Janeiro',
    uid: '25a3c151-0fef-44c1-a2ff-0c9c45be4184',
    position: 'Linksaußen',
    league: 'Ligue 1',
    marketValue: 6000000,
  },
  {
    jerseyNumber: 9,
    name: 'Moussa Dembélé',
    dateOfBirth: '12.07.1996',
    nationality: 'Frankreich',
    height: 1.87,
    foot: 'rechts',
    teamMemberSince: '31.08.2018',
    club: 'Olympique Lyon',
    previousClub: 'Celtic Glasgow',
    uid: '25b1e6b9-b433-423b-a35d-e2667d6914be',
    position: 'Mittelstürmer',
    league: 'Ligue 1',
    marketValue: 15000000,
  },
  {
    jerseyNumber: 10,
    name: 'Alexandre Lacazette',
    dateOfBirth: '28.05.1991',
    nationality: 'Frankreich',
    height: 1.75,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'Olympique Lyon',
    previousClub: 'FC Arsenal',
    uid: '2850e145-f03e-4337-af59-5740ccec05b8',
    position: 'Mittelstürmer',
    league: 'Ligue 1',
    marketValue: 12000000,
  },
  {
    jerseyNumber: 7,
    name: 'Amin Sarr',
    dateOfBirth: '11.03.2001',
    nationality: 'Schweden',
    height: 1.88,
    foot: 'rechts',
    teamMemberSince: '30.01.2023',
    club: 'Olympique Lyon',
    previousClub: 'SC Heerenveen',
    uid: 'c623650f-10d9-4f68-82fa-0df22a0c863f',
    position: 'Mittelstürmer',
    league: 'Ligue 1',
    marketValue: 9000000,
  },
  {
    jerseyNumber: 26,
    name: 'Bradley Barcola',
    dateOfBirth: '02.09.2002',
    nationality: 'Frankreich',
    height: 1.86,
    foot: 'rechts',
    teamMemberSince: '07.01.2022',
    club: 'Olympique Lyon',
    previousClub: 'Olympique Lyon B',
    uid: '54616dd8-51f5-40f4-9628-016d76a5e9d2',
    position: 'Mittelstürmer',
    league: 'Ligue 1',
    marketValue: 7000000,
  },
  {
    jerseyNumber: 30,
    name: 'Lucas Chevalier',
    dateOfBirth: '06.11.2001',
    nationality: 'Frankreich',
    height: 1.89,
    foot: 'rechts',
    teamMemberSince: '01.07.2020',
    club: 'LOSC Lille',
    previousClub: 'LOSC Lille B',
    uid: '7c370752-70b5-4530-8590-1218d2207b5c',
    position: 'Torwart',
    league: 'Ligue 1',
    marketValue: 10000000,
  },
  {
    jerseyNumber: 25,
    name: 'Benoît Costil',
    dateOfBirth: '03.07.1987',
    nationality: 'Frankreich',
    height: 1.88,
    foot: 'rechts',
    teamMemberSince: '26.01.2023',
    club: 'LOSC Lille',
    previousClub: 'AJ Auxerre',
    uid: '5403aaf9-0b8c-4ca3-afc6-f80b0f3ae494',
    position: 'Torwart',
    league: 'Ligue 1',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 16,
    name: 'Adam Jakubech',
    dateOfBirth: '02.01.1997',
    nationality: 'Slowakei',
    height: 1.88,
    foot: 'rechts',
    teamMemberSince: '26.07.2017',
    club: 'LOSC Lille',
    previousClub: 'Spartak Trnava',
    uid: '47284e1b-d63d-440d-a014-755b780c607c',
    position: 'Torwart',
    league: 'Ligue 1',
    marketValue: 400000,
  },
  {
    jerseyNumber: 3,
    name: 'Tiago Djaló',
    dateOfBirth: '09.04.2000',
    nationality: 'Portugal',
    height: 1.9,
    foot: 'rechts',
    teamMemberSince: '01.08.2019',
    club: 'LOSC Lille',
    previousClub: 'AC Mailand U19',
    uid: '04ee48e1-d207-4018-9d3a-3ebb9c99d76d',
    position: 'Innenverteidiger',
    league: 'Ligue 1',
    marketValue: 16000000,
  },
  {
    jerseyNumber: 15,
    name: 'Leny Yoro',
    dateOfBirth: '13.11.2005',
    nationality: 'Frankreich',
    height: 1.9,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'LOSC Lille',
    previousClub: 'LOSC Lille B',
    uid: '87f93061-c574-440c-b947-c0647f216350',
    position: 'Innenverteidiger',
    league: 'Ligue 1',
    marketValue: 8000000,
  },
  {
    jerseyNumber: 4,
    name: 'Alexsandro',
    dateOfBirth: '09.08.1999',
    nationality: 'Brasilien',
    height: 1.89,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'LOSC Lille',
    previousClub: 'GD Chaves',
    uid: '03a678e7-6ed4-46ee-ab57-c93032376bc1',
    position: 'Innenverteidiger',
    league: 'Ligue 1',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 6,
    name: 'José Fonte',
    dateOfBirth: '22.12.1983',
    nationality: 'Portugal',
    height: 1.87,
    foot: 'rechts',
    teamMemberSince: '15.07.2018',
    club: 'LOSC Lille',
    previousClub: 'Dalian Yifang',
    uid: 'd8aced27-7085-4118-9094-19954c0cd5cd',
    position: 'Innenverteidiger',
    league: 'Ligue 1',
    marketValue: 700000,
  },
  {
    jerseyNumber: 5,
    name: 'Gabriel Gudmundsson',
    dateOfBirth: '29.04.1999',
    nationality: 'Schweden',
    height: 1.8,
    foot: 'links',
    teamMemberSince: '31.08.2021',
    club: 'LOSC Lille',
    previousClub: ': Ablöse 6,00 Mio. €',
    uid: 'eaf8a756-a190-4b89-8f28-19f0e586f579',
    position: 'Linker Verteidiger',
    league: 'Ligue 1',
    marketValue: 5000000,
  },
  {
    jerseyNumber: 31,
    name: 'Ismaily',
    dateOfBirth: '11.01.1990',
    nationality: 'Brasilien',
    height: 1.77,
    foot: 'links',
    teamMemberSince: '04.08.2022',
    club: 'LOSC Lille',
    previousClub: 'Shakhtar Donetsk',
    uid: '1790ae1d-7039-4d02-aebf-bd86c5c0477b',
    position: 'Linker Verteidiger',
    league: 'Ligue 1',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 18,
    name: 'Bafodé Diakité',
    dateOfBirth: '06.01.2001',
    nationality: 'Frankreich',
    height: 1.85,
    foot: 'rechts',
    teamMemberSince: '05.08.2022',
    club: 'LOSC Lille',
    previousClub: 'FC Toulouse',
    uid: 'f95a5138-6f49-4a09-9592-6777023b7ef9',
    position: 'Rechter Verteidiger',
    league: 'Ligue 1',
    marketValue: 8000000,
  },
  {
    jerseyNumber: 21,
    name: 'Benjamin André',
    dateOfBirth: '03.08.1990',
    nationality: 'Frankreich',
    height: 1.8,
    foot: 'rechts',
    teamMemberSince: '17.07.2019',
    club: 'LOSC Lille',
    previousClub: 'FC Stade Rennes',
    uid: 'ea0fec44-7cc4-4dd5-bfb7-a7844d119c19',
    position: 'Defensives Mittelfeld',
    league: 'Ligue 1',
    marketValue: 10000000,
  },
  {
    jerseyNumber: 8,
    name: 'Jonas Martin',
    dateOfBirth: '09.04.1990',
    nationality: 'Frankreich',
    height: 1.84,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'LOSC Lille',
    previousClub: 'FC Stade Rennes',
    uid: '632128cd-6913-4a29-a996-4f91cb9610c7',
    position: 'Defensives Mittelfeld',
    league: 'Ligue 1',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 28,
    name: 'André Gomes',
    dateOfBirth: '30.07.1993',
    nationality: 'Portugal',
    height: 1.88,
    foot: 'rechts',
    teamMemberSince: '01.09.2022',
    club: 'LOSC Lille',
    previousClub: 'FC Everton',
    uid: '6303c001-9eab-4b52-8b2f-7158283adf9c',
    position: 'Zentrales Mittelfeld',
    league: 'Ligue 1',
    marketValue: 14000000,
  },
  {
    jerseyNumber: 35,
    name: 'Carlos Baleba',
    dateOfBirth: '03.01.2004',
    nationality: 'Kamerun',
    height: 1.79,
    foot: 'links',
    teamMemberSince: '01.07.2022',
    club: 'LOSC Lille',
    previousClub: 'LOSC Lille B',
    uid: '865a9ec6-80e2-4f2c-88d4-999ccc96576a',
    position: 'Zentrales Mittelfeld',
    league: 'Ligue 1',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 20,
    name: 'Angel Gomes',
    dateOfBirth: '31.08.2000',
    nationality: 'England',
    height: 1.68,
    foot: 'rechts',
    teamMemberSince: '09.08.2020',
    club: 'LOSC Lille',
    previousClub: 'Manchester United',
    uid: '2f934010-af14-43e8-8c75-7c9fe711c068',
    position: 'Offensives Mittelfeld',
    league: 'Ligue 1',
    marketValue: 13000000,
  },
  {
    jerseyNumber: 10,
    name: 'Rémy Cabella',
    dateOfBirth: '08.03.1990',
    nationality: 'Frankreich',
    height: 1.72,
    foot: 'rechts',
    teamMemberSince: '10.07.2022',
    club: 'LOSC Lille',
    previousClub: 'HSC Montpellier',
    uid: '50bf3f2a-9eb1-4ae6-923a-d4f8fd2b3b86',
    position: 'Offensives Mittelfeld',
    league: 'Ligue 1',
    marketValue: 5000000,
  },
  {
    jerseyNumber: 7,
    name: 'Jonathan Bamba',
    dateOfBirth: '26.03.1996',
    nationality: 'Elfenbeinküste',
    height: 1.75,
    foot: 'rechts',
    teamMemberSince: '02.07.2018',
    club: 'LOSC Lille',
    previousClub: ': Ablöse ablösefrei',
    uid: '8caf06d9-b7b4-4260-b418-74c4b37fc4ba',
    position: 'Linksaußen',
    league: 'Ligue 1',
    marketValue: 20000000,
  },
  {
    jerseyNumber: 26,
    name: 'Alan Virginius',
    dateOfBirth: '03.01.2003',
    nationality: 'Frankreich',
    height: 1.75,
    foot: 'rechts',
    teamMemberSince: '17.08.2022',
    club: 'LOSC Lille',
    previousClub: 'FC Sochaux-Montbéliard',
    uid: '5d917886-94c6-4ff3-bcee-7c3c65491e15',
    position: 'Linksaußen',
    league: 'Ligue 1',
    marketValue: 4000000,
  },
  {
    jerseyNumber: 22,
    name: 'Timothy Weah',
    dateOfBirth: '22.02.2000',
    nationality: 'Vereinigte Staaten',
    height: 1.83,
    foot: 'rechts',
    teamMemberSince: '01.07.2019',
    club: 'LOSC Lille',
    previousClub: 'FC Paris Saint-Germain',
    uid: 'd9f9d166-b439-4933-9dad-528a132e90ce',
    position: 'Rechtsaußen',
    league: 'Ligue 1',
    marketValue: 12000000,
  },
  {
    jerseyNumber: 11,
    name: 'Adam Ounas',
    dateOfBirth: '11.11.1996',
    nationality: 'Algerien',
    height: 1.72,
    foot: 'links',
    teamMemberSince: '01.09.2022',
    club: 'LOSC Lille',
    previousClub: 'SSC Neapel',
    uid: '6e9b4a95-7987-469c-adb6-4dcce49aa798',
    position: 'Rechtsaußen',
    league: 'Ligue 1',
    marketValue: 8000000,
  },
  {
    jerseyNumber: 23,
    name: 'Edon Zhegrova',
    dateOfBirth: '31.03.1999',
    nationality: 'Kosovo',
    height: 1.81,
    foot: 'links',
    teamMemberSince: '14.01.2022',
    club: 'LOSC Lille',
    previousClub: 'FC Basel 1893',
    uid: '1d2faaa0-31bc-46c2-a353-646c04c0a79b',
    position: 'Rechtsaußen',
    league: 'Ligue 1',
    marketValue: 7000000,
  },
  {
    jerseyNumber: 9,
    name: 'Jonathan David',
    dateOfBirth: '14.01.2000',
    nationality: 'Kanada',
    height: 1.75,
    foot: 'beidfüßig',
    teamMemberSince: '11.08.2020',
    club: 'LOSC Lille',
    previousClub: 'KAA Gent',
    uid: 'ba683bbb-44c0-4db1-a15f-d86a2d53e5e2',
    position: 'Mittelstürmer',
    league: 'Ligue 1',
    marketValue: 60000000,
  },
  {
    jerseyNumber: 27,
    name: 'Mohamed Bayo',
    dateOfBirth: '04.06.1998',
    nationality: 'Guinea',
    height: 1.88,
    foot: 'rechts',
    teamMemberSince: '13.07.2022',
    club: 'LOSC Lille',
    previousClub: 'Clermont Foot 63',
    uid: '814dc96a-2a90-4536-bd57-a5cf528f616a',
    position: 'Mittelstürmer',
    league: 'Ligue 1',
    marketValue: 8000000,
  },
  {
    jerseyNumber: 30,
    name: 'Brice Samba',
    dateOfBirth: '25.04.1994',
    nationality: 'Frankreich',
    height: 1.87,
    foot: 'rechts',
    teamMemberSince: '05.07.2022',
    club: 'RC Lens',
    previousClub: 'Nottingham Forest',
    uid: '51fc447e-cb6a-458c-9ef1-713a3a77eb3e',
    position: 'Torwart',
    league: 'Ligue 1',
    marketValue: 10000000,
  },
  {
    jerseyNumber: 1,
    name: 'Wuilker Fariñez',
    dateOfBirth: '15.02.1998',
    nationality: 'Venezuela',
    height: 1.8,
    foot: 'rechts',
    teamMemberSince: '01.07.2021',
    club: 'RC Lens',
    previousClub: 'Millonarios FC',
    uid: '924cc315-5f86-4074-a2cc-93af9ead597a',
    position: 'Torwart',
    league: 'Ligue 1',
    marketValue: 4000000,
  },
  {
    jerseyNumber: 16,
    name: 'Jean-Louis Leca',
    dateOfBirth: '21.09.1985',
    nationality: 'Frankreich',
    height: 1.8,
    foot: 'rechts',
    teamMemberSince: '01.07.2018',
    club: 'RC Lens',
    previousClub: 'AC Ajaccio',
    uid: '5296237e-0af3-4f10-b784-048a99cb7071',
    position: 'Torwart',
    league: 'Ligue 1',
    marketValue: 300000,
  },
  {
    jerseyNumber: 40,
    name: 'Yannick Pandor',
    dateOfBirth: '01.05.2001',
    nationality: 'Komoren',
    height: 1.92,
    foot: 'links',
    teamMemberSince: '01.07.2022',
    club: 'RC Lens',
    previousClub: 'RC Lens B',
    uid: '62bac26f-3eb4-47d5-8b58-b156ce0225ac',
    position: 'Torwart',
    league: 'Ligue 1',
    marketValue: 200000,
  },
  {
    jerseyNumber: 4,
    name: 'Kevin Danso',
    dateOfBirth: '19.09.1998',
    nationality: 'Österreich',
    height: 1.9,
    foot: 'rechts',
    teamMemberSince: '06.08.2021',
    club: 'RC Lens',
    previousClub: 'FC Augsburg',
    uid: '0f09da9b-017b-411f-ad51-710193143115',
    position: 'Innenverteidiger',
    league: 'Ligue 1',
    marketValue: 16000000,
  },
  {
    jerseyNumber: 14,
    name: 'Facundo Medina',
    dateOfBirth: '28.05.1999',
    nationality: 'Argentinien',
    height: 1.84,
    foot: 'links',
    teamMemberSince: '02.07.2020',
    club: 'RC Lens',
    previousClub: 'Club Atlético Talleres',
    uid: '44042732-2f6e-438a-8eb6-f76e304f49c7',
    position: 'Innenverteidiger',
    league: 'Ligue 1',
    marketValue: 16000000,
  },
  {
    jerseyNumber: 24,
    name: 'Jonathan Gradit',
    dateOfBirth: '24.11.1992',
    nationality: 'Frankreich',
    height: 1.8,
    foot: 'rechts',
    teamMemberSince: '20.08.2019',
    club: 'RC Lens',
    previousClub: 'SM Caen',
    uid: 'b447c986-855f-4e8c-983f-02b920401e6d',
    position: 'Innenverteidiger',
    league: 'Ligue 1',
    marketValue: 4000000,
  },
  {
    jerseyNumber: 15,
    name: 'Steven Fortes',
    dateOfBirth: '17.04.1992',
    nationality: 'Kap Verde',
    height: 1.92,
    foot: 'rechts',
    teamMemberSince: '01.07.2019',
    club: 'RC Lens',
    previousClub: 'FC Toulouse',
    uid: '372d73dc-3fe9-470a-ad66-be71bdfbf066',
    position: 'Innenverteidiger',
    league: 'Ligue 1',
    marketValue: 500000,
  },
  {
    jerseyNumber: 35,
    name: 'Adrien Louveau',
    dateOfBirth: '01.02.2000',
    nationality: 'Frankreich',
    height: 1.89,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'RC Lens',
    previousClub: 'RC Lens B',
    uid: '52332cec-5b1e-4bba-91d7-93c3c41fbb33',
    position: 'Innenverteidiger',
    league: 'Ligue 1',
    marketValue: 100000,
  },
  {
    jerseyNumber: 3,
    name: 'Deiver Machado',
    dateOfBirth: '02.09.1993',
    nationality: 'Kolumbien',
    height: 1.8,
    foot: 'links',
    teamMemberSince: '07.07.2021',
    club: 'RC Lens',
    previousClub: 'FC Toulouse',
    uid: '36ba92f5-ea49-4feb-9ccc-9a045d358616',
    position: 'Linker Verteidiger',
    league: 'Ligue 1',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 21,
    name: 'Massadio Haidara',
    dateOfBirth: '02.12.1992',
    nationality: 'Mali',
    height: 1.79,
    foot: 'links',
    teamMemberSince: '01.07.2018',
    club: 'RC Lens',
    previousClub: 'Newcastle United',
    uid: 'ae05b66e-9e24-442b-922d-bae520ae5807',
    position: 'Linker Verteidiger',
    league: 'Ligue 1',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 23,
    name: 'Ismaël Boura',
    dateOfBirth: '14.08.2000',
    nationality: 'Frankreich',
    height: 1.73,
    foot: 'links',
    teamMemberSince: '19.04.2020',
    club: 'RC Lens',
    previousClub: 'RC Lens B',
    uid: 'fc43b39d-3e22-459c-b8a7-7d2a6319b6b3',
    position: 'Linker Verteidiger',
    league: 'Ligue 1',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 25,
    name: 'Julien Le Cardinal',
    dateOfBirth: '03.08.1997',
    nationality: 'Frankreich',
    height: 1.84,
    foot: 'rechts',
    teamMemberSince: '15.11.2022',
    club: 'RC Lens',
    previousClub: 'Paris FC',
    uid: '39bb228d-9440-4817-b8e0-5e79f6ede7af',
    position: 'Rechter Verteidiger',
    league: 'Ligue 1',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 26,
    name: 'Salis Abdul Samed',
    dateOfBirth: '26.03.2000',
    nationality: 'Ghana',
    height: 1.79,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'RC Lens',
    previousClub: 'Clermont Foot 63',
    uid: 'b76ffb5f-815b-4812-a472-237c8ac0a9e3',
    position: 'Defensives Mittelfeld',
    league: 'Ligue 1',
    marketValue: 13000000,
  },
  {
    jerseyNumber: 6,
    name: 'Jean Onana',
    dateOfBirth: '08.01.2000',
    nationality: 'Kamerun',
    height: 1.89,
    foot: 'rechts',
    teamMemberSince: '01.09.2022',
    club: 'RC Lens',
    previousClub: 'FC Girondins Bordeaux',
    uid: '6e83a114-aeff-4723-a6bf-e81416cb3a8f',
    position: 'Defensives Mittelfeld',
    league: 'Ligue 1',
    marketValue: 4000000,
  },
  {
    jerseyNumber: 13,
    name: 'Lukasz Poreba',
    dateOfBirth: '13.03.2000',
    nationality: 'Polen',
    height: 1.79,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'RC Lens',
    previousClub: 'Zaglebie Lubin',
    uid: '575c2b67-e4fc-4c04-bd42-08465827a292',
    position: 'Defensives Mittelfeld',
    league: 'Ligue 1',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 8,
    name: 'Seko Fofana',
    dateOfBirth: '07.05.1995',
    nationality: 'Elfenbeinküste',
    height: 1.85,
    foot: 'rechts',
    teamMemberSince: '18.08.2020',
    club: 'RC Lens',
    previousClub: 'Udinese Calcio',
    uid: 'ebbcc9aa-384d-4182-804c-1d19dae5a0d8',
    position: 'Zentrales Mittelfeld',
    league: 'Ligue 1',
    marketValue: 30000000,
  },
  {
    jerseyNumber: 29,
    name: 'Przemyslaw Frankowski',
    dateOfBirth: '12.04.1995',
    nationality: 'Polen',
    height: 1.75,
    foot: 'rechts',
    teamMemberSince: '05.08.2021',
    club: 'RC Lens',
    previousClub: 'Chicago Fire FC',
    uid: '6e2abdbc-fc28-4658-b306-c24e6ac802d5',
    position: 'Rechtes Mittelfeld',
    league: 'Ligue 1',
    marketValue: 7000000,
  },
  {
    jerseyNumber: 19,
    name: 'Jimmy Cabot',
    dateOfBirth: '18.04.1994',
    nationality: 'Frankreich',
    height: 1.64,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'RC Lens',
    previousClub: 'Angers SCO',
    uid: 'eea10da6-4b5b-480f-bcf6-a54bc3b62a33',
    position: 'Rechtes Mittelfeld',
    league: 'Ligue 1',
    marketValue: 4000000,
  },
  {
    jerseyNumber: 10,
    name: 'David Costa',
    dateOfBirth: '05.01.2001',
    nationality: 'Portugal',
    height: 1.68,
    foot: 'rechts',
    teamMemberSince: '01.07.2020',
    club: 'RC Lens',
    previousClub: 'RC Lens B',
    uid: 'c2d9511c-615d-4696-8eb6-c0f1600830a9',
    position: 'Offensives Mittelfeld',
    league: 'Ligue 1',
    marketValue: 10000000,
  },
  {
    jerseyNumber: 20,
    name: 'Angelo Fulgini',
    dateOfBirth: '20.08.1996',
    nationality: 'Frankreich',
    height: 1.82,
    foot: 'rechts',
    teamMemberSince: '31.01.2023',
    club: 'RC Lens',
    previousClub: '1.FSV Mainz 05',
    uid: '336a6ad5-d56b-475c-b588-3ad71ad24991',
    position: 'Offensives Mittelfeld',
    league: 'Ligue 1',
    marketValue: 9000000,
  },
  {
    jerseyNumber: 18,
    name: 'Alexis Claude-Maurice',
    dateOfBirth: '06.06.1998',
    nationality: 'Frankreich',
    height: 1.75,
    foot: 'rechts',
    teamMemberSince: '31.08.2022',
    club: 'RC Lens',
    previousClub: 'OGC Nizza',
    uid: 'bc0624e2-e637-4a9e-8d86-c15a11a8e8d2',
    position: 'Offensives Mittelfeld',
    league: 'Ligue 1',
    marketValue: 5000000,
  },
  {
    jerseyNumber: 28,
    name: 'Adrien Thomasson',
    dateOfBirth: '10.12.1993',
    nationality: 'Frankreich',
    height: 1.75,
    foot: 'rechts',
    teamMemberSince: '12.01.2023',
    club: 'RC Lens',
    previousClub: 'RC Straßburg Alsace',
    uid: 'f34ac6c8-8daf-4878-9106-d01457f78438',
    position: 'Offensives Mittelfeld',
    league: 'Ligue 1',
    marketValue: 4000000,
  },
  {
    jerseyNumber: 11,
    name: 'Loïs Openda',
    dateOfBirth: '16.02.2000',
    nationality: 'Belgien',
    height: 1.77,
    foot: 'rechts',
    teamMemberSince: '06.07.2022',
    club: 'RC Lens',
    previousClub: 'FC Brügge',
    uid: '2d033255-f78e-4ba9-a26d-3f1a44612231',
    position: 'Mittelstürmer',
    league: 'Ligue 1',
    marketValue: 20000000,
  },
  {
    jerseyNumber: 9,
    name: 'Adam Buksa',
    dateOfBirth: '12.07.1996',
    nationality: 'Polen',
    height: 1.93,
    foot: 'links',
    teamMemberSince: '01.07.2022',
    club: 'RC Lens',
    previousClub: 'New England Revolution',
    uid: 'ca11f72f-5689-4ff2-b0c9-dbdf7bc989c0',
    position: 'Mittelstürmer',
    league: 'Ligue 1',
    marketValue: 7000000,
  },
  {
    jerseyNumber: 7,
    name: 'Florian Sotoca',
    dateOfBirth: '25.10.1990',
    nationality: 'Frankreich',
    height: 1.87,
    foot: 'rechts',
    teamMemberSince: '01.07.2019',
    club: 'RC Lens',
    previousClub: 'Grenoble Foot 38',
    uid: 'e9c1525d-f6db-4891-8fb5-b8973c6a086c',
    position: 'Mittelstürmer',
    league: 'Ligue 1',
    marketValue: 4000000,
  },
  {
    jerseyNumber: 22,
    name: 'Wesley Saïd',
    dateOfBirth: '19.04.1995',
    nationality: 'Frankreich',
    height: 1.71,
    foot: 'rechts',
    teamMemberSince: '01.07.2021',
    club: 'RC Lens',
    previousClub: 'FC Toulouse',
    uid: '3435b193-89d4-458d-81f9-3a22ea204602',
    position: 'Mittelstürmer',
    league: 'Ligue 1',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 36,
    name: 'Rémy Labeau Lascary',
    dateOfBirth: '03.03.2003',
    nationality: 'Guadeloupe',
    height: 1.84,
    foot: 'rechts',
    teamMemberSince: '01.01.2023',
    club: 'RC Lens',
    previousClub: 'RC Lens B',
    uid: 'fec03269-9478-4bea-82a3-c7176ab8f187',
    position: 'Mittelstürmer',
    league: 'Ligue 1',
    marketValue: 400000,
  },
  {
    jerseyNumber: 1,
    name: 'Alban Lafont',
    dateOfBirth: '23.01.1999',
    nationality: 'Frankreich',
    height: 1.96,
    foot: 'rechts',
    teamMemberSince: '01.07.2021',
    club: 'FC Nantes',
    previousClub: ': Ablöse 7,50 Mio. €',
    uid: 'ad9e60c5-10a0-4f84-8574-a172881d1822',
    position: 'Torwart',
    league: 'Ligue 1',
    marketValue: 20000000,
  },
  {
    jerseyNumber: 16,
    name: 'Rémy Descamps',
    dateOfBirth: '25.06.1996',
    nationality: 'Frankreich',
    height: 1.96,
    foot: 'links',
    teamMemberSince: '01.07.2021',
    club: 'FC Nantes',
    previousClub: 'RSC Charleroi',
    uid: 'b8ed20e8-9654-4c49-8b00-ed94e0a94d98',
    position: 'Torwart',
    league: 'Ligue 1',
    marketValue: 800000,
  },
  {
    jerseyNumber: 30,
    name: 'Denis Petric',
    dateOfBirth: '24.05.1988',
    nationality: 'Slowenien',
    height: 1.87,
    foot: 'rechts',
    teamMemberSince: '01.08.2019',
    club: 'FC Nantes',
    previousClub: 'EA Guingamp',
    uid: '99ba26ff-8906-438e-90ea-3fa25f3f84eb',
    position: 'Torwart',
    league: 'Ligue 1',
    marketValue: 200000,
  },
  {
    jerseyNumber: 38,
    name: 'João Victor',
    dateOfBirth: '17.07.1998',
    nationality: 'Brasilien',
    height: 1.87,
    foot: 'rechts',
    teamMemberSince: '25.01.2023',
    club: 'FC Nantes',
    previousClub: 'Benfica Lissabon',
    uid: 'b127864b-3098-4ba2-a859-1fec638b749a',
    position: 'Innenverteidiger',
    league: 'Ligue 1',
    marketValue: 8000000,
  },
  {
    jerseyNumber: 3,
    name: 'Andrei Girotto',
    dateOfBirth: '17.02.1992',
    nationality: 'Brasilien',
    height: 1.86,
    foot: 'rechts',
    teamMemberSince: '12.08.2017',
    club: 'FC Nantes',
    previousClub: 'Tombense FC (MG)',
    uid: '47c43b97-c467-4027-8b57-7f7fed6d2cd2',
    position: 'Innenverteidiger',
    league: 'Ligue 1',
    marketValue: 4000000,
  },
  {
    jerseyNumber: 21,
    name: 'Jean-Charles Castelletto',
    dateOfBirth: '26.01.1995',
    nationality: 'Kamerun',
    height: 1.86,
    foot: 'beidfüßig',
    teamMemberSince: '01.07.2020',
    club: 'FC Nantes',
    previousClub: 'Stade Brest 29',
    uid: '34687904-d071-47c5-aa83-e98cbe62fc53',
    position: 'Innenverteidiger',
    league: 'Ligue 1',
    marketValue: 4000000,
  },
  {
    jerseyNumber: 4,
    name: 'Nicolas Pallois',
    dateOfBirth: '19.09.1987',
    nationality: 'Frankreich',
    height: 1.89,
    foot: 'links',
    teamMemberSince: '27.07.2017',
    club: 'FC Nantes',
    previousClub: ': Ablöse 2,00 Mio. €',
    uid: '4b0ebceb-692f-4eba-8bdc-74f203e5aae5',
    position: 'Innenverteidiger',
    league: 'Ligue 1',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 23,
    name: 'Robin Voisine',
    dateOfBirth: '07.04.2002',
    nationality: 'Frankreich',
    height: 1.88,
    foot: 'links',
    teamMemberSince: '01.07.2022',
    club: 'FC Nantes',
    previousClub: 'FC Nantes B',
    uid: '62185f2f-5c6e-4e75-8640-055dacde19d8',
    position: 'Innenverteidiger',
    league: 'Ligue 1',
    marketValue: 150000,
  },
  {
    jerseyNumber: 29,
    name: 'Quentin Merlin',
    dateOfBirth: '16.05.2002',
    nationality: 'Frankreich',
    height: 1.73,
    foot: 'links',
    teamMemberSince: '01.07.2021',
    club: 'FC Nantes',
    previousClub: 'FC Nantes B',
    uid: '976ea394-4bb3-4e2e-9376-0a608f63567d',
    position: 'Linker Verteidiger',
    league: 'Ligue 1',
    marketValue: 7000000,
  },
  {
    jerseyNumber: 26,
    name: 'Jaouen Hadjam',
    dateOfBirth: '26.03.2003',
    nationality: 'Algerien',
    height: 1.84,
    foot: 'links',
    teamMemberSince: '17.01.2023',
    club: 'FC Nantes',
    previousClub: 'Paris FC',
    uid: '5db06b5e-d440-4d28-88f2-a8a44f337135',
    position: 'Linker Verteidiger',
    league: 'Ligue 1',
    marketValue: 800000,
  },
  {
    jerseyNumber: 93,
    name: 'Charles Traoré',
    dateOfBirth: '01.01.1992',
    nationality: 'Mali',
    height: 1.8,
    foot: 'links',
    teamMemberSince: '31.08.2018',
    club: 'FC Nantes',
    previousClub: 'ESTAC Troyes',
    uid: '49560b98-c7ea-4280-accd-5695e77167c7',
    position: 'Linker Verteidiger',
    league: 'Ligue 1',
    marketValue: 800000,
  },
  {
    jerseyNumber: 28,
    name: 'Fabien Centonze',
    dateOfBirth: '16.01.1996',
    nationality: 'Frankreich',
    height: 1.82,
    foot: 'rechts',
    teamMemberSince: '22.09.2022',
    club: 'FC Nantes',
    previousClub: 'FC Metz',
    uid: '305a61ac-6b04-4b9f-a6b4-49813776ac5a',
    position: 'Rechter Verteidiger',
    league: 'Ligue 1',
    marketValue: 5000000,
  },
  {
    jerseyNumber: 24,
    name: 'Sébastien Corchia',
    dateOfBirth: '01.11.1990',
    nationality: 'Frankreich',
    height: 1.76,
    foot: 'rechts',
    teamMemberSince: '07.10.2020',
    club: 'FC Nantes',
    previousClub: 'Vereinslos',
    uid: '2cd39e42-fc9c-47e5-a3e8-aa84bee88510',
    position: 'Rechter Verteidiger',
    league: 'Ligue 1',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 5,
    name: 'Pedro Chirivella',
    dateOfBirth: '23.05.1997',
    nationality: 'Spanien',
    height: 1.78,
    foot: 'rechts',
    teamMemberSince: '01.07.2020',
    club: 'FC Nantes',
    previousClub: 'FC Liverpool U23',
    uid: 'b3b5a7dc-f849-473b-90e1-18fb74392fa4',
    position: 'Defensives Mittelfeld',
    league: 'Ligue 1',
    marketValue: 6000000,
  },
  {
    jerseyNumber: 20,
    name: 'Lohann Doucet',
    dateOfBirth: '14.09.2002',
    nationality: 'Frankreich',
    height: 1.8,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'FC Nantes',
    previousClub: 'FC Nantes B',
    uid: '1738f727-d8bc-4c77-9642-bda218c91781',
    position: 'Defensives Mittelfeld',
    league: 'Ligue 1',
    marketValue: 800000,
  },
  {
    jerseyNumber: 8,
    name: 'Samuel Moutoussamy',
    dateOfBirth: '12.08.1996',
    nationality: 'DR Kongo',
    height: 1.76,
    foot: 'links',
    teamMemberSince: '06.09.2017',
    club: 'FC Nantes',
    previousClub: ': Ablöse -',
    uid: 'dc59fd4d-a618-41f4-9e70-15c73d857d44',
    position: 'Zentrales Mittelfeld',
    league: 'Ligue 1',
    marketValue: 4000000,
  },
  {
    jerseyNumber: 17,
    name: 'Moussa Sissoko',
    dateOfBirth: '16.08.1989',
    nationality: 'Frankreich',
    height: 1.87,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'FC Nantes',
    previousClub: 'FC Watford',
    uid: '7b06cfba-5866-4c81-a309-91833ebd0bfd',
    position: 'Zentrales Mittelfeld',
    league: 'Ligue 1',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 10,
    name: 'Ludovic Blas',
    dateOfBirth: '31.12.1997',
    nationality: 'Frankreich',
    height: 1.8,
    foot: 'links',
    teamMemberSince: '02.09.2019',
    club: 'FC Nantes',
    previousClub: 'EA Guingamp',
    uid: '8a8346c8-fab6-4caa-bfe5-5d9934dab201',
    position: 'Offensives Mittelfeld',
    league: 'Ligue 1',
    marketValue: 18000000,
  },
  {
    jerseyNumber: 25,
    name: 'Florent Mollet',
    dateOfBirth: '19.11.1991',
    nationality: 'Frankreich',
    height: 1.75,
    foot: 'rechts',
    teamMemberSince: '17.01.2023',
    club: 'FC Nantes',
    previousClub: 'FC Schalke 04',
    uid: '2ade2ef4-8676-4c51-8849-0e936890156f',
    position: 'Offensives Mittelfeld',
    league: 'Ligue 1',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 22,
    name: 'Gor Manvelyan',
    dateOfBirth: '09.04.2002',
    nationality: 'Frankreich',
    height: 1.8,
    foot: 'beidfüßig',
    teamMemberSince: '01.07.2021',
    club: 'FC Nantes',
    previousClub: 'FC Nantes B',
    uid: 'eb5d84bb-cc36-4d62-921c-2a03d4c6d5b2',
    position: 'Offensives Mittelfeld',
    league: 'Ligue 1',
    marketValue: 400000,
  },
  {
    jerseyNumber: 18,
    name: 'Samuel Yépié Yépié',
    dateOfBirth: '02.10.2002',
    nationality: 'Frankreich',
    height: 1.71,
    foot: 'links',
    teamMemberSince: '01.07.2022',
    club: 'FC Nantes',
    previousClub: 'FC Nantes B',
    uid: 'd3968453-5e0b-4753-bbf5-8b988d43d7d7',
    position: 'Offensives Mittelfeld',
    league: 'Ligue 1',
    marketValue: 150000,
  },
  {
    jerseyNumber: 27,
    name: 'Moses Simon',
    dateOfBirth: '12.07.1995',
    nationality: 'Nigeria',
    height: 1.68,
    foot: 'rechts',
    teamMemberSince: '01.07.2020',
    club: 'FC Nantes',
    previousClub: 'UD Levante',
    uid: 'dba0b6bf-c8b1-49f4-9802-5a1709993f1c',
    position: 'Linksaußen',
    league: 'Ligue 1',
    marketValue: 9000000,
  },
  {
    jerseyNumber: 11,
    name: 'Marcus Coco',
    dateOfBirth: '24.06.1996',
    nationality: 'Frankreich',
    height: 1.84,
    foot: 'rechts',
    teamMemberSince: '16.07.2019',
    club: 'FC Nantes',
    previousClub: 'EA Guingamp',
    uid: '8e604bc7-894f-48d3-9e82-992a6f8a459f',
    position: 'Rechtsaußen',
    league: 'Ligue 1',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 99,
    name: 'Andy Delort',
    dateOfBirth: '09.10.1991',
    nationality: 'Algerien',
    height: 1.81,
    foot: 'rechts',
    teamMemberSince: '30.01.2023',
    club: 'FC Nantes',
    previousClub: 'OGC Nizza',
    uid: '99d836f1-1f0f-4150-b9d7-a6764805ae72',
    position: 'Mittelstürmer',
    league: 'Ligue 1',
    marketValue: 10000000,
  },
  {
    jerseyNumber: 31,
    name: 'Mostafa Mohamed',
    dateOfBirth: '28.11.1997',
    nationality: 'Ägypten',
    height: 1.85,
    foot: 'rechts',
    teamMemberSince: '21.07.2022',
    club: 'FC Nantes',
    previousClub: 'Galatasaray',
    uid: '86d27a9f-810c-407b-8ec9-02ea9b8fb05f',
    position: 'Mittelstürmer',
    league: 'Ligue 1',
    marketValue: 6000000,
  },
  {
    jerseyNumber: 7,
    name: 'Evann Guessand',
    dateOfBirth: '01.07.2001',
    nationality: 'Frankreich',
    height: 1.87,
    foot: 'rechts',
    teamMemberSince: '12.07.2022',
    club: 'FC Nantes',
    previousClub: 'OGC Nizza',
    uid: 'fadf5ce9-8def-44f9-8526-5391bfea91a8',
    position: 'Mittelstürmer',
    league: 'Ligue 1',
    marketValue: 5000000,
  },
  {
    jerseyNumber: 14,
    name: 'Ignatius Ganago',
    dateOfBirth: '16.02.1999',
    nationality: 'Kamerun',
    height: 1.76,
    foot: 'rechts',
    teamMemberSince: '01.09.2022',
    club: 'FC Nantes',
    previousClub: 'RC Lens',
    uid: 'cdd7f534-c6d6-418e-81f5-61395daf5d45',
    position: 'Mittelstürmer',
    league: 'Ligue 1',
    marketValue: 4000000,
  },
  {
    jerseyNumber: 40,
    name: 'Benjamin Lecomte',
    dateOfBirth: '26.04.1991',
    nationality: 'Frankreich',
    height: 1.86,
    foot: 'links',
    teamMemberSince: '26.01.2023',
    club: 'HSC Montpellier',
    previousClub: 'AS Monaco',
    uid: '83ffec6e-bb74-4822-9939-bf7102d7a4ef',
    position: 'Torwart',
    league: 'Ligue 1',
    marketValue: 4000000,
  },
  {
    jerseyNumber: 16,
    name: 'Dimitry Bertaud',
    dateOfBirth: '06.06.1998',
    nationality: 'Frankreich',
    height: 1.8,
    foot: 'rechts',
    teamMemberSince: '01.07.2017',
    club: 'HSC Montpellier',
    previousClub: 'HSC Montpellier B',
    uid: '0ba67639-5625-465a-af1f-13d6869a96a1',
    position: 'Torwart',
    league: 'Ligue 1',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 90,
    name: 'Bingourou Kamara',
    dateOfBirth: '21.10.1996',
    nationality: 'Senegal',
    height: 1.95,
    foot: 'rechts',
    teamMemberSince: '01.09.2022',
    club: 'HSC Montpellier',
    previousClub: 'RC Straßburg Alsace',
    uid: 'a85a5fef-2c94-414e-9c16-cfd0c6a11edc',
    position: 'Torwart',
    league: 'Ligue 1',
    marketValue: 800000,
  },
  {
    jerseyNumber: 30,
    name: 'Matis Carvalho',
    dateOfBirth: '28.04.1999',
    nationality: 'Portugal',
    height: 1.86,
    foot: 'links',
    teamMemberSince: '01.07.2019',
    club: 'HSC Montpellier',
    previousClub: 'FC Toulouse B',
    uid: 'ef405ece-28af-4309-97a6-cec152a2eead',
    position: 'Torwart',
    league: 'Ligue 1',
    marketValue: 300000,
  },
  {
    jerseyNumber: 14,
    name: 'Maxime Estève',
    dateOfBirth: '26.05.2002',
    nationality: 'Frankreich',
    height: 1.93,
    foot: 'links',
    teamMemberSince: '01.07.2021',
    club: 'HSC Montpellier',
    previousClub: 'HSC Montpellier B',
    uid: 'b7220a2a-9065-408c-b23e-a5bd06eece8c',
    position: 'Innenverteidiger',
    league: 'Ligue 1',
    marketValue: 5000000,
  },
  {
    jerseyNumber: 4,
    name: 'Kiki Kouyaté',
    dateOfBirth: '15.04.1997',
    nationality: 'Mali',
    height: 1.92,
    foot: 'beidfüßig',
    teamMemberSince: '20.01.2023',
    club: 'HSC Montpellier',
    previousClub: 'FC Metz',
    uid: '4b674d69-05f3-4bcd-9598-c37d4da860c1',
    position: 'Innenverteidiger',
    league: 'Ligue 1',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 6,
    name: 'Christopher Jullien',
    dateOfBirth: '22.03.1993',
    nationality: 'Frankreich',
    height: 1.96,
    foot: 'rechts',
    teamMemberSince: '23.08.2022',
    club: 'HSC Montpellier',
    previousClub: 'Celtic Glasgow',
    uid: '159519c9-5220-4794-868f-81dee2a24ae1',
    position: 'Innenverteidiger',
    league: 'Ligue 1',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 75,
    name: 'Mamadou Sakho',
    dateOfBirth: '13.02.1990',
    nationality: 'Frankreich',
    height: 1.87,
    foot: 'links',
    teamMemberSince: '27.07.2021',
    club: 'HSC Montpellier',
    previousClub: 'Crystal Palace',
    uid: '511da7c4-4abd-42de-8907-6b96c0214245',
    position: 'Innenverteidiger',
    league: 'Ligue 1',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 5,
    name: 'Pedro Mendes',
    dateOfBirth: '01.10.1990',
    nationality: 'Portugal',
    height: 1.86,
    foot: 'rechts',
    teamMemberSince: '18.07.2017',
    club: 'HSC Montpellier',
    previousClub: 'FC Stade Rennes',
    uid: '4a1e4719-7e02-4d20-a866-a696f0970619',
    position: 'Innenverteidiger',
    league: 'Ligue 1',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 29,
    name: 'Enzo Tchato',
    dateOfBirth: '23.11.2002',
    nationality: 'Kamerun',
    height: 1.82,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'HSC Montpellier',
    previousClub: 'HSC Montpellier B',
    uid: '38349b92-36cf-4d74-a438-06d8e4e177e5',
    position: 'Innenverteidiger',
    league: 'Ligue 1',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 27,
    name: 'Faitout Maouassa',
    dateOfBirth: '06.07.1998',
    nationality: 'Frankreich',
    height: 1.7,
    foot: 'links',
    teamMemberSince: '01.07.2022',
    club: 'HSC Montpellier',
    previousClub: 'FC Brügge',
    uid: '58e4aa0e-141e-40dc-ae3b-74a8a0aecfa3',
    position: 'Linker Verteidiger',
    league: 'Ligue 1',
    marketValue: 5000000,
  },
  {
    jerseyNumber: 3,
    name: 'Issiaga Sylla',
    dateOfBirth: '01.01.1994',
    nationality: 'Guinea',
    height: 1.8,
    foot: 'links',
    teamMemberSince: '31.01.2023',
    club: 'HSC Montpellier',
    previousClub: 'FC Toulouse',
    uid: 'bf701eb9-df1d-4261-810e-f90c4a7bff9d',
    position: 'Linker Verteidiger',
    league: 'Ligue 1',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 17,
    name: 'Théo Sainte-Luce',
    dateOfBirth: '20.10.1998',
    nationality: 'Frankreich',
    height: 1.76,
    foot: 'links',
    teamMemberSince: '01.07.2022',
    club: 'HSC Montpellier',
    previousClub: 'Nîmes Olympique',
    uid: '72d26395-8856-41d6-a91a-6920ff5e4fd9',
    position: 'Linker Verteidiger',
    league: 'Ligue 1',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 26,
    name: 'Thibault Tamas',
    dateOfBirth: '20.02.2001',
    nationality: 'Frankreich',
    height: 1.78,
    foot: 'links',
    teamMemberSince: '27.09.2019',
    club: 'HSC Montpellier',
    previousClub: 'HSC Montpellier B',
    uid: '5653efa8-40fa-4d9d-9f90-abd04eceac23',
    position: 'Linker Verteidiger',
    league: 'Ligue 1',
    marketValue: 200000,
  },
  {
    jerseyNumber: 77,
    name: 'Falaye Sacko',
    dateOfBirth: '01.05.1995',
    nationality: 'Mali',
    height: 1.79,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'HSC Montpellier',
    previousClub: 'Vitória Guimarães SC',
    uid: '30ce56d7-ed18-4f96-8a3b-45b6ae69963c',
    position: 'Rechter Verteidiger',
    league: 'Ligue 1',
    marketValue: 4000000,
  },
  {
    jerseyNumber: 15,
    name: 'Mathías Suárez',
    dateOfBirth: '24.06.1996',
    nationality: 'Uruguay',
    height: 1.82,
    foot: 'rechts',
    teamMemberSince: '02.01.2019',
    club: 'HSC Montpellier',
    previousClub: 'Defensor SC',
    uid: 'ee777a2d-3133-461b-9da7-1fcb43377ce4',
    position: 'Rechter Verteidiger',
    league: 'Ligue 1',
    marketValue: 400000,
  },
  {
    jerseyNumber: 13,
    name: 'Joris Chotard',
    dateOfBirth: '24.09.2001',
    nationality: 'Frankreich',
    height: 1.79,
    foot: 'rechts',
    teamMemberSince: '25.09.2019',
    club: 'HSC Montpellier',
    previousClub: 'HSC Montpellier B',
    uid: '8ebc6fba-abe2-49fb-8ffe-39b6f11aa8d3',
    position: 'Defensives Mittelfeld',
    league: 'Ligue 1',
    marketValue: 9000000,
  },
  {
    jerseyNumber: 12,
    name: 'Jordan Ferri',
    dateOfBirth: '12.03.1992',
    nationality: 'Frankreich',
    height: 1.73,
    foot: 'rechts',
    teamMemberSince: '01.07.2019',
    club: 'HSC Montpellier',
    previousClub: ': Ablöse 2,00 Mio. €',
    uid: '11d5b56b-3470-4270-afeb-96edbf5fb8a9',
    position: 'Zentrales Mittelfeld',
    league: 'Ligue 1',
    marketValue: 5000000,
  },
  {
    jerseyNumber: 22,
    name: 'Khalil Fayad',
    dateOfBirth: '09.06.2004',
    nationality: 'Frankreich',
    height: 1.76,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'HSC Montpellier',
    previousClub: 'HSC Montpellier B',
    uid: '6a7dd9b2-135c-4c3d-ab4d-938c5319f9ad',
    position: 'Zentrales Mittelfeld',
    league: 'Ligue 1',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 11,
    name: 'Téji Savanier',
    dateOfBirth: '22.12.1991',
    nationality: 'Frankreich',
    height: 1.72,
    foot: 'rechts',
    teamMemberSince: '02.07.2019',
    club: 'HSC Montpellier',
    previousClub: 'Nîmes Olympique',
    uid: '2def285a-c68f-41e6-aa02-ce46af9dab3d',
    position: 'Offensives Mittelfeld',
    league: 'Ligue 1',
    marketValue: 12000000,
  },
  {
    jerseyNumber: 10,
    name: 'Stephy Mavididi',
    dateOfBirth: '31.05.1998',
    nationality: 'England',
    height: 1.82,
    foot: 'rechts',
    teamMemberSince: '01.07.2020',
    club: 'HSC Montpellier',
    previousClub: 'Juventus Turin U23',
    uid: 'a22e0cce-edfd-481f-bbae-a8622614388a',
    position: 'Linksaußen',
    league: 'Ligue 1',
    marketValue: 8000000,
  },
  {
    jerseyNumber: 28,
    name: 'Béni Makouana',
    dateOfBirth: '28.09.2002',
    nationality: 'Kongo',
    height: 1.81,
    foot: 'rechts',
    teamMemberSince: '19.10.2020',
    club: 'HSC Montpellier',
    previousClub: 'Diables Noirs Brazzaville',
    uid: '6789b07f-f39c-42ae-9fb3-15ab4a2c3d02',
    position: 'Linksaußen',
    league: 'Ligue 1',
    marketValue: 800000,
  },
  {
    jerseyNumber: 7,
    name: 'Arnaud Nordin',
    dateOfBirth: '17.06.1998',
    nationality: 'Frankreich',
    height: 1.7,
    foot: 'links',
    teamMemberSince: '01.07.2022',
    club: 'HSC Montpellier',
    previousClub: 'AS Saint-Étienne',
    uid: 'e383a4cd-aeee-4659-b623-23efcc77187b',
    position: 'Rechtsaußen',
    league: 'Ligue 1',
    marketValue: 5000000,
  },
  {
    jerseyNumber: 21,
    name: 'Elye Wahi',
    dateOfBirth: '02.01.2003',
    nationality: 'Frankreich',
    height: 1.84,
    foot: 'rechts',
    teamMemberSince: '01.01.2021',
    club: 'HSC Montpellier',
    previousClub: 'HSC Montpellier B',
    uid: '381a7c10-8ee6-4cb9-952f-f864f05e7701',
    position: 'Mittelstürmer',
    league: 'Ligue 1',
    marketValue: 25000000,
  },
  {
    jerseyNumber: 99,
    name: 'Wahbi Khazri',
    dateOfBirth: '08.02.1991',
    nationality: 'Tunesien',
    height: 1.76,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'HSC Montpellier',
    previousClub: 'AS Saint-Étienne',
    uid: '6d688111-6859-49d8-b770-56b6d07b50fc',
    position: 'Mittelstürmer',
    league: 'Ligue 1',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 9,
    name: 'Valère Germain',
    dateOfBirth: '17.04.1990',
    nationality: 'Frankreich',
    height: 1.81,
    foot: 'rechts',
    teamMemberSince: '31.08.2021',
    club: 'HSC Montpellier',
    previousClub: 'Olympique Marseille',
    uid: '0816ab64-2c0c-4ba1-afe4-92081f7ad85f',
    position: 'Mittelstürmer',
    league: 'Ligue 1',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 1,
    name: 'Matz Sels',
    dateOfBirth: '26.02.1992',
    nationality: 'Belgien',
    height: 1.88,
    foot: 'rechts',
    teamMemberSince: '26.07.2018',
    club: 'RC Straßburg Alsace',
    previousClub: 'Newcastle United',
    uid: 'a720a0a0-1a27-4d0f-8c85-5208ec56fef5',
    position: 'Torwart',
    league: 'Ligue 1',
    marketValue: 8000000,
  },
  {
    jerseyNumber: 16,
    name: 'Eiji Kawashima',
    dateOfBirth: '20.03.1983',
    nationality: 'Japan',
    height: 1.85,
    foot: 'rechts',
    teamMemberSince: '29.08.2018',
    club: 'RC Straßburg Alsace',
    previousClub: ': Ablöse ablösefrei',
    uid: '2a8abccb-c250-4cca-8960-4eb2117b777a',
    position: 'Torwart',
    league: 'Ligue 1',
    marketValue: 200000,
  },
  {
    jerseyNumber: 40,
    name: 'Robin Risser',
    dateOfBirth: '02.12.2004',
    nationality: 'Frankreich',
    height: 1.93,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'RC Straßburg Alsace',
    previousClub: 'Racing Straßburg U19',
    uid: 'ef4cd4c1-55e8-4aae-aef1-89313e22ea26',
    position: 'Torwart',
    league: 'Ligue 1',
    marketValue: 100000,
  },
  {
    jerseyNumber: 24,
    name: 'Alexander Djiku',
    dateOfBirth: '09.08.1994',
    nationality: 'Ghana',
    height: 1.82,
    foot: 'rechts',
    teamMemberSince: '05.07.2019',
    club: 'RC Straßburg Alsace',
    previousClub: 'SM Caen',
    uid: '66476db3-8c58-43fa-8da5-6bdab5494dae',
    position: 'Innenverteidiger',
    league: 'Ligue 1',
    marketValue: 8000000,
  },
  {
    jerseyNumber: 22,
    name: 'Gerzino Nyamsi',
    dateOfBirth: '22.01.1997',
    nationality: 'Frankreich',
    height: 1.95,
    foot: 'rechts',
    teamMemberSince: '30.08.2021',
    club: 'RC Straßburg Alsace',
    previousClub: 'FC Stade Rennes',
    uid: 'eeabfce8-1fbf-435e-9688-952866d6df95',
    position: 'Innenverteidiger',
    league: 'Ligue 1',
    marketValue: 6000000,
  },
  {
    jerseyNumber: 5,
    name: 'Lucas Perrin',
    dateOfBirth: '19.11.1998',
    nationality: 'Frankreich',
    height: 1.88,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'RC Straßburg Alsace',
    previousClub: 'Olympique Marseille',
    uid: '06b228fa-c4bc-4754-aa3e-70d7b8c94877',
    position: 'Innenverteidiger',
    league: 'Ligue 1',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 29,
    name: 'Ismaël Doukouré',
    dateOfBirth: '24.07.2003',
    nationality: 'Frankreich',
    height: 1.83,
    foot: 'rechts',
    teamMemberSince: '29.01.2022',
    club: 'RC Straßburg Alsace',
    previousClub: 'FC Valenciennes',
    uid: 'edc3b099-6b31-439f-81bd-9dbc95de0092',
    position: 'Innenverteidiger',
    league: 'Ligue 1',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 23,
    name: 'Maxime Le Marchand',
    dateOfBirth: '11.10.1989',
    nationality: 'Frankreich',
    height: 1.85,
    foot: 'links',
    teamMemberSince: '26.08.2021',
    club: 'RC Straßburg Alsace',
    previousClub: 'FC Fulham',
    uid: '3fd54bd4-2cd6-4567-ad21-4a81ca20f224',
    position: 'Innenverteidiger',
    league: 'Ligue 1',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 77,
    name: 'Eduard Sobol',
    dateOfBirth: '20.04.1995',
    nationality: 'Ukraine',
    height: 1.86,
    foot: 'links',
    teamMemberSince: '23.01.2023',
    club: 'RC Straßburg Alsace',
    previousClub: 'FC Brügge',
    uid: 'b2c14434-c3cf-4c6a-b585-e4dd46509aca',
    position: 'Linker Verteidiger',
    league: 'Ligue 1',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 3,
    name: 'Thomas Delaine',
    dateOfBirth: '24.03.1992',
    nationality: 'Frankreich',
    height: 1.8,
    foot: 'links',
    teamMemberSince: '01.07.2022',
    club: 'RC Straßburg Alsace',
    previousClub: 'FC Metz',
    uid: '4c8038f5-d196-4619-9866-4006d54d7c16',
    position: 'Linker Verteidiger',
    league: 'Ligue 1',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 2,
    name: 'Colin Dagba',
    dateOfBirth: '09.09.1998',
    nationality: 'Frankreich',
    height: 1.7,
    foot: 'rechts',
    teamMemberSince: '06.07.2022',
    club: 'RC Straßburg Alsace',
    previousClub: 'FC Paris Saint-Germain',
    uid: '4a2d86dd-4aa8-4940-91f3-e96ec9a45d33',
    position: 'Rechter Verteidiger',
    league: 'Ligue 1',
    marketValue: 5000000,
  },
  {
    jerseyNumber: 32,
    name: 'Frédéric Guilbert',
    dateOfBirth: '24.12.1994',
    nationality: 'Frankreich',
    height: 1.78,
    foot: 'rechts',
    teamMemberSince: '17.01.2023',
    club: 'RC Straßburg Alsace',
    previousClub: 'Aston Villa',
    uid: '5b2321ba-9c27-47d6-950f-12705f7fb1b1',
    position: 'Rechter Verteidiger',
    league: 'Ligue 1',
    marketValue: 5000000,
  },
  {
    jerseyNumber: 27,
    name: 'Ibrahima Sissoko',
    dateOfBirth: '27.10.1997',
    nationality: 'Frankreich',
    height: 1.93,
    foot: 'rechts',
    teamMemberSince: '01.07.2018',
    club: 'RC Straßburg Alsace',
    previousClub: 'Stade Brest 29',
    uid: 'd5628f45-d626-461a-8881-364f4ffc3281',
    position: 'Defensives Mittelfeld',
    league: 'Ligue 1',
    marketValue: 8000000,
  },
  {
    jerseyNumber: 19,
    name: 'Habib Diarra',
    dateOfBirth: '03.01.2004',
    nationality: 'Frankreich',
    height: 1.79,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'RC Straßburg Alsace',
    previousClub: 'Racing Straßburg U19',
    uid: '4fbbc117-5576-43df-a5b9-a3c54cc57930',
    position: 'Defensives Mittelfeld',
    league: 'Ligue 1',
    marketValue: 4000000,
  },
  {
    jerseyNumber: 6,
    name: 'Jean-Eudes Aholou',
    dateOfBirth: '20.03.1994',
    nationality: 'Elfenbeinküste',
    height: 1.86,
    foot: 'links',
    teamMemberSince: '01.07.2022',
    club: 'RC Straßburg Alsace',
    previousClub: 'AS Monaco',
    uid: '18fe19ae-964c-427b-974c-17488b473fcc',
    position: 'Defensives Mittelfeld',
    league: 'Ligue 1',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 17,
    name: 'Jean-Ricner Bellegarde',
    dateOfBirth: '27.06.1998',
    nationality: 'Frankreich',
    height: 1.72,
    foot: 'rechts',
    teamMemberSince: '02.07.2019',
    club: 'RC Straßburg Alsace',
    previousClub: 'RC Lens',
    uid: '7a43759f-aca8-434a-a6dc-f55e511e9e89',
    position: 'Zentrales Mittelfeld',
    league: 'Ligue 1',
    marketValue: 10000000,
  },
  {
    jerseyNumber: 8,
    name: 'Morgan Sanson',
    dateOfBirth: '18.08.1994',
    nationality: 'Frankreich',
    height: 1.8,
    foot: 'rechts',
    teamMemberSince: '23.01.2023',
    club: 'RC Straßburg Alsace',
    previousClub: 'Aston Villa',
    uid: '4215681d-049d-434e-9831-c21469a5b7c1',
    position: 'Zentrales Mittelfeld',
    league: 'Ligue 1',
    marketValue: 6000000,
  },
  {
    jerseyNumber: 14,
    name: 'Sanjin Prcic',
    dateOfBirth: '20.11.1993',
    nationality: 'Bosnien-Herzegowina',
    height: 1.81,
    foot: 'rechts',
    teamMemberSince: '02.09.2019',
    club: 'RC Straßburg Alsace',
    previousClub: 'UD Levante',
    uid: '78ca08c6-b357-4f99-b969-790b1350fd56',
    position: 'Zentrales Mittelfeld',
    league: 'Ligue 1',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 11,
    name: 'Dimitri Liénard',
    dateOfBirth: '13.02.1988',
    nationality: 'Frankreich',
    height: 1.81,
    foot: 'links',
    teamMemberSince: '01.07.2013',
    club: 'RC Straßburg Alsace',
    previousClub: ': Ablöse ablösefrei',
    uid: '410d4070-f781-46f0-83f7-21f459077ce4',
    position: 'Linkes Mittelfeld',
    league: 'Ligue 1',
    marketValue: 600000,
  },
  {
    jerseyNumber: 18,
    name: 'Yuito Suzuki',
    dateOfBirth: '25.10.2001',
    nationality: 'Japan',
    height: 1.75,
    foot: 'rechts',
    teamMemberSince: '27.01.2023',
    club: 'RC Straßburg Alsace',
    previousClub: 'Shimizu S-Pulse',
    uid: '20ed7668-3123-401b-8eb8-24c30a93e5f4',
    position: 'Offensives Mittelfeld',
    league: 'Ligue 1',
    marketValue: 700000,
  },
  {
    jerseyNumber: 34,
    name: 'Nordine Kandil',
    dateOfBirth: '31.10.2001',
    nationality: 'Marokko',
    height: 1.65,
    foot: 'links',
    teamMemberSince: '01.07.2021',
    club: 'RC Straßburg Alsace',
    previousClub: 'Racing Straßburg B',
    uid: 'f02ef2c7-5da8-4fb9-9fa5-3e920b35558b',
    position: 'Offensives Mittelfeld',
    league: 'Ligue 1',
    marketValue: 400000,
  },
  {
    jerseyNumber: 20,
    name: 'Habib Diallo',
    dateOfBirth: '18.06.1995',
    nationality: 'Senegal',
    height: 1.86,
    foot: 'rechts',
    teamMemberSince: '05.10.2020',
    club: 'RC Straßburg Alsace',
    previousClub: ': Ablöse 10,00 Mio. €',
    uid: '68e78448-f26e-4013-946d-044c6dfdf5e8',
    position: 'Mittelstürmer',
    league: 'Ligue 1',
    marketValue: 14000000,
  },
  {
    jerseyNumber: 9,
    name: 'Kévin Gameiro',
    dateOfBirth: '09.05.1987',
    nationality: 'Frankreich',
    height: 1.72,
    foot: 'rechts',
    teamMemberSince: '18.07.2021',
    club: 'RC Straßburg Alsace',
    previousClub: 'FC Valencia',
    uid: '2ebf0f06-db55-48de-a3aa-72313dc79446',
    position: 'Mittelstürmer',
    league: 'Ligue 1',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 12,
    name: 'Lebo Mothiba',
    dateOfBirth: '28.01.1996',
    nationality: 'Südafrika',
    height: 1.82,
    foot: 'rechts',
    teamMemberSince: '30.08.2018',
    club: 'RC Straßburg Alsace',
    previousClub: 'LOSC Lille',
    uid: '1a8e630c-35c4-4674-83a0-17c2d3c8ea7b',
    position: 'Mittelstürmer',
    league: 'Ligue 1',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 94,
    name: 'Yehvann Diouf',
    dateOfBirth: '16.11.1999',
    nationality: 'Frankreich',
    height: 1.84,
    foot: 'rechts',
    teamMemberSince: '01.07.2019',
    club: 'Stade Reims',
    previousClub: 'ESTAC Troyes',
    uid: 'f3a2e051-d382-48ff-8ea6-ff35a1a24f52',
    position: 'Torwart',
    league: 'Ligue 1',
    marketValue: 5000000,
  },
  {
    jerseyNumber: 96,
    name: 'Alexandre Olliero',
    dateOfBirth: '15.02.1996',
    nationality: 'Frankreich',
    height: 1.93,
    foot: 'rechts',
    teamMemberSince: '31.01.2023',
    club: 'Stade Reims',
    previousClub: 'Pau FC',
    uid: '2e54f094-9650-4426-a7db-dcbbce8f3c68',
    position: 'Torwart',
    league: 'Ligue 1',
    marketValue: 700000,
  },
  {
    jerseyNumber: 30,
    name: 'Nicolas Penneteau',
    dateOfBirth: '28.02.1981',
    nationality: 'Frankreich',
    height: 1.85,
    foot: 'links',
    teamMemberSince: '01.07.2021',
    club: 'Stade Reims',
    previousClub: 'RSC Charleroi',
    uid: 'e48d144a-d606-4ac5-a038-634c8521ebec',
    position: 'Torwart',
    league: 'Ligue 1',
    marketValue: 150000,
  },
  {
    jerseyNumber: 24,
    name: 'Emmanuel Agbadou',
    dateOfBirth: '17.06.1997',
    nationality: 'Elfenbeinküste',
    height: 1.92,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'Stade Reims',
    previousClub: 'KAS Eupen',
    uid: 'b3cb3d2e-ce0c-4eb3-bead-9325f271e507',
    position: 'Innenverteidiger',
    league: 'Ligue 1',
    marketValue: 6000000,
  },
  {
    jerseyNumber: 43,
    name: 'Cheick Keita',
    dateOfBirth: '02.04.2003',
    nationality: 'Frankreich',
    height: 1.85,
    foot: 'rechts',
    teamMemberSince: '01.03.2023',
    club: 'Stade Reims',
    previousClub: 'Stade Reims B',
    uid: 'a8b3b66c-7144-4bf2-bf0c-f067581cc963',
    position: 'Innenverteidiger',
    league: 'Ligue 1',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 5,
    name: 'Yunis Abdelhamid',
    dateOfBirth: '28.09.1987',
    nationality: 'Marokko',
    height: 1.9,
    foot: 'links',
    teamMemberSince: '01.07.2017',
    club: 'Stade Reims',
    previousClub: 'Dijon FCO',
    uid: 'f6305c87-2b3e-448d-8d4e-c11b2dc7d726',
    position: 'Innenverteidiger',
    league: 'Ligue 1',
    marketValue: 800000,
  },
  {
    jerseyNumber: 25,
    name: 'Thibault De Smet',
    dateOfBirth: '05.06.1998',
    nationality: 'Belgien',
    height: 1.83,
    foot: 'links',
    teamMemberSince: '01.07.2020',
    club: 'Stade Reims',
    previousClub: 'VV St. Truiden',
    uid: 'f6bf1ac6-98f5-4515-ad9e-3fb2253fe1f6',
    position: 'Linker Verteidiger',
    league: 'Ligue 1',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 32,
    name: 'Thomas Foket',
    dateOfBirth: '25.09.1994',
    nationality: 'Belgien',
    height: 1.77,
    foot: 'rechts',
    teamMemberSince: '31.08.2018',
    club: 'Stade Reims',
    previousClub: 'KAA Gent',
    uid: 'f8d6e362-6c57-46bb-9b04-c2ac980bedfc',
    position: 'Rechter Verteidiger',
    league: 'Ligue 1',
    marketValue: 4000000,
  },
  {
    jerseyNumber: 4,
    name: 'Maxime Busi',
    dateOfBirth: '14.10.1999',
    nationality: 'Belgien',
    height: 1.82,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'Stade Reims',
    previousClub: 'Parma Calcio 1913',
    uid: 'a87e33a8-dcd4-46ec-a973-2ddd770e431b',
    position: 'Rechter Verteidiger',
    league: 'Ligue 1',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 21,
    name: 'Azor Matusiwa',
    dateOfBirth: '28.04.1998',
    nationality: 'Niederlande',
    height: 1.73,
    foot: 'rechts',
    teamMemberSince: '31.08.2021',
    club: 'Stade Reims',
    previousClub: ': Ablöse 4,00 Mio. €',
    uid: '3186881e-127c-4d10-a749-8988dc7fe814',
    position: 'Defensives Mittelfeld',
    league: 'Ligue 1',
    marketValue: 6000000,
  },
  {
    jerseyNumber: 15,
    name: 'Marshall Munetsi',
    dateOfBirth: '22.06.1996',
    nationality: 'Simbabwe',
    height: 1.88,
    foot: 'rechts',
    teamMemberSince: '01.07.2019',
    club: 'Stade Reims',
    previousClub: 'Orlando Pirates',
    uid: 'df9757c7-7c60-43a5-86a8-464a8055779e',
    position: 'Defensives Mittelfeld',
    league: 'Ligue 1',
    marketValue: 5000000,
  },
  {
    jerseyNumber: 8,
    name: 'Jens Cajuste',
    dateOfBirth: '10.08.1999',
    nationality: 'Schweden',
    height: 1.88,
    foot: 'rechts',
    teamMemberSince: '10.01.2022',
    club: 'Stade Reims',
    previousClub: 'FC Midtjylland',
    uid: 'ea5ce3f1-c527-48dc-8e78-125572eb5246',
    position: 'Defensives Mittelfeld',
    league: 'Ligue 1',
    marketValue: 4000000,
  },
  {
    jerseyNumber: 17,
    name: 'Dion Lopy',
    dateOfBirth: '02.02.2002',
    nationality: 'Senegal',
    height: 1.86,
    foot: 'rechts',
    teamMemberSince: '01.07.2021',
    club: 'Stade Reims',
    previousClub: 'Stade Reims B',
    uid: 'ed8bfdc7-29fe-457b-9c85-6142180290ce',
    position: 'Defensives Mittelfeld',
    league: 'Ligue 1',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 3,
    name: 'Kamory Doumbia',
    dateOfBirth: '18.02.2003',
    nationality: 'Mali',
    height: 1.7,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'Stade Reims',
    previousClub: 'Stade Reims B',
    uid: '1f54ecc6-9092-4f53-9a64-6bfe19a46727',
    position: 'Offensives Mittelfeld',
    league: 'Ligue 1',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 10,
    name: 'Arbër Zeneli',
    dateOfBirth: '25.02.1995',
    nationality: 'Kosovo',
    height: 1.76,
    foot: 'rechts',
    teamMemberSince: '26.01.2019',
    club: 'Stade Reims',
    previousClub: ': Ablöse 4,00 Mio. €',
    uid: '81bf5f90-a85d-476f-b03e-e97b66437949',
    position: 'Linksaußen',
    league: 'Ligue 1',
    marketValue: 4000000,
  },
  {
    jerseyNumber: 11,
    name: 'Myziane Maolida',
    dateOfBirth: '14.02.1999',
    nationality: 'Frankreich',
    height: 1.82,
    foot: 'rechts',
    teamMemberSince: '31.01.2023',
    club: 'Stade Reims',
    previousClub: 'Hertha BSC',
    uid: 'e68acc12-1812-4e50-a8e9-72b6ccba68b6',
    position: 'Linksaußen',
    league: 'Ligue 1',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 19,
    name: 'Mitchell van Bergen',
    dateOfBirth: '27.08.1999',
    nationality: 'Niederlande',
    height: 1.7,
    foot: 'rechts',
    teamMemberSince: '27.08.2021',
    club: 'Stade Reims',
    previousClub: 'SC Heerenveen',
    uid: 'f782efa9-ceaa-4429-a200-82ed0c8d1344',
    position: 'Linksaußen',
    league: 'Ligue 1',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 39,
    name: 'Junya Ito',
    dateOfBirth: '09.03.1993',
    nationality: 'Japan',
    height: 1.76,
    foot: 'rechts',
    teamMemberSince: '29.07.2022',
    club: 'Stade Reims',
    previousClub: 'KRC Genk',
    uid: '631b8557-5950-485f-8347-41f99d202b3b',
    position: 'Rechtsaußen',
    league: 'Ligue 1',
    marketValue: 10000000,
  },
  {
    jerseyNumber: 70,
    name: 'Alexis Flips',
    dateOfBirth: '18.01.2000',
    nationality: 'Frankreich',
    height: 1.73,
    foot: 'rechts',
    teamMemberSince: '01.07.2021',
    club: 'Stade Reims',
    previousClub: 'Stade Reims B',
    uid: '2c3b50aa-54ab-4164-98e8-52531dd88586',
    position: 'Rechtsaußen',
    league: 'Ligue 1',
    marketValue: 5000000,
  },
  {
    jerseyNumber: 29,
    name: 'Folarin Balogun',
    dateOfBirth: '03.07.2001',
    nationality: 'Vereinigte Staaten',
    height: 1.78,
    foot: 'rechts',
    teamMemberSince: '03.08.2022',
    club: 'Stade Reims',
    previousClub: 'FC Arsenal',
    uid: 'd225a9a4-4fa6-4486-8381-eaec5c7dd137',
    position: 'Mittelstürmer',
    league: 'Ligue 1',
    marketValue: 25000000,
  },
  {
    jerseyNumber: 9,
    name: 'Kaj Sierhuis',
    dateOfBirth: '27.04.1998',
    nationality: 'Niederlande',
    height: 1.8,
    foot: 'rechts',
    teamMemberSince: '31.01.2020',
    club: 'Stade Reims',
    previousClub: 'Ajax Amsterdam II',
    uid: 'e9f9f338-54ec-45df-8ec7-231aea7539f2',
    position: 'Mittelstürmer',
    league: 'Ligue 1',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 38,
    name: 'Yvon Mvogo',
    dateOfBirth: '06.06.1994',
    nationality: 'Schweiz',
    height: 1.9,
    foot: 'rechts',
    teamMemberSince: '13.07.2022',
    club: 'FC Lorient',
    previousClub: 'RasenBallsport Leipzig',
    uid: 'e31758b6-77eb-427b-a6cc-5ce602d7a320',
    position: 'Torwart',
    league: 'Ligue 1',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 23,
    name: 'Julian Pollersbeck',
    dateOfBirth: '16.08.1994',
    nationality: 'Deutschland',
    height: 1.95,
    foot: 'rechts',
    teamMemberSince: '31.01.2023',
    club: 'FC Lorient',
    previousClub: 'Olympique Lyon',
    uid: 'c4e4ccd9-68e8-4a34-8ee5-a3ae2437cfc4',
    position: 'Torwart',
    league: 'Ligue 1',
    marketValue: 600000,
  },
  {
    jerseyNumber: 1,
    name: 'Vito Mannone',
    dateOfBirth: '02.03.1988',
    nationality: 'Italien',
    height: 1.89,
    foot: 'rechts',
    teamMemberSince: '01.09.2022',
    club: 'FC Lorient',
    previousClub: 'AS Monaco',
    uid: 'ff294a42-8df8-4e7c-9966-1fae338be7ba',
    position: 'Torwart',
    league: 'Ligue 1',
    marketValue: 300000,
  },
  {
    jerseyNumber: 77,
    name: 'Teddy Bartouche',
    dateOfBirth: '05.06.1997',
    nationality: 'Frankreich',
    height: 1.78,
    foot: 'rechts',
    teamMemberSince: '01.07.2020',
    club: 'FC Lorient',
    previousClub: 'FC Lorient B',
    uid: 'c5f241e8-bf45-45e3-9e97-fe4999d0f9eb',
    position: 'Torwart',
    league: 'Ligue 1',
    marketValue: 150000,
  },
  {
    jerseyNumber: 3,
    name: 'Montassar Talbi',
    dateOfBirth: '26.05.1998',
    nationality: 'Tunesien',
    height: 1.9,
    foot: 'rechts',
    teamMemberSince: '18.07.2022',
    club: 'FC Lorient',
    previousClub: 'Rubin Kazan',
    uid: '9c248d70-7c68-4c25-8636-ca1cf6c8ed57',
    position: 'Innenverteidiger',
    league: 'Ligue 1',
    marketValue: 5000000,
  },
  {
    jerseyNumber: 15,
    name: 'Julien Laporte',
    dateOfBirth: '04.11.1993',
    nationality: 'Frankreich',
    height: 1.85,
    foot: 'rechts',
    teamMemberSince: '08.07.2019',
    club: 'FC Lorient',
    previousClub: 'Clermont Foot 63',
    uid: '3fb962c1-6aee-4123-9cca-9684ce44ff1f',
    position: 'Innenverteidiger',
    league: 'Ligue 1',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 25,
    name: 'Vincent Le Goff',
    dateOfBirth: '15.10.1989',
    nationality: 'Frankreich',
    height: 1.77,
    foot: 'links',
    teamMemberSince: '17.07.2014',
    club: 'FC Lorient',
    previousClub: ': Ablöse ?',
    uid: '92620e66-ceb7-4c5a-8b04-95401f8fd168',
    position: 'Linker Verteidiger',
    league: 'Ligue 1',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 12,
    name: 'Darlin Yongwa',
    dateOfBirth: '22.09.2000',
    nationality: 'Kamerun',
    height: 1.77,
    foot: 'links',
    teamMemberSince: '01.07.2022',
    club: 'FC Lorient',
    previousClub: 'Chamois Niortais FC',
    uid: '1274a5b1-efb7-45a7-8614-0cd97140b021',
    position: 'Linker Verteidiger',
    league: 'Ligue 1',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 24,
    name: 'Gédéon Kalulu',
    dateOfBirth: '29.08.1997',
    nationality: 'DR Kongo',
    height: 1.79,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'FC Lorient',
    previousClub: 'AC Ajaccio',
    uid: '9c72275a-6a7d-473c-8321-ee4269a6aa89',
    position: 'Rechter Verteidiger',
    league: 'Ligue 1',
    marketValue: 5000000,
  },
  {
    jerseyNumber: 2,
    name: 'Igor Silva',
    dateOfBirth: '21.08.1996',
    nationality: 'Brasilien',
    height: 1.73,
    foot: 'rechts',
    teamMemberSince: '23.07.2021',
    club: 'FC Lorient',
    previousClub: 'NK Osijek',
    uid: '356259f8-715b-47c8-bd58-af142cc3ec78',
    position: 'Rechter Verteidiger',
    league: 'Ligue 1',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 19,
    name: 'Laurent Abergel',
    dateOfBirth: '01.02.1993',
    nationality: 'Frankreich',
    height: 1.7,
    foot: 'rechts',
    teamMemberSince: '26.07.2019',
    club: 'FC Lorient',
    previousClub: 'AS Nancy-Lorraine',
    uid: 'f1e7176c-c62f-404c-9e1e-3a803643b77d',
    position: 'Defensives Mittelfeld',
    league: 'Ligue 1',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 8,
    name: 'Bonke Innocent',
    dateOfBirth: '20.01.1996',
    nationality: 'Nigeria',
    height: 1.78,
    foot: 'rechts',
    teamMemberSince: '18.01.2022',
    club: 'FC Lorient',
    previousClub: 'Malmö FF',
    uid: 'da5c1b1e-ee00-4328-8716-a946dd41048d',
    position: 'Defensives Mittelfeld',
    league: 'Ligue 1',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 17,
    name: 'Jean-Victor Makengo',
    dateOfBirth: '12.06.1998',
    nationality: 'Frankreich',
    height: 1.77,
    foot: 'links',
    teamMemberSince: '30.01.2023',
    club: 'FC Lorient',
    previousClub: 'Udinese Calcio',
    uid: '794b91bc-ff5e-49d8-b495-45890c3ffb24',
    position: 'Zentrales Mittelfeld',
    league: 'Ligue 1',
    marketValue: 10000000,
  },
  {
    jerseyNumber: 44,
    name: 'Ayman Kari',
    dateOfBirth: '19.11.2004',
    nationality: 'Frankreich',
    height: 1.76,
    foot: 'rechts',
    teamMemberSince: '31.01.2023',
    club: 'FC Lorient',
    previousClub: 'FC Paris Saint-Germain',
    uid: '878706fc-e893-4a33-b70b-49debfade55d',
    position: 'Zentrales Mittelfeld',
    league: 'Ligue 1',
    marketValue: 500000,
  },
  {
    jerseyNumber: 14,
    name: 'Romain Faivre',
    dateOfBirth: '14.07.1998',
    nationality: 'Frankreich',
    height: 1.8,
    foot: 'links',
    teamMemberSince: '28.01.2023',
    club: 'FC Lorient',
    previousClub: 'Olympique Lyon',
    uid: 'd13e5563-6f36-4ecf-8c8c-50f074075d58',
    position: 'Rechtes Mittelfeld',
    league: 'Ligue 1',
    marketValue: 8000000,
  },
  {
    jerseyNumber: 80,
    name: 'Enzo Le Fée',
    dateOfBirth: '03.02.2000',
    nationality: 'Frankreich',
    height: 1.7,
    foot: 'rechts',
    teamMemberSince: '01.07.2019',
    club: 'FC Lorient',
    previousClub: 'FC Lorient B',
    uid: 'faeeeff6-ca11-4164-b9d7-e7b26a9e6cad',
    position: 'Offensives Mittelfeld',
    league: 'Ligue 1',
    marketValue: 15000000,
  },
  {
    jerseyNumber: 10,
    name: 'Adil Aouchiche',
    dateOfBirth: '15.07.2002',
    nationality: 'Frankreich',
    height: 1.81,
    foot: 'rechts',
    teamMemberSince: '01.09.2022',
    club: 'FC Lorient',
    previousClub: 'AS Saint-Étienne',
    uid: '3b19a603-1caf-4797-961f-bb7b53e1f6ca',
    position: 'Offensives Mittelfeld',
    league: 'Ligue 1',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 21,
    name: 'Julien Ponceau',
    dateOfBirth: '28.11.2000',
    nationality: 'Frankreich',
    height: 1.7,
    foot: 'rechts',
    teamMemberSince: '01.07.2018',
    club: 'FC Lorient',
    previousClub: 'FC Lorient U19',
    uid: 'e176e336-3166-4d0b-ae08-a9de36ceaa82',
    position: 'Offensives Mittelfeld',
    league: 'Ligue 1',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 37,
    name: 'Théo Le Bris',
    dateOfBirth: '01.10.2002',
    nationality: 'Frankreich',
    height: 1.7,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'FC Lorient',
    previousClub: 'FC Lorient B',
    uid: '3c84ddfa-cf5e-4777-8092-af0633801ce8',
    position: 'Offensives Mittelfeld',
    league: 'Ligue 1',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 22,
    name: 'Yoann Cathline',
    dateOfBirth: '22.07.2002',
    nationality: 'Frankreich',
    height: 1.76,
    foot: 'rechts',
    teamMemberSince: '05.09.2022',
    club: 'FC Lorient',
    previousClub: 'EA Guingamp',
    uid: '3f3ec320-a4a9-447b-a3ec-01e4725d8a15',
    position: 'Linksaußen',
    league: 'Ligue 1',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 7,
    name: 'Stéphane Diarra',
    dateOfBirth: '09.12.1998',
    nationality: 'Elfenbeinküste',
    height: 1.73,
    foot: 'links',
    teamMemberSince: '01.07.2020',
    club: 'FC Lorient',
    previousClub: 'FC Le Mans',
    uid: '14ba1657-2080-4336-b9ff-ccb3dc1886ad',
    position: 'Rechtsaußen',
    league: 'Ligue 1',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 11,
    name: 'Bamba Dieng',
    dateOfBirth: '23.03.2000',
    nationality: 'Senegal',
    height: 1.78,
    foot: 'rechts',
    teamMemberSince: '27.01.2023',
    club: 'FC Lorient',
    previousClub: 'Olympique Marseille',
    uid: '3dcf68d1-7649-4968-805d-4e3527ddd600',
    position: 'Mittelstürmer',
    league: 'Ligue 1',
    marketValue: 8000000,
  },
  {
    jerseyNumber: 9,
    name: 'Ibrahima Koné',
    dateOfBirth: '16.06.1999',
    nationality: 'Mali',
    height: 1.9,
    foot: 'links',
    teamMemberSince: '31.01.2022',
    club: 'FC Lorient',
    previousClub: 'Sarpsborg 08 FF',
    uid: '3eaa7a46-2c28-41c8-abea-3af76e328836',
    position: 'Mittelstürmer',
    league: 'Ligue 1',
    marketValue: 4000000,
  },
  {
    jerseyNumber: 29,
    name: 'Siriné Doucouré',
    dateOfBirth: '08.04.2002',
    nationality: 'Frankreich',
    height: 1.93,
    foot: 'rechts',
    teamMemberSince: '01.09.2022',
    club: 'FC Lorient',
    previousClub: 'LB Châteauroux',
    uid: '7001a850-29d9-4ff2-a83f-073f543ea85d',
    position: 'Mittelstürmer',
    league: 'Ligue 1',
    marketValue: 500000,
  },
  {
    jerseyNumber: 30,
    name: 'Maxime Dupé',
    dateOfBirth: '04.03.1993',
    nationality: 'Frankreich',
    height: 1.88,
    foot: 'rechts',
    teamMemberSince: '01.07.2020',
    club: 'FC Toulouse',
    previousClub: 'FC Nantes',
    uid: 'eeeb7925-eb6d-4634-bda2-704dee1fb7ed',
    position: 'Torwart',
    league: 'Ligue 1',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 16,
    name: 'Kjetil Haug',
    dateOfBirth: '12.06.1998',
    nationality: 'Norwegen',
    height: 1.91,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'FC Toulouse',
    previousClub: 'Vålerenga Fotball',
    uid: 'bc3dcc3e-4d40-4552-b2e5-13ef9c179005',
    position: 'Torwart',
    league: 'Ligue 1',
    marketValue: 400000,
  },
  {
    jerseyNumber: 4,
    name: 'Anthony Rouault',
    dateOfBirth: '29.05.2001',
    nationality: 'Frankreich',
    height: 1.86,
    foot: 'rechts',
    teamMemberSince: '01.07.2019',
    club: 'FC Toulouse',
    previousClub: 'FC Toulouse B',
    uid: 'b8dd15a7-02e5-4190-a5b1-d8f3c5bff42a',
    position: 'Innenverteidiger',
    league: 'Ligue 1',
    marketValue: 8000000,
  },
  {
    jerseyNumber: 2,
    name: 'Rasmus Nicolaisen',
    dateOfBirth: '16.03.1997',
    nationality: 'Dänemark',
    height: 1.91,
    foot: 'links',
    teamMemberSince: '17.08.2021',
    club: 'FC Toulouse',
    previousClub: 'FC Midtjylland',
    uid: '27b50103-28af-4423-8d84-38b55ba8c3fd',
    position: 'Innenverteidiger',
    league: 'Ligue 1',
    marketValue: 5000000,
  },
  {
    jerseyNumber: 14,
    name: 'Logan Costa',
    dateOfBirth: '01.04.2001',
    nationality: 'Kap Verde',
    height: 1.9,
    foot: 'rechts',
    teamMemberSince: '20.08.2021',
    club: 'FC Toulouse',
    previousClub: 'Stade Reims',
    uid: 'c6f32668-fe36-4f11-9874-ab1544e8782c',
    position: 'Innenverteidiger',
    league: 'Ligue 1',
    marketValue: 400000,
  },
  {
    jerseyNumber: 15,
    name: 'Gabriel Suazo',
    dateOfBirth: '09.08.1997',
    nationality: 'Chile',
    height: 1.78,
    foot: 'links',
    teamMemberSince: '16.01.2023',
    club: 'FC Toulouse',
    previousClub: 'CSD Colo Colo',
    uid: '48f9e276-f32f-47ce-a7fd-9ced3de15418',
    position: 'Linker Verteidiger',
    league: 'Ligue 1',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 23,
    name: 'Moussa Diarra',
    dateOfBirth: '10.11.2000',
    nationality: 'Mali',
    height: 1.85,
    foot: 'links',
    teamMemberSince: '01.07.2019',
    club: 'FC Toulouse',
    previousClub: 'FC Toulouse B',
    uid: '2832fdec-5164-40ae-8106-69a54b4a8baa',
    position: 'Linker Verteidiger',
    league: 'Ligue 1',
    marketValue: 800000,
  },
  {
    jerseyNumber: 3,
    name: 'Mikkel Desler',
    dateOfBirth: '19.02.1995',
    nationality: 'Dänemark',
    height: 1.84,
    foot: 'rechts',
    teamMemberSince: '20.07.2021',
    club: 'FC Toulouse',
    previousClub: 'FK Haugesund',
    uid: '0573ca5f-b167-4508-8d68-3ac2ff58e5a6',
    position: 'Rechter Verteidiger',
    league: 'Ligue 1',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 26,
    name: 'Warren Kamanzi',
    dateOfBirth: '11.11.2000',
    nationality: 'Norwegen',
    height: 1.77,
    foot: 'rechts',
    teamMemberSince: '02.01.2023',
    club: 'FC Toulouse',
    previousClub: 'Tromsø IL',
    uid: '6b5d3ea5-d8cf-4f0d-a0d8-a1fc6175532d',
    position: 'Rechter Verteidiger',
    league: 'Ligue 1',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 17,
    name: 'Stijn Spierings',
    dateOfBirth: '12.03.1996',
    nationality: 'Niederlande',
    height: 1.88,
    foot: 'rechts',
    teamMemberSince: '05.10.2020',
    club: 'FC Toulouse',
    previousClub: 'Levski Sofia',
    uid: 'dba20355-7e52-4136-b033-68e4b15454f1',
    position: 'Defensives Mittelfeld',
    league: 'Ligue 1',
    marketValue: 4000000,
  },
  {
    jerseyNumber: 8,
    name: 'Branco van den Boomen',
    dateOfBirth: '21.07.1995',
    nationality: 'Niederlande',
    height: 1.88,
    foot: 'rechts',
    teamMemberSince: '07.08.2020',
    club: 'FC Toulouse',
    previousClub: 'De Graafschap Doetinchem',
    uid: 'aeb9f28b-0585-442b-af7c-3c1bea0ec4d9',
    position: 'Zentrales Mittelfeld',
    league: 'Ligue 1',
    marketValue: 9000000,
  },
  {
    jerseyNumber: 10,
    name: 'Brecht Dejaegere',
    dateOfBirth: '29.05.1991',
    nationality: 'Belgien',
    height: 1.73,
    foot: 'rechts',
    teamMemberSince: '01.07.2021',
    club: 'FC Toulouse',
    previousClub: 'KAA Gent',
    uid: 'ab1380c8-b92e-4b85-8a25-c01727bec85b',
    position: 'Zentrales Mittelfeld',
    league: 'Ligue 1',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 24,
    name: 'Theocharis Tsingaras',
    dateOfBirth: '20.08.2000',
    nationality: 'Griechenland',
    height: 1.75,
    foot: 'rechts',
    teamMemberSince: '01.09.2022',
    club: 'FC Toulouse',
    previousClub: 'PAOK Thessaloniki',
    uid: 'a642bcf7-8d8e-45f6-a093-807b4a6a8343',
    position: 'Zentrales Mittelfeld',
    league: 'Ligue 1',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 13,
    name: 'Vincent Sierro',
    dateOfBirth: '08.10.1995',
    nationality: 'Schweiz',
    height: 1.85,
    foot: 'rechts',
    teamMemberSince: '23.01.2023',
    club: 'FC Toulouse',
    previousClub: 'BSC Young Boys',
    uid: '7e855c6d-2068-4cf0-86d9-db855d5140fd',
    position: 'Zentrales Mittelfeld',
    league: 'Ligue 1',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 5,
    name: 'Denis Genreau',
    dateOfBirth: '21.05.1999',
    nationality: 'Australien',
    height: 1.75,
    foot: 'rechts',
    teamMemberSince: '02.08.2021',
    club: 'FC Toulouse',
    previousClub: 'Macarthur FC',
    uid: '2f5a14ad-68ab-4902-892a-3fb3dc5060d0',
    position: 'Offensives Mittelfeld',
    league: 'Ligue 1',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 28,
    name: 'Farès Chaïbi',
    dateOfBirth: '28.11.2002',
    nationality: 'Algerien',
    height: 1.83,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'FC Toulouse',
    previousClub: 'FC Toulouse B',
    uid: '1f534872-62dc-4b60-8f81-cd88e0eec635',
    position: 'Linksaußen',
    league: 'Ligue 1',
    marketValue: 10000000,
  },
  {
    jerseyNumber: 19,
    name: 'Veljko Birmancevic',
    dateOfBirth: '05.03.1998',
    nationality: 'Serbien',
    height: 1.79,
    foot: 'beidfüßig',
    teamMemberSince: '01.09.2022',
    club: 'FC Toulouse',
    previousClub: 'Malmö FF',
    uid: 'ec7ed6ea-2b51-4825-84ce-5cda4f828dfb',
    position: 'Linksaußen',
    league: 'Ligue 1',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 21,
    name: 'Rafael Ratão',
    dateOfBirth: '30.11.1995',
    nationality: 'Brasilien',
    height: 1.83,
    foot: 'rechts',
    teamMemberSince: '31.08.2021',
    club: 'FC Toulouse',
    previousClub: 'Slovan Bratislava',
    uid: '536c1013-44ea-46f7-9feb-9d6aa140139f',
    position: 'Linksaußen',
    league: 'Ligue 1',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 6,
    name: 'Zakaria Aboukhlal',
    dateOfBirth: '18.02.2000',
    nationality: 'Marokko',
    height: 1.79,
    foot: 'beidfüßig',
    teamMemberSince: '01.07.2022',
    club: 'FC Toulouse',
    previousClub: 'AZ Alkmaar',
    uid: '1cd87446-a9d7-4e62-8202-4d14ca550290',
    position: 'Rechtsaußen',
    league: 'Ligue 1',
    marketValue: 7000000,
  },
  {
    jerseyNumber: 27,
    name: 'Thijs Dallinga',
    dateOfBirth: '03.08.2000',
    nationality: 'Niederlande',
    height: 1.9,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'FC Toulouse',
    previousClub: 'Excelsior Rotterdam',
    uid: '1006f268-546f-4216-86f2-027c08d3ee61',
    position: 'Mittelstürmer',
    league: 'Ligue 1',
    marketValue: 7000000,
  },
  {
    jerseyNumber: 9,
    name: 'Rhys Healey',
    dateOfBirth: '06.12.1994',
    nationality: 'England',
    height: 1.8,
    foot: 'rechts',
    teamMemberSince: '27.08.2020',
    club: 'FC Toulouse',
    previousClub: 'Milton Keynes Dons',
    uid: 'b069dd56-1c6d-4572-bc3c-65c7de948fab',
    position: 'Mittelstürmer',
    league: 'Ligue 1',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 7,
    name: 'Ado Onaiwu',
    dateOfBirth: '08.11.1995',
    nationality: 'Japan',
    height: 1.8,
    foot: 'rechts',
    teamMemberSince: '20.07.2021',
    club: 'FC Toulouse',
    previousClub: 'Yokohama F. Marinos',
    uid: '49905d85-99ff-4f14-8f94-e9146877f5f4',
    position: 'Mittelstürmer',
    league: 'Ligue 1',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 29,
    name: 'Saïd Hamulic',
    dateOfBirth: '12.11.2000',
    nationality: 'Bosnien-Herzegowina',
    height: 1.88,
    foot: 'rechts',
    teamMemberSince: '24.01.2023',
    club: 'FC Toulouse',
    previousClub: 'Stal Mielec',
    uid: '7491c023-a6c1-4151-8e08-e8c45cb9a06a',
    position: 'Mittelstürmer',
    league: 'Ligue 1',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 30,
    name: 'Gauthier Gallon',
    dateOfBirth: '23.04.1993',
    nationality: 'Frankreich',
    height: 1.86,
    foot: 'rechts',
    teamMemberSince: '01.07.2019',
    club: 'ESTAC Troyes',
    previousClub: 'US Orléans',
    uid: 'b9ed63ce-04c9-4841-be6b-0970863697da',
    position: 'Torwart',
    league: 'Ligue 1',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 1,
    name: 'Mateusz Lis',
    dateOfBirth: '27.02.1997',
    nationality: 'Polen',
    height: 1.92,
    foot: 'rechts',
    teamMemberSince: '01.09.2022',
    club: 'ESTAC Troyes',
    previousClub: 'FC Southampton',
    uid: '2a2eddab-6693-4212-95ee-033aa0037168',
    position: 'Torwart',
    league: 'Ligue 1',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 40,
    name: 'Jessy Moulin',
    dateOfBirth: '13.01.1986',
    nationality: 'Frankreich',
    height: 1.84,
    foot: 'rechts',
    teamMemberSince: '19.07.2021',
    club: 'ESTAC Troyes',
    previousClub: ': Ablöse ablösefrei',
    uid: 'e4fa6e48-8039-4fad-9ee5-c048f8bbe42c',
    position: 'Torwart',
    league: 'Ligue 1',
    marketValue: 250000,
  },
  {
    jerseyNumber: 2,
    name: 'Jackson Porozo',
    dateOfBirth: '04.08.2000',
    nationality: 'Ecuador',
    height: 1.92,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'ESTAC Troyes',
    previousClub: 'Boavista Porto FC',
    uid: 'fca68444-b0ff-4320-b978-2578fce125a1',
    position: 'Innenverteidiger',
    league: 'Ligue 1',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 4,
    name: 'Erik Palmer-Brown',
    dateOfBirth: '24.04.1997',
    nationality: 'Vereinigte Staaten',
    height: 1.86,
    foot: 'rechts',
    teamMemberSince: '02.02.2022',
    club: 'ESTAC Troyes',
    previousClub: 'Manchester City',
    uid: '7e6fe0f1-45d1-4e97-94ae-f7dd5ce8d65f',
    position: 'Innenverteidiger',
    league: 'Ligue 1',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 17,
    name: 'Yoann Salmier',
    dateOfBirth: '21.11.1992',
    nationality: 'Französisch-Guayana',
    height: 1.88,
    foot: 'rechts',
    teamMemberSince: '01.07.2019',
    club: 'ESTAC Troyes',
    previousClub: 'RC Straßburg Alsace',
    uid: '61ae54d9-6966-430a-a510-ee670ea6a046',
    position: 'Innenverteidiger',
    league: 'Ligue 1',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 22,
    name: 'Tanguy Zoukrou',
    dateOfBirth: '07.05.2003',
    nationality: 'Frankreich',
    height: 1.89,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'ESTAC Troyes',
    previousClub: 'ESTAC Troyes B',
    uid: 'fdcb470f-9339-4677-97af-ccfe3c497e23',
    position: 'Innenverteidiger',
    league: 'Ligue 1',
    marketValue: 300000,
  },
  {
    jerseyNumber: 23,
    name: 'Adil Rami',
    dateOfBirth: '27.12.1985',
    nationality: 'Frankreich',
    height: 1.91,
    foot: 'rechts',
    teamMemberSince: '24.08.2021',
    club: 'ESTAC Troyes',
    previousClub: 'Boavista Porto FC',
    uid: '6314e773-3a5e-4dbe-837e-6170ff5096c5',
    position: 'Innenverteidiger',
    league: 'Ligue 1',
    marketValue: 300000,
  },
  {
    jerseyNumber: 39,
    name: 'Yasser Larouci',
    dateOfBirth: '01.01.2001',
    nationality: 'Frankreich',
    height: 1.76,
    foot: 'links',
    teamMemberSince: '28.07.2021',
    club: 'ESTAC Troyes',
    previousClub: 'FC Liverpool U23',
    uid: '5d52319e-ef44-4664-9427-b99c30d44218',
    position: 'Linker Verteidiger',
    league: 'Ligue 1',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 18,
    name: 'Thierno Baldé',
    dateOfBirth: '10.06.2002',
    nationality: 'Frankreich',
    height: 1.82,
    foot: 'rechts',
    teamMemberSince: '09.08.2022',
    club: 'ESTAC Troyes',
    previousClub: 'FC Paris Saint-Germain',
    uid: 'c9dbb0e6-add7-46e7-927c-0c5e3a159a42',
    position: 'Rechter Verteidiger',
    league: 'Ligue 1',
    marketValue: 4000000,
  },
  {
    jerseyNumber: 19,
    name: 'Andreas Bruus',
    dateOfBirth: '16.01.1999',
    nationality: 'Dänemark',
    height: 1.83,
    foot: 'rechts',
    teamMemberSince: '18.07.2022',
    club: 'ESTAC Troyes',
    previousClub: 'Bröndby IF',
    uid: '74799532-e9a7-4fbe-9af5-aff12a623271',
    position: 'Rechter Verteidiger',
    league: 'Ligue 1',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 8,
    name: 'Lucien Agoumé',
    dateOfBirth: '09.02.2002',
    nationality: 'Frankreich',
    height: 1.85,
    foot: 'rechts',
    teamMemberSince: '01.09.2022',
    club: 'ESTAC Troyes',
    previousClub: 'Inter Mailand',
    uid: '6cdf8e9c-b68d-4be4-94c7-a774929cf3a5',
    position: 'Defensives Mittelfeld',
    league: 'Ligue 1',
    marketValue: 7000000,
  },
  {
    jerseyNumber: 6,
    name: 'Rominigue Kouamé',
    dateOfBirth: '17.12.1996',
    nationality: 'Mali',
    height: 1.77,
    foot: 'links',
    teamMemberSince: '01.07.2021',
    club: 'ESTAC Troyes',
    previousClub: 'LOSC Lille',
    uid: 'fac51e11-09a6-4e62-95ce-f916dbdbdd86',
    position: 'Zentrales Mittelfeld',
    league: 'Ligue 1',
    marketValue: 5000000,
  },
  {
    jerseyNumber: 10,
    name: 'Florian Tardieu',
    dateOfBirth: '22.04.1992',
    nationality: 'Frankreich',
    height: 1.84,
    foot: 'rechts',
    teamMemberSince: '24.07.2019',
    club: 'ESTAC Troyes',
    previousClub: 'SV Zulte Waregem',
    uid: 'd86ba9d4-0ae5-49d2-8ddc-69f8ea2085ed',
    position: 'Zentrales Mittelfeld',
    league: 'Ligue 1',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 24,
    name: 'Xavier Chavalerin',
    dateOfBirth: '07.03.1991',
    nationality: 'Frankreich',
    height: 1.79,
    foot: 'links',
    teamMemberSince: '29.08.2021',
    club: 'ESTAC Troyes',
    previousClub: 'Stade Reims',
    uid: 'cc548877-0dd9-4de0-b139-cc1a36881acf',
    position: 'Zentrales Mittelfeld',
    league: 'Ligue 1',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 15,
    name: 'Ante Palaversa',
    dateOfBirth: '06.04.2000',
    nationality: 'Kroatien',
    height: 1.87,
    foot: 'rechts',
    teamMemberSince: '31.08.2022',
    club: 'ESTAC Troyes',
    previousClub: 'Manchester City',
    uid: 'b6cd4e6a-9569-41d7-956f-d29965e99573',
    position: 'Zentrales Mittelfeld',
    league: 'Ligue 1',
    marketValue: 800000,
  },
  {
    jerseyNumber: 14,
    name: 'Jeff Reine-Adélaïde',
    dateOfBirth: '17.01.1998',
    nationality: 'Frankreich',
    height: 1.84,
    foot: 'rechts',
    teamMemberSince: '30.01.2023',
    club: 'ESTAC Troyes',
    previousClub: 'Olympique Lyon',
    uid: '243a3594-fda8-4a58-b924-8854acc1146e',
    position: 'Offensives Mittelfeld',
    league: 'Ligue 1',
    marketValue: 7000000,
  },
  {
    jerseyNumber: 25,
    name: 'Alexis Tibidi',
    dateOfBirth: '03.11.2003',
    nationality: 'Frankreich',
    height: 1.87,
    foot: 'rechts',
    teamMemberSince: '28.01.2023',
    club: 'ESTAC Troyes',
    previousClub: 'VfB Stuttgart',
    uid: '06deef2e-e71e-498e-abdc-9f0fa4f62b10',
    position: 'Linksaußen',
    league: 'Ligue 1',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 26,
    name: 'Papa Ndiaga Yade',
    dateOfBirth: '05.01.2000',
    nationality: 'Senegal',
    height: 1.85,
    foot: 'links',
    teamMemberSince: '25.08.2022',
    club: 'ESTAC Troyes',
    previousClub: 'FC Metz',
    uid: '90eea75a-8397-4c58-b095-23f06d49e0d6',
    position: 'Linksaußen',
    league: 'Ligue 1',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 44,
    name: 'Levi Lumeka',
    dateOfBirth: '05.09.1998',
    nationality: 'England',
    height: 1.7,
    foot: 'rechts',
    teamMemberSince: '28.07.2020',
    club: 'ESTAC Troyes',
    previousClub: 'Varzim SC',
    uid: '4fc398d5-b440-4b89-b8a6-131ab04b3b4e',
    position: 'Linksaußen',
    league: 'Ligue 1',
    marketValue: 250000,
  },
  {
    jerseyNumber: 11,
    name: 'Rony Lopes',
    dateOfBirth: '28.12.1995',
    nationality: 'Portugal',
    height: 1.74,
    foot: 'links',
    teamMemberSince: '24.08.2022',
    club: 'ESTAC Troyes',
    previousClub: 'FC Sevilla',
    uid: '1429b15d-f0f3-4dc6-8eb5-ad1c08cca0eb',
    position: 'Rechtsaußen',
    league: 'Ligue 1',
    marketValue: 4000000,
  },
  {
    jerseyNumber: 20,
    name: 'Renaud Ripart',
    dateOfBirth: '14.03.1993',
    nationality: 'Frankreich',
    height: 1.81,
    foot: 'rechts',
    teamMemberSince: '15.07.2021',
    club: 'ESTAC Troyes',
    previousClub: 'Nîmes Olympique',
    uid: 'f67a5a7a-ffba-4352-9571-fa37d04b17f1',
    position: 'Rechtsaußen',
    league: 'Ligue 1',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 7,
    name: 'Mama Baldé',
    dateOfBirth: '06.11.1995',
    nationality: 'Guinea-Bissau',
    height: 1.76,
    foot: 'rechts',
    teamMemberSince: '31.07.2021',
    club: 'ESTAC Troyes',
    previousClub: 'Dijon FCO',
    uid: '38b283f7-30b6-42db-b8c5-a85c68a29d1f',
    position: 'Mittelstürmer',
    league: 'Ligue 1',
    marketValue: 8000000,
  },
  {
    jerseyNumber: 9,
    name: 'Iké Ugbo',
    dateOfBirth: '21.09.1998',
    nationality: 'Kanada',
    height: 1.84,
    foot: 'rechts',
    teamMemberSince: '04.08.2022',
    club: 'ESTAC Troyes',
    previousClub: 'KRC Genk',
    uid: 'e82a4ba8-a11c-4b8d-8052-01c5c43127c4',
    position: 'Mittelstürmer',
    league: 'Ligue 1',
    marketValue: 4000000,
  },
  {
    jerseyNumber: 40,
    name: 'Marco Bizot',
    dateOfBirth: '10.03.1991',
    nationality: 'Niederlande',
    height: 1.93,
    foot: 'rechts',
    teamMemberSince: '04.08.2021',
    club: 'Stade Brest 29',
    previousClub: 'AZ Alkmaar',
    uid: '40d1e270-d04b-4628-b380-cc1d7ed9235b',
    position: 'Torwart',
    league: 'Ligue 1',
    marketValue: 4000000,
  },
  {
    jerseyNumber: 1,
    name: 'Joaquín Blázquez',
    dateOfBirth: '28.01.2001',
    nationality: 'Argentinien',
    height: 1.93,
    foot: 'rechts',
    teamMemberSince: '27.07.2022',
    club: 'Stade Brest 29',
    previousClub: 'Club Atlético Talleres',
    uid: 'e25932fd-6e33-4061-903d-784db519cb7b',
    position: 'Torwart',
    league: 'Ligue 1',
    marketValue: 500000,
  },
  {
    jerseyNumber: 30,
    name: 'Grégoire Coudert',
    dateOfBirth: '03.04.1999',
    nationality: 'Frankreich',
    height: 1.88,
    foot: 'links',
    teamMemberSince: '05.07.2021',
    club: 'Stade Brest 29',
    previousClub: 'Amiens SC',
    uid: '17a640d8-d8b5-4ebc-9f16-aaa3f7f13193',
    position: 'Torwart',
    league: 'Ligue 1',
    marketValue: 200000,
  },
  {
    jerseyNumber: 5,
    name: 'Brendan Chardonnet',
    dateOfBirth: '22.12.1994',
    nationality: 'Frankreich',
    height: 1.84,
    foot: 'rechts',
    teamMemberSince: '01.07.2013',
    club: 'Stade Brest 29',
    previousClub: 'Stade Brest 29 B',
    uid: 'e5458675-3ec7-42a6-bc92-47ee59a8c547',
    position: 'Innenverteidiger',
    league: 'Ligue 1',
    marketValue: 5000000,
  },
  {
    jerseyNumber: 3,
    name: 'Lilian Brassier',
    dateOfBirth: '02.11.1999',
    nationality: 'Frankreich',
    height: 1.86,
    foot: 'links',
    teamMemberSince: '01.07.2021',
    club: 'Stade Brest 29',
    previousClub: 'FC Stade Rennes',
    uid: '9a797a15-a4a5-4031-b825-be4ce57d9014',
    position: 'Innenverteidiger',
    league: 'Ligue 1',
    marketValue: 4000000,
  },
  {
    jerseyNumber: 4,
    name: 'Achraf Dari',
    dateOfBirth: '06.05.1999',
    nationality: 'Marokko',
    height: 1.88,
    foot: 'rechts',
    teamMemberSince: '29.07.2022',
    club: 'Stade Brest 29',
    previousClub: 'Wydad Casablanca',
    uid: 'e9db7b35-3cc7-45c7-87cd-eb02a9fd9ec7',
    position: 'Innenverteidiger',
    league: 'Ligue 1',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 23,
    name: 'Christophe Hérelle',
    dateOfBirth: '22.08.1992',
    nationality: 'Martinique',
    height: 1.88,
    foot: 'rechts',
    teamMemberSince: '11.08.2020',
    club: 'Stade Brest 29',
    previousClub: 'OGC Nizza',
    uid: 'b82f1734-6ea5-4ca1-b2cd-a3583f0edc59',
    position: 'Innenverteidiger',
    league: 'Ligue 1',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 2,
    name: 'Jean-Kévin Duverne',
    dateOfBirth: '12.07.1997',
    nationality: 'Frankreich',
    height: 1.84,
    foot: 'rechts',
    teamMemberSince: '02.09.2019',
    club: 'Stade Brest 29',
    previousClub: 'RC Lens',
    uid: '3c597def-a33d-4169-8b5c-c29ddf9ffe98',
    position: 'Linker Verteidiger',
    league: 'Ligue 1',
    marketValue: 4000000,
  },
  {
    jerseyNumber: 28,
    name: 'Bradley Locko',
    dateOfBirth: '06.05.2002',
    nationality: 'Frankreich',
    height: 1.8,
    foot: 'links',
    teamMemberSince: '31.01.2023',
    club: 'Stade Brest 29',
    previousClub: 'Stade Reims',
    uid: '2498e50a-074f-4fa3-9f11-5d95067986e3',
    position: 'Linker Verteidiger',
    league: 'Ligue 1',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 27,
    name: 'Kenny Lala',
    dateOfBirth: '03.10.1991',
    nationality: 'Frankreich',
    height: 1.78,
    foot: 'rechts',
    teamMemberSince: '01.01.2023',
    club: 'Stade Brest 29',
    previousClub: 'Olympiakos Piräus',
    uid: '6dd96cae-2945-43a8-ade3-045c26fb4d95',
    position: 'Rechter Verteidiger',
    league: 'Ligue 1',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 99,
    name: 'Noah Fadiga',
    dateOfBirth: '03.12.1999',
    nationality: 'Senegal',
    height: 1.87,
    foot: 'beidfüßig',
    teamMemberSince: '01.07.2022',
    club: 'Stade Brest 29',
    previousClub: 'Heracles Almelo',
    uid: '393f7f1c-df87-4955-945d-42e4689c3417',
    position: 'Rechter Verteidiger',
    league: 'Ligue 1',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 20,
    name: 'Pierre Lees-Melou',
    dateOfBirth: '25.05.1993',
    nationality: 'Frankreich',
    height: 1.85,
    foot: 'rechts',
    teamMemberSince: '23.07.2022',
    club: 'Stade Brest 29',
    previousClub: 'Norwich City',
    uid: 'a04771ae-9c6d-46a8-837f-25a41ecc8eea',
    position: 'Defensives Mittelfeld',
    league: 'Ligue 1',
    marketValue: 4000000,
  },
  {
    jerseyNumber: 7,
    name: 'Haris Belkebla',
    dateOfBirth: '28.01.1994',
    nationality: 'Algerien',
    height: 1.77,
    foot: 'rechts',
    teamMemberSince: '01.07.2018',
    club: 'Stade Brest 29',
    previousClub: 'FC Tours',
    uid: 'a0f9feed-380c-43e6-9543-7064e15f1b2d',
    position: 'Zentrales Mittelfeld',
    league: 'Ligue 1',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 45,
    name: 'Mahdi Camara',
    dateOfBirth: '30.06.1998',
    nationality: 'Frankreich',
    height: 1.78,
    foot: 'rechts',
    teamMemberSince: '26.08.2022',
    club: 'Stade Brest 29',
    previousClub: 'AS Saint-Étienne',
    uid: '91922527-b559-48a7-9e52-bf1296564730',
    position: 'Zentrales Mittelfeld',
    league: 'Ligue 1',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 8,
    name: 'Hugo Magnetti',
    dateOfBirth: '30.05.1998',
    nationality: 'Frankreich',
    height: 1.8,
    foot: 'rechts',
    teamMemberSince: '01.07.2018',
    club: 'Stade Brest 29',
    previousClub: 'Stade Brest 29 B',
    uid: '29c4a5c2-e768-4d90-be67-16e6c11f194c',
    position: 'Zentrales Mittelfeld',
    league: 'Ligue 1',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 37,
    name: 'Félix Lemaréchal',
    dateOfBirth: '07.08.2003',
    nationality: 'Frankreich',
    height: 1.8,
    foot: 'rechts',
    teamMemberSince: '31.01.2023',
    club: 'Stade Brest 29',
    previousClub: 'AS Monaco',
    uid: '572cb4ec-7411-4457-afa7-626e03247d64',
    position: 'Zentrales Mittelfeld',
    league: 'Ligue 1',
    marketValue: 700000,
  },
  {
    jerseyNumber: 10,
    name: 'Romain Del Castillo',
    dateOfBirth: '29.03.1996',
    nationality: 'Frankreich',
    height: 1.72,
    foot: 'links',
    teamMemberSince: '31.08.2021',
    club: 'Stade Brest 29',
    previousClub: 'FC Stade Rennes',
    uid: '0b91ff37-8d03-493c-b8ec-7a2e71f3c860',
    position: 'Rechtes Mittelfeld',
    league: 'Ligue 1',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 29,
    name: 'Mathias Pereira Lage',
    dateOfBirth: '30.11.1996',
    nationality: 'Frankreich',
    height: 1.8,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'Stade Brest 29',
    previousClub: 'Angers SCO',
    uid: '00b5a2f7-3924-492d-8535-173af49be179',
    position: 'Rechtes Mittelfeld',
    league: 'Ligue 1',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 22,
    name: 'Jérémy Le Douaron',
    dateOfBirth: '21.04.1998',
    nationality: 'Frankreich',
    height: 1.89,
    foot: 'links',
    teamMemberSince: '01.07.2020',
    club: 'Stade Brest 29',
    previousClub: 'Stade Briochin',
    uid: '5fd75abc-2279-4bea-8fd6-c1174011b564',
    position: 'Linksaußen',
    league: 'Ligue 1',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 9,
    name: 'Franck Honorat',
    dateOfBirth: '11.08.1996',
    nationality: 'Frankreich',
    height: 1.8,
    foot: 'rechts',
    teamMemberSince: '01.07.2020',
    club: 'Stade Brest 29',
    previousClub: ': Ablöse 5,00 Mio. €',
    uid: 'aba9d2e8-22eb-4e7d-b753-e38066759ef6',
    position: 'Rechtsaußen',
    league: 'Ligue 1',
    marketValue: 9000000,
  },
  {
    jerseyNumber: 17,
    name: 'Alberth Elis',
    dateOfBirth: '12.02.1996',
    nationality: 'Honduras',
    height: 1.85,
    foot: 'rechts',
    teamMemberSince: '30.01.2023',
    club: 'Stade Brest 29',
    previousClub: 'FC Girondins Bordeaux',
    uid: '9565f73a-99b1-4f28-a43e-30aa56d3c7f8',
    position: 'Rechtsaußen',
    league: 'Ligue 1',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 18,
    name: 'Karamoko Dembélé',
    dateOfBirth: '22.02.2003',
    nationality: 'England',
    height: 1.67,
    foot: 'links',
    teamMemberSince: '05.07.2022',
    club: 'Stade Brest 29',
    previousClub: 'Celtic Glasgow',
    uid: '4b5f330a-83dd-4ad6-9a8e-a31b9116afbd',
    position: 'Rechtsaußen',
    league: 'Ligue 1',
    marketValue: 500000,
  },
  {
    jerseyNumber: 15,
    name: 'Steve Mounié',
    dateOfBirth: '29.09.1994',
    nationality: 'Benin',
    height: 1.9,
    foot: 'rechts',
    teamMemberSince: '09.09.2020',
    club: 'Stade Brest 29',
    previousClub: 'Huddersfield Town',
    uid: 'd43987bc-5baa-47aa-974e-92eb186c1234',
    position: 'Mittelstürmer',
    league: 'Ligue 1',
    marketValue: 6000000,
  },
  {
    jerseyNumber: 14,
    name: 'Loïc Rémy',
    dateOfBirth: '02.01.1987',
    nationality: 'Frankreich',
    height: 1.85,
    foot: 'rechts',
    teamMemberSince: '01.02.2023',
    club: 'Stade Brest 29',
    previousClub: 'Vereinslos',
    uid: '35862f54-1f80-43d5-a809-7cbfa4883b73',
    position: 'Mittelstürmer',
    league: 'Ligue 1',
    marketValue: 150000,
  },
  {
    jerseyNumber: 1,
    name: 'Paul Bernardoni',
    dateOfBirth: '18.04.1997',
    nationality: 'Frankreich',
    height: 1.9,
    foot: 'links',
    teamMemberSince: '01.07.2020',
    club: 'Angers SCO',
    previousClub: ': Ablöse 7,50 Mio. €',
    uid: 'bc76920b-c513-46a9-8641-4a98713e6f39',
    position: 'Torwart',
    league: 'Ligue 1',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 30,
    name: 'Yahia Fofana',
    dateOfBirth: '21.08.2000',
    nationality: 'Frankreich',
    height: 1.94,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'Angers SCO',
    previousClub: 'AC Le Havre',
    uid: 'edf18f5c-95c6-4b17-a9ee-7e8dcd037a1b',
    position: 'Torwart',
    league: 'Ligue 1',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 16,
    name: 'Melvin Zinga',
    dateOfBirth: '16.03.2002',
    nationality: 'Frankreich',
    height: 1.87,
    foot: 'links',
    teamMemberSince: '01.07.2022',
    club: 'Angers SCO',
    previousClub: 'Angers SCO B',
    uid: '0e1f9c0e-6374-423a-89a4-2306db0b67d9',
    position: 'Torwart',
    league: 'Ligue 1',
    marketValue: 100000,
  },
  {
    jerseyNumber: 22,
    name: 'Cédric Hountondji',
    dateOfBirth: '19.01.1994',
    nationality: 'Benin',
    height: 1.94,
    foot: 'rechts',
    teamMemberSince: '18.07.2022',
    club: 'Angers SCO',
    previousClub: 'Clermont Foot 63',
    uid: 'ef49f18a-7677-43e3-ab46-e9e0250562ee',
    position: 'Innenverteidiger',
    league: 'Ligue 1',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 5,
    name: 'Miha Blazic',
    dateOfBirth: '08.05.1993',
    nationality: 'Slowenien',
    height: 1.85,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'Angers SCO',
    previousClub: 'Ferencvárosi TC',
    uid: 'd045f6cb-7fa1-499d-9265-7c3459af08c7',
    position: 'Innenverteidiger',
    league: 'Ligue 1',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 29,
    name: 'Ousmane Camara',
    dateOfBirth: '06.03.2003',
    nationality: 'Frankreich',
    height: 1.97,
    foot: 'rechts',
    teamMemberSince: '16.08.2022',
    club: 'Angers SCO',
    previousClub: 'Paris FC',
    uid: '8ed3038d-8f69-4769-b586-33435e01ef15',
    position: 'Innenverteidiger',
    league: 'Ligue 1',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 3,
    name: 'Souleyman Doumbia',
    dateOfBirth: '24.09.1996',
    nationality: 'Elfenbeinküste',
    height: 1.83,
    foot: 'links',
    teamMemberSince: '08.07.2020',
    club: 'Angers SCO',
    previousClub: 'FC Stade Rennes',
    uid: '9419ce27-6225-4ac2-96ad-155135f518a1',
    position: 'Linker Verteidiger',
    league: 'Ligue 1',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 8,
    name: 'Faouzi Ghoulam',
    dateOfBirth: '01.02.1991',
    nationality: 'Algerien',
    height: 1.84,
    foot: 'links',
    teamMemberSince: '31.01.2023',
    club: 'Angers SCO',
    previousClub: 'Vereinslos',
    uid: '68213713-e125-42b7-a91e-49cd50aa616f',
    position: 'Linker Verteidiger',
    league: 'Ligue 1',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 31,
    name: 'Ilyes Chetti',
    dateOfBirth: '22.01.1995',
    nationality: 'Algerien',
    height: 1.76,
    foot: 'links',
    teamMemberSince: '19.07.2022',
    club: 'Angers SCO',
    previousClub: 'Esperance Tunis',
    uid: '27b84a0d-6dda-4664-9f34-257d969322d8',
    position: 'Linker Verteidiger',
    league: 'Ligue 1',
    marketValue: 500000,
  },
  {
    jerseyNumber: 94,
    name: 'Yan Valery',
    dateOfBirth: '22.02.1999',
    nationality: 'Tunesien',
    height: 1.8,
    foot: 'rechts',
    teamMemberSince: '01.09.2022',
    club: 'Angers SCO',
    previousClub: 'FC Southampton',
    uid: '89981c44-6e53-4118-aaa7-ffb6d0eb6b8f',
    position: 'Rechter Verteidiger',
    league: 'Ligue 1',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 4,
    name: 'Halid Sabanovic',
    dateOfBirth: '22.08.1999',
    nationality: 'Bosnien-Herzegowina',
    height: 1.82,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'Angers SCO',
    previousClub: 'FK Sarajevo',
    uid: 'e0840306-5fd8-471f-8711-ae29a4fd2313',
    position: 'Rechter Verteidiger',
    league: 'Ligue 1',
    marketValue: 800000,
  },
  {
    jerseyNumber: 25,
    name: 'Abdoulaye Bamba',
    dateOfBirth: '25.04.1990',
    nationality: 'Elfenbeinküste',
    height: 1.82,
    foot: 'rechts',
    teamMemberSince: '01.01.2017',
    club: 'Angers SCO',
    previousClub: 'Vereinslos',
    uid: '0620c471-1b34-4813-bd04-b748c3f3712b',
    position: 'Rechter Verteidiger',
    league: 'Ligue 1',
    marketValue: 400000,
  },
  {
    jerseyNumber: 27,
    name: 'Ali Kalla',
    dateOfBirth: '23.04.1998',
    nationality: 'Frankreich',
    height: 1.72,
    foot: 'links',
    teamMemberSince: '01.07.2022',
    club: 'Angers SCO',
    previousClub: 'Angers SCO B',
    uid: '2d77a610-4606-4890-a6f7-6b39a8322264',
    position: 'Rechter Verteidiger',
    league: 'Ligue 1',
    marketValue: 100000,
  },
  {
    jerseyNumber: 2,
    name: 'Batista Mendy',
    dateOfBirth: '12.01.2000',
    nationality: 'Frankreich',
    height: 1.91,
    foot: 'rechts',
    teamMemberSince: '19.07.2021',
    club: 'Angers SCO',
    previousClub: 'FC Nantes',
    uid: 'c9ad1e48-6b6e-490a-91fd-ee2fa406104e',
    position: 'Defensives Mittelfeld',
    league: 'Ligue 1',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 12,
    name: 'Zinédine Ould Khaled',
    dateOfBirth: '14.01.2000',
    nationality: 'Frankreich',
    height: 1.95,
    foot: 'rechts',
    teamMemberSince: '29.07.2019',
    club: 'Angers SCO',
    previousClub: 'Angers SCO B',
    uid: '985ba244-50fc-416c-85ed-c59f3341ca02',
    position: 'Defensives Mittelfeld',
    league: 'Ligue 1',
    marketValue: 250000,
  },
  {
    jerseyNumber: 6,
    name: 'Nabil Bentaleb',
    dateOfBirth: '24.11.1994',
    nationality: 'Algerien',
    height: 1.87,
    foot: 'links',
    teamMemberSince: '06.01.2022',
    club: 'Angers SCO',
    previousClub: 'Vereinslos',
    uid: '020004e7-d5a6-4820-9f92-29253ae80dbe',
    position: 'Zentrales Mittelfeld',
    league: 'Ligue 1',
    marketValue: 7000000,
  },
  {
    jerseyNumber: 14,
    name: 'Yassin Belkhdim',
    dateOfBirth: '14.02.2002',
    nationality: 'Marokko',
    height: 1.75,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'Angers SCO',
    previousClub: 'Angers SCO B',
    uid: '9173190e-23b2-4787-82ae-015a4980d898',
    position: 'Zentrales Mittelfeld',
    league: 'Ligue 1',
    marketValue: 100000,
  },
  {
    jerseyNumber: 15,
    name: 'Pierrick Capelle',
    dateOfBirth: '15.04.1987',
    nationality: 'Frankreich',
    height: 1.81,
    foot: 'links',
    teamMemberSince: '01.07.2015',
    club: 'Angers SCO',
    previousClub: 'Clermont Foot 63',
    uid: '92574402-cc72-48bf-8cdc-676e218345e4',
    position: 'Linkes Mittelfeld',
    league: 'Ligue 1',
    marketValue: 300000,
  },
  {
    jerseyNumber: 10,
    name: 'Himad Abdelli',
    dateOfBirth: '17.11.1999',
    nationality: 'Frankreich',
    height: 1.85,
    foot: 'links',
    teamMemberSince: '01.07.2022',
    club: 'Angers SCO',
    previousClub: 'AC Le Havre',
    uid: '66256617-ee23-4a7e-91f5-8489ad4f1382',
    position: 'Offensives Mittelfeld',
    league: 'Ligue 1',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 26,
    name: 'Waniss Taïbi',
    dateOfBirth: '07.03.2002',
    nationality: 'Frankreich',
    height: 1.84,
    foot: 'beidfüßig',
    teamMemberSince: '01.07.2021',
    club: 'Angers SCO',
    previousClub: 'Angers SCO B',
    uid: '7f3bfdff-2064-4364-9a4f-b1555b1e7349',
    position: 'Offensives Mittelfeld',
    league: 'Ligue 1',
    marketValue: 500000,
  },
  {
    jerseyNumber: 28,
    name: 'Farid El Melali',
    dateOfBirth: '13.07.1997',
    nationality: 'Algerien',
    height: 1.68,
    foot: 'beidfüßig',
    teamMemberSince: '09.08.2018',
    club: 'Angers SCO',
    previousClub: 'Paradou AC',
    uid: '763df4d6-39d7-4e03-8507-dad79aa67dc9',
    position: 'Linksaußen',
    league: 'Ligue 1',
    marketValue: 700000,
  },
  {
    jerseyNumber: 18,
    name: 'Jason Mbock',
    dateOfBirth: '01.11.1999',
    nationality: 'Frankreich',
    height: 1.79,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'Angers SCO',
    previousClub: 'Angers SCO B',
    uid: '5e2de165-bafc-486a-834d-5cb2db93cbe6',
    position: 'Linksaußen',
    league: 'Ligue 1',
    marketValue: 200000,
  },
  {
    jerseyNumber: 19,
    name: 'Abdallah Sima',
    dateOfBirth: '17.06.2001',
    nationality: 'Senegal',
    height: 1.88,
    foot: 'rechts',
    teamMemberSince: '13.07.2022',
    club: 'Angers SCO',
    previousClub: 'Brighton & Hove Albion',
    uid: '36a77dbb-ae77-43b2-9ebe-a9e51517c36b',
    position: 'Rechtsaußen',
    league: 'Ligue 1',
    marketValue: 7000000,
  },
  {
    jerseyNumber: 92,
    name: 'Sada Thioub',
    dateOfBirth: '01.06.1995',
    nationality: 'Senegal',
    height: 1.79,
    foot: 'rechts',
    teamMemberSince: '23.07.2019',
    club: 'Angers SCO',
    previousClub: 'Nîmes Olympique',
    uid: '53d4fc40-d52b-49af-817c-3fec991fc507',
    position: 'Rechtsaußen',
    league: 'Ligue 1',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 7,
    name: 'Ibrahima Niane',
    dateOfBirth: '11.03.1999',
    nationality: 'Senegal',
    height: 1.87,
    foot: 'rechts',
    teamMemberSince: '31.01.2023',
    club: 'Angers SCO',
    previousClub: 'FC Metz',
    uid: 'a9094af2-7c87-482b-871c-beaa3f66b3c3',
    position: 'Mittelstürmer',
    league: 'Ligue 1',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 23,
    name: 'Adrien Hunou',
    dateOfBirth: '19.01.1994',
    nationality: 'Frankreich',
    height: 1.79,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'Angers SCO',
    previousClub: 'Minnesota United FC',
    uid: '9968e103-b301-405a-9a56-bd31de85a32e',
    position: 'Mittelstürmer',
    league: 'Ligue 1',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 11,
    name: 'Amine Salama',
    dateOfBirth: '18.07.2000',
    nationality: 'Frankreich',
    height: 1.92,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'Angers SCO',
    previousClub: 'USL Dunkerque',
    uid: '60a2f9a1-7dff-45cf-b0da-8eec283ef516',
    position: 'Mittelstürmer',
    league: 'Ligue 1',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 9,
    name: 'Loïs Diony',
    dateOfBirth: '20.12.1992',
    nationality: 'Frankreich',
    height: 1.83,
    foot: 'rechts',
    teamMemberSince: '10.09.2020',
    club: 'Angers SCO',
    previousClub: ': Ablöse ablösefrei',
    uid: '76a08add-45c5-450e-a70d-d085565d87d4',
    position: 'Mittelstürmer',
    league: 'Ligue 1',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 20,
    name: 'Ulrick Eneme-Ella',
    dateOfBirth: '22.05.2001',
    nationality: 'Gabun',
    height: 1.84,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'Angers SCO',
    previousClub: 'Brighton & Hove Albion U21',
    uid: '4fb8ab7e-7cff-4a78-92af-48d93ad76f93',
    position: 'Mittelstürmer',
    league: 'Ligue 1',
    marketValue: 250000,
  },
  {
    jerseyNumber: 1,
    name: 'Ionut Radu',
    dateOfBirth: '28.05.1997',
    nationality: 'Rumänien',
    height: 1.88,
    foot: 'rechts',
    teamMemberSince: '25.01.2023',
    club: 'AJ Auxerre',
    previousClub: 'Inter Mailand',
    uid: '5fd86d9c-3565-48a1-85a9-fceb2c86be9f',
    position: 'Torwart',
    league: 'Ligue 1',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 16,
    name: 'Donovan Léon',
    dateOfBirth: '03.11.1992',
    nationality: 'Französisch-Guayana',
    height: 1.87,
    foot: 'rechts',
    teamMemberSince: '01.07.2020',
    club: 'AJ Auxerre',
    previousClub: 'Stade Brest 29',
    uid: '83b37764-2ca9-42dc-8c35-faf0ddda0c85',
    position: 'Torwart',
    league: 'Ligue 1',
    marketValue: 600000,
  },
  {
    jerseyNumber: 40,
    name: 'Théo De Percin',
    dateOfBirth: '02.02.2001',
    nationality: 'Martinique',
    height: 1.85,
    foot: 'rechts',
    teamMemberSince: '17.03.2021',
    club: 'AJ Auxerre',
    previousClub: 'AJ Auxerre B',
    uid: '9cff6468-cd4d-4103-ba39-de5a7fa04f2c',
    position: 'Torwart',
    league: 'Ligue 1',
    marketValue: 150000,
  },
  {
    jerseyNumber: 95,
    name: 'Isaak Touré',
    dateOfBirth: '28.03.2003',
    nationality: 'Frankreich',
    height: 2.06,
    foot: 'links',
    teamMemberSince: '03.01.2023',
    club: 'AJ Auxerre',
    previousClub: 'Olympique Marseille',
    uid: 'e2ff5082-dab9-439c-b042-044247672d58',
    position: 'Innenverteidiger',
    league: 'Ligue 1',
    marketValue: 5000000,
  },
  {
    jerseyNumber: 4,
    name: 'Jubal',
    dateOfBirth: '29.08.1993',
    nationality: 'Brasilien',
    height: 1.9,
    foot: 'rechts',
    teamMemberSince: '03.09.2020',
    club: 'AJ Auxerre',
    previousClub: 'Vitória Setúbal FC',
    uid: '3c392d1e-5f06-42a1-a822-0ef422040310',
    position: 'Innenverteidiger',
    league: 'Ligue 1',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 5,
    name: 'Théo Pellenard',
    dateOfBirth: '04.03.1994',
    nationality: 'Frankreich',
    height: 1.82,
    foot: 'links',
    teamMemberSince: '01.07.2021',
    club: 'AJ Auxerre',
    previousClub: 'FC Valenciennes',
    uid: 'f0bb2c02-f4c2-42c0-9499-2daee18cbb86',
    position: 'Innenverteidiger',
    league: 'Ligue 1',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 27,
    name: 'Julian Jeanvier',
    dateOfBirth: '31.03.1992',
    nationality: 'Guinea',
    height: 1.83,
    foot: 'rechts',
    teamMemberSince: '02.08.2022',
    club: 'AJ Auxerre',
    previousClub: 'FC Brentford',
    uid: 'c9e6171b-81ee-43ae-8914-c5cd6e00eb42',
    position: 'Innenverteidiger',
    league: 'Ligue 1',
    marketValue: 800000,
  },
  {
    jerseyNumber: 32,
    name: 'Denys Bain',
    dateOfBirth: '02.07.1993',
    nationality: 'Frankreich',
    height: 1.81,
    foot: 'beidfüßig',
    teamMemberSince: '05.07.2022',
    club: 'AJ Auxerre',
    previousClub: 'Stade Brest 29',
    uid: '56a42a19-732e-40b4-b6ad-11062c5d8c83',
    position: 'Innenverteidiger',
    league: 'Ligue 1',
    marketValue: 800000,
  },
  {
    jerseyNumber: 14,
    name: 'Gideon Mensah',
    dateOfBirth: '18.07.1998',
    nationality: 'Ghana',
    height: 1.78,
    foot: 'links',
    teamMemberSince: '13.08.2022',
    club: 'AJ Auxerre',
    previousClub: 'Red Bull Salzburg',
    uid: 'a91497b2-8990-4569-b1b8-68f0ffbe230e',
    position: 'Linker Verteidiger',
    league: 'Ligue 1',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 24,
    name: 'Kenji-Van Boto',
    dateOfBirth: '07.03.1996',
    nationality: 'Madagaskar',
    height: 1.83,
    foot: 'links',
    teamMemberSince: '01.07.2014',
    club: 'AJ Auxerre',
    previousClub: 'AJ Auxerre U19',
    uid: 'e69228b1-d834-4892-a9bd-4c083e005917',
    position: 'Linker Verteidiger',
    league: 'Ligue 1',
    marketValue: 500000,
  },
  {
    jerseyNumber: 13,
    name: 'Akim Zedadka',
    dateOfBirth: '30.05.1995',
    nationality: 'Algerien',
    height: 1.73,
    foot: 'rechts',
    teamMemberSince: '09.12.2022',
    club: 'AJ Auxerre',
    previousClub: 'LOSC Lille',
    uid: 'a8781dec-96d2-4741-a79d-1146ecbe4583',
    position: 'Rechter Verteidiger',
    league: 'Ligue 1',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 42,
    name: 'Elisha Owusu',
    dateOfBirth: '07.11.1997',
    nationality: 'Ghana',
    height: 1.82,
    foot: 'rechts',
    teamMemberSince: '16.01.2023',
    club: 'AJ Auxerre',
    previousClub: 'KAA Gent',
    uid: '76d8087d-7852-48ae-a6b5-23eb3ae014ff',
    position: 'Defensives Mittelfeld',
    league: 'Ligue 1',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 12,
    name: 'Birama Touré',
    dateOfBirth: '06.06.1992',
    nationality: 'Mali',
    height: 1.83,
    foot: 'rechts',
    teamMemberSince: '17.07.2017',
    club: 'AJ Auxerre',
    previousClub: 'Standard Lüttich',
    uid: '4dbf9dec-d7c0-46d8-92ca-822930dcbeda',
    position: 'Defensives Mittelfeld',
    league: 'Ligue 1',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 97,
    name: 'Rayan Raveloson',
    dateOfBirth: '16.01.1997',
    nationality: 'Madagaskar',
    height: 1.83,
    foot: 'rechts',
    teamMemberSince: '04.08.2022',
    club: 'AJ Auxerre',
    previousClub: 'Los Angeles Galaxy',
    uid: '3f49f537-84a2-48b1-8c21-b7771cc53b1c',
    position: 'Defensives Mittelfeld',
    league: 'Ligue 1',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 8,
    name: 'Ousoumane Camara',
    dateOfBirth: '19.12.1998',
    nationality: 'Frankreich',
    height: 1.84,
    foot: 'rechts',
    teamMemberSince: '01.07.2019',
    club: 'AJ Auxerre',
    previousClub: 'AJ Auxerre B',
    uid: 'c067ae55-ba77-443a-83a3-1b8a54d6b815',
    position: 'Defensives Mittelfeld',
    league: 'Ligue 1',
    marketValue: 150000,
  },
  {
    jerseyNumber: 80,
    name: 'Han-Noah Massengo',
    dateOfBirth: '07.07.2001',
    nationality: 'Frankreich',
    height: 1.78,
    foot: 'rechts',
    teamMemberSince: '30.01.2023',
    club: 'AJ Auxerre',
    previousClub: 'Bristol City',
    uid: 'b8432874-82d3-4e94-b298-3d956625b4f5',
    position: 'Zentrales Mittelfeld',
    league: 'Ligue 1',
    marketValue: 4000000,
  },
  {
    jerseyNumber: 18,
    name: 'Kays Ruiz-Atil',
    dateOfBirth: '26.08.2002',
    nationality: 'Marokko',
    height: 1.77,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'AJ Auxerre',
    previousClub: 'Vereinslos',
    uid: '44a2da1e-daf1-4529-9dd2-1a98a02bcde1',
    position: 'Zentrales Mittelfeld',
    league: 'Ligue 1',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 10,
    name: 'Gaëtan Perrin',
    dateOfBirth: '07.06.1996',
    nationality: 'Frankreich',
    height: 1.69,
    foot: 'rechts',
    teamMemberSince: '01.07.2021',
    club: 'AJ Auxerre',
    previousClub: 'US Orléans',
    uid: 'dbefd0c0-687a-4e88-83c0-a5f89552df31',
    position: 'Rechtes Mittelfeld',
    league: 'Ligue 1',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 22,
    name: 'Hamza Sakhi',
    dateOfBirth: '07.06.1996',
    nationality: 'Marokko',
    height: 1.76,
    foot: 'rechts',
    teamMemberSince: '16.04.2018',
    club: 'AJ Auxerre',
    previousClub: ': Ablöse ?',
    uid: '0b5bb1ae-a872-4b8e-8c77-639b626529f2',
    position: 'Offensives Mittelfeld',
    league: 'Ligue 1',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 6,
    name: "Youssouf M'Changama",
    dateOfBirth: '29.08.1990',
    nationality: 'Komoren',
    height: 1.75,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'AJ Auxerre',
    previousClub: 'EA Guingamp',
    uid: '44b75e0b-f9db-46ce-a9c0-2ad74f8c9018',
    position: 'Offensives Mittelfeld',
    league: 'Ligue 1',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 29,
    name: 'Mathias Autret',
    dateOfBirth: '01.03.1991',
    nationality: 'Frankreich',
    height: 1.79,
    foot: 'rechts',
    teamMemberSince: '01.07.2020',
    club: 'AJ Auxerre',
    previousClub: 'Stade Brest 29',
    uid: 'a267a7fa-d7a8-4ab6-a95e-8356199c6faf',
    position: 'Offensives Mittelfeld',
    league: 'Ligue 1',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 77,
    name: 'Siriki Dembélé',
    dateOfBirth: '07.09.1996',
    nationality: 'Schottland',
    height: 1.73,
    foot: 'rechts',
    teamMemberSince: '31.01.2023',
    club: 'AJ Auxerre',
    previousClub: 'AFC Bournemouth',
    uid: 'f27e6197-76d3-420a-8c0a-9ea96a3b9d9a',
    position: 'Linksaußen',
    league: 'Ligue 1',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 21,
    name: 'Rémy Dugimont',
    dateOfBirth: '01.07.1986',
    nationality: 'Frankreich',
    height: 1.82,
    foot: 'rechts',
    teamMemberSince: '13.08.2018',
    club: 'AJ Auxerre',
    previousClub: 'Clermont Foot 63',
    uid: '801b59d6-d8f7-4d55-a49f-28b9df5c2a34',
    position: 'Linksaußen',
    league: 'Ligue 1',
    marketValue: 300000,
  },
  {
    jerseyNumber: 7,
    name: 'Gauthier Hein',
    dateOfBirth: '07.08.1996',
    nationality: 'Frankreich',
    height: 1.7,
    foot: 'links',
    teamMemberSince: '01.07.2020',
    club: 'AJ Auxerre',
    previousClub: ': Ablöse ?',
    uid: '1202c8b3-d61d-4299-8fe3-a9199f16d23a',
    position: 'Rechtsaußen',
    league: 'Ligue 1',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 17,
    name: 'Lassine Sinayoko',
    dateOfBirth: '08.12.1999',
    nationality: 'Mali',
    height: 1.86,
    foot: 'rechts',
    teamMemberSince: '01.07.2019',
    club: 'AJ Auxerre',
    previousClub: 'AJ Auxerre B',
    uid: '5a4eb877-c3eb-4fc5-9d66-d48f9ba509b0',
    position: 'Rechtsaußen',
    league: 'Ligue 1',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 19,
    name: 'Matthis Abline',
    dateOfBirth: '28.03.2003',
    nationality: 'Frankreich',
    height: 1.82,
    foot: 'rechts',
    teamMemberSince: '02.01.2023',
    club: 'AJ Auxerre',
    previousClub: 'FC Stade Rennes',
    uid: 'cd5f2bd5-2735-4f4a-81d0-abab078cd570',
    position: 'Mittelstürmer',
    league: 'Ligue 1',
    marketValue: 4000000,
  },
  {
    jerseyNumber: 11,
    name: "M'Baye Niang",
    dateOfBirth: '19.12.1994',
    nationality: 'Senegal',
    height: 1.88,
    foot: 'rechts',
    teamMemberSince: '16.08.2022',
    club: 'AJ Auxerre',
    previousClub: 'FC Girondins Bordeaux',
    uid: '581076e2-57eb-4f21-96d3-1b26e24e1232',
    position: 'Mittelstürmer',
    league: 'Ligue 1',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 9,
    name: 'Nuno Da Costa',
    dateOfBirth: '10.02.1991',
    nationality: 'Kap Verde',
    height: 1.82,
    foot: 'rechts',
    teamMemberSince: '05.08.2022',
    club: 'AJ Auxerre',
    previousClub: 'Nottingham Forest',
    uid: '5de24ab1-5664-4f3a-b037-93a9d51020ea',
    position: 'Mittelstürmer',
    league: 'Ligue 1',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 99,
    name: 'Mory Diaw',
    dateOfBirth: '22.06.1993',
    nationality: 'Senegal',
    height: 1.95,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'Clermont Foot 63',
    previousClub: 'FC Lausanne-Sport',
    uid: '9dffef38-ba2f-4fe5-82fa-fc3cb8b2c35d',
    position: 'Torwart',
    league: 'Ligue 1',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 40,
    name: 'Ouparine Djoco',
    dateOfBirth: '22.04.1998',
    nationality: 'Frankreich',
    height: 1.88,
    foot: 'links',
    teamMemberSince: '01.07.2018',
    club: 'Clermont Foot 63',
    previousClub: 'Clermont Foot 63 B',
    uid: '4af742d5-49b1-42d3-9526-897a982ccdd4',
    position: 'Torwart',
    league: 'Ligue 1',
    marketValue: 600000,
  },
  {
    jerseyNumber: 16,
    name: 'Lucas Margueron',
    dateOfBirth: '12.02.2001',
    nationality: 'Frankreich',
    height: 1.94,
    foot: 'rechts',
    teamMemberSince: '02.07.2020',
    club: 'Clermont Foot 63',
    previousClub: 'Olympique Lyon B',
    uid: 'b224384f-9dc8-46b1-b61a-df1db4164980',
    position: 'Torwart',
    league: 'Ligue 1',
    marketValue: 150000,
  },
  {
    jerseyNumber: 36,
    name: 'Alidu Seidu',
    dateOfBirth: '04.06.2000',
    nationality: 'Ghana',
    height: 1.73,
    foot: 'rechts',
    teamMemberSince: '01.07.2020',
    club: 'Clermont Foot 63',
    previousClub: 'Clermont Foot 63 B',
    uid: '5c27eccc-53ae-44cd-b649-1219d34faa78',
    position: 'Innenverteidiger',
    league: 'Ligue 1',
    marketValue: 5000000,
  },
  {
    jerseyNumber: 4,
    name: 'Mateusz Wieteska',
    dateOfBirth: '11.02.1997',
    nationality: 'Polen',
    height: 1.87,
    foot: 'rechts',
    teamMemberSince: '25.07.2022',
    club: 'Clermont Foot 63',
    previousClub: 'Legia Warschau',
    uid: '5613720a-ff8c-4382-83d9-2b1257a9045a',
    position: 'Innenverteidiger',
    league: 'Ligue 1',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 5,
    name: 'Maximiliano Caufriez',
    dateOfBirth: '16.02.1997',
    nationality: 'Belgien',
    height: 1.89,
    foot: 'rechts',
    teamMemberSince: '29.08.2022',
    club: 'Clermont Foot 63',
    previousClub: 'Spartak Moskau',
    uid: '271d3989-b2f5-4d37-bb4d-0d1e5bf2ca72',
    position: 'Innenverteidiger',
    league: 'Ligue 1',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 21,
    name: 'Florent Ogier',
    dateOfBirth: '21.03.1989',
    nationality: 'Frankreich',
    height: 1.83,
    foot: 'rechts',
    teamMemberSince: '13.07.2018',
    club: 'Clermont Foot 63',
    previousClub: 'FC Sochaux-Montbéliard',
    uid: 'fa4d4c3b-12e1-4cdf-ac48-bbabc6d8c2dc',
    position: 'Innenverteidiger',
    league: 'Ligue 1',
    marketValue: 500000,
  },
  {
    jerseyNumber: 3,
    name: 'Neto Borges',
    dateOfBirth: '13.09.1996',
    nationality: 'Brasilien',
    height: 1.85,
    foot: 'links',
    teamMemberSince: '01.07.2022',
    club: 'Clermont Foot 63',
    previousClub: 'KRC Genk',
    uid: 'f6663506-4a90-4ab6-a712-d2b719c820f4',
    position: 'Linker Verteidiger',
    league: 'Ligue 1',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 31,
    name: 'Baïla Diallo',
    dateOfBirth: '24.06.2001',
    nationality: 'Senegal',
    height: 1.76,
    foot: 'links',
    teamMemberSince: '22.01.2021',
    club: 'Clermont Foot 63',
    previousClub: 'Clermont Foot 63 B',
    uid: '57654e40-9e4f-4235-86d4-94e5e5c52f4e',
    position: 'Linker Verteidiger',
    league: 'Ligue 1',
    marketValue: 250000,
  },
  {
    jerseyNumber: 2,
    name: 'Mehdi Zeffane',
    dateOfBirth: '19.05.1992',
    nationality: 'Algerien',
    height: 1.76,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'Clermont Foot 63',
    previousClub: 'Vereinslos',
    uid: 'f6333679-dcb5-42d1-9b0a-094679377700',
    position: 'Rechter Verteidiger',
    league: 'Ligue 1',
    marketValue: 500000,
  },
  {
    jerseyNumber: 7,
    name: 'Yohann Magnin',
    dateOfBirth: '21.06.1997',
    nationality: 'Frankreich',
    height: 1.83,
    foot: 'rechts',
    teamMemberSince: '25.01.2019',
    club: 'Clermont Foot 63',
    previousClub: 'Clermont Foot 63 B',
    uid: '21dc57c0-e65f-4834-a551-03e589c7bc6d',
    position: 'Defensives Mittelfeld',
    league: 'Ligue 1',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 12,
    name: 'Maxime Gonalons',
    dateOfBirth: '10.03.1989',
    nationality: 'Frankreich',
    height: 1.87,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'Clermont Foot 63',
    previousClub: 'FC Granada',
    uid: '6c4e59e0-0c35-4030-9c14-5c7c83ff6821',
    position: 'Defensives Mittelfeld',
    league: 'Ligue 1',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 25,
    name: 'Johan Gastien',
    dateOfBirth: '25.01.1988',
    nationality: 'Frankreich',
    height: 1.79,
    foot: 'rechts',
    teamMemberSince: '01.07.2018',
    club: 'Clermont Foot 63',
    previousClub: 'Stade Brest 29',
    uid: 'aac3f948-2fbd-4718-8615-d1b5998c4608',
    position: 'Defensives Mittelfeld',
    league: 'Ligue 1',
    marketValue: 800000,
  },
  {
    jerseyNumber: 97,
    name: 'Yanis Massolin',
    dateOfBirth: '20.09.2002',
    nationality: 'Frankreich',
    height: 1.97,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'Clermont Foot 63',
    previousClub: 'Moulins-Yzeure Foot 03',
    uid: '2ff05c9c-1d98-44d7-85a4-dd9bb762a335',
    position: 'Zentrales Mittelfeld',
    league: 'Ligue 1',
    marketValue: 100000,
  },
  {
    jerseyNumber: 11,
    name: 'Jim Allevinah',
    dateOfBirth: '27.02.1995',
    nationality: 'Gabun',
    height: 1.72,
    foot: 'rechts',
    teamMemberSince: '01.07.2019',
    club: 'Clermont Foot 63',
    previousClub: 'Le Puy Foot 43 Auvergne',
    uid: '4fa0d131-fab3-4e24-91e5-8a6e226a55f4',
    position: 'Rechtes Mittelfeld',
    league: 'Ligue 1',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 70,
    name: 'Muhammed Cham',
    dateOfBirth: '26.09.2000',
    nationality: 'Österreich',
    height: 1.8,
    foot: 'links',
    teamMemberSince: '04.10.2020',
    club: 'Clermont Foot 63',
    previousClub: 'FC Admira Wacker Mödling',
    uid: '9d791c61-7c02-4d0a-9b39-3f249e06147b',
    position: 'Offensives Mittelfeld',
    league: 'Ligue 1',
    marketValue: 5000000,
  },
  {
    jerseyNumber: 10,
    name: 'Saîf-Eddine Khaoui',
    dateOfBirth: '27.04.1995',
    nationality: 'Tunesien',
    height: 1.8,
    foot: 'links',
    teamMemberSince: '14.08.2021',
    club: 'Clermont Foot 63',
    previousClub: 'Olympique Marseille',
    uid: '3a664d16-04fd-4bfc-aebf-3efe4068393e',
    position: 'Offensives Mittelfeld',
    league: 'Ligue 1',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 22,
    name: 'Aïman Maurer',
    dateOfBirth: '25.09.2004',
    nationality: 'Marokko',
    height: 1.73,
    foot: 'rechts',
    teamMemberSince: '01.02.2023',
    club: 'Clermont Foot 63',
    previousClub: 'Clermont Foot 63 B',
    uid: '346dd01c-8603-41cb-bfd5-0c4503f8b125',
    position: 'Offensives Mittelfeld',
    league: 'Ligue 1',
    marketValue: 500000,
  },
  {
    jerseyNumber: 18,
    name: 'Elbasan Rashani',
    dateOfBirth: '09.05.1993',
    nationality: 'Kosovo',
    height: 1.81,
    foot: 'links',
    teamMemberSince: '16.07.2021',
    club: 'Clermont Foot 63',
    previousClub: ': Ablöse ablösefrei',
    uid: '51a01d4b-4e46-4481-97d4-41e015531c54',
    position: 'Linksaußen',
    league: 'Ligue 1',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 91,
    name: 'Jérémie Bela',
    dateOfBirth: '08.04.1993',
    nationality: 'Angola',
    height: 1.73,
    foot: 'rechts',
    teamMemberSince: '02.08.2022',
    club: 'Clermont Foot 63',
    previousClub: 'Birmingham City',
    uid: 'a8e87ed5-43df-4ed0-8bf6-4aef57a20742',
    position: 'Linksaußen',
    league: 'Ligue 1',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 9,
    name: 'Komnen Andric',
    dateOfBirth: '01.07.1995',
    nationality: 'Serbien',
    height: 1.89,
    foot: 'rechts',
    teamMemberSince: '15.07.2022',
    club: 'Clermont Foot 63',
    previousClub: 'GNK Dinamo Zagreb',
    uid: '060d02e2-107e-4fbd-b79b-e239378e9f4b',
    position: 'Mittelstürmer',
    league: 'Ligue 1',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 95,
    name: 'Grejohn Kyei',
    dateOfBirth: '12.08.1995',
    nationality: 'Frankreich',
    height: 1.87,
    foot: 'rechts',
    teamMemberSince: '31.01.2022',
    club: 'Clermont Foot 63',
    previousClub: 'Servette FC',
    uid: '78f43ec8-1ee9-47b7-90f7-2be364c22d73',
    position: 'Mittelstürmer',
    league: 'Ligue 1',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 1,
    name: 'Benjamin Leroy',
    dateOfBirth: '07.04.1989',
    nationality: 'Frankreich',
    height: 1.84,
    foot: 'rechts',
    teamMemberSince: '01.07.2018',
    club: 'AC Ajaccio',
    previousClub: 'Dijon FCO',
    uid: '515b488a-6b4c-4562-84ea-f3152a3f7afe',
    position: 'Torwart',
    league: 'Ligue 1',
    marketValue: 600000,
  },
  {
    jerseyNumber: 16,
    name: 'François-Joseph Sollacaro',
    dateOfBirth: '21.03.1994',
    nationality: 'Frankreich',
    height: 1.82,
    foot: 'rechts',
    teamMemberSince: '01.07.2018',
    club: 'AC Ajaccio',
    previousClub: 'AC Ajaccio B',
    uid: '59d84581-d400-41e0-bd48-6f14fef32a21',
    position: 'Torwart',
    league: 'Ligue 1',
    marketValue: 300000,
  },
  {
    jerseyNumber: 25,
    name: 'Oumar Gonzalez',
    dateOfBirth: '25.02.1998',
    nationality: 'Kamerun',
    height: 1.86,
    foot: 'rechts',
    teamMemberSince: '01.07.2021',
    club: 'AC Ajaccio',
    previousClub: 'FC Chambly Oise',
    uid: '08b64bd8-b497-4d46-9bf6-28caaade4adb',
    position: 'Innenverteidiger',
    league: 'Ligue 1',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 15,
    name: 'Clément Vidal',
    dateOfBirth: '18.06.2000',
    nationality: 'Frankreich',
    height: 1.81,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'AC Ajaccio',
    previousClub: 'HSC Montpellier',
    uid: '81214776-f454-4eb5-9385-bd6d622e2a09',
    position: 'Innenverteidiger',
    league: 'Ligue 1',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 77,
    name: 'Fernand Mayembo',
    dateOfBirth: '09.01.1996',
    nationality: 'Kongo',
    height: 1.84,
    foot: 'rechts',
    teamMemberSince: '26.07.2022',
    club: 'AC Ajaccio',
    previousClub: 'AC Le Havre',
    uid: '197f496c-a133-4bf3-a4a8-e7fc6997388a',
    position: 'Innenverteidiger',
    league: 'Ligue 1',
    marketValue: 700000,
  },
  {
    jerseyNumber: 21,
    name: 'Cédric Avinel',
    dateOfBirth: '11.09.1986',
    nationality: 'Guadeloupe',
    height: 1.87,
    foot: 'rechts',
    teamMemberSince: '01.07.2017',
    club: 'AC Ajaccio',
    previousClub: 'Clermont Foot 63',
    uid: '2f666696-c9ba-474b-afb5-6684373c496a',
    position: 'Innenverteidiger',
    league: 'Ligue 1',
    marketValue: 300000,
  },
  {
    jerseyNumber: 99,
    name: 'Youssouf Koné',
    dateOfBirth: '05.07.1995',
    nationality: 'Mali',
    height: 1.74,
    foot: 'links',
    teamMemberSince: '30.08.2022',
    club: 'AC Ajaccio',
    previousClub: 'Olympique Lyon',
    uid: 'f01ff97d-9e63-481d-86ac-5155920af59a',
    position: 'Linker Verteidiger',
    league: 'Ligue 1',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 3,
    name: 'Ismaël Diallo',
    dateOfBirth: '29.01.1997',
    nationality: 'Elfenbeinküste',
    height: 1.78,
    foot: 'links',
    teamMemberSince: '01.07.2018',
    club: 'AC Ajaccio',
    previousClub: 'SC Bastia',
    uid: '032d8ed8-df6e-49c6-8869-368b636e5d1a',
    position: 'Linker Verteidiger',
    league: 'Ligue 1',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 44,
    name: 'Chaker Alhadhur',
    dateOfBirth: '04.12.1991',
    nationality: 'Komoren',
    height: 1.72,
    foot: 'rechts',
    teamMemberSince: '12.10.2021',
    club: 'AC Ajaccio',
    previousClub: 'Vereinslos',
    uid: 'e90c5562-2c03-4a60-8a4c-66d087fb32bf',
    position: 'Linker Verteidiger',
    league: 'Ligue 1',
    marketValue: 200000,
  },
  {
    jerseyNumber: 2,
    name: 'Mickaël Alphonse',
    dateOfBirth: '12.07.1989',
    nationality: 'Guadeloupe',
    height: 1.82,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'AC Ajaccio',
    previousClub: 'Maccabi Haifa',
    uid: 'c56c7b89-80be-4036-a604-5de7281266b0',
    position: 'Rechter Verteidiger',
    league: 'Ligue 1',
    marketValue: 300000,
  },
  {
    jerseyNumber: 20,
    name: 'Mohamed Youssouf',
    dateOfBirth: '26.03.1988',
    nationality: 'Komoren',
    height: 1.69,
    foot: 'rechts',
    teamMemberSince: '01.07.2018',
    club: 'AC Ajaccio',
    previousClub: 'APO Levadiakos',
    uid: 'a9d9aa87-8401-4054-9d86-6ed911070486',
    position: 'Rechter Verteidiger',
    league: 'Ligue 1',
    marketValue: 200000,
  },
  {
    jerseyNumber: 8,
    name: 'Vincent Marchetti',
    dateOfBirth: '04.07.1997',
    nationality: 'Frankreich',
    height: 1.81,
    foot: 'links',
    teamMemberSince: '05.10.2020',
    club: 'AC Ajaccio',
    previousClub: 'AS Nancy-Lorraine',
    uid: 'aa9c3b6c-0f82-425f-b775-c21188d8fe24',
    position: 'Zentrales Mittelfeld',
    league: 'Ligue 1',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 23,
    name: 'Thomas Mangani',
    dateOfBirth: '29.04.1987',
    nationality: 'Frankreich',
    height: 1.83,
    foot: 'links',
    teamMemberSince: '01.07.2022',
    club: 'AC Ajaccio',
    previousClub: 'Angers SCO',
    uid: 'b1f60edb-b493-4b19-a63b-652194d7a216',
    position: 'Zentrales Mittelfeld',
    league: 'Ligue 1',
    marketValue: 600000,
  },
  {
    jerseyNumber: 6,
    name: 'Mathieu Coutadeur',
    dateOfBirth: '20.03.1986',
    nationality: 'Frankreich',
    height: 1.69,
    foot: 'rechts',
    teamMemberSince: '01.07.2017',
    club: 'AC Ajaccio',
    previousClub: 'Stade Laval',
    uid: 'cf2f723b-7281-49ea-a219-15b7d2abf15d',
    position: 'Zentrales Mittelfeld',
    league: 'Ligue 1',
    marketValue: 250000,
  },
  {
    jerseyNumber: 19,
    name: "Alassane N'Diaye",
    dateOfBirth: '14.06.1991',
    nationality: 'Frankreich',
    height: 1.8,
    foot: 'rechts',
    teamMemberSince: '01.07.2020',
    club: 'AC Ajaccio',
    previousClub: 'Clermont Foot 63',
    uid: '36196905-1a0e-4a4f-800b-30f3cab23984',
    position: 'Rechtes Mittelfeld',
    league: 'Ligue 1',
    marketValue: 200000,
  },
  {
    jerseyNumber: 22,
    name: 'Yanis Cimignani',
    dateOfBirth: '22.01.2002',
    nationality: 'Frankreich',
    height: 1.76,
    foot: 'links',
    teamMemberSince: '01.07.2020',
    club: 'AC Ajaccio',
    previousClub: 'AC Ajaccio B',
    uid: 'a9b8c90e-4690-4db1-bb2e-96aa1e2f842d',
    position: 'Linkes Mittelfeld',
    league: 'Ligue 1',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 4,
    name: 'Michaël Barreto',
    dateOfBirth: '18.01.1991',
    nationality: 'Frankreich',
    height: 1.75,
    foot: 'rechts',
    teamMemberSince: '01.07.2020',
    club: 'AC Ajaccio',
    previousClub: 'AJ Auxerre',
    uid: '47f9a806-33a3-40f5-92b2-14d082b4ab54',
    position: 'Offensives Mittelfeld',
    league: 'Ligue 1',
    marketValue: 500000,
  },
  {
    jerseyNumber: 29,
    name: 'Florian Chabrolle',
    dateOfBirth: '07.04.1998',
    nationality: 'Frankreich',
    height: 1.71,
    foot: 'rechts',
    teamMemberSince: '05.01.2021',
    club: 'AC Ajaccio',
    previousClub: 'Olympique Marseille',
    uid: '7bf50a88-97da-4765-94dc-3ebcfa2fc2d2',
    position: 'Offensives Mittelfeld',
    league: 'Ligue 1',
    marketValue: 250000,
  },
  {
    jerseyNumber: 10,
    name: 'Youcef Belaïli',
    dateOfBirth: '14.03.1992',
    nationality: 'Algerien',
    height: 1.82,
    foot: 'rechts',
    teamMemberSince: '12.10.2022',
    club: 'AC Ajaccio',
    previousClub: 'Vereinslos',
    uid: '765e6122-e2f3-467d-b7d9-52cda7ac7c40',
    position: 'Linksaußen',
    league: 'Ligue 1',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 27,
    name: 'Kevin Spadanuda',
    dateOfBirth: '16.01.1997',
    nationality: 'Schweiz',
    height: 1.77,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'AC Ajaccio',
    previousClub: 'FC Aarau',
    uid: '5f77c1dd-2950-407a-8dbd-a0f6197e4dee',
    position: 'Linksaußen',
    league: 'Ligue 1',
    marketValue: 800000,
  },
  {
    jerseyNumber: 18,
    name: 'Jean Botué',
    dateOfBirth: '07.08.2002',
    nationality: 'Burkina Faso',
    height: 1.84,
    foot: 'links',
    teamMemberSince: '16.02.2021',
    club: 'AC Ajaccio',
    previousClub: 'USFA Ouagadougou',
    uid: '3fb7574b-f1be-4391-962f-bf8ac2ccf075',
    position: 'Linksaußen',
    league: 'Ligue 1',
    marketValue: 500000,
  },
  {
    jerseyNumber: 14,
    name: 'Cyrille Bayala',
    dateOfBirth: '24.05.1996',
    nationality: 'Burkina Faso',
    height: 1.81,
    foot: 'rechts',
    teamMemberSince: '15.01.2021',
    club: 'AC Ajaccio',
    previousClub: 'RC Lens',
    uid: '8177640c-b2f6-47f3-bd0c-912d29f0a8dc',
    position: 'Rechtsaußen',
    league: 'Ligue 1',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 5,
    name: 'Riad Nouri',
    dateOfBirth: '07.06.1985',
    nationality: 'Frankreich',
    height: 1.78,
    foot: 'rechts',
    teamMemberSince: '07.09.2020',
    club: 'AC Ajaccio',
    previousClub: 'Ümraniyespor',
    uid: '2cac5acc-c40b-4257-a506-d2c0e042c225',
    position: 'Rechtsaußen',
    league: 'Ligue 1',
    marketValue: 250000,
  },
  {
    jerseyNumber: 24,
    name: 'Ruan Levine',
    dateOfBirth: '19.01.1999',
    nationality: 'Brasilien',
    height: 1.77,
    foot: 'rechts',
    teamMemberSince: '20.09.2022',
    club: 'AC Ajaccio',
    previousClub: 'SSA FC',
    uid: '172fd1a6-d881-42a4-bab5-47cfb9701dfb',
    position: 'Rechtsaußen',
    league: 'Ligue 1',
    marketValue: 100000,
  },
  {
    jerseyNumber: 28,
    name: 'Moussa Djitté',
    dateOfBirth: '04.10.1999',
    nationality: 'Senegal',
    height: 1.8,
    foot: 'rechts',
    teamMemberSince: '31.01.2023',
    club: 'AC Ajaccio',
    previousClub: 'Austin FC',
    uid: '308cb8e3-b155-4929-a366-90feabe8ffe0',
    position: 'Mittelstürmer',
    league: 'Ligue 1',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 7,
    name: 'Mounaïm El Idrissy',
    dateOfBirth: '10.02.1999',
    nationality: 'Frankreich',
    height: 1.81,
    foot: 'rechts',
    teamMemberSince: '01.07.2019',
    club: 'AC Ajaccio',
    previousClub: 'AC Ajaccio B',
    uid: '0471be72-2aba-4e3a-9e95-5f71c3d880fc',
    position: 'Mittelstürmer',
    league: 'Ligue 1',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 17,
    name: 'Romain Hamouma',
    dateOfBirth: '29.03.1987',
    nationality: 'Frankreich',
    height: 1.79,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'AC Ajaccio',
    previousClub: 'AS Saint-Étienne',
    uid: 'b0d44301-fe37-4cfd-976c-f25b6f1d8610',
    position: 'Mittelstürmer',
    league: 'Ligue 1',
    marketValue: 600000,
  },
  {
    jerseyNumber: 9,
    name: 'Yoann Touzghar',
    dateOfBirth: '28.11.1986',
    nationality: 'Tunesien',
    height: 1.8,
    foot: 'rechts',
    teamMemberSince: '24.08.2022',
    club: 'AC Ajaccio',
    previousClub: 'ESTAC Troyes',
    uid: 'd8355fb6-6e83-41b6-912b-2b7e91ae4f62',
    position: 'Mittelstürmer',
    league: 'Ligue 1',
    marketValue: 500000,
  },
  {
    jerseyNumber: 1,
    name: 'Alex Meret',
    dateOfBirth: '22.03.1997',
    nationality: 'Italien',
    height: 1.9,
    foot: 'links',
    teamMemberSince: '01.07.2019',
    club: 'SSC Neapel',
    previousClub: 'Udinese Calcio',
    uid: '3abd4751-b490-4b1b-bd9f-ec4e7b30a41f',
    position: 'Torwart',
    league: 'Serie A',
    marketValue: 18000000,
  },
  {
    jerseyNumber: 95,
    name: 'Pierluigi Gollini',
    dateOfBirth: '18.03.1995',
    nationality: 'Italien',
    height: 1.94,
    foot: 'rechts',
    teamMemberSince: '25.01.2023',
    club: 'SSC Neapel',
    previousClub: 'Atalanta Bergamo',
    uid: 'e64a583a-f38d-4cf7-8908-8f1274a49de7',
    position: 'Torwart',
    league: 'Serie A',
    marketValue: 5000000,
  },
  {
    jerseyNumber: 16,
    name: 'Hubert Idasiak',
    dateOfBirth: '03.02.2002',
    nationality: 'Polen',
    height: 1.88,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'SSC Neapel',
    previousClub: 'SSC Neapel U19',
    uid: '62669592-44b3-4540-9db3-b15c8909c7f5',
    position: 'Torwart',
    league: 'Serie A',
    marketValue: 150000,
  },
  {
    jerseyNumber: 12,
    name: 'Davide Marfella',
    dateOfBirth: '15.09.1999',
    nationality: 'Italien',
    height: 1.82,
    foot: 'rechts',
    teamMemberSince: '19.08.2021',
    club: 'SSC Neapel',
    previousClub: 'SSC Bari',
    uid: 'f54b8247-3c0c-4fe5-99ac-6d56844a4e90',
    position: 'Torwart',
    league: 'Serie A',
    marketValue: 50000,
  },
  {
    jerseyNumber: 3,
    name: 'Min-jae Kim',
    dateOfBirth: '15.11.1996',
    nationality: 'Südkorea',
    height: 1.9,
    foot: 'rechts',
    teamMemberSince: '27.07.2022',
    club: 'SSC Neapel',
    previousClub: 'Fenerbahce',
    uid: '390149c1-5c42-4f81-b3a5-5e6aa5827fd7',
    position: 'Innenverteidiger',
    league: 'Serie A',
    marketValue: 50000000,
  },
  {
    jerseyNumber: 13,
    name: 'Amir Rrahmani',
    dateOfBirth: '24.02.1994',
    nationality: 'Kosovo',
    height: 1.92,
    foot: 'beidfüßig',
    teamMemberSince: '20.01.2020',
    club: 'SSC Neapel',
    previousClub: ': Ablöse 14,20 Mio. €',
    uid: '117e647f-fec6-4cec-b1c9-948a770d29a0',
    position: 'Innenverteidiger',
    league: 'Serie A',
    marketValue: 25000000,
  },
  {
    jerseyNumber: 55,
    name: 'Leo Östigard',
    dateOfBirth: '28.11.1999',
    nationality: 'Norwegen',
    height: 1.82,
    foot: 'rechts',
    teamMemberSince: '18.07.2022',
    club: 'SSC Neapel',
    previousClub: 'Brighton & Hove Albion',
    uid: '8f2af451-f3ee-4570-94b9-1d654128ec38',
    position: 'Innenverteidiger',
    league: 'Serie A',
    marketValue: 7000000,
  },
  {
    jerseyNumber: 5,
    name: 'Juan Jesus',
    dateOfBirth: '10.06.1991',
    nationality: 'Brasilien',
    height: 1.85,
    foot: 'links',
    teamMemberSince: '18.08.2021',
    club: 'SSC Neapel',
    previousClub: 'AS Rom',
    uid: '4ce78aef-5bae-46d9-9b1d-9617afd26905',
    position: 'Innenverteidiger',
    league: 'Serie A',
    marketValue: 4000000,
  },
  {
    jerseyNumber: 17,
    name: 'Mathías Olivera',
    dateOfBirth: '31.10.1997',
    nationality: 'Uruguay',
    height: 1.85,
    foot: 'links',
    teamMemberSince: '01.07.2022',
    club: 'SSC Neapel',
    previousClub: 'FC Getafe',
    uid: 'b231ca2a-0a93-47fe-93be-c26a110f6b58',
    position: 'Linker Verteidiger',
    league: 'Serie A',
    marketValue: 18000000,
  },
  {
    jerseyNumber: 6,
    name: 'Mário Rui',
    dateOfBirth: '27.05.1991',
    nationality: 'Portugal',
    height: 1.68,
    foot: 'links',
    teamMemberSince: '03.01.2018',
    club: 'SSC Neapel',
    previousClub: 'AS Rom',
    uid: 'e10b51b3-56b8-4af2-8281-50e8f94f6d1a',
    position: 'Linker Verteidiger',
    league: 'Serie A',
    marketValue: 8000000,
  },
  {
    jerseyNumber: 22,
    name: 'Giovanni Di Lorenzo',
    dateOfBirth: '04.08.1993',
    nationality: 'Italien',
    height: 1.83,
    foot: 'rechts',
    teamMemberSince: '01.07.2019',
    club: 'SSC Neapel',
    previousClub: ': Ablöse 9,80 Mio. €',
    uid: '7990f856-05e5-40f0-a6ce-91f61e72ea2a',
    position: 'Rechter Verteidiger',
    league: 'Serie A',
    marketValue: 25000000,
  },
  {
    jerseyNumber: 19,
    name: 'Bartosz Bereszynski',
    dateOfBirth: '12.07.1992',
    nationality: 'Polen',
    height: 1.83,
    foot: 'rechts',
    teamMemberSince: '07.01.2023',
    club: 'SSC Neapel',
    previousClub: 'Sampdoria Genua',
    uid: '2316ce3e-9652-4af3-88ca-73c58399293f',
    position: 'Rechter Verteidiger',
    league: 'Serie A',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 68,
    name: 'Stanislav Lobotka',
    dateOfBirth: '25.11.1994',
    nationality: 'Slowakei',
    height: 1.7,
    foot: 'beidfüßig',
    teamMemberSince: '15.01.2020',
    club: 'SSC Neapel',
    previousClub: 'Celta Vigo',
    uid: 'f13b4328-3cf3-42b6-9e68-13cc5a1e5d5a',
    position: 'Defensives Mittelfeld',
    league: 'Serie A',
    marketValue: 38000000,
  },
  {
    jerseyNumber: 4,
    name: 'Diego Demme',
    dateOfBirth: '21.11.1991',
    nationality: 'Deutschland',
    height: 1.7,
    foot: 'rechts',
    teamMemberSince: '11.01.2020',
    club: 'SSC Neapel',
    previousClub: ': Ablöse 10,25 Mio. €',
    uid: 'b63a4a9f-fd35-49dd-90dd-286e6a55d6c5',
    position: 'Defensives Mittelfeld',
    league: 'Serie A',
    marketValue: 4000000,
  },
  {
    jerseyNumber: 20,
    name: 'Piotr Zielinski',
    dateOfBirth: '20.05.1994',
    nationality: 'Polen',
    height: 1.8,
    foot: 'beidfüßig',
    teamMemberSince: '04.08.2016',
    club: 'SSC Neapel',
    previousClub: 'Udinese Calcio',
    uid: '70d4bb30-f14b-4ce4-94ed-c7b3562c1dca',
    position: 'Zentrales Mittelfeld',
    league: 'Serie A',
    marketValue: 40000000,
  },
  {
    jerseyNumber: 99,
    name: 'Frank Anguissa',
    dateOfBirth: '16.11.1995',
    nationality: 'Kamerun',
    height: 1.84,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'SSC Neapel',
    previousClub: 'FC Fulham',
    uid: 'e26d583a-d0cb-425e-baef-9911124a347c',
    position: 'Zentrales Mittelfeld',
    league: 'Serie A',
    marketValue: 40000000,
  },
  {
    jerseyNumber: 7,
    name: 'Eljif Elmas',
    dateOfBirth: '24.09.1999',
    nationality: 'Nordmazedonien',
    height: 1.82,
    foot: 'rechts',
    teamMemberSince: '24.07.2019',
    club: 'SSC Neapel',
    previousClub: 'Fenerbahce',
    uid: '49a706d8-dab3-4dc3-ad63-fa266a525ded',
    position: 'Zentrales Mittelfeld',
    league: 'Serie A',
    marketValue: 26000000,
  },
  {
    jerseyNumber: 91,
    name: 'Tanguy Ndombélé',
    dateOfBirth: '28.12.1996',
    nationality: 'Frankreich',
    height: 1.81,
    foot: 'rechts',
    teamMemberSince: '19.08.2022',
    club: 'SSC Neapel',
    previousClub: 'Tottenham Hotspur',
    uid: '63a278df-6fbd-4b52-82f9-5b888c198427',
    position: 'Zentrales Mittelfeld',
    league: 'Serie A',
    marketValue: 25000000,
  },
  {
    jerseyNumber: 31,
    name: 'Karim Zedadka',
    dateOfBirth: '09.06.2000',
    nationality: 'Algerien',
    height: 1.84,
    foot: 'rechts',
    teamMemberSince: '01.09.2020',
    club: 'SSC Neapel',
    previousClub: 'SSC Neapel U19',
    uid: '961b0271-afe2-4580-b072-6ed8038dcf5b',
    position: 'Linkes Mittelfeld',
    league: 'Serie A',
    marketValue: 300000,
  },
  {
    jerseyNumber: 70,
    name: 'Gianluca Gaetano',
    dateOfBirth: '05.05.2000',
    nationality: 'Italien',
    height: 1.83,
    foot: 'rechts',
    teamMemberSince: '01.07.2019',
    club: 'SSC Neapel',
    previousClub: 'SSC Neapel U19',
    uid: '524b089c-bb8e-4182-84c7-1a52e369ff7e',
    position: 'Offensives Mittelfeld',
    league: 'Serie A',
    marketValue: 4000000,
  },
  {
    jerseyNumber: 77,
    name: 'Khvicha Kvaratskhelia',
    dateOfBirth: '12.02.2001',
    nationality: 'Georgien',
    height: 1.83,
    foot: 'beidfüßig',
    teamMemberSince: '01.07.2022',
    club: 'SSC Neapel',
    previousClub: 'Dinamo Batumi',
    uid: '63ccee3c-c41a-4279-ad0e-b38c78c68dd5',
    position: 'Linksaußen',
    league: 'Serie A',
    marketValue: 85000000,
  },
  {
    jerseyNumber: 23,
    name: 'Alessio Zerbin',
    dateOfBirth: '03.03.1999',
    nationality: 'Italien',
    height: 1.82,
    foot: 'rechts',
    teamMemberSince: '01.07.2018',
    club: 'SSC Neapel',
    previousClub: 'SSC Neapel U19',
    uid: '5fe4237b-34d0-4aaa-b2a7-84828ac479b1',
    position: 'Linksaußen',
    league: 'Serie A',
    marketValue: 4000000,
  },
  {
    jerseyNumber: 11,
    name: 'Hirving Lozano',
    dateOfBirth: '30.07.1995',
    nationality: 'Mexiko',
    height: 1.75,
    foot: 'rechts',
    teamMemberSince: '23.08.2019',
    club: 'SSC Neapel',
    previousClub: ': Ablöse 45,00 Mio. €',
    uid: '2c40ae60-ab19-49df-b2c9-cfb00434e2b6',
    position: 'Rechtsaußen',
    league: 'Serie A',
    marketValue: 28000000,
  },
  {
    jerseyNumber: 21,
    name: 'Matteo Politano',
    dateOfBirth: '03.08.1993',
    nationality: 'Italien',
    height: 1.71,
    foot: 'links',
    teamMemberSince: '01.07.2021',
    club: 'SSC Neapel',
    previousClub: 'Inter Mailand',
    uid: '7b812530-3b97-4123-acd5-ba4de69021a1',
    position: 'Rechtsaußen',
    league: 'Serie A',
    marketValue: 20000000,
  },
  {
    jerseyNumber: 81,
    name: 'Giacomo Raspadori',
    dateOfBirth: '18.02.2000',
    nationality: 'Italien',
    height: 1.72,
    foot: 'beidfüßig',
    teamMemberSince: '20.08.2022',
    club: 'SSC Neapel',
    previousClub: 'US Sassuolo',
    uid: '7184824d-1863-4bf8-9d39-d2873d73b14d',
    position: 'Hängende Spitze',
    league: 'Serie A',
    marketValue: 35000000,
  },
  {
    jerseyNumber: 9,
    name: 'Victor Osimhen',
    dateOfBirth: '29.12.1998',
    nationality: 'Nigeria',
    height: 1.86,
    foot: 'rechts',
    teamMemberSince: '01.09.2020',
    club: 'SSC Neapel',
    previousClub: 'LOSC Lille',
    uid: '8117c435-f252-4aed-9e40-18a74b976f38',
    position: 'Mittelstürmer',
    league: 'Serie A',
    marketValue: 100000000,
  },
  {
    jerseyNumber: 18,
    name: 'Giovanni Simeone',
    dateOfBirth: '05.07.1995',
    nationality: 'Argentinien',
    height: 1.8,
    foot: 'rechts',
    teamMemberSince: '18.08.2022',
    club: 'SSC Neapel',
    previousClub: 'Hellas Verona',
    uid: '5ca44675-8101-4f95-8b31-887eb6ac6ce1',
    position: 'Mittelstürmer',
    league: 'Serie A',
    marketValue: 17000000,
  },
  {
    jerseyNumber: 16,
    name: 'Mike Maignan',
    dateOfBirth: '03.07.1995',
    nationality: 'Frankreich',
    height: 1.91,
    foot: 'rechts',
    teamMemberSince: '01.07.2021',
    club: 'AC Mailand',
    previousClub: 'LOSC Lille',
    uid: '10b4c113-21a9-4ece-9339-cf6aba85b748',
    position: 'Torwart',
    league: 'Serie A',
    marketValue: 35000000,
  },
  {
    jerseyNumber: 1,
    name: 'Ciprian Tatarusanu',
    dateOfBirth: '09.02.1986',
    nationality: 'Rumänien',
    height: 1.98,
    foot: 'rechts',
    teamMemberSince: '11.09.2020',
    club: 'AC Mailand',
    previousClub: ': Ablöse 1,00 Mio. €',
    uid: '18c9f7df-8321-4d3c-a9bd-0fd795983d3e',
    position: 'Torwart',
    league: 'Serie A',
    marketValue: 600000,
  },
  {
    jerseyNumber: 77,
    name: 'Devis Vásquez',
    dateOfBirth: '12.05.1998',
    nationality: 'Kolumbien',
    height: 1.95,
    foot: 'rechts',
    teamMemberSince: '03.01.2023',
    club: 'AC Mailand',
    previousClub: 'Club Guaraní',
    uid: '241b7d8d-fe2f-4f45-a18b-bbfa7a9f1cb1',
    position: 'Torwart',
    league: 'Serie A',
    marketValue: 350000,
  },
  {
    jerseyNumber: 83,
    name: 'Antonio Mirante',
    dateOfBirth: '08.07.1983',
    nationality: 'Italien',
    height: 1.93,
    foot: 'rechts',
    teamMemberSince: '13.10.2021',
    club: 'AC Mailand',
    previousClub: 'Vereinslos',
    uid: '308214bd-c41c-4d4c-88dc-d44133c19a0b',
    position: 'Torwart',
    league: 'Serie A',
    marketValue: 300000,
  },
  {
    jerseyNumber: 23,
    name: 'Fikayo Tomori',
    dateOfBirth: '19.12.1997',
    nationality: 'England',
    height: 1.85,
    foot: 'rechts',
    teamMemberSince: '01.07.2021',
    club: 'AC Mailand',
    previousClub: 'FC Chelsea',
    uid: '60ce8479-848e-46e2-bb46-f18ca92d44f4',
    position: 'Innenverteidiger',
    league: 'Serie A',
    marketValue: 45000000,
  },
  {
    jerseyNumber: 20,
    name: 'Pierre Kalulu',
    dateOfBirth: '05.06.2000',
    nationality: 'Frankreich',
    height: 1.82,
    foot: 'rechts',
    teamMemberSince: '01.09.2020',
    club: 'AC Mailand',
    previousClub: 'Olympique Lyon B',
    uid: '834025b8-64a0-4263-8c7b-8cc1fb0db1db',
    position: 'Innenverteidiger',
    league: 'Serie A',
    marketValue: 35000000,
  },
  {
    jerseyNumber: 28,
    name: 'Malick Thiaw',
    dateOfBirth: '08.08.2001',
    nationality: 'Deutschland',
    height: 1.94,
    foot: 'rechts',
    teamMemberSince: '29.08.2022',
    club: 'AC Mailand',
    previousClub: 'FC Schalke 04',
    uid: '327f846d-c597-40b4-bd44-9985fa6f4c55',
    position: 'Innenverteidiger',
    league: 'Serie A',
    marketValue: 15000000,
  },
  {
    jerseyNumber: 46,
    name: 'Matteo Gabbia',
    dateOfBirth: '21.10.1999',
    nationality: 'Italien',
    height: 1.85,
    foot: 'rechts',
    teamMemberSince: '01.07.2017',
    club: 'AC Mailand',
    previousClub: 'AC Mailand U19',
    uid: 'b0254ecf-85fb-485d-b23a-42edc29c1858',
    position: 'Innenverteidiger',
    league: 'Serie A',
    marketValue: 7000000,
  },
  {
    jerseyNumber: 24,
    name: 'Simon Kjaer',
    dateOfBirth: '26.03.1989',
    nationality: 'Dänemark',
    height: 1.91,
    foot: 'rechts',
    teamMemberSince: '01.09.2020',
    club: 'AC Mailand',
    previousClub: 'FC Sevilla',
    uid: 'b4ffb222-374b-4605-92ee-beb62f788f2d',
    position: 'Innenverteidiger',
    league: 'Serie A',
    marketValue: 5000000,
  },
  {
    jerseyNumber: 19,
    name: 'Theo Hernández',
    dateOfBirth: '06.10.1997',
    nationality: 'Frankreich',
    height: 1.84,
    foot: 'links',
    teamMemberSince: '06.07.2019',
    club: 'AC Mailand',
    previousClub: 'Real Madrid',
    uid: '45105588-fb21-4a30-9667-07387095b2b0',
    position: 'Linker Verteidiger',
    league: 'Serie A',
    marketValue: 60000000,
  },
  {
    jerseyNumber: 5,
    name: 'Fodé Ballo-Touré',
    dateOfBirth: '03.01.1997',
    nationality: 'Senegal',
    height: 1.82,
    foot: 'links',
    teamMemberSince: '18.07.2021',
    club: 'AC Mailand',
    previousClub: 'AS Monaco',
    uid: 'c27515df-c445-4c24-9fe9-f20cbcb1cce5',
    position: 'Linker Verteidiger',
    league: 'Serie A',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 2,
    name: 'Davide Calabria',
    dateOfBirth: '06.12.1996',
    nationality: 'Italien',
    height: 1.76,
    foot: 'rechts',
    teamMemberSince: '01.07.2015',
    club: 'AC Mailand',
    previousClub: 'AC Mailand U19',
    uid: '64ed77ca-983d-427f-8610-491c969a9b83',
    position: 'Rechter Verteidiger',
    league: 'Serie A',
    marketValue: 20000000,
  },
  {
    jerseyNumber: 21,
    name: 'Sergiño Dest',
    dateOfBirth: '03.11.2000',
    nationality: 'Vereinigte Staaten',
    height: 1.71,
    foot: 'rechts',
    teamMemberSince: '01.09.2022',
    club: 'AC Mailand',
    previousClub: 'FC Barcelona',
    uid: '966c3344-19fe-4ae2-9f84-9a94443e9448',
    position: 'Rechter Verteidiger',
    league: 'Serie A',
    marketValue: 12000000,
  },
  {
    jerseyNumber: 25,
    name: 'Alessandro Florenzi',
    dateOfBirth: '11.03.1991',
    nationality: 'Italien',
    height: 1.73,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'AC Mailand',
    previousClub: 'AS Rom',
    uid: 'ab179097-313d-47a6-a360-42ff6a56bc95',
    position: 'Rechter Verteidiger',
    league: 'Serie A',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 8,
    name: 'Sandro Tonali',
    dateOfBirth: '08.05.2000',
    nationality: 'Italien',
    height: 1.81,
    foot: 'rechts',
    teamMemberSince: '08.07.2021',
    club: 'AC Mailand',
    previousClub: 'Brescia Calcio',
    uid: '742ef6b5-3805-435c-bba9-421896617787',
    position: 'Defensives Mittelfeld',
    league: 'Serie A',
    marketValue: 50000000,
  },
  {
    jerseyNumber: 4,
    name: 'Ismaël Bennacer',
    dateOfBirth: '01.12.1997',
    nationality: 'Algerien',
    height: 1.75,
    foot: 'links',
    teamMemberSince: '04.08.2019',
    club: 'AC Mailand',
    previousClub: ': Ablöse 17,20 Mio. €',
    uid: '809948ef-4f27-42f1-91c7-dc2ed1116d04',
    position: 'Defensives Mittelfeld',
    league: 'Serie A',
    marketValue: 40000000,
  },
  {
    jerseyNumber: 14,
    name: 'Tiemoué Bakayoko',
    dateOfBirth: '17.08.1994',
    nationality: 'Frankreich',
    height: 1.89,
    foot: 'rechts',
    teamMemberSince: '30.08.2021',
    club: 'AC Mailand',
    previousClub: 'FC Chelsea',
    uid: '9b1b96bb-6d0f-4d24-a2f5-5ac786dcd7c8',
    position: 'Defensives Mittelfeld',
    league: 'Serie A',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 32,
    name: 'Tommaso Pobega',
    dateOfBirth: '15.07.1999',
    nationality: 'Italien',
    height: 1.88,
    foot: 'beidfüßig',
    teamMemberSince: '01.07.2018',
    club: 'AC Mailand',
    previousClub: 'AC Mailand U19',
    uid: '9520d152-9d12-41ea-a8be-25d33cf644e2',
    position: 'Zentrales Mittelfeld',
    league: 'Serie A',
    marketValue: 15000000,
  },
  {
    jerseyNumber: 33,
    name: 'Rade Krunic',
    dateOfBirth: '07.10.1993',
    nationality: 'Bosnien-Herzegowina',
    height: 1.84,
    foot: 'rechts',
    teamMemberSince: '08.07.2019',
    club: 'AC Mailand',
    previousClub: ': Ablöse 8,60 Mio. €',
    uid: '9b2a64f0-9e4b-42c1-a377-4a97c8a76745',
    position: 'Zentrales Mittelfeld',
    league: 'Serie A',
    marketValue: 9000000,
  },
  {
    jerseyNumber: 40,
    name: 'Aster Vranckx',
    dateOfBirth: '04.10.2002',
    nationality: 'Belgien',
    height: 1.83,
    foot: 'rechts',
    teamMemberSince: '01.09.2022',
    club: 'AC Mailand',
    previousClub: 'VfL Wolfsburg',
    uid: 'bf96a073-ecf0-4057-8022-104fa25fbbdb',
    position: 'Zentrales Mittelfeld',
    league: 'Serie A',
    marketValue: 8000000,
  },
  {
    jerseyNumber: 90,
    name: 'Charles De Ketelaere',
    dateOfBirth: '10.03.2001',
    nationality: 'Belgien',
    height: 1.92,
    foot: 'links',
    teamMemberSince: '02.08.2022',
    club: 'AC Mailand',
    previousClub: 'FC Brügge',
    uid: '55076cff-2be9-4e7f-9d35-cb834e7d37db',
    position: 'Offensives Mittelfeld',
    league: 'Serie A',
    marketValue: 27000000,
  },
  {
    jerseyNumber: 10,
    name: 'Brahim Díaz',
    dateOfBirth: '03.08.1999',
    nationality: 'Spanien',
    height: 1.71,
    foot: 'beidfüßig',
    teamMemberSince: '19.07.2021',
    club: 'AC Mailand',
    previousClub: 'Real Madrid',
    uid: '204f9829-0132-4a28-aa6f-45c5c00f99ef',
    position: 'Offensives Mittelfeld',
    league: 'Serie A',
    marketValue: 20000000,
  },
  {
    jerseyNumber: 7,
    name: 'Yacine Adli',
    dateOfBirth: '29.07.2000',
    nationality: 'Frankreich',
    height: 1.86,
    foot: 'rechts',
    teamMemberSince: '30.08.2021',
    club: 'AC Mailand',
    previousClub: 'FC Girondins Bordeaux',
    uid: 'd19edd14-e2a4-42b7-ac4c-8d122d43d3e5',
    position: 'Offensives Mittelfeld',
    league: 'Serie A',
    marketValue: 9000000,
  },
  {
    jerseyNumber: 17,
    name: 'Rafael Leão',
    dateOfBirth: '10.06.1999',
    nationality: 'Portugal',
    height: 1.88,
    foot: 'rechts',
    teamMemberSince: '01.08.2019',
    club: 'AC Mailand',
    previousClub: 'LOSC Lille',
    uid: '8bef7b70-5d8f-4113-b50a-26b964c0332e',
    position: 'Linksaußen',
    league: 'Serie A',
    marketValue: 80000000,
  },
  {
    jerseyNumber: 12,
    name: 'Ante Rebic',
    dateOfBirth: '21.09.1993',
    nationality: 'Kroatien',
    height: 1.85,
    foot: 'rechts',
    teamMemberSince: '12.09.2020',
    club: 'AC Mailand',
    previousClub: 'Eintracht Frankfurt',
    uid: '32c9bcfa-f4a4-4312-8ffa-785df394dcc3',
    position: 'Linksaußen',
    league: 'Serie A',
    marketValue: 10000000,
  },
  {
    jerseyNumber: 56,
    name: 'Alexis Saelemaekers',
    dateOfBirth: '27.06.1999',
    nationality: 'Belgien',
    height: 1.8,
    foot: 'rechts',
    teamMemberSince: '01.09.2020',
    club: 'AC Mailand',
    previousClub: 'RSC Anderlecht',
    uid: '7c156f7b-37e3-46a4-8b42-6b6ae7c973ce',
    position: 'Rechtsaußen',
    league: 'Serie A',
    marketValue: 15000000,
  },
  {
    jerseyNumber: 30,
    name: 'Junior Messias',
    dateOfBirth: '13.05.1991',
    nationality: 'Brasilien',
    height: 1.74,
    foot: 'links',
    teamMemberSince: '07.07.2022',
    club: 'AC Mailand',
    previousClub: 'FC Crotone',
    uid: '302ef8da-7061-481c-9ac4-a0cdc4c3fff0',
    position: 'Rechtsaußen',
    league: 'Serie A',
    marketValue: 6000000,
  },
  {
    jerseyNumber: 27,
    name: 'Divock Origi',
    dateOfBirth: '18.04.1995',
    nationality: 'Belgien',
    height: 1.89,
    foot: 'rechts',
    teamMemberSince: '05.07.2022',
    club: 'AC Mailand',
    previousClub: 'FC Liverpool',
    uid: 'c17491b9-2711-4f85-9887-ad05075d446a',
    position: 'Mittelstürmer',
    league: 'Serie A',
    marketValue: 8000000,
  },
  {
    jerseyNumber: 9,
    name: 'Olivier Giroud',
    dateOfBirth: '30.09.1986',
    nationality: 'Frankreich',
    height: 1.92,
    foot: 'links',
    teamMemberSince: '17.07.2021',
    club: 'AC Mailand',
    previousClub: 'FC Chelsea',
    uid: '9cd1824a-0ceb-4563-a470-bc0db88dcbee',
    position: 'Mittelstürmer',
    league: 'Serie A',
    marketValue: 4000000,
  },
  {
    jerseyNumber: 11,
    name: 'Zlatan Ibrahimović',
    dateOfBirth: '03.10.1981',
    nationality: 'Schweden',
    height: 1.95,
    foot: 'rechts',
    teamMemberSince: '02.01.2020',
    club: 'AC Mailand',
    previousClub: 'Los Angeles Galaxy',
    uid: '2136f4ef-2c73-4b51-9bd7-3e50c34c6536',
    position: 'Mittelstürmer',
    league: 'Serie A',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 24,
    name: 'André Onana',
    dateOfBirth: '02.04.1996',
    nationality: 'Kamerun',
    height: 1.9,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'Inter Mailand',
    previousClub: 'Ajax Amsterdam',
    uid: '4dc5c7d0-a272-463d-bbed-4d7f14c48014',
    position: 'Torwart',
    league: 'Serie A',
    marketValue: 20000000,
  },
  {
    jerseyNumber: 1,
    name: 'Samir Handanovic',
    dateOfBirth: '14.07.1984',
    nationality: 'Slowenien',
    height: 1.93,
    foot: 'rechts',
    teamMemberSince: '09.07.2012',
    club: 'Inter Mailand',
    previousClub: 'Udinese Calcio',
    uid: '743160fe-d3c5-4252-a83a-ec9a41a42cea',
    position: 'Torwart',
    league: 'Serie A',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 21,
    name: 'Alex Cordaz',
    dateOfBirth: '01.01.1983',
    nationality: 'Italien',
    height: 1.88,
    foot: 'rechts',
    teamMemberSince: '01.07.2021',
    club: 'Inter Mailand',
    previousClub: 'FC Crotone',
    uid: 'eb121100-6ccf-4dce-8198-03c62ed3c299',
    position: 'Torwart',
    league: 'Serie A',
    marketValue: 300000,
  },
  {
    jerseyNumber: 37,
    name: 'Milan Skriniar',
    dateOfBirth: '11.02.1995',
    nationality: 'Slowakei',
    height: 1.88,
    foot: 'rechts',
    teamMemberSince: '07.07.2017',
    club: 'Inter Mailand',
    previousClub: 'Sampdoria Genua',
    uid: '058687f0-3577-4296-afd9-1963fd347c31',
    position: 'Innenverteidiger',
    league: 'Serie A',
    marketValue: 60000000,
  },
  {
    jerseyNumber: 95,
    name: 'Alessandro Bastoni',
    dateOfBirth: '13.04.1999',
    nationality: 'Italien',
    height: 1.9,
    foot: 'links',
    teamMemberSince: '30.08.2017',
    club: 'Inter Mailand',
    previousClub: 'Atalanta Bergamo',
    uid: 'c22f7c2b-8b85-40e5-8755-9c9af364ebb4',
    position: 'Innenverteidiger',
    league: 'Serie A',
    marketValue: 55000000,
  },
  {
    jerseyNumber: 6,
    name: 'Stefan de Vrij',
    dateOfBirth: '05.02.1992',
    nationality: 'Niederlande',
    height: 1.89,
    foot: 'rechts',
    teamMemberSince: '01.07.2018',
    club: 'Inter Mailand',
    previousClub: ': Ablöse ablösefrei',
    uid: 'b83aa677-0938-4123-a541-2179b81da51b',
    position: 'Innenverteidiger',
    league: 'Serie A',
    marketValue: 10000000,
  },
  {
    jerseyNumber: 15,
    name: 'Francesco Acerbi',
    dateOfBirth: '10.02.1988',
    nationality: 'Italien',
    height: 1.92,
    foot: 'links',
    teamMemberSince: '01.09.2022',
    club: 'Inter Mailand',
    previousClub: 'Lazio Rom',
    uid: '31831a4b-9261-4bd2-b553-43ad81987281',
    position: 'Innenverteidiger',
    league: 'Serie A',
    marketValue: 4000000,
  },
  {
    jerseyNumber: 33,
    name: "Danilo D'Ambrosio",
    dateOfBirth: '09.09.1988',
    nationality: 'Italien',
    height: 1.8,
    foot: 'rechts',
    teamMemberSince: '30.01.2014',
    club: 'Inter Mailand',
    previousClub: 'FC Turin',
    uid: '92e91288-3e05-4ed4-82e7-a7f7bcdc1209',
    position: 'Innenverteidiger',
    league: 'Serie A',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 47,
    name: 'Alessandro Fontanarosa',
    dateOfBirth: '07.02.2003',
    nationality: 'Italien',
    height: 1.85,
    foot: 'links',
    teamMemberSince: '01.07.2022',
    club: 'Inter Mailand',
    previousClub: 'Inter Mailand U19',
    uid: 'd786f7e5-0f1c-4b1d-80b0-b2b7a7e95a84',
    position: 'Innenverteidiger',
    league: 'Serie A',
    marketValue: 300000,
  },
  {
    jerseyNumber: 32,
    name: 'Federico Dimarco',
    dateOfBirth: '10.11.1997',
    nationality: 'Italien',
    height: 1.75,
    foot: 'links',
    teamMemberSince: '05.07.2018',
    club: 'Inter Mailand',
    previousClub: 'FC Sion',
    uid: '14f2a0cd-c042-4d24-8957-b7182b026d9f',
    position: 'Linker Verteidiger',
    league: 'Serie A',
    marketValue: 25000000,
  },
  {
    jerseyNumber: 29,
    name: 'Dalbert',
    dateOfBirth: '08.09.1993',
    nationality: 'Brasilien',
    height: 1.81,
    foot: 'links',
    teamMemberSince: '09.08.2017',
    club: 'Inter Mailand',
    previousClub: 'OGC Nizza',
    uid: '814d4315-ca90-4f00-8ce2-4b5837062116',
    position: 'Linker Verteidiger',
    league: 'Serie A',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 12,
    name: 'Raoul Bellanova',
    dateOfBirth: '17.05.2000',
    nationality: 'Italien',
    height: 1.88,
    foot: 'rechts',
    teamMemberSince: '06.07.2022',
    club: 'Inter Mailand',
    previousClub: 'Cagliari Calcio',
    uid: 'f89122e7-9100-43f0-87d6-2bd46b90aeee',
    position: 'Rechter Verteidiger',
    league: 'Serie A',
    marketValue: 5000000,
  },
  {
    jerseyNumber: 36,
    name: 'Matteo Darmian',
    dateOfBirth: '02.12.1989',
    nationality: 'Italien',
    height: 1.83,
    foot: 'rechts',
    teamMemberSince: '01.07.2021',
    club: 'Inter Mailand',
    previousClub: 'Parma Calcio 1913',
    uid: '631e0a45-9757-4995-a959-40c5d81d9cc1',
    position: 'Rechter Verteidiger',
    league: 'Serie A',
    marketValue: 4000000,
  },
  {
    jerseyNumber: 46,
    name: 'Mattia Zanotti',
    dateOfBirth: '11.01.2003',
    nationality: 'Italien',
    height: 1.77,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'Inter Mailand',
    previousClub: 'Inter Mailand U19',
    uid: '3ef61660-20c3-4bfe-af4f-c7256586d17f',
    position: 'Rechter Verteidiger',
    league: 'Serie A',
    marketValue: 350000,
  },
  {
    jerseyNumber: 77,
    name: 'Marcelo Brozovic',
    dateOfBirth: '16.11.1992',
    nationality: 'Kroatien',
    height: 1.81,
    foot: 'beidfüßig',
    teamMemberSince: '01.07.2016',
    club: 'Inter Mailand',
    previousClub: 'GNK Dinamo Zagreb',
    uid: '715cc294-ae0e-48b1-a702-3f1d3db9a64c',
    position: 'Defensives Mittelfeld',
    league: 'Serie A',
    marketValue: 28000000,
  },
  {
    jerseyNumber: 14,
    name: 'Kristjan Asllani',
    dateOfBirth: '09.03.2002',
    nationality: 'Albanien',
    height: 1.75,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'Inter Mailand',
    previousClub: 'FC Empoli',
    uid: '16a2026d-7351-4eab-9626-e12e755c74cd',
    position: 'Defensives Mittelfeld',
    league: 'Serie A',
    marketValue: 12000000,
  },
  {
    jerseyNumber: 23,
    name: 'Nicolò Barella',
    dateOfBirth: '07.02.1997',
    nationality: 'Italien',
    height: 1.75,
    foot: 'rechts',
    teamMemberSince: '01.09.2020',
    club: 'Inter Mailand',
    previousClub: 'Cagliari Calcio',
    uid: 'a1b9a53c-b810-438c-96cc-ae84a701fa65',
    position: 'Zentrales Mittelfeld',
    league: 'Serie A',
    marketValue: 70000000,
  },
  {
    jerseyNumber: 20,
    name: 'Hakan Calhanoglu',
    dateOfBirth: '08.02.1994',
    nationality: 'Türkei',
    height: 1.78,
    foot: 'rechts',
    teamMemberSince: '01.07.2021',
    club: 'Inter Mailand',
    previousClub: 'AC Mailand',
    uid: 'eac4ae98-fba3-4ae6-9b2d-6ce81ce0148d',
    position: 'Zentrales Mittelfeld',
    league: 'Serie A',
    marketValue: 35000000,
  },
  {
    jerseyNumber: 5,
    name: 'Roberto Gagliardini',
    dateOfBirth: '07.04.1994',
    nationality: 'Italien',
    height: 1.88,
    foot: 'rechts',
    teamMemberSince: '01.08.2017',
    club: 'Inter Mailand',
    previousClub: 'Atalanta Bergamo',
    uid: '8911ed2e-db02-4921-b707-e9d86afb0e1a',
    position: 'Zentrales Mittelfeld',
    league: 'Serie A',
    marketValue: 6000000,
  },
  {
    jerseyNumber: 2,
    name: 'Denzel Dumfries',
    dateOfBirth: '18.04.1996',
    nationality: 'Niederlande',
    height: 1.88,
    foot: 'rechts',
    teamMemberSince: '14.08.2021',
    club: 'Inter Mailand',
    previousClub: 'PSV Eindhoven',
    uid: '472117cb-5198-4c02-a59e-f0e35e73aff1',
    position: 'Rechtes Mittelfeld',
    league: 'Serie A',
    marketValue: 28000000,
  },
  {
    jerseyNumber: 8,
    name: 'Robin Gosens',
    dateOfBirth: '05.07.1994',
    nationality: 'Deutschland',
    height: 1.83,
    foot: 'links',
    teamMemberSince: '27.01.2022',
    club: 'Inter Mailand',
    previousClub: 'Atalanta Bergamo',
    uid: '536d988f-1b98-4733-8cc1-46da1ff6cf2a',
    position: 'Linkes Mittelfeld',
    league: 'Serie A',
    marketValue: 18000000,
  },
  {
    jerseyNumber: 22,
    name: 'Henrikh Mkhitaryan',
    dateOfBirth: '21.01.1989',
    nationality: 'Armenien',
    height: 1.77,
    foot: 'beidfüßig',
    teamMemberSince: '01.07.2022',
    club: 'Inter Mailand',
    previousClub: 'AS Rom',
    uid: 'fc161144-11f3-4328-bb3f-3e299c8c39b8',
    position: 'Offensives Mittelfeld',
    league: 'Serie A',
    marketValue: 6000000,
  },
  {
    jerseyNumber: 45,
    name: 'Valentín Carboni',
    dateOfBirth: '05.03.2005',
    nationality: 'Argentinien',
    height: 1.85,
    foot: 'links',
    teamMemberSince: '01.07.2022',
    club: 'Inter Mailand',
    previousClub: 'Inter Mailand U19',
    uid: '3a6b95b0-692d-4a1a-ad91-ae3127235541',
    position: 'Offensives Mittelfeld',
    league: 'Serie A',
    marketValue: 4000000,
  },
  {
    jerseyNumber: 11,
    name: 'Joaquín Correa',
    dateOfBirth: '13.08.1994',
    nationality: 'Argentinien',
    height: 1.88,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'Inter Mailand',
    previousClub: 'Lazio Rom',
    uid: '05afcb3c-1f81-45ce-af23-13e1492bafbe',
    position: 'Hängende Spitze',
    league: 'Serie A',
    marketValue: 14000000,
  },
  {
    jerseyNumber: 10,
    name: 'Lautaro Martínez',
    dateOfBirth: '22.08.1997',
    nationality: 'Argentinien',
    height: 1.74,
    foot: 'rechts',
    teamMemberSince: '04.07.2018',
    club: 'Inter Mailand',
    previousClub: 'Racing Club',
    uid: '685f83bc-1c06-4cfd-b446-ac87e8b1408d',
    position: 'Mittelstürmer',
    league: 'Serie A',
    marketValue: 80000000,
  },
  {
    jerseyNumber: 90,
    name: 'Romelu Lukaku',
    dateOfBirth: '13.05.1993',
    nationality: 'Belgien',
    height: 1.91,
    foot: 'links',
    teamMemberSince: '01.07.2022',
    club: 'Inter Mailand',
    previousClub: 'FC Chelsea',
    uid: 'a8c459ca-4357-4e92-b2d7-a5ef36237211',
    position: 'Mittelstürmer',
    league: 'Serie A',
    marketValue: 40000000,
  },
  {
    jerseyNumber: 9,
    name: 'Edin Dzeko',
    dateOfBirth: '17.03.1986',
    nationality: 'Bosnien-Herzegowina',
    height: 1.93,
    foot: 'rechts',
    teamMemberSince: '14.08.2021',
    club: 'Inter Mailand',
    previousClub: 'AS Rom',
    uid: 'd8b070a9-3571-49b1-9ed4-9e4a7fb7cdb7',
    position: 'Mittelstürmer',
    league: 'Serie A',
    marketValue: 4000000,
  },
  {
    jerseyNumber: 1,
    name: 'Wojciech Szczesny',
    dateOfBirth: '18.04.1990',
    nationality: 'Polen',
    height: 1.96,
    foot: 'rechts',
    teamMemberSince: '19.07.2017',
    club: 'Juventus Turin',
    previousClub: 'FC Arsenal',
    uid: '96c711c1-1d88-452f-8e3a-1d53e27ceefe',
    position: 'Torwart',
    league: 'Serie A',
    marketValue: 13000000,
  },
  {
    jerseyNumber: 36,
    name: 'Mattia Perin',
    dateOfBirth: '10.11.1992',
    nationality: 'Italien',
    height: 1.88,
    foot: 'rechts',
    teamMemberSince: '01.07.2018',
    club: 'Juventus Turin',
    previousClub: ': Ablöse 14,20 Mio. €',
    uid: 'e50722c9-87e6-4ab4-b7f4-04728a1904b2',
    position: 'Torwart',
    league: 'Serie A',
    marketValue: 4000000,
  },
  {
    jerseyNumber: 23,
    name: 'Carlo Pinsoglio',
    dateOfBirth: '16.03.1990',
    nationality: 'Italien',
    height: 1.94,
    foot: 'links',
    teamMemberSince: '01.07.2014',
    club: 'Juventus Turin',
    previousClub: ': Ablöse 800 Tsd. €',
    uid: 'be5cc154-9dc5-4e55-ad72-827971ccf2aa',
    position: 'Torwart',
    league: 'Serie A',
    marketValue: 300000,
  },
  {
    jerseyNumber: 3,
    name: 'Bremer',
    dateOfBirth: '18.03.1997',
    nationality: 'Brasilien',
    height: 1.88,
    foot: 'rechts',
    teamMemberSince: '20.07.2022',
    club: 'Juventus Turin',
    previousClub: 'FC Turin',
    uid: 'dc929b58-fa93-4ec4-8c67-befc4e974618',
    position: 'Innenverteidiger',
    league: 'Serie A',
    marketValue: 40000000,
  },
  {
    jerseyNumber: 15,
    name: 'Federico Gatti',
    dateOfBirth: '24.06.1998',
    nationality: 'Italien',
    height: 1.9,
    foot: 'rechts',
    teamMemberSince: '31.01.2022',
    club: 'Juventus Turin',
    previousClub: 'Frosinone Calcio',
    uid: 'f04441fb-e610-41f2-a80d-c36295a0a6a4',
    position: 'Innenverteidiger',
    league: 'Serie A',
    marketValue: 5000000,
  },
  {
    jerseyNumber: 19,
    name: 'Leonardo Bonucci',
    dateOfBirth: '01.05.1987',
    nationality: 'Italien',
    height: 1.9,
    foot: 'rechts',
    teamMemberSince: '02.08.2018',
    club: 'Juventus Turin',
    previousClub: 'AC Mailand',
    uid: 'f3c6c1be-c1d0-4a45-824d-3f256429e51b',
    position: 'Innenverteidiger',
    league: 'Serie A',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 24,
    name: 'Daniele Rugani',
    dateOfBirth: '29.07.1994',
    nationality: 'Italien',
    height: 1.9,
    foot: 'rechts',
    teamMemberSince: '01.02.2015',
    club: 'Juventus Turin',
    previousClub: ': Ablöse 4,20 Mio. €',
    uid: '7efa78bf-4a17-4877-b299-f44406cbf8dd',
    position: 'Innenverteidiger',
    league: 'Serie A',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 12,
    name: 'Alex Sandro',
    dateOfBirth: '26.01.1991',
    nationality: 'Brasilien',
    height: 1.81,
    foot: 'links',
    teamMemberSince: '20.08.2015',
    club: 'Juventus Turin',
    previousClub: 'FC Porto',
    uid: '296d0302-27e2-4632-9a4d-ea662af21660',
    position: 'Linker Verteidiger',
    league: 'Serie A',
    marketValue: 4000000,
  },
  {
    jerseyNumber: 6,
    name: 'Danilo',
    dateOfBirth: '15.07.1991',
    nationality: 'Brasilien',
    height: 1.84,
    foot: 'rechts',
    teamMemberSince: '07.08.2019',
    club: 'Juventus Turin',
    previousClub: 'Manchester City',
    uid: 'b588e03b-1bc3-40f4-81a6-f47e442094ce',
    position: 'Rechter Verteidiger',
    league: 'Serie A',
    marketValue: 12000000,
  },
  {
    jerseyNumber: 2,
    name: 'Mattia De Sciglio',
    dateOfBirth: '20.10.1992',
    nationality: 'Italien',
    height: 1.83,
    foot: 'rechts',
    teamMemberSince: '20.07.2017',
    club: 'Juventus Turin',
    previousClub: 'AC Mailand',
    uid: '7e3f335c-b8f2-4610-ae47-6fe1a7b6b87b',
    position: 'Rechter Verteidiger',
    league: 'Serie A',
    marketValue: 4000000,
  },
  {
    jerseyNumber: 5,
    name: 'Manuel Locatelli',
    dateOfBirth: '08.01.1998',
    nationality: 'Italien',
    height: 1.85,
    foot: 'rechts',
    teamMemberSince: '18.08.2021',
    club: 'Juventus Turin',
    previousClub: 'US Sassuolo',
    uid: 'c8d7f289-0881-4ef6-ba31-b3fc67eaa486',
    position: 'Defensives Mittelfeld',
    league: 'Serie A',
    marketValue: 30000000,
  },
  {
    jerseyNumber: 32,
    name: 'Leandro Paredes',
    dateOfBirth: '29.06.1994',
    nationality: 'Argentinien',
    height: 1.8,
    foot: 'rechts',
    teamMemberSince: '31.08.2022',
    club: 'Juventus Turin',
    previousClub: 'FC Paris Saint-Germain',
    uid: '38bec129-e784-4edd-94bf-0ca5362304ae',
    position: 'Defensives Mittelfeld',
    league: 'Serie A',
    marketValue: 12000000,
  },
  {
    jerseyNumber: 45,
    name: 'Enzo Barrenechea',
    dateOfBirth: '22.05.2001',
    nationality: 'Argentinien',
    height: 1.86,
    foot: 'rechts',
    teamMemberSince: '01.04.2023',
    club: 'Juventus Turin',
    previousClub: 'Juventus Next Gen',
    uid: '62699120-17f0-4f99-b7f3-42b42b4821c0',
    position: 'Defensives Mittelfeld',
    league: 'Serie A',
    marketValue: 900000,
  },
  {
    jerseyNumber: 25,
    name: 'Adrien Rabiot',
    dateOfBirth: '03.04.1995',
    nationality: 'Frankreich',
    height: 1.88,
    foot: 'links',
    teamMemberSince: '01.07.2019',
    club: 'Juventus Turin',
    previousClub: 'FC Paris Saint-Germain',
    uid: '9979760c-2c76-4963-aa67-e7129dca1b36',
    position: 'Zentrales Mittelfeld',
    league: 'Serie A',
    marketValue: 30000000,
  },
  {
    jerseyNumber: 10,
    name: 'Paul Pogba',
    dateOfBirth: '15.03.1993',
    nationality: 'Frankreich',
    height: 1.91,
    foot: 'beidfüßig',
    teamMemberSince: '11.07.2022',
    club: 'Juventus Turin',
    previousClub: 'Manchester United',
    uid: 'c5d04604-8cbf-4ed8-b476-7f554b55f609',
    position: 'Zentrales Mittelfeld',
    league: 'Serie A',
    marketValue: 20000000,
  },
  {
    jerseyNumber: 44,
    name: 'Nicolò Fagioli',
    dateOfBirth: '12.02.2001',
    nationality: 'Italien',
    height: 1.78,
    foot: 'rechts',
    teamMemberSince: '01.01.2021',
    club: 'Juventus Turin',
    previousClub: 'Juventus Turin U23',
    uid: '8cb4c696-fcb9-424b-991b-990c200d05d8',
    position: 'Zentrales Mittelfeld',
    league: 'Serie A',
    marketValue: 20000000,
  },
  {
    jerseyNumber: 20,
    name: 'Fabio Miretti',
    dateOfBirth: '03.08.2003',
    nationality: 'Italien',
    height: 1.8,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'Juventus Turin',
    previousClub: 'Juventus Next Gen',
    uid: '39657dc7-4dd0-4a49-9a91-1e4abcd6c4bf',
    position: 'Zentrales Mittelfeld',
    league: 'Serie A',
    marketValue: 15000000,
  },
  {
    jerseyNumber: 11,
    name: 'Juan Cuadrado',
    dateOfBirth: '26.05.1988',
    nationality: 'Kolumbien',
    height: 1.76,
    foot: 'rechts',
    teamMemberSince: '01.07.2017',
    club: 'Juventus Turin',
    previousClub: 'FC Chelsea',
    uid: '6a4a5541-98a3-4c69-b100-6050dcb1ca84',
    position: 'Rechtes Mittelfeld',
    league: 'Serie A',
    marketValue: 5000000,
  },
  {
    jerseyNumber: 17,
    name: 'Filip Kostic',
    dateOfBirth: '01.11.1992',
    nationality: 'Serbien',
    height: 1.84,
    foot: 'links',
    teamMemberSince: '12.08.2022',
    club: 'Juventus Turin',
    previousClub: 'Eintracht Frankfurt',
    uid: '6e1aaac1-eff8-43fe-be43-f75ed03cefd4',
    position: 'Linkes Mittelfeld',
    league: 'Serie A',
    marketValue: 24000000,
  },
  {
    jerseyNumber: 7,
    name: 'Federico Chiesa',
    dateOfBirth: '25.10.1997',
    nationality: 'Italien',
    height: 1.75,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'Juventus Turin',
    previousClub: 'AC Florenz',
    uid: '7e4ff98c-9106-4f4d-8b33-ed5d947b9b48',
    position: 'Linksaußen',
    league: 'Serie A',
    marketValue: 50000000,
  },
  {
    jerseyNumber: 43,
    name: 'Samuel Iling Junior',
    dateOfBirth: '04.10.2003',
    nationality: 'England',
    height: 1.82,
    foot: 'links',
    teamMemberSince: '19.12.2022',
    club: 'Juventus Turin',
    previousClub: 'Juventus Next Gen',
    uid: '224e2d20-a755-48da-8b56-117f7ced8513',
    position: 'Linksaußen',
    league: 'Serie A',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 22,
    name: 'Ángel Di María',
    dateOfBirth: '14.02.1988',
    nationality: 'Argentinien',
    height: 1.8,
    foot: 'links',
    teamMemberSince: '08.07.2022',
    club: 'Juventus Turin',
    previousClub: 'FC Paris Saint-Germain',
    uid: '4926b101-86c3-4634-8815-dfe808900425',
    position: 'Rechtsaußen',
    league: 'Serie A',
    marketValue: 10000000,
  },
  {
    jerseyNumber: 30,
    name: 'Matías Soulé',
    dateOfBirth: '15.04.2003',
    nationality: 'Argentinien',
    height: 1.82,
    foot: 'links',
    teamMemberSince: '01.07.2022',
    club: 'Juventus Turin',
    previousClub: 'Juventus Next Gen',
    uid: 'b374aeaf-a6c7-4214-8b04-d21874dfc016',
    position: 'Rechtsaußen',
    league: 'Serie A',
    marketValue: 4000000,
  },
  {
    jerseyNumber: 9,
    name: 'Dušan Vlahović',
    dateOfBirth: '28.01.2000',
    nationality: 'Serbien',
    height: 1.9,
    foot: 'links',
    teamMemberSince: '28.01.2022',
    club: 'Juventus Turin',
    previousClub: ': Ablöse 81,60 Mio. €',
    uid: 'b8bc5c75-98e9-4f9b-9b5d-046578143743',
    position: 'Mittelstürmer',
    league: 'Serie A',
    marketValue: 75000000,
  },
  {
    jerseyNumber: 18,
    name: 'Moise Kean',
    dateOfBirth: '28.02.2000',
    nationality: 'Italien',
    height: 1.83,
    foot: 'rechts',
    teamMemberSince: '31.08.2021',
    club: 'Juventus Turin',
    previousClub: 'FC Everton',
    uid: 'ff5ae476-eaf0-46bc-80cd-aa61e87fb874',
    position: 'Mittelstürmer',
    league: 'Serie A',
    marketValue: 20000000,
  },
  {
    jerseyNumber: 14,
    name: 'Arkadiusz Milik',
    dateOfBirth: '28.02.1994',
    nationality: 'Polen',
    height: 1.86,
    foot: 'links',
    teamMemberSince: '26.08.2022',
    club: 'Juventus Turin',
    previousClub: 'Olympique Marseille',
    uid: '37e22761-b9e7-43d6-b7fb-ed1f5daf4f36',
    position: 'Mittelstürmer',
    league: 'Serie A',
    marketValue: 10000000,
  },
  {
    jerseyNumber: 21,
    name: 'Kaio Jorge',
    dateOfBirth: '24.01.2002',
    nationality: 'Brasilien',
    height: 1.82,
    foot: 'rechts',
    teamMemberSince: '17.08.2021',
    club: 'Juventus Turin',
    previousClub: 'FC Santos',
    uid: 'dc3b48cc-2c23-4f75-be19-e5cdd827839d',
    position: 'Mittelstürmer',
    league: 'Serie A',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 1,
    name: 'Juan Musso',
    dateOfBirth: '06.05.1994',
    nationality: 'Argentinien',
    height: 1.91,
    foot: 'rechts',
    teamMemberSince: '02.07.2021',
    club: 'Atalanta Bergamo',
    previousClub: 'Udinese Calcio',
    uid: '672d576f-32be-44d8-a427-251984123ce4',
    position: 'Torwart',
    league: 'Serie A',
    marketValue: 14000000,
  },
  {
    jerseyNumber: 57,
    name: 'Marco Sportiello',
    dateOfBirth: '10.05.1992',
    nationality: 'Italien',
    height: 1.92,
    foot: 'rechts',
    teamMemberSince: '22.06.2012',
    club: 'Atalanta Bergamo',
    previousClub: 'US Poggibonsi',
    uid: 'db3f3363-8dcd-4c64-86e9-b13f7e8f2f64',
    position: 'Torwart',
    league: 'Serie A',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 31,
    name: 'Francesco Rossi',
    dateOfBirth: '27.04.1991',
    nationality: 'Italien',
    height: 1.93,
    foot: 'rechts',
    teamMemberSince: '01.08.2009',
    club: 'Atalanta Bergamo',
    previousClub: 'Atalanta Bergamo U19',
    uid: 'e5db988f-db44-4c30-b425-cd6775dd9ac6',
    position: 'Torwart',
    league: 'Serie A',
    marketValue: 100000,
  },
  {
    jerseyNumber: 42,
    name: 'Giorgio Scalvini',
    dateOfBirth: '11.12.2003',
    nationality: 'Italien',
    height: 1.94,
    foot: 'rechts',
    teamMemberSince: '09.07.2021',
    club: 'Atalanta Bergamo',
    previousClub: 'Atalanta Bergamo U19',
    uid: '49d3d403-1ba5-4afa-af2f-ead0a2b6ed97',
    position: 'Innenverteidiger',
    league: 'Serie A',
    marketValue: 30000000,
  },
  {
    jerseyNumber: 28,
    name: 'Merih Demiral',
    dateOfBirth: '05.03.1998',
    nationality: 'Türkei',
    height: 1.9,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'Atalanta Bergamo',
    previousClub: 'Juventus Turin',
    uid: 'd76d1feb-7be0-4f03-96c5-bffc1c8ce4e2',
    position: 'Innenverteidiger',
    league: 'Serie A',
    marketValue: 25000000,
  },
  {
    jerseyNumber: 19,
    name: 'Berat Djimsiti',
    dateOfBirth: '19.02.1993',
    nationality: 'Albanien',
    height: 1.9,
    foot: 'rechts',
    teamMemberSince: '18.01.2016',
    club: 'Atalanta Bergamo',
    previousClub: ': Ablöse ablösefrei',
    uid: 'a86d9418-aa0d-4913-ba8f-b805650895f5',
    position: 'Innenverteidiger',
    league: 'Serie A',
    marketValue: 13000000,
  },
  {
    jerseyNumber: 5,
    name: 'Caleb Okoli',
    dateOfBirth: '13.07.2001',
    nationality: 'Italien',
    height: 1.91,
    foot: 'rechts',
    teamMemberSince: '01.09.2020',
    club: 'Atalanta Bergamo',
    previousClub: 'Atalanta Bergamo U19',
    uid: '8890effc-a850-4de3-96d5-6c9bd1b37a48',
    position: 'Innenverteidiger',
    league: 'Serie A',
    marketValue: 10000000,
  },
  {
    jerseyNumber: 2,
    name: 'Rafael Tolói',
    dateOfBirth: '10.10.1990',
    nationality: 'Italien',
    height: 1.85,
    foot: 'rechts',
    teamMemberSince: '26.08.2015',
    club: 'Atalanta Bergamo',
    previousClub: 'FC São Paulo',
    uid: 'b4f7bef1-6605-4584-b772-95bc786468ec',
    position: 'Innenverteidiger',
    league: 'Serie A',
    marketValue: 8000000,
  },
  {
    jerseyNumber: 6,
    name: 'José Luis Palomino',
    dateOfBirth: '05.01.1990',
    nationality: 'Argentinien',
    height: 1.88,
    foot: 'links',
    teamMemberSince: '01.07.2017',
    club: 'Atalanta Bergamo',
    previousClub: ': Ablöse 4,70 Mio. €',
    uid: 'adecb061-6622-4762-964d-613018444e18',
    position: 'Innenverteidiger',
    league: 'Serie A',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 3,
    name: 'Joakim Maehle',
    dateOfBirth: '20.05.1997',
    nationality: 'Dänemark',
    height: 1.85,
    foot: 'rechts',
    teamMemberSince: '04.01.2021',
    club: 'Atalanta Bergamo',
    previousClub: 'KRC Genk',
    uid: '25cdd45b-1f33-4169-8618-9d3f58ddc36e',
    position: 'Linker Verteidiger',
    league: 'Serie A',
    marketValue: 14000000,
  },
  {
    jerseyNumber: 22,
    name: 'Matteo Ruggeri',
    dateOfBirth: '11.07.2002',
    nationality: 'Italien',
    height: 1.87,
    foot: 'links',
    teamMemberSince: '01.09.2020',
    club: 'Atalanta Bergamo',
    previousClub: 'Atalanta Bergamo U19',
    uid: 'e460cb8f-5ae1-4ef5-bfb2-54d712f86bd5',
    position: 'Linker Verteidiger',
    league: 'Serie A',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 93,
    name: 'Brandon Soppy',
    dateOfBirth: '21.02.2002',
    nationality: 'Frankreich',
    height: 1.81,
    foot: 'rechts',
    teamMemberSince: '19.08.2022',
    club: 'Atalanta Bergamo',
    previousClub: 'Udinese Calcio',
    uid: '3607f4ee-6d6d-47ba-afeb-a0219c407a92',
    position: 'Rechter Verteidiger',
    league: 'Serie A',
    marketValue: 10000000,
  },
  {
    jerseyNumber: 77,
    name: 'Davide Zappacosta',
    dateOfBirth: '11.06.1992',
    nationality: 'Italien',
    height: 1.82,
    foot: 'rechts',
    teamMemberSince: '24.08.2021',
    club: 'Atalanta Bergamo',
    previousClub: 'FC Chelsea',
    uid: '5bbd734e-feca-44fc-8585-1ef85b4a0b8f',
    position: 'Rechter Verteidiger',
    league: 'Serie A',
    marketValue: 7000000,
  },
  {
    jerseyNumber: 33,
    name: 'Hans Hateboer',
    dateOfBirth: '09.01.1994',
    nationality: 'Niederlande',
    height: 1.85,
    foot: 'rechts',
    teamMemberSince: '31.01.2017',
    club: 'Atalanta Bergamo',
    previousClub: ': Ablöse 1,04 Mio. €',
    uid: '1623113e-eff0-427e-b636-33ebc0f4acc6',
    position: 'Rechter Verteidiger',
    league: 'Serie A',
    marketValue: 6000000,
  },
  {
    jerseyNumber: 7,
    name: 'Teun Koopmeiners',
    dateOfBirth: '28.02.1998',
    nationality: 'Niederlande',
    height: 1.83,
    foot: 'links',
    teamMemberSince: '30.08.2021',
    club: 'Atalanta Bergamo',
    previousClub: 'AZ Alkmaar',
    uid: '83bfc78e-43de-495f-9f17-b57f8f9d4fbd',
    position: 'Defensives Mittelfeld',
    league: 'Serie A',
    marketValue: 30000000,
  },
  {
    jerseyNumber: 15,
    name: 'Marten de Roon',
    dateOfBirth: '29.03.1991',
    nationality: 'Niederlande',
    height: 1.85,
    foot: 'rechts',
    teamMemberSince: '10.08.2017',
    club: 'Atalanta Bergamo',
    previousClub: 'FC Middlesbrough',
    uid: '0bdb7c9d-5ad9-41d1-8257-c008542304f9',
    position: 'Defensives Mittelfeld',
    league: 'Serie A',
    marketValue: 12000000,
  },
  {
    jerseyNumber: 13,
    name: 'Éderson',
    dateOfBirth: '07.07.1999',
    nationality: 'Brasilien',
    height: 1.83,
    foot: 'rechts',
    teamMemberSince: '06.07.2022',
    club: 'Atalanta Bergamo',
    previousClub: 'US Salernitana 1919',
    uid: '285b2595-01dc-4bef-87a1-829b00106a99',
    position: 'Zentrales Mittelfeld',
    league: 'Serie A',
    marketValue: 20000000,
  },
  {
    jerseyNumber: 88,
    name: 'Mario Pasalic',
    dateOfBirth: '09.02.1995',
    nationality: 'Kroatien',
    height: 1.88,
    foot: 'rechts',
    teamMemberSince: '01.09.2020',
    club: 'Atalanta Bergamo',
    previousClub: 'FC Chelsea',
    uid: 'b1fdd170-b979-4382-9679-4c487c0cc6e9',
    position: 'Offensives Mittelfeld',
    league: 'Serie A',
    marketValue: 25000000,
  },
  {
    jerseyNumber: 10,
    name: 'Jérémie Boga',
    dateOfBirth: '03.01.1997',
    nationality: 'Elfenbeinküste',
    height: 1.74,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'Atalanta Bergamo',
    previousClub: 'US Sassuolo',
    uid: '1cc411fb-6a77-4f96-a36d-472d66e895df',
    position: 'Linksaußen',
    league: 'Serie A',
    marketValue: 15000000,
  },
  {
    jerseyNumber: 23,
    name: 'Lukas Vorlicky',
    dateOfBirth: '18.01.2002',
    nationality: 'Tschechien',
    height: 1.86,
    foot: 'rechts',
    teamMemberSince: '01.01.2023',
    club: 'Atalanta Bergamo',
    previousClub: 'Atalanta Bergamo U19',
    uid: '5cc0eea0-d6e8-40b0-a20d-64d1027fc097',
    position: 'Linksaußen',
    league: 'Serie A',
    marketValue: 100000,
  },
  {
    jerseyNumber: 11,
    name: 'Ademola Lookman',
    dateOfBirth: '20.10.1997',
    nationality: 'Nigeria',
    height: 1.74,
    foot: 'rechts',
    teamMemberSince: '04.08.2022',
    club: 'Atalanta Bergamo',
    previousClub: 'RasenBallsport Leipzig',
    uid: '9ea69dbc-6d8b-4652-99e1-f5d5769b8af7',
    position: 'Hängende Spitze',
    league: 'Serie A',
    marketValue: 30000000,
  },
  {
    jerseyNumber: 17,
    name: 'Rasmus Højlund',
    dateOfBirth: '04.02.2003',
    nationality: 'Dänemark',
    height: 1.91,
    foot: 'links',
    teamMemberSince: '27.08.2022',
    club: 'Atalanta Bergamo',
    previousClub: 'SK Sturm Graz',
    uid: '35df93f8-1303-4037-ae2a-da9a6ea2c47a',
    position: 'Mittelstürmer',
    league: 'Serie A',
    marketValue: 35000000,
  },
  {
    jerseyNumber: 91,
    name: 'Duván Zapata',
    dateOfBirth: '01.04.1991',
    nationality: 'Kolumbien',
    height: 1.89,
    foot: 'rechts',
    teamMemberSince: '17.01.2020',
    club: 'Atalanta Bergamo',
    previousClub: 'Sampdoria Genua',
    uid: '80201079-57f0-4fab-91a7-05b746c71967',
    position: 'Mittelstürmer',
    league: 'Serie A',
    marketValue: 10000000,
  },
  {
    jerseyNumber: 9,
    name: 'Luis Muriel',
    dateOfBirth: '16.04.1991',
    nationality: 'Kolumbien',
    height: 1.79,
    foot: 'rechts',
    teamMemberSince: '01.07.2019',
    club: 'Atalanta Bergamo',
    previousClub: 'FC Sevilla',
    uid: '65db6217-090b-4e4f-9150-c1e1099cc767',
    position: 'Mittelstürmer',
    league: 'Serie A',
    marketValue: 8000000,
  },
  {
    jerseyNumber: 1,
    name: 'Rui Patrício',
    dateOfBirth: '15.02.1988',
    nationality: 'Portugal',
    height: 1.9,
    foot: 'links',
    teamMemberSince: '13.07.2021',
    club: 'AS Rom',
    previousClub: 'Wolverhampton Wanderers',
    uid: '7cf61477-07a8-49e7-ab3a-73e49eb9a7d2',
    position: 'Torwart',
    league: 'Serie A',
    marketValue: 5000000,
  },
  {
    jerseyNumber: 99,
    name: 'Mile Svilar',
    dateOfBirth: '27.08.1999',
    nationality: 'Serbien',
    height: 1.89,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'AS Rom',
    previousClub: 'Benfica Lissabon',
    uid: '01f7d519-74d0-434b-bda4-43d089ff7011',
    position: 'Torwart',
    league: 'Serie A',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 63,
    name: 'Pietro Boer',
    dateOfBirth: '12.05.2002',
    nationality: 'Italien',
    height: 1.93,
    foot: 'rechts',
    teamMemberSince: '01.07.2021',
    club: 'AS Rom',
    previousClub: 'AS Rom U19',
    uid: '241ae46d-996e-4c07-b5eb-d7f5b1d17cc8',
    position: 'Torwart',
    league: 'Serie A',
    marketValue: 150000,
  },
  {
    jerseyNumber: 3,
    name: 'Roger Ibañez',
    dateOfBirth: '23.11.1998',
    nationality: 'Brasilien',
    height: 1.86,
    foot: 'rechts',
    teamMemberSince: '01.07.2021',
    club: 'AS Rom',
    previousClub: 'Atalanta Bergamo',
    uid: 'f498a0e3-6a24-4bcd-a177-20e8c5aa8d69',
    position: 'Innenverteidiger',
    league: 'Serie A',
    marketValue: 30000000,
  },
  {
    jerseyNumber: 23,
    name: 'Gianluca Mancini',
    dateOfBirth: '17.04.1996',
    nationality: 'Italien',
    height: 1.9,
    foot: 'rechts',
    teamMemberSince: '01.09.2020',
    club: 'AS Rom',
    previousClub: 'Atalanta Bergamo',
    uid: '1f812e33-bdf3-44d2-b1c3-dd45ec089335',
    position: 'Innenverteidiger',
    league: 'Serie A',
    marketValue: 18000000,
  },
  {
    jerseyNumber: 14,
    name: 'Diego Llorente',
    dateOfBirth: '16.08.1993',
    nationality: 'Spanien',
    height: 1.86,
    foot: 'rechts',
    teamMemberSince: '31.01.2023',
    club: 'AS Rom',
    previousClub: 'Leeds United',
    uid: '0beb7ce1-cb05-4dbc-a679-b74028d877c3',
    position: 'Innenverteidiger',
    league: 'Serie A',
    marketValue: 12000000,
  },
  {
    jerseyNumber: 24,
    name: 'Marash Kumbulla',
    dateOfBirth: '08.02.2000',
    nationality: 'Albanien',
    height: 1.91,
    foot: 'rechts',
    teamMemberSince: '01.07.2021',
    club: 'AS Rom',
    previousClub: 'Hellas Verona',
    uid: '472bbc89-5dfe-41ce-8aaa-f9ec7d533ce0',
    position: 'Innenverteidiger',
    league: 'Serie A',
    marketValue: 9000000,
  },
  {
    jerseyNumber: 6,
    name: 'Chris Smalling',
    dateOfBirth: '22.11.1989',
    nationality: 'England',
    height: 1.94,
    foot: 'rechts',
    teamMemberSince: '05.10.2020',
    club: 'AS Rom',
    previousClub: 'Manchester United',
    uid: 'd43c2271-8735-4c69-828a-5a6a02bdeedf',
    position: 'Innenverteidiger',
    league: 'Serie A',
    marketValue: 8000000,
  },
  {
    jerseyNumber: 37,
    name: 'Leonardo Spinazzola',
    dateOfBirth: '25.03.1993',
    nationality: 'Italien',
    height: 1.86,
    foot: 'rechts',
    teamMemberSince: '01.07.2019',
    club: 'AS Rom',
    previousClub: ': Ablöse 29,50 Mio. €',
    uid: 'd884d6b3-f994-4338-9e80-368d4a972a5a',
    position: 'Linker Verteidiger',
    league: 'Serie A',
    marketValue: 15000000,
  },
  {
    jerseyNumber: 19,
    name: 'Zeki Celik',
    dateOfBirth: '17.02.1997',
    nationality: 'Türkei',
    height: 1.8,
    foot: 'rechts',
    teamMemberSince: '05.07.2022',
    club: 'AS Rom',
    previousClub: 'LOSC Lille',
    uid: 'e3eae816-6d55-4d86-89d2-eb8b8f552415',
    position: 'Rechter Verteidiger',
    league: 'Serie A',
    marketValue: 10000000,
  },
  {
    jerseyNumber: 2,
    name: 'Rick Karsdorp',
    dateOfBirth: '11.02.1995',
    nationality: 'Niederlande',
    height: 1.84,
    foot: 'rechts',
    teamMemberSince: '01.07.2017',
    club: 'AS Rom',
    previousClub: 'Feyenoord Rotterdam',
    uid: '9ac26c84-a003-4eab-830c-316e275e152c',
    position: 'Rechter Verteidiger',
    league: 'Serie A',
    marketValue: 9000000,
  },
  {
    jerseyNumber: 4,
    name: 'Bryan Cristante',
    dateOfBirth: '03.03.1995',
    nationality: 'Italien',
    height: 1.86,
    foot: 'rechts',
    teamMemberSince: '01.07.2019',
    club: 'AS Rom',
    previousClub: 'Atalanta Bergamo',
    uid: 'e85a9cc4-d589-4db1-8cb4-cc468e66a219',
    position: 'Defensives Mittelfeld',
    league: 'Serie A',
    marketValue: 20000000,
  },
  {
    jerseyNumber: 8,
    name: 'Nemanja Matic',
    dateOfBirth: '01.08.1988',
    nationality: 'Serbien',
    height: 1.94,
    foot: 'links',
    teamMemberSince: '01.07.2022',
    club: 'AS Rom',
    previousClub: 'Manchester United',
    uid: 'df545da9-1cce-4041-b36e-2083ea309367',
    position: 'Defensives Mittelfeld',
    league: 'Serie A',
    marketValue: 4000000,
  },
  {
    jerseyNumber: 55,
    name: 'Ebrima Darboe',
    dateOfBirth: '06.06.2001',
    nationality: 'Gambia',
    height: 1.79,
    foot: 'rechts',
    teamMemberSince: '01.07.2021',
    club: 'AS Rom',
    previousClub: 'AS Rom U19',
    uid: 'f2a1e3a7-fa40-4ab5-8792-1c3a522d0eda',
    position: 'Defensives Mittelfeld',
    league: 'Serie A',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 25,
    name: 'Georginio Wijnaldum',
    dateOfBirth: '11.11.1990',
    nationality: 'Niederlande',
    height: 1.75,
    foot: 'rechts',
    teamMemberSince: '05.08.2022',
    club: 'AS Rom',
    previousClub: 'FC Paris Saint-Germain',
    uid: '7d224fd4-3ec1-4f4a-9a77-2089d69d4b01',
    position: 'Zentrales Mittelfeld',
    league: 'Serie A',
    marketValue: 12000000,
  },
  {
    jerseyNumber: 20,
    name: 'Mady Camara',
    dateOfBirth: '28.02.1997',
    nationality: 'Guinea',
    height: 1.82,
    foot: 'rechts',
    teamMemberSince: '31.08.2022',
    club: 'AS Rom',
    previousClub: 'Olympiakos Piräus',
    uid: '2f89d5fd-c606-4e51-881e-de01505bbfc3',
    position: 'Zentrales Mittelfeld',
    league: 'Serie A',
    marketValue: 11000000,
  },
  {
    jerseyNumber: 52,
    name: 'Edoardo Bove',
    dateOfBirth: '16.05.2002',
    nationality: 'Italien',
    height: 1.81,
    foot: 'rechts',
    teamMemberSince: '01.07.2021',
    club: 'AS Rom',
    previousClub: 'AS Rom U19',
    uid: '66834fd5-7a07-4d04-96b2-d8bc67b2dd04',
    position: 'Zentrales Mittelfeld',
    league: 'Serie A',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 68,
    name: 'Benjamin Tahirovic',
    dateOfBirth: '03.03.2003',
    nationality: 'Bosnien-Herzegowina',
    height: 1.93,
    foot: 'rechts',
    teamMemberSince: '01.11.2022',
    club: 'AS Rom',
    previousClub: 'AS Rom U19',
    uid: 'dc6c57f3-6f48-4548-8a96-3f052410a970',
    position: 'Zentrales Mittelfeld',
    league: 'Serie A',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 59,
    name: 'Nicola Zalewski',
    dateOfBirth: '23.01.2002',
    nationality: 'Polen',
    height: 1.75,
    foot: 'beidfüßig',
    teamMemberSince: '01.07.2021',
    club: 'AS Rom',
    previousClub: 'AS Rom U19',
    uid: 'e4c573df-d4e2-4aac-9290-ecf304704489',
    position: 'Linkes Mittelfeld',
    league: 'Serie A',
    marketValue: 15000000,
  },
  {
    jerseyNumber: 7,
    name: 'Lorenzo Pellegrini',
    dateOfBirth: '19.06.1996',
    nationality: 'Italien',
    height: 1.86,
    foot: 'beidfüßig',
    teamMemberSince: '01.07.2017',
    club: 'AS Rom',
    previousClub: ': Ablöse 10,00 Mio. €',
    uid: 'c9dd2299-3ff7-403a-8dbc-4370b9be4d61',
    position: 'Offensives Mittelfeld',
    league: 'Serie A',
    marketValue: 35000000,
  },
  {
    jerseyNumber: 62,
    name: 'Cristian Volpato',
    dateOfBirth: '15.11.2003',
    nationality: 'Italien',
    height: 1.87,
    foot: 'links',
    teamMemberSince: '01.10.2022',
    club: 'AS Rom',
    previousClub: 'AS Rom U19',
    uid: 'eeb48f40-0556-4815-9640-03dbbf898ab7',
    position: 'Offensives Mittelfeld',
    league: 'Serie A',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 92,
    name: 'Stephan El Shaarawy',
    dateOfBirth: '27.10.1992',
    nationality: 'Italien',
    height: 1.78,
    foot: 'rechts',
    teamMemberSince: '30.01.2021',
    club: 'AS Rom',
    previousClub: 'Shanghai Shenhua',
    uid: '7fef1378-ae51-40cd-a089-f504875d3a17',
    position: 'Linksaußen',
    league: 'Serie A',
    marketValue: 5000000,
  },
  {
    jerseyNumber: 18,
    name: 'Ola Solbakken',
    dateOfBirth: '07.09.1998',
    nationality: 'Norwegen',
    height: 1.86,
    foot: 'links',
    teamMemberSince: '02.01.2023',
    club: 'AS Rom',
    previousClub: 'FK Bodø/Glimt',
    uid: '0eeb5f81-e7bb-4704-aecf-82496c2d4ee8',
    position: 'Rechtsaußen',
    league: 'Serie A',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 21,
    name: 'Paulo Dybala',
    dateOfBirth: '15.11.1993',
    nationality: 'Argentinien',
    height: 1.77,
    foot: 'links',
    teamMemberSince: '20.07.2022',
    club: 'AS Rom',
    previousClub: 'Juventus Turin',
    uid: '96d2b448-9f55-416f-af32-c7b998b65b51',
    position: 'Hängende Spitze',
    league: 'Serie A',
    marketValue: 30000000,
  },
  {
    jerseyNumber: 9,
    name: 'Tammy Abraham',
    dateOfBirth: '02.10.1997',
    nationality: 'England',
    height: 1.94,
    foot: 'rechts',
    teamMemberSince: '17.08.2021',
    club: 'AS Rom',
    previousClub: 'FC Chelsea',
    uid: '627a5101-9fcb-4e29-a662-cbc80afca2a6',
    position: 'Mittelstürmer',
    league: 'Serie A',
    marketValue: 45000000,
  },
  {
    jerseyNumber: 11,
    name: 'Andrea Belotti',
    dateOfBirth: '20.12.1993',
    nationality: 'Italien',
    height: 1.81,
    foot: 'rechts',
    teamMemberSince: '28.08.2022',
    club: 'AS Rom',
    previousClub: 'FC Turin',
    uid: '0998d950-ac01-4b16-85f5-3d46cd16f23b',
    position: 'Mittelstürmer',
    league: 'Serie A',
    marketValue: 9000000,
  },
  {
    jerseyNumber: 94,
    name: 'Ivan Provedel',
    dateOfBirth: '17.03.1994',
    nationality: 'Italien',
    height: 1.94,
    foot: 'rechts',
    teamMemberSince: '08.08.2022',
    club: 'Lazio Rom',
    previousClub: 'Spezia Calcio',
    uid: 'fbefc2ed-d202-4788-b6d1-fd901b58d7f7',
    position: 'Torwart',
    league: 'Serie A',
    marketValue: 12000000,
  },
  {
    jerseyNumber: 1,
    name: 'Luís Maximiano',
    dateOfBirth: '05.01.1999',
    nationality: 'Portugal',
    height: 1.9,
    foot: 'beidfüßig',
    teamMemberSince: '13.07.2022',
    club: 'Lazio Rom',
    previousClub: 'FC Granada',
    uid: 'aa1bedc1-62fb-49ed-b008-914de9a96bda',
    position: 'Torwart',
    league: 'Serie A',
    marketValue: 8000000,
  },
  {
    jerseyNumber: 31,
    name: 'Marius Adamonis',
    dateOfBirth: '13.05.1997',
    nationality: 'Litauen',
    height: 1.91,
    foot: 'rechts',
    teamMemberSince: '01.07.2017',
    club: 'Lazio Rom',
    previousClub: 'Lazio Rom U19',
    uid: 'af257798-4c13-4727-b80d-91ce72f6cbaf',
    position: 'Torwart',
    league: 'Serie A',
    marketValue: 150000,
  },
  {
    jerseyNumber: 13,
    name: 'Alessio Romagnoli',
    dateOfBirth: '12.01.1995',
    nationality: 'Italien',
    height: 1.85,
    foot: 'links',
    teamMemberSince: '12.07.2022',
    club: 'Lazio Rom',
    previousClub: 'AC Mailand',
    uid: '31a3d3a1-652b-4d40-99b7-adf1556660c8',
    position: 'Innenverteidiger',
    league: 'Serie A',
    marketValue: 18000000,
  },
  {
    jerseyNumber: 15,
    name: 'Nicolò Casale',
    dateOfBirth: '14.02.1998',
    nationality: 'Italien',
    height: 1.91,
    foot: 'rechts',
    teamMemberSince: '08.07.2022',
    club: 'Lazio Rom',
    previousClub: 'Hellas Verona',
    uid: 'cdb968f8-bcb2-4f3b-b04c-611409a47a73',
    position: 'Innenverteidiger',
    league: 'Serie A',
    marketValue: 17000000,
  },
  {
    jerseyNumber: 4,
    name: 'Patric',
    dateOfBirth: '17.04.1993',
    nationality: 'Spanien',
    height: 1.84,
    foot: 'rechts',
    teamMemberSince: '01.07.2015',
    club: 'Lazio Rom',
    previousClub: 'FC Barcelona B',
    uid: '01b963de-81cb-4cb7-93b8-07e9a4012950',
    position: 'Innenverteidiger',
    league: 'Serie A',
    marketValue: 6000000,
  },
  {
    jerseyNumber: 34,
    name: 'Mario Gila',
    dateOfBirth: '29.08.2000',
    nationality: 'Spanien',
    height: 1.85,
    foot: 'rechts',
    teamMemberSince: '12.07.2022',
    club: 'Lazio Rom',
    previousClub: 'Real Madrid Castilla',
    uid: '564df1af-f9ac-4285-8a91-d195356c924a',
    position: 'Innenverteidiger',
    league: 'Serie A',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 3,
    name: 'Luca Pellegrini',
    dateOfBirth: '07.03.1999',
    nationality: 'Italien',
    height: 1.78,
    foot: 'links',
    teamMemberSince: '31.01.2023',
    club: 'Lazio Rom',
    previousClub: 'Juventus Turin',
    uid: 'e775929c-b676-4ed6-83e6-a073082e6650',
    position: 'Linker Verteidiger',
    league: 'Serie A',
    marketValue: 9000000,
  },
  {
    jerseyNumber: 26,
    name: 'Stefan Radu',
    dateOfBirth: '22.10.1986',
    nationality: 'Rumänien',
    height: 1.83,
    foot: 'links',
    teamMemberSince: '01.07.2008',
    club: 'Lazio Rom',
    previousClub: 'FC Dinamo 1948',
    uid: '8d271eb4-e5c9-486b-83ff-bf94634bea96',
    position: 'Linker Verteidiger',
    league: 'Serie A',
    marketValue: 400000,
  },
  {
    jerseyNumber: 29,
    name: 'Manuel Lazzari',
    dateOfBirth: '29.11.1993',
    nationality: 'Italien',
    height: 1.74,
    foot: 'rechts',
    teamMemberSince: '12.07.2019',
    club: 'Lazio Rom',
    previousClub: 'SPAL',
    uid: '7aff37d9-87c8-40a3-8c5d-1298c0c26953',
    position: 'Rechter Verteidiger',
    league: 'Serie A',
    marketValue: 15000000,
  },
  {
    jerseyNumber: 77,
    name: 'Adam Marusic',
    dateOfBirth: '17.10.1992',
    nationality: 'Montenegro',
    height: 1.85,
    foot: 'rechts',
    teamMemberSince: '01.07.2017',
    club: 'Lazio Rom',
    previousClub: ': Ablöse 5,50 Mio. €',
    uid: '126a9747-f0a8-4444-b524-eb251c6a215a',
    position: 'Rechter Verteidiger',
    league: 'Serie A',
    marketValue: 7000000,
  },
  {
    jerseyNumber: 23,
    name: 'Elseid Hysaj',
    dateOfBirth: '02.02.1994',
    nationality: 'Albanien',
    height: 1.82,
    foot: 'rechts',
    teamMemberSince: '01.07.2021',
    club: 'Lazio Rom',
    previousClub: 'SSC Neapel',
    uid: 'd2cd5949-86df-4d96-84ae-d78f8abcb7fc',
    position: 'Rechter Verteidiger',
    league: 'Serie A',
    marketValue: 4000000,
  },
  {
    jerseyNumber: 32,
    name: 'Danilo Cataldi',
    dateOfBirth: '06.08.1994',
    nationality: 'Italien',
    height: 1.8,
    foot: 'rechts',
    teamMemberSince: '01.07.2013',
    club: 'Lazio Rom',
    previousClub: 'Lazio Rom U19',
    uid: '033835a9-3cfb-4f57-8f53-9c41ab520330',
    position: 'Defensives Mittelfeld',
    league: 'Serie A',
    marketValue: 7000000,
  },
  {
    jerseyNumber: 50,
    name: 'Marco Bertini',
    dateOfBirth: '07.08.2002',
    nationality: 'Italien',
    height: 1.85,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'Lazio Rom',
    previousClub: 'Lazio Rom U19',
    uid: '448624a1-a10f-4272-8e7f-6e064efef9f9',
    position: 'Defensives Mittelfeld',
    league: 'Serie A',
    marketValue: 100000,
  },
  {
    jerseyNumber: 21,
    name: 'Sergej Milinkovic-Savic',
    dateOfBirth: '27.02.1995',
    nationality: 'Serbien',
    height: 1.91,
    foot: 'rechts',
    teamMemberSince: '06.08.2015',
    club: 'Lazio Rom',
    previousClub: ': Ablöse 12,00 Mio. €',
    uid: 'f3bdf0e2-54c9-4158-96ac-1a61a2f14157',
    position: 'Zentrales Mittelfeld',
    league: 'Serie A',
    marketValue: 60000000,
  },
  {
    jerseyNumber: 10,
    name: 'Luis Alberto',
    dateOfBirth: '28.09.1992',
    nationality: 'Spanien',
    height: 1.83,
    foot: 'rechts',
    teamMemberSince: '31.08.2016',
    club: 'Lazio Rom',
    previousClub: 'FC Liverpool',
    uid: 'e6b873f8-902d-415d-bb5e-c72f8c178963',
    position: 'Zentrales Mittelfeld',
    league: 'Serie A',
    marketValue: 18000000,
  },
  {
    jerseyNumber: 6,
    name: 'Marcos Antônio',
    dateOfBirth: '13.06.2000',
    nationality: 'Brasilien',
    height: 1.66,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'Lazio Rom',
    previousClub: 'Shakhtar Donetsk',
    uid: '6a0a6053-c4e9-492f-914a-46045bcda63b',
    position: 'Zentrales Mittelfeld',
    league: 'Serie A',
    marketValue: 8000000,
  },
  {
    jerseyNumber: 88,
    name: 'Toma Basic',
    dateOfBirth: '25.11.1996',
    nationality: 'Kroatien',
    height: 1.9,
    foot: 'links',
    teamMemberSince: '25.08.2021',
    club: 'Lazio Rom',
    previousClub: 'FC Girondins Bordeaux',
    uid: '14adc52a-3412-4926-b913-b851502a7c08',
    position: 'Zentrales Mittelfeld',
    league: 'Serie A',
    marketValue: 7000000,
  },
  {
    jerseyNumber: 5,
    name: 'Matías Vecino',
    dateOfBirth: '24.08.1991',
    nationality: 'Uruguay',
    height: 1.87,
    foot: 'rechts',
    teamMemberSince: '01.08.2022',
    club: 'Lazio Rom',
    previousClub: 'Inter Mailand',
    uid: 'd1bd422f-a0e0-44e2-9956-720bebce39f1',
    position: 'Zentrales Mittelfeld',
    league: 'Serie A',
    marketValue: 4000000,
  },
  {
    jerseyNumber: 96,
    name: 'Mohamed Fares',
    dateOfBirth: '15.02.1996',
    nationality: 'Algerien',
    height: 1.83,
    foot: 'links',
    teamMemberSince: '01.10.2020',
    club: 'Lazio Rom',
    previousClub: 'SPAL',
    uid: '6a1ae4a8-2ba8-4835-8c74-a4eadb98770d',
    position: 'Linkes Mittelfeld',
    league: 'Serie A',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 20,
    name: 'Mattia Zaccagni',
    dateOfBirth: '16.06.1995',
    nationality: 'Italien',
    height: 1.77,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'Lazio Rom',
    previousClub: 'Hellas Verona',
    uid: '8a9bc843-de69-461d-8086-92b095bf9f3f',
    position: 'Linksaußen',
    league: 'Serie A',
    marketValue: 25000000,
  },
  {
    jerseyNumber: 7,
    name: 'Felipe Anderson',
    dateOfBirth: '15.04.1993',
    nationality: 'Brasilien',
    height: 1.75,
    foot: 'rechts',
    teamMemberSince: '16.07.2021',
    club: 'Lazio Rom',
    previousClub: 'West Ham United',
    uid: 'a0c8cc16-eb2e-4189-aacb-bae0a1ddec17',
    position: 'Rechtsaußen',
    league: 'Serie A',
    marketValue: 15000000,
  },
  {
    jerseyNumber: 11,
    name: 'Matteo Cancellieri',
    dateOfBirth: '12.02.2002',
    nationality: 'Italien',
    height: 1.8,
    foot: 'links',
    teamMemberSince: '01.07.2022',
    club: 'Lazio Rom',
    previousClub: 'Hellas Verona',
    uid: '35f8f815-20ca-4b1e-b588-198c42a7bba1',
    position: 'Rechtsaußen',
    league: 'Serie A',
    marketValue: 5000000,
  },
  {
    jerseyNumber: 9,
    name: 'Pedro',
    dateOfBirth: '28.07.1987',
    nationality: 'Spanien',
    height: 1.67,
    foot: 'beidfüßig',
    teamMemberSince: '19.08.2021',
    club: 'Lazio Rom',
    previousClub: 'AS Rom',
    uid: '318ceb83-0a12-43ba-8c64-fc86146f3efd',
    position: 'Rechtsaußen',
    league: 'Serie A',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 18,
    name: 'Luka Romero',
    dateOfBirth: '18.11.2004',
    nationality: 'Argentinien',
    height: 1.65,
    foot: 'links',
    teamMemberSince: '20.07.2021',
    club: 'Lazio Rom',
    previousClub: 'RCD Mallorca B',
    uid: 'defce717-57d3-445d-ae4a-d61ede84750b',
    position: 'Rechtsaußen',
    league: 'Serie A',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 17,
    name: 'Ciro Immobile',
    dateOfBirth: '20.02.1990',
    nationality: 'Italien',
    height: 1.85,
    foot: 'rechts',
    teamMemberSince: '27.07.2016',
    club: 'Lazio Rom',
    previousClub: 'FC Sevilla',
    uid: '012604a8-7f39-4b60-9265-406d3d64d31d',
    position: 'Mittelstürmer',
    league: 'Serie A',
    marketValue: 18000000,
  },
  {
    jerseyNumber: 1,
    name: 'Pietro Terracciano',
    dateOfBirth: '08.03.1990',
    nationality: 'Italien',
    height: 1.93,
    foot: 'rechts',
    teamMemberSince: '07.07.2019',
    club: 'AC Florenz',
    previousClub: ': Ablöse ablösefrei',
    uid: '3978a563-dac8-4209-8275-3ece361d15e8',
    position: 'Torwart',
    league: 'Serie A',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 56,
    name: 'Salvatore Sirigu',
    dateOfBirth: '12.01.1987',
    nationality: 'Italien',
    height: 1.92,
    foot: 'rechts',
    teamMemberSince: '25.01.2023',
    club: 'AC Florenz',
    previousClub: 'SSC Neapel',
    uid: 'befbb6ae-72cc-47b8-8630-b8888e53d395',
    position: 'Torwart',
    league: 'Serie A',
    marketValue: 600000,
  },
  {
    jerseyNumber: 31,
    name: 'Michele Cerofolini',
    dateOfBirth: '04.01.1999',
    nationality: 'Italien',
    height: 1.88,
    foot: 'rechts',
    teamMemberSince: '01.07.2017',
    club: 'AC Florenz',
    previousClub: 'AC Florenz U19',
    uid: 'bcd0638b-f642-4318-8ace-3a8df5d56138',
    position: 'Torwart',
    league: 'Serie A',
    marketValue: 300000,
  },
  {
    jerseyNumber: 4,
    name: 'Nikola Milenkovic',
    dateOfBirth: '12.10.1997',
    nationality: 'Serbien',
    height: 1.95,
    foot: 'rechts',
    teamMemberSince: '01.07.2017',
    club: 'AC Florenz',
    previousClub: 'FK Partizan Belgrad',
    uid: '7e5768e8-18be-49ea-bafc-6cbb1193748f',
    position: 'Innenverteidiger',
    league: 'Serie A',
    marketValue: 20000000,
  },
  {
    jerseyNumber: 98,
    name: 'Igor',
    dateOfBirth: '07.02.1998',
    nationality: 'Brasilien',
    height: 1.85,
    foot: 'links',
    teamMemberSince: '01.07.2021',
    club: 'AC Florenz',
    previousClub: 'SPAL',
    uid: '81acc31c-3d02-4b03-a904-ccc1323bb453',
    position: 'Innenverteidiger',
    league: 'Serie A',
    marketValue: 11000000,
  },
  {
    jerseyNumber: 28,
    name: 'Lucas Martínez Quarta',
    dateOfBirth: '10.05.1996',
    nationality: 'Argentinien',
    height: 1.83,
    foot: 'rechts',
    teamMemberSince: '05.10.2020',
    club: 'AC Florenz',
    previousClub: ': Ablöse 13,00 Mio. €',
    uid: 'f7b78c99-b1a6-42dd-9d33-18b9cdda0772',
    position: 'Innenverteidiger',
    league: 'Serie A',
    marketValue: 10000000,
  },
  {
    jerseyNumber: 16,
    name: 'Luca Ranieri',
    dateOfBirth: '23.04.1999',
    nationality: 'Italien',
    height: 1.87,
    foot: 'links',
    teamMemberSince: '01.07.2018',
    club: 'AC Florenz',
    previousClub: 'AC Florenz U19',
    uid: '1d0ca3e4-a6e1-45c3-8c92-d233996d8ccc',
    position: 'Innenverteidiger',
    league: 'Serie A',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 3,
    name: 'Cristiano Biraghi',
    dateOfBirth: '01.09.1992',
    nationality: 'Italien',
    height: 1.85,
    foot: 'links',
    teamMemberSince: '01.07.2018',
    club: 'AC Florenz',
    previousClub: ': Ablöse 3,00 Mio. €',
    uid: 'e0105fb4-155b-480f-a757-b6954a151baa',
    position: 'Linker Verteidiger',
    league: 'Serie A',
    marketValue: 6000000,
  },
  {
    jerseyNumber: 15,
    name: 'Aleksa Terzic',
    dateOfBirth: '17.08.1999',
    nationality: 'Serbien',
    height: 1.84,
    foot: 'links',
    teamMemberSince: '01.07.2019',
    club: 'AC Florenz',
    previousClub: 'Roter Stern Belgrad',
    uid: '9eac839b-a49b-4f5c-a4cd-8235e5a36adb',
    position: 'Linker Verteidiger',
    league: 'Serie A',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 2,
    name: 'Dodô',
    dateOfBirth: '17.11.1998',
    nationality: 'Brasilien',
    height: 1.66,
    foot: 'rechts',
    teamMemberSince: '22.07.2022',
    club: 'AC Florenz',
    previousClub: 'Shakhtar Donetsk',
    uid: 'ac0b1b77-a497-4d33-ade9-2a10e66ae4b9',
    position: 'Rechter Verteidiger',
    league: 'Serie A',
    marketValue: 17000000,
  },
  {
    jerseyNumber: 23,
    name: 'Lorenzo Venuti',
    dateOfBirth: '12.04.1995',
    nationality: 'Italien',
    height: 1.79,
    foot: 'rechts',
    teamMemberSince: '01.07.2014',
    club: 'AC Florenz',
    previousClub: 'AC Florenz U19',
    uid: '472ef819-a4c9-421e-a98e-576a9004bead',
    position: 'Rechter Verteidiger',
    league: 'Serie A',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 34,
    name: 'Sofyan Amrabat',
    dateOfBirth: '21.08.1996',
    nationality: 'Marokko',
    height: 1.85,
    foot: 'rechts',
    teamMemberSince: '30.01.2020',
    club: 'AC Florenz',
    previousClub: ': Ablöse 19,50 Mio. €',
    uid: 'f8ce0f73-1b42-41d0-a0bc-36ae7f519405',
    position: 'Defensives Mittelfeld',
    league: 'Serie A',
    marketValue: 25000000,
  },
  {
    jerseyNumber: 38,
    name: 'Rolando Mandragora',
    dateOfBirth: '29.06.1997',
    nationality: 'Italien',
    height: 1.83,
    foot: 'links',
    teamMemberSince: '04.07.2022',
    club: 'AC Florenz',
    previousClub: 'Juventus Turin',
    uid: '2a3a35b1-2090-4805-bb5e-cb72b9cebf0c',
    position: 'Defensives Mittelfeld',
    league: 'Serie A',
    marketValue: 9000000,
  },
  {
    jerseyNumber: 10,
    name: 'Gaetano Castrovilli',
    dateOfBirth: '17.02.1997',
    nationality: 'Italien',
    height: 1.87,
    foot: 'rechts',
    teamMemberSince: '01.07.2017',
    club: 'AC Florenz',
    previousClub: ': Ablöse 1,90 Mio. €',
    uid: 'ad2a648b-a10c-4c79-afcf-b4511d967259',
    position: 'Zentrales Mittelfeld',
    league: 'Serie A',
    marketValue: 10000000,
  },
  {
    jerseyNumber: 32,
    name: 'Alfred Duncan',
    dateOfBirth: '10.03.1993',
    nationality: 'Ghana',
    height: 1.78,
    foot: 'links',
    teamMemberSince: '01.09.2020',
    club: 'AC Florenz',
    previousClub: ': Ablöse 15,00 Mio. €',
    uid: 'a15306c8-17eb-45ab-98ea-29a52dce660e',
    position: 'Zentrales Mittelfeld',
    league: 'Serie A',
    marketValue: 5000000,
  },
  {
    jerseyNumber: 5,
    name: 'Giacomo Bonaventura',
    dateOfBirth: '22.08.1989',
    nationality: 'Italien',
    height: 1.8,
    foot: 'rechts',
    teamMemberSince: '10.09.2020',
    club: 'AC Florenz',
    previousClub: 'AC Mailand',
    uid: '027d7ac5-4ab2-4475-a1d4-53048612b674',
    position: 'Zentrales Mittelfeld',
    league: 'Serie A',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 42,
    name: 'Alessandro Bianco',
    dateOfBirth: '01.10.2002',
    nationality: 'Italien',
    height: 1.73,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'AC Florenz',
    previousClub: 'AC Florenz U19',
    uid: '77011a3c-5cb9-4871-b35a-3ce56da5bf47',
    position: 'Zentrales Mittelfeld',
    league: 'Serie A',
    marketValue: 600000,
  },
  {
    jerseyNumber: 72,
    name: 'Antonin Barak',
    dateOfBirth: '03.12.1994',
    nationality: 'Tschechien',
    height: 1.9,
    foot: 'links',
    teamMemberSince: '31.01.2023',
    club: 'AC Florenz',
    previousClub: 'Hellas Verona',
    uid: 'fdadcc68-45e0-4449-9f0b-90f6793d45e1',
    position: 'Offensives Mittelfeld',
    league: 'Serie A',
    marketValue: 12000000,
  },
  {
    jerseyNumber: 8,
    name: 'Riccardo Saponara',
    dateOfBirth: '21.12.1991',
    nationality: 'Italien',
    height: 1.84,
    foot: 'rechts',
    teamMemberSince: '01.07.2018',
    club: 'AC Florenz',
    previousClub: ': Ablöse 9,00 Mio. €',
    uid: 'e579eab2-db18-479f-b19f-5ce6ebf1846f',
    position: 'Offensives Mittelfeld',
    league: 'Serie A',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 22,
    name: 'Nicolás González',
    dateOfBirth: '06.04.1998',
    nationality: 'Argentinien',
    height: 1.8,
    foot: 'links',
    teamMemberSince: '01.07.2021',
    club: 'AC Florenz',
    previousClub: 'VfB Stuttgart',
    uid: '319d0faf-4ec9-42bd-98af-1d77eba34d42',
    position: 'Linksaußen',
    league: 'Serie A',
    marketValue: 28000000,
  },
  {
    jerseyNumber: 33,
    name: 'Riccardo Sottil',
    dateOfBirth: '03.06.1999',
    nationality: 'Italien',
    height: 1.8,
    foot: 'rechts',
    teamMemberSince: '01.07.2018',
    club: 'AC Florenz',
    previousClub: 'AC Florenz U19',
    uid: 'eb1d28c2-9bb0-44e6-bd01-8d1de25abeda',
    position: 'Linksaußen',
    league: 'Serie A',
    marketValue: 9000000,
  },
  {
    jerseyNumber: 77,
    name: 'Josip Brekalo',
    dateOfBirth: '23.06.1998',
    nationality: 'Kroatien',
    height: 1.75,
    foot: 'rechts',
    teamMemberSince: '28.01.2023',
    club: 'AC Florenz',
    previousClub: 'VfL Wolfsburg',
    uid: '1ffede29-840f-48ce-acb5-cb529834ca7a',
    position: 'Linksaußen',
    league: 'Serie A',
    marketValue: 6000000,
  },
  {
    jerseyNumber: 11,
    name: 'Jonathan Ikoné',
    dateOfBirth: '02.05.1998',
    nationality: 'Frankreich',
    height: 1.75,
    foot: 'links',
    teamMemberSince: '03.01.2022',
    club: 'AC Florenz',
    previousClub: 'LOSC Lille',
    uid: 'c7ad19a0-e7f1-4bf6-9343-6a1b297802b6',
    position: 'Rechtsaußen',
    league: 'Serie A',
    marketValue: 16000000,
  },
  {
    jerseyNumber: 9,
    name: 'Arthur Cabral',
    dateOfBirth: '25.04.1998',
    nationality: 'Brasilien',
    height: 1.86,
    foot: 'rechts',
    teamMemberSince: '29.01.2022',
    club: 'AC Florenz',
    previousClub: 'FC Basel 1893',
    uid: '2f60013d-8198-447e-b6be-641178a076d9',
    position: 'Mittelstürmer',
    league: 'Serie A',
    marketValue: 14000000,
  },
  {
    jerseyNumber: 99,
    name: 'Christian Kouamé',
    dateOfBirth: '06.12.1997',
    nationality: 'Elfenbeinküste',
    height: 1.85,
    foot: 'rechts',
    teamMemberSince: '01.09.2020',
    club: 'AC Florenz',
    previousClub: ': Ablöse 11,00 Mio. €',
    uid: 'a99c241c-2f06-468d-9929-7c95c86dfee5',
    position: 'Mittelstürmer',
    league: 'Serie A',
    marketValue: 13000000,
  },
  {
    jerseyNumber: 7,
    name: 'Luka Jovic',
    dateOfBirth: '23.12.1997',
    nationality: 'Serbien',
    height: 1.82,
    foot: 'beidfüßig',
    teamMemberSince: '08.07.2022',
    club: 'AC Florenz',
    previousClub: 'Real Madrid',
    uid: '46db42ed-5d52-481a-9be4-8a234bdc1006',
    position: 'Mittelstürmer',
    league: 'Serie A',
    marketValue: 10000000,
  },
  {
    jerseyNumber: 47,
    name: 'Andrea Consigli',
    dateOfBirth: '27.01.1987',
    nationality: 'Italien',
    height: 1.89,
    foot: 'rechts',
    teamMemberSince: '01.09.2014',
    club: 'US Sassuolo',
    previousClub: 'Atalanta Bergamo',
    uid: '95063487-405d-4026-b997-1351d02d9d2a',
    position: 'Torwart',
    league: 'Serie A',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 64,
    name: 'Alessandro Russo',
    dateOfBirth: '31.03.2001',
    nationality: 'Italien',
    height: 1.93,
    foot: 'rechts',
    teamMemberSince: '26.07.2019',
    club: 'US Sassuolo',
    previousClub: ': Ablöse 7,00 Mio. €',
    uid: '91b6a28c-42f1-46c0-b523-6200fb430460',
    position: 'Torwart',
    league: 'Serie A',
    marketValue: 500000,
  },
  {
    jerseyNumber: 25,
    name: 'Gianluca Pegolo',
    dateOfBirth: '25.03.1981',
    nationality: 'Italien',
    height: 1.83,
    foot: 'links',
    teamMemberSince: '02.09.2013',
    club: 'US Sassuolo',
    previousClub: ': Ablöse 500 Tsd. €',
    uid: '789a20d2-0209-4b9c-9166-5109d12bce55',
    position: 'Torwart',
    league: 'Serie A',
    marketValue: 100000,
  },
  {
    jerseyNumber: 28,
    name: 'Martin Erlic',
    dateOfBirth: '24.01.1998',
    nationality: 'Kroatien',
    height: 1.93,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'US Sassuolo',
    previousClub: 'Spezia Calcio',
    uid: '817af9ee-1167-4e6c-b8a0-6c7795a9a2c3',
    position: 'Innenverteidiger',
    league: 'Serie A',
    marketValue: 5000000,
  },
  {
    jerseyNumber: 13,
    name: 'Gian Marco Ferrari',
    dateOfBirth: '15.05.1992',
    nationality: 'Italien',
    height: 1.89,
    foot: 'links',
    teamMemberSince: '30.08.2016',
    club: 'US Sassuolo',
    previousClub: 'FC Crotone',
    uid: 'ebd5d5c2-7e2d-4fc4-a897-5101499b64ba',
    position: 'Innenverteidiger',
    league: 'Serie A',
    marketValue: 5000000,
  },
  {
    jerseyNumber: 44,
    name: 'Ruan Tressoldi',
    dateOfBirth: '07.06.1999',
    nationality: 'Brasilien',
    height: 1.87,
    foot: 'rechts',
    teamMemberSince: '11.08.2021',
    club: 'US Sassuolo',
    previousClub: 'Grêmio Porto Alegre',
    uid: '9d1d026a-9d0c-4c47-8c8a-38e3b9d7ab77',
    position: 'Innenverteidiger',
    league: 'Serie A',
    marketValue: 4000000,
  },
  {
    jerseyNumber: 19,
    name: 'Filippo Romagna',
    dateOfBirth: '26.05.1997',
    nationality: 'Italien',
    height: 1.86,
    foot: 'rechts',
    teamMemberSince: '17.09.2020',
    club: 'US Sassuolo',
    previousClub: 'Cagliari Calcio',
    uid: '5fff6b5d-38e6-4e98-adf5-4bcc9a55fd36',
    position: 'Innenverteidiger',
    league: 'Serie A',
    marketValue: 800000,
  },
  {
    jerseyNumber: 6,
    name: 'Rogério',
    dateOfBirth: '13.01.1998',
    nationality: 'Brasilien',
    height: 1.78,
    foot: 'links',
    teamMemberSince: '01.07.2019',
    club: 'US Sassuolo',
    previousClub: ': Ablöse 6,95 Mio. €',
    uid: 'd5790744-0264-481b-886f-1bcb1cdb2610',
    position: 'Linker Verteidiger',
    league: 'Serie A',
    marketValue: 5000000,
  },
  {
    jerseyNumber: 3,
    name: 'Riccardo Marchizza',
    dateOfBirth: '26.03.1998',
    nationality: 'Italien',
    height: 1.86,
    foot: 'links',
    teamMemberSince: '13.07.2017',
    club: 'US Sassuolo',
    previousClub: 'AS Rom',
    uid: '8bd8a94c-a2b5-4bd1-aa07-c3d1f53d8b2e',
    position: 'Linker Verteidiger',
    league: 'Serie A',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 17,
    name: 'Mert Müldür',
    dateOfBirth: '03.04.1999',
    nationality: 'Türkei',
    height: 1.84,
    foot: 'rechts',
    teamMemberSince: '20.08.2019',
    club: 'US Sassuolo',
    previousClub: 'SK Rapid Wien',
    uid: '507548cc-f5e5-478d-9ab4-c7922302da7d',
    position: 'Rechter Verteidiger',
    league: 'Serie A',
    marketValue: 5000000,
  },
  {
    jerseyNumber: 22,
    name: 'Jeremy Toljan',
    dateOfBirth: '08.08.1994',
    nationality: 'Deutschland',
    height: 1.82,
    foot: 'rechts',
    teamMemberSince: '01.07.2021',
    club: 'US Sassuolo',
    previousClub: 'Borussia Dortmund',
    uid: '0e5a1274-eca2-4ecc-a8aa-25a3a22f336a',
    position: 'Rechter Verteidiger',
    league: 'Serie A',
    marketValue: 4000000,
  },
  {
    jerseyNumber: 21,
    name: 'Nadir Zortea',
    dateOfBirth: '19.06.1999',
    nationality: 'Italien',
    height: 1.75,
    foot: 'rechts',
    teamMemberSince: '31.01.2023',
    club: 'US Sassuolo',
    previousClub: 'Atalanta Bergamo',
    uid: 'd89c707c-636a-4406-acfa-296b9e2d10e1',
    position: 'Rechter Verteidiger',
    league: 'Serie A',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 27,
    name: 'Maxime López',
    dateOfBirth: '04.12.1997',
    nationality: 'Frankreich',
    height: 1.67,
    foot: 'rechts',
    teamMemberSince: '01.07.2021',
    club: 'US Sassuolo',
    previousClub: 'Olympique Marseille',
    uid: '9721287c-eb12-4d46-a2fb-ada82f05f37e',
    position: 'Defensives Mittelfeld',
    league: 'Serie A',
    marketValue: 15000000,
  },
  {
    jerseyNumber: 16,
    name: 'Davide Frattesi',
    dateOfBirth: '22.09.1999',
    nationality: 'Italien',
    height: 1.84,
    foot: 'rechts',
    teamMemberSince: '13.07.2017',
    club: 'US Sassuolo',
    previousClub: 'AS Rom',
    uid: '7f0fe6a3-f9ce-4bd1-b9ff-91bd888e7321',
    position: 'Zentrales Mittelfeld',
    league: 'Serie A',
    marketValue: 22000000,
  },
  {
    jerseyNumber: 7,
    name: 'Matheus Henrique',
    dateOfBirth: '19.12.1997',
    nationality: 'Brasilien',
    height: 1.75,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'US Sassuolo',
    previousClub: 'Grêmio Porto Alegre',
    uid: '37a276eb-6df5-40eb-8e63-c1ca797748b2',
    position: 'Zentrales Mittelfeld',
    league: 'Serie A',
    marketValue: 8000000,
  },
  {
    jerseyNumber: 8,
    name: 'Abdou Harroui',
    dateOfBirth: '13.01.1998',
    nationality: 'Marokko',
    height: 1.82,
    foot: 'beidfüßig',
    teamMemberSince: '01.07.2022',
    club: 'US Sassuolo',
    previousClub: 'Sparta Rotterdam',
    uid: '167a686e-879d-4ac4-847d-7a65eac5df9a',
    position: 'Zentrales Mittelfeld',
    league: 'Serie A',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 14,
    name: 'Pedro Obiang',
    dateOfBirth: '27.03.1992',
    nationality: 'Äquatorialguinea',
    height: 1.86,
    foot: 'rechts',
    teamMemberSince: '24.07.2019',
    club: 'US Sassuolo',
    previousClub: 'West Ham United',
    uid: '73be28f9-74b2-4d72-8ee5-27dc2ec65104',
    position: 'Zentrales Mittelfeld',
    league: 'Serie A',
    marketValue: 900000,
  },
  {
    jerseyNumber: 42,
    name: 'Kristian Thorstvedt',
    dateOfBirth: '13.03.1999',
    nationality: 'Norwegen',
    height: 1.89,
    foot: 'links',
    teamMemberSince: '12.07.2022',
    club: 'US Sassuolo',
    previousClub: 'KRC Genk',
    uid: '2bc2676c-2b4e-43b6-9a78-fe91cf27b996',
    position: 'Offensives Mittelfeld',
    league: 'Serie A',
    marketValue: 8000000,
  },
  {
    jerseyNumber: 20,
    name: 'Nedim Bajrami',
    dateOfBirth: '28.02.1999',
    nationality: 'Albanien',
    height: 1.79,
    foot: 'rechts',
    teamMemberSince: '31.01.2023',
    club: 'US Sassuolo',
    previousClub: 'FC Empoli',
    uid: 'fcf57717-b81e-4e1c-bee0-817b5628e16a',
    position: 'Offensives Mittelfeld',
    league: 'Serie A',
    marketValue: 8000000,
  },
  {
    jerseyNumber: 45,
    name: 'Armand Laurienté',
    dateOfBirth: '04.12.1998',
    nationality: 'Frankreich',
    height: 1.71,
    foot: 'rechts',
    teamMemberSince: '31.08.2022',
    club: 'US Sassuolo',
    previousClub: 'FC Lorient',
    uid: 'f08e255d-dc3e-45cf-ab90-9ceebdba3e1c',
    position: 'Linksaußen',
    league: 'Serie A',
    marketValue: 18000000,
  },
  {
    jerseyNumber: 15,
    name: 'Emil Konradsen Ceide',
    dateOfBirth: '03.09.2001',
    nationality: 'Norwegen',
    height: 1.75,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'US Sassuolo',
    previousClub: 'Rosenborg BK',
    uid: 'ed6ebf7e-0381-4a04-825c-a051f902eca7',
    position: 'Linksaußen',
    league: 'Serie A',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 10,
    name: 'Domenico Berardi',
    dateOfBirth: '01.08.1994',
    nationality: 'Italien',
    height: 1.83,
    foot: 'links',
    teamMemberSince: '01.07.2015',
    club: 'US Sassuolo',
    previousClub: ': Ablöse 10,00 Mio. €',
    uid: '10700578-85f1-4b43-bab3-1460b580f328',
    position: 'Rechtsaußen',
    league: 'Serie A',
    marketValue: 18000000,
  },
  {
    jerseyNumber: 35,
    name: "Luca D'Andrea",
    dateOfBirth: '06.09.2004',
    nationality: 'Italien',
    height: 1.73,
    foot: 'links',
    teamMemberSince: '01.07.2022',
    club: 'US Sassuolo',
    previousClub: 'US Sassuolo U19',
    uid: '4aaa483e-5166-496e-8faa-423ec359b840',
    position: 'Rechtsaußen',
    league: 'Serie A',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 9,
    name: 'Andrea Pinamonti',
    dateOfBirth: '19.05.1999',
    nationality: 'Italien',
    height: 1.85,
    foot: 'rechts',
    teamMemberSince: '11.08.2022',
    club: 'US Sassuolo',
    previousClub: 'Inter Mailand',
    uid: '1d8dfe67-8282-4ada-ae7f-186794f0f102',
    position: 'Mittelstürmer',
    league: 'Serie A',
    marketValue: 17000000,
  },
  {
    jerseyNumber: 11,
    name: 'Agustín Álvarez',
    dateOfBirth: '19.05.2001',
    nationality: 'Uruguay',
    height: 1.77,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'US Sassuolo',
    previousClub: 'CA Peñarol',
    uid: '4da0ce43-a048-4358-9430-12229cd394fd',
    position: 'Mittelstürmer',
    league: 'Serie A',
    marketValue: 8000000,
  },
  {
    jerseyNumber: 92,
    name: 'Grégoire Defrel',
    dateOfBirth: '17.06.1991',
    nationality: 'Frankreich',
    height: 1.79,
    foot: 'links',
    teamMemberSince: '01.09.2020',
    club: 'US Sassuolo',
    previousClub: 'AS Rom',
    uid: '3324e404-ebf3-4afc-bdd6-f50bd28554bf',
    position: 'Mittelstürmer',
    league: 'Serie A',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 32,
    name: 'Vanja Milinković-Savić',
    dateOfBirth: '20.02.1997',
    nationality: 'Serbien',
    height: 2.02,
    foot: 'rechts',
    teamMemberSince: '01.07.2017',
    club: 'FC Turin',
    previousClub: 'Lechia Gdansk',
    uid: '02552df4-9567-49d2-b790-ef10d2b3437e',
    position: 'Torwart',
    league: 'Serie A',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 1,
    name: 'Etrit Berisha',
    dateOfBirth: '10.03.1989',
    nationality: 'Albanien',
    height: 1.94,
    foot: 'links',
    teamMemberSince: '01.07.2022',
    club: 'FC Turin',
    previousClub: 'SPAL',
    uid: 'd1fd8920-1f75-42ad-9021-f7b48a66e79a',
    position: 'Torwart',
    league: 'Serie A',
    marketValue: 600000,
  },
  {
    jerseyNumber: 89,
    name: 'Luca Gemello',
    dateOfBirth: '03.07.2000',
    nationality: 'Italien',
    height: 1.9,
    foot: 'rechts',
    teamMemberSince: '01.07.2019',
    club: 'FC Turin',
    previousClub: 'FC Turin U19',
    uid: 'a04ee88a-a9c5-4310-8982-9f8e820ef467',
    position: 'Torwart',
    league: 'Serie A',
    marketValue: 300000,
  },
  {
    jerseyNumber: 73,
    name: 'Matteo Fiorenza',
    dateOfBirth: '15.06.2003',
    nationality: 'Italien',
    height: 1.95,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'FC Turin',
    previousClub: 'FC Turin U19',
    uid: '6939875c-3c83-4c6f-898e-66d23cd68869',
    position: 'Torwart',
    league: 'Serie A',
    marketValue: 25000,
  },
  {
    jerseyNumber: 3,
    name: 'Perr Schuurs',
    dateOfBirth: '26.11.1999',
    nationality: 'Niederlande',
    height: 1.91,
    foot: 'rechts',
    teamMemberSince: '18.08.2022',
    club: 'FC Turin',
    previousClub: 'Ajax Amsterdam',
    uid: '2354b6c1-205b-4cb3-b64d-6869cb126dd7',
    position: 'Innenverteidiger',
    league: 'Serie A',
    marketValue: 20000000,
  },
  {
    jerseyNumber: 4,
    name: 'Alessandro Buongiorno',
    dateOfBirth: '06.06.1999',
    nationality: 'Italien',
    height: 1.94,
    foot: 'links',
    teamMemberSince: '01.07.2018',
    club: 'FC Turin',
    previousClub: 'FC Turin U19',
    uid: 'faaaea9d-f577-4faa-8291-ad095384e1ec',
    position: 'Innenverteidiger',
    league: 'Serie A',
    marketValue: 8000000,
  },
  {
    jerseyNumber: 6,
    name: 'David Zima',
    dateOfBirth: '08.11.2000',
    nationality: 'Tschechien',
    height: 1.9,
    foot: 'rechts',
    teamMemberSince: '31.08.2021',
    club: 'FC Turin',
    previousClub: ': Ablöse 5,90 Mio. €',
    uid: '403d6168-6b26-4b1b-b3fa-73aacd539558',
    position: 'Innenverteidiger',
    league: 'Serie A',
    marketValue: 5000000,
  },
  {
    jerseyNumber: 5,
    name: 'Andreaw Gravillon',
    dateOfBirth: '08.02.1998',
    nationality: 'Guadeloupe',
    height: 1.88,
    foot: 'rechts',
    teamMemberSince: '31.01.2023',
    club: 'FC Turin',
    previousClub: 'Stade Reims',
    uid: 'c2eb195a-d7e5-46c8-aa0c-a2ec5b696e66',
    position: 'Innenverteidiger',
    league: 'Serie A',
    marketValue: 5000000,
  },
  {
    jerseyNumber: 13,
    name: 'Ricardo Rodríguez',
    dateOfBirth: '25.08.1992',
    nationality: 'Schweiz',
    height: 1.8,
    foot: 'links',
    teamMemberSince: '01.09.2020',
    club: 'FC Turin',
    previousClub: 'AC Mailand',
    uid: 'e341770b-9682-431f-a009-3c850b9bd59c',
    position: 'Innenverteidiger',
    league: 'Serie A',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 26,
    name: 'Koffi Djidji',
    dateOfBirth: '30.11.1992',
    nationality: 'Elfenbeinküste',
    height: 1.84,
    foot: 'rechts',
    teamMemberSince: '01.07.2019',
    club: 'FC Turin',
    previousClub: 'FC Nantes',
    uid: 'c6a58527-b0f6-4530-880a-cb3a0cc40982',
    position: 'Innenverteidiger',
    league: 'Serie A',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 17,
    name: 'Stephane Singo',
    dateOfBirth: '25.12.2000',
    nationality: 'Elfenbeinküste',
    height: 1.9,
    foot: 'rechts',
    teamMemberSince: '01.07.2019',
    club: 'FC Turin',
    previousClub: 'FC Turin U19',
    uid: '307b415d-3f28-49c4-be49-3ea8cb7ae69a',
    position: 'Rechter Verteidiger',
    league: 'Serie A',
    marketValue: 14000000,
  },
  {
    jerseyNumber: 19,
    name: 'Valentino Lazaro',
    dateOfBirth: '24.03.1996',
    nationality: 'Österreich',
    height: 1.8,
    foot: 'rechts',
    teamMemberSince: '01.08.2022',
    club: 'FC Turin',
    previousClub: 'Inter Mailand',
    uid: 'f91e6c33-2fdb-45fa-9db8-6931b957709a',
    position: 'Rechter Verteidiger',
    league: 'Serie A',
    marketValue: 6000000,
  },
  {
    jerseyNumber: 27,
    name: 'Mërgim Vojvoda',
    dateOfBirth: '01.02.1995',
    nationality: 'Kosovo',
    height: 1.87,
    foot: 'rechts',
    teamMemberSince: '01.09.2020',
    club: 'FC Turin',
    previousClub: 'Standard Lüttich',
    uid: '96507820-bd24-4788-984e-ad0211fd582c',
    position: 'Rechter Verteidiger',
    league: 'Serie A',
    marketValue: 5000000,
  },
  {
    jerseyNumber: 34,
    name: 'Ola Aina',
    dateOfBirth: '08.10.1996',
    nationality: 'Nigeria',
    height: 1.84,
    foot: 'rechts',
    teamMemberSince: '01.07.2019',
    club: 'FC Turin',
    previousClub: 'FC Chelsea',
    uid: '121847be-46ce-475b-9a91-0e342dcb543e',
    position: 'Rechter Verteidiger',
    league: 'Serie A',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 2,
    name: 'Brian Bayeye',
    dateOfBirth: '30.06.2000',
    nationality: 'Frankreich',
    height: 1.82,
    foot: 'rechts',
    teamMemberSince: '04.07.2022',
    club: 'FC Turin',
    previousClub: 'US Catanzaro',
    uid: '4ca35c64-fbf2-4d2d-844d-a86067a3bb0d',
    position: 'Rechter Verteidiger',
    league: 'Serie A',
    marketValue: 700000,
  },
  {
    jerseyNumber: 28,
    name: 'Samuele Ricci',
    dateOfBirth: '21.08.2001',
    nationality: 'Italien',
    height: 1.8,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'FC Turin',
    previousClub: 'FC Empoli',
    uid: 'f9e0df3e-42b0-4cd3-bf84-1c2a9096f002',
    position: 'Defensives Mittelfeld',
    league: 'Serie A',
    marketValue: 15000000,
  },
  {
    jerseyNumber: 8,
    name: 'Ivan Ilic',
    dateOfBirth: '17.03.2001',
    nationality: 'Serbien',
    height: 1.85,
    foot: 'links',
    teamMemberSince: '30.01.2023',
    club: 'FC Turin',
    previousClub: 'Hellas Verona',
    uid: '67accf91-2826-43be-ae04-3c7f44c3f9e8',
    position: 'Zentrales Mittelfeld',
    league: 'Serie A',
    marketValue: 15000000,
  },
  {
    jerseyNumber: 77,
    name: 'Karol Linetty',
    dateOfBirth: '02.02.1995',
    nationality: 'Polen',
    height: 1.76,
    foot: 'rechts',
    teamMemberSince: '01.09.2020',
    club: 'FC Turin',
    previousClub: 'Sampdoria Genua',
    uid: '61635864-0a92-4fd6-b6e1-b4d0deab09ca',
    position: 'Zentrales Mittelfeld',
    league: 'Serie A',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 14,
    name: 'Ronaldo Vieira',
    dateOfBirth: '19.07.1998',
    nationality: 'England',
    height: 1.78,
    foot: 'rechts',
    teamMemberSince: '31.01.2023',
    club: 'FC Turin',
    previousClub: 'Sampdoria Genua',
    uid: '12e09df8-11e6-4fa1-b765-3da6f3d108c2',
    position: 'Zentrales Mittelfeld',
    league: 'Serie A',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 66,
    name: 'Gvidas Gineitis',
    dateOfBirth: '15.04.2004',
    nationality: 'Litauen',
    height: 1.87,
    foot: 'links',
    teamMemberSince: '02.01.2023',
    club: 'FC Turin',
    previousClub: 'FC Turin U19',
    uid: '2b3a15db-61ad-4118-9fc4-a3fc4e3cdf6c',
    position: 'Zentrales Mittelfeld',
    league: 'Serie A',
    marketValue: 500000,
  },
  {
    jerseyNumber: 21,
    name: 'Michel Adopo',
    dateOfBirth: '19.07.2000',
    nationality: 'Frankreich',
    height: 1.87,
    foot: 'rechts',
    teamMemberSince: '25.01.2020',
    club: 'FC Turin',
    previousClub: 'FC Turin U19',
    uid: 'c534528b-5be6-46a1-8f3f-1795786e0186',
    position: 'Zentrales Mittelfeld',
    league: 'Serie A',
    marketValue: 400000,
  },
  {
    jerseyNumber: 16,
    name: 'Nikola Vlasic',
    dateOfBirth: '04.10.1997',
    nationality: 'Kroatien',
    height: 1.78,
    foot: 'rechts',
    teamMemberSince: '11.08.2022',
    club: 'FC Turin',
    previousClub: 'West Ham United',
    uid: '24fb0c4d-4e62-4cfe-9105-a1df92eaf7af',
    position: 'Offensives Mittelfeld',
    league: 'Serie A',
    marketValue: 20000000,
  },
  {
    jerseyNumber: 59,
    name: 'Aleksey Miranchuk',
    dateOfBirth: '17.10.1995',
    nationality: 'Russland',
    height: 1.82,
    foot: 'links',
    teamMemberSince: '11.08.2022',
    club: 'FC Turin',
    previousClub: 'Atalanta Bergamo',
    uid: '13d1c3b1-3804-4042-9eb3-77731652ab05',
    position: 'Offensives Mittelfeld',
    league: 'Serie A',
    marketValue: 12000000,
  },
  {
    jerseyNumber: 49,
    name: 'Nemanja Radonjic',
    dateOfBirth: '15.02.1996',
    nationality: 'Serbien',
    height: 1.85,
    foot: 'beidfüßig',
    teamMemberSince: '07.07.2022',
    club: 'FC Turin',
    previousClub: 'Olympique Marseille',
    uid: 'a76081d2-2127-45d5-8e49-48b69e6b73b6',
    position: 'Linksaußen',
    league: 'Serie A',
    marketValue: 4000000,
  },
  {
    jerseyNumber: 7,
    name: 'Yann Karamoh',
    dateOfBirth: '08.07.1998',
    nationality: 'Elfenbeinküste',
    height: 1.85,
    foot: 'rechts',
    teamMemberSince: '01.09.2022',
    club: 'FC Turin',
    previousClub: 'Parma Calcio 1913',
    uid: '47aab48f-2df0-4b96-9244-36a93719ce3b',
    position: 'Linksaußen',
    league: 'Serie A',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 23,
    name: 'Demba Seck',
    dateOfBirth: '10.02.2001',
    nationality: 'Senegal',
    height: 1.9,
    foot: 'links',
    teamMemberSince: '31.01.2022',
    club: 'FC Turin',
    previousClub: 'SPAL',
    uid: '1a544a0b-4f05-4783-a3b4-fb7883613be4',
    position: 'Rechtsaußen',
    league: 'Serie A',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 9,
    name: 'Antonio Sanabria',
    dateOfBirth: '04.03.1996',
    nationality: 'Paraguay',
    height: 1.81,
    foot: 'rechts',
    teamMemberSince: '31.01.2021',
    club: 'FC Turin',
    previousClub: ': Ablöse 6,50 Mio. €',
    uid: '60a0090b-3592-43ad-b82f-503c8c4a1a59',
    position: 'Mittelstürmer',
    league: 'Serie A',
    marketValue: 7000000,
  },
  {
    jerseyNumber: 11,
    name: 'Pietro Pellegri',
    dateOfBirth: '17.03.2001',
    nationality: 'Italien',
    height: 1.88,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'FC Turin',
    previousClub: 'AS Monaco',
    uid: '9d985b6c-5ca5-4364-99a8-2850bfefbc0b',
    position: 'Mittelstürmer',
    league: 'Serie A',
    marketValue: 4000000,
  },
  {
    jerseyNumber: 1,
    name: 'Marco Silvestri',
    dateOfBirth: '02.03.1991',
    nationality: 'Italien',
    height: 1.91,
    foot: 'rechts',
    teamMemberSince: '20.07.2021',
    club: 'Udinese Calcio',
    previousClub: 'Hellas Verona',
    uid: '8c8e922f-6a7c-4804-baaa-5df797012b62',
    position: 'Torwart',
    league: 'Serie A',
    marketValue: 5000000,
  },
  {
    jerseyNumber: 20,
    name: 'Daniele Padelli',
    dateOfBirth: '25.10.1985',
    nationality: 'Italien',
    height: 1.91,
    foot: 'rechts',
    teamMemberSince: '01.07.2021',
    club: 'Udinese Calcio',
    previousClub: 'Inter Mailand',
    uid: 'a6cd285f-493d-4ae1-baa6-2d6e15d39c70',
    position: 'Torwart',
    league: 'Serie A',
    marketValue: 300000,
  },
  {
    jerseyNumber: 99,
    name: 'Edoardo Piana',
    dateOfBirth: '29.09.2003',
    nationality: 'Italien',
    height: 1.98,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'Udinese Calcio',
    previousClub: 'Udinese Calcio U19',
    uid: 'e06145b1-8f6a-4adf-a187-3ab0008d7b7f',
    position: 'Torwart',
    league: 'Serie A',
    marketValue: 50000,
  },
  {
    jerseyNumber: 29,
    name: 'Jaka Bijol',
    dateOfBirth: '05.02.1999',
    nationality: 'Slowenien',
    height: 1.9,
    foot: 'rechts',
    teamMemberSince: '14.07.2022',
    club: 'Udinese Calcio',
    previousClub: 'ZSKA Moskau',
    uid: '095a8523-63d3-49e1-826e-aa669e2d5a07',
    position: 'Innenverteidiger',
    league: 'Serie A',
    marketValue: 10000000,
  },
  {
    jerseyNumber: 50,
    name: 'Rodrigo Becão',
    dateOfBirth: '19.01.1996',
    nationality: 'Brasilien',
    height: 1.91,
    foot: 'rechts',
    teamMemberSince: '06.07.2019',
    club: 'Udinese Calcio',
    previousClub: 'EC Bahia',
    uid: '7309650a-414f-4ab5-b985-d7f2388ce69b',
    position: 'Innenverteidiger',
    league: 'Serie A',
    marketValue: 10000000,
  },
  {
    jerseyNumber: 18,
    name: 'Nehuén Pérez',
    dateOfBirth: '24.06.2000',
    nationality: 'Argentinien',
    height: 1.84,
    foot: 'rechts',
    teamMemberSince: '29.07.2022',
    club: 'Udinese Calcio',
    previousClub: 'Atlético Madrid',
    uid: '9884aa4a-bc99-44ff-9026-5fb1586bc539',
    position: 'Innenverteidiger',
    league: 'Serie A',
    marketValue: 8000000,
  },
  {
    jerseyNumber: 23,
    name: 'Enzo Ebosse',
    dateOfBirth: '11.03.1999',
    nationality: 'Kamerun',
    height: 1.85,
    foot: 'links',
    teamMemberSince: '29.07.2022',
    club: 'Udinese Calcio',
    previousClub: 'Angers SCO',
    uid: '5af534e1-c72c-4c45-8ad0-c0ed903781dc',
    position: 'Innenverteidiger',
    league: 'Serie A',
    marketValue: 4000000,
  },
  {
    jerseyNumber: 67,
    name: 'Axel Guessand',
    dateOfBirth: '06.11.2004',
    nationality: 'Frankreich',
    height: 1.84,
    foot: 'links',
    teamMemberSince: '01.07.2022',
    club: 'Udinese Calcio',
    previousClub: 'AS Nancy-Lorraine B',
    uid: 'f069cb85-7956-459b-ba58-da80f3585b8a',
    position: 'Innenverteidiger',
    league: 'Serie A',
    marketValue: 300000,
  },
  {
    jerseyNumber: 14,
    name: 'James Abankwah',
    dateOfBirth: '16.01.2004',
    nationality: 'Irland',
    height: 1.82,
    foot: 'rechts',
    teamMemberSince: '26.01.2022',
    club: 'Udinese Calcio',
    previousClub: "St. Patrick's Athletic",
    uid: 'f99ab40f-ead7-403b-850e-9bcb267fa91c',
    position: 'Innenverteidiger',
    league: 'Serie A',
    marketValue: 200000,
  },
  {
    jerseyNumber: 13,
    name: 'Destiny Udogie',
    dateOfBirth: '28.11.2002',
    nationality: 'Italien',
    height: 1.88,
    foot: 'links',
    teamMemberSince: '17.08.2022',
    club: 'Udinese Calcio',
    previousClub: 'Tottenham Hotspur',
    uid: '0d1932d2-8214-42f1-9518-4f67be26991e',
    position: 'Linker Verteidiger',
    league: 'Serie A',
    marketValue: 22000000,
  },
  {
    jerseyNumber: 3,
    name: 'Adam Masina',
    dateOfBirth: '02.01.1994',
    nationality: 'Marokko',
    height: 1.91,
    foot: 'links',
    teamMemberSince: '18.07.2022',
    club: 'Udinese Calcio',
    previousClub: 'FC Watford',
    uid: 'f90f4a6a-d9b9-42cc-9bd1-c36f6985b21b',
    position: 'Linker Verteidiger',
    league: 'Serie A',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 15,
    name: 'Leonardo Buta',
    dateOfBirth: '05.06.2002',
    nationality: 'Portugal',
    height: 1.82,
    foot: 'links',
    teamMemberSince: '01.07.2022',
    club: 'Udinese Calcio',
    previousClub: 'SC Braga B',
    uid: 'ac1cfbc9-a79f-4c9e-86d3-169cf96de9cb',
    position: 'Linker Verteidiger',
    league: 'Serie A',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 8,
    name: 'Marvin Zeegelaar',
    dateOfBirth: '12.08.1990',
    nationality: 'Niederlande',
    height: 1.86,
    foot: 'links',
    teamMemberSince: '07.03.2023',
    club: 'Udinese Calcio',
    previousClub: 'Vereinslos',
    uid: '35090eb0-2869-4f2f-b959-0ee92549c62a',
    position: 'Linker Verteidiger',
    league: 'Serie A',
    marketValue: 400000,
  },
  {
    jerseyNumber: 2,
    name: 'Festy Ebosele',
    dateOfBirth: '02.08.2002',
    nationality: 'Irland',
    height: 1.8,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'Udinese Calcio',
    previousClub: 'Derby County',
    uid: '9b659028-41e5-4bdd-a979-ca41c89bdd21',
    position: 'Rechter Verteidiger',
    league: 'Serie A',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 19,
    name: 'Kingsley Ehizibue',
    dateOfBirth: '25.05.1995',
    nationality: 'Niederlande',
    height: 1.89,
    foot: 'rechts',
    teamMemberSince: '29.08.2022',
    club: 'Udinese Calcio',
    previousClub: '1.FC Köln',
    uid: '04eecdb6-975b-4b20-aac1-5d24f9d367ba',
    position: 'Rechter Verteidiger',
    league: 'Serie A',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 11,
    name: 'Walace',
    dateOfBirth: '04.04.1995',
    nationality: 'Brasilien',
    height: 1.88,
    foot: 'rechts',
    teamMemberSince: '12.08.2019',
    club: 'Udinese Calcio',
    previousClub: ': Ablöse 6,00 Mio. €',
    uid: '4285c7ba-7f4c-46dc-a5d0-616c1a4c819f',
    position: 'Defensives Mittelfeld',
    league: 'Serie A',
    marketValue: 7000000,
  },
  {
    jerseyNumber: 4,
    name: 'Sandi Lovric',
    dateOfBirth: '28.03.1998',
    nationality: 'Slowenien',
    height: 1.8,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'Udinese Calcio',
    previousClub: 'FC Lugano',
    uid: '254341d2-1a36-4083-87df-e078a7a7f3c9',
    position: 'Zentrales Mittelfeld',
    league: 'Serie A',
    marketValue: 7000000,
  },
  {
    jerseyNumber: 37,
    name: 'Roberto Pereyra',
    dateOfBirth: '07.01.1991',
    nationality: 'Argentinien',
    height: 1.82,
    foot: 'beidfüßig',
    teamMemberSince: '28.09.2020',
    club: 'Udinese Calcio',
    previousClub: 'FC Watford',
    uid: 'f5a9b61a-305a-4533-821f-6be2c910bd7f',
    position: 'Zentrales Mittelfeld',
    league: 'Serie A',
    marketValue: 6000000,
  },
  {
    jerseyNumber: 5,
    name: 'Tolgay Arslan',
    dateOfBirth: '16.08.1990',
    nationality: 'Deutschland',
    height: 1.8,
    foot: 'rechts',
    teamMemberSince: '18.09.2020',
    club: 'Udinese Calcio',
    previousClub: 'Fenerbahce',
    uid: 'c2d9a0df-21eb-46d5-9a01-776290a5a8b3',
    position: 'Zentrales Mittelfeld',
    league: 'Serie A',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 24,
    name: 'Lazar Samardzic',
    dateOfBirth: '24.02.2002',
    nationality: 'Serbien',
    height: 1.84,
    foot: 'links',
    teamMemberSince: '05.08.2021',
    club: 'Udinese Calcio',
    previousClub: 'RasenBallsport Leipzig',
    uid: '75bc8a01-8647-478e-b23f-a8585ec7e27e',
    position: 'Offensives Mittelfeld',
    league: 'Serie A',
    marketValue: 7000000,
  },
  {
    jerseyNumber: 80,
    name: 'Simone Pafundi',
    dateOfBirth: '14.03.2006',
    nationality: 'Italien',
    height: 1.66,
    foot: 'links',
    teamMemberSince: '01.07.2022',
    club: 'Udinese Calcio',
    previousClub: 'Udinese Calcio U19',
    uid: 'ffb0eba7-06ac-45e9-b3b6-62df2ae6ad04',
    position: 'Offensives Mittelfeld',
    league: 'Serie A',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 26,
    name: 'Florian Thauvin',
    dateOfBirth: '26.01.1993',
    nationality: 'Frankreich',
    height: 1.81,
    foot: 'links',
    teamMemberSince: '31.01.2023',
    club: 'Udinese Calcio',
    previousClub: 'Tigres UANL',
    uid: '820b7e93-56cc-4d3f-88c2-2911ef462efc',
    position: 'Rechtsaußen',
    league: 'Serie A',
    marketValue: 7000000,
  },
  {
    jerseyNumber: 9,
    name: 'Beto',
    dateOfBirth: '31.01.1998',
    nationality: 'Portugal',
    height: 1.94,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'Udinese Calcio',
    previousClub: 'Portimonense SC',
    uid: 'b720bb6a-f152-4325-92e5-384a503d8bde',
    position: 'Mittelstürmer',
    league: 'Serie A',
    marketValue: 13000000,
  },
  {
    jerseyNumber: 10,
    name: 'Gerard Deulofeu',
    dateOfBirth: '13.03.1994',
    nationality: 'Spanien',
    height: 1.77,
    foot: 'rechts',
    teamMemberSince: '30.01.2021',
    club: 'Udinese Calcio',
    previousClub: 'FC Watford',
    uid: '0b7e76da-e03b-4ee4-86a8-d444e1c94841',
    position: 'Mittelstürmer',
    league: 'Serie A',
    marketValue: 13000000,
  },
  {
    jerseyNumber: 7,
    name: 'Isaac Success',
    dateOfBirth: '07.01.1996',
    nationality: 'Nigeria',
    height: 1.82,
    foot: 'rechts',
    teamMemberSince: '26.08.2021',
    club: 'Udinese Calcio',
    previousClub: 'FC Watford',
    uid: 'e7307468-bb77-492e-a8ee-5ae6c76d1589',
    position: 'Mittelstürmer',
    league: 'Serie A',
    marketValue: 4000000,
  },
  {
    jerseyNumber: 30,
    name: 'Ilija Nestorovski',
    dateOfBirth: '12.03.1990',
    nationality: 'Nordmazedonien',
    height: 1.82,
    foot: 'links',
    teamMemberSince: '26.07.2019',
    club: 'Udinese Calcio',
    previousClub: ': Ablöse ablösefrei',
    uid: '1ac65499-199c-4c5b-878b-61aafdc82d1a',
    position: 'Mittelstürmer',
    league: 'Serie A',
    marketValue: 600000,
  },
  {
    jerseyNumber: 39,
    name: 'Vivaldo',
    dateOfBirth: '28.01.2005',
    nationality: 'Portugal',
    height: 1.92,
    foot: 'links',
    teamMemberSince: '01.01.2023',
    club: 'Udinese Calcio',
    previousClub: 'Udinese Calcio U19',
    uid: '462364a6-c6de-419e-9474-0a4160877f08',
    position: 'Mittelstürmer',
    league: 'Serie A',
    marketValue: 400000,
  },
  {
    jerseyNumber: 28,
    name: 'Lukasz Skorupski',
    dateOfBirth: '05.05.1991',
    nationality: 'Polen',
    height: 1.87,
    foot: 'rechts',
    teamMemberSince: '01.07.2018',
    club: 'FC Bologna',
    previousClub: 'AS Rom',
    uid: '09ecebf8-c8a9-4ff4-ad17-e3318e5746c6',
    position: 'Torwart',
    league: 'Serie A',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 1,
    name: 'Francesco Bardi',
    dateOfBirth: '18.01.1992',
    nationality: 'Italien',
    height: 1.88,
    foot: 'rechts',
    teamMemberSince: '17.07.2021',
    club: 'FC Bologna',
    previousClub: 'Frosinone Calcio',
    uid: '285efc73-9a05-4ab3-92b4-232207b32e76',
    position: 'Torwart',
    league: 'Serie A',
    marketValue: 500000,
  },
  {
    jerseyNumber: 34,
    name: 'Federico Ravaglia',
    dateOfBirth: '11.11.1999',
    nationality: 'Italien',
    height: 1.96,
    foot: 'rechts',
    teamMemberSince: '01.07.2017',
    club: 'FC Bologna',
    previousClub: ': Ablöse -',
    uid: 'acdbf504-423c-400d-9045-498a415cce0a',
    position: 'Torwart',
    league: 'Serie A',
    marketValue: 450000,
  },
  {
    jerseyNumber: 23,
    name: 'Nicola Bagnolini',
    dateOfBirth: '14.03.2004',
    nationality: 'Italien',
    height: 1.93,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'FC Bologna',
    previousClub: 'FC Bologna U19',
    uid: '47df9520-e6e2-4c50-b025-2c9df4d33077',
    position: 'Torwart',
    league: 'Serie A',
    marketValue: 200000,
  },
  {
    jerseyNumber: 26,
    name: 'Jhon Lucumí',
    dateOfBirth: '26.06.1998',
    nationality: 'Kolumbien',
    height: 1.87,
    foot: 'links',
    teamMemberSince: '18.08.2022',
    club: 'FC Bologna',
    previousClub: 'KRC Genk',
    uid: '6126af17-1a10-4e5e-8779-344de9adb9dd',
    position: 'Innenverteidiger',
    league: 'Serie A',
    marketValue: 9000000,
  },
  {
    jerseyNumber: 5,
    name: 'Adama Soumaoro',
    dateOfBirth: '18.06.1992',
    nationality: 'Frankreich',
    height: 1.86,
    foot: 'rechts',
    teamMemberSince: '01.07.2021',
    club: 'FC Bologna',
    previousClub: 'LOSC Lille',
    uid: '60e9780b-a4a6-4f3a-a3e4-d8da60a17d92',
    position: 'Innenverteidiger',
    league: 'Serie A',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 14,
    name: 'Kevin Bonifazi',
    dateOfBirth: '19.05.1996',
    nationality: 'Italien',
    height: 1.87,
    foot: 'rechts',
    teamMemberSince: '02.07.2021',
    club: 'FC Bologna',
    previousClub: 'SPAL',
    uid: '8182a98a-928d-4fd2-9932-638c8f74043d',
    position: 'Innenverteidiger',
    league: 'Serie A',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 4,
    name: 'Joaquín Sosa',
    dateOfBirth: '10.01.2002',
    nationality: 'Uruguay',
    height: 1.85,
    foot: 'links',
    teamMemberSince: '17.08.2022',
    club: 'FC Bologna',
    previousClub: 'Club Nacional',
    uid: '03b332bc-885c-4d7f-964d-b63e1dab0162',
    position: 'Innenverteidiger',
    league: 'Serie A',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 66,
    name: 'Wisdom Amey',
    dateOfBirth: '11.08.2005',
    nationality: 'Togo',
    height: 1.87,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'FC Bologna',
    previousClub: 'FC Bologna U19',
    uid: 'bbd5e2a6-ed4f-4bc7-b34b-934430ae40c2',
    position: 'Innenverteidiger',
    league: 'Serie A',
    marketValue: 700000,
  },
  {
    jerseyNumber: 50,
    name: 'Andrea Cambiaso',
    dateOfBirth: '20.02.2000',
    nationality: 'Italien',
    height: 1.83,
    foot: 'beidfüßig',
    teamMemberSince: '15.07.2022',
    club: 'FC Bologna',
    previousClub: 'Juventus Turin',
    uid: '5911c59c-c1de-4f5d-8770-962fd93f9588',
    position: 'Linker Verteidiger',
    league: 'Serie A',
    marketValue: 6000000,
  },
  {
    jerseyNumber: 77,
    name: 'Georgios Kyriakopoulos',
    dateOfBirth: '05.02.1996',
    nationality: 'Griechenland',
    height: 1.82,
    foot: 'links',
    teamMemberSince: '31.01.2023',
    club: 'FC Bologna',
    previousClub: 'US Sassuolo',
    uid: '9fdf3750-7c41-4197-a624-0bc28ed4a3e1',
    position: 'Linker Verteidiger',
    league: 'Serie A',
    marketValue: 5000000,
  },
  {
    jerseyNumber: 22,
    name: 'Charalampos Lykogiannis',
    dateOfBirth: '22.10.1993',
    nationality: 'Griechenland',
    height: 1.9,
    foot: 'links',
    teamMemberSince: '01.07.2022',
    club: 'FC Bologna',
    previousClub: 'Cagliari Calcio',
    uid: '42819273-424c-4e29-845a-2eeae49bf9e6',
    position: 'Linker Verteidiger',
    league: 'Serie A',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 3,
    name: 'Stefan Posch',
    dateOfBirth: '14.05.1997',
    nationality: 'Österreich',
    height: 1.9,
    foot: 'rechts',
    teamMemberSince: '01.09.2022',
    club: 'FC Bologna',
    previousClub: 'TSG 1899 Hoffenheim',
    uid: '429291ee-3cdb-4804-809b-4678007c1740',
    position: 'Rechter Verteidiger',
    league: 'Serie A',
    marketValue: 10000000,
  },
  {
    jerseyNumber: 29,
    name: 'Lorenzo De Silvestri',
    dateOfBirth: '23.05.1988',
    nationality: 'Italien',
    height: 1.86,
    foot: 'rechts',
    teamMemberSince: '17.09.2020',
    club: 'FC Bologna',
    previousClub: 'FC Turin',
    uid: 'bdadd0fb-e0d6-4bfa-898d-2b73eb6c4bec',
    position: 'Rechter Verteidiger',
    league: 'Serie A',
    marketValue: 600000,
  },
  {
    jerseyNumber: 30,
    name: 'Jerdy Schouten',
    dateOfBirth: '12.01.1997',
    nationality: 'Niederlande',
    height: 1.85,
    foot: 'rechts',
    teamMemberSince: '05.07.2019',
    club: 'FC Bologna',
    previousClub: ': Ablöse 2,15 Mio. €',
    uid: '6fb049b5-e113-424d-9169-1803248af791',
    position: 'Defensives Mittelfeld',
    league: 'Serie A',
    marketValue: 8000000,
  },
  {
    jerseyNumber: 6,
    name: 'Nikola Moro',
    dateOfBirth: '12.03.1998',
    nationality: 'Kroatien',
    height: 1.85,
    foot: 'rechts',
    teamMemberSince: '29.08.2022',
    club: 'FC Bologna',
    previousClub: 'Dinamo Moskau',
    uid: '6336d48b-3e79-49de-95b5-b11ae0c1e748',
    position: 'Defensives Mittelfeld',
    league: 'Serie A',
    marketValue: 7000000,
  },
  {
    jerseyNumber: 17,
    name: 'Gary Medel',
    dateOfBirth: '03.08.1987',
    nationality: 'Chile',
    height: 1.71,
    foot: 'rechts',
    teamMemberSince: '29.08.2019',
    club: 'FC Bologna',
    previousClub: 'Besiktas JK',
    uid: 'f1342aee-9efa-4a1e-9daa-50cbe4965cd3',
    position: 'Defensives Mittelfeld',
    league: 'Serie A',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 82,
    name: 'Kacper Urbanski',
    dateOfBirth: '07.09.2004',
    nationality: 'Polen',
    height: 1.78,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'FC Bologna',
    previousClub: 'FC Bologna U19',
    uid: '23527ce5-046e-4cf7-bc7c-7b4733e0861d',
    position: 'Defensives Mittelfeld',
    league: 'Serie A',
    marketValue: 250000,
  },
  {
    jerseyNumber: 8,
    name: 'Nicolás Domínguez',
    dateOfBirth: '28.06.1998',
    nationality: 'Argentinien',
    height: 1.79,
    foot: 'rechts',
    teamMemberSince: '30.08.2019',
    club: 'FC Bologna',
    previousClub: 'Vélez Sarsfield',
    uid: '620ca956-3db7-411e-b570-f7a2a9bc9c98',
    position: 'Zentrales Mittelfeld',
    league: 'Serie A',
    marketValue: 15000000,
  },
  {
    jerseyNumber: 19,
    name: 'Lewis Ferguson',
    dateOfBirth: '24.08.1999',
    nationality: 'Schottland',
    height: 1.81,
    foot: 'rechts',
    teamMemberSince: '12.07.2022',
    club: 'FC Bologna',
    previousClub: 'Aberdeen FC',
    uid: '462ad971-ddea-4243-a353-b0d416491cc6',
    position: 'Zentrales Mittelfeld',
    league: 'Serie A',
    marketValue: 9000000,
  },
  {
    jerseyNumber: 20,
    name: 'Michel Aebischer',
    dateOfBirth: '06.01.1997',
    nationality: 'Schweiz',
    height: 1.83,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'FC Bologna',
    previousClub: 'BSC Young Boys',
    uid: 'b1d9b019-7364-4e82-9d2e-6400b3367088',
    position: 'Zentrales Mittelfeld',
    league: 'Serie A',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 25,
    name: 'Niklas Pyyhtiä',
    dateOfBirth: '25.09.2003',
    nationality: 'Finnland',
    height: 1.86,
    foot: 'links',
    teamMemberSince: '01.01.2023',
    club: 'FC Bologna',
    previousClub: 'FC Bologna U19',
    uid: '46360b4c-6908-4b41-8963-a2cae1ae4ada',
    position: 'Zentrales Mittelfeld',
    league: 'Serie A',
    marketValue: 500000,
  },
  {
    jerseyNumber: 21,
    name: 'Roberto Soriano',
    dateOfBirth: '08.02.1991',
    nationality: 'Italien',
    height: 1.82,
    foot: 'rechts',
    teamMemberSince: '01.07.2019',
    club: 'FC Bologna',
    previousClub: 'FC Villarreal',
    uid: '5b34247d-ca8b-4d38-8238-bcdaa0b8d6fd',
    position: 'Offensives Mittelfeld',
    league: 'Serie A',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 99,
    name: 'Musa Barrow',
    dateOfBirth: '14.11.1998',
    nationality: 'Gambia',
    height: 1.84,
    foot: 'rechts',
    teamMemberSince: '01.07.2021',
    club: 'FC Bologna',
    previousClub: 'Atalanta Bergamo',
    uid: 'a3ccc248-9a5c-4267-91b2-0a79b6d71020',
    position: 'Linksaußen',
    league: 'Serie A',
    marketValue: 12000000,
  },
  {
    jerseyNumber: 10,
    name: 'Nicola Sansone',
    dateOfBirth: '10.09.1991',
    nationality: 'Italien',
    height: 1.73,
    foot: 'rechts',
    teamMemberSince: '01.07.2019',
    club: 'FC Bologna',
    previousClub: 'FC Villarreal',
    uid: '5a4830e4-ea30-43f4-b88d-7a72c55c41b5',
    position: 'Linksaußen',
    league: 'Serie A',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 7,
    name: 'Riccardo Orsolini',
    dateOfBirth: '24.01.1997',
    nationality: 'Italien',
    height: 1.83,
    foot: 'links',
    teamMemberSince: '01.07.2019',
    club: 'FC Bologna',
    previousClub: ': Ablöse 15,00 Mio. €',
    uid: '78b3c390-a799-44d5-9d07-a12490aa3b04',
    position: 'Rechtsaußen',
    league: 'Serie A',
    marketValue: 10000000,
  },
  {
    jerseyNumber: 11,
    name: 'Joshua Zirkzee',
    dateOfBirth: '22.05.2001',
    nationality: 'Niederlande',
    height: 1.93,
    foot: 'rechts',
    teamMemberSince: '30.08.2022',
    club: 'FC Bologna',
    previousClub: 'FC Bayern München',
    uid: 'fc607845-3e60-4dc4-950e-ee8270fc60f8',
    position: 'Mittelstürmer',
    league: 'Serie A',
    marketValue: 9000000,
  },
  {
    jerseyNumber: 9,
    name: 'Marko Arnautovic',
    dateOfBirth: '19.04.1989',
    nationality: 'Österreich',
    height: 1.92,
    foot: 'beidfüßig',
    teamMemberSince: '01.08.2021',
    club: 'FC Bologna',
    previousClub: 'Shanghai Port',
    uid: '1beb582f-b112-450c-9ca3-9a48597f9965',
    position: 'Mittelstürmer',
    league: 'Serie A',
    marketValue: 7000000,
  },
  {
    jerseyNumber: 18,
    name: 'Antonio Raimondo',
    dateOfBirth: '18.03.2004',
    nationality: 'Italien',
    height: 1.85,
    foot: 'links',
    teamMemberSince: '01.07.2022',
    club: 'FC Bologna',
    previousClub: 'FC Bologna U19',
    uid: '5654b1b2-9f11-4b4a-82e1-80d2c0913ad0',
    position: 'Mittelstürmer',
    league: 'Serie A',
    marketValue: 700000,
  },
  {
    jerseyNumber: 16,
    name: 'Michele Di Gregorio',
    dateOfBirth: '27.07.1997',
    nationality: 'Italien',
    height: 1.87,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'AC Monza',
    previousClub: 'Inter Mailand',
    uid: '898f35e3-62e7-4f16-be13-1f485ff12507',
    position: 'Torwart',
    league: 'Serie A',
    marketValue: 7000000,
  },
  {
    jerseyNumber: 89,
    name: 'Alessio Cragno',
    dateOfBirth: '28.06.1994',
    nationality: 'Italien',
    height: 1.84,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'AC Monza',
    previousClub: 'Cagliari Calcio',
    uid: 'f1387c5f-4223-40cb-82fd-255ca426479a',
    position: 'Torwart',
    league: 'Serie A',
    marketValue: 4000000,
  },
  {
    jerseyNumber: 91,
    name: 'Alessandro Sorrentino',
    dateOfBirth: '03.04.2002',
    nationality: 'Italien',
    height: 1.9,
    foot: 'rechts',
    teamMemberSince: '13.07.2022',
    club: 'AC Monza',
    previousClub: 'Delfino Pescara 1936',
    uid: '9e0ad276-3e94-4f15-b5af-289ccbe7928e',
    position: 'Torwart',
    league: 'Serie A',
    marketValue: 500000,
  },
  {
    jerseyNumber: 1,
    name: 'Eugenio Lamanna',
    dateOfBirth: '07.08.1989',
    nationality: 'Italien',
    height: 1.87,
    foot: 'rechts',
    teamMemberSince: '13.07.2019',
    club: 'AC Monza',
    previousClub: 'Spezia Calcio',
    uid: '9515903b-9a48-4cf6-ba05-87d2c7f3bea5',
    position: 'Torwart',
    league: 'Serie A',
    marketValue: 100000,
  },
  {
    jerseyNumber: 4,
    name: 'Marlon',
    dateOfBirth: '07.09.1995',
    nationality: 'Brasilien',
    height: 1.82,
    foot: 'rechts',
    teamMemberSince: '05.08.2022',
    club: 'AC Monza',
    previousClub: 'Shakhtar Donetsk',
    uid: '004a7374-a62c-48f2-8bab-c9e65d43f82f',
    position: 'Innenverteidiger',
    league: 'Serie A',
    marketValue: 7000000,
  },
  {
    jerseyNumber: 3,
    name: 'Pablo Marí',
    dateOfBirth: '31.08.1993',
    nationality: 'Spanien',
    height: 1.93,
    foot: 'links',
    teamMemberSince: '11.08.2022',
    club: 'AC Monza',
    previousClub: 'FC Arsenal',
    uid: 'a89d4a2d-d61a-40bc-882c-0e3c27cadbc4',
    position: 'Innenverteidiger',
    league: 'Serie A',
    marketValue: 5000000,
  },
  {
    jerseyNumber: 5,
    name: 'Luca Caldirola',
    dateOfBirth: '01.02.1991',
    nationality: 'Italien',
    height: 1.86,
    foot: 'links',
    teamMemberSince: '17.07.2021',
    club: 'AC Monza',
    previousClub: 'Benevento Calcio',
    uid: 'da458914-03c9-4ba3-ba5c-c3462bcd2a11',
    position: 'Innenverteidiger',
    league: 'Serie A',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 26,
    name: 'Valentin Antov',
    dateOfBirth: '09.11.2000',
    nationality: 'Bulgarien',
    height: 1.87,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'AC Monza',
    previousClub: 'ZSKA Sofia',
    uid: 'f0c450a1-9bed-4108-8d58-e6d94582ead2',
    position: 'Innenverteidiger',
    league: 'Serie A',
    marketValue: 800000,
  },
  {
    jerseyNumber: 34,
    name: 'Luca Marrone',
    dateOfBirth: '28.03.1990',
    nationality: 'Italien',
    height: 1.86,
    foot: 'rechts',
    teamMemberSince: '31.08.2021',
    club: 'AC Monza',
    previousClub: 'FC Crotone',
    uid: 'b813ed15-6b0d-429a-9cc6-63afa14e1d00',
    position: 'Innenverteidiger',
    league: 'Serie A',
    marketValue: 300000,
  },
  {
    jerseyNumber: 30,
    name: 'Carlos Augusto',
    dateOfBirth: '07.01.1999',
    nationality: 'Brasilien',
    height: 1.84,
    foot: 'links',
    teamMemberSince: '01.09.2020',
    club: 'AC Monza',
    previousClub: 'Corinthians São Paulo',
    uid: '3b2abd03-9f0c-48d8-b662-6ceb9989ecb1',
    position: 'Linker Verteidiger',
    league: 'Serie A',
    marketValue: 8000000,
  },
  {
    jerseyNumber: 11,
    name: 'Franco Carboni',
    dateOfBirth: '04.04.2003',
    nationality: 'Argentinien',
    height: 1.9,
    foot: 'links',
    teamMemberSince: '24.01.2023',
    club: 'AC Monza',
    previousClub: 'Inter Mailand',
    uid: 'c2b860d8-ad46-4bac-b3a3-476adbb5ff74',
    position: 'Linker Verteidiger',
    league: 'Serie A',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 19,
    name: 'Samuele Birindelli',
    dateOfBirth: '19.07.1999',
    nationality: 'Italien',
    height: 1.76,
    foot: 'rechts',
    teamMemberSince: '07.07.2022',
    club: 'AC Monza',
    previousClub: 'Pisa Sporting Club',
    uid: '4cc173c9-65df-4beb-b4fa-a6c9dd5b6b4b',
    position: 'Rechter Verteidiger',
    league: 'Serie A',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 2,
    name: 'Giulio Donati',
    dateOfBirth: '05.02.1990',
    nationality: 'Italien',
    height: 1.78,
    foot: 'rechts',
    teamMemberSince: '01.09.2020',
    club: 'AC Monza',
    previousClub: 'US Lecce',
    uid: '5519d06c-e7e1-4350-b49b-f1e6a028a059',
    position: 'Rechter Verteidiger',
    league: 'Serie A',
    marketValue: 500000,
  },
  {
    jerseyNumber: 6,
    name: 'Nicolò Rovella',
    dateOfBirth: '04.12.2001',
    nationality: 'Italien',
    height: 1.79,
    foot: 'rechts',
    teamMemberSince: '31.08.2022',
    club: 'AC Monza',
    previousClub: 'Juventus Turin',
    uid: '19af3bc4-7393-4622-8ae2-ba4b69ec2865',
    position: 'Defensives Mittelfeld',
    league: 'Serie A',
    marketValue: 12000000,
  },
  {
    jerseyNumber: 8,
    name: 'Andrea Barberis',
    dateOfBirth: '11.12.1993',
    nationality: 'Italien',
    height: 1.77,
    foot: 'rechts',
    teamMemberSince: '01.09.2020',
    club: 'AC Monza',
    previousClub: 'FC Crotone',
    uid: 'd59742f3-c7fa-438a-a700-2d8541bcc6c7',
    position: 'Defensives Mittelfeld',
    league: 'Serie A',
    marketValue: 900000,
  },
  {
    jerseyNumber: 32,
    name: 'Matteo Pessina',
    dateOfBirth: '21.04.1997',
    nationality: 'Italien',
    height: 1.87,
    foot: 'links',
    teamMemberSince: '06.07.2022',
    club: 'AC Monza',
    previousClub: 'Atalanta Bergamo',
    uid: 'd0d72830-a107-4115-bf02-b9f11681128b',
    position: 'Zentrales Mittelfeld',
    league: 'Serie A',
    marketValue: 14000000,
  },
  {
    jerseyNumber: 12,
    name: 'Stefano Sensi',
    dateOfBirth: '05.08.1995',
    nationality: 'Italien',
    height: 1.68,
    foot: 'rechts',
    teamMemberSince: '02.07.2022',
    club: 'AC Monza',
    previousClub: 'Inter Mailand',
    uid: 'cd5fbb5f-30fc-471b-8f5e-6ee85104c88d',
    position: 'Zentrales Mittelfeld',
    league: 'Serie A',
    marketValue: 8000000,
  },
  {
    jerseyNumber: 28,
    name: 'Andrea Colpani',
    dateOfBirth: '11.05.1999',
    nationality: 'Italien',
    height: 1.84,
    foot: 'links',
    teamMemberSince: '01.07.2022',
    club: 'AC Monza',
    previousClub: 'Atalanta Bergamo',
    uid: '4b506fae-ffcc-43c2-8856-db15929e58ab',
    position: 'Zentrales Mittelfeld',
    league: 'Serie A',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 22,
    name: 'Filippo Ranocchia',
    dateOfBirth: '14.05.2001',
    nationality: 'Italien',
    height: 1.86,
    foot: 'beidfüßig',
    teamMemberSince: '21.07.2022',
    club: 'AC Monza',
    previousClub: 'Juventus Turin',
    uid: '95dbbf5b-d36e-4e77-a815-4915b04f1cc4',
    position: 'Zentrales Mittelfeld',
    league: 'Serie A',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 10,
    name: 'Mattia Valoti',
    dateOfBirth: '06.09.1993',
    nationality: 'Italien',
    height: 1.87,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'AC Monza',
    previousClub: 'SPAL',
    uid: '52da0867-496a-4aff-94b5-f805610a069f',
    position: 'Zentrales Mittelfeld',
    league: 'Serie A',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 7,
    name: 'José Machín',
    dateOfBirth: '14.08.1996',
    nationality: 'Äquatorialguinea',
    height: 1.84,
    foot: 'rechts',
    teamMemberSince: '01.07.2020',
    club: 'AC Monza',
    previousClub: 'Parma Calcio 1913',
    uid: 'c53eff60-06a0-456f-b278-a8188d1d5ddb',
    position: 'Zentrales Mittelfeld',
    league: 'Serie A',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 80,
    name: 'Samuele Vignato',
    dateOfBirth: '24.02.2004',
    nationality: 'Italien',
    height: 1.72,
    foot: 'rechts',
    teamMemberSince: '11.08.2021',
    club: 'AC Monza',
    previousClub: 'Chievo Verona U19',
    uid: '6d62e836-9588-499b-badb-80b8bcf5a08a',
    position: 'Offensives Mittelfeld',
    league: 'Serie A',
    marketValue: 600000,
  },
  {
    jerseyNumber: 84,
    name: 'Patrick Ciurria',
    dateOfBirth: '09.02.1995',
    nationality: 'Italien',
    height: 1.78,
    foot: 'beidfüßig',
    teamMemberSince: '31.07.2021',
    club: 'AC Monza',
    previousClub: 'Pordenone Calcio',
    uid: 'ef4f7499-c748-4007-bacb-10d643867ee1',
    position: 'Rechtsaußen',
    league: 'Serie A',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 77,
    name: "Marco D'Alessandro",
    dateOfBirth: '17.02.1991',
    nationality: 'Italien',
    height: 1.73,
    foot: 'rechts',
    teamMemberSince: '27.01.2021',
    club: 'AC Monza',
    previousClub: 'SPAL',
    uid: 'a372d1ac-1447-4983-82c0-26c1dc02fa86',
    position: 'Rechtsaußen',
    league: 'Serie A',
    marketValue: 900000,
  },
  {
    jerseyNumber: 17,
    name: 'Gianluca Caprari',
    dateOfBirth: '30.07.1993',
    nationality: 'Italien',
    height: 1.71,
    foot: 'rechts',
    teamMemberSince: '21.07.2022',
    club: 'AC Monza',
    previousClub: 'Hellas Verona',
    uid: 'd0730756-39c3-4429-9794-8391810b2997',
    position: 'Hängende Spitze',
    league: 'Serie A',
    marketValue: 9000000,
  },
  {
    jerseyNumber: 37,
    name: 'Andrea Petagna',
    dateOfBirth: '30.06.1995',
    nationality: 'Italien',
    height: 1.9,
    foot: 'links',
    teamMemberSince: '12.08.2022',
    club: 'AC Monza',
    previousClub: 'SSC Neapel',
    uid: 'f0757599-661a-465e-88ac-89b47c6c3c53',
    position: 'Mittelstürmer',
    league: 'Serie A',
    marketValue: 10000000,
  },
  {
    jerseyNumber: 47,
    name: 'Dany Mota',
    dateOfBirth: '02.05.1998',
    nationality: 'Portugal',
    height: 1.8,
    foot: 'rechts',
    teamMemberSince: '01.07.2020',
    club: 'AC Monza',
    previousClub: 'Juventus Turin U23',
    uid: 'd2c1cbf8-e0c2-431b-90ae-339db64e1300',
    position: 'Mittelstürmer',
    league: 'Serie A',
    marketValue: 5000000,
  },
  {
    jerseyNumber: 9,
    name: 'Christian Gytkjaer',
    dateOfBirth: '06.05.1990',
    nationality: 'Dänemark',
    height: 1.85,
    foot: 'rechts',
    teamMemberSince: '01.08.2020',
    club: 'AC Monza',
    previousClub: 'Lech Posen',
    uid: 'cbb0f1e7-6687-4a50-9a5a-112967125f9c',
    position: 'Mittelstürmer',
    league: 'Serie A',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 24,
    name: 'Mirko Maric',
    dateOfBirth: '16.05.1995',
    nationality: 'Kroatien',
    height: 1.88,
    foot: 'links',
    teamMemberSince: '01.09.2020',
    club: 'AC Monza',
    previousClub: 'NK Osijek',
    uid: '1a41b6ee-97dc-4789-8cbb-d2aef278c287',
    position: 'Mittelstürmer',
    league: 'Serie A',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 13,
    name: 'Guglielmo Vicario',
    dateOfBirth: '07.10.1996',
    nationality: 'Italien',
    height: 1.94,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'FC Empoli',
    previousClub: 'Cagliari Calcio',
    uid: 'a4d389fa-0467-478e-a3ba-e2bea988a990',
    position: 'Torwart',
    league: 'Serie A',
    marketValue: 16000000,
  },
  {
    jerseyNumber: 1,
    name: 'Samuele Perisan',
    dateOfBirth: '21.08.1997',
    nationality: 'Italien',
    height: 1.92,
    foot: 'links',
    teamMemberSince: '01.07.2022',
    club: 'FC Empoli',
    previousClub: 'Pordenone Calcio',
    uid: 'f4122644-fb95-43de-a00f-64ce45a4d8c7',
    position: 'Torwart',
    league: 'Serie A',
    marketValue: 700000,
  },
  {
    jerseyNumber: 22,
    name: 'Samir Ujkani',
    dateOfBirth: '05.07.1988',
    nationality: 'Kosovo',
    height: 1.86,
    foot: 'rechts',
    teamMemberSince: '01.08.2021',
    club: 'FC Empoli',
    previousClub: 'FC Turin',
    uid: '277d7570-a2e6-4dab-a54e-6183e2efcc04',
    position: 'Torwart',
    league: 'Serie A',
    marketValue: 200000,
  },
  {
    jerseyNumber: 6,
    name: 'Koni De Winter',
    dateOfBirth: '12.06.2002',
    nationality: 'Belgien',
    height: 1.91,
    foot: 'rechts',
    teamMemberSince: '09.07.2022',
    club: 'FC Empoli',
    previousClub: 'Juventus Next Gen',
    uid: '44f863a0-7b28-4b21-9211-32904fe7b7b4',
    position: 'Innenverteidiger',
    league: 'Serie A',
    marketValue: 4000000,
  },
  {
    jerseyNumber: 34,
    name: 'Ardian Ismajli',
    dateOfBirth: '30.09.1996',
    nationality: 'Albanien',
    height: 1.85,
    foot: 'rechts',
    teamMemberSince: '09.08.2021',
    club: 'FC Empoli',
    previousClub: 'Spezia Calcio',
    uid: '81eeade5-3751-483d-84b2-6d4718344ad8',
    position: 'Innenverteidiger',
    league: 'Serie A',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 4,
    name: 'Sebastian Walukiewicz',
    dateOfBirth: '05.04.2000',
    nationality: 'Polen',
    height: 1.88,
    foot: 'rechts',
    teamMemberSince: '01.09.2022',
    club: 'FC Empoli',
    previousClub: 'Cagliari Calcio',
    uid: 'd6651a65-0ff1-451a-930e-43acd092a691',
    position: 'Innenverteidiger',
    league: 'Serie A',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 33,
    name: 'Sebastiano Luperto',
    dateOfBirth: '06.09.1996',
    nationality: 'Italien',
    height: 1.91,
    foot: 'links',
    teamMemberSince: '18.07.2022',
    club: 'FC Empoli',
    previousClub: 'SSC Neapel',
    uid: '8d9dfb1a-f772-4447-baea-f3e3464997b1',
    position: 'Innenverteidiger',
    league: 'Serie A',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 26,
    name: 'Lorenzo Tonelli',
    dateOfBirth: '17.01.1990',
    nationality: 'Italien',
    height: 1.83,
    foot: 'rechts',
    teamMemberSince: '27.08.2021',
    club: 'FC Empoli',
    previousClub: 'Sampdoria Genua',
    uid: '790085d3-b003-4fa2-9e4f-f5c193581c4f',
    position: 'Innenverteidiger',
    league: 'Serie A',
    marketValue: 800000,
  },
  {
    jerseyNumber: 36,
    name: 'Gabriele Guarino',
    dateOfBirth: '14.04.2004',
    nationality: 'Italien',
    height: 1.78,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'FC Empoli',
    previousClub: 'FC Empoli U19',
    uid: 'dcd92e66-75be-4d70-b828-17cd0fea709d',
    position: 'Innenverteidiger',
    league: 'Serie A',
    marketValue: 200000,
  },
  {
    jerseyNumber: 65,
    name: 'Fabiano Parisi',
    dateOfBirth: '09.11.2000',
    nationality: 'Italien',
    height: 1.78,
    foot: 'links',
    teamMemberSince: '22.09.2020',
    club: 'FC Empoli',
    previousClub: 'US Avellino 1912',
    uid: 'f85586a1-6e41-44fa-95f4-d9a6079c8e90',
    position: 'Linker Verteidiger',
    league: 'Serie A',
    marketValue: 10000000,
  },
  {
    jerseyNumber: 3,
    name: 'Liberato Cacace',
    dateOfBirth: '27.09.2000',
    nationality: 'Neuseeland',
    height: 1.83,
    foot: 'links',
    teamMemberSince: '01.07.2022',
    club: 'FC Empoli',
    previousClub: 'VV St. Truiden',
    uid: 'e94acc77-8389-4734-8836-32a605e303ee',
    position: 'Linker Verteidiger',
    league: 'Serie A',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 30,
    name: 'Petar Stojanovic',
    dateOfBirth: '07.10.1995',
    nationality: 'Slowenien',
    height: 1.78,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'FC Empoli',
    previousClub: 'GNK Dinamo Zagreb',
    uid: '734e2e82-b0e7-4165-86d3-a2d46974cd81',
    position: 'Rechter Verteidiger',
    league: 'Serie A',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 24,
    name: 'Tyronne Ebuehi',
    dateOfBirth: '16.12.1995',
    nationality: 'Nigeria',
    height: 1.87,
    foot: 'rechts',
    teamMemberSince: '05.07.2022',
    club: 'FC Empoli',
    previousClub: 'Benfica Lissabon B',
    uid: 'e7765800-3b70-4ccb-9bfc-f4c4c3d328e9',
    position: 'Rechter Verteidiger',
    league: 'Serie A',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 20,
    name: 'Duccio Degli Innocenti',
    dateOfBirth: '28.04.2003',
    nationality: 'Italien',
    height: 1.8,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'FC Empoli',
    previousClub: 'FC Empoli U19',
    uid: 'ceba1d33-4dd8-4a1a-b4b6-5cbf7da04cf8',
    position: 'Defensives Mittelfeld',
    league: 'Serie A',
    marketValue: 500000,
  },
  {
    jerseyNumber: 18,
    name: 'Razvan Marin',
    dateOfBirth: '23.05.1996',
    nationality: 'Rumänien',
    height: 1.78,
    foot: 'rechts',
    teamMemberSince: '05.07.2022',
    club: 'FC Empoli',
    previousClub: 'Cagliari Calcio',
    uid: '87a5aded-2f6b-4be5-b206-3e60b360ad9d',
    position: 'Zentrales Mittelfeld',
    league: 'Serie A',
    marketValue: 8000000,
  },
  {
    jerseyNumber: 25,
    name: 'Filippo Bandinelli',
    dateOfBirth: '29.03.1995',
    nationality: 'Italien',
    height: 1.8,
    foot: 'links',
    teamMemberSince: '13.07.2019',
    club: 'FC Empoli',
    previousClub: ': Ablöse 1 Tsd. €',
    uid: 'ee4c5c1e-e9f7-4f89-9123-85e78572371a',
    position: 'Zentrales Mittelfeld',
    league: 'Serie A',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 8,
    name: 'Liam Henderson',
    dateOfBirth: '25.04.1996',
    nationality: 'Schottland',
    height: 1.83,
    foot: 'rechts',
    teamMemberSince: '11.08.2021',
    club: 'FC Empoli',
    previousClub: 'US Lecce',
    uid: '16110435-852e-4e35-97ac-2e7567271688',
    position: 'Zentrales Mittelfeld',
    league: 'Serie A',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 32,
    name: 'Nicolas Haas',
    dateOfBirth: '23.01.1996',
    nationality: 'Schweiz',
    height: 1.78,
    foot: 'rechts',
    teamMemberSince: '01.07.2021',
    club: 'FC Empoli',
    previousClub: 'Atalanta Bergamo',
    uid: '443ba4e9-81f9-4c0c-bcd3-bf744728e772',
    position: 'Zentrales Mittelfeld',
    league: 'Serie A',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 5,
    name: 'Alberto Grassi',
    dateOfBirth: '07.03.1995',
    nationality: 'Italien',
    height: 1.83,
    foot: 'rechts',
    teamMemberSince: '17.08.2022',
    club: 'FC Empoli',
    previousClub: 'Parma Calcio 1913',
    uid: '94ad128e-43ac-46bf-b692-a89ee6ea0a04',
    position: 'Zentrales Mittelfeld',
    league: 'Serie A',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 11,
    name: 'Jean-Daniel Akpa Akpro',
    dateOfBirth: '11.10.1992',
    nationality: 'Elfenbeinküste',
    height: 1.8,
    foot: 'rechts',
    teamMemberSince: '01.09.2022',
    club: 'FC Empoli',
    previousClub: 'Lazio Rom',
    uid: '9b46d953-ea54-45f3-a20b-10f60657697d',
    position: 'Zentrales Mittelfeld',
    league: 'Serie A',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 21,
    name: 'Jacopo Fazzini',
    dateOfBirth: '16.03.2003',
    nationality: 'Italien',
    height: 1.78,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'FC Empoli',
    previousClub: 'FC Empoli U19',
    uid: '5fa0b69b-1527-4d61-baa4-734658650ddc',
    position: 'Zentrales Mittelfeld',
    league: 'Serie A',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 35,
    name: 'Tommaso Baldanzi',
    dateOfBirth: '23.03.2003',
    nationality: 'Italien',
    height: 1.7,
    foot: 'links',
    teamMemberSince: '01.07.2021',
    club: 'FC Empoli',
    previousClub: 'FC Empoli U19',
    uid: '443d04fb-7a1d-42e5-8972-2d360180133d',
    position: 'Offensives Mittelfeld',
    league: 'Serie A',
    marketValue: 10000000,
  },
  {
    jerseyNumber: 28,
    name: 'Nicolò Cambiaghi',
    dateOfBirth: '28.12.2000',
    nationality: 'Italien',
    height: 1.73,
    foot: 'rechts',
    teamMemberSince: '04.08.2022',
    club: 'FC Empoli',
    previousClub: 'Atalanta Bergamo',
    uid: '47d8a02f-80eb-47e3-a3e1-1e9207cfbe51',
    position: 'Linksaußen',
    league: 'Serie A',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 14,
    name: 'Marko Pjaca',
    dateOfBirth: '06.05.1995',
    nationality: 'Kroatien',
    height: 1.86,
    foot: 'rechts',
    teamMemberSince: '01.09.2022',
    club: 'FC Empoli',
    previousClub: 'Juventus Turin',
    uid: '591a1b67-0453-42ed-9792-43465a65ff00',
    position: 'Linksaußen',
    league: 'Serie A',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 55,
    name: 'Emanuel Vignato',
    dateOfBirth: '24.08.2000',
    nationality: 'Italien',
    height: 1.75,
    foot: 'rechts',
    teamMemberSince: '31.01.2023',
    club: 'FC Empoli',
    previousClub: 'FC Bologna',
    uid: 'e9146230-76a1-4406-852c-a9c79cf168f1',
    position: 'Rechtsaußen',
    league: 'Serie A',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 9,
    name: 'Martín Satriano',
    dateOfBirth: '20.02.2001',
    nationality: 'Uruguay',
    height: 1.87,
    foot: 'rechts',
    teamMemberSince: '04.07.2022',
    club: 'FC Empoli',
    previousClub: 'Inter Mailand',
    uid: 'a270ece8-6a3a-47b8-9895-60ac9596acb9',
    position: 'Mittelstürmer',
    league: 'Serie A',
    marketValue: 8000000,
  },
  {
    jerseyNumber: 91,
    name: 'Roberto Piccoli',
    dateOfBirth: '27.01.2001',
    nationality: 'Italien',
    height: 1.9,
    foot: 'rechts',
    teamMemberSince: '31.01.2023',
    club: 'FC Empoli',
    previousClub: 'Atalanta Bergamo',
    uid: 'e1528993-2774-4b5e-a6dd-b8b26ce5d374',
    position: 'Mittelstürmer',
    league: 'Serie A',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 19,
    name: 'Francesco Caputo',
    dateOfBirth: '06.08.1987',
    nationality: 'Italien',
    height: 1.81,
    foot: 'rechts',
    teamMemberSince: '02.01.2023',
    club: 'FC Empoli',
    previousClub: 'Sampdoria Genua',
    uid: '3181bbb3-527a-4fd1-ba83-4a60c217b0b8',
    position: 'Mittelstürmer',
    league: 'Serie A',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 23,
    name: 'Mattia Destro',
    dateOfBirth: '20.03.1991',
    nationality: 'Italien',
    height: 1.82,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'FC Empoli',
    previousClub: 'Genua CFC',
    uid: 'ce2446e3-f513-4d9c-999b-27fd28864a5c',
    position: 'Mittelstürmer',
    league: 'Serie A',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 13,
    name: 'Guillermo Ochoa',
    dateOfBirth: '13.07.1985',
    nationality: 'Mexiko',
    height: 1.83,
    foot: 'rechts',
    teamMemberSince: '02.01.2023',
    club: 'US Salernitana 1919',
    previousClub: 'CF América',
    uid: '307921fc-0153-4758-ace8-0d54ffa4343e',
    position: 'Torwart',
    league: 'Serie A',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 33,
    name: 'Luigi Sepe',
    dateOfBirth: '08.05.1991',
    nationality: 'Italien',
    height: 1.85,
    foot: 'links',
    teamMemberSince: '01.07.2022',
    club: 'US Salernitana 1919',
    previousClub: 'Parma Calcio 1913',
    uid: '06edf6e7-b335-4d84-baf4-fd0f9eeac8e2',
    position: 'Torwart',
    league: 'Serie A',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 1,
    name: 'Vincenzo Fiorillo',
    dateOfBirth: '13.01.1990',
    nationality: 'Italien',
    height: 1.9,
    foot: 'links',
    teamMemberSince: '06.08.2021',
    club: 'US Salernitana 1919',
    previousClub: ': Ablöse 600 Tsd. €',
    uid: '30bae292-dfb7-48ab-a5e8-ea1a6fea27ac',
    position: 'Torwart',
    league: 'Serie A',
    marketValue: 300000,
  },
  {
    jerseyNumber: 5,
    name: 'Flavius Daniliuc',
    dateOfBirth: '27.04.2001',
    nationality: 'Österreich',
    height: 1.88,
    foot: 'rechts',
    teamMemberSince: '29.08.2022',
    club: 'US Salernitana 1919',
    previousClub: 'OGC Nizza',
    uid: '5305b806-1f8d-4461-86a4-bb0cc7851322',
    position: 'Innenverteidiger',
    league: 'Serie A',
    marketValue: 8000000,
  },
  {
    jerseyNumber: 66,
    name: 'Matteo Lovato',
    dateOfBirth: '14.02.2000',
    nationality: 'Italien',
    height: 1.88,
    foot: 'rechts',
    teamMemberSince: '06.07.2022',
    club: 'US Salernitana 1919',
    previousClub: 'Atalanta Bergamo',
    uid: 'cd3cb14f-b016-4e17-8eea-50f966a99ee3',
    position: 'Innenverteidiger',
    league: 'Serie A',
    marketValue: 6000000,
  },
  {
    jerseyNumber: 2,
    name: 'Dylan Bronn',
    dateOfBirth: '19.06.1995',
    nationality: 'Tunesien',
    height: 1.86,
    foot: 'rechts',
    teamMemberSince: '12.08.2022',
    club: 'US Salernitana 1919',
    previousClub: 'FC Metz',
    uid: '94ee4133-3cac-4360-bfd8-840d3e21f224',
    position: 'Innenverteidiger',
    league: 'Serie A',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 15,
    name: 'William Troost-Ekong',
    dateOfBirth: '01.09.1993',
    nationality: 'Nigeria',
    height: 1.91,
    foot: 'rechts',
    teamMemberSince: '24.01.2023',
    club: 'US Salernitana 1919',
    previousClub: 'FC Watford',
    uid: 'e2b4eb44-7ae8-4e63-8820-bf62efe096ab',
    position: 'Innenverteidiger',
    league: 'Serie A',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 98,
    name: 'Lorenzo Pirola',
    dateOfBirth: '20.02.2002',
    nationality: 'Italien',
    height: 1.88,
    foot: 'links',
    teamMemberSince: '30.07.2022',
    club: 'US Salernitana 1919',
    previousClub: 'Inter Mailand',
    uid: 'a7e03766-eb12-468e-b086-420d6935ca22',
    position: 'Innenverteidiger',
    league: 'Serie A',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 23,
    name: 'Norbert Gyomber',
    dateOfBirth: '03.07.1992',
    nationality: 'Slowakei',
    height: 1.89,
    foot: 'rechts',
    teamMemberSince: '12.09.2020',
    club: 'US Salernitana 1919',
    previousClub: 'AC Perugia Calcio',
    uid: '8907b7bc-4afb-42c2-ba54-89dee51403b5',
    position: 'Innenverteidiger',
    league: 'Serie A',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 17,
    name: 'Federico Fazio',
    dateOfBirth: '17.03.1987',
    nationality: 'Argentinien',
    height: 1.95,
    foot: 'rechts',
    teamMemberSince: '29.01.2022',
    club: 'US Salernitana 1919',
    previousClub: 'AS Rom',
    uid: '2eb489d4-89a1-4a95-a943-0a3b9c5c9d1f',
    position: 'Innenverteidiger',
    league: 'Serie A',
    marketValue: 600000,
  },
  {
    jerseyNumber: 3,
    name: 'Domagoj Bradaric',
    dateOfBirth: '10.12.1999',
    nationality: 'Kroatien',
    height: 1.78,
    foot: 'links',
    teamMemberSince: '15.07.2022',
    club: 'US Salernitana 1919',
    previousClub: 'LOSC Lille',
    uid: 'c7bfd782-31d0-42be-beb5-805383013e02',
    position: 'Linker Verteidiger',
    league: 'Serie A',
    marketValue: 5000000,
  },
  {
    jerseyNumber: 30,
    name: 'Pasquale Mazzocchi',
    dateOfBirth: '27.07.1995',
    nationality: 'Italien',
    height: 1.83,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'US Salernitana 1919',
    previousClub: 'Venezia FC',
    uid: 'e4c2847c-ad99-4365-9ecf-be03bdc8bf44',
    position: 'Rechter Verteidiger',
    league: 'Serie A',
    marketValue: 5000000,
  },
  {
    jerseyNumber: 6,
    name: 'Junior Sambia',
    dateOfBirth: '07.09.1996',
    nationality: 'Frankreich',
    height: 1.85,
    foot: 'rechts',
    teamMemberSince: '19.07.2022',
    club: 'US Salernitana 1919',
    previousClub: 'HSC Montpellier',
    uid: 'be6b24e9-b465-4ceb-8a42-94581b17398a',
    position: 'Rechter Verteidiger',
    league: 'Serie A',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 25,
    name: 'Giulio Maggiore',
    dateOfBirth: '12.03.1998',
    nationality: 'Italien',
    height: 1.84,
    foot: 'rechts',
    teamMemberSince: '16.08.2022',
    club: 'US Salernitana 1919',
    previousClub: 'Spezia Calcio',
    uid: '37c34e51-7275-4e38-bcea-f60fa3d78091',
    position: 'Zentrales Mittelfeld',
    league: 'Serie A',
    marketValue: 7000000,
  },
  {
    jerseyNumber: 8,
    name: 'Emil Bohinen',
    dateOfBirth: '12.03.1999',
    nationality: 'Norwegen',
    height: 1.89,
    foot: 'links',
    teamMemberSince: '01.07.2022',
    club: 'US Salernitana 1919',
    previousClub: 'ZSKA Moskau',
    uid: '93807775-8928-48fd-9d5c-36e9dd850e64',
    position: 'Zentrales Mittelfeld',
    league: 'Serie A',
    marketValue: 4000000,
  },
  {
    jerseyNumber: 18,
    name: 'Lassana Coulibaly',
    dateOfBirth: '10.04.1996',
    nationality: 'Mali',
    height: 1.83,
    foot: 'rechts',
    teamMemberSince: '31.07.2021',
    club: 'US Salernitana 1919',
    previousClub: 'Angers SCO',
    uid: 'e2a4bd35-1947-416d-b64a-cad56a11e569',
    position: 'Zentrales Mittelfeld',
    league: 'Serie A',
    marketValue: 4000000,
  },
  {
    jerseyNumber: 10,
    name: 'Tonny Vilhena',
    dateOfBirth: '03.01.1995',
    nationality: 'Niederlande',
    height: 1.75,
    foot: 'links',
    teamMemberSince: '10.08.2022',
    club: 'US Salernitana 1919',
    previousClub: 'Espanyol Barcelona',
    uid: '4ca8f1db-7b44-48be-b15e-aaee4a8c658c',
    position: 'Zentrales Mittelfeld',
    league: 'Serie A',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 41,
    name: 'Hans Nicolussi Caviglia',
    dateOfBirth: '18.06.2000',
    nationality: 'Italien',
    height: 1.84,
    foot: 'rechts',
    teamMemberSince: '05.01.2023',
    club: 'US Salernitana 1919',
    previousClub: 'Juventus Next Gen',
    uid: '10d9cf63-ad01-43ed-afd0-9f6d6b093250',
    position: 'Zentrales Mittelfeld',
    league: 'Serie A',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 22,
    name: 'Domen Crnigoj',
    dateOfBirth: '18.11.1995',
    nationality: 'Slowenien',
    height: 1.88,
    foot: 'rechts',
    teamMemberSince: '26.01.2023',
    club: 'US Salernitana 1919',
    previousClub: 'Venezia FC',
    uid: '1c2e3648-0036-40dc-bd58-c568aad41b53',
    position: 'Zentrales Mittelfeld',
    league: 'Serie A',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 20,
    name: 'Grigoris Kastanos',
    dateOfBirth: '30.01.1998',
    nationality: 'Zypern',
    height: 1.79,
    foot: 'links',
    teamMemberSince: '02.08.2022',
    club: 'US Salernitana 1919',
    previousClub: 'Juventus Next Gen',
    uid: '729b7283-94c5-408d-ab00-2c0f7f31e6b1',
    position: 'Zentrales Mittelfeld',
    league: 'Serie A',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 87,
    name: 'Antonio Candreva',
    dateOfBirth: '28.02.1987',
    nationality: 'Italien',
    height: 1.8,
    foot: 'rechts',
    teamMemberSince: '12.08.2022',
    club: 'US Salernitana 1919',
    previousClub: 'Sampdoria Genua',
    uid: 'c5335232-f7e9-4125-a35e-8eb5a07a9554',
    position: 'Rechtes Mittelfeld',
    league: 'Serie A',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 29,
    name: 'Boulaye Dia',
    dateOfBirth: '16.11.1996',
    nationality: 'Senegal',
    height: 1.8,
    foot: 'rechts',
    teamMemberSince: '18.08.2022',
    club: 'US Salernitana 1919',
    previousClub: 'FC Villarreal',
    uid: '707cb36f-9c03-4231-ac5f-796b27060b38',
    position: 'Mittelstürmer',
    league: 'Serie A',
    marketValue: 14000000,
  },
  {
    jerseyNumber: 99,
    name: 'Krzysztof Piatek',
    dateOfBirth: '01.07.1995',
    nationality: 'Polen',
    height: 1.83,
    foot: 'rechts',
    teamMemberSince: '01.09.2022',
    club: 'US Salernitana 1919',
    previousClub: 'Hertha BSC',
    uid: 'bdbe22fd-8ac0-4ae4-9f4f-6655644ba95b',
    position: 'Mittelstürmer',
    league: 'Serie A',
    marketValue: 8000000,
  },
  {
    jerseyNumber: 9,
    name: 'Federico Bonazzoli',
    dateOfBirth: '21.05.1997',
    nationality: 'Italien',
    height: 1.82,
    foot: 'links',
    teamMemberSince: '26.07.2022',
    club: 'US Salernitana 1919',
    previousClub: 'Sampdoria Genua',
    uid: 'd6f6fb72-0e6e-41b3-9373-26c22d4a9c40',
    position: 'Mittelstürmer',
    league: 'Serie A',
    marketValue: 4000000,
  },
  {
    jerseyNumber: 11,
    name: 'Erik Botheim',
    dateOfBirth: '10.01.2000',
    nationality: 'Norwegen',
    height: 1.85,
    foot: 'rechts',
    teamMemberSince: '07.07.2022',
    club: 'US Salernitana 1919',
    previousClub: 'FK Krasnodar',
    uid: '44b79020-8310-4c33-aaa6-bf5a4536cca9',
    position: 'Mittelstürmer',
    league: 'Serie A',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 14,
    name: 'Diego Valencia',
    dateOfBirth: '14.01.2000',
    nationality: 'Chile',
    height: 1.85,
    foot: 'rechts',
    teamMemberSince: '11.07.2022',
    club: 'US Salernitana 1919',
    previousClub: 'CD Universidad Católica',
    uid: '63f0428f-6187-4f62-b1d2-9e0a1cb31e8f',
    position: 'Mittelstürmer',
    league: 'Serie A',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 30,
    name: 'Wladimiro Falcone',
    dateOfBirth: '12.04.1995',
    nationality: 'Italien',
    height: 1.95,
    foot: 'rechts',
    teamMemberSince: '17.07.2022',
    club: 'US Lecce',
    previousClub: 'Sampdoria Genua',
    uid: '284ff199-1566-4980-82e9-2ccfb6189818',
    position: 'Torwart',
    league: 'Serie A',
    marketValue: 5000000,
  },
  {
    jerseyNumber: 1,
    name: 'Marco Bleve',
    dateOfBirth: '18.10.1995',
    nationality: 'Italien',
    height: 1.84,
    foot: 'rechts',
    teamMemberSince: '31.01.2013',
    club: 'US Lecce',
    previousClub: 'Lecce Berretti',
    uid: '61481690-4206-4c5f-9b16-37b18a072f61',
    position: 'Torwart',
    league: 'Serie A',
    marketValue: 200000,
  },
  {
    jerseyNumber: 21,
    name: 'Federico Brancolini',
    dateOfBirth: '14.07.2001',
    nationality: 'Italien',
    height: 1.92,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'US Lecce',
    previousClub: 'AC Florenz',
    uid: '27597e44-9a7b-48a5-b15c-496cc9a41d72',
    position: 'Torwart',
    league: 'Serie A',
    marketValue: 200000,
  },
  {
    jerseyNumber: 6,
    name: 'Federico Baschirotto',
    dateOfBirth: '20.09.1996',
    nationality: 'Italien',
    height: 1.87,
    foot: 'rechts',
    teamMemberSince: '12.07.2022',
    club: 'US Lecce',
    previousClub: 'Ascoli Calcio',
    uid: 'b417681d-5b0a-44f5-ae5e-84d620cd782b',
    position: 'Innenverteidiger',
    league: 'Serie A',
    marketValue: 8000000,
  },
  {
    jerseyNumber: 5,
    name: 'Marin Pongracic',
    dateOfBirth: '11.09.1997',
    nationality: 'Kroatien',
    height: 1.93,
    foot: 'rechts',
    teamMemberSince: '22.08.2022',
    club: 'US Lecce',
    previousClub: 'VfL Wolfsburg',
    uid: '66c3ea7c-7446-402a-979c-69c704ef4c59',
    position: 'Innenverteidiger',
    league: 'Serie A',
    marketValue: 6000000,
  },
  {
    jerseyNumber: 93,
    name: 'Samuel Umtiti',
    dateOfBirth: '14.11.1993',
    nationality: 'Frankreich',
    height: 1.82,
    foot: 'links',
    teamMemberSince: '25.08.2022',
    club: 'US Lecce',
    previousClub: 'FC Barcelona',
    uid: 'bcf3cc69-8f8f-40c7-a905-fc8b0058cfd9',
    position: 'Innenverteidiger',
    league: 'Serie A',
    marketValue: 4000000,
  },
  {
    jerseyNumber: 18,
    name: 'Pietro Ceccaroni',
    dateOfBirth: '21.12.1995',
    nationality: 'Italien',
    height: 1.88,
    foot: 'links',
    teamMemberSince: '31.01.2023',
    club: 'US Lecce',
    previousClub: 'Venezia FC',
    uid: 'c3496179-3d53-43d9-9e92-ab999350e259',
    position: 'Innenverteidiger',
    league: 'Serie A',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 4,
    name: 'Simone Romagnoli',
    dateOfBirth: '09.02.1990',
    nationality: 'Italien',
    height: 1.93,
    foot: 'rechts',
    teamMemberSince: '31.01.2023',
    club: 'US Lecce',
    previousClub: 'Parma Calcio 1913',
    uid: 'd84f8e35-8642-4a68-bdbf-a1bfb6b92649',
    position: 'Innenverteidiger',
    league: 'Serie A',
    marketValue: 750000,
  },
  {
    jerseyNumber: 13,
    name: 'Alessandro Tuia',
    dateOfBirth: '08.06.1990',
    nationality: 'Italien',
    height: 1.84,
    foot: 'rechts',
    teamMemberSince: '01.07.2021',
    club: 'US Lecce',
    previousClub: 'Benevento Calcio',
    uid: 'f0819d6a-80ab-41d9-aff8-e14ccf2d6dfa',
    position: 'Innenverteidiger',
    league: 'Serie A',
    marketValue: 700000,
  },
  {
    jerseyNumber: 3,
    name: 'Kastriot Dermaku',
    dateOfBirth: '15.01.1992',
    nationality: 'Albanien',
    height: 1.94,
    foot: 'rechts',
    teamMemberSince: '30.08.2021',
    club: 'US Lecce',
    previousClub: 'Parma Calcio 1913',
    uid: '44bf769a-369c-4f4b-a6e6-8ee1aea86b47',
    position: 'Innenverteidiger',
    league: 'Serie A',
    marketValue: 600000,
  },
  {
    jerseyNumber: 97,
    name: 'Giuseppe Pezzella',
    dateOfBirth: '29.11.1997',
    nationality: 'Italien',
    height: 1.87,
    foot: 'links',
    teamMemberSince: '26.08.2022',
    club: 'US Lecce',
    previousClub: 'Parma Calcio 1913',
    uid: '0883e304-5267-4c75-84b7-6c6b8edf135e',
    position: 'Linker Verteidiger',
    league: 'Serie A',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 25,
    name: 'Antonino Gallo',
    dateOfBirth: '05.01.2000',
    nationality: 'Italien',
    height: 1.83,
    foot: 'links',
    teamMemberSince: '25.07.2019',
    club: 'US Lecce',
    previousClub: ': Ablöse ablösefrei',
    uid: 'ec7e8641-d985-4bf5-8ad2-0e624c3e7be4',
    position: 'Linker Verteidiger',
    league: 'Serie A',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 17,
    name: 'Valentin Gendrey',
    dateOfBirth: '21.06.2000',
    nationality: 'Frankreich',
    height: 1.79,
    foot: 'rechts',
    teamMemberSince: '29.07.2021',
    club: 'US Lecce',
    previousClub: 'Amiens SC',
    uid: '77d80162-e4fe-4f54-b90e-bdd333bc62f7',
    position: 'Rechter Verteidiger',
    league: 'Serie A',
    marketValue: 4000000,
  },
  {
    jerseyNumber: 84,
    name: 'Tommaso Cassandro',
    dateOfBirth: '09.01.2000',
    nationality: 'Italien',
    height: 1.85,
    foot: 'rechts',
    teamMemberSince: '17.01.2023',
    club: 'US Lecce',
    previousClub: 'AS Cittadella',
    uid: 'cca039de-ca32-4ec1-903f-be690bd40b80',
    position: 'Rechter Verteidiger',
    league: 'Serie A',
    marketValue: 900000,
  },
  {
    jerseyNumber: 83,
    name: 'Mats Lemmens',
    dateOfBirth: '10.03.2002',
    nationality: 'Belgien',
    height: 1.86,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'US Lecce',
    previousClub: 'US Lecce U19',
    uid: 'ba7ff925-a990-43dd-b688-7cf3bad631ca',
    position: 'Rechter Verteidiger',
    league: 'Serie A',
    marketValue: 100000,
  },
  {
    jerseyNumber: 42,
    name: 'Morten Hjulmand',
    dateOfBirth: '25.06.1999',
    nationality: 'Dänemark',
    height: 1.85,
    foot: 'rechts',
    teamMemberSince: '16.01.2021',
    club: 'US Lecce',
    previousClub: 'FC Admira Wacker Mödling',
    uid: '1db9a11a-561b-4545-a87f-07b9d95fbd33',
    position: 'Defensives Mittelfeld',
    league: 'Serie A',
    marketValue: 12000000,
  },
  {
    jerseyNumber: 20,
    name: 'Daniel Samek',
    dateOfBirth: '19.02.2004',
    nationality: 'Tschechien',
    height: 1.84,
    foot: 'rechts',
    teamMemberSince: '30.07.2022',
    club: 'US Lecce',
    previousClub: 'SK Slavia Prag',
    uid: 'c983137a-8976-4992-a89c-c4dee5dce4ba',
    position: 'Defensives Mittelfeld',
    league: 'Serie A',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 29,
    name: 'Alexis Blin',
    dateOfBirth: '16.09.1996',
    nationality: 'Frankreich',
    height: 1.84,
    foot: 'rechts',
    teamMemberSince: '01.07.2021',
    club: 'US Lecce',
    previousClub: 'Amiens SC',
    uid: '353c6523-4c38-42f7-b423-ff8dd95d40f0',
    position: 'Defensives Mittelfeld',
    league: 'Serie A',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 16,
    name: 'Joan González',
    dateOfBirth: '01.02.2002',
    nationality: 'Spanien',
    height: 1.9,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'US Lecce',
    previousClub: 'US Lecce U19',
    uid: 'bc43a2bf-ad34-4bab-b1ab-fae8d905b770',
    position: 'Zentrales Mittelfeld',
    league: 'Serie A',
    marketValue: 6000000,
  },
  {
    jerseyNumber: 32,
    name: 'Youssef Maleh',
    dateOfBirth: '22.08.1998',
    nationality: 'Marokko',
    height: 1.79,
    foot: 'links',
    teamMemberSince: '03.01.2023',
    club: 'US Lecce',
    previousClub: 'AC Florenz',
    uid: '5d3b62c3-6433-435c-b153-fe200dc3252e',
    position: 'Zentrales Mittelfeld',
    league: 'Serie A',
    marketValue: 4000000,
  },
  {
    jerseyNumber: 7,
    name: 'Kristoffer Askildsen',
    dateOfBirth: '09.01.2001',
    nationality: 'Norwegen',
    height: 1.9,
    foot: 'rechts',
    teamMemberSince: '17.07.2022',
    club: 'US Lecce',
    previousClub: 'Sampdoria Genua',
    uid: '7ffdcbd4-0204-4582-8d64-ce48c768f952',
    position: 'Zentrales Mittelfeld',
    league: 'Serie A',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 14,
    name: 'Thórir Jóhann Helgason',
    dateOfBirth: '28.09.2000',
    nationality: 'Island',
    height: 1.87,
    foot: 'rechts',
    teamMemberSince: '15.07.2021',
    club: 'US Lecce',
    previousClub: 'FH Hafnarfjördur',
    uid: '2b69268a-32b7-4930-bc4a-f6a33c030bed',
    position: 'Zentrales Mittelfeld',
    league: 'Serie A',
    marketValue: 900000,
  },
  {
    jerseyNumber: 80,
    name: 'Medon Berisha',
    dateOfBirth: '21.10.2003',
    nationality: 'Albanien',
    height: 1.86,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'US Lecce',
    previousClub: 'US Lecce U19',
    uid: '70ac6ff6-775d-4e6c-b539-08d7b8cf619a',
    position: 'Zentrales Mittelfeld',
    league: 'Serie A',
    marketValue: 100000,
  },
  {
    jerseyNumber: 22,
    name: 'Lameck Banda',
    dateOfBirth: '29.01.2001',
    nationality: 'Sambia',
    height: 1.69,
    foot: 'rechts',
    teamMemberSince: '04.08.2022',
    club: 'US Lecce',
    previousClub: 'Maccabi Petah Tikva',
    uid: '18fbec73-d765-42d0-ab08-e9f470719689',
    position: 'Linksaußen',
    league: 'Serie A',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 11,
    name: 'Federico Di Francesco',
    dateOfBirth: '14.06.1994',
    nationality: 'Italien',
    height: 1.71,
    foot: 'rechts',
    teamMemberSince: '31.07.2022',
    club: 'US Lecce',
    previousClub: 'SPAL',
    uid: '77345b9b-599a-4f92-8cfc-3b00f4e0161a',
    position: 'Linksaußen',
    league: 'Serie A',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 27,
    name: 'Gabriel Strefezza',
    dateOfBirth: '18.04.1997',
    nationality: 'Brasilien',
    height: 1.68,
    foot: 'rechts',
    teamMemberSince: '12.08.2021',
    club: 'US Lecce',
    previousClub: 'SPAL',
    uid: '87218739-d685-4f6d-b75c-7dc872e1c112',
    position: 'Rechtsaußen',
    league: 'Serie A',
    marketValue: 10000000,
  },
  {
    jerseyNumber: 28,
    name: 'Rémi Oudin',
    dateOfBirth: '18.11.1996',
    nationality: 'Frankreich',
    height: 1.86,
    foot: 'links',
    teamMemberSince: '31.08.2022',
    club: 'US Lecce',
    previousClub: 'FC Girondins Bordeaux',
    uid: '85309c68-359c-4ef3-ac0a-d4d1f2161d39',
    position: 'Rechtsaußen',
    league: 'Serie A',
    marketValue: 4000000,
  },
  {
    jerseyNumber: 9,
    name: 'Lorenzo Colombo',
    dateOfBirth: '08.03.2002',
    nationality: 'Italien',
    height: 1.83,
    foot: 'links',
    teamMemberSince: '07.07.2022',
    club: 'US Lecce',
    previousClub: 'AC Mailand',
    uid: '61b63dbd-0e5a-45ed-a93f-b416d42b7813',
    position: 'Mittelstürmer',
    league: 'Serie A',
    marketValue: 5000000,
  },
  {
    jerseyNumber: 77,
    name: 'Assan Ceesay',
    dateOfBirth: '17.03.1994',
    nationality: 'Gambia',
    height: 1.88,
    foot: 'links',
    teamMemberSince: '01.07.2022',
    club: 'US Lecce',
    previousClub: 'FC Zürich',
    uid: '49411308-564b-4848-b889-fd7bbf902ffd',
    position: 'Mittelstürmer',
    league: 'Serie A',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 31,
    name: 'Joel Voelkerling Persson',
    dateOfBirth: '15.01.2003',
    nationality: 'Schweden',
    height: 1.94,
    foot: 'rechts',
    teamMemberSince: '13.07.2022',
    club: 'US Lecce',
    previousClub: 'AS Rom U19',
    uid: '9769938b-92dd-43b5-bcbf-49b17c177c6c',
    position: 'Mittelstürmer',
    league: 'Serie A',
    marketValue: 600000,
  },
  {
    jerseyNumber: 69,
    name: 'Bartlomiej Dragowski',
    dateOfBirth: '19.08.1997',
    nationality: 'Polen',
    height: 1.91,
    foot: 'rechts',
    teamMemberSince: '10.08.2022',
    club: 'Spezia Calcio',
    previousClub: 'AC Florenz',
    uid: '132da1d3-06d8-4df3-a329-b8c586ebee22',
    position: 'Torwart',
    league: 'Serie A',
    marketValue: 4000000,
  },
  {
    jerseyNumber: 1,
    name: 'Jeroen Zoet',
    dateOfBirth: '06.01.1991',
    nationality: 'Niederlande',
    height: 1.89,
    foot: 'rechts',
    teamMemberSince: '08.09.2020',
    club: 'Spezia Calcio',
    previousClub: 'PSV Eindhoven',
    uid: '5126fc3a-9a75-48ff-a0ea-62e469697a6e',
    position: 'Torwart',
    league: 'Serie A',
    marketValue: 500000,
  },
  {
    jerseyNumber: 40,
    name: 'Petar Zovko',
    dateOfBirth: '25.03.2002',
    nationality: 'Bosnien-Herzegowina',
    height: 1.92,
    foot: 'links',
    teamMemberSince: '12.07.2021',
    club: 'Spezia Calcio',
    previousClub: 'Sampdoria Genua U19',
    uid: '807a836f-1f68-4a58-b2c7-2aeebb5d5197',
    position: 'Torwart',
    league: 'Serie A',
    marketValue: 100000,
  },
  {
    jerseyNumber: 22,
    name: 'Federico Marchetti',
    dateOfBirth: '07.02.1983',
    nationality: 'Italien',
    height: 1.88,
    foot: 'links',
    teamMemberSince: '21.01.2023',
    club: 'Spezia Calcio',
    previousClub: 'Vereinslos',
    uid: '9e4e1d06-3cc1-4b0a-b041-742b9cd11b29',
    position: 'Torwart',
    league: 'Serie A',
    marketValue: 50000,
  },
  {
    jerseyNumber: 4,
    name: 'Ethan Ampadu',
    dateOfBirth: '14.09.2000',
    nationality: 'Wales',
    height: 1.83,
    foot: 'rechts',
    teamMemberSince: '01.09.2022',
    club: 'Spezia Calcio',
    previousClub: 'FC Chelsea',
    uid: 'b7d9ee26-e1e6-4ab0-9ac6-ff2f181a23cf',
    position: 'Innenverteidiger',
    league: 'Serie A',
    marketValue: 9000000,
  },
  {
    jerseyNumber: 43,
    name: 'Dimitrios Nikolaou',
    dateOfBirth: '13.08.1998',
    nationality: 'Griechenland',
    height: 1.88,
    foot: 'links',
    teamMemberSince: '09.08.2021',
    club: 'Spezia Calcio',
    previousClub: 'FC Empoli',
    uid: 'e1f7becf-785c-4af0-8c1f-2f2239bd57c4',
    position: 'Innenverteidiger',
    league: 'Serie A',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 55,
    name: 'Przemyslaw Wisniewski',
    dateOfBirth: '27.07.1998',
    nationality: 'Polen',
    height: 1.95,
    foot: 'rechts',
    teamMemberSince: '25.01.2023',
    club: 'Spezia Calcio',
    previousClub: 'Venezia FC',
    uid: '64ab3971-be1b-4dea-b825-552bcd6d4c4e',
    position: 'Innenverteidiger',
    league: 'Serie A',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 29,
    name: 'Mattia Caldara',
    dateOfBirth: '05.05.1994',
    nationality: 'Italien',
    height: 1.87,
    foot: 'rechts',
    teamMemberSince: '17.07.2022',
    club: 'Spezia Calcio',
    previousClub: 'AC Mailand',
    uid: '92a6c153-3855-4293-a5af-b43d73771782',
    position: 'Innenverteidiger',
    league: 'Serie A',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 13,
    name: 'Arkadiusz Reca',
    dateOfBirth: '17.06.1995',
    nationality: 'Polen',
    height: 1.87,
    foot: 'links',
    teamMemberSince: '01.07.2022',
    club: 'Spezia Calcio',
    previousClub: 'Atalanta Bergamo',
    uid: 'c4b14378-d286-4024-998c-58556651cf30',
    position: 'Linker Verteidiger',
    league: 'Serie A',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 5,
    name: 'João Moutinho',
    dateOfBirth: '12.01.1998',
    nationality: 'Portugal',
    height: 1.83,
    foot: 'links',
    teamMemberSince: '02.01.2023',
    club: 'Spezia Calcio',
    previousClub: 'Orlando City SC',
    uid: '18d91f65-9b6b-4568-909c-8ffa514e548a',
    position: 'Linker Verteidiger',
    league: 'Serie A',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 2,
    name: 'Emil Holm',
    dateOfBirth: '13.05.2000',
    nationality: 'Schweden',
    height: 1.91,
    foot: 'rechts',
    teamMemberSince: '26.08.2021',
    club: 'Spezia Calcio',
    previousClub: ': Ablöse 300 Tsd. €',
    uid: '3963e84f-fe3b-4381-a701-3ecad31c5dcb',
    position: 'Rechter Verteidiger',
    league: 'Serie A',
    marketValue: 5000000,
  },
  {
    jerseyNumber: 27,
    name: 'Kelvin Amian',
    dateOfBirth: '08.02.1998',
    nationality: 'Frankreich',
    height: 1.8,
    foot: 'rechts',
    teamMemberSince: '22.07.2021',
    club: 'Spezia Calcio',
    previousClub: 'FC Toulouse',
    uid: '25b45cd6-1c44-41c7-909c-30b2f614d12c',
    position: 'Rechter Verteidiger',
    league: 'Serie A',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 21,
    name: 'Salva Ferrer',
    dateOfBirth: '21.01.1998',
    nationality: 'Spanien',
    height: 1.84,
    foot: 'rechts',
    teamMemberSince: '09.08.2019',
    club: 'Spezia Calcio',
    previousClub: 'Gimnàstic de Tarragona',
    uid: 'faca8b53-d907-4c0f-9759-23993f834e18',
    position: 'Rechter Verteidiger',
    league: 'Serie A',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 25,
    name: 'Salvatore Esposito',
    dateOfBirth: '07.10.2000',
    nationality: 'Italien',
    height: 1.78,
    foot: 'rechts',
    teamMemberSince: '06.01.2023',
    club: 'Spezia Calcio',
    previousClub: 'SPAL',
    uid: 'a4fb6a91-6175-4cd6-91a1-5b431794b3c1',
    position: 'Defensives Mittelfeld',
    league: 'Serie A',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 6,
    name: 'Mehdi Bourabia',
    dateOfBirth: '07.08.1991',
    nationality: 'Marokko',
    height: 1.83,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'Spezia Calcio',
    previousClub: 'US Sassuolo',
    uid: '23f5b910-44ac-4ff7-bd72-8d02a27cbec5',
    position: 'Defensives Mittelfeld',
    league: 'Serie A',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 8,
    name: 'Albin Ekdal',
    dateOfBirth: '28.07.1989',
    nationality: 'Schweden',
    height: 1.86,
    foot: 'rechts',
    teamMemberSince: '13.07.2022',
    club: 'Spezia Calcio',
    previousClub: 'Sampdoria Genua',
    uid: '7ba0cd8f-8756-4d2c-b5c8-efb35d3003cd',
    position: 'Defensives Mittelfeld',
    league: 'Serie A',
    marketValue: 800000,
  },
  {
    jerseyNumber: 20,
    name: 'Simone Bastoni',
    dateOfBirth: '05.11.1996',
    nationality: 'Italien',
    height: 1.81,
    foot: 'links',
    teamMemberSince: '01.07.2014',
    club: 'Spezia Calcio',
    previousClub: 'Spezia Calcio U19',
    uid: 'b48f2534-edb4-4e81-ba41-d306951d04e6',
    position: 'Zentrales Mittelfeld',
    league: 'Serie A',
    marketValue: 7000000,
  },
  {
    jerseyNumber: 77,
    name: 'Szymon Zurkowski',
    dateOfBirth: '25.09.1997',
    nationality: 'Polen',
    height: 1.85,
    foot: 'rechts',
    teamMemberSince: '12.01.2023',
    club: 'Spezia Calcio',
    previousClub: 'AC Florenz',
    uid: 'd439cb9a-89d1-4d19-9beb-3f2651e858b8',
    position: 'Zentrales Mittelfeld',
    league: 'Serie A',
    marketValue: 4000000,
  },
  {
    jerseyNumber: 7,
    name: 'Jacopo Sala',
    dateOfBirth: '05.12.1991',
    nationality: 'Italien',
    height: 1.81,
    foot: 'rechts',
    teamMemberSince: '05.09.2020',
    club: 'Spezia Calcio',
    previousClub: 'SPAL',
    uid: '7c8d19b1-831a-48b5-bf38-33d4fd78ea0d',
    position: 'Zentrales Mittelfeld',
    league: 'Serie A',
    marketValue: 500000,
  },
  {
    jerseyNumber: 72,
    name: 'Tio Cipot',
    dateOfBirth: '20.04.2003',
    nationality: 'Slowenien',
    height: 1.84,
    foot: 'rechts',
    teamMemberSince: '19.01.2023',
    club: 'Spezia Calcio',
    previousClub: 'NS Mura',
    uid: 'f4b6af03-bbc3-49e6-9f78-78dc70f7e8d2',
    position: 'Zentrales Mittelfeld',
    league: 'Serie A',
    marketValue: 500000,
  },
  {
    jerseyNumber: 16,
    name: 'Julius Beck',
    dateOfBirth: '27.04.2005',
    nationality: 'Dänemark',
    height: 1.78,
    foot: 'rechts',
    teamMemberSince: '01.08.2022',
    club: 'Spezia Calcio',
    previousClub: 'Spezia Calcio U19',
    uid: '7aa51880-31be-43dc-9ce0-39f79936668e',
    position: 'Zentrales Mittelfeld',
    league: 'Serie A',
    marketValue: 250000,
  },
  {
    jerseyNumber: 33,
    name: 'Kevin Agudelo',
    dateOfBirth: '14.11.1998',
    nationality: 'Kolumbien',
    height: 1.78,
    foot: 'links',
    teamMemberSince: '01.07.2022',
    club: 'Spezia Calcio',
    previousClub: 'Genua CFC',
    uid: 'fd867ca0-a025-49f6-b322-010e0bcfc907',
    position: 'Offensives Mittelfeld',
    league: 'Serie A',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 24,
    name: 'Viktor Kovalenko',
    dateOfBirth: '14.02.1996',
    nationality: 'Ukraine',
    height: 1.82,
    foot: 'rechts',
    teamMemberSince: '02.08.2022',
    club: 'Spezia Calcio',
    previousClub: 'Atalanta Bergamo',
    uid: '7304f2c6-f482-4e36-806b-2c65c6cb6d31',
    position: 'Offensives Mittelfeld',
    league: 'Serie A',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 30,
    name: 'Daniel Maldini',
    dateOfBirth: '11.10.2001',
    nationality: 'Italien',
    height: 1.88,
    foot: 'rechts',
    teamMemberSince: '29.07.2022',
    club: 'Spezia Calcio',
    previousClub: 'AC Mailand',
    uid: 'abc978de-0990-4677-b2b8-a7abd9808e2b',
    position: 'Offensives Mittelfeld',
    league: 'Serie A',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 11,
    name: 'Emmanuel Gyasi',
    dateOfBirth: '11.01.1994',
    nationality: 'Ghana',
    height: 1.81,
    foot: 'rechts',
    teamMemberSince: '09.08.2016',
    club: 'Spezia Calcio',
    previousClub: ': Ablöse 1 Tsd. €',
    uid: 'bd4d8114-7645-436e-87c5-72e4c2bba1e3',
    position: 'Linksaußen',
    league: 'Serie A',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 10,
    name: 'Daniele Verde',
    dateOfBirth: '20.06.1996',
    nationality: 'Italien',
    height: 1.68,
    foot: 'links',
    teamMemberSince: '01.07.2021',
    club: 'Spezia Calcio',
    previousClub: 'AEK Athen',
    uid: '8865bed2-20be-48d8-b89d-c7c6a890082d',
    position: 'Rechtsaußen',
    league: 'Serie A',
    marketValue: 4000000,
  },
  {
    jerseyNumber: 14,
    name: 'Eldor Shomurodov',
    dateOfBirth: '29.06.1995',
    nationality: 'Usbekistan',
    height: 1.9,
    foot: 'rechts',
    teamMemberSince: '30.01.2023',
    club: 'Spezia Calcio',
    previousClub: 'AS Rom',
    uid: 'b07513bf-1515-4752-aea7-47550d57e5e6',
    position: 'Mittelstürmer',
    league: 'Serie A',
    marketValue: 7000000,
  },
  {
    jerseyNumber: 18,
    name: "M'Bala Nzola",
    dateOfBirth: '18.08.1996',
    nationality: 'Angola',
    height: 1.85,
    foot: 'links',
    teamMemberSince: '07.10.2020',
    club: 'Spezia Calcio',
    previousClub: ': Ablöse ablösefrei',
    uid: '9ea5bb65-80a2-4e30-ba13-9490b5c57d7d',
    position: 'Mittelstürmer',
    league: 'Serie A',
    marketValue: 6000000,
  },
  {
    jerseyNumber: 19,
    name: 'Raimonds Krollis',
    dateOfBirth: '28.10.2001',
    nationality: 'Lettland',
    height: 1.86,
    foot: 'links',
    teamMemberSince: '20.01.2023',
    club: 'Spezia Calcio',
    previousClub: 'Valmiera FC',
    uid: 'fc3a858b-5dc8-4671-9358-8d4d3b8fb153',
    position: 'Mittelstürmer',
    league: 'Serie A',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 1,
    name: 'Lorenzo Montipò',
    dateOfBirth: '20.02.1996',
    nationality: 'Italien',
    height: 1.91,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'Hellas Verona',
    previousClub: 'Benevento Calcio',
    uid: 'abc4728f-3cfc-422f-8ea9-e9e1c2ca6651',
    position: 'Torwart',
    league: 'Serie A',
    marketValue: 4000000,
  },
  {
    jerseyNumber: 34,
    name: 'Simone Perilli',
    dateOfBirth: '07.01.1995',
    nationality: 'Italien',
    height: 1.95,
    foot: 'rechts',
    teamMemberSince: '03.08.2022',
    club: 'Hellas Verona',
    previousClub: 'Brescia Calcio',
    uid: '370af71f-876f-442c-910c-6fdb1c3fec37',
    position: 'Torwart',
    league: 'Serie A',
    marketValue: 200000,
  },
  {
    jerseyNumber: 22,
    name: 'Alessandro Berardi',
    dateOfBirth: '16.01.1991',
    nationality: 'Italien',
    height: 1.85,
    foot: 'rechts',
    teamMemberSince: '14.12.2018',
    club: 'Hellas Verona',
    previousClub: 'Vereinslos',
    uid: '3d1df64c-0009-4cc6-9a7b-b897443d2586',
    position: 'Torwart',
    league: 'Serie A',
    marketValue: 50000,
  },
  {
    jerseyNumber: 6,
    name: 'Isak Hien',
    dateOfBirth: '13.01.1999',
    nationality: 'Schweden',
    height: 1.91,
    foot: 'rechts',
    teamMemberSince: '27.08.2022',
    club: 'Hellas Verona',
    previousClub: 'Djurgårdens IF',
    uid: '377ba1c0-0fcb-4b17-91f6-9a8d7230cc9b',
    position: 'Innenverteidiger',
    league: 'Serie A',
    marketValue: 6000000,
  },
  {
    jerseyNumber: 27,
    name: 'Pawel Dawidowicz',
    dateOfBirth: '20.05.1995',
    nationality: 'Polen',
    height: 1.89,
    foot: 'beidfüßig',
    teamMemberSince: '01.07.2019',
    club: 'Hellas Verona',
    previousClub: 'Benfica Lissabon',
    uid: '5fc07ebf-07eb-432f-bc7d-eebd8bd73e0f',
    position: 'Innenverteidiger',
    league: 'Serie A',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 17,
    name: 'Federico Ceccherini',
    dateOfBirth: '11.05.1992',
    nationality: 'Italien',
    height: 1.87,
    foot: 'rechts',
    teamMemberSince: '01.07.2021',
    club: 'Hellas Verona',
    previousClub: ': Ablöse 3,00 Mio. €',
    uid: 'e40cc695-3257-4074-be22-105f9e51e9f3',
    position: 'Innenverteidiger',
    league: 'Serie A',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 23,
    name: 'Giangiacomo Magnani',
    dateOfBirth: '04.10.1995',
    nationality: 'Italien',
    height: 1.91,
    foot: 'rechts',
    teamMemberSince: '01.07.2021',
    club: 'Hellas Verona',
    previousClub: 'US Sassuolo',
    uid: '15a56908-4bda-4275-b800-d61ae5ea4d48',
    position: 'Innenverteidiger',
    league: 'Serie A',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 42,
    name: 'Diego Coppola',
    dateOfBirth: '28.12.2003',
    nationality: 'Italien',
    height: 1.92,
    foot: 'rechts',
    teamMemberSince: '01.01.2022',
    club: 'Hellas Verona',
    previousClub: 'Hellas Verona U19',
    uid: '5885b203-88bb-4f10-b81e-0ffbea93111b',
    position: 'Innenverteidiger',
    league: 'Serie A',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 32,
    name: 'Juan Cabal',
    dateOfBirth: '08.01.2001',
    nationality: 'Kolumbien',
    height: 1.86,
    foot: 'links',
    teamMemberSince: '20.08.2022',
    club: 'Hellas Verona',
    previousClub: 'Atlético Nacional',
    uid: '5baf4e13-07ee-4aeb-a703-d38750736ee6',
    position: 'Innenverteidiger',
    league: 'Serie A',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 3,
    name: 'Josh Doig',
    dateOfBirth: '18.05.2002',
    nationality: 'Schottland',
    height: 1.89,
    foot: 'links',
    teamMemberSince: '13.07.2022',
    club: 'Hellas Verona',
    previousClub: 'Hibernian FC',
    uid: '57dddc71-bf55-41a7-b546-f6519780e2c4',
    position: 'Linker Verteidiger',
    league: 'Serie A',
    marketValue: 10000000,
  },
  {
    jerseyNumber: 5,
    name: 'Davide Faraoni',
    dateOfBirth: '25.10.1991',
    nationality: 'Italien',
    height: 1.8,
    foot: 'rechts',
    teamMemberSince: '01.07.2019',
    club: 'Hellas Verona',
    previousClub: 'FC Crotone',
    uid: '1db0d97d-f6d6-4129-b62b-c235805c7795',
    position: 'Rechter Verteidiger',
    league: 'Serie A',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 29,
    name: 'Fabio Depaoli',
    dateOfBirth: '24.04.1997',
    nationality: 'Italien',
    height: 1.82,
    foot: 'rechts',
    teamMemberSince: '01.09.2022',
    club: 'Hellas Verona',
    previousClub: 'Sampdoria Genua',
    uid: 'bc2d340d-5d10-4226-abd6-ded81bf5ef8d',
    position: 'Rechter Verteidiger',
    league: 'Serie A',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 2,
    name: 'Deyovaisio Zeefuik',
    dateOfBirth: '11.03.1998',
    nationality: 'Niederlande',
    height: 1.77,
    foot: 'rechts',
    teamMemberSince: '16.01.2023',
    club: 'Hellas Verona',
    previousClub: 'Hertha BSC',
    uid: 'f7c96e7f-4280-466a-b3e4-2ad675cddf1c',
    position: 'Rechter Verteidiger',
    league: 'Serie A',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 61,
    name: 'Adrien Tamèze',
    dateOfBirth: '04.02.1994',
    nationality: 'Kamerun',
    height: 1.8,
    foot: 'rechts',
    teamMemberSince: '03.09.2020',
    club: 'Hellas Verona',
    previousClub: 'OGC Nizza',
    uid: 'e8f4bd0e-97b5-4329-86f2-85257c2cb690',
    position: 'Defensives Mittelfeld',
    league: 'Serie A',
    marketValue: 8000000,
  },
  {
    jerseyNumber: 28,
    name: 'Oliver Abildgaard',
    dateOfBirth: '10.06.1996',
    nationality: 'Dänemark',
    height: 1.92,
    foot: 'links',
    teamMemberSince: '31.01.2023',
    club: 'Hellas Verona',
    previousClub: 'Celtic Glasgow',
    uid: '8e5e02d8-d6d7-4857-b90e-ee922a7bcc2b',
    position: 'Defensives Mittelfeld',
    league: 'Serie A',
    marketValue: 5000000,
  },
  {
    jerseyNumber: 77,
    name: 'Ibrahim Sulemana',
    dateOfBirth: '22.05.2003',
    nationality: 'Ghana',
    height: 1.8,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'Hellas Verona',
    previousClub: 'Hellas Verona U19',
    uid: '66ec7b3d-fa90-456f-9370-22fa14570b2d',
    position: 'Defensives Mittelfeld',
    league: 'Serie A',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 4,
    name: 'Miguel Veloso',
    dateOfBirth: '11.05.1986',
    nationality: 'Portugal',
    height: 1.8,
    foot: 'links',
    teamMemberSince: '20.07.2019',
    club: 'Hellas Verona',
    previousClub: ': Ablöse ablösefrei',
    uid: '2ca89f5c-1233-4e43-bde0-d353492fadc0',
    position: 'Defensives Mittelfeld',
    league: 'Serie A',
    marketValue: 700000,
  },
  {
    jerseyNumber: 24,
    name: 'Filippo Terracciano',
    dateOfBirth: '08.02.2003',
    nationality: 'Italien',
    height: 1.81,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'Hellas Verona',
    previousClub: 'Hellas Verona U19',
    uid: '48c7284c-4024-455c-8b22-71ea9e887506',
    position: 'Rechtes Mittelfeld',
    league: 'Serie A',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 8,
    name: 'Darko Lazovic',
    dateOfBirth: '15.09.1990',
    nationality: 'Serbien',
    height: 1.81,
    foot: 'rechts',
    teamMemberSince: '06.08.2019',
    club: 'Hellas Verona',
    previousClub: ': Ablöse ablösefrei',
    uid: '4bc17926-c463-4c6b-a16c-cd8a14636a3b',
    position: 'Linkes Mittelfeld',
    league: 'Serie A',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 7,
    name: 'Simone Verdi',
    dateOfBirth: '12.07.1992',
    nationality: 'Italien',
    height: 1.74,
    foot: 'beidfüßig',
    teamMemberSince: '01.09.2022',
    club: 'Hellas Verona',
    previousClub: 'FC Turin',
    uid: '3217f02b-67e7-45ef-8e56-64884933a4da',
    position: 'Offensives Mittelfeld',
    league: 'Serie A',
    marketValue: 4000000,
  },
  {
    jerseyNumber: 33,
    name: 'Ondrej Duda',
    dateOfBirth: '05.12.1994',
    nationality: 'Slowakei',
    height: 1.81,
    foot: 'rechts',
    teamMemberSince: '29.01.2023',
    club: 'Hellas Verona',
    previousClub: '1.FC Köln',
    uid: '9f88c2cb-2e73-4efc-8d10-e31bc8f6bb67',
    position: 'Offensives Mittelfeld',
    league: 'Serie A',
    marketValue: 4000000,
  },
  {
    jerseyNumber: 10,
    name: 'Ajdin Hrustic',
    dateOfBirth: '05.07.1996',
    nationality: 'Australien',
    height: 1.8,
    foot: 'links',
    teamMemberSince: '01.09.2022',
    club: 'Hellas Verona',
    previousClub: 'Eintracht Frankfurt',
    uid: '9cf1c331-982c-4afe-a33e-ee435a2831c7',
    position: 'Offensives Mittelfeld',
    league: 'Serie A',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 30,
    name: 'Yayah Kallon',
    dateOfBirth: '30.06.2001',
    nationality: 'Sierra Leone',
    height: 1.75,
    foot: 'links',
    teamMemberSince: '26.08.2022',
    club: 'Hellas Verona',
    previousClub: 'Genua CFC',
    uid: '8bda9c23-3c3a-4f1b-8bf3-c05848a9ac14',
    position: 'Rechtsaußen',
    league: 'Serie A',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 26,
    name: 'Cyril Ngonge',
    dateOfBirth: '26.05.2000',
    nationality: 'Belgien',
    height: 1.79,
    foot: 'links',
    teamMemberSince: '19.01.2023',
    club: 'Hellas Verona',
    previousClub: 'FC Groningen',
    uid: 'c9aa5cd3-61fb-4873-b3e5-ed060b06386e',
    position: 'Rechtsaußen',
    league: 'Serie A',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 9,
    name: 'Thomas Henry',
    dateOfBirth: '20.09.1994',
    nationality: 'Frankreich',
    height: 1.91,
    foot: 'rechts',
    teamMemberSince: '16.07.2022',
    club: 'Hellas Verona',
    previousClub: 'Venezia FC',
    uid: '9fab4d74-b6ee-4781-9ba5-639cf7138030',
    position: 'Mittelstürmer',
    league: 'Serie A',
    marketValue: 4000000,
  },
  {
    jerseyNumber: 11,
    name: 'Kevin Lasagna',
    dateOfBirth: '10.08.1992',
    nationality: 'Italien',
    height: 1.81,
    foot: 'links',
    teamMemberSince: '01.07.2021',
    club: 'Hellas Verona',
    previousClub: 'Udinese Calcio',
    uid: '64ac54ec-4e28-4b3d-bc0c-4b9af7f02dfb',
    position: 'Mittelstürmer',
    league: 'Serie A',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 38,
    name: 'Adolfo Gaich',
    dateOfBirth: '26.02.1999',
    nationality: 'Argentinien',
    height: 1.9,
    foot: 'rechts',
    teamMemberSince: '30.01.2023',
    club: 'Hellas Verona',
    previousClub: 'ZSKA Moskau',
    uid: '7ed24af6-e4c3-4128-8ae4-b416fa8d4cd5',
    position: 'Mittelstürmer',
    league: 'Serie A',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 19,
    name: 'Milan Djuric',
    dateOfBirth: '22.05.1990',
    nationality: 'Bosnien-Herzegowina',
    height: 1.99,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'Hellas Verona',
    previousClub: 'US Salernitana 1919',
    uid: 'e2e1b275-0d7f-401b-8547-237b6e257216',
    position: 'Mittelstürmer',
    league: 'Serie A',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 25,
    name: 'Jayden Braaf',
    dateOfBirth: '31.08.2002',
    nationality: 'Niederlande',
    height: 1.8,
    foot: 'rechts',
    teamMemberSince: '16.01.2023',
    club: 'Hellas Verona',
    previousClub: 'Borussia Dortmund II',
    uid: '126a6194-3f64-4851-8c19-352412dad0df',
    position: 'Mittelstürmer',
    league: 'Serie A',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 1,
    name: 'Emil Audero',
    dateOfBirth: '18.01.1997',
    nationality: 'Italien',
    height: 1.92,
    foot: 'rechts',
    teamMemberSince: '01.07.2019',
    club: 'Sampdoria Genua',
    previousClub: ': Ablöse 20,00 Mio. €',
    uid: '856bdd71-cc93-4cb9-ae86-3cf10f1e5980',
    position: 'Torwart',
    league: 'Serie A',
    marketValue: 6000000,
  },
  {
    jerseyNumber: 22,
    name: 'Martin Turk',
    dateOfBirth: '21.08.2003',
    nationality: 'Slowenien',
    height: 1.9,
    foot: 'links',
    teamMemberSince: '13.01.2023',
    club: 'Sampdoria Genua',
    previousClub: 'Parma Calcio 1913',
    uid: '4ebb2353-fa94-4713-8bda-46c8984aa102',
    position: 'Torwart',
    league: 'Serie A',
    marketValue: 300000,
  },
  {
    jerseyNumber: 30,
    name: 'Nicola Ravaglia',
    dateOfBirth: '12.12.1988',
    nationality: 'Italien',
    height: 1.84,
    foot: 'rechts',
    teamMemberSince: '01.09.2020',
    club: 'Sampdoria Genua',
    previousClub: 'US Cremonese',
    uid: '62b593ad-4a5c-4d17-af81-efe6366ea57d',
    position: 'Torwart',
    league: 'Serie A',
    marketValue: 100000,
  },
  {
    jerseyNumber: 4,
    name: 'Koray Günter',
    dateOfBirth: '16.08.1994',
    nationality: 'Deutschland',
    height: 1.86,
    foot: 'rechts',
    teamMemberSince: '31.01.2023',
    club: 'Sampdoria Genua',
    previousClub: 'Hellas Verona',
    uid: '94344422-0b3e-4b36-90ea-ffcc6ce61ba3',
    position: 'Innenverteidiger',
    league: 'Serie A',
    marketValue: 4000000,
  },
  {
    jerseyNumber: 2,
    name: 'Bruno Amione',
    dateOfBirth: '03.01.2002',
    nationality: 'Argentinien',
    height: 1.87,
    foot: 'links',
    teamMemberSince: '01.09.2022',
    club: 'Sampdoria Genua',
    previousClub: 'Hellas Verona',
    uid: '0416fed0-9bd3-4676-9edc-405a1a9a065a',
    position: 'Innenverteidiger',
    league: 'Serie A',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 17,
    name: 'Bram Nuytinck',
    dateOfBirth: '04.05.1990',
    nationality: 'Niederlande',
    height: 1.91,
    foot: 'links',
    teamMemberSince: '02.01.2023',
    club: 'Sampdoria Genua',
    previousClub: 'Udinese Calcio',
    uid: '14a9b30a-ce4f-425d-b3b9-41d8c4ba8872',
    position: 'Innenverteidiger',
    league: 'Serie A',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 21,
    name: 'Jeison Murillo',
    dateOfBirth: '27.05.1992',
    nationality: 'Kolumbien',
    height: 1.82,
    foot: 'rechts',
    teamMemberSince: '14.01.2020',
    club: 'Sampdoria Genua',
    previousClub: 'FC Valencia',
    uid: 'e5407921-fe24-4f6f-bcc6-6137f30cd29f',
    position: 'Innenverteidiger',
    league: 'Serie A',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 5,
    name: 'Marios Oikonomou',
    dateOfBirth: '06.10.1992',
    nationality: 'Griechenland',
    height: 1.89,
    foot: 'rechts',
    teamMemberSince: '17.02.2023',
    club: 'Sampdoria Genua',
    previousClub: 'Vereinslos',
    uid: '6350cd10-bcde-47c8-82a2-240f688bbb3c',
    position: 'Innenverteidiger',
    league: 'Serie A',
    marketValue: 300000,
  },
  {
    jerseyNumber: 3,
    name: 'Tommaso Augello',
    dateOfBirth: '30.08.1994',
    nationality: 'Italien',
    height: 1.8,
    foot: 'links',
    teamMemberSince: '01.09.2020',
    club: 'Sampdoria Genua',
    previousClub: 'Spezia Calcio',
    uid: '32f927ca-c56e-4431-8411-b3c9789c608d',
    position: 'Linker Verteidiger',
    league: 'Serie A',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 29,
    name: 'Nicola Murru',
    dateOfBirth: '16.12.1994',
    nationality: 'Italien',
    height: 1.8,
    foot: 'links',
    teamMemberSince: '01.07.2017',
    club: 'Sampdoria Genua',
    previousClub: 'Cagliari Calcio',
    uid: 'cf374c5f-9fe1-4cce-a0bc-f8e146909b0e',
    position: 'Linker Verteidiger',
    league: 'Serie A',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 59,
    name: 'Alessandro Zanoli',
    dateOfBirth: '03.10.2000',
    nationality: 'Italien',
    height: 1.88,
    foot: 'rechts',
    teamMemberSince: '07.01.2023',
    club: 'Sampdoria Genua',
    previousClub: 'SSC Neapel',
    uid: '2c31add3-4ddc-4e10-8025-68b0268a3dec',
    position: 'Rechter Verteidiger',
    league: 'Serie A',
    marketValue: 5000000,
  },
  {
    jerseyNumber: 13,
    name: 'Andrea Conti',
    dateOfBirth: '02.03.1994',
    nationality: 'Italien',
    height: 1.84,
    foot: 'rechts',
    teamMemberSince: '10.01.2022',
    club: 'Sampdoria Genua',
    previousClub: 'AC Mailand',
    uid: 'c3ab17b9-0d3e-4ad1-ae45-9f258af1caab',
    position: 'Rechter Verteidiger',
    league: 'Serie A',
    marketValue: 600000,
  },
  {
    jerseyNumber: 20,
    name: 'Harry Winks',
    dateOfBirth: '02.02.1996',
    nationality: 'England',
    height: 1.78,
    foot: 'rechts',
    teamMemberSince: '30.08.2022',
    club: 'Sampdoria Genua',
    previousClub: 'Tottenham Hotspur',
    uid: '818b3c74-e6e5-4ade-90db-baa721e51508',
    position: 'Defensives Mittelfeld',
    league: 'Serie A',
    marketValue: 12000000,
  },
  {
    jerseyNumber: 8,
    name: 'Tomás Rincón',
    dateOfBirth: '13.01.1988',
    nationality: 'Venezuela',
    height: 1.77,
    foot: 'rechts',
    teamMemberSince: '05.07.2022',
    club: 'Sampdoria Genua',
    previousClub: 'FC Turin',
    uid: 'cf005dcf-5c88-44ae-a071-59f913694aa7',
    position: 'Defensives Mittelfeld',
    league: 'Serie A',
    marketValue: 800000,
  },
  {
    jerseyNumber: 36,
    name: 'Flavio Paoletti',
    dateOfBirth: '16.01.2003',
    nationality: 'Italien',
    height: 1.82,
    foot: 'rechts',
    teamMemberSince: '02.01.2023',
    club: 'Sampdoria Genua',
    previousClub: 'Sampdoria Genua U19',
    uid: '9440e4d6-ee11-425e-8362-eefcfc1e0dda',
    position: 'Defensives Mittelfeld',
    league: 'Serie A',
    marketValue: 800000,
  },
  {
    jerseyNumber: 28,
    name: 'Gerard Yepes',
    dateOfBirth: '25.08.2002',
    nationality: 'Spanien',
    height: 1.7,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'Sampdoria Genua',
    previousClub: 'Sampdoria Genua U19',
    uid: '8a3c0ba6-39b1-43aa-a744-a13d054eaf23',
    position: 'Defensives Mittelfeld',
    league: 'Serie A',
    marketValue: 500000,
  },
  {
    jerseyNumber: 26,
    name: 'Emirhan Ilkhan',
    dateOfBirth: '01.06.2004',
    nationality: 'Türkei',
    height: 1.75,
    foot: 'rechts',
    teamMemberSince: '31.01.2023',
    club: 'Sampdoria Genua',
    previousClub: 'FC Turin',
    uid: '54f66e07-98c9-467d-8fa6-4cf41a851847',
    position: 'Zentrales Mittelfeld',
    league: 'Serie A',
    marketValue: 4000000,
  },
  {
    jerseyNumber: 80,
    name: 'Michaël Cuisance',
    dateOfBirth: '16.08.1999',
    nationality: 'Frankreich',
    height: 1.81,
    foot: 'links',
    teamMemberSince: '30.01.2023',
    club: 'Sampdoria Genua',
    previousClub: 'Venezia FC',
    uid: '6e0c9539-7ad3-445b-b27a-32354e52922a',
    position: 'Zentrales Mittelfeld',
    league: 'Serie A',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 37,
    name: 'Mehdi Léris',
    dateOfBirth: '23.05.1998',
    nationality: 'Algerien',
    height: 1.86,
    foot: 'rechts',
    teamMemberSince: '12.08.2019',
    club: 'Sampdoria Genua',
    previousClub: 'Chievo Verona',
    uid: '59aa040f-046f-48e8-a37e-3511df7b13e0',
    position: 'Rechtes Mittelfeld',
    league: 'Serie A',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 31,
    name: 'Lorenzo Malagrida',
    dateOfBirth: '24.10.2003',
    nationality: 'Italien',
    height: 1.74,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'Sampdoria Genua',
    previousClub: 'Sampdoria Genua U19',
    uid: '48592e88-fff7-4cc6-a574-9e2dca93b459',
    position: 'Linkes Mittelfeld',
    league: 'Serie A',
    marketValue: 250000,
  },
  {
    jerseyNumber: 11,
    name: 'Abdelhamid Sabiri',
    dateOfBirth: '28.11.1996',
    nationality: 'Marokko',
    height: 1.86,
    foot: 'rechts',
    teamMemberSince: '31.01.2023',
    club: 'Sampdoria Genua',
    previousClub: 'AC Florenz',
    uid: '9e3ecba9-d098-4a99-9e69-381981f39305',
    position: 'Offensives Mittelfeld',
    league: 'Serie A',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 7,
    name: 'Filip Djuricic',
    dateOfBirth: '30.01.1992',
    nationality: 'Serbien',
    height: 1.81,
    foot: 'rechts',
    teamMemberSince: '01.08.2022',
    club: 'Sampdoria Genua',
    previousClub: 'US Sassuolo',
    uid: '081f7181-4c55-4579-979e-a71eb0429655',
    position: 'Offensives Mittelfeld',
    league: 'Serie A',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 99,
    name: 'Jesé',
    dateOfBirth: '26.02.1993',
    nationality: 'Spanien',
    height: 1.78,
    foot: 'rechts',
    teamMemberSince: '10.02.2023',
    club: 'Sampdoria Genua',
    previousClub: 'Vereinslos',
    uid: '0e679de4-5612-41c3-9668-9ba353358ef4',
    position: 'Linksaußen',
    league: 'Serie A',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 18,
    name: 'Ignacio Pussetto',
    dateOfBirth: '21.12.1995',
    nationality: 'Argentinien',
    height: 1.8,
    foot: 'rechts',
    teamMemberSince: '01.09.2022',
    club: 'Sampdoria Genua',
    previousClub: 'FC Watford',
    uid: 'c85338ab-7029-4d47-b303-0ad51aa8c506',
    position: 'Rechtsaußen',
    league: 'Serie A',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 23,
    name: 'Manolo Gabbiadini',
    dateOfBirth: '26.11.1991',
    nationality: 'Italien',
    height: 1.86,
    foot: 'links',
    teamMemberSince: '11.01.2019',
    club: 'Sampdoria Genua',
    previousClub: 'FC Southampton',
    uid: 'bcbd32aa-3db2-4d44-ad3e-609ee4689a62',
    position: 'Mittelstürmer',
    league: 'Serie A',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 9,
    name: 'Manuel De Luca',
    dateOfBirth: '17.07.1998',
    nationality: 'Italien',
    height: 1.92,
    foot: 'beidfüßig',
    teamMemberSince: '11.08.2021',
    club: 'Sampdoria Genua',
    previousClub: 'Chievo Verona',
    uid: '9886c829-bc5f-4630-b84f-291596e3b71b',
    position: 'Mittelstürmer',
    league: 'Serie A',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 10,
    name: 'Sam Lammers',
    dateOfBirth: '30.04.1997',
    nationality: 'Niederlande',
    height: 1.91,
    foot: 'beidfüßig',
    teamMemberSince: '02.01.2023',
    club: 'Sampdoria Genua',
    previousClub: 'FC Empoli',
    uid: '83ae3e35-fc0d-43d9-aae4-05c2b6226be8',
    position: 'Mittelstürmer',
    league: 'Serie A',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 27,
    name: 'Fabio Quagliarella',
    dateOfBirth: '31.01.1983',
    nationality: 'Italien',
    height: 1.8,
    foot: 'rechts',
    teamMemberSince: '01.07.2016',
    club: 'Sampdoria Genua',
    previousClub: 'FC Turin',
    uid: 'abe87d70-640e-4c2e-8206-e9d0adad0e03',
    position: 'Mittelstürmer',
    league: 'Serie A',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 34,
    name: 'Daniele Montevago',
    dateOfBirth: '18.03.2003',
    nationality: 'Italien',
    height: 1.84,
    foot: 'rechts',
    teamMemberSince: '28.10.2022',
    club: 'Sampdoria Genua',
    previousClub: 'Sampdoria Genua U19',
    uid: 'f5e29139-a937-4b1a-8426-4f74a6d25c38',
    position: 'Mittelstürmer',
    league: 'Serie A',
    marketValue: 400000,
  },
  {
    jerseyNumber: 12,
    name: 'Marco Carnesecchi',
    dateOfBirth: '01.07.2000',
    nationality: 'Italien',
    height: 1.91,
    foot: 'rechts',
    teamMemberSince: '31.08.2022',
    club: 'US Cremonese',
    previousClub: 'Atalanta Bergamo',
    uid: 'cb5ee39b-7788-4902-8608-8179be56d015',
    position: 'Torwart',
    league: 'Serie A',
    marketValue: 13000000,
  },
  {
    jerseyNumber: 45,
    name: 'Mouhamadou Sarr',
    dateOfBirth: '05.01.1997',
    nationality: 'Senegal',
    height: 1.9,
    foot: 'rechts',
    teamMemberSince: '27.08.2021',
    club: 'US Cremonese',
    previousClub: 'FC Bologna',
    uid: 'a6587077-552b-4f7d-a767-df3b3493ada2',
    position: 'Torwart',
    league: 'Serie A',
    marketValue: 250000,
  },
  {
    jerseyNumber: 13,
    name: 'Gianluca Saro',
    dateOfBirth: '25.06.2000',
    nationality: 'Italien',
    height: 1.79,
    foot: 'rechts',
    teamMemberSince: '12.08.2022',
    club: 'US Cremonese',
    previousClub: 'FC Crotone',
    uid: '2706ef77-7906-4cda-8685-839fb5320bc9',
    position: 'Torwart',
    league: 'Serie A',
    marketValue: 200000,
  },
  {
    jerseyNumber: 22,
    name: 'Dorian Ciezkowski',
    dateOfBirth: '22.03.2001',
    nationality: 'Polen',
    height: 1.92,
    foot: 'links',
    teamMemberSince: '30.07.2021',
    club: 'US Cremonese',
    previousClub: 'Taranto FC 1927',
    uid: '615ab58c-9ecf-4595-9f89-6fa86d9b43ff',
    position: 'Torwart',
    league: 'Serie A',
    marketValue: 100000,
  },
  {
    jerseyNumber: 5,
    name: 'Johan Vásquez',
    dateOfBirth: '22.10.1998',
    nationality: 'Mexiko',
    height: 1.84,
    foot: 'links',
    teamMemberSince: '01.07.2022',
    club: 'US Cremonese',
    previousClub: 'Genua CFC',
    uid: '674538fb-334c-422d-b400-7337cd021ad9',
    position: 'Innenverteidiger',
    league: 'Serie A',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 4,
    name: 'Emanuel Aiwu',
    dateOfBirth: '25.12.2000',
    nationality: 'Österreich',
    height: 1.85,
    foot: 'rechts',
    teamMemberSince: '05.08.2022',
    club: 'US Cremonese',
    previousClub: 'SK Rapid Wien',
    uid: '12cb625e-21f0-45ac-b6a5-89aee3406467',
    position: 'Innenverteidiger',
    league: 'Serie A',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 24,
    name: 'Alex Ferrari',
    dateOfBirth: '01.07.1994',
    nationality: 'Italien',
    height: 1.91,
    foot: 'rechts',
    teamMemberSince: '02.01.2023',
    club: 'US Cremonese',
    previousClub: 'Sampdoria Genua',
    uid: '6a696daa-8485-4443-8e1a-e4381f773892',
    position: 'Innenverteidiger',
    league: 'Serie A',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 44,
    name: 'Luka Lochoshvili',
    dateOfBirth: '29.05.1998',
    nationality: 'Georgien',
    height: 1.92,
    foot: 'links',
    teamMemberSince: '10.08.2022',
    club: 'US Cremonese',
    previousClub: 'Wolfsberger AC',
    uid: '80fb7a35-d61a-45bf-b655-6d85c7737758',
    position: 'Innenverteidiger',
    league: 'Serie A',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 21,
    name: 'Vlad Chiriches',
    dateOfBirth: '14.11.1989',
    nationality: 'Rumänien',
    height: 1.84,
    foot: 'rechts',
    teamMemberSince: '08.07.2022',
    club: 'US Cremonese',
    previousClub: 'US Sassuolo',
    uid: '69b3db19-2dfb-4308-adc0-a198fa12a67a',
    position: 'Innenverteidiger',
    league: 'Serie A',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 15,
    name: 'Matteo Bianchetti',
    dateOfBirth: '17.03.1993',
    nationality: 'Italien',
    height: 1.89,
    foot: 'rechts',
    teamMemberSince: '17.08.2019',
    club: 'US Cremonese',
    previousClub: ': Ablöse ablösefrei',
    uid: 'b2f2e76f-dcd9-4cbf-bfe5-520dfe196205',
    position: 'Innenverteidiger',
    league: 'Serie A',
    marketValue: 800000,
  },
  {
    jerseyNumber: 3,
    name: 'Emanuele Valeri',
    dateOfBirth: '07.12.1998',
    nationality: 'Italien',
    height: 1.8,
    foot: 'links',
    teamMemberSince: '10.09.2020',
    club: 'US Cremonese',
    previousClub: 'Cesena FC',
    uid: '3bde35c8-8dbb-4f11-bc14-15377f64b65b',
    position: 'Linker Verteidiger',
    league: 'Serie A',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 33,
    name: 'Giacomo Quagliata',
    dateOfBirth: '19.02.2000',
    nationality: 'Italien',
    height: 1.8,
    foot: 'links',
    teamMemberSince: '26.07.2022',
    club: 'US Cremonese',
    previousClub: 'Heracles Almelo',
    uid: '12f51d04-deb0-404e-b93e-b984118de176',
    position: 'Linker Verteidiger',
    league: 'Serie A',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 17,
    name: 'Leonardo Sernicola',
    dateOfBirth: '30.07.1997',
    nationality: 'Italien',
    height: 1.87,
    foot: 'rechts',
    teamMemberSince: '08.07.2022',
    club: 'US Cremonese',
    previousClub: 'US Sassuolo',
    uid: 'f988e1b2-1771-4c41-91c6-9cbaec36bab8',
    position: 'Rechter Verteidiger',
    league: 'Serie A',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 18,
    name: 'Paolo Ghiglione',
    dateOfBirth: '02.02.1997',
    nationality: 'Italien',
    height: 1.91,
    foot: 'rechts',
    teamMemberSince: '17.07.2022',
    club: 'US Cremonese',
    previousClub: 'Genua CFC',
    uid: 'b22f14d9-3565-4a61-9e43-54025d0278e2',
    position: 'Rechter Verteidiger',
    league: 'Serie A',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 6,
    name: 'Charles Pickel',
    dateOfBirth: '15.05.1997',
    nationality: 'Schweiz',
    height: 1.87,
    foot: 'rechts',
    teamMemberSince: '22.07.2022',
    club: 'US Cremonese',
    previousClub: 'FC Famalicão',
    uid: '96d80bda-aeec-4f78-b0c5-5eddcc6b95c8',
    position: 'Defensives Mittelfeld',
    league: 'Serie A',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 27,
    name: 'Pablo Galdames',
    dateOfBirth: '30.12.1996',
    nationality: 'Chile',
    height: 1.76,
    foot: 'rechts',
    teamMemberSince: '31.01.2023',
    club: 'US Cremonese',
    previousClub: 'Genua CFC',
    uid: '8cb872a5-574c-4219-b86e-3b97d64af309',
    position: 'Defensives Mittelfeld',
    league: 'Serie A',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 19,
    name: 'Michele Castagnetti',
    dateOfBirth: '27.12.1989',
    nationality: 'Italien',
    height: 1.8,
    foot: 'links',
    teamMemberSince: '02.07.2018',
    club: 'US Cremonese',
    previousClub: 'SPAL',
    uid: 'bb6d9ca8-3429-4e70-8129-d02d4b4ee08b',
    position: 'Defensives Mittelfeld',
    league: 'Serie A',
    marketValue: 700000,
  },
  {
    jerseyNumber: 28,
    name: 'Soualiho Meïté',
    dateOfBirth: '17.03.1994',
    nationality: 'Frankreich',
    height: 1.87,
    foot: 'rechts',
    teamMemberSince: '01.09.2022',
    club: 'US Cremonese',
    previousClub: 'Benfica Lissabon',
    uid: 'd7790de9-2c43-4f69-a0d2-f435b247c98d',
    position: 'Zentrales Mittelfeld',
    league: 'Serie A',
    marketValue: 4000000,
  },
  {
    jerseyNumber: 26,
    name: 'Marco Benassi',
    dateOfBirth: '08.09.1994',
    nationality: 'Italien',
    height: 1.84,
    foot: 'rechts',
    teamMemberSince: '19.01.2023',
    club: 'US Cremonese',
    previousClub: 'AC Florenz',
    uid: '84372132-6608-4dda-966b-26daf025e46c',
    position: 'Zentrales Mittelfeld',
    league: 'Serie A',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 10,
    name: 'Cristian Buonaiuto',
    dateOfBirth: '29.12.1992',
    nationality: 'Italien',
    height: 1.79,
    foot: 'rechts',
    teamMemberSince: '04.09.2020',
    club: 'US Cremonese',
    previousClub: 'AC Perugia Calcio',
    uid: '70cbbfc7-8255-405c-9c87-cb89942fedd9',
    position: 'Linksaußen',
    league: 'Serie A',
    marketValue: 800000,
  },
  {
    jerseyNumber: 90,
    name: 'Cyriel Dessers',
    dateOfBirth: '08.12.1994',
    nationality: 'Nigeria',
    height: 1.85,
    foot: 'rechts',
    teamMemberSince: '10.08.2022',
    club: 'US Cremonese',
    previousClub: 'KRC Genk',
    uid: '5442ba83-d45c-4192-a9fb-573ed1c08e0b',
    position: 'Mittelstürmer',
    league: 'Serie A',
    marketValue: 6000000,
  },
  {
    jerseyNumber: 20,
    name: 'Felix Afena-Gyan',
    dateOfBirth: '19.01.2003',
    nationality: 'Ghana',
    height: 1.75,
    foot: 'rechts',
    teamMemberSince: '29.08.2022',
    club: 'US Cremonese',
    previousClub: 'AS Rom',
    uid: '7d3c1d12-e918-4159-9471-d5e00015759e',
    position: 'Mittelstürmer',
    league: 'Serie A',
    marketValue: 5000000,
  },
  {
    jerseyNumber: 77,
    name: 'David Okereke',
    dateOfBirth: '29.08.1997',
    nationality: 'Nigeria',
    height: 1.81,
    foot: 'rechts',
    teamMemberSince: '28.07.2022',
    club: 'US Cremonese',
    previousClub: 'FC Brügge',
    uid: 'c7702305-274a-4e92-ae0c-0bdf11252132',
    position: 'Mittelstürmer',
    league: 'Serie A',
    marketValue: 4000000,
  },
  {
    jerseyNumber: 74,
    name: 'Frank Tsadjout',
    dateOfBirth: '28.07.1999',
    nationality: 'Italien',
    height: 1.9,
    foot: 'links',
    teamMemberSince: '15.07.2022',
    club: 'US Cremonese',
    previousClub: 'AC Mailand',
    uid: 'c43d5f23-793d-4c15-936e-6020790c1c3f',
    position: 'Mittelstürmer',
    league: 'Serie A',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 9,
    name: 'Daniel Ciofani',
    dateOfBirth: '31.07.1985',
    nationality: 'Italien',
    height: 1.91,
    foot: 'rechts',
    teamMemberSince: '09.08.2019',
    club: 'US Cremonese',
    previousClub: 'Frosinone Calcio',
    uid: 'b7664b50-3204-44f7-a05e-e5ab247e27b5',
    position: 'Mittelstürmer',
    league: 'Serie A',
    marketValue: 200000,
  },
  {
    jerseyNumber: 1,
    name: 'Thibaut Courtois',
    dateOfBirth: '11.05.1992',
    nationality: 'Belgien',
    height: 2,
    foot: 'links',
    teamMemberSince: '09.08.2018',
    club: 'Real Madrid',
    previousClub: 'FC Chelsea',
    uid: '3d0cf104-908b-4fe0-9cd8-22811eb10f3f',
    position: 'Torwart',
    league: 'LaLiga',
    marketValue: 60000000,
  },
  {
    jerseyNumber: 13,
    name: 'Andriy Lunin',
    dateOfBirth: '11.02.1999',
    nationality: 'Ukraine',
    height: 1.91,
    foot: 'rechts',
    teamMemberSince: '01.07.2018',
    club: 'Real Madrid',
    previousClub: ': Ablöse 8,50 Mio. €',
    uid: '2f301be4-e491-4286-bbe3-814675532714',
    position: 'Torwart',
    league: 'LaLiga',
    marketValue: 5000000,
  },
  {
    jerseyNumber: 3,
    name: 'Éder Militão',
    dateOfBirth: '18.01.1998',
    nationality: 'Brasilien',
    height: 1.86,
    foot: 'rechts',
    teamMemberSince: '01.07.2019',
    club: 'Real Madrid',
    previousClub: 'FC Porto',
    uid: '78f65167-28e8-423f-9ec2-447e519df9f2',
    position: 'Innenverteidiger',
    league: 'LaLiga',
    marketValue: 70000000,
  },
  {
    jerseyNumber: 4,
    name: 'David Alaba',
    dateOfBirth: '24.06.1992',
    nationality: 'Österreich',
    height: 1.8,
    foot: 'links',
    teamMemberSince: '01.07.2021',
    club: 'Real Madrid',
    previousClub: 'FC Bayern München',
    uid: '464805b2-c827-4a61-ac2c-5bbf41274a17',
    position: 'Innenverteidiger',
    league: 'LaLiga',
    marketValue: 50000000,
  },
  {
    jerseyNumber: 22,
    name: 'Antonio Rüdiger',
    dateOfBirth: '03.03.1993',
    nationality: 'Deutschland',
    height: 1.9,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'Real Madrid',
    previousClub: 'FC Chelsea',
    uid: '57e7c1e0-93b3-49bf-b93f-5a4e6460be15',
    position: 'Innenverteidiger',
    league: 'LaLiga',
    marketValue: 40000000,
  },
  {
    jerseyNumber: 6,
    name: 'Nacho Fernández',
    dateOfBirth: '18.01.1990',
    nationality: 'Spanien',
    height: 1.8,
    foot: 'rechts',
    teamMemberSince: '01.09.2012',
    club: 'Real Madrid',
    previousClub: 'Real Madrid Castilla',
    uid: '34e3e58b-7ce2-48d0-a905-0004a56e5bf0',
    position: 'Innenverteidiger',
    league: 'LaLiga',
    marketValue: 6000000,
  },
  {
    jerseyNumber: 5,
    name: 'Jesús Vallejo',
    dateOfBirth: '05.01.1997',
    nationality: 'Spanien',
    height: 1.84,
    foot: 'rechts',
    teamMemberSince: '31.07.2015',
    club: 'Real Madrid',
    previousClub: 'Real Saragossa',
    uid: '401e9397-58b4-4854-9cb9-4d65518e09c9',
    position: 'Innenverteidiger',
    league: 'LaLiga',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 23,
    name: 'Ferland Mendy',
    dateOfBirth: '08.06.1995',
    nationality: 'Frankreich',
    height: 1.8,
    foot: 'links',
    teamMemberSince: '01.07.2019',
    club: 'Real Madrid',
    previousClub: ': Ablöse 48,00 Mio. €',
    uid: '88ee2aba-e6a9-4225-a599-e4d005658a6d',
    position: 'Linker Verteidiger',
    league: 'LaLiga',
    marketValue: 35000000,
  },
  {
    jerseyNumber: 2,
    name: 'Daniel Carvajal',
    dateOfBirth: '11.01.1992',
    nationality: 'Spanien',
    height: 1.73,
    foot: 'rechts',
    teamMemberSince: '01.07.2013',
    club: 'Real Madrid',
    previousClub: 'Bayer 04 Leverkusen',
    uid: '46793d4a-f137-45b2-ae71-591367d98d71',
    position: 'Rechter Verteidiger',
    league: 'LaLiga',
    marketValue: 15000000,
  },
  {
    jerseyNumber: 17,
    name: 'Lucas Vázquez',
    dateOfBirth: '01.07.1991',
    nationality: 'Spanien',
    height: 1.73,
    foot: 'rechts',
    teamMemberSince: '02.07.2015',
    club: 'Real Madrid',
    previousClub: 'Espanyol Barcelona',
    uid: 'e4605675-7e76-4f4e-81dd-31518cac710a',
    position: 'Rechter Verteidiger',
    league: 'LaLiga',
    marketValue: 9000000,
  },
  {
    jerseyNumber: 16,
    name: 'Álvaro Odriozola',
    dateOfBirth: '14.12.1995',
    nationality: 'Spanien',
    height: 1.76,
    foot: 'rechts',
    teamMemberSince: '05.07.2018',
    club: 'Real Madrid',
    previousClub: 'Real Sociedad San Sebastián',
    uid: '4c90a495-47be-4456-9da1-b0e3cf950b9c',
    position: 'Rechter Verteidiger',
    league: 'LaLiga',
    marketValue: 5000000,
  },
  {
    jerseyNumber: 18,
    name: 'Aurélien Tchouaméni',
    dateOfBirth: '27.01.2000',
    nationality: 'Frankreich',
    height: 1.88,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'Real Madrid',
    previousClub: 'AS Monaco',
    uid: 'd6b9c197-c96e-4afc-96e9-8f62ad747aa1',
    position: 'Defensives Mittelfeld',
    league: 'LaLiga',
    marketValue: 90000000,
  },
  {
    jerseyNumber: 15,
    name: 'Federico Valverde',
    dateOfBirth: '22.07.1998',
    nationality: 'Uruguay',
    height: 1.82,
    foot: 'rechts',
    teamMemberSince: '01.07.2018',
    club: 'Real Madrid',
    previousClub: 'Real Madrid Castilla',
    uid: '8c110405-a6c4-4852-abca-182051f914b4',
    position: 'Zentrales Mittelfeld',
    league: 'LaLiga',
    marketValue: 100000000,
  },
  {
    jerseyNumber: 12,
    name: 'Eduardo Camavinga',
    dateOfBirth: '10.11.2002',
    nationality: 'Frankreich',
    height: 1.82,
    foot: 'links',
    teamMemberSince: '31.08.2021',
    club: 'Real Madrid',
    previousClub: 'FC Stade Rennes',
    uid: '9a744a5f-5bfe-497f-9472-f2b52c9a81cc',
    position: 'Zentrales Mittelfeld',
    league: 'LaLiga',
    marketValue: 60000000,
  },
  {
    jerseyNumber: 8,
    name: 'Toni Kroos',
    dateOfBirth: '04.01.1990',
    nationality: 'Deutschland',
    height: 1.83,
    foot: 'rechts',
    teamMemberSince: '17.07.2014',
    club: 'Real Madrid',
    previousClub: ': Ablöse 25,00 Mio. €',
    uid: '020f9d90-3e7a-4d44-b6f5-c5c26e539865',
    position: 'Zentrales Mittelfeld',
    league: 'LaLiga',
    marketValue: 18000000,
  },
  {
    jerseyNumber: 19,
    name: 'Dani Ceballos',
    dateOfBirth: '07.08.1996',
    nationality: 'Spanien',
    height: 1.79,
    foot: 'rechts',
    teamMemberSince: '14.07.2017',
    club: 'Real Madrid',
    previousClub: ': Ablöse 16,50 Mio. €',
    uid: '6cc64cc8-a9de-4397-90ad-3a87ba0a4837',
    position: 'Zentrales Mittelfeld',
    league: 'LaLiga',
    marketValue: 12000000,
  },
  {
    jerseyNumber: 10,
    name: 'Luka Modric',
    dateOfBirth: '09.09.1985',
    nationality: 'Kroatien',
    height: 1.72,
    foot: 'rechts',
    teamMemberSince: '27.08.2012',
    club: 'Real Madrid',
    previousClub: 'Tottenham Hotspur',
    uid: '97864678-9953-42b8-b82b-1b9be847c34f',
    position: 'Zentrales Mittelfeld',
    league: 'LaLiga',
    marketValue: 10000000,
  },
  {
    jerseyNumber: 20,
    name: 'Vinicius Junior',
    dateOfBirth: '12.07.2000',
    nationality: 'Brasilien',
    height: 1.76,
    foot: 'rechts',
    teamMemberSince: '12.07.2018',
    club: 'Real Madrid',
    previousClub: 'Flamengo Rio de Janeiro',
    uid: 'a2755a1e-2079-4ef2-ba7d-ec006f4736d8',
    position: 'Linksaußen',
    league: 'LaLiga',
    marketValue: 120000000,
  },
  {
    jerseyNumber: 7,
    name: 'Eden Hazard',
    dateOfBirth: '07.01.1991',
    nationality: 'Belgien',
    height: 1.75,
    foot: 'rechts',
    teamMemberSince: '01.07.2019',
    club: 'Real Madrid',
    previousClub: 'FC Chelsea',
    uid: '6cf43792-71c3-4bce-b0c7-dca23ac4c192',
    position: 'Linksaußen',
    league: 'LaLiga',
    marketValue: 5000000,
  },
  {
    jerseyNumber: 21,
    name: 'Rodrygo',
    dateOfBirth: '09.01.2001',
    nationality: 'Brasilien',
    height: 1.74,
    foot: 'rechts',
    teamMemberSince: '01.07.2019',
    club: 'Real Madrid',
    previousClub: 'FC Santos',
    uid: 'fedd7db8-d70b-48e6-b7e1-0ebcd55ab493',
    position: 'Rechtsaußen',
    league: 'LaLiga',
    marketValue: 80000000,
  },
  {
    jerseyNumber: 11,
    name: 'Marco Asensio',
    dateOfBirth: '21.01.1996',
    nationality: 'Spanien',
    height: 1.82,
    foot: 'links',
    teamMemberSince: '01.07.2015',
    club: 'Real Madrid',
    previousClub: 'RCD Mallorca',
    uid: '119d2a1b-1ba0-4502-b8c8-b4cbddc8157d',
    position: 'Rechtsaußen',
    league: 'LaLiga',
    marketValue: 25000000,
  },
  {
    jerseyNumber: 9,
    name: 'Karim Benzema',
    dateOfBirth: '19.12.1987',
    nationality: 'Frankreich',
    height: 1.85,
    foot: 'rechts',
    teamMemberSince: '09.07.2009',
    club: 'Real Madrid',
    previousClub: ': Ablöse 35,00 Mio. €',
    uid: 'b9d08015-5830-4574-ab44-4453779c3490',
    position: 'Mittelstürmer',
    league: 'LaLiga',
    marketValue: 35000000,
  },
  {
    jerseyNumber: 24,
    name: 'Mariano Díaz',
    dateOfBirth: '01.08.1993',
    nationality: 'Dominikanische Republik',
    height: 1.8,
    foot: 'rechts',
    teamMemberSince: '29.08.2018',
    club: 'Real Madrid',
    previousClub: ': Ablöse 21,50 Mio. €',
    uid: '25cc79c8-a748-4e0a-a7bf-ab7863730325',
    position: 'Mittelstürmer',
    league: 'LaLiga',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 1,
    name: 'Marc-André ter Stegen',
    dateOfBirth: '30.04.1992',
    nationality: 'Deutschland',
    height: 1.87,
    foot: 'rechts',
    teamMemberSince: '01.07.2014',
    club: 'FC Barcelona',
    previousClub: 'Borussia Mönchengladbach',
    uid: '2c526e4d-c23c-43a0-bb6c-a6e8a83e8c25',
    position: 'Torwart',
    league: 'LaLiga',
    marketValue: 40000000,
  },
  {
    jerseyNumber: 13,
    name: 'Iñaki Peña',
    dateOfBirth: '02.03.1999',
    nationality: 'Spanien',
    height: 1.84,
    foot: 'rechts',
    teamMemberSince: '30.01.2022',
    club: 'FC Barcelona',
    previousClub: 'FC Barcelona B',
    uid: 'd921f0ac-c7ca-448a-bda2-11bff88d906b',
    position: 'Torwart',
    league: 'LaLiga',
    marketValue: 4000000,
  },
  {
    jerseyNumber: 4,
    name: 'Ronald Araújo',
    dateOfBirth: '07.03.1999',
    nationality: 'Uruguay',
    height: 1.88,
    foot: 'rechts',
    teamMemberSince: '05.10.2020',
    club: 'FC Barcelona',
    previousClub: 'FC Barcelona B',
    uid: '730d42d7-51a0-48bb-b361-9856e67b09b0',
    position: 'Innenverteidiger',
    league: 'LaLiga',
    marketValue: 70000000,
  },
  {
    jerseyNumber: 23,
    name: 'Jules Koundé',
    dateOfBirth: '12.11.1998',
    nationality: 'Frankreich',
    height: 1.8,
    foot: 'rechts',
    teamMemberSince: '28.07.2022',
    club: 'FC Barcelona',
    previousClub: 'FC Sevilla',
    uid: 'f2cff9e0-7039-4b21-ba16-dd05bb9686c5',
    position: 'Innenverteidiger',
    league: 'LaLiga',
    marketValue: 60000000,
  },
  {
    jerseyNumber: 15,
    name: 'Andreas Christensen',
    dateOfBirth: '10.04.1996',
    nationality: 'Dänemark',
    height: 1.87,
    foot: 'rechts',
    teamMemberSince: '04.07.2022',
    club: 'FC Barcelona',
    previousClub: 'FC Chelsea',
    uid: 'c8fecf31-1497-4411-b185-9db455af4e4d',
    position: 'Innenverteidiger',
    league: 'LaLiga',
    marketValue: 30000000,
  },
  {
    jerseyNumber: 24,
    name: 'Eric García',
    dateOfBirth: '09.01.2001',
    nationality: 'Spanien',
    height: 1.82,
    foot: 'rechts',
    teamMemberSince: '01.07.2021',
    club: 'FC Barcelona',
    previousClub: 'Manchester City',
    uid: '36fa1fee-1e00-4734-8e5a-e610f25a1477',
    position: 'Innenverteidiger',
    league: 'LaLiga',
    marketValue: 12000000,
  },
  {
    jerseyNumber: 28,
    name: 'Alejandro Balde',
    dateOfBirth: '18.10.2003',
    nationality: 'Spanien',
    height: 1.75,
    foot: 'links',
    teamMemberSince: '01.07.2022',
    club: 'FC Barcelona',
    previousClub: 'FC Barcelona Atlètic',
    uid: 'c6e8530d-97ef-45f4-9a1f-b3d689a8a933',
    position: 'Linker Verteidiger',
    league: 'LaLiga',
    marketValue: 30000000,
  },
  {
    jerseyNumber: 17,
    name: 'Marcos Alonso',
    dateOfBirth: '28.12.1990',
    nationality: 'Spanien',
    height: 1.88,
    foot: 'links',
    teamMemberSince: '01.09.2022',
    club: 'FC Barcelona',
    previousClub: 'FC Chelsea',
    uid: '11dbd04f-4905-42f0-a016-78c3a25f0d2c',
    position: 'Linker Verteidiger',
    league: 'LaLiga',
    marketValue: 7000000,
  },
  {
    jerseyNumber: 18,
    name: 'Jordi Alba',
    dateOfBirth: '21.03.1989',
    nationality: 'Spanien',
    height: 1.7,
    foot: 'links',
    teamMemberSince: '01.07.2012',
    club: 'FC Barcelona',
    previousClub: 'FC Valencia',
    uid: 'b87274b3-7f79-41cd-beca-e88069bc7697',
    position: 'Linker Verteidiger',
    league: 'LaLiga',
    marketValue: 5000000,
  },
  {
    jerseyNumber: 20,
    name: 'Sergi Roberto',
    dateOfBirth: '07.02.1992',
    nationality: 'Spanien',
    height: 1.78,
    foot: 'rechts',
    teamMemberSince: '01.07.2013',
    club: 'FC Barcelona',
    previousClub: 'FC Barcelona B',
    uid: '7fcda793-21eb-491e-9eb4-070f2c989655',
    position: 'Rechter Verteidiger',
    league: 'LaLiga',
    marketValue: 6000000,
  },
  {
    jerseyNumber: 5,
    name: 'Sergio Busquets',
    dateOfBirth: '16.07.1988',
    nationality: 'Spanien',
    height: 1.89,
    foot: 'rechts',
    teamMemberSince: '01.07.2008',
    club: 'FC Barcelona',
    previousClub: 'FC Barcelona Atlètic',
    uid: '21aff5da-4ca8-4344-959d-6b144e94016e',
    position: 'Defensives Mittelfeld',
    league: 'LaLiga',
    marketValue: 5000000,
  },
  {
    jerseyNumber: 8,
    name: 'Pedri',
    dateOfBirth: '25.11.2002',
    nationality: 'Spanien',
    height: 1.74,
    foot: 'rechts',
    teamMemberSince: '02.09.2019',
    club: 'FC Barcelona',
    previousClub: 'UD Las Palmas',
    uid: '7eb6e2e7-fed8-45ba-80ed-b7f7cce27c4b',
    position: 'Zentrales Mittelfeld',
    league: 'LaLiga',
    marketValue: 100000000,
  },
  {
    jerseyNumber: 30,
    name: 'Gavi',
    dateOfBirth: '05.08.2004',
    nationality: 'Spanien',
    height: 1.73,
    foot: 'rechts',
    teamMemberSince: '01.07.2021',
    club: 'FC Barcelona',
    previousClub: 'FC Barcelona U19',
    uid: 'fc304a4f-44a4-4e05-85e0-315202b276b6',
    position: 'Zentrales Mittelfeld',
    league: 'LaLiga',
    marketValue: 90000000,
  },
  {
    jerseyNumber: 21,
    name: 'Frenkie de Jong',
    dateOfBirth: '12.05.1997',
    nationality: 'Niederlande',
    height: 1.81,
    foot: 'rechts',
    teamMemberSince: '01.07.2019',
    club: 'FC Barcelona',
    previousClub: 'Ajax Amsterdam',
    uid: 'd59a7917-5ec6-4e42-b104-ae344efc460b',
    position: 'Zentrales Mittelfeld',
    league: 'LaLiga',
    marketValue: 60000000,
  },
  {
    jerseyNumber: 19,
    name: 'Franck Kessié',
    dateOfBirth: '19.12.1996',
    nationality: 'Elfenbeinküste',
    height: 1.83,
    foot: 'rechts',
    teamMemberSince: '04.07.2022',
    club: 'FC Barcelona',
    previousClub: 'AC Mailand',
    uid: 'd6536acf-9f76-4e4b-bd91-921a72c6d5a5',
    position: 'Zentrales Mittelfeld',
    league: 'LaLiga',
    marketValue: 28000000,
  },
  {
    jerseyNumber: 32,
    name: 'Pablo Torre',
    dateOfBirth: '03.04.2003',
    nationality: 'Spanien',
    height: 1.73,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'FC Barcelona',
    previousClub: 'Racing Santander',
    uid: 'd002acd0-d678-44e1-b100-7c5938a80490',
    position: 'Zentrales Mittelfeld',
    league: 'LaLiga',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 10,
    name: 'Ansu Fati',
    dateOfBirth: '31.10.2002',
    nationality: 'Spanien',
    height: 1.78,
    foot: 'rechts',
    teamMemberSince: '23.09.2020',
    club: 'FC Barcelona',
    previousClub: 'FC Barcelona B',
    uid: '11bba626-85ff-494d-bc96-792924d86d88',
    position: 'Linksaußen',
    league: 'LaLiga',
    marketValue: 35000000,
  },
  {
    jerseyNumber: 11,
    name: 'Ferran Torres',
    dateOfBirth: '29.02.2000',
    nationality: 'Spanien',
    height: 1.82,
    foot: 'rechts',
    teamMemberSince: '01.01.2022',
    club: 'FC Barcelona',
    previousClub: 'Manchester City',
    uid: '8a62f57f-0649-463d-8c6e-9e5d9f5c8498',
    position: 'Linksaußen',
    league: 'LaLiga',
    marketValue: 30000000,
  },
  {
    jerseyNumber: 7,
    name: 'Ousmane Dembélé',
    dateOfBirth: '15.05.1997',
    nationality: 'Frankreich',
    height: 1.78,
    foot: 'beidfüßig',
    teamMemberSince: '25.08.2017',
    club: 'FC Barcelona',
    previousClub: 'Borussia Dortmund',
    uid: '93954ab3-fb77-4b9b-a7a7-7ccfaabe7079',
    position: 'Rechtsaußen',
    league: 'LaLiga',
    marketValue: 60000000,
  },
  {
    jerseyNumber: 22,
    name: 'Raphinha',
    dateOfBirth: '14.12.1996',
    nationality: 'Brasilien',
    height: 1.76,
    foot: 'links',
    teamMemberSince: '13.07.2022',
    club: 'FC Barcelona',
    previousClub: 'Leeds United',
    uid: '97ae090a-4575-449f-b39d-b57dd189598f',
    position: 'Rechtsaußen',
    league: 'LaLiga',
    marketValue: 50000000,
  },
  {
    jerseyNumber: 9,
    name: 'Robert Lewandowski',
    dateOfBirth: '21.08.1988',
    nationality: 'Polen',
    height: 1.85,
    foot: 'rechts',
    teamMemberSince: '19.07.2022',
    club: 'FC Barcelona',
    previousClub: 'FC Bayern München',
    uid: '9e0fba7d-5164-4a55-a00b-1aeac492ce2e',
    position: 'Mittelstürmer',
    league: 'LaLiga',
    marketValue: 40000000,
  },
  {
    jerseyNumber: 13,
    name: 'Jan Oblak',
    dateOfBirth: '07.01.1993',
    nationality: 'Slowenien',
    height: 1.88,
    foot: 'rechts',
    teamMemberSince: '16.07.2014',
    club: 'Atlético Madrid',
    previousClub: 'Benfica Lissabon',
    uid: '060c0e62-93d4-4465-af14-507bc74e671e',
    position: 'Torwart',
    league: 'LaLiga',
    marketValue: 40000000,
  },
  {
    jerseyNumber: 1,
    name: 'Ivo Grbic',
    dateOfBirth: '18.01.1996',
    nationality: 'Kroatien',
    height: 1.95,
    foot: 'rechts',
    teamMemberSince: '20.08.2020',
    club: 'Atlético Madrid',
    previousClub: 'NK Lokomotiva Zagreb',
    uid: '7f632982-e781-4e5c-b328-a24e572bc9a0',
    position: 'Torwart',
    league: 'LaLiga',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 2,
    name: 'José María Giménez',
    dateOfBirth: '20.01.1995',
    nationality: 'Uruguay',
    height: 1.85,
    foot: 'rechts',
    teamMemberSince: '25.04.2013',
    club: 'Atlético Madrid',
    previousClub: ': Ablöse 995 Tsd. €',
    uid: 'ac657e62-0693-4e05-ac12-0cdf0f7ceb41',
    position: 'Innenverteidiger',
    league: 'LaLiga',
    marketValue: 35000000,
  },
  {
    jerseyNumber: 22,
    name: 'Mario Hermoso',
    dateOfBirth: '18.06.1995',
    nationality: 'Spanien',
    height: 1.84,
    foot: 'links',
    teamMemberSince: '18.07.2019',
    club: 'Atlético Madrid',
    previousClub: 'Espanyol Barcelona',
    uid: 'c1edc4cf-82ec-4538-a6b6-160dd33453c0',
    position: 'Innenverteidiger',
    league: 'LaLiga',
    marketValue: 18000000,
  },
  {
    jerseyNumber: 15,
    name: 'Stefan Savic',
    dateOfBirth: '08.01.1991',
    nationality: 'Montenegro',
    height: 1.87,
    foot: 'rechts',
    teamMemberSince: '20.07.2015',
    club: 'Atlético Madrid',
    previousClub: ': Ablöse 12,00 Mio. €',
    uid: 'b640cf82-8722-410f-9b2e-fc83f9d5f301',
    position: 'Innenverteidiger',
    league: 'LaLiga',
    marketValue: 7000000,
  },
  {
    jerseyNumber: 23,
    name: 'Reinildo Mandava',
    dateOfBirth: '21.01.1994',
    nationality: 'Mosambik',
    height: 1.8,
    foot: 'links',
    teamMemberSince: '31.01.2022',
    club: 'Atlético Madrid',
    previousClub: 'LOSC Lille',
    uid: '14dd171c-d6bd-4bbf-ab4c-829f798c8237',
    position: 'Linker Verteidiger',
    league: 'LaLiga',
    marketValue: 25000000,
  },
  {
    jerseyNumber: 3,
    name: 'Sergio Reguilón',
    dateOfBirth: '16.12.1996',
    nationality: 'Spanien',
    height: 1.78,
    foot: 'links',
    teamMemberSince: '30.08.2022',
    club: 'Atlético Madrid',
    previousClub: 'Tottenham Hotspur',
    uid: '43a4c1c1-120d-48a3-b7ef-fd5cefac29eb',
    position: 'Linker Verteidiger',
    league: 'LaLiga',
    marketValue: 15000000,
  },
  {
    jerseyNumber: 16,
    name: 'Nahuel Molina',
    dateOfBirth: '06.04.1998',
    nationality: 'Argentinien',
    height: 1.75,
    foot: 'rechts',
    teamMemberSince: '28.07.2022',
    club: 'Atlético Madrid',
    previousClub: 'Udinese Calcio',
    uid: 'db180b9e-aca9-4d6c-bece-a27023f28207',
    position: 'Rechter Verteidiger',
    league: 'LaLiga',
    marketValue: 24000000,
  },
  {
    jerseyNumber: 12,
    name: 'Matt Doherty',
    dateOfBirth: '16.01.1992',
    nationality: 'Irland',
    height: 1.85,
    foot: 'rechts',
    teamMemberSince: '31.01.2023',
    club: 'Atlético Madrid',
    previousClub: 'Tottenham Hotspur',
    uid: 'b9aebedc-2582-430e-b1f3-72035581df62',
    position: 'Rechter Verteidiger',
    league: 'LaLiga',
    marketValue: 9000000,
  },
  {
    jerseyNumber: 4,
    name: 'Geoffrey Kondogbia',
    dateOfBirth: '15.02.1993',
    nationality: 'Zentralafrikanische Republik',
    height: 1.88,
    foot: 'links',
    teamMemberSince: '03.11.2020',
    club: 'Atlético Madrid',
    previousClub: 'FC Valencia',
    uid: 'b26b7962-b15d-4e0e-8f5d-af9ab5625118',
    position: 'Defensives Mittelfeld',
    league: 'LaLiga',
    marketValue: 18000000,
  },
  {
    jerseyNumber: 20,
    name: 'Axel Witsel',
    dateOfBirth: '12.01.1989',
    nationality: 'Belgien',
    height: 1.86,
    foot: 'rechts',
    teamMemberSince: '06.07.2022',
    club: 'Atlético Madrid',
    previousClub: 'Borussia Dortmund',
    uid: 'd0d5875b-e198-4f01-9d10-189d65dc0cd7',
    position: 'Defensives Mittelfeld',
    league: 'LaLiga',
    marketValue: 5000000,
  },
  {
    jerseyNumber: 5,
    name: 'Rodrigo de Paul',
    dateOfBirth: '24.05.1994',
    nationality: 'Argentinien',
    height: 1.8,
    foot: 'rechts',
    teamMemberSince: '12.07.2021',
    club: 'Atlético Madrid',
    previousClub: 'Udinese Calcio',
    uid: '932709f3-c877-416b-9d1d-ddf8ad2a10d3',
    position: 'Zentrales Mittelfeld',
    league: 'LaLiga',
    marketValue: 40000000,
  },
  {
    jerseyNumber: 14,
    name: 'Marcos Llorente',
    dateOfBirth: '30.01.1995',
    nationality: 'Spanien',
    height: 1.84,
    foot: 'rechts',
    teamMemberSince: '01.07.2019',
    club: 'Atlético Madrid',
    previousClub: 'Real Madrid',
    uid: '412f7343-152c-4449-a1b0-a558e6bbe5df',
    position: 'Zentrales Mittelfeld',
    league: 'LaLiga',
    marketValue: 35000000,
  },
  {
    jerseyNumber: 6,
    name: 'Koke',
    dateOfBirth: '08.01.1992',
    nationality: 'Spanien',
    height: 1.76,
    foot: 'rechts',
    teamMemberSince: '01.01.2011',
    club: 'Atlético Madrid',
    previousClub: ': Ablöse -',
    uid: 'ecccbd33-225e-4981-8ce9-7e74464731fc',
    position: 'Zentrales Mittelfeld',
    league: 'LaLiga',
    marketValue: 16000000,
  },
  {
    jerseyNumber: 17,
    name: 'Saúl Ñíguez',
    dateOfBirth: '21.11.1994',
    nationality: 'Spanien',
    height: 1.84,
    foot: 'links',
    teamMemberSince: '01.07.2013',
    club: 'Atlético Madrid',
    previousClub: ': Ablöse -',
    uid: 'bdfe1222-6913-46dc-943c-0d55abe3dc8b',
    position: 'Zentrales Mittelfeld',
    league: 'LaLiga',
    marketValue: 10000000,
  },
  {
    jerseyNumber: 24,
    name: 'Pablo Barrios',
    dateOfBirth: '15.06.2003',
    nationality: 'Spanien',
    height: 1.81,
    foot: 'rechts',
    teamMemberSince: '23.01.2023',
    club: 'Atlético Madrid',
    previousClub: 'Atlético Madrid B',
    uid: '2fc5c9e2-bd28-4b6c-8d20-64cecad87955',
    position: 'Zentrales Mittelfeld',
    league: 'LaLiga',
    marketValue: 5000000,
  },
  {
    jerseyNumber: 11,
    name: 'Thomas Lemar',
    dateOfBirth: '12.11.1995',
    nationality: 'Frankreich',
    height: 1.71,
    foot: 'links',
    teamMemberSince: '01.07.2018',
    club: 'Atlético Madrid',
    previousClub: 'AS Monaco',
    uid: '1ac9378a-8c6a-422d-b0a8-fa18bd0c22c0',
    position: 'Offensives Mittelfeld',
    league: 'LaLiga',
    marketValue: 25000000,
  },
  {
    jerseyNumber: 21,
    name: 'Yannick Carrasco',
    dateOfBirth: '04.09.1993',
    nationality: 'Belgien',
    height: 1.81,
    foot: 'rechts',
    teamMemberSince: '08.09.2020',
    club: 'Atlético Madrid',
    previousClub: 'Dalian Professional',
    uid: 'd03ecebb-e11f-40dd-86d1-29c0d9584b6b',
    position: 'Linksaußen',
    league: 'LaLiga',
    marketValue: 25000000,
  },
  {
    jerseyNumber: 10,
    name: 'Ángel Correa',
    dateOfBirth: '09.03.1995',
    nationality: 'Argentinien',
    height: 1.71,
    foot: 'rechts',
    teamMemberSince: '01.01.2015',
    club: 'Atlético Madrid',
    previousClub: 'CA San Lorenzo de Almagro',
    uid: '3b4f0eca-af3a-4ccb-8b0e-b259d05eef27',
    position: 'Rechtsaußen',
    league: 'LaLiga',
    marketValue: 35000000,
  },
  {
    jerseyNumber: 8,
    name: 'Antoine Griezmann',
    dateOfBirth: '21.03.1991',
    nationality: 'Frankreich',
    height: 1.76,
    foot: 'links',
    teamMemberSince: '31.08.2021',
    club: 'Atlético Madrid',
    previousClub: 'FC Barcelona',
    uid: '7d85a990-6510-4d70-9e72-dbad8e7a29f2',
    position: 'Hängende Spitze',
    league: 'LaLiga',
    marketValue: 25000000,
  },
  {
    jerseyNumber: 19,
    name: 'Álvaro Morata',
    dateOfBirth: '23.10.1992',
    nationality: 'Spanien',
    height: 1.89,
    foot: 'rechts',
    teamMemberSince: '01.07.2020',
    club: 'Atlético Madrid',
    previousClub: 'FC Chelsea',
    uid: '8084b879-6154-46f3-aea3-59e6d6a4611c',
    position: 'Mittelstürmer',
    league: 'LaLiga',
    marketValue: 25000000,
  },
  {
    jerseyNumber: 9,
    name: 'Memphis Depay',
    dateOfBirth: '13.02.1994',
    nationality: 'Niederlande',
    height: 1.78,
    foot: 'rechts',
    teamMemberSince: '20.01.2023',
    club: 'Atlético Madrid',
    previousClub: 'FC Barcelona',
    uid: '48bc98eb-6e18-4639-a80f-a56f27504412',
    position: 'Mittelstürmer',
    league: 'LaLiga',
    marketValue: 16000000,
  },
  {
    jerseyNumber: 1,
    name: 'Álex Remiro',
    dateOfBirth: '24.03.1995',
    nationality: 'Spanien',
    height: 1.91,
    foot: 'rechts',
    teamMemberSince: '01.07.2019',
    club: 'Real Sociedad San Sebastián',
    previousClub: 'Athletic Bilbao',
    uid: '8d7c9f65-5964-4ad8-ab4d-687b58967c65',
    position: 'Torwart',
    league: 'LaLiga',
    marketValue: 25000000,
  },
  {
    jerseyNumber: 13,
    name: 'Andoni Zubiaurre',
    dateOfBirth: '04.12.1996',
    nationality: 'Spanien',
    height: 1.89,
    foot: 'links',
    teamMemberSince: '01.07.2022',
    club: 'Real Sociedad San Sebastián',
    previousClub: 'Real Sociedad B',
    uid: 'c3927afd-6df8-4fc6-8201-21635e9019ab',
    position: 'Torwart',
    league: 'LaLiga',
    marketValue: 300000,
  },
  {
    jerseyNumber: 24,
    name: 'Robin Le Normand',
    dateOfBirth: '11.11.1996',
    nationality: 'Frankreich',
    height: 1.87,
    foot: 'rechts',
    teamMemberSince: '01.07.2019',
    club: 'Real Sociedad San Sebastián',
    previousClub: 'Real Sociedad B',
    uid: 'c67ee089-43b5-4528-9003-dd7db02872e4',
    position: 'Innenverteidiger',
    league: 'LaLiga',
    marketValue: 35000000,
  },
  {
    jerseyNumber: 5,
    name: 'Igor Zubeldia',
    dateOfBirth: '30.03.1997',
    nationality: 'Spanien',
    height: 1.81,
    foot: 'rechts',
    teamMemberSince: '01.07.2017',
    club: 'Real Sociedad San Sebastián',
    previousClub: 'Real Sociedad B',
    uid: 'db1d586e-3ee9-49c4-aeb5-271ffea08251',
    position: 'Innenverteidiger',
    league: 'LaLiga',
    marketValue: 15000000,
  },
  {
    jerseyNumber: 6,
    name: 'Aritz Elustondo',
    dateOfBirth: '28.03.1994',
    nationality: 'Spanien',
    height: 1.82,
    foot: 'rechts',
    teamMemberSince: '04.01.2016',
    club: 'Real Sociedad San Sebastián',
    previousClub: 'Real Sociedad B',
    uid: '06fe5fad-c2b4-4a94-ada6-56cc5e11a8dc',
    position: 'Innenverteidiger',
    league: 'LaLiga',
    marketValue: 14000000,
  },
  {
    jerseyNumber: 20,
    name: 'Jon Pacheco',
    dateOfBirth: '08.01.2001',
    nationality: 'Spanien',
    height: 1.84,
    foot: 'links',
    teamMemberSince: '01.07.2022',
    club: 'Real Sociedad San Sebastián',
    previousClub: 'Real Sociedad B',
    uid: 'd91e76b3-446d-4d21-95e8-ef0278aef3ef',
    position: 'Innenverteidiger',
    league: 'LaLiga',
    marketValue: 6000000,
  },
  {
    jerseyNumber: 12,
    name: 'Aihen Muñoz',
    dateOfBirth: '16.08.1997',
    nationality: 'Spanien',
    height: 1.75,
    foot: 'links',
    teamMemberSince: '01.07.2019',
    club: 'Real Sociedad San Sebastián',
    previousClub: 'Real Sociedad B',
    uid: '559c9c3d-a7c5-406f-a4ef-a11d11b28e34',
    position: 'Linker Verteidiger',
    league: 'LaLiga',
    marketValue: 6000000,
  },
  {
    jerseyNumber: 15,
    name: 'Diego Rico',
    dateOfBirth: '23.02.1993',
    nationality: 'Spanien',
    height: 1.83,
    foot: 'links',
    teamMemberSince: '26.07.2021',
    club: 'Real Sociedad San Sebastián',
    previousClub: 'AFC Bournemouth',
    uid: 'db71152e-2641-4460-b0ef-7b272c1813d6',
    position: 'Linker Verteidiger',
    league: 'LaLiga',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 18,
    name: 'Andoni Gorosabel',
    dateOfBirth: '04.08.1996',
    nationality: 'Spanien',
    height: 1.74,
    foot: 'rechts',
    teamMemberSince: '01.07.2018',
    club: 'Real Sociedad San Sebastián',
    previousClub: 'Real Sociedad B',
    uid: 'ff76505f-e3ac-4d2b-a68c-fb5910bb0e84',
    position: 'Rechter Verteidiger',
    league: 'LaLiga',
    marketValue: 10000000,
  },
  {
    jerseyNumber: 2,
    name: 'Álex Sola',
    dateOfBirth: '09.06.1999',
    nationality: 'Spanien',
    height: 1.78,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'Real Sociedad San Sebastián',
    previousClub: 'Real Sociedad B',
    uid: '13f350a9-06db-46de-b4fb-046b81acea39',
    position: 'Rechter Verteidiger',
    league: 'LaLiga',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 3,
    name: 'Martín Zubimendi',
    dateOfBirth: '02.02.1999',
    nationality: 'Spanien',
    height: 1.81,
    foot: 'rechts',
    teamMemberSince: '10.09.2020',
    club: 'Real Sociedad San Sebastián',
    previousClub: 'Real Sociedad B',
    uid: '696d8709-bd16-4477-b3c6-73d6ecad5ad6',
    position: 'Defensives Mittelfeld',
    league: 'LaLiga',
    marketValue: 40000000,
  },
  {
    jerseyNumber: 4,
    name: 'Asier Illarramendi',
    dateOfBirth: '08.03.1990',
    nationality: 'Spanien',
    height: 1.79,
    foot: 'rechts',
    teamMemberSince: '26.08.2015',
    club: 'Real Sociedad San Sebastián',
    previousClub: 'Real Madrid',
    uid: '74fd69a0-03c9-4af5-9eec-ca034e366c26',
    position: 'Defensives Mittelfeld',
    league: 'LaLiga',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 8,
    name: 'Mikel Merino',
    dateOfBirth: '22.06.1996',
    nationality: 'Spanien',
    height: 1.89,
    foot: 'links',
    teamMemberSince: '12.07.2018',
    club: 'Real Sociedad San Sebastián',
    previousClub: 'Newcastle United',
    uid: '76cda3e0-288c-4ee4-be1a-45a625a4359e',
    position: 'Zentrales Mittelfeld',
    league: 'LaLiga',
    marketValue: 50000000,
  },
  {
    jerseyNumber: 16,
    name: 'Ander Guevara',
    dateOfBirth: '07.07.1997',
    nationality: 'Spanien',
    height: 1.8,
    foot: 'rechts',
    teamMemberSince: '01.07.2019',
    club: 'Real Sociedad San Sebastián',
    previousClub: 'Real Sociedad B',
    uid: 'daaf943a-aaf0-4224-8ddb-a8af6bbb76cf',
    position: 'Zentrales Mittelfeld',
    league: 'LaLiga',
    marketValue: 5000000,
  },
  {
    jerseyNumber: 22,
    name: 'Beñat Turrientes',
    dateOfBirth: '31.01.2002',
    nationality: 'Spanien',
    height: 1.81,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'Real Sociedad San Sebastián',
    previousClub: 'Real Sociedad B',
    uid: '21749333-4b1f-4fcb-b37b-596a967443df',
    position: 'Zentrales Mittelfeld',
    league: 'LaLiga',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 23,
    name: 'Brais Méndez',
    dateOfBirth: '07.01.1997',
    nationality: 'Spanien',
    height: 1.84,
    foot: 'links',
    teamMemberSince: '06.07.2022',
    club: 'Real Sociedad San Sebastián',
    previousClub: 'Celta Vigo',
    uid: '5ff95e5f-6f98-4f9b-ad08-ab4545044a01',
    position: 'Offensives Mittelfeld',
    league: 'LaLiga',
    marketValue: 30000000,
  },
  {
    jerseyNumber: 17,
    name: 'Robert Navarro',
    dateOfBirth: '12.04.2002',
    nationality: 'Spanien',
    height: 1.78,
    foot: 'rechts',
    teamMemberSince: '07.08.2022',
    club: 'Real Sociedad San Sebastián',
    previousClub: 'Real Sociedad B',
    uid: '8ea7922c-fbd7-4c3d-a056-915e0c796add',
    position: 'Offensives Mittelfeld',
    league: 'LaLiga',
    marketValue: 4000000,
  },
  {
    jerseyNumber: 21,
    name: 'David Silva',
    dateOfBirth: '08.01.1986',
    nationality: 'Spanien',
    height: 1.7,
    foot: 'links',
    teamMemberSince: '17.08.2020',
    club: 'Real Sociedad San Sebastián',
    previousClub: 'Manchester City',
    uid: '15958b8d-c845-47c9-b1a8-8c081340995a',
    position: 'Offensives Mittelfeld',
    league: 'LaLiga',
    marketValue: 4000000,
  },
  {
    jerseyNumber: 10,
    name: 'Mikel Oyarzabal',
    dateOfBirth: '21.04.1997',
    nationality: 'Spanien',
    height: 1.81,
    foot: 'links',
    teamMemberSince: '01.01.2016',
    club: 'Real Sociedad San Sebastián',
    previousClub: 'Real Sociedad B',
    uid: '157a9e16-0a03-4ac8-bc29-2c030baf39f1',
    position: 'Linksaußen',
    league: 'LaLiga',
    marketValue: 50000000,
  },
  {
    jerseyNumber: 7,
    name: 'Ander Barrenetxea',
    dateOfBirth: '27.12.2001',
    nationality: 'Spanien',
    height: 1.75,
    foot: 'rechts',
    teamMemberSince: '01.07.2019',
    club: 'Real Sociedad San Sebastián',
    previousClub: 'Real Sociedad B',
    uid: 'b3d34680-d423-48d5-8dbe-de448308a2e6',
    position: 'Linksaußen',
    league: 'LaLiga',
    marketValue: 6000000,
  },
  {
    jerseyNumber: 14,
    name: 'Takefusa Kubo',
    dateOfBirth: '04.06.2001',
    nationality: 'Japan',
    height: 1.73,
    foot: 'links',
    teamMemberSince: '19.07.2022',
    club: 'Real Sociedad San Sebastián',
    previousClub: 'Real Madrid',
    uid: '1f96d75e-d90a-46aa-8c95-13f559412c4b',
    position: 'Rechtsaußen',
    league: 'LaLiga',
    marketValue: 15000000,
  },
  {
    jerseyNumber: 25,
    name: 'Umar Sadiq',
    dateOfBirth: '02.02.1997',
    nationality: 'Nigeria',
    height: 1.91,
    foot: 'rechts',
    teamMemberSince: '01.09.2022',
    club: 'Real Sociedad San Sebastián',
    previousClub: 'UD Almería',
    uid: '8d2e8c49-022f-4655-9510-9429f0ae465b',
    position: 'Mittelstürmer',
    league: 'LaLiga',
    marketValue: 18000000,
  },
  {
    jerseyNumber: 11,
    name: 'Momo Cho',
    dateOfBirth: '19.01.2004',
    nationality: 'Frankreich',
    height: 1.82,
    foot: 'links',
    teamMemberSince: '01.07.2022',
    club: 'Real Sociedad San Sebastián',
    previousClub: 'Angers SCO',
    uid: '06bac3d4-596d-47b5-be59-8c5ac17e34d8',
    position: 'Mittelstürmer',
    league: 'LaLiga',
    marketValue: 15000000,
  },
  {
    jerseyNumber: 19,
    name: 'Alexander Sørloth',
    dateOfBirth: '05.12.1995',
    nationality: 'Norwegen',
    height: 1.95,
    foot: 'links',
    teamMemberSince: '29.08.2022',
    club: 'Real Sociedad San Sebastián',
    previousClub: 'RasenBallsport Leipzig',
    uid: 'ace6e5ed-642f-45e9-8ff1-c1de2c2d6c06',
    position: 'Mittelstürmer',
    league: 'LaLiga',
    marketValue: 12000000,
  },
  {
    jerseyNumber: 9,
    name: 'Carlos Fernández',
    dateOfBirth: '22.05.1996',
    nationality: 'Spanien',
    height: 1.85,
    foot: 'links',
    teamMemberSince: '24.01.2021',
    club: 'Real Sociedad San Sebastián',
    previousClub: 'FC Sevilla',
    uid: '0f4c57a3-cd67-4ea7-b408-70776f8352d6',
    position: 'Mittelstürmer',
    league: 'LaLiga',
    marketValue: 4000000,
  },
  {
    jerseyNumber: 35,
    name: 'Filip Jörgensen',
    dateOfBirth: '16.04.2002',
    nationality: 'Dänemark',
    height: 1.9,
    foot: 'rechts',
    teamMemberSince: '05.01.2023',
    club: 'FC Villarreal',
    previousClub: 'FC Villarreal B',
    uid: '56f645dc-b785-4696-92ca-ab501e9a32e8',
    position: 'Torwart',
    league: 'LaLiga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 1,
    name: 'Pepe Reina',
    dateOfBirth: '31.08.1982',
    nationality: 'Spanien',
    height: 1.88,
    foot: 'rechts',
    teamMemberSince: '08.07.2022',
    club: 'FC Villarreal',
    previousClub: 'Lazio Rom',
    uid: '6b6a9751-988a-49d2-9dd8-4f31053f26e8',
    position: 'Torwart',
    league: 'LaLiga',
    marketValue: 700000,
  },
  {
    jerseyNumber: 4,
    name: 'Pau Torres',
    dateOfBirth: '16.01.1997',
    nationality: 'Spanien',
    height: 1.91,
    foot: 'links',
    teamMemberSince: '01.07.2018',
    club: 'FC Villarreal',
    previousClub: 'FC Villarreal B',
    uid: '884ba3dd-0538-416f-9ff8-029f9fe8941f',
    position: 'Innenverteidiger',
    league: 'LaLiga',
    marketValue: 45000000,
  },
  {
    jerseyNumber: 5,
    name: 'Jorge Cuenca',
    dateOfBirth: '17.11.1999',
    nationality: 'Spanien',
    height: 1.9,
    foot: 'links',
    teamMemberSince: '21.09.2020',
    club: 'FC Villarreal',
    previousClub: 'FC Barcelona B',
    uid: '4ca67e0c-c541-4c93-8b26-c511a42cce57',
    position: 'Innenverteidiger',
    league: 'LaLiga',
    marketValue: 5000000,
  },
  {
    jerseyNumber: 23,
    name: 'Aïssa Mandi',
    dateOfBirth: '22.10.1991',
    nationality: 'Algerien',
    height: 1.84,
    foot: 'rechts',
    teamMemberSince: '01.07.2021',
    club: 'FC Villarreal',
    previousClub: ': Ablöse ablösefrei',
    uid: '39d84cfb-5140-4418-836b-4207c551e369',
    position: 'Innenverteidiger',
    league: 'LaLiga',
    marketValue: 4000000,
  },
  {
    jerseyNumber: 3,
    name: 'Raúl Albiol',
    dateOfBirth: '04.09.1985',
    nationality: 'Spanien',
    height: 1.9,
    foot: 'rechts',
    teamMemberSince: '04.07.2019',
    club: 'FC Villarreal',
    previousClub: 'SSC Neapel',
    uid: '867aec3c-c940-4e8d-bafb-218575f9bf11',
    position: 'Innenverteidiger',
    league: 'LaLiga',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 24,
    name: 'Alfonso Pedraza',
    dateOfBirth: '09.04.1996',
    nationality: 'Spanien',
    height: 1.84,
    foot: 'links',
    teamMemberSince: '01.07.2016',
    club: 'FC Villarreal',
    previousClub: 'FC Villarreal B',
    uid: 'c285549c-1e10-4a8f-94ae-87f990b3cad8',
    position: 'Linker Verteidiger',
    league: 'LaLiga',
    marketValue: 15000000,
  },
  {
    jerseyNumber: 12,
    name: 'Johan Mojica',
    dateOfBirth: '21.08.1992',
    nationality: 'Kolumbien',
    height: 1.85,
    foot: 'links',
    teamMemberSince: '01.09.2022',
    club: 'FC Villarreal',
    previousClub: 'FC Elche',
    uid: 'c19f06a5-80c0-4cc2-860d-fcb0b9d12035',
    position: 'Linker Verteidiger',
    league: 'LaLiga',
    marketValue: 5000000,
  },
  {
    jerseyNumber: 18,
    name: 'Alberto Moreno',
    dateOfBirth: '05.07.1992',
    nationality: 'Spanien',
    height: 1.71,
    foot: 'links',
    teamMemberSince: '09.07.2019',
    club: 'FC Villarreal',
    previousClub: 'FC Liverpool',
    uid: '06942417-509c-492b-b5a8-27d7de624ca9',
    position: 'Linker Verteidiger',
    league: 'LaLiga',
    marketValue: 4000000,
  },
  {
    jerseyNumber: 8,
    name: 'Juan Foyth',
    dateOfBirth: '12.01.1998',
    nationality: 'Argentinien',
    height: 1.87,
    foot: 'rechts',
    teamMemberSince: '01.07.2021',
    club: 'FC Villarreal',
    previousClub: 'Tottenham Hotspur',
    uid: '1ac96d86-8e5a-4037-85af-90d7dff31d21',
    position: 'Rechter Verteidiger',
    league: 'LaLiga',
    marketValue: 25000000,
  },
  {
    jerseyNumber: 2,
    name: 'Kiko Femenía',
    dateOfBirth: '02.02.1991',
    nationality: 'Spanien',
    height: 1.71,
    foot: 'rechts',
    teamMemberSince: '28.07.2022',
    club: 'FC Villarreal',
    previousClub: 'FC Watford',
    uid: 'fd089931-0dcc-49c0-b542-0b011f2bbbdf',
    position: 'Rechter Verteidiger',
    league: 'LaLiga',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 19,
    name: 'Francis Coquelin',
    dateOfBirth: '13.05.1991',
    nationality: 'Frankreich',
    height: 1.77,
    foot: 'rechts',
    teamMemberSince: '12.08.2020',
    club: 'FC Villarreal',
    previousClub: 'FC Valencia',
    uid: '5c4b7e64-ef42-4c62-8ffe-40fb7a3ce6b8',
    position: 'Defensives Mittelfeld',
    league: 'LaLiga',
    marketValue: 5000000,
  },
  {
    jerseyNumber: 6,
    name: 'Étienne Capoue',
    dateOfBirth: '11.07.1988',
    nationality: 'Frankreich',
    height: 1.89,
    foot: 'rechts',
    teamMemberSince: '04.01.2021',
    club: 'FC Villarreal',
    previousClub: 'FC Watford',
    uid: '8e590921-7276-458d-a7eb-31b2430d581a',
    position: 'Defensives Mittelfeld',
    league: 'LaLiga',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 39,
    name: 'Ramón Terrats',
    dateOfBirth: '18.10.2000',
    nationality: 'Spanien',
    height: 1.81,
    foot: 'links',
    teamMemberSince: '18.01.2023',
    club: 'FC Villarreal',
    previousClub: 'FC Girona',
    uid: '6e5c001e-0bbd-403e-b636-56995eb213d0',
    position: 'Defensives Mittelfeld',
    league: 'LaLiga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 17,
    name: 'Giovani Lo Celso',
    dateOfBirth: '09.04.1996',
    nationality: 'Argentinien',
    height: 1.77,
    foot: 'links',
    teamMemberSince: '14.08.2022',
    club: 'FC Villarreal',
    previousClub: 'Tottenham Hotspur',
    uid: '134e4ca4-988d-4d27-8240-ce679575c17d',
    position: 'Zentrales Mittelfeld',
    league: 'LaLiga',
    marketValue: 18000000,
  },
  {
    jerseyNumber: 10,
    name: 'Dani Parejo',
    dateOfBirth: '16.04.1989',
    nationality: 'Spanien',
    height: 1.82,
    foot: 'rechts',
    teamMemberSince: '12.08.2020',
    club: 'FC Villarreal',
    previousClub: 'FC Valencia',
    uid: '278f2a11-3c81-4d53-a930-e12dd0b54f08',
    position: 'Zentrales Mittelfeld',
    league: 'LaLiga',
    marketValue: 7000000,
  },
  {
    jerseyNumber: 14,
    name: 'Manu Trigueros',
    dateOfBirth: '17.10.1991',
    nationality: 'Spanien',
    height: 1.78,
    foot: 'rechts',
    teamMemberSince: '01.07.2012',
    club: 'FC Villarreal',
    previousClub: 'FC Villarreal B',
    uid: '8a483309-a572-4ede-954a-eab0bccea772',
    position: 'Zentrales Mittelfeld',
    league: 'LaLiga',
    marketValue: 6000000,
  },
  {
    jerseyNumber: 16,
    name: 'Álex Baena',
    dateOfBirth: '20.07.2001',
    nationality: 'Spanien',
    height: 1.74,
    foot: 'rechts',
    teamMemberSince: '01.08.2020',
    club: 'FC Villarreal',
    previousClub: 'FC Villarreal B',
    uid: '3ed29357-bb27-4a58-9907-bef9e11905d7',
    position: 'Linksaußen',
    league: 'LaLiga',
    marketValue: 20000000,
  },
  {
    jerseyNumber: 21,
    name: 'Yéremy Pino',
    dateOfBirth: '20.10.2002',
    nationality: 'Spanien',
    height: 1.72,
    foot: 'rechts',
    teamMemberSince: '01.08.2020',
    club: 'FC Villarreal',
    previousClub: 'FC Villarreal C',
    uid: '246b4a31-1df1-41b2-84ba-ffebec5e3493',
    position: 'Rechtsaußen',
    league: 'LaLiga',
    marketValue: 38000000,
  },
  {
    jerseyNumber: 11,
    name: 'Samuel Chukwueze',
    dateOfBirth: '22.05.1999',
    nationality: 'Nigeria',
    height: 1.72,
    foot: 'links',
    teamMemberSince: '01.11.2018',
    club: 'FC Villarreal',
    previousClub: 'FC Villarreal B',
    uid: 'c71bf086-660f-4e59-945b-b8a8573ad13b',
    position: 'Rechtsaußen',
    league: 'LaLiga',
    marketValue: 20000000,
  },
  {
    jerseyNumber: 7,
    name: 'Gerard Moreno',
    dateOfBirth: '07.04.1992',
    nationality: 'Spanien',
    height: 1.8,
    foot: 'links',
    teamMemberSince: '01.07.2018',
    club: 'FC Villarreal',
    previousClub: 'Espanyol Barcelona',
    uid: '4d50a22c-0466-4274-b97b-118feca03e03',
    position: 'Mittelstürmer',
    league: 'LaLiga',
    marketValue: 30000000,
  },
  {
    jerseyNumber: 15,
    name: 'Nicolas Jackson',
    dateOfBirth: '20.06.2001',
    nationality: 'Senegal',
    height: 1.88,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'FC Villarreal',
    previousClub: 'FC Villarreal B',
    uid: 'dd968301-e90f-44e4-a7b9-b4917de20815',
    position: 'Mittelstürmer',
    league: 'LaLiga',
    marketValue: 15000000,
  },
  {
    jerseyNumber: 22,
    name: 'José Luis Morales',
    dateOfBirth: '23.07.1987',
    nationality: 'Spanien',
    height: 1.8,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'FC Villarreal',
    previousClub: 'UD Levante',
    uid: 'c086128e-a84f-42a2-b41c-d63941f11de2',
    position: 'Mittelstürmer',
    league: 'LaLiga',
    marketValue: 4000000,
  },
  {
    jerseyNumber: 13,
    name: 'Rui Silva',
    dateOfBirth: '07.02.1994',
    nationality: 'Portugal',
    height: 1.91,
    foot: 'links',
    teamMemberSince: '01.07.2021',
    club: 'Real Betis Sevilla',
    previousClub: 'FC Granada',
    uid: 'e6d85142-b42d-4533-a80c-32e9d64d7943',
    position: 'Torwart',
    league: 'LaLiga',
    marketValue: 15000000,
  },
  {
    jerseyNumber: 25,
    name: 'Dani Martín',
    dateOfBirth: '08.07.1998',
    nationality: 'Spanien',
    height: 1.87,
    foot: 'rechts',
    teamMemberSince: '18.07.2019',
    club: 'Real Betis Sevilla',
    previousClub: 'Sporting Gijón',
    uid: 'e251a480-b6e8-404d-81da-8d603739f2a0',
    position: 'Torwart',
    league: 'LaLiga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 1,
    name: 'Claudio Bravo',
    dateOfBirth: '13.04.1983',
    nationality: 'Chile',
    height: 1.84,
    foot: 'rechts',
    teamMemberSince: '30.08.2020',
    club: 'Real Betis Sevilla',
    previousClub: 'Manchester City',
    uid: '9e5f3ae9-7c30-4474-8733-53d927d20833',
    position: 'Torwart',
    league: 'LaLiga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 19,
    name: 'Luiz Felipe',
    dateOfBirth: '22.03.1997',
    nationality: 'Italien',
    height: 1.87,
    foot: 'rechts',
    teamMemberSince: '04.07.2022',
    club: 'Real Betis Sevilla',
    previousClub: 'Lazio Rom',
    uid: '91a7349c-b84b-4c13-960c-4d5c3a01df43',
    position: 'Innenverteidiger',
    league: 'LaLiga',
    marketValue: 15000000,
  },
  {
    jerseyNumber: 3,
    name: 'Edgar González',
    dateOfBirth: '01.04.1997',
    nationality: 'Spanien',
    height: 1.93,
    foot: 'rechts',
    teamMemberSince: '21.07.2020',
    club: 'Real Betis Sevilla',
    previousClub: ': Ablöse -',
    uid: 'a9fcde38-c0a2-4237-a16f-6e44edf44955',
    position: 'Innenverteidiger',
    league: 'LaLiga',
    marketValue: 10000000,
  },
  {
    jerseyNumber: 16,
    name: 'Germán Pezzella',
    dateOfBirth: '27.06.1991',
    nationality: 'Argentinien',
    height: 1.87,
    foot: 'rechts',
    teamMemberSince: '19.08.2021',
    club: 'Real Betis Sevilla',
    previousClub: ': Ablöse 3,25 Mio. €',
    uid: '4709dcd4-6549-4e23-b037-c2d2300f09af',
    position: 'Innenverteidiger',
    league: 'LaLiga',
    marketValue: 5000000,
  },
  {
    jerseyNumber: 6,
    name: 'Víctor Ruiz',
    dateOfBirth: '25.01.1989',
    nationality: 'Spanien',
    height: 1.84,
    foot: 'links',
    teamMemberSince: '31.08.2020',
    club: 'Real Betis Sevilla',
    previousClub: 'Besiktas JK',
    uid: 'ee351544-51ac-4d0a-a309-db7db699a982',
    position: 'Innenverteidiger',
    league: 'LaLiga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 20,
    name: 'Abner',
    dateOfBirth: '27.05.2000',
    nationality: 'Brasilien',
    height: 1.81,
    foot: 'links',
    teamMemberSince: '15.01.2023',
    club: 'Real Betis Sevilla',
    previousClub: 'CA Paranaense',
    uid: '3676718a-0364-4458-af84-60ab8c82018f',
    position: 'Linker Verteidiger',
    league: 'LaLiga',
    marketValue: 8000000,
  },
  {
    jerseyNumber: 33,
    name: 'Juan Miranda',
    dateOfBirth: '19.01.2000',
    nationality: 'Spanien',
    height: 1.85,
    foot: 'links',
    teamMemberSince: '01.07.2021',
    club: 'Real Betis Sevilla',
    previousClub: 'FC Barcelona',
    uid: '4b1558bc-3a49-4bbc-a8b3-f3c02f79f368',
    position: 'Linker Verteidiger',
    league: 'LaLiga',
    marketValue: 5000000,
  },
  {
    jerseyNumber: 23,
    name: 'Youssouf Sabaly',
    dateOfBirth: '05.03.1993',
    nationality: 'Senegal',
    height: 1.73,
    foot: 'rechts',
    teamMemberSince: '01.07.2021',
    club: 'Real Betis Sevilla',
    previousClub: 'FC Girondins Bordeaux',
    uid: '3d6a6ea6-301c-44a3-8c28-35046977f5b6',
    position: 'Rechter Verteidiger',
    league: 'LaLiga',
    marketValue: 5000000,
  },
  {
    jerseyNumber: 24,
    name: 'Aitor Ruibal',
    dateOfBirth: '22.03.1996',
    nationality: 'Spanien',
    height: 1.76,
    foot: 'rechts',
    teamMemberSince: '01.07.2019',
    club: 'Real Betis Sevilla',
    previousClub: ': Ablöse -',
    uid: '3bde1882-9170-4454-b497-ae622a220dfe',
    position: 'Rechter Verteidiger',
    league: 'LaLiga',
    marketValue: 5000000,
  },
  {
    jerseyNumber: 2,
    name: 'Martín Montoya',
    dateOfBirth: '14.04.1991',
    nationality: 'Spanien',
    height: 1.75,
    foot: 'rechts',
    teamMemberSince: '25.08.2020',
    club: 'Real Betis Sevilla',
    previousClub: 'Brighton & Hove Albion',
    uid: 'c7a996e2-7f0a-4896-882a-53e8d9d9c369',
    position: 'Rechter Verteidiger',
    league: 'LaLiga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 5,
    name: 'Guido Rodríguez',
    dateOfBirth: '12.04.1994',
    nationality: 'Argentinien',
    height: 1.85,
    foot: 'rechts',
    teamMemberSince: '14.01.2020',
    club: 'Real Betis Sevilla',
    previousClub: 'CF América',
    uid: '30928cb0-c707-401d-af3e-939dfc5a0e7a',
    position: 'Defensives Mittelfeld',
    league: 'LaLiga',
    marketValue: 28000000,
  },
  {
    jerseyNumber: 14,
    name: 'William Carvalho',
    dateOfBirth: '07.04.1992',
    nationality: 'Portugal',
    height: 1.87,
    foot: 'rechts',
    teamMemberSince: '13.07.2018',
    club: 'Real Betis Sevilla',
    previousClub: 'Sporting Lissabon',
    uid: '97c760e4-1a2f-4b69-9628-bc3df0137385',
    position: 'Defensives Mittelfeld',
    league: 'LaLiga',
    marketValue: 16000000,
  },
  {
    jerseyNumber: 4,
    name: 'Paul Akouokou',
    dateOfBirth: '20.12.1997',
    nationality: 'Elfenbeinküste',
    height: 1.81,
    foot: 'rechts',
    teamMemberSince: '10.09.2020',
    club: 'Real Betis Sevilla',
    previousClub: ': Ablöse -',
    uid: '987a4ab4-b93e-46c8-a83d-3c0818a1da0a',
    position: 'Defensives Mittelfeld',
    league: 'LaLiga',
    marketValue: 4000000,
  },
  {
    jerseyNumber: 18,
    name: 'Andrés Guardado',
    dateOfBirth: '28.09.1986',
    nationality: 'Mexiko',
    height: 1.67,
    foot: 'links',
    teamMemberSince: '07.07.2017',
    club: 'Real Betis Sevilla',
    previousClub: ': Ablöse 2,50 Mio. €',
    uid: 'b3863d37-268a-48d0-8cb9-9b6133ed72d6',
    position: 'Zentrales Mittelfeld',
    league: 'LaLiga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 8,
    name: 'Nabil Fekir',
    dateOfBirth: '18.07.1993',
    nationality: 'Frankreich',
    height: 1.73,
    foot: 'links',
    teamMemberSince: '23.07.2019',
    club: 'Real Betis Sevilla',
    previousClub: ': Ablöse 19,75 Mio. €',
    uid: '0359a80d-e344-4611-b2bf-216bdfe1f975',
    position: 'Offensives Mittelfeld',
    league: 'LaLiga',
    marketValue: 30000000,
  },
  {
    jerseyNumber: 10,
    name: 'Sergio Canales',
    dateOfBirth: '16.02.1991',
    nationality: 'Spanien',
    height: 1.78,
    foot: 'links',
    teamMemberSince: '03.07.2018',
    club: 'Real Betis Sevilla',
    previousClub: 'Real Sociedad San Sebastián',
    uid: '962254b0-3394-42e6-93f7-4bcaf09cf79f',
    position: 'Offensives Mittelfeld',
    league: 'LaLiga',
    marketValue: 20000000,
  },
  {
    jerseyNumber: 28,
    name: 'Rodri Sánchez',
    dateOfBirth: '16.05.2000',
    nationality: 'Spanien',
    height: 1.75,
    foot: 'links',
    teamMemberSince: '01.07.2021',
    club: 'Real Betis Sevilla',
    previousClub: ': Ablöse -',
    uid: '70280084-0eef-41d2-87aa-2a38c76506df',
    position: 'Offensives Mittelfeld',
    league: 'LaLiga',
    marketValue: 8000000,
  },
  {
    jerseyNumber: 7,
    name: 'Juanmi',
    dateOfBirth: '20.05.1993',
    nationality: 'Spanien',
    height: 1.72,
    foot: 'rechts',
    teamMemberSince: '01.07.2019',
    club: 'Real Betis Sevilla',
    previousClub: 'Real Sociedad San Sebastián',
    uid: 'ed976f67-b0b7-4b3b-a51e-2988358c99ae',
    position: 'Linksaußen',
    league: 'LaLiga',
    marketValue: 15000000,
  },
  {
    jerseyNumber: 11,
    name: 'Luiz Henrique',
    dateOfBirth: '02.01.2001',
    nationality: 'Brasilien',
    height: 1.82,
    foot: 'links',
    teamMemberSince: '01.07.2022',
    club: 'Real Betis Sevilla',
    previousClub: 'Fluminense Rio de Janeiro',
    uid: '1e69fec4-8bd8-489f-b3da-f71890d00439',
    position: 'Rechtsaußen',
    league: 'LaLiga',
    marketValue: 15000000,
  },
  {
    jerseyNumber: 17,
    name: 'Joaquín',
    dateOfBirth: '21.07.1981',
    nationality: 'Spanien',
    height: 1.81,
    foot: 'rechts',
    teamMemberSince: '31.08.2015',
    club: 'Real Betis Sevilla',
    previousClub: ': Ablöse 1,50 Mio. €',
    uid: '8918d724-ff36-4643-923d-1e20859c7a5b',
    position: 'Rechtsaußen',
    league: 'LaLiga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 21,
    name: 'Ayoze Pérez',
    dateOfBirth: '29.07.1993',
    nationality: 'Spanien',
    height: 1.78,
    foot: 'rechts',
    teamMemberSince: '31.01.2023',
    club: 'Real Betis Sevilla',
    previousClub: 'Leicester City',
    uid: '3b3e39d0-c4db-422a-b488-11f4e10bdd5e',
    position: 'Hängende Spitze',
    league: 'LaLiga',
    marketValue: 6000000,
  },
  {
    jerseyNumber: 9,
    name: 'Borja Iglesias',
    dateOfBirth: '17.01.1993',
    nationality: 'Spanien',
    height: 1.87,
    foot: 'rechts',
    teamMemberSince: '14.08.2019',
    club: 'Real Betis Sevilla',
    previousClub: 'Espanyol Barcelona',
    uid: '7e436829-7222-4694-8b05-759f91bd4015',
    position: 'Mittelstürmer',
    league: 'LaLiga',
    marketValue: 25000000,
  },
  {
    jerseyNumber: 12,
    name: 'Willian José',
    dateOfBirth: '23.11.1991',
    nationality: 'Brasilien',
    height: 1.89,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'Real Betis Sevilla',
    previousClub: 'Real Sociedad San Sebastián',
    uid: 'f1fc97a7-bceb-47e0-843b-834f36bc965e',
    position: 'Mittelstürmer',
    league: 'LaLiga',
    marketValue: 7000000,
  },
  {
    jerseyNumber: 1,
    name: 'Unai Simón',
    dateOfBirth: '11.06.1997',
    nationality: 'Spanien',
    height: 1.9,
    foot: 'rechts',
    teamMemberSince: '01.07.2018',
    club: 'Athletic Bilbao',
    previousClub: 'Bilbao Athletic',
    uid: 'd5495a92-4790-46a6-a740-f6501af6abb7',
    position: 'Torwart',
    league: 'LaLiga',
    marketValue: 25000000,
  },
  {
    jerseyNumber: 13,
    name: 'Julen Agirrezabala',
    dateOfBirth: '26.12.2000',
    nationality: 'Spanien',
    height: 1.87,
    foot: 'links',
    teamMemberSince: '01.07.2022',
    club: 'Athletic Bilbao',
    previousClub: 'Bilbao Athletic',
    uid: '5d98eb84-dc7e-46cb-b756-15d0077588ab',
    position: 'Torwart',
    league: 'LaLiga',
    marketValue: 5000000,
  },
  {
    jerseyNumber: 35,
    name: 'Ander Iru',
    dateOfBirth: '22.08.1998',
    nationality: 'Spanien',
    height: 1.88,
    foot: 'links',
    teamMemberSince: '01.07.2022',
    club: 'Athletic Bilbao',
    previousClub: 'Bilbao Athletic',
    uid: 'fe20c772-0ced-487d-931e-35319441fdf3',
    position: 'Torwart',
    league: 'LaLiga',
    marketValue: 100000,
  },
  {
    jerseyNumber: 4,
    name: 'Iñigo Martínez',
    dateOfBirth: '17.05.1991',
    nationality: 'Spanien',
    height: 1.82,
    foot: 'links',
    teamMemberSince: '30.01.2018',
    club: 'Athletic Bilbao',
    previousClub: 'Real Sociedad San Sebastián',
    uid: '73e3e673-5e79-4f3d-81a8-f4d2025a1050',
    position: 'Innenverteidiger',
    league: 'LaLiga',
    marketValue: 18000000,
  },
  {
    jerseyNumber: 5,
    name: 'Yeray Álvarez',
    dateOfBirth: '24.01.1995',
    nationality: 'Spanien',
    height: 1.82,
    foot: 'rechts',
    teamMemberSince: '01.07.2016',
    club: 'Athletic Bilbao',
    previousClub: 'Bilbao Athletic',
    uid: 'a17e0ca1-3c85-45ae-a7cb-6da9c50507c7',
    position: 'Innenverteidiger',
    league: 'LaLiga',
    marketValue: 15000000,
  },
  {
    jerseyNumber: 3,
    name: 'Dani Vivian',
    dateOfBirth: '05.07.1999',
    nationality: 'Spanien',
    height: 1.84,
    foot: 'rechts',
    teamMemberSince: '01.08.2020',
    club: 'Athletic Bilbao',
    previousClub: 'Bilbao Athletic',
    uid: '955255fa-5296-4265-8624-076732e15cab',
    position: 'Innenverteidiger',
    league: 'LaLiga',
    marketValue: 14000000,
  },
  {
    jerseyNumber: 31,
    name: 'Aitor Paredes',
    dateOfBirth: '29.04.2000',
    nationality: 'Spanien',
    height: 1.85,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'Athletic Bilbao',
    previousClub: 'Bilbao Athletic',
    uid: 'b250186b-283c-49e1-83ea-83616e77fcf4',
    position: 'Innenverteidiger',
    league: 'LaLiga',
    marketValue: 400000,
  },
  {
    jerseyNumber: 17,
    name: 'Yuri Berchiche',
    dateOfBirth: '10.02.1990',
    nationality: 'Spanien',
    height: 1.81,
    foot: 'links',
    teamMemberSince: '02.07.2018',
    club: 'Athletic Bilbao',
    previousClub: 'FC Paris Saint-Germain',
    uid: 'bf2fcf2f-bcfc-439c-8d44-ed7544c04d64',
    position: 'Linker Verteidiger',
    league: 'LaLiga',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 24,
    name: 'Mikel Balenziaga',
    dateOfBirth: '29.02.1988',
    nationality: 'Spanien',
    height: 1.77,
    foot: 'links',
    teamMemberSince: '01.07.2013',
    club: 'Athletic Bilbao',
    previousClub: ': Ablöse 500 Tsd. €',
    uid: '867212fa-d7f0-44d8-b66c-63e346c6e200',
    position: 'Linker Verteidiger',
    league: 'LaLiga',
    marketValue: 500000,
  },
  {
    jerseyNumber: 15,
    name: 'Iñigo Lekue',
    dateOfBirth: '04.05.1993',
    nationality: 'Spanien',
    height: 1.8,
    foot: 'rechts',
    teamMemberSince: '01.07.2016',
    club: 'Athletic Bilbao',
    previousClub: 'Bilbao Athletic',
    uid: '286a4518-d6e4-4402-8446-ef840d094307',
    position: 'Rechter Verteidiger',
    league: 'LaLiga',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 21,
    name: 'Ander Capa',
    dateOfBirth: '08.02.1992',
    nationality: 'Spanien',
    height: 1.75,
    foot: 'rechts',
    teamMemberSince: '01.07.2018',
    club: 'Athletic Bilbao',
    previousClub: 'SD Eibar',
    uid: 'e7d21608-d530-4967-b4c6-2c9a43494065',
    position: 'Rechter Verteidiger',
    league: 'LaLiga',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 18,
    name: 'Óscar de Marcos',
    dateOfBirth: '14.04.1989',
    nationality: 'Spanien',
    height: 1.82,
    foot: 'rechts',
    teamMemberSince: '01.07.2010',
    club: 'Athletic Bilbao',
    previousClub: 'Deportivo Alavés',
    uid: 'da7085f4-412b-41b5-bc2b-5c66c09c30c3',
    position: 'Rechter Verteidiger',
    league: 'LaLiga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 6,
    name: 'Mikel Vesga',
    dateOfBirth: '08.04.1993',
    nationality: 'Spanien',
    height: 1.91,
    foot: 'links',
    teamMemberSince: '01.07.2016',
    club: 'Athletic Bilbao',
    previousClub: 'Bilbao Athletic',
    uid: 'eedf4aef-6410-4f02-a403-534033a5fc19',
    position: 'Defensives Mittelfeld',
    league: 'LaLiga',
    marketValue: 5000000,
  },
  {
    jerseyNumber: 16,
    name: 'Unai Vencedor',
    dateOfBirth: '15.11.2000',
    nationality: 'Spanien',
    height: 1.76,
    foot: 'rechts',
    teamMemberSince: '01.08.2020',
    club: 'Athletic Bilbao',
    previousClub: 'Bilbao Athletic',
    uid: 'c01f6e83-0a67-4926-8f76-702e1f865f05',
    position: 'Zentrales Mittelfeld',
    league: 'LaLiga',
    marketValue: 14000000,
  },
  {
    jerseyNumber: 19,
    name: 'Oier Zarraga',
    dateOfBirth: '04.01.1999',
    nationality: 'Spanien',
    height: 1.75,
    foot: 'rechts',
    teamMemberSince: '01.02.2021',
    club: 'Athletic Bilbao',
    previousClub: 'Bilbao Athletic',
    uid: 'b035f824-02b3-477d-a1a8-a5859c900fe9',
    position: 'Zentrales Mittelfeld',
    league: 'LaLiga',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 23,
    name: 'Ander Herrera',
    dateOfBirth: '14.08.1989',
    nationality: 'Spanien',
    height: 1.82,
    foot: 'rechts',
    teamMemberSince: '31.01.2023',
    club: 'Athletic Bilbao',
    previousClub: 'FC Paris Saint-Germain',
    uid: '8fd2a518-cc20-4636-9298-4558bd6cbcf8',
    position: 'Zentrales Mittelfeld',
    league: 'LaLiga',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 14,
    name: 'Dani García',
    dateOfBirth: '24.05.1990',
    nationality: 'Spanien',
    height: 1.79,
    foot: 'rechts',
    teamMemberSince: '01.07.2018',
    club: 'Athletic Bilbao',
    previousClub: 'SD Eibar',
    uid: '609e7adb-0919-4e6e-8a96-bc2826f1c480',
    position: 'Zentrales Mittelfeld',
    league: 'LaLiga',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 8,
    name: 'Oihan Sancet',
    dateOfBirth: '25.04.2000',
    nationality: 'Spanien',
    height: 1.88,
    foot: 'rechts',
    teamMemberSince: '04.08.2020',
    club: 'Athletic Bilbao',
    previousClub: 'Bilbao Athletic',
    uid: '7211f6fa-78f9-462b-a341-e8fe287a17de',
    position: 'Offensives Mittelfeld',
    league: 'LaLiga',
    marketValue: 30000000,
  },
  {
    jerseyNumber: 10,
    name: 'Iker Muniain',
    dateOfBirth: '19.12.1992',
    nationality: 'Spanien',
    height: 1.69,
    foot: 'rechts',
    teamMemberSince: '01.07.2009',
    club: 'Athletic Bilbao',
    previousClub: 'Bilbao Athletic',
    uid: '96bbdeed-d3ed-41f5-91dd-750506ca4a15',
    position: 'Offensives Mittelfeld',
    league: 'LaLiga',
    marketValue: 10000000,
  },
  {
    jerseyNumber: 7,
    name: 'Álex Berenguer',
    dateOfBirth: '04.07.1995',
    nationality: 'Spanien',
    height: 1.75,
    foot: 'rechts',
    teamMemberSince: '02.10.2020',
    club: 'Athletic Bilbao',
    previousClub: 'FC Turin',
    uid: '156ed94f-af76-47fc-aa9b-2b636e94ebf1',
    position: 'Linksaußen',
    league: 'LaLiga',
    marketValue: 15000000,
  },
  {
    jerseyNumber: 2,
    name: 'Jon Morcillo',
    dateOfBirth: '15.09.1998',
    nationality: 'Spanien',
    height: 1.83,
    foot: 'links',
    teamMemberSince: '01.08.2020',
    club: 'Athletic Bilbao',
    previousClub: 'Bilbao Athletic',
    uid: '67479613-af56-4514-b582-112ae968bafb',
    position: 'Linksaußen',
    league: 'LaLiga',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 11,
    name: 'Nico Williams',
    dateOfBirth: '12.07.2002',
    nationality: 'Spanien',
    height: 1.81,
    foot: 'rechts',
    teamMemberSince: '01.07.2021',
    club: 'Athletic Bilbao',
    previousClub: 'Bilbao Athletic',
    uid: 'f840bcf4-6af2-4481-ad63-67b88d9cfa5d',
    position: 'Rechtsaußen',
    league: 'LaLiga',
    marketValue: 25000000,
  },
  {
    jerseyNumber: 9,
    name: 'Iñaki Williams',
    dateOfBirth: '15.06.1994',
    nationality: 'Ghana',
    height: 1.86,
    foot: 'rechts',
    teamMemberSince: '01.07.2015',
    club: 'Athletic Bilbao',
    previousClub: 'Bilbao Athletic',
    uid: '2af62f28-4787-4fce-9adb-2878b080e905',
    position: 'Mittelstürmer',
    league: 'LaLiga',
    marketValue: 25000000,
  },
  {
    jerseyNumber: 22,
    name: 'Raúl García',
    dateOfBirth: '11.07.1986',
    nationality: 'Spanien',
    height: 1.84,
    foot: 'rechts',
    teamMemberSince: '30.08.2015',
    club: 'Athletic Bilbao',
    previousClub: ': Ablöse 10,00 Mio. €',
    uid: '57d8cb60-6ae0-4418-bd65-b38385a2b495',
    position: 'Mittelstürmer',
    league: 'LaLiga',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 12,
    name: 'Gorka Guruzeta',
    dateOfBirth: '12.09.1996',
    nationality: 'Spanien',
    height: 1.88,
    foot: 'rechts',
    teamMemberSince: '03.07.2022',
    club: 'Athletic Bilbao',
    previousClub: 'SD Amorebieta',
    uid: '5737b946-1371-4136-aacd-8874d2f7ddd4',
    position: 'Mittelstürmer',
    league: 'LaLiga',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 25,
    name: 'Giorgi Mamardashvili',
    dateOfBirth: '29.09.2000',
    nationality: 'Georgien',
    height: 1.97,
    foot: 'links',
    teamMemberSince: '01.01.2022',
    club: 'FC Valencia',
    previousClub: 'Dinamo Tiflis',
    uid: 'b17d6c8e-fe9b-412a-b36b-f8ccc4bdfb02',
    position: 'Torwart',
    league: 'LaLiga',
    marketValue: 25000000,
  },
  {
    jerseyNumber: 23,
    name: 'Jaume Doménech',
    dateOfBirth: '05.11.1990',
    nationality: 'Spanien',
    height: 1.85,
    foot: 'rechts',
    teamMemberSince: '01.07.2015',
    club: 'FC Valencia',
    previousClub: 'FC Valencia Mestalla',
    uid: 'b4ab05b8-adb6-41b9-86b5-6935993b3cd0',
    position: 'Torwart',
    league: 'LaLiga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 1,
    name: 'Iago Herrerín',
    dateOfBirth: '25.01.1988',
    nationality: 'Spanien',
    height: 1.87,
    foot: 'rechts',
    teamMemberSince: '28.09.2022',
    club: 'FC Valencia',
    previousClub: 'Vereinslos',
    uid: '7d810bf4-04d0-44d0-9c5c-da17ec34f618',
    position: 'Torwart',
    league: 'LaLiga',
    marketValue: 500000,
  },
  {
    jerseyNumber: 13,
    name: 'Cristian Rivero',
    dateOfBirth: '21.03.1998',
    nationality: 'Spanien',
    height: 1.88,
    foot: 'rechts',
    teamMemberSince: '01.08.2020',
    club: 'FC Valencia',
    previousClub: 'FC Valencia Mestalla',
    uid: '84433fa1-3532-494a-b302-b334155400bb',
    position: 'Torwart',
    league: 'LaLiga',
    marketValue: 200000,
  },
  {
    jerseyNumber: 12,
    name: 'Mouctar Diakhaby',
    dateOfBirth: '19.12.1996',
    nationality: 'Guinea',
    height: 1.92,
    foot: 'rechts',
    teamMemberSince: '01.07.2018',
    club: 'FC Valencia',
    previousClub: ': Ablöse 15,00 Mio. €',
    uid: '490379ad-d2e6-4a33-9932-724159c1d3ad',
    position: 'Innenverteidiger',
    league: 'LaLiga',
    marketValue: 8000000,
  },
  {
    jerseyNumber: 24,
    name: 'Eray Cömert',
    dateOfBirth: '04.02.1998',
    nationality: 'Schweiz',
    height: 1.83,
    foot: 'rechts',
    teamMemberSince: '25.01.2022',
    club: 'FC Valencia',
    previousClub: 'FC Basel 1893',
    uid: '67fafb28-b79d-4449-b22d-31498cd5c060',
    position: 'Innenverteidiger',
    league: 'LaLiga',
    marketValue: 4000000,
  },
  {
    jerseyNumber: 5,
    name: 'Gabriel Paulista',
    dateOfBirth: '26.11.1990',
    nationality: 'Brasilien',
    height: 1.87,
    foot: 'rechts',
    teamMemberSince: '18.08.2017',
    club: 'FC Valencia',
    previousClub: 'FC Arsenal',
    uid: '6d4c582e-8274-49b2-836c-78bf025a5e9b',
    position: 'Innenverteidiger',
    league: 'LaLiga',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 15,
    name: 'Cenk Özkacar',
    dateOfBirth: '06.10.2000',
    nationality: 'Türkei',
    height: 1.9,
    foot: 'links',
    teamMemberSince: '23.08.2022',
    club: 'FC Valencia',
    previousClub: 'Olympique Lyon',
    uid: '945d3d45-7408-46ef-9e46-dfebf3a4a7cb',
    position: 'Innenverteidiger',
    league: 'LaLiga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 14,
    name: 'José Gayà',
    dateOfBirth: '25.05.1995',
    nationality: 'Spanien',
    height: 1.72,
    foot: 'links',
    teamMemberSince: '01.07.2014',
    club: 'FC Valencia',
    previousClub: 'FC Valencia Mestalla',
    uid: '3b9e3373-18b4-49da-a9d3-0f64944f9ce8',
    position: 'Linker Verteidiger',
    league: 'LaLiga',
    marketValue: 35000000,
  },
  {
    jerseyNumber: 21,
    name: 'Jesús Vázquez',
    dateOfBirth: '02.01.2003',
    nationality: 'Spanien',
    height: 1.82,
    foot: 'links',
    teamMemberSince: '01.01.2022',
    club: 'FC Valencia',
    previousClub: 'FC Valencia Mestalla',
    uid: '8bf1282d-ae56-4f73-9d55-d4b0e179cb40',
    position: 'Linker Verteidiger',
    league: 'LaLiga',
    marketValue: 5000000,
  },
  {
    jerseyNumber: 3,
    name: 'Toni Lato',
    dateOfBirth: '21.11.1997',
    nationality: 'Spanien',
    height: 1.71,
    foot: 'links',
    teamMemberSince: '01.07.2017',
    club: 'FC Valencia',
    previousClub: 'FC Valencia Mestalla',
    uid: 'b32260d3-5fe3-49a7-a735-6f07067cc163',
    position: 'Linker Verteidiger',
    league: 'LaLiga',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 2,
    name: 'Thierry Correia',
    dateOfBirth: '09.03.1999',
    nationality: 'Portugal',
    height: 1.76,
    foot: 'rechts',
    teamMemberSince: '02.09.2019',
    club: 'FC Valencia',
    previousClub: 'Sporting Lissabon',
    uid: '99494f2d-e049-4620-980d-edf041666eab',
    position: 'Rechter Verteidiger',
    league: 'LaLiga',
    marketValue: 12000000,
  },
  {
    jerseyNumber: 20,
    name: 'Dimitri Foulquier',
    dateOfBirth: '23.03.1993',
    nationality: 'Guadeloupe',
    height: 1.83,
    foot: 'rechts',
    teamMemberSince: '30.08.2021',
    club: 'FC Valencia',
    previousClub: 'FC Granada',
    uid: '7f403b68-da39-41d2-a430-a9ad1ea57dfe',
    position: 'Rechter Verteidiger',
    league: 'LaLiga',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 6,
    name: 'Hugo Guillamón',
    dateOfBirth: '31.01.2000',
    nationality: 'Spanien',
    height: 1.78,
    foot: 'rechts',
    teamMemberSince: '01.07.2020',
    club: 'FC Valencia',
    previousClub: 'FC Valencia Mestalla',
    uid: 'e5b65dfe-bddb-4b06-a99e-0beb9071396b',
    position: 'Defensives Mittelfeld',
    league: 'LaLiga',
    marketValue: 22000000,
  },
  {
    jerseyNumber: 4,
    name: 'Yunus Musah',
    dateOfBirth: '29.11.2002',
    nationality: 'Vereinigte Staaten',
    height: 1.78,
    foot: 'rechts',
    teamMemberSince: '01.08.2020',
    club: 'FC Valencia',
    previousClub: 'FC Valencia Mestalla',
    uid: 'a3574b84-a2a3-482a-9606-85f0b8882f1c',
    position: 'Zentrales Mittelfeld',
    league: 'LaLiga',
    marketValue: 22000000,
  },
  {
    jerseyNumber: 18,
    name: 'André Almeida',
    dateOfBirth: '30.05.2000',
    nationality: 'Portugal',
    height: 1.76,
    foot: 'rechts',
    teamMemberSince: '25.08.2022',
    club: 'FC Valencia',
    previousClub: 'Vitória Guimarães SC',
    uid: '7d62c208-aa2c-43d5-b7ef-d91283f40aa2',
    position: 'Zentrales Mittelfeld',
    league: 'LaLiga',
    marketValue: 15000000,
  },
  {
    jerseyNumber: 17,
    name: 'Nico González',
    dateOfBirth: '03.01.2002',
    nationality: 'Spanien',
    height: 1.88,
    foot: 'rechts',
    teamMemberSince: '13.08.2022',
    club: 'FC Valencia',
    previousClub: 'FC Barcelona',
    uid: 'c72ace63-a5ca-498c-9439-1a03d07d8e40',
    position: 'Zentrales Mittelfeld',
    league: 'LaLiga',
    marketValue: 10000000,
  },
  {
    jerseyNumber: 8,
    name: 'Ilaix Moriba',
    dateOfBirth: '19.01.2003',
    nationality: 'Guinea',
    height: 1.85,
    foot: 'rechts',
    teamMemberSince: '01.09.2022',
    club: 'FC Valencia',
    previousClub: 'RasenBallsport Leipzig',
    uid: '588922a6-61e9-45bc-ae5b-1545826817db',
    position: 'Zentrales Mittelfeld',
    league: 'LaLiga',
    marketValue: 6000000,
  },
  {
    jerseyNumber: 16,
    name: 'Samuel Lino',
    dateOfBirth: '23.12.1999',
    nationality: 'Brasilien',
    height: 1.78,
    foot: 'rechts',
    teamMemberSince: '28.07.2022',
    club: 'FC Valencia',
    previousClub: 'Atlético Madrid',
    uid: '97314c27-88d2-4e9f-a5dd-fcc277d6e81b',
    position: 'Linksaußen',
    league: 'LaLiga',
    marketValue: 16000000,
  },
  {
    jerseyNumber: 9,
    name: 'Justin Kluivert',
    dateOfBirth: '05.05.1999',
    nationality: 'Niederlande',
    height: 1.72,
    foot: 'beidfüßig',
    teamMemberSince: '01.09.2022',
    club: 'FC Valencia',
    previousClub: 'AS Rom',
    uid: '9edecf4c-35fd-46ae-8a53-7e0ae632b113',
    position: 'Linksaußen',
    league: 'LaLiga',
    marketValue: 12000000,
  },
  {
    jerseyNumber: 11,
    name: 'Samu Castillejo',
    dateOfBirth: '18.01.1995',
    nationality: 'Spanien',
    height: 1.82,
    foot: 'links',
    teamMemberSince: '12.07.2022',
    club: 'FC Valencia',
    previousClub: 'AC Mailand',
    uid: 'f8322514-bea7-4bde-a953-324d6ec0a91f',
    position: 'Rechtsaußen',
    league: 'LaLiga',
    marketValue: 8000000,
  },
  {
    jerseyNumber: 19,
    name: 'Hugo Duro',
    dateOfBirth: '10.11.1999',
    nationality: 'Spanien',
    height: 1.82,
    foot: 'links',
    teamMemberSince: '01.07.2022',
    club: 'FC Valencia',
    previousClub: 'FC Getafe',
    uid: 'e41d3eb2-f313-4ada-bc42-b12bb22b7d7a',
    position: 'Mittelstürmer',
    league: 'LaLiga',
    marketValue: 5000000,
  },
  {
    jerseyNumber: 7,
    name: 'Edinson Cavani',
    dateOfBirth: '14.02.1987',
    nationality: 'Uruguay',
    height: 1.84,
    foot: 'rechts',
    teamMemberSince: '29.08.2022',
    club: 'FC Valencia',
    previousClub: 'Manchester United',
    uid: 'ac99047a-7dc9-43ea-a499-339dae65b8dd',
    position: 'Mittelstürmer',
    league: 'LaLiga',
    marketValue: 4000000,
  },
  {
    jerseyNumber: 22,
    name: 'Marcos André',
    dateOfBirth: '20.10.1996',
    nationality: 'Brasilien',
    height: 1.85,
    foot: 'rechts',
    teamMemberSince: '25.08.2021',
    club: 'FC Valencia',
    previousClub: ': Ablöse 8,50 Mio. €',
    uid: '6e0b114b-75cc-4d6d-806e-ae1c9cc46e52',
    position: 'Mittelstürmer',
    league: 'LaLiga',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 13,
    name: 'Bono',
    dateOfBirth: '05.04.1991',
    nationality: 'Marokko',
    height: 1.92,
    foot: 'links',
    teamMemberSince: '04.09.2020',
    club: 'FC Sevilla',
    previousClub: 'FC Girona',
    uid: '7cd8fcc1-1be2-41c7-bf6f-4ab365480f29',
    position: 'Torwart',
    league: 'LaLiga',
    marketValue: 12000000,
  },
  {
    jerseyNumber: 1,
    name: 'Marko Dmitrović',
    dateOfBirth: '24.01.1992',
    nationality: 'Serbien',
    height: 1.89,
    foot: 'links',
    teamMemberSince: '04.07.2021',
    club: 'FC Sevilla',
    previousClub: 'SD Eibar',
    uid: '698bff52-d4c0-4c06-b2a9-e7afbfd37df4',
    position: 'Torwart',
    league: 'LaLiga',
    marketValue: 4000000,
  },
  {
    jerseyNumber: 22,
    name: 'Loïc Badé',
    dateOfBirth: '11.04.2000',
    nationality: 'Frankreich',
    height: 1.91,
    foot: 'rechts',
    teamMemberSince: '01.01.2023',
    club: 'FC Sevilla',
    previousClub: 'FC Stade Rennes',
    uid: '51202389-9eea-49e5-a6db-421173d2eb0f',
    position: 'Innenverteidiger',
    league: 'LaLiga',
    marketValue: 10000000,
  },
  {
    jerseyNumber: 23,
    name: 'Marcão',
    dateOfBirth: '05.06.1996',
    nationality: 'Brasilien',
    height: 1.85,
    foot: 'links',
    teamMemberSince: '08.07.2022',
    club: 'FC Sevilla',
    previousClub: 'Galatasaray',
    uid: '4d4ef299-0825-43e8-88b9-adb031620147',
    position: 'Innenverteidiger',
    league: 'LaLiga',
    marketValue: 10000000,
  },
  {
    jerseyNumber: 14,
    name: 'Tanguy Nianzou',
    dateOfBirth: '07.06.2002',
    nationality: 'Frankreich',
    height: 1.91,
    foot: 'rechts',
    teamMemberSince: '17.08.2022',
    club: 'FC Sevilla',
    previousClub: 'FC Bayern München',
    uid: '30d8def5-8853-4514-87f3-f68136282a8d',
    position: 'Innenverteidiger',
    league: 'LaLiga',
    marketValue: 9000000,
  },
  {
    jerseyNumber: 4,
    name: 'Karim Rekik',
    dateOfBirth: '02.12.1994',
    nationality: 'Niederlande',
    height: 1.86,
    foot: 'links',
    teamMemberSince: '05.10.2020',
    club: 'FC Sevilla',
    previousClub: 'Hertha BSC',
    uid: 'e0695de8-32c4-4454-8180-e7283348c1d8',
    position: 'Innenverteidiger',
    league: 'LaLiga',
    marketValue: 4000000,
  },
  {
    jerseyNumber: 3,
    name: 'Alex Telles',
    dateOfBirth: '15.12.1992',
    nationality: 'Brasilien',
    height: 1.81,
    foot: 'links',
    teamMemberSince: '04.08.2022',
    club: 'FC Sevilla',
    previousClub: 'Manchester United',
    uid: '80602ebc-f2ff-4c01-884b-ab1b1d922674',
    position: 'Linker Verteidiger',
    league: 'LaLiga',
    marketValue: 12000000,
  },
  {
    jerseyNumber: 19,
    name: 'Marcos Acuña',
    dateOfBirth: '28.10.1991',
    nationality: 'Argentinien',
    height: 1.72,
    foot: 'links',
    teamMemberSince: '14.09.2020',
    club: 'FC Sevilla',
    previousClub: 'Sporting Lissabon',
    uid: '91cfb3f0-01c5-4f11-bf5c-57f7202b0f97',
    position: 'Linker Verteidiger',
    league: 'LaLiga',
    marketValue: 12000000,
  },
  {
    jerseyNumber: 2,
    name: 'Gonzalo Montiel',
    dateOfBirth: '01.01.1997',
    nationality: 'Argentinien',
    height: 1.75,
    foot: 'rechts',
    teamMemberSince: '13.08.2021',
    club: 'FC Sevilla',
    previousClub: ': Ablöse 11,00 Mio. €',
    uid: 'adbb8ebb-4cc9-40ab-aa70-c41ad07720cb',
    position: 'Rechter Verteidiger',
    league: 'LaLiga',
    marketValue: 12000000,
  },
  {
    jerseyNumber: 16,
    name: 'Jesús Navas',
    dateOfBirth: '21.11.1985',
    nationality: 'Spanien',
    height: 1.72,
    foot: 'rechts',
    teamMemberSince: '01.08.2017',
    club: 'FC Sevilla',
    previousClub: 'Manchester City',
    uid: '45046258-bc41-4388-b94a-575d73524168',
    position: 'Rechter Verteidiger',
    league: 'LaLiga',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 18,
    name: 'Pape Gueye',
    dateOfBirth: '24.01.1999',
    nationality: 'Senegal',
    height: 1.89,
    foot: 'links',
    teamMemberSince: '30.01.2023',
    club: 'FC Sevilla',
    previousClub: 'Olympique Marseille',
    uid: 'a2a56322-1eb5-44f1-81a1-d1cbcfb4a578',
    position: 'Defensives Mittelfeld',
    league: 'LaLiga',
    marketValue: 10000000,
  },
  {
    jerseyNumber: 6,
    name: 'Nemanja Gudelj',
    dateOfBirth: '16.11.1991',
    nationality: 'Serbien',
    height: 1.87,
    foot: 'rechts',
    teamMemberSince: '23.07.2019',
    club: 'FC Sevilla',
    previousClub: 'Guangzhou Evergrande Taobao',
    uid: '8dc9ea08-fe8b-4eeb-afc0-754c5849b0ad',
    position: 'Defensives Mittelfeld',
    league: 'LaLiga',
    marketValue: 4000000,
  },
  {
    jerseyNumber: 20,
    name: 'Fernando',
    dateOfBirth: '25.07.1987',
    nationality: 'Brasilien',
    height: 1.83,
    foot: 'rechts',
    teamMemberSince: '12.07.2019',
    club: 'FC Sevilla',
    previousClub: 'Galatasaray',
    uid: 'b440d492-fedf-4b05-b1cc-afccb070a6c8',
    position: 'Defensives Mittelfeld',
    league: 'LaLiga',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 8,
    name: 'Joan Jordán',
    dateOfBirth: '06.07.1994',
    nationality: 'Spanien',
    height: 1.85,
    foot: 'rechts',
    teamMemberSince: '01.07.2019',
    club: 'FC Sevilla',
    previousClub: 'SD Eibar',
    uid: 'd904742b-7759-4439-9aca-080b7da2c993',
    position: 'Zentrales Mittelfeld',
    league: 'LaLiga',
    marketValue: 12000000,
  },
  {
    jerseyNumber: 21,
    name: 'Óliver Torres',
    dateOfBirth: '10.11.1994',
    nationality: 'Spanien',
    height: 1.75,
    foot: 'rechts',
    teamMemberSince: '15.07.2019',
    club: 'FC Sevilla',
    previousClub: 'FC Porto',
    uid: 'c7f045cf-ac33-4b0a-a78f-bd17ee59e54d',
    position: 'Zentrales Mittelfeld',
    league: 'LaLiga',
    marketValue: 9000000,
  },
  {
    jerseyNumber: 10,
    name: 'Ivan Rakitic',
    dateOfBirth: '10.03.1988',
    nationality: 'Kroatien',
    height: 1.84,
    foot: 'rechts',
    teamMemberSince: '01.09.2020',
    club: 'FC Sevilla',
    previousClub: 'FC Barcelona',
    uid: '52206082-75e6-4ce9-88e2-a3588290a944',
    position: 'Zentrales Mittelfeld',
    league: 'LaLiga',
    marketValue: 4000000,
  },
  {
    jerseyNumber: 5,
    name: 'Lucas Ocampos',
    dateOfBirth: '11.07.1994',
    nationality: 'Argentinien',
    height: 1.87,
    foot: 'beidfüßig',
    teamMemberSince: '03.07.2019',
    club: 'FC Sevilla',
    previousClub: 'Olympique Marseille',
    uid: 'cadf3f65-9419-48db-a063-723f71d52703',
    position: 'Linksaußen',
    league: 'LaLiga',
    marketValue: 12000000,
  },
  {
    jerseyNumber: 25,
    name: 'Bryan Gil',
    dateOfBirth: '11.02.2001',
    nationality: 'Spanien',
    height: 1.75,
    foot: 'links',
    teamMemberSince: '30.01.2023',
    club: 'FC Sevilla',
    previousClub: 'Tottenham Hotspur',
    uid: '25eb0456-968a-478f-8662-ec8f26be479c',
    position: 'Linksaußen',
    league: 'LaLiga',
    marketValue: 12000000,
  },
  {
    jerseyNumber: 24,
    name: 'Papu Gómez',
    dateOfBirth: '15.02.1988',
    nationality: 'Argentinien',
    height: 1.67,
    foot: 'rechts',
    teamMemberSince: '26.01.2021',
    club: 'FC Sevilla',
    previousClub: 'Atalanta Bergamo',
    uid: 'cd603780-2456-45f3-8d3f-4df8f713b3d7',
    position: 'Linksaußen',
    league: 'LaLiga',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 9,
    name: 'Tecatito',
    dateOfBirth: '06.01.1993',
    nationality: 'Mexiko',
    height: 1.73,
    foot: 'beidfüßig',
    teamMemberSince: '14.01.2022',
    club: 'FC Sevilla',
    previousClub: 'FC Porto',
    uid: '97f76ea0-ec97-4195-8b3f-a85b1433b62b',
    position: 'Rechtsaußen',
    league: 'LaLiga',
    marketValue: 10000000,
  },
  {
    jerseyNumber: 17,
    name: 'Erik Lamela',
    dateOfBirth: '04.03.1992',
    nationality: 'Argentinien',
    height: 1.84,
    foot: 'links',
    teamMemberSince: '26.07.2021',
    club: 'FC Sevilla',
    previousClub: 'Tottenham Hotspur',
    uid: '16218c77-89ee-4ea4-87ba-0f0b269ceb19',
    position: 'Rechtsaußen',
    league: 'LaLiga',
    marketValue: 10000000,
  },
  {
    jerseyNumber: 7,
    name: 'Suso',
    dateOfBirth: '19.11.1993',
    nationality: 'Spanien',
    height: 1.77,
    foot: 'links',
    teamMemberSince: '21.07.2020',
    club: 'FC Sevilla',
    previousClub: 'AC Mailand',
    uid: '4482d80a-f888-44ee-a3ab-28ce56a6d2bf',
    position: 'Rechtsaußen',
    league: 'LaLiga',
    marketValue: 7000000,
  },
  {
    jerseyNumber: 15,
    name: 'Youssef En-Nesyri',
    dateOfBirth: '01.06.1997',
    nationality: 'Marokko',
    height: 1.92,
    foot: 'links',
    teamMemberSince: '16.01.2020',
    club: 'FC Sevilla',
    previousClub: 'CD Leganés',
    uid: '2b02d3be-e473-42ed-9bbc-9c7b0b3bea52',
    position: 'Mittelstürmer',
    league: 'LaLiga',
    marketValue: 15000000,
  },
  {
    jerseyNumber: 12,
    name: 'Rafa Mir',
    dateOfBirth: '18.06.1997',
    nationality: 'Spanien',
    height: 1.91,
    foot: 'rechts',
    teamMemberSince: '20.08.2021',
    club: 'FC Sevilla',
    previousClub: 'Wolverhampton Wanderers',
    uid: '0660f514-f718-40d2-8c9a-4beeeb5fc461',
    position: 'Mittelstürmer',
    league: 'LaLiga',
    marketValue: 10000000,
  },
  {
    jerseyNumber: 1,
    name: 'Agustín Marchesín',
    dateOfBirth: '16.03.1988',
    nationality: 'Argentinien',
    height: 1.88,
    foot: 'rechts',
    teamMemberSince: '01.08.2022',
    club: 'Celta Vigo',
    previousClub: 'FC Porto',
    uid: 'afe01a85-0a58-460b-ba37-d2ad4dbee999',
    position: 'Torwart',
    league: 'LaLiga',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 13,
    name: 'Iván Villar',
    dateOfBirth: '09.07.1997',
    nationality: 'Spanien',
    height: 1.86,
    foot: 'rechts',
    teamMemberSince: '01.07.2019',
    club: 'Celta Vigo',
    previousClub: 'Celta Vigo B',
    uid: '8f2cb87d-48ea-4ff2-ad4a-ca2cfe2dddd2',
    position: 'Torwart',
    league: 'LaLiga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 15,
    name: 'Joseph Aidoo',
    dateOfBirth: '29.09.1995',
    nationality: 'Ghana',
    height: 1.77,
    foot: 'rechts',
    teamMemberSince: '11.07.2019',
    club: 'Celta Vigo',
    previousClub: 'KRC Genk',
    uid: 'def8dd3e-5bbb-4984-9a1d-95f5eef18b38',
    position: 'Innenverteidiger',
    league: 'LaLiga',
    marketValue: 8000000,
  },
  {
    jerseyNumber: 4,
    name: 'Unai Núñez',
    dateOfBirth: '30.01.1997',
    nationality: 'Spanien',
    height: 1.86,
    foot: 'rechts',
    teamMemberSince: '20.07.2022',
    club: 'Celta Vigo',
    previousClub: 'Athletic Bilbao',
    uid: 'c8006fcb-23e4-4b9f-99c3-d9ad79862330',
    position: 'Innenverteidiger',
    league: 'LaLiga',
    marketValue: 6000000,
  },
  {
    jerseyNumber: 3,
    name: 'Óscar Mingueza',
    dateOfBirth: '13.05.1999',
    nationality: 'Spanien',
    height: 1.84,
    foot: 'rechts',
    teamMemberSince: '30.07.2022',
    club: 'Celta Vigo',
    previousClub: 'FC Barcelona',
    uid: '2410f993-c775-4805-8e99-1c90f7552bb8',
    position: 'Innenverteidiger',
    league: 'LaLiga',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 17,
    name: 'Javi Galán',
    dateOfBirth: '19.11.1994',
    nationality: 'Spanien',
    height: 1.72,
    foot: 'links',
    teamMemberSince: '31.07.2021',
    club: 'Celta Vigo',
    previousClub: 'SD Huesca',
    uid: '2ce15f4a-3ade-4d18-bf13-595a3ecc6ea1',
    position: 'Linker Verteidiger',
    league: 'LaLiga',
    marketValue: 12000000,
  },
  {
    jerseyNumber: 2,
    name: 'Hugo Mallo',
    dateOfBirth: '22.06.1991',
    nationality: 'Spanien',
    height: 1.74,
    foot: 'rechts',
    teamMemberSince: '01.07.2009',
    club: 'Celta Vigo',
    previousClub: 'Celta Vigo U19',
    uid: '46eebbb7-5b0b-4259-8836-7440f8a14957',
    position: 'Rechter Verteidiger',
    league: 'LaLiga',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 20,
    name: 'Kevin Vázquez',
    dateOfBirth: '23.03.1993',
    nationality: 'Spanien',
    height: 1.75,
    foot: 'rechts',
    teamMemberSince: '01.07.2018',
    club: 'Celta Vigo',
    previousClub: 'Celta Vigo B',
    uid: '3dbd1202-5c4f-45a2-b764-eff1b44a3321',
    position: 'Rechter Verteidiger',
    league: 'LaLiga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 14,
    name: 'Renato Tapia',
    dateOfBirth: '28.07.1995',
    nationality: 'Peru',
    height: 1.85,
    foot: 'rechts',
    teamMemberSince: '04.08.2020',
    club: 'Celta Vigo',
    previousClub: 'Feyenoord Rotterdam',
    uid: '2a9fe571-5fc1-46f1-9835-e9262f3f5c79',
    position: 'Defensives Mittelfeld',
    league: 'LaLiga',
    marketValue: 7000000,
  },
  {
    jerseyNumber: 8,
    name: 'Fran Beltrán',
    dateOfBirth: '03.02.1999',
    nationality: 'Spanien',
    height: 1.65,
    foot: 'rechts',
    teamMemberSince: '01.08.2018',
    club: 'Celta Vigo',
    previousClub: ': Ablöse 8,00 Mio. €',
    uid: '9e47675f-169e-4f63-8b97-a13d7caad7f1',
    position: 'Zentrales Mittelfeld',
    league: 'LaLiga',
    marketValue: 15000000,
  },
  {
    jerseyNumber: 23,
    name: 'Luca de la Torre',
    dateOfBirth: '23.05.1998',
    nationality: 'Vereinigte Staaten',
    height: 1.77,
    foot: 'rechts',
    teamMemberSince: '08.07.2022',
    club: 'Celta Vigo',
    previousClub: 'Heracles Almelo',
    uid: 'ec375aaa-1b5d-44ae-a8e2-4d1dc609de9e',
    position: 'Zentrales Mittelfeld',
    league: 'LaLiga',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 24,
    name: 'Gabri Veiga',
    dateOfBirth: '27.05.2002',
    nationality: 'Spanien',
    height: 1.85,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'Celta Vigo',
    previousClub: 'Celta Vigo B',
    uid: '08eb9b22-10ab-4b9f-83b8-e75ebfa0d75c',
    position: 'Offensives Mittelfeld',
    league: 'LaLiga',
    marketValue: 30000000,
  },
  {
    jerseyNumber: 5,
    name: 'Óscar Rodríguez',
    dateOfBirth: '28.06.1998',
    nationality: 'Spanien',
    height: 1.74,
    foot: 'rechts',
    teamMemberSince: '08.07.2022',
    club: 'Celta Vigo',
    previousClub: 'FC Sevilla',
    uid: '7266f866-6c0b-4903-8fea-422141e2d86e',
    position: 'Offensives Mittelfeld',
    league: 'LaLiga',
    marketValue: 10000000,
  },
  {
    jerseyNumber: 19,
    name: 'Williot Swedberg',
    dateOfBirth: '01.02.2004',
    nationality: 'Schweden',
    height: 1.87,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'Celta Vigo',
    previousClub: 'Hammarby IF',
    uid: '2d636484-1ede-41ac-b6a6-545be02300b3',
    position: 'Offensives Mittelfeld',
    league: 'LaLiga',
    marketValue: 4000000,
  },
  {
    jerseyNumber: 11,
    name: 'Franco Cervi',
    dateOfBirth: '26.05.1994',
    nationality: 'Argentinien',
    height: 1.66,
    foot: 'links',
    teamMemberSince: '05.07.2021',
    club: 'Celta Vigo',
    previousClub: 'Benfica Lissabon',
    uid: '6ac67ec0-4cc9-41ce-bb44-b40d1be1b2df',
    position: 'Linksaußen',
    league: 'LaLiga',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 7,
    name: 'Carles Pérez',
    dateOfBirth: '16.02.1998',
    nationality: 'Spanien',
    height: 1.73,
    foot: 'links',
    teamMemberSince: '09.08.2022',
    club: 'Celta Vigo',
    previousClub: 'AS Rom',
    uid: '4ff9fb18-d237-4faf-b482-42118e6136d8',
    position: 'Rechtsaußen',
    league: 'LaLiga',
    marketValue: 5000000,
  },
  {
    jerseyNumber: 21,
    name: 'Augusto Solari',
    dateOfBirth: '03.01.1992',
    nationality: 'Argentinien',
    height: 1.76,
    foot: 'rechts',
    teamMemberSince: '23.01.2021',
    club: 'Celta Vigo',
    previousClub: 'Racing Club',
    uid: '82bbd9af-6ac9-4029-8150-9d1ce6f1a300',
    position: 'Rechtsaußen',
    league: 'LaLiga',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 18,
    name: 'Jörgen Strand Larsen',
    dateOfBirth: '06.02.2000',
    nationality: 'Norwegen',
    height: 1.93,
    foot: 'rechts',
    teamMemberSince: '01.09.2022',
    club: 'Celta Vigo',
    previousClub: 'FC Groningen',
    uid: 'db766d3b-16ce-4596-a6a0-dff6bd8343f6',
    position: 'Mittelstürmer',
    league: 'LaLiga',
    marketValue: 11000000,
  },
  {
    jerseyNumber: 10,
    name: 'Iago Aspas',
    dateOfBirth: '01.08.1987',
    nationality: 'Spanien',
    height: 1.76,
    foot: 'links',
    teamMemberSince: '07.07.2015',
    club: 'Celta Vigo',
    previousClub: 'FC Sevilla',
    uid: '143bb7dd-78fa-4d85-b06f-2d107df2b514',
    position: 'Mittelstürmer',
    league: 'LaLiga',
    marketValue: 7000000,
  },
  {
    jerseyNumber: 22,
    name: 'Haris Seferovic',
    dateOfBirth: '22.02.1992',
    nationality: 'Schweiz',
    height: 1.89,
    foot: 'links',
    teamMemberSince: '31.01.2023',
    club: 'Celta Vigo',
    previousClub: 'Benfica Lissabon',
    uid: 'fd2366d0-6a6d-4ee1-ac52-5d3b58fa13cf',
    position: 'Mittelstürmer',
    league: 'LaLiga',
    marketValue: 6000000,
  },
  {
    jerseyNumber: 9,
    name: 'Gonçalo Paciência',
    dateOfBirth: '01.08.1994',
    nationality: 'Portugal',
    height: 1.84,
    foot: 'rechts',
    teamMemberSince: '06.08.2022',
    club: 'Celta Vigo',
    previousClub: 'Eintracht Frankfurt',
    uid: 'aefb43aa-09fd-41bf-a2cd-a740223f7457',
    position: 'Mittelstürmer',
    league: 'LaLiga',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 13,
    name: 'David Soria',
    dateOfBirth: '04.04.1993',
    nationality: 'Spanien',
    height: 1.92,
    foot: 'rechts',
    teamMemberSince: '13.07.2018',
    club: 'FC Getafe',
    previousClub: 'FC Sevilla',
    uid: '63d9c8b0-3663-40aa-90a3-cc26014f1ba6',
    position: 'Torwart',
    league: 'LaLiga',
    marketValue: 10000000,
  },
  {
    jerseyNumber: 1,
    name: 'Kiko Casilla',
    dateOfBirth: '02.10.1986',
    nationality: 'Spanien',
    height: 1.9,
    foot: 'rechts',
    teamMemberSince: '10.08.2022',
    club: 'FC Getafe',
    previousClub: 'Leeds United',
    uid: 'f3cf7a76-9126-4c71-965c-f3e9d579c578',
    position: 'Torwart',
    league: 'LaLiga',
    marketValue: 400000,
  },
  {
    jerseyNumber: 6,
    name: 'Domingos Duarte',
    dateOfBirth: '10.03.1995',
    nationality: 'Portugal',
    height: 1.92,
    foot: 'rechts',
    teamMemberSince: '11.07.2022',
    club: 'FC Getafe',
    previousClub: 'FC Granada',
    uid: 'ba2f2137-a626-450d-9601-16c3ebf21ca3',
    position: 'Innenverteidiger',
    league: 'LaLiga',
    marketValue: 8000000,
  },
  {
    jerseyNumber: 2,
    name: 'Djené',
    dateOfBirth: '31.12.1991',
    nationality: 'Togo',
    height: 1.78,
    foot: 'rechts',
    teamMemberSince: '24.07.2017',
    club: 'FC Getafe',
    previousClub: 'VV St. Truiden',
    uid: '98fe10c3-beb5-43b8-8873-7a202e001125',
    position: 'Innenverteidiger',
    league: 'LaLiga',
    marketValue: 6000000,
  },
  {
    jerseyNumber: 4,
    name: 'Gastón Álvarez',
    dateOfBirth: '24.03.2000',
    nationality: 'Uruguay',
    height: 1.84,
    foot: 'links',
    teamMemberSince: '28.01.2022',
    club: 'FC Getafe',
    previousClub: 'CA Boston River',
    uid: '9025b2de-e496-4642-b624-16ec2ef5a99f',
    position: 'Innenverteidiger',
    league: 'LaLiga',
    marketValue: 5000000,
  },
  {
    jerseyNumber: 15,
    name: 'Omar Alderete',
    dateOfBirth: '26.12.1996',
    nationality: 'Paraguay',
    height: 1.88,
    foot: 'links',
    teamMemberSince: '19.08.2022',
    club: 'FC Getafe',
    previousClub: 'Hertha BSC',
    uid: 'ef677618-6662-4827-be60-107c357478e8',
    position: 'Innenverteidiger',
    league: 'LaLiga',
    marketValue: 4000000,
  },
  {
    jerseyNumber: 23,
    name: 'Stefan Mitrovic',
    dateOfBirth: '22.05.1990',
    nationality: 'Serbien',
    height: 1.89,
    foot: 'beidfüßig',
    teamMemberSince: '06.07.2021',
    club: 'FC Getafe',
    previousClub: 'RC Straßburg Alsace',
    uid: 'b0c501e8-eba3-4529-ae0f-4decee79a30b',
    position: 'Innenverteidiger',
    league: 'LaLiga',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 3,
    name: 'Fabrizio Angileri',
    dateOfBirth: '15.03.1994',
    nationality: 'Argentinien',
    height: 1.85,
    foot: 'links',
    teamMemberSince: '14.07.2022',
    club: 'FC Getafe',
    previousClub: 'CA River Plate',
    uid: '690d3971-37a7-41c1-9333-85ea9ca63ce1',
    position: 'Linker Verteidiger',
    league: 'LaLiga',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 12,
    name: 'Jordan Amavi',
    dateOfBirth: '09.03.1994',
    nationality: 'Frankreich',
    height: 1.76,
    foot: 'links',
    teamMemberSince: '01.09.2022',
    club: 'FC Getafe',
    previousClub: 'Olympique Marseille',
    uid: '3443dcea-c747-416b-b93f-e94ff0e77473',
    position: 'Linker Verteidiger',
    league: 'LaLiga',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 21,
    name: 'Juan Iglesias',
    dateOfBirth: '03.07.1998',
    nationality: 'Spanien',
    height: 1.87,
    foot: 'rechts',
    teamMemberSince: '01.07.2021',
    club: 'FC Getafe',
    previousClub: 'FC Getafe B',
    uid: 'a459ead5-df72-4811-b60a-5976c9eda51c',
    position: 'Rechter Verteidiger',
    league: 'LaLiga',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 22,
    name: 'Damián Suárez',
    dateOfBirth: '27.04.1988',
    nationality: 'Uruguay',
    height: 1.73,
    foot: 'rechts',
    teamMemberSince: '08.07.2015',
    club: 'FC Getafe',
    previousClub: 'FC Elche',
    uid: '4619af0e-905a-4c4c-a2d9-d2538df4e752',
    position: 'Rechter Verteidiger',
    league: 'LaLiga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 16,
    name: 'Ángel Algobia',
    dateOfBirth: '23.06.1999',
    nationality: 'Spanien',
    height: 1.88,
    foot: 'rechts',
    teamMemberSince: '11.08.2022',
    club: 'FC Getafe',
    previousClub: 'FC Getafe B',
    uid: '85c753f6-73ba-486c-990e-e7ab6b2d552f',
    position: 'Defensives Mittelfeld',
    league: 'LaLiga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 18,
    name: 'Mauro Arambarri',
    dateOfBirth: '30.09.1995',
    nationality: 'Uruguay',
    height: 1.75,
    foot: 'rechts',
    teamMemberSince: '01.07.2018',
    club: 'FC Getafe',
    previousClub: 'CA Boston River',
    uid: 'aad26915-b8c4-46b2-9cfd-9d9725b73024',
    position: 'Zentrales Mittelfeld',
    league: 'LaLiga',
    marketValue: 13000000,
  },
  {
    jerseyNumber: 11,
    name: 'Carles Aleñá',
    dateOfBirth: '05.01.1998',
    nationality: 'Spanien',
    height: 1.8,
    foot: 'links',
    teamMemberSince: '10.07.2021',
    club: 'FC Getafe',
    previousClub: 'FC Barcelona',
    uid: '6510bcf6-337a-4fdb-899a-a6e0319298ab',
    position: 'Zentrales Mittelfeld',
    league: 'LaLiga',
    marketValue: 10000000,
  },
  {
    jerseyNumber: 20,
    name: 'Nemanja Maksimovic',
    dateOfBirth: '26.01.1995',
    nationality: 'Serbien',
    height: 1.84,
    foot: 'rechts',
    teamMemberSince: '16.07.2018',
    club: 'FC Getafe',
    previousClub: 'FC Valencia',
    uid: '23898b4c-977a-4e67-84d6-5bdd4b01e1c6',
    position: 'Zentrales Mittelfeld',
    league: 'LaLiga',
    marketValue: 8000000,
  },
  {
    jerseyNumber: 5,
    name: 'Luis Milla',
    dateOfBirth: '07.10.1994',
    nationality: 'Spanien',
    height: 1.75,
    foot: 'rechts',
    teamMemberSince: '25.07.2022',
    club: 'FC Getafe',
    previousClub: 'FC Granada',
    uid: 'ec974dcb-b62f-4cf6-9b1b-66d57aeab25d',
    position: 'Zentrales Mittelfeld',
    league: 'LaLiga',
    marketValue: 5000000,
  },
  {
    jerseyNumber: 24,
    name: 'Gonzalo Villar',
    dateOfBirth: '23.03.1998',
    nationality: 'Spanien',
    height: 1.81,
    foot: 'rechts',
    teamMemberSince: '13.01.2023',
    club: 'FC Getafe',
    previousClub: 'AS Rom',
    uid: '36ab632a-b89e-4c66-b360-db276fbbc26d',
    position: 'Zentrales Mittelfeld',
    league: 'LaLiga',
    marketValue: 4000000,
  },
  {
    jerseyNumber: 8,
    name: 'Jaime Seoane',
    dateOfBirth: '22.01.1997',
    nationality: 'Spanien',
    height: 1.77,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'FC Getafe',
    previousClub: 'SD Huesca',
    uid: '72a0cbfb-27da-41a7-aae2-6ff43b7c4671',
    position: 'Zentrales Mittelfeld',
    league: 'LaLiga',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 9,
    name: 'Portu',
    dateOfBirth: '21.05.1992',
    nationality: 'Spanien',
    height: 1.67,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'FC Getafe',
    previousClub: 'Real Sociedad San Sebastián',
    uid: '1849b42c-3cab-47ba-a23b-61ae72810571',
    position: 'Rechtsaußen',
    league: 'LaLiga',
    marketValue: 4000000,
  },
  {
    jerseyNumber: 10,
    name: 'Enes Ünal',
    dateOfBirth: '10.05.1997',
    nationality: 'Türkei',
    height: 1.87,
    foot: 'rechts',
    teamMemberSince: '12.08.2020',
    club: 'FC Getafe',
    previousClub: 'FC Villarreal',
    uid: '42064510-9639-4b37-ab1a-b0f958918781',
    position: 'Mittelstürmer',
    league: 'LaLiga',
    marketValue: 25000000,
  },
  {
    jerseyNumber: 19,
    name: 'Borja Mayoral',
    dateOfBirth: '05.04.1997',
    nationality: 'Spanien',
    height: 1.82,
    foot: 'rechts',
    teamMemberSince: '01.08.2022',
    club: 'FC Getafe',
    previousClub: 'Real Madrid',
    uid: 'c94a9ecf-887b-4151-9bea-20f607a209e5',
    position: 'Mittelstürmer',
    league: 'LaLiga',
    marketValue: 9000000,
  },
  {
    jerseyNumber: 17,
    name: 'Munir El Haddadi',
    dateOfBirth: '01.09.1995',
    nationality: 'Marokko',
    height: 1.75,
    foot: 'links',
    teamMemberSince: '31.08.2022',
    club: 'FC Getafe',
    previousClub: 'FC Sevilla',
    uid: '155f8833-5eb9-41a8-9b81-22118950aeee',
    position: 'Mittelstürmer',
    league: 'LaLiga',
    marketValue: 4000000,
  },
  {
    jerseyNumber: 7,
    name: 'Jaime Mata',
    dateOfBirth: '24.10.1988',
    nationality: 'Spanien',
    height: 1.86,
    foot: 'rechts',
    teamMemberSince: '01.07.2018',
    club: 'FC Getafe',
    previousClub: ': Ablöse ablösefrei',
    uid: '2a2d0a18-d35d-4547-91aa-1d280a84b82e',
    position: 'Mittelstürmer',
    league: 'LaLiga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 14,
    name: 'Juanmi Latasa',
    dateOfBirth: '23.03.2001',
    nationality: 'Spanien',
    height: 1.92,
    foot: 'links',
    teamMemberSince: '26.08.2022',
    club: 'FC Getafe',
    previousClub: 'Real Madrid Castilla',
    uid: 'e33b1cb4-f9dc-4d26-9006-e54bd268e2d1',
    position: 'Mittelstürmer',
    league: 'LaLiga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 1,
    name: 'Sergio Herrera',
    dateOfBirth: '05.06.1993',
    nationality: 'Spanien',
    height: 1.92,
    foot: 'rechts',
    teamMemberSince: '12.07.2017',
    club: 'CA Osasuna',
    previousClub: 'SD Huesca',
    uid: 'ccc16da2-5ccc-49d2-970c-e3ef84ae703d',
    position: 'Torwart',
    league: 'LaLiga',
    marketValue: 8000000,
  },
  {
    jerseyNumber: 25,
    name: 'Aitor Fernández',
    dateOfBirth: '03.05.1991',
    nationality: 'Spanien',
    height: 1.82,
    foot: 'rechts',
    teamMemberSince: '02.07.2022',
    club: 'CA Osasuna',
    previousClub: 'UD Levante',
    uid: '6482f617-bcc6-4128-8ca6-a9d54b4923b2',
    position: 'Torwart',
    league: 'LaLiga',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 5,
    name: 'David García',
    dateOfBirth: '14.02.1994',
    nationality: 'Spanien',
    height: 1.86,
    foot: 'beidfüßig',
    teamMemberSince: '01.01.2015',
    club: 'CA Osasuna',
    previousClub: 'CA Osasuna B',
    uid: 'dd74a773-063e-4ccc-b604-377c13d9cba2',
    position: 'Innenverteidiger',
    league: 'LaLiga',
    marketValue: 20000000,
  },
  {
    jerseyNumber: 4,
    name: 'Unai García',
    dateOfBirth: '03.02.1992',
    nationality: 'Spanien',
    height: 1.86,
    foot: 'rechts',
    teamMemberSince: '01.07.2015',
    club: 'CA Osasuna',
    previousClub: 'CA Osasuna B',
    uid: '7d09cf77-18f3-4e34-9e7b-fcca2deeb1b2',
    position: 'Innenverteidiger',
    league: 'LaLiga',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 23,
    name: 'Aridane Hernández',
    dateOfBirth: '23.03.1989',
    nationality: 'Spanien',
    height: 1.88,
    foot: 'rechts',
    teamMemberSince: '17.07.2017',
    club: 'CA Osasuna',
    previousClub: 'FC Cádiz',
    uid: '31b8fe47-0cd1-4ec5-aa3c-2bf1555921c8',
    position: 'Innenverteidiger',
    league: 'LaLiga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 20,
    name: 'Manu Sánchez',
    dateOfBirth: '24.08.2000',
    nationality: 'Spanien',
    height: 1.71,
    foot: 'links',
    teamMemberSince: '27.07.2022',
    club: 'CA Osasuna',
    previousClub: 'Atlético Madrid',
    uid: '4190e434-2c77-4ba8-bb03-baad11bd800f',
    position: 'Linker Verteidiger',
    league: 'LaLiga',
    marketValue: 10000000,
  },
  {
    jerseyNumber: 3,
    name: 'Juan Cruz',
    dateOfBirth: '28.07.1992',
    nationality: 'Spanien',
    height: 1.82,
    foot: 'links',
    teamMemberSince: '30.08.2020',
    club: 'CA Osasuna',
    previousClub: 'FC Elche',
    uid: '15e6493a-543c-4589-b025-b4b35a0c435b',
    position: 'Linker Verteidiger',
    league: 'LaLiga',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 2,
    name: 'Nacho Vidal',
    dateOfBirth: '24.01.1995',
    nationality: 'Spanien',
    height: 1.8,
    foot: 'rechts',
    teamMemberSince: '13.07.2018',
    club: 'CA Osasuna',
    previousClub: 'FC Valencia',
    uid: '96b50476-2264-406a-ad84-f262387bb8c1',
    position: 'Rechter Verteidiger',
    league: 'LaLiga',
    marketValue: 5000000,
  },
  {
    jerseyNumber: 15,
    name: 'Rubén Peña',
    dateOfBirth: '18.07.1991',
    nationality: 'Spanien',
    height: 1.7,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'CA Osasuna',
    previousClub: 'FC Villarreal',
    uid: '63717b48-f092-4252-a514-aa479a0c4a21',
    position: 'Rechter Verteidiger',
    league: 'LaLiga',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 6,
    name: 'Lucas Torró',
    dateOfBirth: '19.07.1994',
    nationality: 'Spanien',
    height: 1.9,
    foot: 'rechts',
    teamMemberSince: '04.08.2020',
    club: 'CA Osasuna',
    previousClub: 'Eintracht Frankfurt',
    uid: '9ac4df95-978c-4e4d-99d3-cec5f57b617a',
    position: 'Defensives Mittelfeld',
    league: 'LaLiga',
    marketValue: 7000000,
  },
  {
    jerseyNumber: 7,
    name: 'Jon Moncayola',
    dateOfBirth: '13.05.1998',
    nationality: 'Spanien',
    height: 1.82,
    foot: 'rechts',
    teamMemberSince: '01.07.2019',
    club: 'CA Osasuna',
    previousClub: 'CA Osasuna B',
    uid: '0b637610-1a30-454b-8e33-8c60c0d607f4',
    position: 'Zentrales Mittelfeld',
    league: 'LaLiga',
    marketValue: 12000000,
  },
  {
    jerseyNumber: 8,
    name: 'Darko Brasanac',
    dateOfBirth: '12.02.1992',
    nationality: 'Serbien',
    height: 1.78,
    foot: 'rechts',
    teamMemberSince: '22.07.2019',
    club: 'CA Osasuna',
    previousClub: ': Ablöse 1,00 Mio. €',
    uid: '7c809e38-d7ee-477b-8b0c-f940783abfad',
    position: 'Zentrales Mittelfeld',
    league: 'LaLiga',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 19,
    name: 'Pablo Ibáñez',
    dateOfBirth: '20.09.1998',
    nationality: 'Spanien',
    height: 1.79,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'CA Osasuna',
    previousClub: 'CA Osasuna B',
    uid: '5aab7646-896b-4238-bb68-e3c97f14664b',
    position: 'Zentrales Mittelfeld',
    league: 'LaLiga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 22,
    name: 'Aimar Oroz',
    dateOfBirth: '27.11.2001',
    nationality: 'Spanien',
    height: 1.77,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'CA Osasuna',
    previousClub: 'CA Osasuna B',
    uid: '277d9682-a17b-4d6b-a59b-c30cd4088cfd',
    position: 'Offensives Mittelfeld',
    league: 'LaLiga',
    marketValue: 6000000,
  },
  {
    jerseyNumber: 16,
    name: 'Moi Gómez',
    dateOfBirth: '23.06.1994',
    nationality: 'Spanien',
    height: 1.76,
    foot: 'rechts',
    teamMemberSince: '28.07.2022',
    club: 'CA Osasuna',
    previousClub: 'FC Villarreal',
    uid: '47329c7b-631d-4cc5-b71e-394ffa7e7a77',
    position: 'Linksaußen',
    league: 'LaLiga',
    marketValue: 10000000,
  },
  {
    jerseyNumber: 12,
    name: 'Abde Ezzalzouli',
    dateOfBirth: '17.12.2001',
    nationality: 'Marokko',
    height: 1.77,
    foot: 'rechts',
    teamMemberSince: '01.09.2022',
    club: 'CA Osasuna',
    previousClub: 'FC Barcelona',
    uid: 'cff44392-fa91-482e-89e4-9b70305e878c',
    position: 'Linksaußen',
    league: 'LaLiga',
    marketValue: 8000000,
  },
  {
    jerseyNumber: 14,
    name: 'Rubén García',
    dateOfBirth: '14.07.1993',
    nationality: 'Spanien',
    height: 1.71,
    foot: 'links',
    teamMemberSince: '01.07.2019',
    club: 'CA Osasuna',
    previousClub: 'UD Levante',
    uid: '03af87bf-2182-4fdb-bad4-ed011e2d51e0',
    position: 'Linksaußen',
    league: 'LaLiga',
    marketValue: 6000000,
  },
  {
    jerseyNumber: 11,
    name: 'Kike Barja',
    dateOfBirth: '01.04.1997',
    nationality: 'Spanien',
    height: 1.79,
    foot: 'rechts',
    teamMemberSince: '01.01.2018',
    club: 'CA Osasuna',
    previousClub: 'CA Osasuna B',
    uid: '79841a2d-1e2d-489f-b694-b2ba13c9a1d0',
    position: 'Rechtsaußen',
    league: 'LaLiga',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 9,
    name: 'Chimy Ávila',
    dateOfBirth: '06.02.1994',
    nationality: 'Argentinien',
    height: 1.72,
    foot: 'rechts',
    teamMemberSince: '01.07.2019',
    club: 'CA Osasuna',
    previousClub: 'CA San Lorenzo de Almagro',
    uid: '837dcc4e-2c9a-4dc0-a1e6-cb246e9c929d',
    position: 'Mittelstürmer',
    league: 'LaLiga',
    marketValue: 10000000,
  },
  {
    jerseyNumber: 17,
    name: 'Ante Budimir',
    dateOfBirth: '22.07.1991',
    nationality: 'Kroatien',
    height: 1.9,
    foot: 'links',
    teamMemberSince: '01.07.2021',
    club: 'CA Osasuna',
    previousClub: 'RCD Mallorca',
    uid: '85272568-fcbf-4cb6-beea-c1f9b578a1e1',
    position: 'Mittelstürmer',
    league: 'LaLiga',
    marketValue: 6000000,
  },
  {
    jerseyNumber: 18,
    name: 'Kike García',
    dateOfBirth: '25.11.1989',
    nationality: 'Spanien',
    height: 1.86,
    foot: 'rechts',
    teamMemberSince: '01.07.2021',
    club: 'CA Osasuna',
    previousClub: 'SD Eibar',
    uid: '1c939ebd-7ed0-4230-a4e2-cfce65586be7',
    position: 'Mittelstürmer',
    league: 'LaLiga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 13,
    name: 'Paulo Gazzaniga',
    dateOfBirth: '02.01.1992',
    nationality: 'Argentinien',
    height: 1.95,
    foot: 'rechts',
    teamMemberSince: '01.09.2022',
    club: 'FC Girona',
    previousClub: 'FC Fulham',
    uid: 'df1dc81f-e9f1-49bc-bee7-169fa23aa428',
    position: 'Torwart',
    league: 'LaLiga',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 26,
    name: 'Toni Fuidias',
    dateOfBirth: '15.04.2001',
    nationality: 'Spanien',
    height: 1.95,
    foot: 'rechts',
    teamMemberSince: '01.09.2022',
    club: 'FC Girona',
    previousClub: 'Real Madrid Castilla',
    uid: '70746907-eb90-46b8-b13e-9d5dde8e8ab5',
    position: 'Torwart',
    league: 'LaLiga',
    marketValue: 300000,
  },
  {
    jerseyNumber: 22,
    name: 'Santiago Bueno',
    dateOfBirth: '09.11.1998',
    nationality: 'Uruguay',
    height: 1.92,
    foot: 'rechts',
    teamMemberSince: '19.08.2019',
    club: 'FC Girona',
    previousClub: 'FC Barcelona B',
    uid: '2843499d-7642-42e7-ab59-359b71b414ff',
    position: 'Innenverteidiger',
    league: 'LaLiga',
    marketValue: 8000000,
  },
  {
    jerseyNumber: 5,
    name: 'David López',
    dateOfBirth: '09.10.1989',
    nationality: 'Spanien',
    height: 1.85,
    foot: 'rechts',
    teamMemberSince: '25.07.2022',
    club: 'FC Girona',
    previousClub: 'Espanyol Barcelona',
    uid: 'fe5d5448-ca39-4583-ad25-e61fa856858c',
    position: 'Innenverteidiger',
    league: 'LaLiga',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 25,
    name: 'Alexander Callens',
    dateOfBirth: '04.05.1992',
    nationality: 'Peru',
    height: 1.85,
    foot: 'links',
    teamMemberSince: '23.01.2023',
    club: 'FC Girona',
    previousClub: 'New York City FC',
    uid: 'b2918cfd-01a9-421b-bbf8-c90429dcebec',
    position: 'Innenverteidiger',
    league: 'LaLiga',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 2,
    name: 'Bernardo Espinosa',
    dateOfBirth: '11.07.1989',
    nationality: 'Kolumbien',
    height: 1.92,
    foot: 'rechts',
    teamMemberSince: '07.07.2017',
    club: 'FC Girona',
    previousClub: 'FC Middlesbrough',
    uid: 'b9aef8dd-faaa-4368-830e-c29e20c2e15c',
    position: 'Innenverteidiger',
    league: 'LaLiga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 15,
    name: 'Juanpe',
    dateOfBirth: '30.04.1991',
    nationality: 'Spanien',
    height: 1.9,
    foot: 'rechts',
    teamMemberSince: '07.07.2016',
    club: 'FC Girona',
    previousClub: 'FC Granada',
    uid: '391294eb-bc9f-4b6e-8608-0c00170bb54a',
    position: 'Innenverteidiger',
    league: 'LaLiga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 3,
    name: 'Miguel Gutiérrez',
    dateOfBirth: '27.07.2001',
    nationality: 'Spanien',
    height: 1.8,
    foot: 'links',
    teamMemberSince: '05.08.2022',
    club: 'FC Girona',
    previousClub: 'Real Madrid',
    uid: '0b2c5c26-83e4-40bf-a65a-6d3db83feb18',
    position: 'Linker Verteidiger',
    league: 'LaLiga',
    marketValue: 9000000,
  },
  {
    jerseyNumber: 16,
    name: 'Javi Hernández',
    dateOfBirth: '02.05.1998',
    nationality: 'Spanien',
    height: 1.85,
    foot: 'links',
    teamMemberSince: '19.08.2022',
    club: 'FC Girona',
    previousClub: 'CD Leganés',
    uid: '0c7757a1-5d58-464f-be8a-f58fd933706f',
    position: 'Linker Verteidiger',
    league: 'LaLiga',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 4,
    name: 'Arnau Martínez',
    dateOfBirth: '25.04.2003',
    nationality: 'Spanien',
    height: 1.82,
    foot: 'rechts',
    teamMemberSince: '01.04.2021',
    club: 'FC Girona',
    previousClub: 'FC Girona B',
    uid: '8cba4830-aac5-4ce5-b7bf-1f5c140c6baf',
    position: 'Rechter Verteidiger',
    league: 'LaLiga',
    marketValue: 15000000,
  },
  {
    jerseyNumber: 20,
    name: 'Yan Couto',
    dateOfBirth: '03.06.2002',
    nationality: 'Brasilien',
    height: 1.68,
    foot: 'rechts',
    teamMemberSince: '25.07.2022',
    club: 'FC Girona',
    previousClub: 'Manchester City',
    uid: '373292c4-690d-48fa-bf1e-fd950a355123',
    position: 'Rechter Verteidiger',
    league: 'LaLiga',
    marketValue: 4000000,
  },
  {
    jerseyNumber: 18,
    name: 'Oriol Romeu',
    dateOfBirth: '24.09.1991',
    nationality: 'Spanien',
    height: 1.83,
    foot: 'rechts',
    teamMemberSince: '01.09.2022',
    club: 'FC Girona',
    previousClub: 'FC Southampton',
    uid: '9856bd2f-49ef-4774-8c5a-3b6232b5e1b9',
    position: 'Defensives Mittelfeld',
    league: 'LaLiga',
    marketValue: 5000000,
  },
  {
    jerseyNumber: 6,
    name: 'Ibrahima Kébé',
    dateOfBirth: '01.08.2000',
    nationality: 'Mali',
    height: 1.8,
    foot: 'rechts',
    teamMemberSince: '01.07.2020',
    club: 'FC Girona',
    previousClub: 'FC Girona B',
    uid: 'ddfc38b7-b480-444d-89c4-fd88c34b3b39',
    position: 'Defensives Mittelfeld',
    league: 'LaLiga',
    marketValue: 600000,
  },
  {
    jerseyNumber: 14,
    name: 'Aleix García',
    dateOfBirth: '28.06.1997',
    nationality: 'Spanien',
    height: 1.73,
    foot: 'rechts',
    teamMemberSince: '23.07.2021',
    club: 'FC Girona',
    previousClub: 'SD Eibar',
    uid: 'a11d4977-5a4c-42d1-895d-97625de669a6',
    position: 'Zentrales Mittelfeld',
    league: 'LaLiga',
    marketValue: 10000000,
  },
  {
    jerseyNumber: 21,
    name: 'Yangel Herrera',
    dateOfBirth: '07.01.1998',
    nationality: 'Venezuela',
    height: 1.84,
    foot: 'rechts',
    teamMemberSince: '02.08.2022',
    club: 'FC Girona',
    previousClub: 'Manchester City',
    uid: 'ac2f13e5-9b24-4905-a998-b5cc082bd12c',
    position: 'Zentrales Mittelfeld',
    league: 'LaLiga',
    marketValue: 9000000,
  },
  {
    jerseyNumber: 19,
    name: 'Reinier',
    dateOfBirth: '19.01.2002',
    nationality: 'Brasilien',
    height: 1.85,
    foot: 'rechts',
    teamMemberSince: '19.08.2022',
    club: 'FC Girona',
    previousClub: 'Real Madrid',
    uid: 'bc69651a-5dd4-48e1-8429-4e44e9adafa2',
    position: 'Offensives Mittelfeld',
    league: 'LaLiga',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 23,
    name: 'Iván Martín',
    dateOfBirth: '14.02.1999',
    nationality: 'Spanien',
    height: 1.78,
    foot: 'links',
    teamMemberSince: '27.01.2022',
    club: 'FC Girona',
    previousClub: 'FC Villarreal',
    uid: '8877a6d5-c0d9-4932-aade-8196a62125d8',
    position: 'Offensives Mittelfeld',
    league: 'LaLiga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 24,
    name: 'Borja García',
    dateOfBirth: '02.11.1990',
    nationality: 'Spanien',
    height: 1.75,
    foot: 'rechts',
    teamMemberSince: '03.09.2021',
    club: 'FC Girona',
    previousClub: 'SD Huesca',
    uid: 'da078d15-0edb-425c-b277-9cb77bfe50fd',
    position: 'Offensives Mittelfeld',
    league: 'LaLiga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 17,
    name: 'Rodrigo Riquelme',
    dateOfBirth: '02.04.2000',
    nationality: 'Spanien',
    height: 1.74,
    foot: 'rechts',
    teamMemberSince: '01.08.2022',
    club: 'FC Girona',
    previousClub: 'Atlético Madrid',
    uid: 'ee571ff3-5ab3-4ef9-8dfa-e9aa826173fb',
    position: 'Linksaußen',
    league: 'LaLiga',
    marketValue: 10000000,
  },
  {
    jerseyNumber: 12,
    name: 'Toni Villa',
    dateOfBirth: '07.01.1995',
    nationality: 'Spanien',
    height: 1.72,
    foot: 'rechts',
    teamMemberSince: '01.09.2022',
    club: 'FC Girona',
    previousClub: 'Real Valladolid',
    uid: '9a60a50c-87d4-4707-b2a8-10fdd4c603ca',
    position: 'Linksaußen',
    league: 'LaLiga',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 8,
    name: 'Viktor Tsygankov',
    dateOfBirth: '15.11.1997',
    nationality: 'Ukraine',
    height: 1.78,
    foot: 'links',
    teamMemberSince: '17.01.2023',
    club: 'FC Girona',
    previousClub: 'Dynamo Kiew',
    uid: '619dcdd3-9367-4255-a6d4-57fb7db055d8',
    position: 'Rechtsaußen',
    league: 'LaLiga',
    marketValue: 22000000,
  },
  {
    jerseyNumber: 11,
    name: 'Valery Fernández',
    dateOfBirth: '23.11.1999',
    nationality: 'Spanien',
    height: 1.82,
    foot: 'rechts',
    teamMemberSince: '01.01.2019',
    club: 'FC Girona',
    previousClub: 'CF Peralada',
    uid: 'b70a4161-52d5-45e5-931d-97fb20d98342',
    position: 'Rechtsaußen',
    league: 'LaLiga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 9,
    name: 'Taty Castellanos',
    dateOfBirth: '03.10.1998',
    nationality: 'Argentinien',
    height: 1.78,
    foot: 'rechts',
    teamMemberSince: '25.07.2022',
    club: 'FC Girona',
    previousClub: 'New York City FC',
    uid: 'fdcc31cd-21de-4e4f-a50d-d47caea6142b',
    position: 'Mittelstürmer',
    league: 'LaLiga',
    marketValue: 10000000,
  },
  {
    jerseyNumber: 7,
    name: 'Cristhian Stuani',
    dateOfBirth: '12.10.1986',
    nationality: 'Uruguay',
    height: 1.84,
    foot: 'rechts',
    teamMemberSince: '21.07.2017',
    club: 'FC Girona',
    previousClub: 'FC Middlesbrough',
    uid: 'b513c174-4975-4074-8700-5880ce98e769',
    position: 'Mittelstürmer',
    league: 'LaLiga',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 13,
    name: 'Fernando Pacheco',
    dateOfBirth: '18.05.1992',
    nationality: 'Spanien',
    height: 1.87,
    foot: 'links',
    teamMemberSince: '31.01.2023',
    club: 'Espanyol Barcelona',
    previousClub: 'UD Almería',
    uid: '93528b71-b049-4dcf-bebe-6d8d0256f810',
    position: 'Torwart',
    league: 'LaLiga',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 25,
    name: 'Álvaro Fernández',
    dateOfBirth: '13.04.1998',
    nationality: 'Spanien',
    height: 1.86,
    foot: 'links',
    teamMemberSince: '31.08.2022',
    club: 'Espanyol Barcelona',
    previousClub: 'SD Huesca',
    uid: '66879195-014f-49f7-a2f7-29c92439a109',
    position: 'Torwart',
    league: 'LaLiga',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 1,
    name: 'Joan García',
    dateOfBirth: '04.05.2001',
    nationality: 'Spanien',
    height: 1.91,
    foot: 'rechts',
    teamMemberSince: '01.07.2021',
    club: 'Espanyol Barcelona',
    previousClub: 'Espanyol Barcelona B',
    uid: '5f639650-53ce-4d12-b2b8-ea587613e10a',
    position: 'Torwart',
    league: 'LaLiga',
    marketValue: 400000,
  },
  {
    jerseyNumber: 23,
    name: 'César Montes',
    dateOfBirth: '24.02.1997',
    nationality: 'Mexiko',
    height: 1.95,
    foot: 'rechts',
    teamMemberSince: '01.01.2023',
    club: 'Espanyol Barcelona',
    previousClub: 'CF Monterrey',
    uid: '3b7e7fce-6506-4a72-9fb5-5d7c307cb270',
    position: 'Innenverteidiger',
    league: 'LaLiga',
    marketValue: 8000000,
  },
  {
    jerseyNumber: 4,
    name: 'Leandro Cabrera',
    dateOfBirth: '17.06.1991',
    nationality: 'Uruguay',
    height: 1.9,
    foot: 'links',
    teamMemberSince: '20.01.2020',
    club: 'Espanyol Barcelona',
    previousClub: 'FC Getafe',
    uid: '61e26a67-461e-4230-89e7-13f1ea10e436',
    position: 'Innenverteidiger',
    league: 'LaLiga',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 5,
    name: 'Fernando Calero',
    dateOfBirth: '14.09.1995',
    nationality: 'Spanien',
    height: 1.84,
    foot: 'rechts',
    teamMemberSince: '09.08.2019',
    club: 'Espanyol Barcelona',
    previousClub: ': Ablöse 8,00 Mio. €',
    uid: 'ed0ed469-197d-4f6e-94d0-cc594271742b',
    position: 'Innenverteidiger',
    league: 'LaLiga',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 24,
    name: 'Sergi Gómez',
    dateOfBirth: '28.03.1992',
    nationality: 'Spanien',
    height: 1.85,
    foot: 'rechts',
    teamMemberSince: '28.07.2021',
    club: 'Espanyol Barcelona',
    previousClub: 'FC Sevilla',
    uid: 'c261ceb4-344c-4076-9c25-ed306795d9df',
    position: 'Innenverteidiger',
    league: 'LaLiga',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 28,
    name: 'Simo Keddari',
    dateOfBirth: '03.02.2005',
    nationality: 'Spanien',
    height: 1.83,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'Espanyol Barcelona',
    previousClub: 'Espanyol Barcelona B',
    uid: '47c7fbc2-c5bf-4b01-baaf-7ed3a4bf8ca2',
    position: 'Innenverteidiger',
    league: 'LaLiga',
    marketValue: 500000,
  },
  {
    jerseyNumber: 14,
    name: 'Brian Oliván',
    dateOfBirth: '01.04.1994',
    nationality: 'Spanien',
    height: 1.75,
    foot: 'links',
    teamMemberSince: '01.07.2022',
    club: 'Espanyol Barcelona',
    previousClub: 'RCD Mallorca',
    uid: '127c88f8-8232-4a8e-b223-cb5213efd417',
    position: 'Linker Verteidiger',
    league: 'LaLiga',
    marketValue: 6000000,
  },
  {
    jerseyNumber: 3,
    name: 'Adrià Pedrosa',
    dateOfBirth: '13.05.1998',
    nationality: 'Spanien',
    height: 1.72,
    foot: 'links',
    teamMemberSince: '01.07.2019',
    club: 'Espanyol Barcelona',
    previousClub: 'Espanyol Barcelona B',
    uid: 'e9f61c7f-5cad-4e3e-978c-aad35ef74525',
    position: 'Linker Verteidiger',
    league: 'LaLiga',
    marketValue: 5000000,
  },
  {
    jerseyNumber: 18,
    name: 'Ronaël Pierre-Gabriel',
    dateOfBirth: '13.06.1998',
    nationality: 'Frankreich',
    height: 1.77,
    foot: 'rechts',
    teamMemberSince: '18.01.2023',
    club: 'Espanyol Barcelona',
    previousClub: '1.FSV Mainz 05',
    uid: 'f11dc7ac-aba4-4713-896c-35b8b90a5dae',
    position: 'Rechter Verteidiger',
    league: 'LaLiga',
    marketValue: 4000000,
  },
  {
    jerseyNumber: 2,
    name: 'Óscar Gil',
    dateOfBirth: '26.04.1998',
    nationality: 'Spanien',
    height: 1.75,
    foot: 'beidfüßig',
    teamMemberSince: '07.09.2020',
    club: 'Espanyol Barcelona',
    previousClub: 'FC Elche',
    uid: 'c7852913-9496-4262-9421-bdbc657bb7e6',
    position: 'Rechter Verteidiger',
    league: 'LaLiga',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 27,
    name: 'Rubén Sánchez',
    dateOfBirth: '04.02.2001',
    nationality: 'Spanien',
    height: 1.85,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'Espanyol Barcelona',
    previousClub: 'Espanyol Barcelona B',
    uid: 'd873dd70-1434-41ca-b5f7-10f829e3c90d',
    position: 'Rechter Verteidiger',
    league: 'LaLiga',
    marketValue: 500000,
  },
  {
    jerseyNumber: 12,
    name: 'Vini Souza',
    dateOfBirth: '17.06.1999',
    nationality: 'Brasilien',
    height: 1.87,
    foot: 'rechts',
    teamMemberSince: '08.07.2022',
    club: 'Espanyol Barcelona',
    previousClub: 'Lommel SK',
    uid: '00d0a201-1bef-4765-baee-31b870ab1f25',
    position: 'Defensives Mittelfeld',
    league: 'LaLiga',
    marketValue: 7000000,
  },
  {
    jerseyNumber: 10,
    name: 'Sergi Darder',
    dateOfBirth: '22.12.1993',
    nationality: 'Spanien',
    height: 1.8,
    foot: 'rechts',
    teamMemberSince: '01.07.2018',
    club: 'Espanyol Barcelona',
    previousClub: ': Ablöse 8,00 Mio. €',
    uid: '59271cde-34a5-4ce0-b98b-bc776261f87e',
    position: 'Zentrales Mittelfeld',
    league: 'LaLiga',
    marketValue: 16000000,
  },
  {
    jerseyNumber: 20,
    name: 'Edu Expósito',
    dateOfBirth: '01.08.1996',
    nationality: 'Spanien',
    height: 1.78,
    foot: 'rechts',
    teamMemberSince: '08.08.2022',
    club: 'Espanyol Barcelona',
    previousClub: 'SD Eibar',
    uid: '85f03c85-78b6-44d3-8d8c-5864e519d7b9',
    position: 'Zentrales Mittelfeld',
    league: 'LaLiga',
    marketValue: 6000000,
  },
  {
    jerseyNumber: 8,
    name: 'Keidi Bare',
    dateOfBirth: '28.08.1997',
    nationality: 'Albanien',
    height: 1.74,
    foot: 'rechts',
    teamMemberSince: '22.09.2020',
    club: 'Espanyol Barcelona',
    previousClub: 'FC Málaga',
    uid: '68219f85-e07c-41cf-819a-9af482e68dda',
    position: 'Zentrales Mittelfeld',
    league: 'LaLiga',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 15,
    name: 'José Gragera',
    dateOfBirth: '14.05.2000',
    nationality: 'Spanien',
    height: 1.86,
    foot: 'rechts',
    teamMemberSince: '31.01.2023',
    club: 'Espanyol Barcelona',
    previousClub: 'Sporting Gijón',
    uid: '83173209-60da-4c0a-95bd-013d10283cb1',
    position: 'Zentrales Mittelfeld',
    league: 'LaLiga',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 6,
    name: 'Denis Suárez',
    dateOfBirth: '06.01.1994',
    nationality: 'Spanien',
    height: 1.76,
    foot: 'rechts',
    teamMemberSince: '31.01.2023',
    club: 'Espanyol Barcelona',
    previousClub: 'Celta Vigo',
    uid: 'a7bc6df7-b873-4373-82fa-d8854df2e63d',
    position: 'Offensives Mittelfeld',
    league: 'LaLiga',
    marketValue: 5000000,
  },
  {
    jerseyNumber: 7,
    name: 'Javi Puado',
    dateOfBirth: '25.05.1998',
    nationality: 'Spanien',
    height: 1.77,
    foot: 'rechts',
    teamMemberSince: '01.07.2018',
    club: 'Espanyol Barcelona',
    previousClub: 'Espanyol Barcelona B',
    uid: '408e0024-236a-42e8-9364-c542aaeac8e0',
    position: 'Linksaußen',
    league: 'LaLiga',
    marketValue: 7000000,
  },
  {
    jerseyNumber: 21,
    name: 'Nico Melamed',
    dateOfBirth: '11.04.2001',
    nationality: 'Spanien',
    height: 1.73,
    foot: 'rechts',
    teamMemberSince: '01.08.2020',
    club: 'Espanyol Barcelona',
    previousClub: 'Espanyol Barcelona B',
    uid: 'f36bd659-7668-4e00-903c-d9c9347619ae',
    position: 'Linksaußen',
    league: 'LaLiga',
    marketValue: 6000000,
  },
  {
    jerseyNumber: 16,
    name: 'José Carlos Lazo',
    dateOfBirth: '16.02.1996',
    nationality: 'Spanien',
    height: 1.78,
    foot: 'beidfüßig',
    teamMemberSince: '24.08.2022',
    club: 'Espanyol Barcelona',
    previousClub: 'UD Almería',
    uid: '4fa1b8ce-9e5e-477d-919f-c736fa41ed07',
    position: 'Linksaußen',
    league: 'LaLiga',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 22,
    name: 'Aleix Vidal',
    dateOfBirth: '21.08.1989',
    nationality: 'Spanien',
    height: 1.77,
    foot: 'rechts',
    teamMemberSince: '18.08.2021',
    club: 'Espanyol Barcelona',
    previousClub: 'FC Sevilla',
    uid: 'd7132556-c686-42e0-adf3-d08f08f7659b',
    position: 'Rechtsaußen',
    league: 'LaLiga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 9,
    name: 'Joselu',
    dateOfBirth: '27.03.1990',
    nationality: 'Spanien',
    height: 1.92,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'Espanyol Barcelona',
    previousClub: 'Deportivo Alavés',
    uid: '17225dc7-d782-4371-bef5-c1d3fe806c4c',
    position: 'Mittelstürmer',
    league: 'LaLiga',
    marketValue: 6000000,
  },
  {
    jerseyNumber: 17,
    name: 'Martin Braithwaite',
    dateOfBirth: '05.06.1991',
    nationality: 'Dänemark',
    height: 1.77,
    foot: 'rechts',
    teamMemberSince: '01.09.2022',
    club: 'Espanyol Barcelona',
    previousClub: 'FC Barcelona',
    uid: 'dcdcaec8-4b85-47de-8bd3-1450e877b04e',
    position: 'Mittelstürmer',
    league: 'LaLiga',
    marketValue: 6000000,
  },
  {
    jerseyNumber: 19,
    name: 'Dani Gómez',
    dateOfBirth: '30.07.1998',
    nationality: 'Spanien',
    height: 1.78,
    foot: 'rechts',
    teamMemberSince: '22.08.2022',
    club: 'Espanyol Barcelona',
    previousClub: 'UD Levante',
    uid: 'cd6ebb85-2dda-4b59-9455-535906c0ec2f',
    position: 'Mittelstürmer',
    league: 'LaLiga',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 1,
    name: 'Stole Dimitrievski',
    dateOfBirth: '25.12.1993',
    nationality: 'Nordmazedonien',
    height: 1.88,
    foot: 'rechts',
    teamMemberSince: '31.01.2019',
    club: 'Rayo Vallecano',
    previousClub: 'Gimnàstic de Tarragona',
    uid: '145f368c-d68b-4192-8a3f-d351457bb8e2',
    position: 'Torwart',
    league: 'LaLiga',
    marketValue: 4000000,
  },
  {
    jerseyNumber: 30,
    name: 'Miguel Ángel Morro',
    dateOfBirth: '11.09.2000',
    nationality: 'Spanien',
    height: 1.95,
    foot: 'links',
    teamMemberSince: '01.08.2020',
    club: 'Rayo Vallecano',
    previousClub: 'Rayo Vallecano B',
    uid: 'b5270c17-c5f5-4a59-b249-cea5f4187d73',
    position: 'Torwart',
    league: 'LaLiga',
    marketValue: 600000,
  },
  {
    jerseyNumber: 13,
    name: 'Diego López',
    dateOfBirth: '03.11.1981',
    nationality: 'Spanien',
    height: 1.96,
    foot: 'rechts',
    teamMemberSince: '02.07.2022',
    club: 'Rayo Vallecano',
    previousClub: 'Espanyol Barcelona',
    uid: '78335581-5a32-4929-ab9a-2b0e81bd68da',
    position: 'Torwart',
    league: 'LaLiga',
    marketValue: 500000,
  },
  {
    jerseyNumber: 5,
    name: 'Alejandro Catena',
    dateOfBirth: '28.10.1994',
    nationality: 'Spanien',
    height: 1.94,
    foot: 'rechts',
    teamMemberSince: '31.01.2019',
    club: 'Rayo Vallecano',
    previousClub: 'CF Reus Deportiu',
    uid: '01eb56de-3a4d-46ff-9336-ffd5c28109df',
    position: 'Innenverteidiger',
    league: 'LaLiga',
    marketValue: 8000000,
  },
  {
    jerseyNumber: 19,
    name: 'Florian Lejeune',
    dateOfBirth: '20.05.1991',
    nationality: 'Frankreich',
    height: 1.9,
    foot: 'rechts',
    teamMemberSince: '29.07.2022',
    club: 'Rayo Vallecano',
    previousClub: 'Deportivo Alavés',
    uid: '9f03f876-07a5-4c2b-a5a3-5cbf30ec29f8',
    position: 'Innenverteidiger',
    league: 'LaLiga',
    marketValue: 4000000,
  },
  {
    jerseyNumber: 16,
    name: 'Abdul Mumin',
    dateOfBirth: '06.06.1998',
    nationality: 'Ghana',
    height: 1.88,
    foot: 'rechts',
    teamMemberSince: '01.09.2022',
    club: 'Rayo Vallecano',
    previousClub: 'Vitória Guimarães SC',
    uid: 'd87632b9-b77f-45a5-af3e-43c7e372e391',
    position: 'Innenverteidiger',
    league: 'LaLiga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 24,
    name: 'Esteban Saveljich',
    dateOfBirth: '20.05.1991',
    nationality: 'Montenegro',
    height: 1.87,
    foot: 'rechts',
    teamMemberSince: '26.07.2019',
    club: 'Rayo Vallecano',
    previousClub: 'UD Levante',
    uid: '2d016322-249a-4df8-a0fa-c8d940b0d890',
    position: 'Innenverteidiger',
    league: 'LaLiga',
    marketValue: 600000,
  },
  {
    jerseyNumber: 4,
    name: 'Mario Suárez',
    dateOfBirth: '24.02.1987',
    nationality: 'Spanien',
    height: 1.89,
    foot: 'rechts',
    teamMemberSince: '31.01.2019',
    club: 'Rayo Vallecano',
    previousClub: 'Guizhou Hengfeng',
    uid: 'f464a089-eed2-4e58-a353-775d0efef779',
    position: 'Innenverteidiger',
    league: 'LaLiga',
    marketValue: 300000,
  },
  {
    jerseyNumber: 3,
    name: 'Fran García',
    dateOfBirth: '14.08.1999',
    nationality: 'Spanien',
    height: 1.69,
    foot: 'links',
    teamMemberSince: '13.07.2021',
    club: 'Rayo Vallecano',
    previousClub: 'Real Madrid Castilla',
    uid: '765f7cb4-af79-4d50-b8ef-5b1a58363116',
    position: 'Linker Verteidiger',
    league: 'LaLiga',
    marketValue: 10000000,
  },
  {
    jerseyNumber: 15,
    name: 'Pep Chavarría',
    dateOfBirth: '10.04.1998',
    nationality: 'Spanien',
    height: 1.74,
    foot: 'links',
    teamMemberSince: '31.08.2022',
    club: 'Rayo Vallecano',
    previousClub: 'Real Saragossa',
    uid: '2924f0eb-ca5e-4f71-a0d2-434c624ce481',
    position: 'Linker Verteidiger',
    league: 'LaLiga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 20,
    name: 'Iván Balliu',
    dateOfBirth: '01.01.1992',
    nationality: 'Albanien',
    height: 1.72,
    foot: 'rechts',
    teamMemberSince: '14.07.2021',
    club: 'Rayo Vallecano',
    previousClub: 'UD Almería',
    uid: '1925edcc-6542-4eda-8b70-2c221027410a',
    position: 'Rechter Verteidiger',
    league: 'LaLiga',
    marketValue: 4000000,
  },
  {
    jerseyNumber: 2,
    name: 'Mario Hernández',
    dateOfBirth: '25.01.1999',
    nationality: 'Spanien',
    height: 1.77,
    foot: 'rechts',
    teamMemberSince: '01.01.2019',
    club: 'Rayo Vallecano',
    previousClub: 'Rayo Vallecano B',
    uid: 'ec0708a8-c7fe-4497-b2a0-82bd3e14e520',
    position: 'Rechter Verteidiger',
    league: 'LaLiga',
    marketValue: 700000,
  },
  {
    jerseyNumber: 6,
    name: 'Santi Comesaña',
    dateOfBirth: '05.10.1996',
    nationality: 'Spanien',
    height: 1.88,
    foot: 'beidfüßig',
    teamMemberSince: '16.07.2016',
    club: 'Rayo Vallecano',
    previousClub: 'FC Coruxo',
    uid: '7e40ff9e-f13e-4192-9e75-d83b75d2665a',
    position: 'Zentrales Mittelfeld',
    league: 'LaLiga',
    marketValue: 5000000,
  },
  {
    jerseyNumber: 17,
    name: 'Unai López',
    dateOfBirth: '30.10.1995',
    nationality: 'Spanien',
    height: 1.7,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'Rayo Vallecano',
    previousClub: 'Athletic Bilbao',
    uid: '38811a6b-3f9a-4805-91f2-3f6544b46218',
    position: 'Zentrales Mittelfeld',
    league: 'LaLiga',
    marketValue: 4000000,
  },
  {
    jerseyNumber: 21,
    name: 'Pathé Ciss',
    dateOfBirth: '16.03.1994',
    nationality: 'Senegal',
    height: 1.86,
    foot: 'rechts',
    teamMemberSince: '28.07.2021',
    club: 'Rayo Vallecano',
    previousClub: 'FC Fuenlabrada',
    uid: '2b1cb334-2f0a-4ede-a366-7502a81185e9',
    position: 'Zentrales Mittelfeld',
    league: 'LaLiga',
    marketValue: 4000000,
  },
  {
    jerseyNumber: 23,
    name: 'Óscar Valentín',
    dateOfBirth: '20.08.1994',
    nationality: 'Spanien',
    height: 1.77,
    foot: 'rechts',
    teamMemberSince: '01.07.2019',
    club: 'Rayo Vallecano',
    previousClub: 'CF Rayo Majadahonda',
    uid: '196ce733-38a7-4f58-b807-1678b2e55861',
    position: 'Zentrales Mittelfeld',
    league: 'LaLiga',
    marketValue: 4000000,
  },
  {
    jerseyNumber: 8,
    name: 'Óscar Trejo',
    dateOfBirth: '26.04.1988',
    nationality: 'Argentinien',
    height: 1.8,
    foot: 'rechts',
    teamMemberSince: '01.07.2017',
    club: 'Rayo Vallecano',
    previousClub: ': Ablöse ablösefrei',
    uid: 'fc92bf30-abbb-496c-8d7b-2f3d0d6cc171',
    position: 'Offensives Mittelfeld',
    league: 'LaLiga',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 22,
    name: 'José Pozo',
    dateOfBirth: '15.03.1996',
    nationality: 'Spanien',
    height: 1.71,
    foot: 'links',
    teamMemberSince: '27.07.2018',
    club: 'Rayo Vallecano',
    previousClub: 'UD Almería',
    uid: '083d96e3-3f9c-4ac7-a441-946fc503db05',
    position: 'Offensives Mittelfeld',
    league: 'LaLiga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 18,
    name: 'Álvaro García',
    dateOfBirth: '27.10.1992',
    nationality: 'Spanien',
    height: 1.67,
    foot: 'beidfüßig',
    teamMemberSince: '23.08.2018',
    club: 'Rayo Vallecano',
    previousClub: 'FC Cádiz',
    uid: '23eaefd6-418e-42f4-9e97-d77720cad322',
    position: 'Linksaußen',
    league: 'LaLiga',
    marketValue: 6000000,
  },
  {
    jerseyNumber: 7,
    name: 'Isi Palazón',
    dateOfBirth: '27.12.1994',
    nationality: 'Spanien',
    height: 1.69,
    foot: 'links',
    teamMemberSince: '23.01.2020',
    club: 'Rayo Vallecano',
    previousClub: 'SD Ponferradina',
    uid: 'acd2c44b-83df-4c75-a8a7-78d75c1c17a3',
    position: 'Rechtsaußen',
    league: 'LaLiga',
    marketValue: 10000000,
  },
  {
    jerseyNumber: 14,
    name: 'Salvi Sánchez',
    dateOfBirth: '30.03.1991',
    nationality: 'Spanien',
    height: 1.7,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'Rayo Vallecano',
    previousClub: 'FC Cádiz',
    uid: 'aebd5c16-74c7-4cae-8bd6-13009581c94c',
    position: 'Rechtsaußen',
    league: 'LaLiga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 34,
    name: 'Sergio Camello',
    dateOfBirth: '10.02.2001',
    nationality: 'Spanien',
    height: 1.77,
    foot: 'rechts',
    teamMemberSince: '03.08.2022',
    club: 'Rayo Vallecano',
    previousClub: 'Atlético Madrid',
    uid: '15ec3c83-3d52-4ca0-8ac5-c0abe6f605bf',
    position: 'Mittelstürmer',
    league: 'LaLiga',
    marketValue: 10000000,
  },
  {
    jerseyNumber: 25,
    name: 'Raúl de Tomás',
    dateOfBirth: '17.10.1994',
    nationality: 'Spanien',
    height: 1.8,
    foot: 'rechts',
    teamMemberSince: '13.09.2022',
    club: 'Rayo Vallecano',
    previousClub: 'Espanyol Barcelona',
    uid: '4fe34e6b-5a38-41cf-a9f2-bbad2b9a5570',
    position: 'Mittelstürmer',
    league: 'LaLiga',
    marketValue: 8000000,
  },
  {
    jerseyNumber: 9,
    name: 'Radamel Falcao',
    dateOfBirth: '10.02.1986',
    nationality: 'Kolumbien',
    height: 1.77,
    foot: 'rechts',
    teamMemberSince: '04.09.2021',
    club: 'Rayo Vallecano',
    previousClub: 'Galatasaray',
    uid: '3f7fa03a-6051-4394-a594-7168e28a25c1',
    position: 'Mittelstürmer',
    league: 'LaLiga',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 12,
    name: 'Andrés Martín',
    dateOfBirth: '11.07.1999',
    nationality: 'Spanien',
    height: 1.78,
    foot: 'links',
    teamMemberSince: '01.07.2019',
    club: 'Rayo Vallecano',
    previousClub: 'FC Córdoba',
    uid: 'ea3cf2e6-c6fa-41a2-9bcb-6b226f9c8dfd',
    position: 'Mittelstürmer',
    league: 'LaLiga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 1,
    name: 'Predrag Rajkovic',
    dateOfBirth: '31.10.1995',
    nationality: 'Serbien',
    height: 1.92,
    foot: 'rechts',
    teamMemberSince: '22.07.2022',
    club: 'RCD Mallorca',
    previousClub: 'Stade Reims',
    uid: 'cfab32ce-854c-479c-be71-f4916d46b392',
    position: 'Torwart',
    league: 'LaLiga',
    marketValue: 10000000,
  },
  {
    jerseyNumber: 13,
    name: 'Dominik Greif',
    dateOfBirth: '06.04.1997',
    nationality: 'Slowakei',
    height: 1.97,
    foot: 'rechts',
    teamMemberSince: '06.07.2021',
    club: 'RCD Mallorca',
    previousClub: 'Slovan Bratislava',
    uid: '04e3527a-485c-4609-8972-4132287e6b54',
    position: 'Torwart',
    league: 'LaLiga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 31,
    name: 'Leo Román',
    dateOfBirth: '06.07.2000',
    nationality: 'Spanien',
    height: 1.89,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'RCD Mallorca',
    previousClub: 'RCD Mallorca B',
    uid: '462433c5-f25f-4abf-9ad0-90821184d00c',
    position: 'Torwart',
    league: 'LaLiga',
    marketValue: 300000,
  },
  {
    jerseyNumber: 24,
    name: 'Martin Valjent',
    dateOfBirth: '11.12.1995',
    nationality: 'Slowakei',
    height: 1.87,
    foot: 'rechts',
    teamMemberSince: '01.07.2019',
    club: 'RCD Mallorca',
    previousClub: 'Chievo Verona',
    uid: '611b85e3-b782-4ff7-9bb4-29fc6ea09d23',
    position: 'Innenverteidiger',
    league: 'LaLiga',
    marketValue: 4000000,
  },
  {
    jerseyNumber: 5,
    name: 'Dennis Hadzikadunic',
    dateOfBirth: '09.07.1998',
    nationality: 'Bosnien-Herzegowina',
    height: 1.91,
    foot: 'rechts',
    teamMemberSince: '18.01.2023',
    club: 'RCD Mallorca',
    previousClub: 'FK Rostov',
    uid: 'dd8f367c-07c9-4569-99f9-356df29864a8',
    position: 'Innenverteidiger',
    league: 'LaLiga',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 6,
    name: 'José Copete',
    dateOfBirth: '10.10.1999',
    nationality: 'Spanien',
    height: 1.9,
    foot: 'links',
    teamMemberSince: '01.07.2022',
    club: 'RCD Mallorca',
    previousClub: 'SD Ponferradina',
    uid: '8ef21223-eeec-4a88-a1a4-15bb03601ecf',
    position: 'Innenverteidiger',
    league: 'LaLiga',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 2,
    name: 'Matija Nastasic',
    dateOfBirth: '28.03.1993',
    nationality: 'Serbien',
    height: 1.88,
    foot: 'links',
    teamMemberSince: '01.09.2022',
    club: 'RCD Mallorca',
    previousClub: 'AC Florenz',
    uid: 'd0609631-aead-4940-b6ad-f9330908d419',
    position: 'Innenverteidiger',
    league: 'LaLiga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 3,
    name: 'Ludwig Augustinsson',
    dateOfBirth: '21.04.1994',
    nationality: 'Schweden',
    height: 1.81,
    foot: 'links',
    teamMemberSince: '30.01.2023',
    club: 'RCD Mallorca',
    previousClub: 'FC Sevilla',
    uid: '34c96ef5-7467-40a7-a8dd-9649137c2e86',
    position: 'Linker Verteidiger',
    league: 'LaLiga',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 18,
    name: 'Jaume Costa',
    dateOfBirth: '18.03.1988',
    nationality: 'Spanien',
    height: 1.71,
    foot: 'links',
    teamMemberSince: '07.07.2021',
    club: 'RCD Mallorca',
    previousClub: 'FC Villarreal',
    uid: '08d797f9-4b1b-4f15-8341-f7bdcee10809',
    position: 'Linker Verteidiger',
    league: 'LaLiga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 15,
    name: 'Pablo Maffeo',
    dateOfBirth: '12.07.1997',
    nationality: 'Spanien',
    height: 1.72,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'RCD Mallorca',
    previousClub: 'VfB Stuttgart',
    uid: 'd2fe438c-b3ff-442a-8653-2f8df045d064',
    position: 'Rechter Verteidiger',
    league: 'LaLiga',
    marketValue: 8000000,
  },
  {
    jerseyNumber: 20,
    name: 'Giovanni González',
    dateOfBirth: '20.09.1994',
    nationality: 'Uruguay',
    height: 1.78,
    foot: 'rechts',
    teamMemberSince: '29.01.2022',
    club: 'RCD Mallorca',
    previousClub: 'CA Peñarol',
    uid: '647536eb-ad18-4185-9b8e-ad36a1abf7e2',
    position: 'Rechter Verteidiger',
    league: 'LaLiga',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 11,
    name: 'Manu Morlanes',
    dateOfBirth: '12.01.1999',
    nationality: 'Spanien',
    height: 1.78,
    foot: 'rechts',
    teamMemberSince: '31.01.2023',
    club: 'RCD Mallorca',
    previousClub: 'FC Villarreal',
    uid: '7729b4e3-8349-4716-9c78-5d1198520f80',
    position: 'Zentrales Mittelfeld',
    league: 'LaLiga',
    marketValue: 4000000,
  },
  {
    jerseyNumber: 12,
    name: 'Iddrisu Baba',
    dateOfBirth: '22.01.1996',
    nationality: 'Ghana',
    height: 1.82,
    foot: 'rechts',
    teamMemberSince: '01.07.2018',
    club: 'RCD Mallorca',
    previousClub: 'RCD Mallorca B',
    uid: 'bba010ac-54cf-46b7-8487-56798bb0167b',
    position: 'Zentrales Mittelfeld',
    league: 'LaLiga',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 4,
    name: 'Iñigo Ruiz de Galarreta',
    dateOfBirth: '06.08.1993',
    nationality: 'Spanien',
    height: 1.75,
    foot: 'rechts',
    teamMemberSince: '01.09.2019',
    club: 'RCD Mallorca',
    previousClub: 'UD Las Palmas',
    uid: '5ffe5353-8602-488f-9c53-84818ea34353',
    position: 'Zentrales Mittelfeld',
    league: 'LaLiga',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 10,
    name: 'Antonio Sánchez',
    dateOfBirth: '22.04.1997',
    nationality: 'Spanien',
    height: 1.79,
    foot: 'rechts',
    teamMemberSince: '01.07.2018',
    club: 'RCD Mallorca',
    previousClub: 'RCD Mallorca B',
    uid: '01634ba5-3b92-46a3-98f9-cedb5170de45',
    position: 'Zentrales Mittelfeld',
    league: 'LaLiga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 8,
    name: 'Clément Grenier',
    dateOfBirth: '07.01.1991',
    nationality: 'Frankreich',
    height: 1.86,
    foot: 'rechts',
    teamMemberSince: '03.03.2022',
    club: 'RCD Mallorca',
    previousClub: 'Vereinslos',
    uid: '889e5355-b361-4125-9563-7dc761da0b27',
    position: 'Zentrales Mittelfeld',
    league: 'LaLiga',
    marketValue: 900000,
  },
  {
    jerseyNumber: 19,
    name: 'Kang-in Lee',
    dateOfBirth: '19.02.2001',
    nationality: 'Südkorea',
    height: 1.73,
    foot: 'links',
    teamMemberSince: '30.08.2021',
    club: 'RCD Mallorca',
    previousClub: 'FC Valencia',
    uid: '9e27aa60-03ae-442e-84fd-f361345e0dce',
    position: 'Offensives Mittelfeld',
    league: 'LaLiga',
    marketValue: 15000000,
  },
  {
    jerseyNumber: 14,
    name: 'Dani Rodríguez',
    dateOfBirth: '06.06.1988',
    nationality: 'Spanien',
    height: 1.78,
    foot: 'rechts',
    teamMemberSince: '01.07.2018',
    club: 'RCD Mallorca',
    previousClub: 'Albacete Balompié',
    uid: '87ff472f-5be4-4ba7-a959-d210339ad48d',
    position: 'Offensives Mittelfeld',
    league: 'LaLiga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 23,
    name: 'Amath Ndiaye',
    dateOfBirth: '16.07.1996',
    nationality: 'Senegal',
    height: 1.73,
    foot: 'rechts',
    teamMemberSince: '01.07.2021',
    club: 'RCD Mallorca',
    previousClub: 'FC Getafe',
    uid: '7df54ad2-d7e0-47ac-9efc-ea4fbc6f4520',
    position: 'Linksaußen',
    league: 'LaLiga',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 7,
    name: 'Vedat Muriqi',
    dateOfBirth: '24.04.1994',
    nationality: 'Kosovo',
    height: 1.94,
    foot: 'links',
    teamMemberSince: '22.07.2022',
    club: 'RCD Mallorca',
    previousClub: 'Lazio Rom',
    uid: 'f6f50bb9-3737-45e1-9d5e-62c3cb3d18af',
    position: 'Mittelstürmer',
    league: 'LaLiga',
    marketValue: 15000000,
  },
  {
    jerseyNumber: 17,
    name: 'Tino Kadewere',
    dateOfBirth: '05.01.1996',
    nationality: 'Simbabwe',
    height: 1.83,
    foot: 'rechts',
    teamMemberSince: '29.08.2022',
    club: 'RCD Mallorca',
    previousClub: 'Olympique Lyon',
    uid: 'ac344bd2-d83b-47c5-b99a-6dd948fa9d23',
    position: 'Mittelstürmer',
    league: 'LaLiga',
    marketValue: 5000000,
  },
  {
    jerseyNumber: 9,
    name: 'Abdón Prats',
    dateOfBirth: '07.12.1992',
    nationality: 'Spanien',
    height: 1.81,
    foot: 'links',
    teamMemberSince: '04.07.2017',
    club: 'RCD Mallorca',
    previousClub: 'Racing Santander',
    uid: '046f98f3-860c-404d-9e8d-976913398ab5',
    position: 'Mittelstürmer',
    league: 'LaLiga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 22,
    name: 'Ángel Rodríguez',
    dateOfBirth: '26.04.1987',
    nationality: 'Spanien',
    height: 1.71,
    foot: 'rechts',
    teamMemberSince: '02.07.2021',
    club: 'RCD Mallorca',
    previousClub: 'FC Getafe',
    uid: 'c2925469-5a36-421e-9b9c-6f884b7d8a77',
    position: 'Mittelstürmer',
    league: 'LaLiga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 13,
    name: 'Fernando Martínez',
    dateOfBirth: '10.06.1990',
    nationality: 'Spanien',
    height: 1.83,
    foot: 'rechts',
    teamMemberSince: '05.07.2017',
    club: 'UD Almería',
    previousClub: 'UCAM Murcia',
    uid: 'fdd0cabb-4ab6-424a-9120-a9d726eb7d7f',
    position: 'Torwart',
    league: 'LaLiga',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 1,
    name: 'Diego Mariño',
    dateOfBirth: '09.05.1990',
    nationality: 'Spanien',
    height: 1.87,
    foot: 'rechts',
    teamMemberSince: '26.01.2023',
    club: 'UD Almería',
    previousClub: 'Sporting Gijón',
    uid: '52819614-78b0-468b-9007-14968b35925f',
    position: 'Torwart',
    league: 'LaLiga',
    marketValue: 300000,
  },
  {
    jerseyNumber: 25,
    name: 'Diego Fuoli',
    dateOfBirth: '20.10.1997',
    nationality: 'Spanien',
    height: 1.88,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'UD Almería',
    previousClub: 'UD Almería B',
    uid: '0b8a67d7-3c4a-4eeb-aed8-7eaa4d42222f',
    position: 'Torwart',
    league: 'LaLiga',
    marketValue: 200000,
  },
  {
    jerseyNumber: 2,
    name: 'Kaiky',
    dateOfBirth: '12.01.2004',
    nationality: 'Brasilien',
    height: 1.81,
    foot: 'rechts',
    teamMemberSince: '19.07.2022',
    club: 'UD Almería',
    previousClub: 'FC Santos',
    uid: '12ab24b5-4df2-4b10-8847-b84e69595db6',
    position: 'Innenverteidiger',
    league: 'LaLiga',
    marketValue: 5000000,
  },
  {
    jerseyNumber: 22,
    name: 'Srdjan Babic',
    dateOfBirth: '22.04.1996',
    nationality: 'Serbien',
    height: 1.94,
    foot: 'links',
    teamMemberSince: '01.07.2022',
    club: 'UD Almería',
    previousClub: 'Roter Stern Belgrad',
    uid: '5a22c572-9b4e-4b0b-8c38-8e0dbda4afc9',
    position: 'Innenverteidiger',
    league: 'LaLiga',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 19,
    name: 'Rodrigo Ely',
    dateOfBirth: '03.11.1993',
    nationality: 'Brasilien',
    height: 1.91,
    foot: 'rechts',
    teamMemberSince: '07.03.2022',
    club: 'UD Almería',
    previousClub: 'Vereinslos',
    uid: 'd58997de-00ff-4855-b926-619ca981c72f',
    position: 'Innenverteidiger',
    league: 'LaLiga',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 21,
    name: 'Chumi',
    dateOfBirth: '02.03.1999',
    nationality: 'Spanien',
    height: 1.85,
    foot: 'rechts',
    teamMemberSince: '10.09.2020',
    club: 'UD Almería',
    previousClub: 'FC Barcelona B',
    uid: '0e79130c-f8c8-494d-ad0f-9737aed40722',
    position: 'Innenverteidiger',
    league: 'LaLiga',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 15,
    name: 'Sergio Akieme',
    dateOfBirth: '16.12.1997',
    nationality: 'Spanien',
    height: 1.75,
    foot: 'links',
    teamMemberSince: '07.07.2021',
    club: 'UD Almería',
    previousClub: 'FC Barcelona B',
    uid: 'fe009651-b62b-4c3a-9c47-dff21c71e3d9',
    position: 'Linker Verteidiger',
    league: 'LaLiga',
    marketValue: 9000000,
  },
  {
    jerseyNumber: 20,
    name: 'Álex Centelles',
    dateOfBirth: '30.08.1999',
    nationality: 'Spanien',
    height: 1.85,
    foot: 'links',
    teamMemberSince: '02.10.2020',
    club: 'UD Almería',
    previousClub: 'FC Valencia',
    uid: '1d57650d-7f36-44c5-a9ef-0f379bcab13a',
    position: 'Linker Verteidiger',
    league: 'LaLiga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 17,
    name: 'Alejandro Pozo',
    dateOfBirth: '22.02.1999',
    nationality: 'Spanien',
    height: 1.73,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'UD Almería',
    previousClub: 'FC Sevilla',
    uid: '7bab1a8c-1ab7-4f73-9f4e-ca334ba0712b',
    position: 'Rechter Verteidiger',
    league: 'LaLiga',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 24,
    name: 'Houboulang Mendes',
    dateOfBirth: '04.05.1998',
    nationality: 'Guinea-Bissau',
    height: 1.84,
    foot: 'rechts',
    teamMemberSince: '06.07.2022',
    club: 'UD Almería',
    previousClub: 'FC Lorient',
    uid: '5c919eae-fbc1-4ab2-b88a-9b6d266069cc',
    position: 'Rechter Verteidiger',
    league: 'LaLiga',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 23,
    name: 'Samú Costa',
    dateOfBirth: '27.11.2000',
    nationality: 'Portugal',
    height: 1.85,
    foot: 'links',
    teamMemberSince: '01.07.2021',
    club: 'UD Almería',
    previousClub: 'SC Braga',
    uid: '0dc201cc-49d8-4084-99d1-a3340ab3e6ff',
    position: 'Defensives Mittelfeld',
    league: 'LaLiga',
    marketValue: 5000000,
  },
  {
    jerseyNumber: 4,
    name: 'Iñigo Eguaras',
    dateOfBirth: '07.03.1992',
    nationality: 'Spanien',
    height: 1.81,
    foot: 'rechts',
    teamMemberSince: '21.01.2022',
    club: 'UD Almería',
    previousClub: 'Real Saragossa',
    uid: 'e72e8750-ebcc-4a6a-8af9-518eb7a559a3',
    position: 'Defensives Mittelfeld',
    league: 'LaLiga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 5,
    name: 'Lucas Robertone',
    dateOfBirth: '18.03.1997',
    nationality: 'Argentinien',
    height: 1.69,
    foot: 'rechts',
    teamMemberSince: '01.10.2020',
    club: 'UD Almería',
    previousClub: 'Vélez Sarsfield',
    uid: '87dd7673-97fa-4ba4-82f3-5f1a2a6b1ea2',
    position: 'Zentrales Mittelfeld',
    league: 'LaLiga',
    marketValue: 7000000,
  },
  {
    jerseyNumber: 3,
    name: 'Gonzalo Melero',
    dateOfBirth: '02.01.1994',
    nationality: 'Spanien',
    height: 1.83,
    foot: 'rechts',
    teamMemberSince: '01.09.2022',
    club: 'UD Almería',
    previousClub: 'UD Levante',
    uid: 'cc484d82-3b6d-4a9d-aa16-97197c3002b7',
    position: 'Zentrales Mittelfeld',
    league: 'LaLiga',
    marketValue: 4000000,
  },
  {
    jerseyNumber: 18,
    name: 'Arnau Puigmal',
    dateOfBirth: '10.01.2001',
    nationality: 'Spanien',
    height: 1.74,
    foot: 'rechts',
    teamMemberSince: '09.07.2021',
    club: 'UD Almería',
    previousClub: 'Manchester United U23',
    uid: 'bc798391-935c-4d24-867c-323f2259aa2b',
    position: 'Zentrales Mittelfeld',
    league: 'LaLiga',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 6,
    name: 'César de la Hoz',
    dateOfBirth: '30.03.1992',
    nationality: 'Spanien',
    height: 1.79,
    foot: 'rechts',
    teamMemberSince: '04.07.2018',
    club: 'UD Almería',
    previousClub: ': Ablöse ablösefrei',
    uid: '5dade626-1121-40ed-8922-ec5cc498b4f6',
    position: 'Zentrales Mittelfeld',
    league: 'LaLiga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 7,
    name: 'Largie Ramazani',
    dateOfBirth: '27.02.2001',
    nationality: 'Belgien',
    height: 1.67,
    foot: 'rechts',
    teamMemberSince: '24.08.2020',
    club: 'UD Almería',
    previousClub: 'Manchester United U23',
    uid: '5de243b4-4a8d-4b82-b9bf-bf2c44e45d70',
    position: 'Linksaußen',
    league: 'LaLiga',
    marketValue: 9000000,
  },
  {
    jerseyNumber: 14,
    name: 'Lázaro',
    dateOfBirth: '12.03.2002',
    nationality: 'Brasilien',
    height: 1.81,
    foot: 'rechts',
    teamMemberSince: '01.09.2022',
    club: 'UD Almería',
    previousClub: 'Flamengo Rio de Janeiro',
    uid: 'f6eb6b32-d1a7-42d9-afa5-dd91816bdd00',
    position: 'Linksaußen',
    league: 'LaLiga',
    marketValue: 5000000,
  },
  {
    jerseyNumber: 10,
    name: 'Adrián Embarba',
    dateOfBirth: '07.05.1992',
    nationality: 'Spanien',
    height: 1.73,
    foot: 'rechts',
    teamMemberSince: '24.08.2022',
    club: 'UD Almería',
    previousClub: 'Espanyol Barcelona',
    uid: 'd1d1dbef-b94a-4a73-8c69-36f9abfcb8a4',
    position: 'Rechtsaußen',
    league: 'LaLiga',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 8,
    name: 'Francisco Portillo',
    dateOfBirth: '13.06.1990',
    nationality: 'Spanien',
    height: 1.69,
    foot: 'rechts',
    teamMemberSince: '22.07.2021',
    club: 'UD Almería',
    previousClub: 'FC Getafe',
    uid: '3da865d5-dcf4-4945-b7e3-f6e674b0af67',
    position: 'Rechtsaußen',
    league: 'LaLiga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 12,
    name: 'Léo Baptistão',
    dateOfBirth: '26.08.1992',
    nationality: 'Brasilien',
    height: 1.85,
    foot: 'rechts',
    teamMemberSince: '11.08.2022',
    club: 'UD Almería',
    previousClub: 'FC Santos',
    uid: '26664930-0513-4395-9572-2ab5e9101092',
    position: 'Hängende Spitze',
    league: 'LaLiga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 9,
    name: 'El Bilal Touré',
    dateOfBirth: '03.10.2001',
    nationality: 'Mali',
    height: 1.85,
    foot: 'beidfüßig',
    teamMemberSince: '01.09.2022',
    club: 'UD Almería',
    previousClub: 'Stade Reims',
    uid: '52695179-dca5-426c-a06f-61357f427eb6',
    position: 'Mittelstürmer',
    league: 'LaLiga',
    marketValue: 10000000,
  },
  {
    jerseyNumber: 16,
    name: 'Luis Suárez',
    dateOfBirth: '02.12.1997',
    nationality: 'Kolumbien',
    height: 1.85,
    foot: 'rechts',
    teamMemberSince: '01.01.2023',
    club: 'UD Almería',
    previousClub: 'Olympique Marseille',
    uid: '436233b5-ade6-4258-842f-a2429bb80ea4',
    position: 'Mittelstürmer',
    league: 'LaLiga',
    marketValue: 9000000,
  },
  {
    jerseyNumber: 27,
    name: 'Marko Milovanovic',
    dateOfBirth: '04.08.2003',
    nationality: 'Serbien',
    height: 1.94,
    foot: 'rechts',
    teamMemberSince: '11.07.2022',
    club: 'UD Almería',
    previousClub: 'FK Partizan Belgrad',
    uid: 'db93e493-945b-42b8-bcb2-8e51139ae02a',
    position: 'Mittelstürmer',
    league: 'LaLiga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 11,
    name: 'Dyego Sousa',
    dateOfBirth: '14.09.1989',
    nationality: 'Portugal',
    height: 1.89,
    foot: 'rechts',
    teamMemberSince: '31.08.2021',
    club: 'UD Almería',
    previousClub: 'Shenzhen FC',
    uid: '192d2031-1036-472d-af47-0b41ced2753e',
    position: 'Mittelstürmer',
    league: 'LaLiga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 13,
    name: 'Sergio Asenjo',
    dateOfBirth: '28.06.1989',
    nationality: 'Spanien',
    height: 1.89,
    foot: 'rechts',
    teamMemberSince: '07.07.2022',
    club: 'Real Valladolid',
    previousClub: 'FC Villarreal',
    uid: 'e642c30a-b5f7-49d8-b286-348a01521405',
    position: 'Torwart',
    league: 'LaLiga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 1,
    name: 'Jordi Masip',
    dateOfBirth: '03.01.1989',
    nationality: 'Spanien',
    height: 1.8,
    foot: 'rechts',
    teamMemberSince: '17.07.2017',
    club: 'Real Valladolid',
    previousClub: 'FC Barcelona',
    uid: '20b3b4bd-7555-431e-8384-780498e281ef',
    position: 'Torwart',
    league: 'LaLiga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 5,
    name: 'Javi Sánchez',
    dateOfBirth: '14.03.1997',
    nationality: 'Spanien',
    height: 1.89,
    foot: 'rechts',
    teamMemberSince: '01.07.2020',
    club: 'Real Valladolid',
    previousClub: 'Real Madrid',
    uid: 'b570b770-6de9-45ec-a584-4213828768f5',
    position: 'Innenverteidiger',
    league: 'LaLiga',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 24,
    name: 'Joaquín Fernández',
    dateOfBirth: '31.05.1996',
    nationality: 'Spanien',
    height: 1.9,
    foot: 'rechts',
    teamMemberSince: '31.08.2018',
    club: 'Real Valladolid',
    previousClub: 'UD Almería',
    uid: '554d45f3-bd8e-4960-ac5a-b72f72b75e3f',
    position: 'Innenverteidiger',
    league: 'LaLiga',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 15,
    name: 'Jawad El Yamiq',
    dateOfBirth: '29.02.1992',
    nationality: 'Marokko',
    height: 1.93,
    foot: 'rechts',
    teamMemberSince: '24.09.2020',
    club: 'Real Valladolid',
    previousClub: ': Ablöse 250 Tsd. €',
    uid: '7656a9f2-5476-46ea-91ba-237980b942fb',
    position: 'Innenverteidiger',
    league: 'LaLiga',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 12,
    name: 'Lucas Olaza',
    dateOfBirth: '21.07.1994',
    nationality: 'Uruguay',
    height: 1.76,
    foot: 'links',
    teamMemberSince: '01.07.2021',
    club: 'Real Valladolid',
    previousClub: 'CA Boca Juniors',
    uid: 'd766570d-f3d5-4999-8197-0dea7126c89b',
    position: 'Linker Verteidiger',
    league: 'LaLiga',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 18,
    name: 'Sergio Escudero',
    dateOfBirth: '02.09.1989',
    nationality: 'Spanien',
    height: 1.76,
    foot: 'links',
    teamMemberSince: '13.07.2022',
    club: 'Real Valladolid',
    previousClub: 'FC Granada',
    uid: '1eb48f57-fdc8-4f0f-a933-9ae533319f7e',
    position: 'Linker Verteidiger',
    league: 'LaLiga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 27,
    name: 'Iván Fresneda',
    dateOfBirth: '28.09.2004',
    nationality: 'Spanien',
    height: 1.82,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'Real Valladolid',
    previousClub: 'Real Valladolid Promesas',
    uid: '843ce0c1-a5f9-43aa-9d58-a4b3cf2b6c89',
    position: 'Rechter Verteidiger',
    league: 'LaLiga',
    marketValue: 10000000,
  },
  {
    jerseyNumber: 2,
    name: 'Luis Pérez',
    dateOfBirth: '04.02.1995',
    nationality: 'Spanien',
    height: 1.73,
    foot: 'rechts',
    teamMemberSince: '04.08.2020',
    club: 'Real Valladolid',
    previousClub: 'CD Teneriffa',
    uid: '24a2bc9b-07f8-439d-8cf0-f946d17801a5',
    position: 'Rechter Verteidiger',
    league: 'LaLiga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 39,
    name: 'Lucas Rosa',
    dateOfBirth: '03.04.2000',
    nationality: 'Brasilien',
    height: 1.77,
    foot: 'rechts',
    teamMemberSince: '01.10.2022',
    club: 'Real Valladolid',
    previousClub: 'Real Valladolid Promesas',
    uid: '41558a5f-4da1-4518-8d0f-3e557e504212',
    position: 'Rechter Verteidiger',
    league: 'LaLiga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 20,
    name: 'Martin Hongla',
    dateOfBirth: '16.03.1998',
    nationality: 'Kamerun',
    height: 1.81,
    foot: 'rechts',
    teamMemberSince: '31.01.2023',
    club: 'Real Valladolid',
    previousClub: 'Hellas Verona',
    uid: '0a5f72ac-85ae-4607-86ba-9265cfe7a1d2',
    position: 'Defensives Mittelfeld',
    league: 'LaLiga',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 8,
    name: 'Monchu',
    dateOfBirth: '13.09.1999',
    nationality: 'Spanien',
    height: 1.73,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'Real Valladolid',
    previousClub: 'FC Granada',
    uid: 'ac1b934d-f2b1-47d1-9ae2-b332c45f1978',
    position: 'Zentrales Mittelfeld',
    league: 'LaLiga',
    marketValue: 5000000,
  },
  {
    jerseyNumber: 4,
    name: 'Kike Pérez',
    dateOfBirth: '14.02.1997',
    nationality: 'Spanien',
    height: 1.84,
    foot: 'links',
    teamMemberSince: '12.08.2020',
    club: 'Real Valladolid',
    previousClub: ': Ablöse -',
    uid: '67fabeec-2227-4460-a233-7f404ca4c72b',
    position: 'Zentrales Mittelfeld',
    league: 'LaLiga',
    marketValue: 4000000,
  },
  {
    jerseyNumber: 6,
    name: 'Álvaro Aguado',
    dateOfBirth: '01.05.1996',
    nationality: 'Spanien',
    height: 1.75,
    foot: 'rechts',
    teamMemberSince: '25.01.2019',
    club: 'Real Valladolid',
    previousClub: 'FC Córdoba',
    uid: 'cc08c873-6a80-420e-8190-3e2c360b5908',
    position: 'Zentrales Mittelfeld',
    league: 'LaLiga',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 17,
    name: 'Roque Mesa',
    dateOfBirth: '07.06.1989',
    nationality: 'Spanien',
    height: 1.72,
    foot: 'rechts',
    teamMemberSince: '05.10.2020',
    club: 'Real Valladolid',
    previousClub: 'FC Sevilla',
    uid: 'ff1785ef-f27d-4b69-9cfb-76b06c979754',
    position: 'Zentrales Mittelfeld',
    league: 'LaLiga',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 23,
    name: 'Anuar',
    dateOfBirth: '15.01.1995',
    nationality: 'Marokko',
    height: 1.73,
    foot: 'rechts',
    teamMemberSince: '01.07.2017',
    club: 'Real Valladolid',
    previousClub: ': Ablöse -',
    uid: '7515c843-abdd-402d-b3a0-a198646fa829',
    position: 'Zentrales Mittelfeld',
    league: 'LaLiga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 9,
    name: 'Selim Amallah',
    dateOfBirth: '15.11.1996',
    nationality: 'Marokko',
    height: 1.86,
    foot: 'rechts',
    teamMemberSince: '31.01.2023',
    club: 'Real Valladolid',
    previousClub: 'Standard Lüttich',
    uid: 'fc348b93-40d5-408b-93df-94a313426bdc',
    position: 'Offensives Mittelfeld',
    league: 'LaLiga',
    marketValue: 4000000,
  },
  {
    jerseyNumber: 22,
    name: 'Darwin Machís',
    dateOfBirth: '07.02.1993',
    nationality: 'Venezuela',
    height: 1.71,
    foot: 'rechts',
    teamMemberSince: '19.01.2023',
    club: 'Real Valladolid',
    previousClub: 'FC Juárez',
    uid: '5a07423e-85d6-4620-8e76-0e63c2549dcd',
    position: 'Linksaußen',
    league: 'LaLiga',
    marketValue: 4000000,
  },
  {
    jerseyNumber: 19,
    name: 'Kenedy',
    dateOfBirth: '08.02.1996',
    nationality: 'Brasilien',
    height: 1.82,
    foot: 'links',
    teamMemberSince: '01.09.2022',
    club: 'Real Valladolid',
    previousClub: 'FC Chelsea',
    uid: '3394fc45-e084-49d0-83af-12e5bdde779f',
    position: 'Linksaußen',
    league: 'LaLiga',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 11,
    name: 'Gonzalo Plata',
    dateOfBirth: '01.11.2000',
    nationality: 'Ecuador',
    height: 1.78,
    foot: 'links',
    teamMemberSince: '11.07.2022',
    club: 'Real Valladolid',
    previousClub: 'Sporting Lissabon',
    uid: 'f1f3a3c6-652a-41c2-87c9-7a7820b2e4b4',
    position: 'Rechtsaußen',
    league: 'LaLiga',
    marketValue: 5000000,
  },
  {
    jerseyNumber: 10,
    name: 'Óscar Plano',
    dateOfBirth: '11.02.1991',
    nationality: 'Spanien',
    height: 1.79,
    foot: 'beidfüßig',
    teamMemberSince: '01.07.2017',
    club: 'Real Valladolid',
    previousClub: 'AD Alcorcón',
    uid: '2d815331-963c-4cde-a279-c688613889ad',
    position: 'Rechtsaußen',
    league: 'LaLiga',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 21,
    name: 'Iván Sánchez',
    dateOfBirth: '23.09.1992',
    nationality: 'Spanien',
    height: 1.69,
    foot: 'links',
    teamMemberSince: '01.07.2022',
    club: 'Real Valladolid',
    previousClub: 'Birmingham City',
    uid: '1ab55ea8-4261-48ec-b707-2b715bdcbd7e',
    position: 'Rechtsaußen',
    league: 'LaLiga',
    marketValue: 800000,
  },
  {
    jerseyNumber: 25,
    name: 'Cyle Larin',
    dateOfBirth: '17.04.1995',
    nationality: 'Kanada',
    height: 1.88,
    foot: 'rechts',
    teamMemberSince: '24.01.2023',
    club: 'Real Valladolid',
    previousClub: 'FC Brügge',
    uid: 'ee35b58d-0ed6-4389-8d75-c1e8b668021c',
    position: 'Mittelstürmer',
    league: 'LaLiga',
    marketValue: 8000000,
  },
  {
    jerseyNumber: 7,
    name: 'Sergio León',
    dateOfBirth: '06.01.1989',
    nationality: 'Spanien',
    height: 1.76,
    foot: 'rechts',
    teamMemberSince: '31.08.2021',
    club: 'Real Valladolid',
    previousClub: 'UD Levante',
    uid: 'e2464c8c-2384-4566-9e10-f3dce31a1d56',
    position: 'Mittelstürmer',
    league: 'LaLiga',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 1,
    name: 'Conan Ledesma',
    dateOfBirth: '13.02.1993',
    nationality: 'Argentinien',
    height: 1.86,
    foot: 'rechts',
    teamMemberSince: '01.07.2021',
    club: 'FC Cádiz',
    previousClub: 'CA Rosario Central',
    uid: '277cdb3a-a5a3-405d-a941-3065db3ceff1',
    position: 'Torwart',
    league: 'LaLiga',
    marketValue: 10000000,
  },
  {
    jerseyNumber: 13,
    name: 'David Gil',
    dateOfBirth: '11.01.1994',
    nationality: 'Spanien',
    height: 1.86,
    foot: 'rechts',
    teamMemberSince: '01.07.2018',
    club: 'FC Cádiz',
    previousClub: ': Ablöse -',
    uid: 'a3b59bdb-7423-40fb-8832-1083df5cf859',
    position: 'Torwart',
    league: 'LaLiga',
    marketValue: 700000,
  },
  {
    jerseyNumber: 32,
    name: 'Víctor Chust',
    dateOfBirth: '05.03.2000',
    nationality: 'Spanien',
    height: 1.84,
    foot: 'beidfüßig',
    teamMemberSince: '06.07.2022',
    club: 'FC Cádiz',
    previousClub: 'Real Madrid',
    uid: '576c9e00-7ad7-4b8b-89de-fdf20aa816c4',
    position: 'Innenverteidiger',
    league: 'LaLiga',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 3,
    name: 'Fali',
    dateOfBirth: '12.08.1993',
    nationality: 'Spanien',
    height: 1.86,
    foot: 'rechts',
    teamMemberSince: '01.07.2019',
    club: 'FC Cádiz',
    previousClub: 'Gimnàstic de Tarragona',
    uid: 'e86d67ba-bd32-4e7d-bcfb-b060f01aabcc',
    position: 'Innenverteidiger',
    league: 'LaLiga',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 25,
    name: 'Jorge Meré',
    dateOfBirth: '17.04.1997',
    nationality: 'Spanien',
    height: 1.82,
    foot: 'rechts',
    teamMemberSince: '31.01.2023',
    club: 'FC Cádiz',
    previousClub: 'CF América',
    uid: 'ca62bbde-f321-4cfe-bcaf-8ed56c305c89',
    position: 'Innenverteidiger',
    league: 'LaLiga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 23,
    name: 'Luis Hernández',
    dateOfBirth: '14.04.1989',
    nationality: 'Spanien',
    height: 1.82,
    foot: 'rechts',
    teamMemberSince: '31.01.2022',
    club: 'FC Cádiz',
    previousClub: 'Maccabi Tel Aviv',
    uid: 'af06030d-010d-4610-95d7-8d42f1c25d4e',
    position: 'Innenverteidiger',
    league: 'LaLiga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 5,
    name: 'Momo Mbaye',
    dateOfBirth: '28.06.1998',
    nationality: 'Senegal',
    height: 1.92,
    foot: 'links',
    teamMemberSince: '09.08.2022',
    club: 'FC Cádiz',
    previousClub: 'FC Cádiz Mirandilla',
    uid: 'c191a2f4-da3c-4917-9e75-c91d8bb9a007',
    position: 'Innenverteidiger',
    league: 'LaLiga',
    marketValue: 700000,
  },
  {
    jerseyNumber: 22,
    name: 'Alfonso Espino',
    dateOfBirth: '05.01.1992',
    nationality: 'Uruguay',
    height: 1.72,
    foot: 'links',
    teamMemberSince: '28.01.2019',
    club: 'FC Cádiz',
    previousClub: 'Club Nacional',
    uid: '101362d2-37be-49a3-bf42-728fe24ee0a3',
    position: 'Linker Verteidiger',
    league: 'LaLiga',
    marketValue: 4000000,
  },
  {
    jerseyNumber: 21,
    name: 'Santiago Arzamendia',
    dateOfBirth: '05.05.1998',
    nationality: 'Paraguay',
    height: 1.72,
    foot: 'links',
    teamMemberSince: '08.07.2021',
    club: 'FC Cádiz',
    previousClub: 'Club Cerro Porteño',
    uid: '0ea488cd-ac0d-4ab0-a1cb-d414a9a010a2',
    position: 'Linker Verteidiger',
    league: 'LaLiga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 20,
    name: 'Iza Carcelén',
    dateOfBirth: '23.04.1993',
    nationality: 'Spanien',
    height: 1.7,
    foot: 'rechts',
    teamMemberSince: '01.07.2019',
    club: 'FC Cádiz',
    previousClub: 'CF Rayo Majadahonda',
    uid: 'bb399354-8a49-4011-9291-971557120fd9',
    position: 'Rechter Verteidiger',
    league: 'LaLiga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 2,
    name: 'Raúl Parra',
    dateOfBirth: '26.11.1999',
    nationality: 'Spanien',
    height: 1.87,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'FC Cádiz',
    previousClub: 'FC Cádiz Mirandilla',
    uid: '2eed2d47-2291-4a6b-8ce1-1e35ab1c8829',
    position: 'Rechter Verteidiger',
    league: 'LaLiga',
    marketValue: 300000,
  },
  {
    jerseyNumber: 6,
    name: 'José Mari',
    dateOfBirth: '06.12.1987',
    nationality: 'Spanien',
    height: 1.82,
    foot: 'rechts',
    teamMemberSince: '16.08.2016',
    club: 'FC Cádiz',
    previousClub: 'UD Levante',
    uid: '85ad9bc0-fe7c-461c-8027-70474d6a1305',
    position: 'Defensives Mittelfeld',
    league: 'LaLiga',
    marketValue: 400000,
  },
  {
    jerseyNumber: 4,
    name: 'Rubén Alcaraz',
    dateOfBirth: '01.05.1991',
    nationality: 'Spanien',
    height: 1.8,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'FC Cádiz',
    previousClub: 'Real Valladolid',
    uid: '76066f0a-ad56-49c3-9d94-e6f65d644aa8',
    position: 'Zentrales Mittelfeld',
    league: 'LaLiga',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 17,
    name: 'Gonzalo Escalante',
    dateOfBirth: '27.03.1993',
    nationality: 'Argentinien',
    height: 1.82,
    foot: 'rechts',
    teamMemberSince: '25.01.2023',
    club: 'FC Cádiz',
    previousClub: 'Lazio Rom',
    uid: '95c67959-3041-4754-a69c-b875834ac5a2',
    position: 'Zentrales Mittelfeld',
    league: 'LaLiga',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 24,
    name: 'Fede San Emeterio',
    dateOfBirth: '16.03.1997',
    nationality: 'Spanien',
    height: 1.76,
    foot: 'rechts',
    teamMemberSince: '07.08.2022',
    club: 'FC Cádiz',
    previousClub: 'Real Valladolid',
    uid: '029e0c15-6564-40d7-9f63-04ee3a8addf5',
    position: 'Zentrales Mittelfeld',
    league: 'LaLiga',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 12,
    name: 'Youba Diarra',
    dateOfBirth: '24.03.1998',
    nationality: 'Mali',
    height: 1.78,
    foot: 'rechts',
    teamMemberSince: '01.01.2023',
    club: 'FC Cádiz',
    previousClub: 'Red Bull Salzburg',
    uid: 'ba2bf6ae-bbfa-44ba-8414-de99282f8ab1',
    position: 'Zentrales Mittelfeld',
    league: 'LaLiga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 8,
    name: 'Álex Fernández',
    dateOfBirth: '15.10.1992',
    nationality: 'Spanien',
    height: 1.83,
    foot: 'rechts',
    teamMemberSince: '07.08.2017',
    club: 'FC Cádiz',
    previousClub: 'FC Elche',
    uid: '7abb114e-2b2a-4607-9ac7-b7c70a41391c',
    position: 'Offensives Mittelfeld',
    league: 'LaLiga',
    marketValue: 5000000,
  },
  {
    jerseyNumber: 14,
    name: 'Brian Ocampo',
    dateOfBirth: '25.06.1999',
    nationality: 'Uruguay',
    height: 1.72,
    foot: 'rechts',
    teamMemberSince: '28.08.2022',
    club: 'FC Cádiz',
    previousClub: 'Club Nacional',
    uid: 'c64937d6-c290-408e-bcb4-19667194129c',
    position: 'Linksaußen',
    league: 'LaLiga',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 10,
    name: 'Théo Bongonda',
    dateOfBirth: '20.11.1995',
    nationality: 'DR Kongo',
    height: 1.77,
    foot: 'links',
    teamMemberSince: '26.08.2022',
    club: 'FC Cádiz',
    previousClub: 'KRC Genk',
    uid: 'cf613cdd-644c-4809-931d-1f75c1e3122b',
    position: 'Rechtsaußen',
    league: 'LaLiga',
    marketValue: 4000000,
  },
  {
    jerseyNumber: 11,
    name: 'Iván Alejo',
    dateOfBirth: '10.02.1995',
    nationality: 'Spanien',
    height: 1.86,
    foot: 'rechts',
    teamMemberSince: '04.08.2020',
    club: 'FC Cádiz',
    previousClub: 'FC Getafe',
    uid: 'dd39caf5-409d-4560-8904-e8bd65081a37',
    position: 'Rechtsaußen',
    league: 'LaLiga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 9,
    name: 'Anthony Lozano',
    dateOfBirth: '25.04.1993',
    nationality: 'Honduras',
    height: 1.83,
    foot: 'rechts',
    teamMemberSince: '04.08.2020',
    club: 'FC Cádiz',
    previousClub: 'FC Girona',
    uid: '2507ddd8-1c9a-4926-af82-2e26493589f2',
    position: 'Mittelstürmer',
    league: 'LaLiga',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 15,
    name: 'Roger Martí',
    dateOfBirth: '03.01.1991',
    nationality: 'Spanien',
    height: 1.79,
    foot: 'rechts',
    teamMemberSince: '26.01.2023',
    club: 'FC Cádiz',
    previousClub: 'FC Elche',
    uid: 'fa0b113b-304d-4d7f-a2c0-f5d1b69c4865',
    position: 'Mittelstürmer',
    league: 'LaLiga',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 19,
    name: 'Sergi Guardiola',
    dateOfBirth: '29.05.1991',
    nationality: 'Spanien',
    height: 1.85,
    foot: 'links',
    teamMemberSince: '31.01.2023',
    club: 'FC Cádiz',
    previousClub: 'Real Valladolid',
    uid: '6021834c-2035-4554-ab7f-a4b05f6f8d77',
    position: 'Mittelstürmer',
    league: 'LaLiga',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 7,
    name: 'Rubén Sobrino',
    dateOfBirth: '01.06.1992',
    nationality: 'Spanien',
    height: 1.85,
    foot: 'rechts',
    teamMemberSince: '30.08.2021',
    club: 'FC Cádiz',
    previousClub: 'FC Valencia',
    uid: '49973193-f44a-4d45-9c8b-5b6040e29a28',
    position: 'Mittelstürmer',
    league: 'LaLiga',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 16,
    name: 'Chris Ramos',
    dateOfBirth: '18.01.1997',
    nationality: 'Spanien',
    height: 1.9,
    foot: 'rechts',
    teamMemberSince: '31.01.2023',
    club: 'FC Cádiz',
    previousClub: 'CD Lugo',
    uid: '009988cd-9582-4e01-bf1a-65fb55fef75f',
    position: 'Mittelstürmer',
    league: 'LaLiga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 18,
    name: 'Álvaro Negredo',
    dateOfBirth: '20.08.1985',
    nationality: 'Spanien',
    height: 1.86,
    foot: 'links',
    teamMemberSince: '04.08.2020',
    club: 'FC Cádiz',
    previousClub: ': Ablöse ablösefrei',
    uid: '11052cf9-1003-4829-a185-754be66ec265',
    position: 'Mittelstürmer',
    league: 'LaLiga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 13,
    name: 'Édgar Badía',
    dateOfBirth: '12.02.1992',
    nationality: 'Spanien',
    height: 1.8,
    foot: 'rechts',
    teamMemberSince: '05.01.2019',
    club: 'FC Elche',
    previousClub: 'CF Reus Deportiu',
    uid: '4d822578-7c39-4d22-ae71-834a04f60b48',
    position: 'Torwart',
    league: 'LaLiga',
    marketValue: 4000000,
  },
  {
    jerseyNumber: 1,
    name: 'Axel Werner',
    dateOfBirth: '28.02.1996',
    nationality: 'Argentinien',
    height: 1.91,
    foot: 'rechts',
    teamMemberSince: '01.09.2021',
    club: 'FC Elche',
    previousClub: 'Vereinslos',
    uid: '8dc8213e-88e8-442f-bc02-15d3ae461dd9',
    position: 'Torwart',
    league: 'LaLiga',
    marketValue: 800000,
  },
  {
    jerseyNumber: 7,
    name: 'Lisandro Magallán',
    dateOfBirth: '27.09.1993',
    nationality: 'Argentinien',
    height: 1.85,
    foot: 'rechts',
    teamMemberSince: '05.01.2023',
    club: 'FC Elche',
    previousClub: 'Ajax Amsterdam',
    uid: '3f6be201-2d5c-47ba-89b1-2a4350d47d3f',
    position: 'Innenverteidiger',
    league: 'LaLiga',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 3,
    name: 'Enzo Roco',
    dateOfBirth: '16.08.1992',
    nationality: 'Chile',
    height: 1.92,
    foot: 'rechts',
    teamMemberSince: '09.07.2021',
    club: 'FC Elche',
    previousClub: 'Fatih Karagümrük',
    uid: 'dea8dbcc-2942-4888-afeb-9f464de401a8',
    position: 'Innenverteidiger',
    league: 'LaLiga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 4,
    name: 'Diego González',
    dateOfBirth: '28.01.1995',
    nationality: 'Spanien',
    height: 1.85,
    foot: 'rechts',
    teamMemberSince: '09.10.2020',
    club: 'FC Elche',
    previousClub: 'FC Málaga',
    uid: 'd701d145-5171-48f7-9c7d-326fa046a0f0',
    position: 'Innenverteidiger',
    league: 'LaLiga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 6,
    name: 'Pedro Bigas',
    dateOfBirth: '15.05.1990',
    nationality: 'Spanien',
    height: 1.81,
    foot: 'links',
    teamMemberSince: '07.07.2021',
    club: 'FC Elche',
    previousClub: 'SD Eibar',
    uid: '3b8f6c3f-d04a-4a6b-a9ff-ec273bc4f386',
    position: 'Innenverteidiger',
    league: 'LaLiga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 5,
    name: 'Gonzalo Verdú',
    dateOfBirth: '21.10.1988',
    nationality: 'Spanien',
    height: 1.88,
    foot: 'rechts',
    teamMemberSince: '01.07.2017',
    club: 'FC Elche',
    previousClub: 'FC Cartagena',
    uid: 'c27118f3-6d51-4870-8430-d715304f61a7',
    position: 'Innenverteidiger',
    league: 'LaLiga',
    marketValue: 700000,
  },
  {
    jerseyNumber: 26,
    name: 'John Donald',
    dateOfBirth: '25.09.2000',
    nationality: 'Spanien',
    height: 1.82,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'FC Elche',
    previousClub: 'Elche Ilicitano',
    uid: '3c5c0350-4c6a-417b-90af-05472ae58605',
    position: 'Innenverteidiger',
    league: 'LaLiga',
    marketValue: 400000,
  },
  {
    jerseyNumber: 2,
    name: 'Lautaro Blanco',
    dateOfBirth: '19.02.1999',
    nationality: 'Argentinien',
    height: 1.76,
    foot: 'links',
    teamMemberSince: '05.08.2022',
    club: 'FC Elche',
    previousClub: 'CA Rosario Central',
    uid: '520b3259-8028-4a35-9cef-6490fa423228',
    position: 'Linker Verteidiger',
    league: 'LaLiga',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 22,
    name: 'Nicolás Fernández Mercau',
    dateOfBirth: '11.01.2000',
    nationality: 'Argentinien',
    height: 1.72,
    foot: 'links',
    teamMemberSince: '01.09.2022',
    club: 'FC Elche',
    previousClub: 'CA San Lorenzo de Almagro',
    uid: 'be714026-4df6-4784-a072-a8c2af85c1ce',
    position: 'Linker Verteidiger',
    league: 'LaLiga',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 23,
    name: 'Carlos Clerc',
    dateOfBirth: '21.02.1992',
    nationality: 'Spanien',
    height: 1.81,
    foot: 'links',
    teamMemberSince: '21.07.2022',
    club: 'FC Elche',
    previousClub: 'UD Levante',
    uid: '8b7bde4c-a69f-4076-aede-6839ebde6249',
    position: 'Linker Verteidiger',
    league: 'LaLiga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 40,
    name: 'José Ángel Carmona',
    dateOfBirth: '29.01.2002',
    nationality: 'Spanien',
    height: 1.84,
    foot: 'rechts',
    teamMemberSince: '11.01.2023',
    club: 'FC Elche',
    previousClub: 'FC Sevilla',
    uid: 'b4f64f71-dfc8-498b-8f42-946af9f7db60',
    position: 'Rechter Verteidiger',
    league: 'LaLiga',
    marketValue: 4000000,
  },
  {
    jerseyNumber: 24,
    name: 'Pol Lirola',
    dateOfBirth: '13.08.1997',
    nationality: 'Spanien',
    height: 1.83,
    foot: 'rechts',
    teamMemberSince: '12.08.2022',
    club: 'FC Elche',
    previousClub: 'Olympique Marseille',
    uid: '97bfb218-8c16-4e52-852a-c6f598ba030c',
    position: 'Rechter Verteidiger',
    league: 'LaLiga',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 14,
    name: 'Helibelton Palacios',
    dateOfBirth: '11.06.1993',
    nationality: 'Kolumbien',
    height: 1.81,
    foot: 'rechts',
    teamMemberSince: '04.02.2021',
    club: 'FC Elche',
    previousClub: 'Vereinslos',
    uid: '12103b14-6c31-44b4-94df-041a5260dafd',
    position: 'Rechter Verteidiger',
    league: 'LaLiga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 21,
    name: 'Omar Mascarell',
    dateOfBirth: '02.02.1993',
    nationality: 'Spanien',
    height: 1.81,
    foot: 'rechts',
    teamMemberSince: '23.08.2021',
    club: 'FC Elche',
    previousClub: 'FC Schalke 04',
    uid: 'c9b1960a-a1e2-4544-87b0-20edc6f15b25',
    position: 'Defensives Mittelfeld',
    league: 'LaLiga',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 20,
    name: 'Gerard Gumbau',
    dateOfBirth: '18.12.1994',
    nationality: 'Spanien',
    height: 1.88,
    foot: 'links',
    teamMemberSince: '31.08.2021',
    club: 'FC Elche',
    previousClub: 'FC Girona',
    uid: '0c49e182-d995-4a34-99c7-17e46eaf1cff',
    position: 'Defensives Mittelfeld',
    league: 'LaLiga',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 8,
    name: 'Raúl Guti',
    dateOfBirth: '30.12.1996',
    nationality: 'Spanien',
    height: 1.78,
    foot: 'rechts',
    teamMemberSince: '20.09.2020',
    club: 'FC Elche',
    previousClub: 'Real Saragossa',
    uid: '80948d96-d104-45cf-848f-54de4ba51849',
    position: 'Zentrales Mittelfeld',
    league: 'LaLiga',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 12,
    name: 'Pape Cheikh',
    dateOfBirth: '08.08.1997',
    nationality: 'Senegal',
    height: 1.8,
    foot: 'rechts',
    teamMemberSince: '14.02.2023',
    club: 'FC Elche',
    previousClub: 'Vereinslos',
    uid: '576c7670-65eb-4cb2-8625-073ab01051c0',
    position: 'Zentrales Mittelfeld',
    league: 'LaLiga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 18,
    name: 'Randy Nteka',
    dateOfBirth: '06.12.1997',
    nationality: 'Frankreich',
    height: 1.89,
    foot: 'links',
    teamMemberSince: '31.01.2023',
    club: 'FC Elche',
    previousClub: 'Rayo Vallecano',
    uid: '7da0fe8e-fe90-45b0-a64c-1540c34fc3dc',
    position: 'Offensives Mittelfeld',
    league: 'LaLiga',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 10,
    name: 'Pere Milla',
    dateOfBirth: '23.09.1992',
    nationality: 'Spanien',
    height: 1.8,
    foot: 'links',
    teamMemberSince: '01.07.2019',
    club: 'FC Elche',
    previousClub: 'SD Eibar',
    uid: 'c2957051-0689-4371-93b7-0f880f3ce67e',
    position: 'Linksaußen',
    league: 'LaLiga',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 11,
    name: 'Tete Morente',
    dateOfBirth: '04.12.1996',
    nationality: 'Spanien',
    height: 1.84,
    foot: 'rechts',
    teamMemberSince: '16.09.2020',
    club: 'FC Elche',
    previousClub: 'FC Málaga',
    uid: '14373ae6-643d-427a-ae2d-64738cdd5112',
    position: 'Linksaußen',
    league: 'LaLiga',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 16,
    name: 'Fidel',
    dateOfBirth: '27.10.1989',
    nationality: 'Spanien',
    height: 1.8,
    foot: 'links',
    teamMemberSince: '01.07.2019',
    club: 'FC Elche',
    previousClub: 'UD Las Palmas',
    uid: '4c4161ed-5983-4263-8104-84629024f823',
    position: 'Linksaußen',
    league: 'LaLiga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 15,
    name: 'Álex Collado',
    dateOfBirth: '22.04.1999',
    nationality: 'Spanien',
    height: 1.77,
    foot: 'links',
    teamMemberSince: '15.08.2022',
    club: 'FC Elche',
    previousClub: 'FC Barcelona',
    uid: '4a3fcd27-9f47-444a-8a91-728c02da81ff',
    position: 'Rechtsaußen',
    league: 'LaLiga',
    marketValue: 4000000,
  },
  {
    jerseyNumber: 17,
    name: 'Josan',
    dateOfBirth: '03.12.1989',
    nationality: 'Spanien',
    height: 1.76,
    foot: 'rechts',
    teamMemberSince: '01.01.2018',
    club: 'FC Elche',
    previousClub: 'Albacete Balompié',
    uid: '17a1f85d-83ee-47cb-8a2c-f9152f0a7dff',
    position: 'Rechtsaußen',
    league: 'LaLiga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 9,
    name: 'Lucas Boyé',
    dateOfBirth: '28.02.1996',
    nationality: 'Argentinien',
    height: 1.83,
    foot: 'rechts',
    teamMemberSince: '01.07.2021',
    club: 'FC Elche',
    previousClub: 'FC Turin',
    uid: '5ff74259-17e7-4378-88ff-68d2c8b23385',
    position: 'Mittelstürmer',
    league: 'LaLiga',
    marketValue: 6000000,
  },
  {
    jerseyNumber: 19,
    name: 'Ezequiel Ponce',
    dateOfBirth: '29.03.1997',
    nationality: 'Argentinien',
    height: 1.81,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'FC Elche',
    previousClub: 'Spartak Moskau',
    uid: '013d0040-10c3-474f-adf0-d3ceda4d4300',
    position: 'Mittelstürmer',
    league: 'LaLiga',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 31,
    name: 'Ederson',
    dateOfBirth: '17.08.1993',
    nationality: 'Brasilien',
    height: 1.88,
    foot: 'links',
    teamMemberSince: '01.07.2017',
    club: 'Manchester City',
    previousClub: 'Benfica Lissabon',
    uid: '5fd7c12e-8007-446c-9073-fb0dc08bfe9b',
    position: 'Torwart',
    league: 'Premier League',
    marketValue: 45000000,
  },
  {
    jerseyNumber: 18,
    name: 'Stefan Ortega',
    dateOfBirth: '06.11.1992',
    nationality: 'Deutschland',
    height: 1.85,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'Manchester City',
    previousClub: 'Arminia Bielefeld',
    uid: '1dcf2bcd-54e6-400f-b7ca-0f6723623b9d',
    position: 'Torwart',
    league: 'Premier League',
    marketValue: 6000000,
  },
  {
    jerseyNumber: 33,
    name: 'Scott Carson',
    dateOfBirth: '03.09.1985',
    nationality: 'England',
    height: 1.88,
    foot: 'rechts',
    teamMemberSince: '20.07.2021',
    club: 'Manchester City',
    previousClub: 'Derby County',
    uid: 'da41f9f8-94bf-4325-97e4-33f9ffe44e5d',
    position: 'Torwart',
    league: 'Premier League',
    marketValue: 250000,
  },
  {
    jerseyNumber: 3,
    name: 'Rúben Dias',
    dateOfBirth: '14.05.1997',
    nationality: 'Portugal',
    height: 1.87,
    foot: 'rechts',
    teamMemberSince: '29.09.2020',
    club: 'Manchester City',
    previousClub: 'Benfica Lissabon',
    uid: '89ad1640-fc5c-4634-8aa8-d29a56ce1fa1',
    position: 'Innenverteidiger',
    league: 'Premier League',
    marketValue: 75000000,
  },
  {
    jerseyNumber: 6,
    name: 'Nathan Aké',
    dateOfBirth: '18.02.1995',
    nationality: 'Niederlande',
    height: 1.8,
    foot: 'links',
    teamMemberSince: '05.08.2020',
    club: 'Manchester City',
    previousClub: 'AFC Bournemouth',
    uid: '638aa7fd-7713-4b8a-a95c-ed6de9aa625c',
    position: 'Innenverteidiger',
    league: 'Premier League',
    marketValue: 35000000,
  },
  {
    jerseyNumber: 5,
    name: 'John Stones',
    dateOfBirth: '28.05.1994',
    nationality: 'England',
    height: 1.88,
    foot: 'rechts',
    teamMemberSince: '09.08.2016',
    club: 'Manchester City',
    previousClub: 'FC Everton',
    uid: '8ef0eee0-88bd-4da6-9381-a22e6eb175bf',
    position: 'Innenverteidiger',
    league: 'Premier League',
    marketValue: 30000000,
  },
  {
    jerseyNumber: 14,
    name: 'Aymeric Laporte',
    dateOfBirth: '27.05.1994',
    nationality: 'Spanien',
    height: 1.89,
    foot: 'links',
    teamMemberSince: '30.01.2018',
    club: 'Manchester City',
    previousClub: 'Athletic Bilbao',
    uid: '892da5d1-55be-4f9a-9e0c-5d4ac5a43e7d',
    position: 'Innenverteidiger',
    league: 'Premier League',
    marketValue: 30000000,
  },
  {
    jerseyNumber: 25,
    name: 'Manuel Akanji',
    dateOfBirth: '19.07.1995',
    nationality: 'Schweiz',
    height: 1.88,
    foot: 'rechts',
    teamMemberSince: '01.09.2022',
    club: 'Manchester City',
    previousClub: 'Borussia Dortmund',
    uid: '4fce5871-e82d-4add-a7a4-59e4e92f014b',
    position: 'Innenverteidiger',
    league: 'Premier League',
    marketValue: 30000000,
  },
  {
    jerseyNumber: 21,
    name: 'Sergio Gómez',
    dateOfBirth: '04.09.2000',
    nationality: 'Spanien',
    height: 1.71,
    foot: 'links',
    teamMemberSince: '16.08.2022',
    club: 'Manchester City',
    previousClub: 'RSC Anderlecht',
    uid: 'fc7b4fa9-7925-4c91-b2aa-99f5a6029eb4',
    position: 'Linker Verteidiger',
    league: 'Premier League',
    marketValue: 15000000,
  },
  {
    jerseyNumber: 2,
    name: 'Kyle Walker',
    dateOfBirth: '28.05.1990',
    nationality: 'England',
    height: 1.83,
    foot: 'rechts',
    teamMemberSince: '14.07.2017',
    club: 'Manchester City',
    previousClub: 'Tottenham Hotspur',
    uid: '86992d7f-8f16-4d4f-bb98-59897593c486',
    position: 'Rechter Verteidiger',
    league: 'Premier League',
    marketValue: 15000000,
  },
  {
    jerseyNumber: 82,
    name: 'Rico Lewis',
    dateOfBirth: '21.11.2004',
    nationality: 'England',
    height: 1.69,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'Manchester City',
    previousClub: 'Manchester City U18',
    uid: 'f9db4262-8645-45ec-81ee-1801dedb1d5e',
    position: 'Rechter Verteidiger',
    league: 'Premier League',
    marketValue: 15000000,
  },
  {
    jerseyNumber: 16,
    name: 'Rodri',
    dateOfBirth: '22.06.1996',
    nationality: 'Spanien',
    height: 1.91,
    foot: 'rechts',
    teamMemberSince: '04.07.2019',
    club: 'Manchester City',
    previousClub: 'Atlético Madrid',
    uid: '3d8da9f5-51d4-4220-a165-1d99896253f6',
    position: 'Defensives Mittelfeld',
    league: 'Premier League',
    marketValue: 80000000,
  },
  {
    jerseyNumber: 4,
    name: 'Kalvin Phillips',
    dateOfBirth: '02.12.1995',
    nationality: 'England',
    height: 1.79,
    foot: 'rechts',
    teamMemberSince: '04.07.2022',
    club: 'Manchester City',
    previousClub: 'Leeds United',
    uid: '75760ba4-c924-4b01-8bfb-c8617025438f',
    position: 'Defensives Mittelfeld',
    league: 'Premier League',
    marketValue: 35000000,
  },
  {
    jerseyNumber: 32,
    name: 'Máximo Perrone',
    dateOfBirth: '07.01.2003',
    nationality: 'Argentinien',
    height: 1.77,
    foot: 'links',
    teamMemberSince: '23.01.2023',
    club: 'Manchester City',
    previousClub: 'Vélez Sarsfield',
    uid: '1d46e83f-eece-4733-9650-8eaf2c673fbb',
    position: 'Defensives Mittelfeld',
    league: 'Premier League',
    marketValue: 10000000,
  },
  {
    jerseyNumber: 8,
    name: 'Ilkay Gündogan',
    dateOfBirth: '24.10.1990',
    nationality: 'Deutschland',
    height: 1.8,
    foot: 'rechts',
    teamMemberSince: '01.07.2016',
    club: 'Manchester City',
    previousClub: 'Borussia Dortmund',
    uid: '7e35068c-70b6-467b-8989-83adb690e44c',
    position: 'Zentrales Mittelfeld',
    league: 'Premier League',
    marketValue: 25000000,
  },
  {
    jerseyNumber: 17,
    name: 'Kevin De Bruyne',
    dateOfBirth: '28.06.1991',
    nationality: 'Belgien',
    height: 1.81,
    foot: 'rechts',
    teamMemberSince: '30.08.2015',
    club: 'Manchester City',
    previousClub: 'VfL Wolfsburg',
    uid: '1ce0a1df-c81f-4f8b-9183-1241c9b1d179',
    position: 'Offensives Mittelfeld',
    league: 'Premier League',
    marketValue: 80000000,
  },
  {
    jerseyNumber: 20,
    name: 'Bernardo Silva',
    dateOfBirth: '10.08.1994',
    nationality: 'Portugal',
    height: 1.73,
    foot: 'links',
    teamMemberSince: '01.07.2017',
    club: 'Manchester City',
    previousClub: 'AS Monaco',
    uid: '10a2a0da-2ea7-43e0-a263-2ad4d9c1816f',
    position: 'Offensives Mittelfeld',
    league: 'Premier League',
    marketValue: 80000000,
  },
  {
    jerseyNumber: 80,
    name: 'Cole Palmer',
    dateOfBirth: '06.05.2002',
    nationality: 'England',
    height: 1.89,
    foot: 'links',
    teamMemberSince: '01.07.2021',
    club: 'Manchester City',
    previousClub: 'Manchester City U23',
    uid: '14eb78b7-8a04-4239-b258-bbb89df01991',
    position: 'Offensives Mittelfeld',
    league: 'Premier League',
    marketValue: 15000000,
  },
  {
    jerseyNumber: 47,
    name: 'Phil Foden',
    dateOfBirth: '28.05.2000',
    nationality: 'England',
    height: 1.71,
    foot: 'links',
    teamMemberSince: '01.07.2017',
    club: 'Manchester City',
    previousClub: 'Manchester City U18',
    uid: 'c7ab4c26-9c85-4a72-951a-5be2686c1a08',
    position: 'Linksaußen',
    league: 'Premier League',
    marketValue: 110000000,
  },
  {
    jerseyNumber: 10,
    name: 'Jack Grealish',
    dateOfBirth: '10.09.1995',
    nationality: 'England',
    height: 1.8,
    foot: 'rechts',
    teamMemberSince: '05.08.2021',
    club: 'Manchester City',
    previousClub: 'Aston Villa',
    uid: 'd90496b5-0dc9-4a4c-9b44-10e255923793',
    position: 'Linksaußen',
    league: 'Premier League',
    marketValue: 70000000,
  },
  {
    jerseyNumber: 26,
    name: 'Riyad Mahrez',
    dateOfBirth: '21.02.1991',
    nationality: 'Algerien',
    height: 1.79,
    foot: 'links',
    teamMemberSince: '10.07.2018',
    club: 'Manchester City',
    previousClub: 'Leicester City',
    uid: 'c57c925d-0eb5-4f4e-b26f-ede0972c61d2',
    position: 'Rechtsaußen',
    league: 'Premier League',
    marketValue: 30000000,
  },
  {
    jerseyNumber: 9,
    name: 'Erling Haaland',
    dateOfBirth: '21.07.2000',
    nationality: 'Norwegen',
    height: 1.95,
    foot: 'links',
    teamMemberSince: '01.07.2022',
    club: 'Manchester City',
    previousClub: 'Borussia Dortmund',
    uid: '430878a5-9b40-410d-abe8-37543663e2ab',
    position: 'Mittelstürmer',
    league: 'Premier League',
    marketValue: 170000000,
  },
  {
    jerseyNumber: 19,
    name: 'Julián Álvarez',
    dateOfBirth: '31.01.2000',
    nationality: 'Argentinien',
    height: 1.7,
    foot: 'rechts',
    teamMemberSince: '31.01.2022',
    club: 'Manchester City',
    previousClub: ': Ablöse 21,40 Mio. €',
    uid: '01835c8b-0288-4e1c-8083-2556d439aaeb',
    position: 'Mittelstürmer',
    league: 'Premier League',
    marketValue: 50000000,
  },
  {
    jerseyNumber: 16,
    name: 'Edouard Mendy',
    dateOfBirth: '01.03.1992',
    nationality: 'Senegal',
    height: 1.94,
    foot: 'rechts',
    teamMemberSince: '24.09.2020',
    club: 'FC Chelsea',
    previousClub: 'FC Stade Rennes',
    uid: 'c1ce996b-c880-4a2c-895b-3ccfe5d2b386',
    position: 'Torwart',
    league: 'Premier League',
    marketValue: 18000000,
  },
  {
    jerseyNumber: 1,
    name: 'Kepa Arrizabalaga',
    dateOfBirth: '03.10.1994',
    nationality: 'Spanien',
    height: 1.86,
    foot: 'rechts',
    teamMemberSince: '08.08.2018',
    club: 'FC Chelsea',
    previousClub: 'Athletic Bilbao',
    uid: '39d736a0-5e18-4e3b-9f13-4f2f804cbcfe',
    position: 'Torwart',
    league: 'Premier League',
    marketValue: 15000000,
  },
  {
    jerseyNumber: 36,
    name: 'Gabriel Slonina',
    dateOfBirth: '15.05.2004',
    nationality: 'Vereinigte Staaten',
    height: 1.93,
    foot: 'rechts',
    teamMemberSince: '02.08.2022',
    club: 'FC Chelsea',
    previousClub: 'Chicago Fire FC',
    uid: '0ebc0784-d498-4f4a-87fc-7b3232920bf2',
    position: 'Torwart',
    league: 'Premier League',
    marketValue: 8000000,
  },
  {
    jerseyNumber: 13,
    name: 'Marcus Bettinelli',
    dateOfBirth: '24.05.1992',
    nationality: 'England',
    height: 1.93,
    foot: 'rechts',
    teamMemberSince: '28.07.2021',
    club: 'FC Chelsea',
    previousClub: 'FC Fulham',
    uid: '5a80355b-42a9-4fc1-a5a7-36fc19d3a81c',
    position: 'Torwart',
    league: 'Premier League',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 33,
    name: 'Wesley Fofana',
    dateOfBirth: '17.12.2000',
    nationality: 'Frankreich',
    height: 1.86,
    foot: 'rechts',
    teamMemberSince: '31.08.2022',
    club: 'FC Chelsea',
    previousClub: 'Leicester City',
    uid: '14ff7e21-fc7c-4371-83a5-bfdf5d4fcd73',
    position: 'Innenverteidiger',
    league: 'Premier League',
    marketValue: 65000000,
  },
  {
    jerseyNumber: 4,
    name: 'Benoît Badiashile',
    dateOfBirth: '26.03.2001',
    nationality: 'Frankreich',
    height: 1.94,
    foot: 'links',
    teamMemberSince: '05.01.2023',
    club: 'FC Chelsea',
    previousClub: 'AS Monaco',
    uid: '10f6d8c4-d734-4727-a7ca-bee6308becd8',
    position: 'Innenverteidiger',
    league: 'Premier League',
    marketValue: 40000000,
  },
  {
    jerseyNumber: 26,
    name: 'Kalidou Koulibaly',
    dateOfBirth: '20.06.1991',
    nationality: 'Senegal',
    height: 1.86,
    foot: 'rechts',
    teamMemberSince: '16.07.2022',
    club: 'FC Chelsea',
    previousClub: 'SSC Neapel',
    uid: '0c45fea4-3688-403d-95f7-47ae49e9589b',
    position: 'Innenverteidiger',
    league: 'Premier League',
    marketValue: 25000000,
  },
  {
    jerseyNumber: 14,
    name: 'Trevoh Chalobah',
    dateOfBirth: '05.07.1999',
    nationality: 'England',
    height: 1.92,
    foot: 'rechts',
    teamMemberSince: '01.07.2021',
    club: 'FC Chelsea',
    previousClub: 'FC Chelsea U23',
    uid: 'c7c510d6-0fee-4567-8e49-3be2e34b6622',
    position: 'Innenverteidiger',
    league: 'Premier League',
    marketValue: 22000000,
  },
  {
    jerseyNumber: 6,
    name: 'Thiago Silva',
    dateOfBirth: '22.09.1984',
    nationality: 'Brasilien',
    height: 1.81,
    foot: 'rechts',
    teamMemberSince: '28.08.2020',
    club: 'FC Chelsea',
    previousClub: 'FC Paris Saint-Germain',
    uid: 'ef962439-4c0b-4160-b7a0-1b0566d746a6',
    position: 'Innenverteidiger',
    league: 'Premier League',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 32,
    name: 'Marc Cucurella',
    dateOfBirth: '22.07.1998',
    nationality: 'Spanien',
    height: 1.73,
    foot: 'links',
    teamMemberSince: '05.08.2022',
    club: 'FC Chelsea',
    previousClub: 'Brighton & Hove Albion',
    uid: '6b969788-9428-4dde-a742-26c403d0a506',
    position: 'Linker Verteidiger',
    league: 'Premier League',
    marketValue: 40000000,
  },
  {
    jerseyNumber: 21,
    name: 'Ben Chilwell',
    dateOfBirth: '21.12.1996',
    nationality: 'England',
    height: 1.8,
    foot: 'links',
    teamMemberSince: '26.08.2020',
    club: 'FC Chelsea',
    previousClub: 'Leicester City',
    uid: '9f8ce6bc-52da-4a82-9ad4-75b547243fcb',
    position: 'Linker Verteidiger',
    league: 'Premier League',
    marketValue: 35000000,
  },
  {
    jerseyNumber: 24,
    name: 'Reece James',
    dateOfBirth: '08.12.1999',
    nationality: 'England',
    height: 1.8,
    foot: 'rechts',
    teamMemberSince: '01.07.2019',
    club: 'FC Chelsea',
    previousClub: 'FC Chelsea U23',
    uid: 'ae438401-e063-4fa8-a3c7-00209e7b928c',
    position: 'Rechter Verteidiger',
    league: 'Premier League',
    marketValue: 70000000,
  },
  {
    jerseyNumber: 28,
    name: 'César Azpilicueta',
    dateOfBirth: '28.08.1989',
    nationality: 'Spanien',
    height: 1.78,
    foot: 'rechts',
    teamMemberSince: '24.08.2012',
    club: 'FC Chelsea',
    previousClub: 'Olympique Marseille',
    uid: '08a33f90-ab89-4be3-b519-82af36d4a034',
    position: 'Rechter Verteidiger',
    league: 'Premier League',
    marketValue: 8000000,
  },
  {
    jerseyNumber: 5,
    name: 'Enzo Fernández',
    dateOfBirth: '17.01.2001',
    nationality: 'Argentinien',
    height: 1.78,
    foot: 'rechts',
    teamMemberSince: '31.01.2023',
    club: 'FC Chelsea',
    previousClub: 'Benfica Lissabon',
    uid: 'f1a53468-5664-4f05-b87c-662e2f638e1d',
    position: 'Defensives Mittelfeld',
    league: 'Premier League',
    marketValue: 85000000,
  },
  {
    jerseyNumber: 7,
    name: "N'Golo Kanté",
    dateOfBirth: '29.03.1991',
    nationality: 'Frankreich',
    height: 1.71,
    foot: 'rechts',
    teamMemberSince: '16.07.2016',
    club: 'FC Chelsea',
    previousClub: 'Leicester City',
    uid: '1e5b6850-4a5a-4606-a5d4-40f44540a51b',
    position: 'Defensives Mittelfeld',
    league: 'Premier League',
    marketValue: 20000000,
  },
  {
    jerseyNumber: 20,
    name: 'Denis Zakaria',
    dateOfBirth: '20.11.1996',
    nationality: 'Schweiz',
    height: 1.9,
    foot: 'rechts',
    teamMemberSince: '01.09.2022',
    club: 'FC Chelsea',
    previousClub: 'Juventus Turin',
    uid: '07264841-567b-4bef-a26a-fc8322e8d915',
    position: 'Defensives Mittelfeld',
    league: 'Premier League',
    marketValue: 20000000,
  },
  {
    jerseyNumber: 8,
    name: 'Mateo Kovacic',
    dateOfBirth: '06.05.1994',
    nationality: 'Kroatien',
    height: 1.77,
    foot: 'rechts',
    teamMemberSince: '01.07.2019',
    club: 'FC Chelsea',
    previousClub: 'Real Madrid',
    uid: '59a906b9-3519-4442-88c6-bf768e2e58fc',
    position: 'Zentrales Mittelfeld',
    league: 'Premier League',
    marketValue: 40000000,
  },
  {
    jerseyNumber: 23,
    name: 'Conor Gallagher',
    dateOfBirth: '06.02.2000',
    nationality: 'England',
    height: 1.82,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'FC Chelsea',
    previousClub: 'FC Chelsea U21',
    uid: '91b76b91-3b32-454f-b793-2b2eb4b1c11b',
    position: 'Zentrales Mittelfeld',
    league: 'Premier League',
    marketValue: 32000000,
  },
  {
    jerseyNumber: 12,
    name: 'Ruben Loftus-Cheek',
    dateOfBirth: '23.01.1996',
    nationality: 'England',
    height: 1.91,
    foot: 'rechts',
    teamMemberSince: '01.01.2015',
    club: 'FC Chelsea',
    previousClub: 'FC Chelsea U21',
    uid: '029bb673-e33e-4cd0-b54b-42b68b9be339',
    position: 'Zentrales Mittelfeld',
    league: 'Premier League',
    marketValue: 25000000,
  },
  {
    jerseyNumber: 30,
    name: 'Carney Chukwuemeka',
    dateOfBirth: '20.10.2003',
    nationality: 'England',
    height: 1.87,
    foot: 'rechts',
    teamMemberSince: '04.08.2022',
    club: 'FC Chelsea',
    previousClub: 'Aston Villa',
    uid: 'c8db2c21-86c9-451a-8284-80ca983a1e24',
    position: 'Zentrales Mittelfeld',
    league: 'Premier League',
    marketValue: 15000000,
  },
  {
    jerseyNumber: 19,
    name: 'Mason Mount',
    dateOfBirth: '10.01.1999',
    nationality: 'England',
    height: 1.81,
    foot: 'rechts',
    teamMemberSince: '01.07.2019',
    club: 'FC Chelsea',
    previousClub: 'FC Chelsea U23',
    uid: '25cd6fde-5e43-41f6-9a08-bdce235c3875',
    position: 'Offensives Mittelfeld',
    league: 'Premier League',
    marketValue: 65000000,
  },
  {
    jerseyNumber: 29,
    name: 'Kai Havertz',
    dateOfBirth: '11.06.1999',
    nationality: 'Deutschland',
    height: 1.93,
    foot: 'links',
    teamMemberSince: '04.09.2020',
    club: 'FC Chelsea',
    previousClub: 'Bayer 04 Leverkusen',
    uid: 'eb299df1-202d-4632-b78f-4f03c0353dd0',
    position: 'Offensives Mittelfeld',
    league: 'Premier League',
    marketValue: 60000000,
  },
  {
    jerseyNumber: 15,
    name: 'Mykhaylo Mudryk',
    dateOfBirth: '05.01.2001',
    nationality: 'Ukraine',
    height: 1.75,
    foot: 'rechts',
    teamMemberSince: '15.01.2023',
    club: 'FC Chelsea',
    previousClub: 'Shakhtar Donetsk',
    uid: '0a2de53a-527e-42a1-87e1-d70ded4f773c',
    position: 'Linksaußen',
    league: 'Premier League',
    marketValue: 60000000,
  },
  {
    jerseyNumber: 17,
    name: 'Raheem Sterling',
    dateOfBirth: '08.12.1994',
    nationality: 'England',
    height: 1.7,
    foot: 'rechts',
    teamMemberSince: '13.07.2022',
    club: 'FC Chelsea',
    previousClub: 'Manchester City',
    uid: 'b1f3dfa0-ce29-45d9-bf44-b821c7517928',
    position: 'Linksaußen',
    league: 'Premier League',
    marketValue: 60000000,
  },
  {
    jerseyNumber: 10,
    name: 'Christian Pulisic',
    dateOfBirth: '18.09.1998',
    nationality: 'Vereinigte Staaten',
    height: 1.77,
    foot: 'rechts',
    teamMemberSince: '02.01.2019',
    club: 'FC Chelsea',
    previousClub: 'Borussia Dortmund',
    uid: '2f3543e3-a6d9-43b8-bad6-02a7ac528d06',
    position: 'Rechtsaußen',
    league: 'Premier League',
    marketValue: 32000000,
  },
  {
    jerseyNumber: 31,
    name: 'Noni Madueke',
    dateOfBirth: '10.03.2002',
    nationality: 'England',
    height: 1.82,
    foot: 'links',
    teamMemberSince: '20.01.2023',
    club: 'FC Chelsea',
    previousClub: 'PSV Eindhoven',
    uid: 'ec8981c4-817e-4ab0-8073-063e39351833',
    position: 'Rechtsaußen',
    league: 'Premier League',
    marketValue: 28000000,
  },
  {
    jerseyNumber: 22,
    name: 'Hakim Ziyech',
    dateOfBirth: '19.03.1993',
    nationality: 'Marokko',
    height: 1.81,
    foot: 'links',
    teamMemberSince: '01.07.2020',
    club: 'FC Chelsea',
    previousClub: 'Ajax Amsterdam',
    uid: '48fe5314-c38d-4304-9be8-56e464229e71',
    position: 'Rechtsaußen',
    league: 'Premier League',
    marketValue: 18000000,
  },
  {
    jerseyNumber: 11,
    name: 'João Félix',
    dateOfBirth: '10.11.1999',
    nationality: 'Portugal',
    height: 1.81,
    foot: 'rechts',
    teamMemberSince: '11.01.2023',
    club: 'FC Chelsea',
    previousClub: 'Atlético Madrid',
    uid: 'ac9a74a4-fde8-433d-a422-c01b8f6ee4b8',
    position: 'Hängende Spitze',
    league: 'Premier League',
    marketValue: 50000000,
  },
  {
    jerseyNumber: 18,
    name: 'Armando Broja',
    dateOfBirth: '10.09.2001',
    nationality: 'Albanien',
    height: 1.91,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'FC Chelsea',
    previousClub: 'FC Chelsea U21',
    uid: 'b85f6b61-5c75-4858-b6a5-3672e8b3c386',
    position: 'Mittelstürmer',
    league: 'Premier League',
    marketValue: 30000000,
  },
  {
    jerseyNumber: 27,
    name: 'David Datro Fofana',
    dateOfBirth: '22.12.2002',
    nationality: 'Elfenbeinküste',
    height: 1.81,
    foot: 'rechts',
    teamMemberSince: '01.01.2023',
    club: 'FC Chelsea',
    previousClub: 'Molde FK',
    uid: 'f7b571fc-0f54-441a-94c4-9c386b507f45',
    position: 'Mittelstürmer',
    league: 'Premier League',
    marketValue: 10000000,
  },
  {
    jerseyNumber: 9,
    name: 'Pierre-Emerick Aubameyang',
    dateOfBirth: '18.06.1989',
    nationality: 'Gabun',
    height: 1.86,
    foot: 'rechts',
    teamMemberSince: '01.09.2022',
    club: 'FC Chelsea',
    previousClub: 'FC Barcelona',
    uid: '6cf7b963-890c-45e8-a160-95f8d9aef93a',
    position: 'Mittelstürmer',
    league: 'Premier League',
    marketValue: 7000000,
  },
  {
    jerseyNumber: 1,
    name: 'Aaron Ramsdale',
    dateOfBirth: '14.05.1998',
    nationality: 'England',
    height: 1.9,
    foot: 'rechts',
    teamMemberSince: '20.08.2021',
    club: 'FC Arsenal',
    previousClub: 'Sheffield United',
    uid: '9abdf16e-0eb2-4cc7-bc0f-9c2999fefb62',
    position: 'Torwart',
    league: 'Premier League',
    marketValue: 38000000,
  },
  {
    jerseyNumber: 30,
    name: 'Matt Turner',
    dateOfBirth: '24.06.1994',
    nationality: 'Vereinigte Staaten',
    height: 1.91,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'FC Arsenal',
    previousClub: 'New England Revolution',
    uid: '76d9f75b-7027-4a28-b7f4-a66e3a8a976e',
    position: 'Torwart',
    league: 'Premier League',
    marketValue: 5000000,
  },
  {
    jerseyNumber: 12,
    name: 'William Saliba',
    dateOfBirth: '24.03.2001',
    nationality: 'Frankreich',
    height: 1.92,
    foot: 'rechts',
    teamMemberSince: '25.07.2019',
    club: 'FC Arsenal',
    previousClub: ': Ablöse 30,00 Mio. €',
    uid: 'eed0a638-341c-457c-b0d0-3ad33f23dbca',
    position: 'Innenverteidiger',
    league: 'Premier League',
    marketValue: 55000000,
  },
  {
    jerseyNumber: 6,
    name: 'Gabriel Magalhães',
    dateOfBirth: '19.12.1997',
    nationality: 'Brasilien',
    height: 1.9,
    foot: 'links',
    teamMemberSince: '01.09.2020',
    club: 'FC Arsenal',
    previousClub: 'LOSC Lille',
    uid: 'ceff15a8-71cd-48b4-b9d5-3070339dfc8c',
    position: 'Innenverteidiger',
    league: 'Premier League',
    marketValue: 50000000,
  },
  {
    jerseyNumber: 15,
    name: 'Jakub Kiwior',
    dateOfBirth: '15.02.2000',
    nationality: 'Polen',
    height: 1.89,
    foot: 'links',
    teamMemberSince: '23.01.2023',
    club: 'FC Arsenal',
    previousClub: 'Spezia Calcio',
    uid: '1bce5c27-06d5-4149-80f9-367725862ea4',
    position: 'Innenverteidiger',
    league: 'Premier League',
    marketValue: 20000000,
  },
  {
    jerseyNumber: 16,
    name: 'Rob Holding',
    dateOfBirth: '20.09.1995',
    nationality: 'England',
    height: 1.89,
    foot: 'rechts',
    teamMemberSince: '22.07.2016',
    club: 'FC Arsenal',
    previousClub: 'Bolton Wanderers',
    uid: 'b1e6b968-0e9a-481b-bf79-0f447de2fc9b',
    position: 'Innenverteidiger',
    league: 'Premier League',
    marketValue: 10000000,
  },
  {
    jerseyNumber: 35,
    name: 'Oleksandr Zinchenko',
    dateOfBirth: '15.12.1996',
    nationality: 'Ukraine',
    height: 1.75,
    foot: 'links',
    teamMemberSince: '22.07.2022',
    club: 'FC Arsenal',
    previousClub: 'Manchester City',
    uid: '5921c44b-9c07-4c5c-a9d1-226b471ba526',
    position: 'Linker Verteidiger',
    league: 'Premier League',
    marketValue: 40000000,
  },
  {
    jerseyNumber: 3,
    name: 'Kieran Tierney',
    dateOfBirth: '05.06.1997',
    nationality: 'Schottland',
    height: 1.8,
    foot: 'links',
    teamMemberSince: '08.08.2019',
    club: 'FC Arsenal',
    previousClub: 'Celtic Glasgow',
    uid: 'bb2a3b08-c014-4a27-8d2d-9db4a1ff5a1f',
    position: 'Linker Verteidiger',
    league: 'Premier League',
    marketValue: 25000000,
  },
  {
    jerseyNumber: 4,
    name: 'Ben White',
    dateOfBirth: '08.10.1997',
    nationality: 'England',
    height: 1.86,
    foot: 'rechts',
    teamMemberSince: '30.07.2021',
    club: 'FC Arsenal',
    previousClub: 'Brighton & Hove Albion',
    uid: 'f8a76c4b-f8a8-4e4e-bb6c-9c9ce4f9d3e6',
    position: 'Rechter Verteidiger',
    league: 'Premier League',
    marketValue: 50000000,
  },
  {
    jerseyNumber: 18,
    name: 'Takehiro Tomiyasu',
    dateOfBirth: '05.11.1998',
    nationality: 'Japan',
    height: 1.88,
    foot: 'rechts',
    teamMemberSince: '31.08.2021',
    club: 'FC Arsenal',
    previousClub: 'FC Bologna',
    uid: '4b929850-955d-405b-8de5-88b2050bc2b2',
    position: 'Rechter Verteidiger',
    league: 'Premier League',
    marketValue: 25000000,
  },
  {
    jerseyNumber: 5,
    name: 'Thomas Partey',
    dateOfBirth: '13.06.1993',
    nationality: 'Ghana',
    height: 1.85,
    foot: 'rechts',
    teamMemberSince: '05.10.2020',
    club: 'FC Arsenal',
    previousClub: 'Atlético Madrid',
    uid: 'bf42e70f-7667-40df-902b-f0b1bb508a19',
    position: 'Defensives Mittelfeld',
    league: 'Premier League',
    marketValue: 38000000,
  },
  {
    jerseyNumber: 20,
    name: 'Jorginho',
    dateOfBirth: '20.12.1991',
    nationality: 'Italien',
    height: 1.78,
    foot: 'rechts',
    teamMemberSince: '31.01.2023',
    club: 'FC Arsenal',
    previousClub: 'FC Chelsea',
    uid: 'e01d6799-12c4-4c01-ba7f-3a36c671abfe',
    position: 'Defensives Mittelfeld',
    league: 'Premier League',
    marketValue: 35000000,
  },
  {
    jerseyNumber: 25,
    name: 'Mohamed Elneny',
    dateOfBirth: '11.07.1992',
    nationality: 'Ägypten',
    height: 1.79,
    foot: 'rechts',
    teamMemberSince: '14.01.2016',
    club: 'FC Arsenal',
    previousClub: ': Ablöse 12,50 Mio. €',
    uid: 'db58de7b-344f-4a3c-90f3-551f580a2da7',
    position: 'Defensives Mittelfeld',
    league: 'Premier League',
    marketValue: 9000000,
  },
  {
    jerseyNumber: 34,
    name: 'Granit Xhaka',
    dateOfBirth: '27.09.1992',
    nationality: 'Schweiz',
    height: 1.86,
    foot: 'links',
    teamMemberSince: '01.07.2016',
    club: 'FC Arsenal',
    previousClub: 'Borussia Mönchengladbach',
    uid: '4bfe8bf6-938a-4a9f-8fe1-a46bfae9621d',
    position: 'Zentrales Mittelfeld',
    league: 'Premier League',
    marketValue: 28000000,
  },
  {
    jerseyNumber: 8,
    name: 'Martin Ødegaard',
    dateOfBirth: '17.12.1998',
    nationality: 'Norwegen',
    height: 1.78,
    foot: 'links',
    teamMemberSince: '20.08.2021',
    club: 'FC Arsenal',
    previousClub: 'Real Madrid',
    uid: '9d702508-a3cd-451a-9b24-ae949163ae03',
    position: 'Offensives Mittelfeld',
    league: 'Premier League',
    marketValue: 80000000,
  },
  {
    jerseyNumber: 10,
    name: 'Emile Smith Rowe',
    dateOfBirth: '28.07.2000',
    nationality: 'England',
    height: 1.82,
    foot: 'rechts',
    teamMemberSince: '01.08.2020',
    club: 'FC Arsenal',
    previousClub: 'FC Arsenal U23',
    uid: '1f403178-036a-4939-b90a-1d6c41c7b45b',
    position: 'Offensives Mittelfeld',
    league: 'Premier League',
    marketValue: 38000000,
  },
  {
    jerseyNumber: 21,
    name: 'Fábio Vieira',
    dateOfBirth: '30.05.2000',
    nationality: 'Portugal',
    height: 1.7,
    foot: 'links',
    teamMemberSince: '01.07.2022',
    club: 'FC Arsenal',
    previousClub: 'FC Porto',
    uid: 'b1880fbc-357a-4f6f-ab51-7f8a3b373d14',
    position: 'Offensives Mittelfeld',
    league: 'Premier League',
    marketValue: 27000000,
  },
  {
    jerseyNumber: 11,
    name: 'Gabriel Martinelli',
    dateOfBirth: '18.06.2001',
    nationality: 'Brasilien',
    height: 1.78,
    foot: 'rechts',
    teamMemberSince: '02.07.2019',
    club: 'FC Arsenal',
    previousClub: 'Ituano FC (SP)',
    uid: '6173134a-6a7a-48f1-95f3-960f33a29b3c',
    position: 'Linksaußen',
    league: 'Premier League',
    marketValue: 70000000,
  },
  {
    jerseyNumber: 19,
    name: 'Leandro Trossard',
    dateOfBirth: '04.12.1994',
    nationality: 'Belgien',
    height: 1.72,
    foot: 'rechts',
    teamMemberSince: '20.01.2023',
    club: 'FC Arsenal',
    previousClub: 'Brighton & Hove Albion',
    uid: '66e30885-afa4-48e1-9ed2-d7fe72855666',
    position: 'Linksaußen',
    league: 'Premier League',
    marketValue: 30000000,
  },
  {
    jerseyNumber: 7,
    name: 'Bukayo Saka',
    dateOfBirth: '05.09.2001',
    nationality: 'England',
    height: 1.78,
    foot: 'links',
    teamMemberSince: '01.07.2019',
    club: 'FC Arsenal',
    previousClub: 'FC Arsenal U23',
    uid: '301c0133-a4dd-4444-bae1-183bea30f6d0',
    position: 'Rechtsaußen',
    league: 'Premier League',
    marketValue: 110000000,
  },
  {
    jerseyNumber: 24,
    name: 'Reiss Nelson',
    dateOfBirth: '10.12.1999',
    nationality: 'England',
    height: 1.75,
    foot: 'rechts',
    teamMemberSince: '01.07.2019',
    club: 'FC Arsenal',
    previousClub: 'FC Arsenal U23',
    uid: '9be7773b-b03e-40b2-a148-b9c5bbf55a9e',
    position: 'Rechtsaußen',
    league: 'Premier League',
    marketValue: 7000000,
  },
  {
    jerseyNumber: 9,
    name: 'Gabriel Jesus',
    dateOfBirth: '03.04.1997',
    nationality: 'Brasilien',
    height: 1.75,
    foot: 'rechts',
    teamMemberSince: '04.07.2022',
    club: 'FC Arsenal',
    previousClub: 'Manchester City',
    uid: '9bb0cc4b-f8a8-4eb1-9bf1-b721dc70c771',
    position: 'Mittelstürmer',
    league: 'Premier League',
    marketValue: 75000000,
  },
  {
    jerseyNumber: 14,
    name: 'Eddie Nketiah',
    dateOfBirth: '30.05.1999',
    nationality: 'England',
    height: 1.75,
    foot: 'rechts',
    teamMemberSince: '01.01.2019',
    club: 'FC Arsenal',
    previousClub: 'FC Arsenal U23',
    uid: '23c7c96d-9324-4583-859f-29ce608ebb32',
    position: 'Mittelstürmer',
    league: 'Premier League',
    marketValue: 25000000,
  },
  {
    jerseyNumber: 1,
    name: 'Alisson',
    dateOfBirth: '02.10.1992',
    nationality: 'Brasilien',
    height: 1.93,
    foot: 'rechts',
    teamMemberSince: '19.07.2018',
    club: 'FC Liverpool',
    previousClub: 'AS Rom',
    uid: '693fa22a-ac9d-48ef-8d8f-a2afd8e833b6',
    position: 'Torwart',
    league: 'Premier League',
    marketValue: 45000000,
  },
  {
    jerseyNumber: 62,
    name: 'Caoimhín Kelleher',
    dateOfBirth: '23.11.1998',
    nationality: 'Irland',
    height: 1.88,
    foot: 'rechts',
    teamMemberSince: '01.07.2019',
    club: 'FC Liverpool',
    previousClub: 'FC Liverpool U23',
    uid: 'df60ed41-a22c-4d0d-b42c-8f35a6c5b7a7',
    position: 'Torwart',
    league: 'Premier League',
    marketValue: 8000000,
  },
  {
    jerseyNumber: 13,
    name: 'Adrián',
    dateOfBirth: '03.01.1987',
    nationality: 'Spanien',
    height: 1.9,
    foot: 'rechts',
    teamMemberSince: '05.08.2019',
    club: 'FC Liverpool',
    previousClub: 'West Ham United',
    uid: 'e5a8a101-6c41-431d-9064-acb0919b1ed1',
    position: 'Torwart',
    league: 'Premier League',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 4,
    name: 'Virgil van Dijk',
    dateOfBirth: '08.07.1991',
    nationality: 'Niederlande',
    height: 1.93,
    foot: 'rechts',
    teamMemberSince: '01.01.2018',
    club: 'FC Liverpool',
    previousClub: 'FC Southampton',
    uid: '1d8d6836-e76e-454f-abfc-6abbfefc3b21',
    position: 'Innenverteidiger',
    league: 'Premier League',
    marketValue: 45000000,
  },
  {
    jerseyNumber: 5,
    name: 'Ibrahima Konaté',
    dateOfBirth: '25.05.1999',
    nationality: 'Frankreich',
    height: 1.94,
    foot: 'rechts',
    teamMemberSince: '01.07.2021',
    club: 'FC Liverpool',
    previousClub: 'RasenBallsport Leipzig',
    uid: '9a31463f-4081-4f95-a590-ad0db195d2b1',
    position: 'Innenverteidiger',
    league: 'Premier League',
    marketValue: 35000000,
  },
  {
    jerseyNumber: 2,
    name: 'Joe Gomez',
    dateOfBirth: '23.05.1997',
    nationality: 'England',
    height: 1.88,
    foot: 'rechts',
    teamMemberSince: '01.07.2015',
    club: 'FC Liverpool',
    previousClub: 'Charlton Athletic',
    uid: '1ec5a3cd-bdf5-41fa-93ce-19c611d3dd62',
    position: 'Innenverteidiger',
    league: 'Premier League',
    marketValue: 30000000,
  },
  {
    jerseyNumber: 32,
    name: 'Joel Matip',
    dateOfBirth: '08.08.1991',
    nationality: 'Kamerun',
    height: 1.95,
    foot: 'rechts',
    teamMemberSince: '01.07.2016',
    club: 'FC Liverpool',
    previousClub: 'FC Schalke 04',
    uid: '455bd578-cfd3-4205-83b8-0bd51b143e65',
    position: 'Innenverteidiger',
    league: 'Premier League',
    marketValue: 16000000,
  },
  {
    jerseyNumber: 47,
    name: 'Nathaniel Phillips',
    dateOfBirth: '21.03.1997',
    nationality: 'England',
    height: 1.9,
    foot: 'rechts',
    teamMemberSince: '01.07.2019',
    club: 'FC Liverpool',
    previousClub: 'FC Liverpool U23',
    uid: 'f8313bfb-50d8-409a-a91e-cc9688a56e0c',
    position: 'Innenverteidiger',
    league: 'Premier League',
    marketValue: 6000000,
  },
  {
    jerseyNumber: 46,
    name: 'Rhys Williams',
    dateOfBirth: '03.02.2001',
    nationality: 'England',
    height: 1.95,
    foot: 'rechts',
    teamMemberSince: '01.07.2020',
    club: 'FC Liverpool',
    previousClub: 'FC Liverpool U23',
    uid: '4d7f24ba-0ed1-4a06-abce-81659d221259',
    position: 'Innenverteidiger',
    league: 'Premier League',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 26,
    name: 'Andrew Robertson',
    dateOfBirth: '11.03.1994',
    nationality: 'Schottland',
    height: 1.78,
    foot: 'links',
    teamMemberSince: '21.07.2017',
    club: 'FC Liverpool',
    previousClub: ': Ablöse 9,00 Mio. €',
    uid: 'dc0fc83f-45d7-47b4-bd73-ff7ec8e00789',
    position: 'Linker Verteidiger',
    league: 'Premier League',
    marketValue: 48000000,
  },
  {
    jerseyNumber: 21,
    name: 'Konstantinos Tsimikas',
    dateOfBirth: '12.05.1996',
    nationality: 'Griechenland',
    height: 1.79,
    foot: 'links',
    teamMemberSince: '10.08.2020',
    club: 'FC Liverpool',
    previousClub: 'Olympiakos Piräus',
    uid: '58267e66-2c7e-4075-91c7-66a173fe038c',
    position: 'Linker Verteidiger',
    league: 'Premier League',
    marketValue: 18000000,
  },
  {
    jerseyNumber: 66,
    name: 'Trent Alexander-Arnold',
    dateOfBirth: '07.10.1998',
    nationality: 'England',
    height: 1.8,
    foot: 'rechts',
    teamMemberSince: '01.07.2016',
    club: 'FC Liverpool',
    previousClub: 'FC Liverpool U18',
    uid: 'a6c90551-f733-435d-89db-874d86b38244',
    position: 'Rechter Verteidiger',
    league: 'Premier League',
    marketValue: 65000000,
  },
  {
    jerseyNumber: 22,
    name: 'Calvin Ramsay',
    dateOfBirth: '31.07.2003',
    nationality: 'Schottland',
    height: 1.77,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'FC Liverpool',
    previousClub: 'Aberdeen FC',
    uid: '317e5f43-056e-482a-abe0-b20359209cc6',
    position: 'Rechter Verteidiger',
    league: 'Premier League',
    marketValue: 5000000,
  },
  {
    jerseyNumber: 3,
    name: 'Fabinho',
    dateOfBirth: '23.10.1993',
    nationality: 'Brasilien',
    height: 1.88,
    foot: 'rechts',
    teamMemberSince: '01.07.2018',
    club: 'FC Liverpool',
    previousClub: 'AS Monaco',
    uid: '32d7b9e9-9b97-4287-81c7-cf4608cdb090',
    position: 'Defensives Mittelfeld',
    league: 'Premier League',
    marketValue: 45000000,
  },
  {
    jerseyNumber: 43,
    name: 'Stefan Bajcetic',
    dateOfBirth: '22.10.2004',
    nationality: 'Spanien',
    height: 1.85,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'FC Liverpool',
    previousClub: 'FC Liverpool U18',
    uid: '89d2e55d-b386-49ae-a4bd-8505406cb987',
    position: 'Defensives Mittelfeld',
    league: 'Premier League',
    marketValue: 13000000,
  },
  {
    jerseyNumber: 6,
    name: 'Thiago',
    dateOfBirth: '11.04.1991',
    nationality: 'Spanien',
    height: 1.74,
    foot: 'rechts',
    teamMemberSince: '18.09.2020',
    club: 'FC Liverpool',
    previousClub: 'FC Bayern München',
    uid: 'e753b3b6-0cb1-40ba-97e0-c27c0c11b3d0',
    position: 'Zentrales Mittelfeld',
    league: 'Premier League',
    marketValue: 18000000,
  },
  {
    jerseyNumber: 8,
    name: 'Naby Keïta',
    dateOfBirth: '10.02.1995',
    nationality: 'Guinea',
    height: 1.72,
    foot: 'rechts',
    teamMemberSince: '01.07.2018',
    club: 'FC Liverpool',
    previousClub: ': Ablöse 60,00 Mio. €',
    uid: '4ed4cacb-3b33-47b2-b30f-90904fb30e58',
    position: 'Zentrales Mittelfeld',
    league: 'Premier League',
    marketValue: 17000000,
  },
  {
    jerseyNumber: 17,
    name: 'Curtis Jones',
    dateOfBirth: '30.01.2001',
    nationality: 'England',
    height: 1.85,
    foot: 'rechts',
    teamMemberSince: '01.07.2020',
    club: 'FC Liverpool',
    previousClub: 'FC Liverpool U23',
    uid: '5539e42b-aa76-429e-8032-1ce984260608',
    position: 'Zentrales Mittelfeld',
    league: 'Premier League',
    marketValue: 17000000,
  },
  {
    jerseyNumber: 29,
    name: 'Arthur Melo',
    dateOfBirth: '12.08.1996',
    nationality: 'Brasilien',
    height: 1.72,
    foot: 'rechts',
    teamMemberSince: '01.09.2022',
    club: 'FC Liverpool',
    previousClub: 'Juventus Turin',
    uid: '115eba91-0cc4-401b-b91b-2b9c8456df32',
    position: 'Zentrales Mittelfeld',
    league: 'Premier League',
    marketValue: 15000000,
  },
  {
    jerseyNumber: 14,
    name: 'Jordan Henderson',
    dateOfBirth: '17.06.1990',
    nationality: 'England',
    height: 1.87,
    foot: 'rechts',
    teamMemberSince: '01.07.2011',
    club: 'FC Liverpool',
    previousClub: 'AFC Sunderland',
    uid: '017465a9-bf98-4421-a71a-1c9c6378b88c',
    position: 'Zentrales Mittelfeld',
    league: 'Premier League',
    marketValue: 10000000,
  },
  {
    jerseyNumber: 15,
    name: 'Alex Oxlade-Chamberlain',
    dateOfBirth: '15.08.1993',
    nationality: 'England',
    height: 1.75,
    foot: 'rechts',
    teamMemberSince: '31.08.2017',
    club: 'FC Liverpool',
    previousClub: 'FC Arsenal',
    uid: 'bac9d43e-702a-4321-ab79-fb5a0aa54a49',
    position: 'Zentrales Mittelfeld',
    league: 'Premier League',
    marketValue: 10000000,
  },
  {
    jerseyNumber: 7,
    name: 'James Milner',
    dateOfBirth: '04.01.1986',
    nationality: 'England',
    height: 1.75,
    foot: 'rechts',
    teamMemberSince: '01.07.2015',
    club: 'FC Liverpool',
    previousClub: ': Ablöse ablösefrei',
    uid: '1afc1878-fa5b-4dd9-8762-862ac0a548bd',
    position: 'Zentrales Mittelfeld',
    league: 'Premier League',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 19,
    name: 'Harvey Elliott',
    dateOfBirth: '04.04.2003',
    nationality: 'England',
    height: 1.7,
    foot: 'links',
    teamMemberSince: '01.07.2021',
    club: 'FC Liverpool',
    previousClub: 'FC Liverpool U23',
    uid: 'a213993b-723f-4d73-9fee-b7b518d3e495',
    position: 'Offensives Mittelfeld',
    league: 'Premier League',
    marketValue: 35000000,
  },
  {
    jerseyNumber: 28,
    name: 'Fábio Carvalho',
    dateOfBirth: '30.08.2002',
    nationality: 'Portugal',
    height: 1.7,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'FC Liverpool',
    previousClub: 'FC Fulham',
    uid: 'be942c14-96a0-4065-af25-0df32404f901',
    position: 'Offensives Mittelfeld',
    league: 'Premier League',
    marketValue: 20000000,
  },
  {
    jerseyNumber: 23,
    name: 'Luis Díaz',
    dateOfBirth: '13.01.1997',
    nationality: 'Kolumbien',
    height: 1.8,
    foot: 'rechts',
    teamMemberSince: '30.01.2022',
    club: 'FC Liverpool',
    previousClub: 'FC Porto',
    uid: '872c57fd-1b8c-4f22-8049-98d1b18b1bc8',
    position: 'Linksaußen',
    league: 'Premier League',
    marketValue: 75000000,
  },
  {
    jerseyNumber: 18,
    name: 'Cody Gakpo',
    dateOfBirth: '07.05.1999',
    nationality: 'Niederlande',
    height: 1.93,
    foot: 'rechts',
    teamMemberSince: '01.01.2023',
    club: 'FC Liverpool',
    previousClub: 'PSV Eindhoven',
    uid: 'b84a90b1-36dc-48f9-8791-50e828262cc9',
    position: 'Linksaußen',
    league: 'Premier League',
    marketValue: 60000000,
  },
  {
    jerseyNumber: 20,
    name: 'Diogo Jota',
    dateOfBirth: '04.12.1996',
    nationality: 'Portugal',
    height: 1.78,
    foot: 'rechts',
    teamMemberSince: '19.09.2020',
    club: 'FC Liverpool',
    previousClub: 'Wolverhampton Wanderers',
    uid: 'd459b7ac-6eba-42f0-b147-f3aae98cb370',
    position: 'Linksaußen',
    league: 'Premier League',
    marketValue: 55000000,
  },
  {
    jerseyNumber: 11,
    name: 'Mohamed Salah',
    dateOfBirth: '15.06.1992',
    nationality: 'Ägypten',
    height: 1.75,
    foot: 'links',
    teamMemberSince: '01.07.2017',
    club: 'FC Liverpool',
    previousClub: 'AS Rom',
    uid: '0f8c2a43-5827-4044-8cfd-385f1e5978b7',
    position: 'Rechtsaußen',
    league: 'Premier League',
    marketValue: 70000000,
  },
  {
    jerseyNumber: 27,
    name: 'Darwin Núñez',
    dateOfBirth: '24.06.1999',
    nationality: 'Uruguay',
    height: 1.87,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'FC Liverpool',
    previousClub: 'Benfica Lissabon',
    uid: 'e804b161-8108-4e4a-b441-3efe46a6d6eb',
    position: 'Mittelstürmer',
    league: 'Premier League',
    marketValue: 70000000,
  },
  {
    jerseyNumber: 9,
    name: 'Roberto Firmino',
    dateOfBirth: '02.10.1991',
    nationality: 'Brasilien',
    height: 1.81,
    foot: 'rechts',
    teamMemberSince: '01.07.2015',
    club: 'FC Liverpool',
    previousClub: 'TSG 1899 Hoffenheim',
    uid: '45988208-3592-49a1-88a6-e7209ef02b39',
    position: 'Mittelstürmer',
    league: 'Premier League',
    marketValue: 22000000,
  },
  {
    jerseyNumber: 1,
    name: 'David de Gea',
    dateOfBirth: '07.11.1990',
    nationality: 'Spanien',
    height: 1.89,
    foot: 'rechts',
    teamMemberSince: '01.07.2011',
    club: 'Manchester United',
    previousClub: ': Ablöse 25,00 Mio. €',
    uid: '1895664c-3d8c-4e59-835b-fd6b1cfdd0f1',
    position: 'Torwart',
    league: 'Premier League',
    marketValue: 15000000,
  },
  {
    jerseyNumber: 31,
    name: 'Jack Butland',
    dateOfBirth: '10.03.1993',
    nationality: 'England',
    height: 1.96,
    foot: 'rechts',
    teamMemberSince: '06.01.2023',
    club: 'Manchester United',
    previousClub: 'Crystal Palace',
    uid: '8b4deb19-997d-4318-8ad6-e67045342ac7',
    position: 'Torwart',
    league: 'Premier League',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 22,
    name: 'Tom Heaton',
    dateOfBirth: '15.04.1986',
    nationality: 'England',
    height: 1.88,
    foot: 'rechts',
    teamMemberSince: '02.07.2021',
    club: 'Manchester United',
    previousClub: 'Aston Villa',
    uid: 'c0f182d4-8598-4986-b481-2990a6f1b814',
    position: 'Torwart',
    league: 'Premier League',
    marketValue: 600000,
  },
  {
    jerseyNumber: 6,
    name: 'Lisandro Martínez',
    dateOfBirth: '18.01.1998',
    nationality: 'Argentinien',
    height: 1.75,
    foot: 'links',
    teamMemberSince: '27.07.2022',
    club: 'Manchester United',
    previousClub: 'Ajax Amsterdam',
    uid: '080c0492-fe5d-4c50-9ffa-5abf6cf8f348',
    position: 'Innenverteidiger',
    league: 'Premier League',
    marketValue: 50000000,
  },
  {
    jerseyNumber: 19,
    name: 'Raphaël Varane',
    dateOfBirth: '25.04.1993',
    nationality: 'Frankreich',
    height: 1.91,
    foot: 'rechts',
    teamMemberSince: '14.08.2021',
    club: 'Manchester United',
    previousClub: 'Real Madrid',
    uid: '836041e3-8853-404b-bb3a-c3c01cc482a4',
    position: 'Innenverteidiger',
    league: 'Premier League',
    marketValue: 40000000,
  },
  {
    jerseyNumber: 5,
    name: 'Harry Maguire',
    dateOfBirth: '05.03.1993',
    nationality: 'England',
    height: 1.94,
    foot: 'rechts',
    teamMemberSince: '05.08.2019',
    club: 'Manchester United',
    previousClub: 'Leicester City',
    uid: '71a58465-1fd6-443c-83ef-843074567640',
    position: 'Innenverteidiger',
    league: 'Premier League',
    marketValue: 25000000,
  },
  {
    jerseyNumber: 2,
    name: 'Victor Lindelöf',
    dateOfBirth: '17.07.1994',
    nationality: 'Schweden',
    height: 1.87,
    foot: 'rechts',
    teamMemberSince: '01.07.2017',
    club: 'Manchester United',
    previousClub: 'Benfica Lissabon',
    uid: '50d9dcbe-cdcc-4ac7-b060-6e87946a15dc',
    position: 'Innenverteidiger',
    league: 'Premier League',
    marketValue: 15000000,
  },
  {
    jerseyNumber: 4,
    name: 'Phil Jones',
    dateOfBirth: '21.02.1992',
    nationality: 'England',
    height: 1.85,
    foot: 'rechts',
    teamMemberSince: '01.07.2011',
    club: 'Manchester United',
    previousClub: 'Blackburn Rovers',
    uid: '2dfebfb8-9f88-4691-90c1-8eb3ea24068b',
    position: 'Innenverteidiger',
    league: 'Premier League',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 43,
    name: 'Teden Mengi',
    dateOfBirth: '30.04.2002',
    nationality: 'England',
    height: 1.86,
    foot: 'rechts',
    teamMemberSince: '01.07.2020',
    club: 'Manchester United',
    previousClub: 'Manchester United U23',
    uid: '71db778a-2010-4375-9a3e-151cfa6ad971',
    position: 'Innenverteidiger',
    league: 'Premier League',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 23,
    name: 'Luke Shaw',
    dateOfBirth: '12.07.1995',
    nationality: 'England',
    height: 1.78,
    foot: 'links',
    teamMemberSince: '01.07.2014',
    club: 'Manchester United',
    previousClub: 'FC Southampton',
    uid: 'b2ccda70-1417-4010-a5bf-47ccfbdc1e9d',
    position: 'Linker Verteidiger',
    league: 'Premier League',
    marketValue: 35000000,
  },
  {
    jerseyNumber: 12,
    name: 'Tyrell Malacia',
    dateOfBirth: '17.08.1999',
    nationality: 'Niederlande',
    height: 1.69,
    foot: 'links',
    teamMemberSince: '05.07.2022',
    club: 'Manchester United',
    previousClub: 'Feyenoord Rotterdam',
    uid: '09218d21-8335-4af0-9ba4-91260ba06c28',
    position: 'Linker Verteidiger',
    league: 'Premier League',
    marketValue: 22000000,
  },
  {
    jerseyNumber: 33,
    name: 'Brandon Williams',
    dateOfBirth: '03.09.2000',
    nationality: 'England',
    height: 1.8,
    foot: 'rechts',
    teamMemberSince: '01.07.2019',
    club: 'Manchester United',
    previousClub: 'Manchester United U18',
    uid: '6a8e3b95-1bcb-49bc-9b3a-c534a9d1d816',
    position: 'Linker Verteidiger',
    league: 'Premier League',
    marketValue: 10000000,
  },
  {
    jerseyNumber: 20,
    name: 'Diogo Dalot',
    dateOfBirth: '18.03.1999',
    nationality: 'Portugal',
    height: 1.83,
    foot: 'rechts',
    teamMemberSince: '01.07.2018',
    club: 'Manchester United',
    previousClub: 'FC Porto',
    uid: 'bb5a95ab-0134-4bef-b3d1-6dd101e9c413',
    position: 'Rechter Verteidiger',
    league: 'Premier League',
    marketValue: 35000000,
  },
  {
    jerseyNumber: 29,
    name: 'Aaron Wan-Bissaka',
    dateOfBirth: '26.11.1997',
    nationality: 'England',
    height: 1.83,
    foot: 'rechts',
    teamMemberSince: '01.07.2019',
    club: 'Manchester United',
    previousClub: 'Crystal Palace',
    uid: '1d4de157-47ff-4a71-a270-7583e5a01776',
    position: 'Rechter Verteidiger',
    league: 'Premier League',
    marketValue: 22000000,
  },
  {
    jerseyNumber: 18,
    name: 'Casemiro',
    dateOfBirth: '23.02.1992',
    nationality: 'Brasilien',
    height: 1.85,
    foot: 'rechts',
    teamMemberSince: '22.08.2022',
    club: 'Manchester United',
    previousClub: 'Real Madrid',
    uid: 'a99ea7a7-a9c2-4d85-bce2-88517f09d6f4',
    position: 'Defensives Mittelfeld',
    league: 'Premier League',
    marketValue: 50000000,
  },
  {
    jerseyNumber: 39,
    name: 'Scott McTominay',
    dateOfBirth: '08.12.1996',
    nationality: 'Schottland',
    height: 1.91,
    foot: 'rechts',
    teamMemberSince: '01.07.2017',
    club: 'Manchester United',
    previousClub: 'Manchester United U23',
    uid: 'b97679c4-7262-4285-a92e-fa959a1d57a1',
    position: 'Defensives Mittelfeld',
    league: 'Premier League',
    marketValue: 25000000,
  },
  {
    jerseyNumber: 14,
    name: 'Christian Eriksen',
    dateOfBirth: '14.02.1992',
    nationality: 'Dänemark',
    height: 1.82,
    foot: 'rechts',
    teamMemberSince: '15.07.2022',
    club: 'Manchester United',
    previousClub: 'FC Brentford',
    uid: '67ca7211-1d75-4d82-bb05-99483b6e49ac',
    position: 'Zentrales Mittelfeld',
    league: 'Premier League',
    marketValue: 25000000,
  },
  {
    jerseyNumber: 15,
    name: 'Marcel Sabitzer',
    dateOfBirth: '17.03.1994',
    nationality: 'Österreich',
    height: 1.77,
    foot: 'rechts',
    teamMemberSince: '31.01.2023',
    club: 'Manchester United',
    previousClub: 'FC Bayern München',
    uid: '1633b318-a1b3-4cd4-b454-6bac1c00bb30',
    position: 'Zentrales Mittelfeld',
    league: 'Premier League',
    marketValue: 20000000,
  },
  {
    jerseyNumber: 17,
    name: 'Fred',
    dateOfBirth: '05.03.1993',
    nationality: 'Brasilien',
    height: 1.69,
    foot: 'links',
    teamMemberSince: '01.07.2018',
    club: 'Manchester United',
    previousClub: 'Shakhtar Donetsk',
    uid: 'f6bfe5c3-46ae-4004-8648-162a8df15585',
    position: 'Zentrales Mittelfeld',
    league: 'Premier League',
    marketValue: 20000000,
  },
  {
    jerseyNumber: 34,
    name: 'Donny van de Beek',
    dateOfBirth: '18.04.1997',
    nationality: 'Niederlande',
    height: 1.84,
    foot: 'rechts',
    teamMemberSince: '02.09.2020',
    club: 'Manchester United',
    previousClub: 'Ajax Amsterdam',
    uid: 'dea995d6-97bf-484e-a62f-191657c2a5dd',
    position: 'Zentrales Mittelfeld',
    league: 'Premier League',
    marketValue: 17000000,
  },
  {
    jerseyNumber: 55,
    name: 'Zidane Iqbal',
    dateOfBirth: '27.04.2003',
    nationality: 'Irak',
    height: 1.81,
    foot: 'rechts',
    teamMemberSince: '01.01.2023',
    club: 'Manchester United',
    previousClub: 'Manchester United U21',
    uid: 'd0db5fdf-a89d-429a-80b4-e07a5f9df97c',
    position: 'Zentrales Mittelfeld',
    league: 'Premier League',
    marketValue: 800000,
  },
  {
    jerseyNumber: 73,
    name: 'Kobbie Mainoo',
    dateOfBirth: '19.04.2005',
    nationality: 'England',
    height: 1.75,
    foot: 'rechts',
    teamMemberSince: '01.01.2023',
    club: 'Manchester United',
    previousClub: 'Manchester United U21',
    uid: '8dca0ce9-0c94-41f3-b720-56a7b75cc5c9',
    position: 'Zentrales Mittelfeld',
    league: 'Premier League',
    marketValue: 800000,
  },
  {
    jerseyNumber: 8,
    name: 'Bruno Fernandes',
    dateOfBirth: '08.09.1994',
    nationality: 'Portugal',
    height: 1.79,
    foot: 'rechts',
    teamMemberSince: '29.01.2020',
    club: 'Manchester United',
    previousClub: 'Sporting Lissabon',
    uid: '25f113a6-bcfe-4164-9e3d-8e95f322e6c2',
    position: 'Offensives Mittelfeld',
    league: 'Premier League',
    marketValue: 75000000,
  },
  {
    jerseyNumber: 10,
    name: 'Marcus Rashford',
    dateOfBirth: '31.10.1997',
    nationality: 'England',
    height: 1.85,
    foot: 'rechts',
    teamMemberSince: '01.01.2016',
    club: 'Manchester United',
    previousClub: 'Manchester United U18',
    uid: 'f29ecfda-262d-48a9-bffc-40a1c4a6353d',
    position: 'Linksaußen',
    league: 'Premier League',
    marketValue: 80000000,
  },
  {
    jerseyNumber: 25,
    name: 'Jadon Sancho',
    dateOfBirth: '25.03.2000',
    nationality: 'England',
    height: 1.8,
    foot: 'rechts',
    teamMemberSince: '23.07.2021',
    club: 'Manchester United',
    previousClub: 'Borussia Dortmund',
    uid: '6bab92da-2806-49ce-9cf5-6679b33c9385',
    position: 'Linksaußen',
    league: 'Premier League',
    marketValue: 55000000,
  },
  {
    jerseyNumber: 36,
    name: 'Anthony Elanga',
    dateOfBirth: '27.04.2002',
    nationality: 'Schweden',
    height: 1.78,
    foot: 'rechts',
    teamMemberSince: '01.01.2022',
    club: 'Manchester United',
    previousClub: 'Manchester United U23',
    uid: '7b69b917-f154-4597-996e-af75a78f8008',
    position: 'Linksaußen',
    league: 'Premier League',
    marketValue: 25000000,
  },
  {
    jerseyNumber: 49,
    name: 'Alejandro Garnacho',
    dateOfBirth: '01.07.2004',
    nationality: 'Argentinien',
    height: 1.8,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'Manchester United',
    previousClub: 'Manchester United U21',
    uid: 'b6d68890-a2ac-42f7-9daf-81330ebfaf72',
    position: 'Linksaußen',
    league: 'Premier League',
    marketValue: 25000000,
  },
  {
    jerseyNumber: 21,
    name: 'Antony',
    dateOfBirth: '24.02.2000',
    nationality: 'Brasilien',
    height: 1.72,
    foot: 'links',
    teamMemberSince: '30.08.2022',
    club: 'Manchester United',
    previousClub: 'Ajax Amsterdam',
    uid: '63c2b860-708c-48ea-aa9d-b06a8216b021',
    position: 'Rechtsaußen',
    league: 'Premier League',
    marketValue: 70000000,
  },
  {
    jerseyNumber: 28,
    name: 'Facundo Pellistri',
    dateOfBirth: '20.12.2001',
    nationality: 'Uruguay',
    height: 1.74,
    foot: 'rechts',
    teamMemberSince: '05.10.2020',
    club: 'Manchester United',
    previousClub: 'CA Peñarol',
    uid: 'beb68c2f-38d7-422a-ac0d-878a8ca49c1a',
    position: 'Rechtsaußen',
    league: 'Premier League',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 9,
    name: 'Anthony Martial',
    dateOfBirth: '05.12.1995',
    nationality: 'Frankreich',
    height: 1.81,
    foot: 'rechts',
    teamMemberSince: '01.09.2015',
    club: 'Manchester United',
    previousClub: 'AS Monaco',
    uid: '668e6960-f27d-496f-82d8-35498cab80d3',
    position: 'Mittelstürmer',
    league: 'Premier League',
    marketValue: 15000000,
  },
  {
    jerseyNumber: 27,
    name: 'Wout Weghorst',
    dateOfBirth: '07.08.1992',
    nationality: 'Niederlande',
    height: 1.97,
    foot: 'rechts',
    teamMemberSince: '13.01.2023',
    club: 'Manchester United',
    previousClub: 'FC Burnley',
    uid: '80d41421-add5-45c1-a223-16aea2845d49',
    position: 'Mittelstürmer',
    league: 'Premier League',
    marketValue: 14000000,
  },
  {
    jerseyNumber: 1,
    name: 'Hugo Lloris',
    dateOfBirth: '26.12.1986',
    nationality: 'Frankreich',
    height: 1.88,
    foot: 'links',
    teamMemberSince: '31.08.2012',
    club: 'Tottenham Hotspur',
    previousClub: ': Ablöse 12,60 Mio. €',
    uid: '95cc6019-35bc-46f0-91ce-bac0dac46541',
    position: 'Torwart',
    league: 'Premier League',
    marketValue: 4000000,
  },
  {
    jerseyNumber: 20,
    name: 'Fraser Forster',
    dateOfBirth: '17.03.1988',
    nationality: 'England',
    height: 2.01,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'Tottenham Hotspur',
    previousClub: 'FC Southampton',
    uid: '1561f633-7af9-420b-925c-0e6445f317ba',
    position: 'Torwart',
    league: 'Premier League',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 41,
    name: 'Alfie Whiteman',
    dateOfBirth: '02.10.1998',
    nationality: 'England',
    height: 1.88,
    foot: 'rechts',
    teamMemberSince: '01.07.2019',
    club: 'Tottenham Hotspur',
    previousClub: 'Tottenham Hotspur U23',
    uid: '4fb4ad65-2a49-45bd-a68f-becfb789aed4',
    position: 'Torwart',
    league: 'Premier League',
    marketValue: 500000,
  },
  {
    jerseyNumber: 40,
    name: 'Brandon Austin',
    dateOfBirth: '08.01.1999',
    nationality: 'England',
    height: 1.88,
    foot: 'rechts',
    teamMemberSince: '01.08.2021',
    club: 'Tottenham Hotspur',
    previousClub: 'Tottenham Hotspur U23',
    uid: '2a8c4a01-2be5-4833-9e09-4267f0428556',
    position: 'Torwart',
    league: 'Premier League',
    marketValue: 300000,
  },
  {
    jerseyNumber: 17,
    name: 'Cristian Romero',
    dateOfBirth: '27.04.1998',
    nationality: 'Argentinien',
    height: 1.85,
    foot: 'rechts',
    teamMemberSince: '30.08.2022',
    club: 'Tottenham Hotspur',
    previousClub: 'Atalanta Bergamo',
    uid: '8131fcf2-00c0-4b08-8974-50049f030ac8',
    position: 'Innenverteidiger',
    league: 'Premier League',
    marketValue: 60000000,
  },
  {
    jerseyNumber: 15,
    name: 'Eric Dier',
    dateOfBirth: '15.01.1994',
    nationality: 'England',
    height: 1.88,
    foot: 'rechts',
    teamMemberSince: '31.07.2014',
    club: 'Tottenham Hotspur',
    previousClub: 'Sporting Lissabon',
    uid: 'dc348269-4cce-46ed-81ed-6994c160b24b',
    position: 'Innenverteidiger',
    league: 'Premier League',
    marketValue: 25000000,
  },
  {
    jerseyNumber: 6,
    name: 'Davinson Sánchez',
    dateOfBirth: '12.06.1996',
    nationality: 'Kolumbien',
    height: 1.87,
    foot: 'rechts',
    teamMemberSince: '23.08.2017',
    club: 'Tottenham Hotspur',
    previousClub: 'Ajax Amsterdam',
    uid: 'd9eb1b3e-fb0a-4927-8646-5b8e5fb0a196',
    position: 'Innenverteidiger',
    league: 'Premier League',
    marketValue: 20000000,
  },
  {
    jerseyNumber: 34,
    name: 'Clément Lenglet',
    dateOfBirth: '17.06.1995',
    nationality: 'Frankreich',
    height: 1.86,
    foot: 'links',
    teamMemberSince: '08.07.2022',
    club: 'Tottenham Hotspur',
    previousClub: 'FC Barcelona',
    uid: 'edd2bea5-e0de-476d-8cec-a0b05bc4900d',
    position: 'Innenverteidiger',
    league: 'Premier League',
    marketValue: 12000000,
  },
  {
    jerseyNumber: 25,
    name: 'Japhet Tanganga',
    dateOfBirth: '31.03.1999',
    nationality: 'England',
    height: 1.84,
    foot: 'rechts',
    teamMemberSince: '01.01.2020',
    club: 'Tottenham Hotspur',
    previousClub: 'Tottenham Hotspur U23',
    uid: '3407888a-fa02-4a87-b73d-c8b978bbb639',
    position: 'Innenverteidiger',
    league: 'Premier League',
    marketValue: 9000000,
  },
  {
    jerseyNumber: 33,
    name: 'Ben Davies',
    dateOfBirth: '24.04.1993',
    nationality: 'Wales',
    height: 1.81,
    foot: 'links',
    teamMemberSince: '23.07.2014',
    club: 'Tottenham Hotspur',
    previousClub: ': Ablöse 12,65 Mio. €',
    uid: 'e120f0c7-9db5-4a88-b337-2ff195abfd58',
    position: 'Linker Verteidiger',
    league: 'Premier League',
    marketValue: 20000000,
  },
  {
    jerseyNumber: 23,
    name: 'Pedro Porro',
    dateOfBirth: '13.09.1999',
    nationality: 'Spanien',
    height: 1.73,
    foot: 'rechts',
    teamMemberSince: '31.01.2023',
    club: 'Tottenham Hotspur',
    previousClub: 'Sporting Lissabon',
    uid: 'f5ca1d5d-4385-4252-9217-885a32ca856d',
    position: 'Rechter Verteidiger',
    league: 'Premier League',
    marketValue: 35000000,
  },
  {
    jerseyNumber: 12,
    name: 'Emerson Royal',
    dateOfBirth: '14.01.1999',
    nationality: 'Brasilien',
    height: 1.83,
    foot: 'rechts',
    teamMemberSince: '31.08.2021',
    club: 'Tottenham Hotspur',
    previousClub: 'FC Barcelona',
    uid: 'db932b69-cde6-43f9-bcaa-fd35d8aa9179',
    position: 'Rechter Verteidiger',
    league: 'Premier League',
    marketValue: 25000000,
  },
  {
    jerseyNumber: 4,
    name: 'Oliver Skipp',
    dateOfBirth: '16.09.2000',
    nationality: 'England',
    height: 1.75,
    foot: 'rechts',
    teamMemberSince: '01.07.2018',
    club: 'Tottenham Hotspur',
    previousClub: 'Tottenham Hotspur U23',
    uid: 'a39a1727-96f6-4565-90ba-0345c0dad7e8',
    position: 'Defensives Mittelfeld',
    league: 'Premier League',
    marketValue: 15000000,
  },
  {
    jerseyNumber: 5,
    name: 'Pierre-Emile Höjbjerg',
    dateOfBirth: '05.08.1995',
    nationality: 'Dänemark',
    height: 1.85,
    foot: 'rechts',
    teamMemberSince: '11.08.2020',
    club: 'Tottenham Hotspur',
    previousClub: 'FC Southampton',
    uid: '19b361b2-586e-4e2e-b128-0ee12deec819',
    position: 'Zentrales Mittelfeld',
    league: 'Premier League',
    marketValue: 45000000,
  },
  {
    jerseyNumber: 30,
    name: 'Rodrigo Bentancur',
    dateOfBirth: '25.06.1997',
    nationality: 'Uruguay',
    height: 1.87,
    foot: 'rechts',
    teamMemberSince: '31.01.2022',
    club: 'Tottenham Hotspur',
    previousClub: 'Juventus Turin',
    uid: 'f8f770dd-b545-4a77-8040-adab2263baa4',
    position: 'Zentrales Mittelfeld',
    league: 'Premier League',
    marketValue: 40000000,
  },
  {
    jerseyNumber: 38,
    name: 'Yves Bissouma',
    dateOfBirth: '30.08.1996',
    nationality: 'Mali',
    height: 1.82,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'Tottenham Hotspur',
    previousClub: 'Brighton & Hove Albion',
    uid: 'd7d832cf-b978-4db4-bc59-6a95bac3433f',
    position: 'Zentrales Mittelfeld',
    league: 'Premier League',
    marketValue: 25000000,
  },
  {
    jerseyNumber: 29,
    name: 'Pape Matar Sarr',
    dateOfBirth: '14.09.2002',
    nationality: 'Senegal',
    height: 1.84,
    foot: 'rechts',
    teamMemberSince: '27.08.2021',
    club: 'Tottenham Hotspur',
    previousClub: 'FC Metz',
    uid: '4eb1aba9-b9ee-41be-86c6-696a9b089d0c',
    position: 'Zentrales Mittelfeld',
    league: 'Premier League',
    marketValue: 15000000,
  },
  {
    jerseyNumber: 19,
    name: 'Ryan Sessegnon',
    dateOfBirth: '18.05.2000',
    nationality: 'England',
    height: 1.78,
    foot: 'links',
    teamMemberSince: '08.08.2019',
    club: 'Tottenham Hotspur',
    previousClub: 'FC Fulham',
    uid: '779f27e6-77d3-48a6-bd28-dcad5bebaa28',
    position: 'Linkes Mittelfeld',
    league: 'Premier League',
    marketValue: 22000000,
  },
  {
    jerseyNumber: 14,
    name: 'Ivan Perisic',
    dateOfBirth: '02.02.1989',
    nationality: 'Kroatien',
    height: 1.86,
    foot: 'beidfüßig',
    teamMemberSince: '01.07.2022',
    club: 'Tottenham Hotspur',
    previousClub: 'Inter Mailand',
    uid: '262e2b54-6893-40bb-b930-52fd3a597627',
    position: 'Linkes Mittelfeld',
    league: 'Premier League',
    marketValue: 10000000,
  },
  {
    jerseyNumber: 7,
    name: 'Heung-min Son',
    dateOfBirth: '08.07.1992',
    nationality: 'Südkorea',
    height: 1.84,
    foot: 'beidfüßig',
    teamMemberSince: '28.08.2015',
    club: 'Tottenham Hotspur',
    previousClub: 'Bayer 04 Leverkusen',
    uid: '487a4031-18b8-4653-b5b4-76a330f2f7c7',
    position: 'Linksaußen',
    league: 'Premier League',
    marketValue: 60000000,
  },
  {
    jerseyNumber: 16,
    name: 'Arnaut Danjuma',
    dateOfBirth: '31.01.1997',
    nationality: 'Niederlande',
    height: 1.78,
    foot: 'rechts',
    teamMemberSince: '25.01.2023',
    club: 'Tottenham Hotspur',
    previousClub: 'FC Villarreal',
    uid: '77e2995e-d669-4f90-8d2e-0baa6f6f203b',
    position: 'Linksaußen',
    league: 'Premier League',
    marketValue: 27000000,
  },
  {
    jerseyNumber: 21,
    name: 'Dejan Kulusevski',
    dateOfBirth: '25.04.2000',
    nationality: 'Schweden',
    height: 1.86,
    foot: 'links',
    teamMemberSince: '31.01.2022',
    club: 'Tottenham Hotspur',
    previousClub: 'Juventus Turin',
    uid: '7c6f2d9b-392c-4923-be6e-d1e52e84988e',
    position: 'Rechtsaußen',
    league: 'Premier League',
    marketValue: 55000000,
  },
  {
    jerseyNumber: 27,
    name: 'Lucas Moura',
    dateOfBirth: '13.08.1992',
    nationality: 'Brasilien',
    height: 1.72,
    foot: 'rechts',
    teamMemberSince: '31.01.2018',
    club: 'Tottenham Hotspur',
    previousClub: 'FC Paris Saint-Germain',
    uid: 'd9b8247e-0f60-4fc8-9e43-9ec7b33a0a46',
    position: 'Rechtsaußen',
    league: 'Premier League',
    marketValue: 9000000,
  },
  {
    jerseyNumber: 10,
    name: 'Harry Kane',
    dateOfBirth: '28.07.1993',
    nationality: 'England',
    height: 1.88,
    foot: 'rechts',
    teamMemberSince: '01.01.2011',
    club: 'Tottenham Hotspur',
    previousClub: 'Tottenham Hotspur U18',
    uid: '2edc60ca-d535-4c13-82f6-7b9e221856fb',
    position: 'Mittelstürmer',
    league: 'Premier League',
    marketValue: 90000000,
  },
  {
    jerseyNumber: 9,
    name: 'Richarlison',
    dateOfBirth: '10.05.1997',
    nationality: 'Brasilien',
    height: 1.84,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'Tottenham Hotspur',
    previousClub: 'FC Everton',
    uid: '7fc8492a-f7ff-4306-b817-7452bec0d39b',
    position: 'Mittelstürmer',
    league: 'Premier League',
    marketValue: 55000000,
  },
  {
    jerseyNumber: 22,
    name: 'Nick Pope',
    dateOfBirth: '19.04.1992',
    nationality: 'England',
    height: 1.98,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'Newcastle United',
    previousClub: 'FC Burnley',
    uid: '9a9f2666-116c-4d21-87f0-c33f49c8b1d0',
    position: 'Torwart',
    league: 'Premier League',
    marketValue: 18000000,
  },
  {
    jerseyNumber: 1,
    name: 'Martin Dúbravka',
    dateOfBirth: '15.01.1989',
    nationality: 'Slowakei',
    height: 1.91,
    foot: 'rechts',
    teamMemberSince: '01.07.2018',
    club: 'Newcastle United',
    previousClub: ': Ablöse 4,00 Mio. €',
    uid: '1ec93051-3cf0-4ccf-9f3f-d4c9d4c67273',
    position: 'Torwart',
    league: 'Premier League',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 18,
    name: 'Loris Karius',
    dateOfBirth: '22.06.1993',
    nationality: 'Deutschland',
    height: 1.91,
    foot: 'rechts',
    teamMemberSince: '12.09.2022',
    club: 'Newcastle United',
    previousClub: 'Vereinslos',
    uid: 'f31a36ea-54b8-45c1-92ac-394d5db2e719',
    position: 'Torwart',
    league: 'Premier League',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 29,
    name: 'Mark Gillespie',
    dateOfBirth: '27.03.1992',
    nationality: 'England',
    height: 1.91,
    foot: 'rechts',
    teamMemberSince: '03.07.2020',
    club: 'Newcastle United',
    previousClub: 'Motherwell FC',
    uid: '36be5a88-c6ff-46af-b673-c4b1d418f0a3',
    position: 'Torwart',
    league: 'Premier League',
    marketValue: 300000,
  },
  {
    jerseyNumber: 4,
    name: 'Sven Botman',
    dateOfBirth: '12.01.2000',
    nationality: 'Niederlande',
    height: 1.95,
    foot: 'links',
    teamMemberSince: '01.07.2022',
    club: 'Newcastle United',
    previousClub: 'LOSC Lille',
    uid: '1d6e615e-d609-4681-9812-5ef711e7b665',
    position: 'Innenverteidiger',
    league: 'Premier League',
    marketValue: 45000000,
  },
  {
    jerseyNumber: 33,
    name: 'Dan Burn',
    dateOfBirth: '09.05.1992',
    nationality: 'England',
    height: 2.01,
    foot: 'links',
    teamMemberSince: '31.01.2022',
    club: 'Newcastle United',
    previousClub: 'Brighton & Hove Albion',
    uid: '626a5a54-1f77-4219-b01f-52f98df31eca',
    position: 'Innenverteidiger',
    league: 'Premier League',
    marketValue: 12000000,
  },
  {
    jerseyNumber: 5,
    name: 'Fabian Schär',
    dateOfBirth: '20.12.1991',
    nationality: 'Schweiz',
    height: 1.86,
    foot: 'rechts',
    teamMemberSince: '26.07.2018',
    club: 'Newcastle United',
    previousClub: 'Deportivo La Coruna',
    uid: 'c3406d4c-cae5-49c9-ba2b-11b74d3c9ebd',
    position: 'Innenverteidiger',
    league: 'Premier League',
    marketValue: 10000000,
  },
  {
    jerseyNumber: 6,
    name: 'Jamaal Lascelles',
    dateOfBirth: '11.11.1993',
    nationality: 'England',
    height: 1.88,
    foot: 'rechts',
    teamMemberSince: '09.08.2014',
    club: 'Newcastle United',
    previousClub: ': Ablöse 5,00 Mio. €',
    uid: '55b55875-f0fa-4b9e-8abd-1b869da021c5',
    position: 'Innenverteidiger',
    league: 'Premier League',
    marketValue: 7000000,
  },
  {
    jerseyNumber: 13,
    name: 'Matt Targett',
    dateOfBirth: '18.09.1995',
    nationality: 'England',
    height: 1.83,
    foot: 'links',
    teamMemberSince: '01.07.2022',
    club: 'Newcastle United',
    previousClub: 'Aston Villa',
    uid: '1740b04f-0612-41a1-aabb-df25a0c05008',
    position: 'Linker Verteidiger',
    league: 'Premier League',
    marketValue: 15000000,
  },
  {
    jerseyNumber: 12,
    name: 'Jamal Lewis',
    dateOfBirth: '25.01.1998',
    nationality: 'Nordirland',
    height: 1.78,
    foot: 'links',
    teamMemberSince: '08.09.2020',
    club: 'Newcastle United',
    previousClub: ': Ablöse 16,50 Mio. €',
    uid: 'ac977fb4-54f9-4867-b72a-6496ac3c4594',
    position: 'Linker Verteidiger',
    league: 'Premier League',
    marketValue: 8000000,
  },
  {
    jerseyNumber: 3,
    name: 'Paul Dummett',
    dateOfBirth: '26.09.1991',
    nationality: 'Wales',
    height: 1.83,
    foot: 'links',
    teamMemberSince: '01.07.2012',
    club: 'Newcastle United',
    previousClub: 'Newcastle United U21',
    uid: 'af3d9275-6ddc-4694-b88b-7f818be6ac55',
    position: 'Linker Verteidiger',
    league: 'Premier League',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 2,
    name: 'Kieran Trippier',
    dateOfBirth: '19.09.1990',
    nationality: 'England',
    height: 1.73,
    foot: 'rechts',
    teamMemberSince: '07.01.2022',
    club: 'Newcastle United',
    previousClub: 'Atlético Madrid',
    uid: '4a16cbd3-575f-407c-9cba-240d3f61d371',
    position: 'Rechter Verteidiger',
    league: 'Premier League',
    marketValue: 13000000,
  },
  {
    jerseyNumber: 17,
    name: 'Emil Krafth',
    dateOfBirth: '02.08.1994',
    nationality: 'Schweden',
    height: 1.84,
    foot: 'rechts',
    teamMemberSince: '08.08.2019',
    club: 'Newcastle United',
    previousClub: ': Ablöse 5,40 Mio. €',
    uid: '9d72672f-3175-4df2-988f-c51422837a40',
    position: 'Rechter Verteidiger',
    league: 'Premier League',
    marketValue: 4000000,
  },
  {
    jerseyNumber: 19,
    name: 'Javier Manquillo',
    dateOfBirth: '05.05.1994',
    nationality: 'Spanien',
    height: 1.78,
    foot: 'rechts',
    teamMemberSince: '21.07.2017',
    club: 'Newcastle United',
    previousClub: 'Atlético Madrid',
    uid: 'e57bb663-7eaf-4838-a0fc-f734b6c85d96',
    position: 'Rechter Verteidiger',
    league: 'Premier League',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 30,
    name: 'Harrison Ashby',
    dateOfBirth: '14.11.2001',
    nationality: 'Schottland',
    height: 1.79,
    foot: 'rechts',
    teamMemberSince: '31.01.2023',
    club: 'Newcastle United',
    previousClub: 'West Ham United U21',
    uid: '91b5fcbf-f8f3-4c8b-bbce-ff01b9770652',
    position: 'Rechter Verteidiger',
    league: 'Premier League',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 39,
    name: 'Bruno Guimarães',
    dateOfBirth: '16.11.1997',
    nationality: 'Brasilien',
    height: 1.82,
    foot: 'rechts',
    teamMemberSince: '30.01.2022',
    club: 'Newcastle United',
    previousClub: ': Ablöse 42,10 Mio. €',
    uid: 'd79a3dfb-8f08-4060-887e-70bdf67f7796',
    position: 'Defensives Mittelfeld',
    league: 'Premier League',
    marketValue: 60000000,
  },
  {
    jerseyNumber: 28,
    name: 'Joe Willock',
    dateOfBirth: '20.08.1999',
    nationality: 'England',
    height: 1.86,
    foot: 'rechts',
    teamMemberSince: '13.08.2021',
    club: 'Newcastle United',
    previousClub: 'FC Arsenal',
    uid: '3ef8d973-bff2-44f8-b976-ad8054dccd21',
    position: 'Zentrales Mittelfeld',
    league: 'Premier League',
    marketValue: 30000000,
  },
  {
    jerseyNumber: 36,
    name: 'Sean Longstaff',
    dateOfBirth: '30.10.1997',
    nationality: 'England',
    height: 1.87,
    foot: 'rechts',
    teamMemberSince: '01.01.2019',
    club: 'Newcastle United',
    previousClub: 'Newcastle United U23',
    uid: '217194a3-8d8b-4aa3-bed6-639b1f83327a',
    position: 'Zentrales Mittelfeld',
    league: 'Premier League',
    marketValue: 22000000,
  },
  {
    jerseyNumber: 11,
    name: 'Matt Ritchie',
    dateOfBirth: '10.09.1989',
    nationality: 'Schottland',
    height: 1.73,
    foot: 'links',
    teamMemberSince: '01.07.2016',
    club: 'Newcastle United',
    previousClub: 'AFC Bournemouth',
    uid: 'c4ba015c-51e6-44d9-aa8c-d6f5b7350de8',
    position: 'Rechtes Mittelfeld',
    league: 'Premier League',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 7,
    name: 'Joelinton',
    dateOfBirth: '14.08.1996',
    nationality: 'Brasilien',
    height: 1.86,
    foot: 'rechts',
    teamMemberSince: '23.07.2019',
    club: 'Newcastle United',
    previousClub: 'TSG 1899 Hoffenheim',
    uid: 'c6f26f46-60fa-4739-bdc6-6da6eb30f6dd',
    position: 'Offensives Mittelfeld',
    league: 'Premier League',
    marketValue: 38000000,
  },
  {
    jerseyNumber: 32,
    name: 'Elliot Anderson',
    dateOfBirth: '06.11.2002',
    nationality: 'Schottland',
    height: 1.79,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'Newcastle United',
    previousClub: 'Newcastle United U21',
    uid: 'fa99d920-2b39-4ee1-8e93-33076c3ed989',
    position: 'Offensives Mittelfeld',
    league: 'Premier League',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 8,
    name: 'Anthony Gordon',
    dateOfBirth: '24.02.2001',
    nationality: 'England',
    height: 1.82,
    foot: 'rechts',
    teamMemberSince: '29.01.2023',
    club: 'Newcastle United',
    previousClub: 'FC Everton',
    uid: 'b5435450-508c-41b2-be17-9be013a68b1d',
    position: 'Linksaußen',
    league: 'Premier League',
    marketValue: 40000000,
  },
  {
    jerseyNumber: 10,
    name: 'Allan Saint-Maximin',
    dateOfBirth: '12.03.1997',
    nationality: 'Frankreich',
    height: 1.73,
    foot: 'rechts',
    teamMemberSince: '02.08.2019',
    club: 'Newcastle United',
    previousClub: 'OGC Nizza',
    uid: '9967c97c-2fc9-4e93-9cd1-0200bd806cef',
    position: 'Linksaußen',
    league: 'Premier League',
    marketValue: 35000000,
  },
  {
    jerseyNumber: 21,
    name: 'Ryan Fraser',
    dateOfBirth: '24.02.1994',
    nationality: 'Schottland',
    height: 1.63,
    foot: 'rechts',
    teamMemberSince: '07.09.2020',
    club: 'Newcastle United',
    previousClub: 'AFC Bournemouth',
    uid: '197b2be4-52ec-4478-864b-8769035c3732',
    position: 'Linksaußen',
    league: 'Premier League',
    marketValue: 8000000,
  },
  {
    jerseyNumber: 24,
    name: 'Miguel Almirón',
    dateOfBirth: '10.02.1994',
    nationality: 'Paraguay',
    height: 1.74,
    foot: 'links',
    teamMemberSince: '31.01.2019',
    club: 'Newcastle United',
    previousClub: 'Atlanta United FC',
    uid: '5e0a45c5-1f3e-4d6a-bc41-2561092ddff6',
    position: 'Rechtsaußen',
    league: 'Premier League',
    marketValue: 35000000,
  },
  {
    jerseyNumber: 23,
    name: 'Jacob Murphy',
    dateOfBirth: '24.02.1995',
    nationality: 'England',
    height: 1.73,
    foot: 'rechts',
    teamMemberSince: '19.07.2017',
    club: 'Newcastle United',
    previousClub: ': Ablöse 11,30 Mio. €',
    uid: '19172b19-cb29-47e8-82bc-d6448e78482d',
    position: 'Rechtsaußen',
    league: 'Premier League',
    marketValue: 10000000,
  },
  {
    jerseyNumber: 14,
    name: 'Alexander Isak',
    dateOfBirth: '21.09.1999',
    nationality: 'Schweden',
    height: 1.92,
    foot: 'rechts',
    teamMemberSince: '26.08.2022',
    club: 'Newcastle United',
    previousClub: 'Real Sociedad San Sebastián',
    uid: '05a738cb-434e-4235-9d81-e5d42fab5968',
    position: 'Mittelstürmer',
    league: 'Premier League',
    marketValue: 50000000,
  },
  {
    jerseyNumber: 9,
    name: 'Callum Wilson',
    dateOfBirth: '27.02.1992',
    nationality: 'England',
    height: 1.8,
    foot: 'rechts',
    teamMemberSince: '07.09.2020',
    club: 'Newcastle United',
    previousClub: 'AFC Bournemouth',
    uid: '486b8b48-2b9c-4699-a114-bdb76e1b2ec9',
    position: 'Mittelstürmer',
    league: 'Premier League',
    marketValue: 18000000,
  },
  {
    jerseyNumber: 13,
    name: 'Alphonse Areola',
    dateOfBirth: '27.02.1993',
    nationality: 'Frankreich',
    height: 1.95,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'West Ham United',
    previousClub: 'FC Paris Saint-Germain',
    uid: '3d9d5673-3a36-44b6-8eba-3a4ad61725c0',
    position: 'Torwart',
    league: 'Premier League',
    marketValue: 8000000,
  },
  {
    jerseyNumber: 1,
    name: 'Lukasz Fabianski',
    dateOfBirth: '18.04.1985',
    nationality: 'Polen',
    height: 1.9,
    foot: 'rechts',
    teamMemberSince: '01.07.2018',
    club: 'West Ham United',
    previousClub: ': Ablöse 8,00 Mio. €',
    uid: 'd5ef5bc3-d5cd-44b7-a950-7c8e735e938c',
    position: 'Torwart',
    league: 'Premier League',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 27,
    name: 'Nayef Aguerd',
    dateOfBirth: '30.03.1996',
    nationality: 'Marokko',
    height: 1.9,
    foot: 'links',
    teamMemberSince: '01.07.2022',
    club: 'West Ham United',
    previousClub: 'FC Stade Rennes',
    uid: '5401cffb-db86-4fb0-ac22-81bbc68129bf',
    position: 'Innenverteidiger',
    league: 'Premier League',
    marketValue: 32000000,
  },
  {
    jerseyNumber: 4,
    name: 'Kurt Zouma',
    dateOfBirth: '27.10.1994',
    nationality: 'Frankreich',
    height: 1.9,
    foot: 'rechts',
    teamMemberSince: '28.08.2021',
    club: 'West Ham United',
    previousClub: 'FC Chelsea',
    uid: '7747c84a-fcce-4934-b2f6-ddda121f87f2',
    position: 'Innenverteidiger',
    league: 'Premier League',
    marketValue: 28000000,
  },
  {
    jerseyNumber: 24,
    name: 'Thilo Kehrer',
    dateOfBirth: '21.09.1996',
    nationality: 'Deutschland',
    height: 1.86,
    foot: 'rechts',
    teamMemberSince: '17.08.2022',
    club: 'West Ham United',
    previousClub: 'FC Paris Saint-Germain',
    uid: 'bf0d2468-199b-49b0-b893-92bb2d5f757e',
    position: 'Innenverteidiger',
    league: 'Premier League',
    marketValue: 18000000,
  },
  {
    jerseyNumber: 21,
    name: 'Angelo Ogbonna',
    dateOfBirth: '23.05.1988',
    nationality: 'Italien',
    height: 1.91,
    foot: 'links',
    teamMemberSince: '10.07.2015',
    club: 'West Ham United',
    previousClub: ': Ablöse 11,00 Mio. €',
    uid: 'a42cf68f-a069-4423-924f-cbc620fefe26',
    position: 'Innenverteidiger',
    league: 'Premier League',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 33,
    name: 'Emerson',
    dateOfBirth: '03.08.1994',
    nationality: 'Italien',
    height: 1.76,
    foot: 'links',
    teamMemberSince: '23.08.2022',
    club: 'West Ham United',
    previousClub: 'FC Chelsea',
    uid: '9ade7881-573f-42ef-924f-d39b86aaa913',
    position: 'Linker Verteidiger',
    league: 'Premier League',
    marketValue: 12000000,
  },
  {
    jerseyNumber: 3,
    name: 'Aaron Cresswell',
    dateOfBirth: '15.12.1989',
    nationality: 'England',
    height: 1.7,
    foot: 'links',
    teamMemberSince: '03.07.2014',
    club: 'West Ham United',
    previousClub: 'Ipswich Town',
    uid: '4afc57c1-c9e5-4069-ace6-9eae2b98b65b',
    position: 'Linker Verteidiger',
    league: 'Premier League',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 2,
    name: 'Ben Johnson',
    dateOfBirth: '24.01.2000',
    nationality: 'England',
    height: 1.84,
    foot: 'rechts',
    teamMemberSince: '01.07.2020',
    club: 'West Ham United',
    previousClub: 'West Ham United U21',
    uid: '8460ae4e-f07f-4068-87bb-d6a6fed7cb22',
    position: 'Rechter Verteidiger',
    league: 'Premier League',
    marketValue: 12000000,
  },
  {
    jerseyNumber: 5,
    name: 'Vladimir Coufal',
    dateOfBirth: '22.08.1992',
    nationality: 'Tschechien',
    height: 1.79,
    foot: 'rechts',
    teamMemberSince: '02.10.2020',
    club: 'West Ham United',
    previousClub: ': Ablöse 6,00 Mio. €',
    uid: 'd89e8f6a-2fa3-4cc8-affa-aaba74e20be0',
    position: 'Rechter Verteidiger',
    league: 'Premier League',
    marketValue: 9000000,
  },
  {
    jerseyNumber: 41,
    name: 'Declan Rice',
    dateOfBirth: '14.01.1999',
    nationality: 'England',
    height: 1.88,
    foot: 'rechts',
    teamMemberSince: '01.07.2017',
    club: 'West Ham United',
    previousClub: 'West Ham United U21',
    uid: '1b5e6e45-45ef-487f-864f-7db5dd55ee1a',
    position: 'Defensives Mittelfeld',
    league: 'Premier League',
    marketValue: 80000000,
  },
  {
    jerseyNumber: 28,
    name: 'Tomas Soucek',
    dateOfBirth: '27.02.1995',
    nationality: 'Tschechien',
    height: 1.92,
    foot: 'rechts',
    teamMemberSince: '27.07.2020',
    club: 'West Ham United',
    previousClub: ': Ablöse 16,20 Mio. €',
    uid: 'd843526a-e0a6-45b8-9100-a0b0893ece28',
    position: 'Defensives Mittelfeld',
    league: 'Premier League',
    marketValue: 35000000,
  },
  {
    jerseyNumber: 12,
    name: 'Flynn Downes',
    dateOfBirth: '20.01.1999',
    nationality: 'England',
    height: 1.78,
    foot: 'rechts',
    teamMemberSince: '07.07.2022',
    club: 'West Ham United',
    previousClub: 'Swansea City',
    uid: '8f9c98f4-2107-42d8-88a9-3ab25135a160',
    position: 'Zentrales Mittelfeld',
    league: 'Premier League',
    marketValue: 8000000,
  },
  {
    jerseyNumber: 11,
    name: 'Lucas Paquetá',
    dateOfBirth: '27.08.1997',
    nationality: 'Brasilien',
    height: 1.8,
    foot: 'links',
    teamMemberSince: '29.08.2022',
    club: 'West Ham United',
    previousClub: 'Olympique Lyon',
    uid: '70e433e0-4571-4730-aee6-b722e5a9d746',
    position: 'Offensives Mittelfeld',
    league: 'Premier League',
    marketValue: 45000000,
  },
  {
    jerseyNumber: 8,
    name: 'Pablo Fornals',
    dateOfBirth: '22.02.1996',
    nationality: 'Spanien',
    height: 1.78,
    foot: 'rechts',
    teamMemberSince: '01.07.2019',
    club: 'West Ham United',
    previousClub: 'FC Villarreal',
    uid: 'a29592e5-357c-4a98-af97-ac13a58ecf14',
    position: 'Offensives Mittelfeld',
    league: 'Premier League',
    marketValue: 25000000,
  },
  {
    jerseyNumber: 10,
    name: 'Manuel Lanzini',
    dateOfBirth: '15.02.1993',
    nationality: 'Argentinien',
    height: 1.67,
    foot: 'rechts',
    teamMemberSince: '01.07.2016',
    club: 'West Ham United',
    previousClub: 'Al-Jazira (Abu Dhabi)',
    uid: '9d0706a1-3151-4237-89ec-86c3fc53b444',
    position: 'Offensives Mittelfeld',
    league: 'Premier League',
    marketValue: 8000000,
  },
  {
    jerseyNumber: 22,
    name: 'Saïd Benrahma',
    dateOfBirth: '10.08.1995',
    nationality: 'Algerien',
    height: 1.72,
    foot: 'rechts',
    teamMemberSince: '29.01.2021',
    club: 'West Ham United',
    previousClub: 'FC Brentford',
    uid: '93963788-b06e-40b6-a5a2-79c51fbca9fd',
    position: 'Linksaußen',
    league: 'Premier League',
    marketValue: 22000000,
  },
  {
    jerseyNumber: 14,
    name: 'Maxwel Cornet',
    dateOfBirth: '27.09.1996',
    nationality: 'Elfenbeinküste',
    height: 1.79,
    foot: 'links',
    teamMemberSince: '05.08.2022',
    club: 'West Ham United',
    previousClub: 'FC Burnley',
    uid: 'fd1e9885-4184-49e7-aab9-bcfba89be846',
    position: 'Linksaußen',
    league: 'Premier League',
    marketValue: 15000000,
  },
  {
    jerseyNumber: 20,
    name: 'Jarrod Bowen',
    dateOfBirth: '20.12.1996',
    nationality: 'England',
    height: 1.82,
    foot: 'links',
    teamMemberSince: '31.01.2020',
    club: 'West Ham United',
    previousClub: 'Hull City',
    uid: '5ce10623-fc5c-4ca0-9a4f-06856b2105df',
    position: 'Rechtsaußen',
    league: 'Premier League',
    marketValue: 38000000,
  },
  {
    jerseyNumber: 7,
    name: 'Gianluca Scamacca',
    dateOfBirth: '01.01.1999',
    nationality: 'Italien',
    height: 1.95,
    foot: 'rechts',
    teamMemberSince: '26.07.2022',
    club: 'West Ham United',
    previousClub: 'US Sassuolo',
    uid: '7f5c7a09-cc5e-4406-8ce6-43b2a9f3e2f7',
    position: 'Mittelstürmer',
    league: 'Premier League',
    marketValue: 27000000,
  },
  {
    jerseyNumber: 18,
    name: 'Danny Ings',
    dateOfBirth: '23.07.1992',
    nationality: 'England',
    height: 1.77,
    foot: 'rechts',
    teamMemberSince: '20.01.2023',
    club: 'West Ham United',
    previousClub: 'Aston Villa',
    uid: '5df16e72-8e61-4c3b-8242-5c7c4ea28fdd',
    position: 'Mittelstürmer',
    league: 'Premier League',
    marketValue: 16000000,
  },
  {
    jerseyNumber: 9,
    name: 'Michail Antonio',
    dateOfBirth: '28.03.1990',
    nationality: 'Jamaika',
    height: 1.8,
    foot: 'rechts',
    teamMemberSince: '01.09.2015',
    club: 'West Ham United',
    previousClub: ': Ablöse 9,50 Mio. €',
    uid: 'b2abbd77-586b-4342-823c-49a25679b4ba',
    position: 'Mittelstürmer',
    league: 'Premier League',
    marketValue: 9000000,
  },
  {
    jerseyNumber: 1,
    name: 'Danny Ward',
    dateOfBirth: '22.06.1993',
    nationality: 'Wales',
    height: 1.91,
    foot: 'rechts',
    teamMemberSince: '20.07.2018',
    club: 'Leicester City',
    previousClub: 'FC Liverpool',
    uid: '324ad1f5-d3d3-4d0f-acf6-6301928599f9',
    position: 'Torwart',
    league: 'Premier League',
    marketValue: 8000000,
  },
  {
    jerseyNumber: 12,
    name: 'Alex Smithies',
    dateOfBirth: '05.03.1990',
    nationality: 'England',
    height: 1.89,
    foot: 'rechts',
    teamMemberSince: '12.08.2022',
    club: 'Leicester City',
    previousClub: 'Cardiff City',
    uid: 'dfc9bec9-3bc9-48d7-a592-15910d3a08f0',
    position: 'Torwart',
    league: 'Premier League',
    marketValue: 600000,
  },
  {
    jerseyNumber: 3,
    name: 'Wout Faes',
    dateOfBirth: '03.04.1998',
    nationality: 'Belgien',
    height: 1.87,
    foot: 'rechts',
    teamMemberSince: '01.09.2022',
    club: 'Leicester City',
    previousClub: 'Stade Reims',
    uid: 'fbf2de55-386e-42da-95fb-3fb4b84b4925',
    position: 'Innenverteidiger',
    league: 'Premier League',
    marketValue: 20000000,
  },
  {
    jerseyNumber: 4,
    name: 'Caglar Söyüncü',
    dateOfBirth: '23.05.1996',
    nationality: 'Türkei',
    height: 1.85,
    foot: 'rechts',
    teamMemberSince: '09.08.2018',
    club: 'Leicester City',
    previousClub: 'SC Freiburg',
    uid: 'b5eca44a-8cd8-4743-9638-5a91acf5bb6d',
    position: 'Innenverteidiger',
    league: 'Premier League',
    marketValue: 15000000,
  },
  {
    jerseyNumber: 15,
    name: 'Harry Souttar',
    dateOfBirth: '22.10.1998',
    nationality: 'Australien',
    height: 1.98,
    foot: 'rechts',
    teamMemberSince: '31.01.2023',
    club: 'Leicester City',
    previousClub: 'Stoke City',
    uid: '424ca7f8-ea3b-4751-89a8-330df360f467',
    position: 'Innenverteidiger',
    league: 'Premier League',
    marketValue: 15000000,
  },
  {
    jerseyNumber: 18,
    name: 'Daniel Amartey',
    dateOfBirth: '21.12.1994',
    nationality: 'Ghana',
    height: 1.86,
    foot: 'rechts',
    teamMemberSince: '22.01.2016',
    club: 'Leicester City',
    previousClub: 'FC Kopenhagen',
    uid: '8d52978b-5883-4ac5-960e-80b81c81b415',
    position: 'Innenverteidiger',
    league: 'Premier League',
    marketValue: 15000000,
  },
  {
    jerseyNumber: 23,
    name: 'Jannik Vestergaard',
    dateOfBirth: '03.08.1992',
    nationality: 'Dänemark',
    height: 1.99,
    foot: 'beidfüßig',
    teamMemberSince: '13.08.2021',
    club: 'Leicester City',
    previousClub: 'FC Southampton',
    uid: 'ed0b4b1f-7600-4c9f-971b-56c11f80c2ac',
    position: 'Innenverteidiger',
    league: 'Premier League',
    marketValue: 5000000,
  },
  {
    jerseyNumber: 6,
    name: 'Jonny Evans',
    dateOfBirth: '03.01.1988',
    nationality: 'Nordirland',
    height: 1.88,
    foot: 'beidfüßig',
    teamMemberSince: '01.07.2018',
    club: 'Leicester City',
    previousClub: 'West Bromwich Albion',
    uid: 'bb091bfd-938a-4a9c-86cb-433ab80be03f',
    position: 'Innenverteidiger',
    league: 'Premier League',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 16,
    name: 'Victor Kristiansen',
    dateOfBirth: '16.12.2002',
    nationality: 'Dänemark',
    height: 1.81,
    foot: 'links',
    teamMemberSince: '20.01.2023',
    club: 'Leicester City',
    previousClub: 'FC Kopenhagen',
    uid: 'e25f12c1-789b-4f61-be30-335ff5e04f7a',
    position: 'Linker Verteidiger',
    league: 'Premier League',
    marketValue: 12000000,
  },
  {
    jerseyNumber: 33,
    name: 'Luke Thomas',
    dateOfBirth: '10.06.2001',
    nationality: 'England',
    height: 1.81,
    foot: 'links',
    teamMemberSince: '01.07.2020',
    club: 'Leicester City',
    previousClub: 'Leicester City U21',
    uid: '6772bf55-60a4-4f1f-ad61-e15f68fab664',
    position: 'Linker Verteidiger',
    league: 'Premier League',
    marketValue: 10000000,
  },
  {
    jerseyNumber: 5,
    name: 'Ryan Bertrand',
    dateOfBirth: '05.08.1989',
    nationality: 'England',
    height: 1.79,
    foot: 'links',
    teamMemberSince: '15.07.2021',
    club: 'Leicester City',
    previousClub: 'FC Southampton',
    uid: '75da7a2a-b397-4667-a6a3-206f204d0b8b',
    position: 'Linker Verteidiger',
    league: 'Premier League',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 27,
    name: 'Timothy Castagne',
    dateOfBirth: '05.12.1995',
    nationality: 'Belgien',
    height: 1.85,
    foot: 'rechts',
    teamMemberSince: '03.09.2020',
    club: 'Leicester City',
    previousClub: 'Atalanta Bergamo',
    uid: '82d29440-d5f7-4c89-9e2a-ffa084c3a40f',
    position: 'Rechter Verteidiger',
    league: 'Premier League',
    marketValue: 28000000,
  },
  {
    jerseyNumber: 2,
    name: 'James Justin',
    dateOfBirth: '23.02.1998',
    nationality: 'England',
    height: 1.83,
    foot: 'rechts',
    teamMemberSince: '01.07.2019',
    club: 'Leicester City',
    previousClub: 'Luton Town',
    uid: '7d71039f-17a9-4104-bc83-cae127467009',
    position: 'Rechter Verteidiger',
    league: 'Premier League',
    marketValue: 25000000,
  },
  {
    jerseyNumber: 21,
    name: 'Ricardo Pereira',
    dateOfBirth: '06.10.1993',
    nationality: 'Portugal',
    height: 1.75,
    foot: 'rechts',
    teamMemberSince: '01.07.2018',
    club: 'Leicester City',
    previousClub: 'FC Porto',
    uid: 'dca7af3f-f937-4517-940a-f7fb91453700',
    position: 'Rechter Verteidiger',
    league: 'Premier League',
    marketValue: 12000000,
  },
  {
    jerseyNumber: 25,
    name: 'Wilfred Ndidi',
    dateOfBirth: '16.12.1996',
    nationality: 'Nigeria',
    height: 1.83,
    foot: 'rechts',
    teamMemberSince: '03.01.2017',
    club: 'Leicester City',
    previousClub: 'KRC Genk',
    uid: '001a7952-e27e-4946-bb3f-33b91fce6e95',
    position: 'Defensives Mittelfeld',
    league: 'Premier League',
    marketValue: 32000000,
  },
  {
    jerseyNumber: 42,
    name: 'Boubakary Soumaré',
    dateOfBirth: '27.02.1999',
    nationality: 'Frankreich',
    height: 1.88,
    foot: 'rechts',
    teamMemberSince: '02.07.2021',
    club: 'Leicester City',
    previousClub: 'LOSC Lille',
    uid: 'af43aa32-9ad1-4ce3-9bf6-81cce53cad38',
    position: 'Defensives Mittelfeld',
    league: 'Premier League',
    marketValue: 20000000,
  },
  {
    jerseyNumber: 24,
    name: 'Nampalys Mendy',
    dateOfBirth: '23.06.1992',
    nationality: 'Senegal',
    height: 1.67,
    foot: 'rechts',
    teamMemberSince: '03.07.2016',
    club: 'Leicester City',
    previousClub: 'OGC Nizza',
    uid: '2af17e2d-b93e-432b-92e5-a53af6d46727',
    position: 'Defensives Mittelfeld',
    league: 'Premier League',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 8,
    name: 'Youri Tielemans',
    dateOfBirth: '07.05.1997',
    nationality: 'Belgien',
    height: 1.76,
    foot: 'rechts',
    teamMemberSince: '08.07.2019',
    club: 'Leicester City',
    previousClub: 'AS Monaco',
    uid: 'a67e5830-856f-41f8-a1aa-74c177ad9f46',
    position: 'Zentrales Mittelfeld',
    league: 'Premier League',
    marketValue: 30000000,
  },
  {
    jerseyNumber: 22,
    name: 'Kiernan Dewsbury-Hall',
    dateOfBirth: '06.09.1998',
    nationality: 'England',
    height: 1.78,
    foot: 'links',
    teamMemberSince: '01.07.2021',
    club: 'Leicester City',
    previousClub: 'Leicester City U21',
    uid: '0b9665f3-ec77-4f21-87e3-21ba76fe4011',
    position: 'Zentrales Mittelfeld',
    league: 'Premier League',
    marketValue: 25000000,
  },
  {
    jerseyNumber: 26,
    name: 'Dennis Praet',
    dateOfBirth: '14.05.1994',
    nationality: 'Belgien',
    height: 1.81,
    foot: 'rechts',
    teamMemberSince: '08.08.2019',
    club: 'Leicester City',
    previousClub: 'Sampdoria Genua',
    uid: 'bb0c4690-9cb4-4101-8db8-2ee5632328cb',
    position: 'Zentrales Mittelfeld',
    league: 'Premier League',
    marketValue: 8000000,
  },
  {
    jerseyNumber: 10,
    name: 'James Maddison',
    dateOfBirth: '23.11.1996',
    nationality: 'England',
    height: 1.75,
    foot: 'rechts',
    teamMemberSince: '01.07.2018',
    club: 'Leicester City',
    previousClub: ': Ablöse 25,00 Mio. €',
    uid: '02b1df52-16dc-430a-8ba9-97a2cb21a32d',
    position: 'Offensives Mittelfeld',
    league: 'Premier League',
    marketValue: 55000000,
  },
  {
    jerseyNumber: 7,
    name: 'Harvey Barnes',
    dateOfBirth: '09.12.1997',
    nationality: 'England',
    height: 1.82,
    foot: 'rechts',
    teamMemberSince: '01.07.2018',
    club: 'Leicester City',
    previousClub: 'Leicester City U21',
    uid: '44501192-31be-4bb6-8458-8c59ca493b98',
    position: 'Linksaußen',
    league: 'Premier League',
    marketValue: 32000000,
  },
  {
    jerseyNumber: 37,
    name: 'Tetê',
    dateOfBirth: '15.02.2000',
    nationality: 'Brasilien',
    height: 1.75,
    foot: 'links',
    teamMemberSince: '29.01.2023',
    club: 'Leicester City',
    previousClub: 'Shakhtar Donetsk',
    uid: '39799a3b-c9eb-4b62-bcf9-1126a13a765c',
    position: 'Rechtsaußen',
    league: 'Premier League',
    marketValue: 25000000,
  },
  {
    jerseyNumber: 20,
    name: 'Patson Daka',
    dateOfBirth: '09.10.1998',
    nationality: 'Sambia',
    height: 1.83,
    foot: 'rechts',
    teamMemberSince: '01.07.2021',
    club: 'Leicester City',
    previousClub: 'Red Bull Salzburg',
    uid: '9d010f35-e9b2-4238-bc45-a59913176d75',
    position: 'Mittelstürmer',
    league: 'Premier League',
    marketValue: 20000000,
  },
  {
    jerseyNumber: 14,
    name: 'Kelechi Iheanacho',
    dateOfBirth: '03.10.1996',
    nationality: 'Nigeria',
    height: 1.85,
    foot: 'links',
    teamMemberSince: '03.08.2017',
    club: 'Leicester City',
    previousClub: 'Manchester City',
    uid: 'ac19a8da-eff8-48ee-9031-c48c739b8bfe',
    position: 'Mittelstürmer',
    league: 'Premier League',
    marketValue: 17000000,
  },
  {
    jerseyNumber: 9,
    name: 'Jamie Vardy',
    dateOfBirth: '11.01.1987',
    nationality: 'England',
    height: 1.79,
    foot: 'rechts',
    teamMemberSince: '01.07.2012',
    club: 'Leicester City',
    previousClub: 'Fleetwood Town',
    uid: 'ad309915-abc0-41bd-a9fd-36f4fc6483c0',
    position: 'Mittelstürmer',
    league: 'Premier League',
    marketValue: 4000000,
  },
  {
    jerseyNumber: 1,
    name: 'Emiliano Martínez',
    dateOfBirth: '02.09.1992',
    nationality: 'Argentinien',
    height: 1.95,
    foot: 'rechts',
    teamMemberSince: '16.09.2020',
    club: 'Aston Villa',
    previousClub: 'FC Arsenal',
    uid: 'e8e28df1-a4ae-4543-bfc9-7838d346fecc',
    position: 'Torwart',
    league: 'Premier League',
    marketValue: 28000000,
  },
  {
    jerseyNumber: 25,
    name: 'Robin Olsen',
    dateOfBirth: '08.01.1990',
    nationality: 'Schweden',
    height: 1.96,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'Aston Villa',
    previousClub: 'AS Rom',
    uid: '6eaba0ad-2689-4896-96de-6fd5df90d515',
    position: 'Torwart',
    league: 'Premier League',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 12,
    name: 'Jed Steer',
    dateOfBirth: '23.09.1992',
    nationality: 'England',
    height: 1.83,
    foot: 'links',
    teamMemberSince: '01.07.2013',
    club: 'Aston Villa',
    previousClub: ': Ablöse 540 Tsd. €',
    uid: '464675b3-70d5-42af-811f-0b69c0d4330d',
    position: 'Torwart',
    league: 'Premier League',
    marketValue: 600000,
  },
  {
    jerseyNumber: 38,
    name: 'Viljami Sinisalo',
    dateOfBirth: '11.10.2001',
    nationality: 'Finnland',
    height: 1.9,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'Aston Villa',
    previousClub: 'Aston Villa U21',
    uid: '70aeca4c-0ff4-4074-9d2f-d736b2ff48a4',
    position: 'Torwart',
    league: 'Premier League',
    marketValue: 400000,
  },
  {
    jerseyNumber: 3,
    name: 'Diego Carlos',
    dateOfBirth: '15.03.1993',
    nationality: 'Brasilien',
    height: 1.85,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'Aston Villa',
    previousClub: 'FC Sevilla',
    uid: '7384e357-678f-44f5-a0e6-c304471858e0',
    position: 'Innenverteidiger',
    league: 'Premier League',
    marketValue: 30000000,
  },
  {
    jerseyNumber: 5,
    name: 'Tyrone Mings',
    dateOfBirth: '13.03.1993',
    nationality: 'England',
    height: 1.96,
    foot: 'links',
    teamMemberSince: '08.07.2019',
    club: 'Aston Villa',
    previousClub: 'AFC Bournemouth',
    uid: '7fdde1d5-a5b5-4da9-9088-e405c40589e7',
    position: 'Innenverteidiger',
    league: 'Premier League',
    marketValue: 25000000,
  },
  {
    jerseyNumber: 4,
    name: 'Ezri Konsa',
    dateOfBirth: '23.10.1997',
    nationality: 'England',
    height: 1.83,
    foot: 'rechts',
    teamMemberSince: '11.07.2019',
    club: 'Aston Villa',
    previousClub: 'FC Brentford',
    uid: 'f1672a0e-9c06-46c3-af4f-b9ff4be12ca7',
    position: 'Innenverteidiger',
    league: 'Premier League',
    marketValue: 22000000,
  },
  {
    jerseyNumber: 16,
    name: 'Calum Chambers',
    dateOfBirth: '20.01.1995',
    nationality: 'England',
    height: 1.82,
    foot: 'rechts',
    teamMemberSince: '27.01.2022',
    club: 'Aston Villa',
    previousClub: 'FC Arsenal',
    uid: 'f38656e0-b1db-4d00-a58c-f9e39f2c53c9',
    position: 'Innenverteidiger',
    league: 'Premier League',
    marketValue: 8000000,
  },
  {
    jerseyNumber: 15,
    name: 'Álex Moreno',
    dateOfBirth: '08.06.1993',
    nationality: 'Spanien',
    height: 1.79,
    foot: 'links',
    teamMemberSince: '11.01.2023',
    club: 'Aston Villa',
    previousClub: 'Real Betis Sevilla',
    uid: '431f7700-67f4-4c11-a5bc-69f1b43060b7',
    position: 'Linker Verteidiger',
    league: 'Premier League',
    marketValue: 20000000,
  },
  {
    jerseyNumber: 27,
    name: 'Lucas Digne',
    dateOfBirth: '20.07.1993',
    nationality: 'Frankreich',
    height: 1.78,
    foot: 'links',
    teamMemberSince: '13.01.2022',
    club: 'Aston Villa',
    previousClub: 'FC Everton',
    uid: 'c2652303-aa65-40a2-9891-37f112ef587a',
    position: 'Linker Verteidiger',
    league: 'Premier League',
    marketValue: 17000000,
  },
  {
    jerseyNumber: 2,
    name: 'Matty Cash',
    dateOfBirth: '07.08.1997',
    nationality: 'Polen',
    height: 1.85,
    foot: 'rechts',
    teamMemberSince: '03.09.2020',
    club: 'Aston Villa',
    previousClub: 'Nottingham Forest',
    uid: 'b05e90e2-2ab1-4873-83fe-cf6313a30303',
    position: 'Rechter Verteidiger',
    league: 'Premier League',
    marketValue: 22000000,
  },
  {
    jerseyNumber: 18,
    name: 'Ashley Young',
    dateOfBirth: '09.07.1985',
    nationality: 'England',
    height: 1.75,
    foot: 'beidfüßig',
    teamMemberSince: '01.07.2021',
    club: 'Aston Villa',
    previousClub: 'Inter Mailand',
    uid: '570942f5-7233-45da-88ba-349843e1d227',
    position: 'Rechter Verteidiger',
    league: 'Premier League',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 44,
    name: 'Boubacar Kamara',
    dateOfBirth: '23.11.1999',
    nationality: 'Frankreich',
    height: 1.84,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'Aston Villa',
    previousClub: 'Olympique Marseille',
    uid: '7020c2cb-a992-4eb5-91d5-dec1226c26bc',
    position: 'Defensives Mittelfeld',
    league: 'Premier League',
    marketValue: 25000000,
  },
  {
    jerseyNumber: 32,
    name: 'Leander Dendoncker',
    dateOfBirth: '15.04.1995',
    nationality: 'Belgien',
    height: 1.88,
    foot: 'rechts',
    teamMemberSince: '01.09.2022',
    club: 'Aston Villa',
    previousClub: 'Wolverhampton Wanderers',
    uid: '150fef3e-1474-4307-b007-d0a81db61fee',
    position: 'Defensives Mittelfeld',
    league: 'Premier League',
    marketValue: 17000000,
  },
  {
    jerseyNumber: 6,
    name: 'Douglas Luiz',
    dateOfBirth: '09.05.1998',
    nationality: 'Brasilien',
    height: 1.77,
    foot: 'rechts',
    teamMemberSince: '25.07.2019',
    club: 'Aston Villa',
    previousClub: 'Manchester City',
    uid: '357c221f-1891-4957-9478-61eed1604fb2',
    position: 'Zentrales Mittelfeld',
    league: 'Premier League',
    marketValue: 35000000,
  },
  {
    jerseyNumber: 41,
    name: 'Jacob Ramsey',
    dateOfBirth: '28.05.2001',
    nationality: 'England',
    height: 1.8,
    foot: 'rechts',
    teamMemberSince: '01.07.2020',
    club: 'Aston Villa',
    previousClub: 'Aston Villa U23',
    uid: '95f260ab-1806-425f-bfa0-7bd707f654f0',
    position: 'Zentrales Mittelfeld',
    league: 'Premier League',
    marketValue: 32000000,
  },
  {
    jerseyNumber: 7,
    name: 'John McGinn',
    dateOfBirth: '18.10.1994',
    nationality: 'Schottland',
    height: 1.78,
    foot: 'links',
    teamMemberSince: '08.08.2018',
    club: 'Aston Villa',
    previousClub: 'Hibernian FC',
    uid: 'bb2ddf0c-0368-42de-a5d1-c1e3b9448b98',
    position: 'Zentrales Mittelfeld',
    league: 'Premier League',
    marketValue: 27000000,
  },
  {
    jerseyNumber: 31,
    name: 'Leon Bailey',
    dateOfBirth: '09.08.1997',
    nationality: 'Jamaika',
    height: 1.78,
    foot: 'links',
    teamMemberSince: '04.08.2021',
    club: 'Aston Villa',
    previousClub: 'Bayer 04 Leverkusen',
    uid: '8719dd84-d2ef-455e-9a64-55ea0bdeb4db',
    position: 'Linksaußen',
    league: 'Premier League',
    marketValue: 25000000,
  },
  {
    jerseyNumber: 23,
    name: 'Philippe Coutinho',
    dateOfBirth: '12.06.1992',
    nationality: 'Brasilien',
    height: 1.72,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'Aston Villa',
    previousClub: 'FC Barcelona',
    uid: '5bd22ba4-b0f9-4481-8974-e7d3f0de301f',
    position: 'Linksaußen',
    league: 'Premier League',
    marketValue: 14000000,
  },
  {
    jerseyNumber: 10,
    name: 'Emiliano Buendía',
    dateOfBirth: '25.12.1996',
    nationality: 'Argentinien',
    height: 1.72,
    foot: 'rechts',
    teamMemberSince: '01.07.2021',
    club: 'Aston Villa',
    previousClub: ': Ablöse 38,40 Mio. €',
    uid: '94d6c967-27a6-4d22-9a31-3a34768cbcce',
    position: 'Rechtsaußen',
    league: 'Premier League',
    marketValue: 28000000,
  },
  {
    jerseyNumber: 9,
    name: 'Bertrand Traoré',
    dateOfBirth: '06.09.1995',
    nationality: 'Burkina Faso',
    height: 1.81,
    foot: 'links',
    teamMemberSince: '19.09.2020',
    club: 'Aston Villa',
    previousClub: ': Ablöse 18,40 Mio. €',
    uid: '7b4795cf-2bee-44f8-b842-a2f668800572',
    position: 'Rechtsaußen',
    league: 'Premier League',
    marketValue: 14000000,
  },
  {
    jerseyNumber: 11,
    name: 'Ollie Watkins',
    dateOfBirth: '30.12.1995',
    nationality: 'England',
    height: 1.8,
    foot: 'rechts',
    teamMemberSince: '09.09.2020',
    club: 'Aston Villa',
    previousClub: 'FC Brentford',
    uid: '63227daf-5d13-452a-beb0-2daebdac68f6',
    position: 'Mittelstürmer',
    league: 'Premier League',
    marketValue: 32000000,
  },
  {
    jerseyNumber: 22,
    name: 'Jhon Durán',
    dateOfBirth: '13.12.2003',
    nationality: 'Kolumbien',
    height: 1.85,
    foot: 'links',
    teamMemberSince: '23.01.2023',
    club: 'Aston Villa',
    previousClub: 'Chicago Fire FC',
    uid: '7d79ec3c-2116-4fff-9e0e-492090e20602',
    position: 'Mittelstürmer',
    league: 'Premier League',
    marketValue: 12000000,
  },
  {
    jerseyNumber: 1,
    name: 'José Sá',
    dateOfBirth: '17.01.1993',
    nationality: 'Portugal',
    height: 1.92,
    foot: 'rechts',
    teamMemberSince: '15.07.2021',
    club: 'Wolverhampton Wanderers',
    previousClub: 'Olympiakos Piräus',
    uid: 'dd3043de-123b-441c-9028-8b430ae193ec',
    position: 'Torwart',
    league: 'Premier League',
    marketValue: 18000000,
  },
  {
    jerseyNumber: 13,
    name: 'Louie Moulden',
    dateOfBirth: '06.01.2002',
    nationality: 'England',
    height: 1.9,
    foot: 'rechts',
    teamMemberSince: '01.07.2021',
    club: 'Wolverhampton Wanderers',
    previousClub: 'Manchester City U23',
    uid: '64ffc731-56cc-4532-b0d5-a187130a2acc',
    position: 'Torwart',
    league: 'Premier League',
    marketValue: 200000,
  },
  {
    jerseyNumber: 23,
    name: 'Max Kilman',
    dateOfBirth: '23.05.1997',
    nationality: 'England',
    height: 1.94,
    foot: 'links',
    teamMemberSince: '09.08.2018',
    club: 'Wolverhampton Wanderers',
    previousClub: ': Ablöse ablösefrei',
    uid: '878c3be7-fb28-40cb-8cc4-b7ba1a6def5f',
    position: 'Innenverteidiger',
    league: 'Premier League',
    marketValue: 25000000,
  },
  {
    jerseyNumber: 4,
    name: 'Nathan Collins',
    dateOfBirth: '30.04.2001',
    nationality: 'Irland',
    height: 1.93,
    foot: 'rechts',
    teamMemberSince: '12.07.2022',
    club: 'Wolverhampton Wanderers',
    previousClub: 'FC Burnley',
    uid: '42c8afb5-c54c-4985-bbef-a633e658ce2d',
    position: 'Innenverteidiger',
    league: 'Premier League',
    marketValue: 22000000,
  },
  {
    jerseyNumber: 15,
    name: 'Craig Dawson',
    dateOfBirth: '06.05.1990',
    nationality: 'England',
    height: 1.88,
    foot: 'rechts',
    teamMemberSince: '22.01.2023',
    club: 'Wolverhampton Wanderers',
    previousClub: 'West Ham United',
    uid: 'a49853fa-d3fa-4672-930a-e0bf1b551517',
    position: 'Innenverteidiger',
    league: 'Premier League',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 24,
    name: 'Toti',
    dateOfBirth: '16.01.1999',
    nationality: 'Portugal',
    height: 1.87,
    foot: 'links',
    teamMemberSince: '02.09.2020',
    club: 'Wolverhampton Wanderers',
    previousClub: 'GD Estoril Praia',
    uid: 'ae44401d-849a-47d0-bbc0-dbc37a800011',
    position: 'Innenverteidiger',
    league: 'Premier League',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 3,
    name: 'Rayan Aït-Nouri',
    dateOfBirth: '06.06.2001',
    nationality: 'Algerien',
    height: 1.8,
    foot: 'links',
    teamMemberSince: '09.07.2021',
    club: 'Wolverhampton Wanderers',
    previousClub: 'Angers SCO',
    uid: 'd1ae07c7-30ca-46f9-97bf-8b74624bb21e',
    position: 'Linker Verteidiger',
    league: 'Premier League',
    marketValue: 22000000,
  },
  {
    jerseyNumber: 19,
    name: 'Jonny Otto',
    dateOfBirth: '03.03.1994',
    nationality: 'Spanien',
    height: 1.75,
    foot: 'beidfüßig',
    teamMemberSince: '31.01.2019',
    club: 'Wolverhampton Wanderers',
    previousClub: 'Atlético Madrid',
    uid: 'd3799319-034d-4ea2-a6a2-b694a3c965dd',
    position: 'Linker Verteidiger',
    league: 'Premier League',
    marketValue: 17000000,
  },
  {
    jerseyNumber: 64,
    name: 'Hugo Bueno',
    dateOfBirth: '18.09.2002',
    nationality: 'Spanien',
    height: 1.8,
    foot: 'links',
    teamMemberSince: '01.07.2022',
    club: 'Wolverhampton Wanderers',
    previousClub: 'Wolverhampton Wanderers U21',
    uid: '69645117-91fd-4824-a470-2af1d9302551',
    position: 'Linker Verteidiger',
    league: 'Premier League',
    marketValue: 200000,
  },
  {
    jerseyNumber: 22,
    name: 'Nélson Semedo',
    dateOfBirth: '16.11.1993',
    nationality: 'Portugal',
    height: 1.77,
    foot: 'rechts',
    teamMemberSince: '23.09.2020',
    club: 'Wolverhampton Wanderers',
    previousClub: 'FC Barcelona',
    uid: '9734cd9e-9518-401f-a67d-5301bcab6221',
    position: 'Rechter Verteidiger',
    league: 'Premier League',
    marketValue: 15000000,
  },
  {
    jerseyNumber: 8,
    name: 'Rúben Neves',
    dateOfBirth: '13.03.1997',
    nationality: 'Portugal',
    height: 1.8,
    foot: 'rechts',
    teamMemberSince: '08.07.2017',
    club: 'Wolverhampton Wanderers',
    previousClub: 'FC Porto',
    uid: 'c35ba4ae-6819-4d34-80d3-e3e425ef349b',
    position: 'Defensives Mittelfeld',
    league: 'Premier League',
    marketValue: 40000000,
  },
  {
    jerseyNumber: 5,
    name: 'Mario Lemina',
    dateOfBirth: '01.09.1993',
    nationality: 'Gabun',
    height: 1.84,
    foot: 'rechts',
    teamMemberSince: '13.01.2023',
    club: 'Wolverhampton Wanderers',
    previousClub: 'OGC Nizza',
    uid: 'c2ecbdc4-6aa7-4ea4-a9b1-75183c0f593b',
    position: 'Defensives Mittelfeld',
    league: 'Premier League',
    marketValue: 10000000,
  },
  {
    jerseyNumber: 59,
    name: 'Joe Hodge',
    dateOfBirth: '14.09.2002',
    nationality: 'Irland',
    height: 1.72,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'Wolverhampton Wanderers',
    previousClub: 'Wolverhampton Wanderers U21',
    uid: 'b2988569-2d4d-42d7-bc33-97673302a228',
    position: 'Defensives Mittelfeld',
    league: 'Premier League',
    marketValue: 500000,
  },
  {
    jerseyNumber: 27,
    name: 'Matheus Nunes',
    dateOfBirth: '27.08.1998',
    nationality: 'Portugal',
    height: 1.83,
    foot: 'rechts',
    teamMemberSince: '18.08.2022',
    club: 'Wolverhampton Wanderers',
    previousClub: 'Sporting Lissabon',
    uid: '297754b3-7b81-4b27-8865-b1f099458bee',
    position: 'Zentrales Mittelfeld',
    league: 'Premier League',
    marketValue: 45000000,
  },
  {
    jerseyNumber: 35,
    name: 'João Gomes',
    dateOfBirth: '12.02.2001',
    nationality: 'Brasilien',
    height: 1.76,
    foot: 'rechts',
    teamMemberSince: '30.01.2023',
    club: 'Wolverhampton Wanderers',
    previousClub: 'Flamengo Rio de Janeiro',
    uid: 'dfa30e51-92fa-4b52-b563-46c12a71c403',
    position: 'Zentrales Mittelfeld',
    league: 'Premier League',
    marketValue: 15000000,
  },
  {
    jerseyNumber: 28,
    name: 'João Moutinho',
    dateOfBirth: '08.09.1986',
    nationality: 'Portugal',
    height: 1.7,
    foot: 'rechts',
    teamMemberSince: '24.07.2018',
    club: 'Wolverhampton Wanderers',
    previousClub: 'AS Monaco',
    uid: '1f80e185-c167-4605-b801-20b4617880a3',
    position: 'Zentrales Mittelfeld',
    league: 'Premier League',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 10,
    name: 'Daniel Podence',
    dateOfBirth: '21.10.1995',
    nationality: 'Portugal',
    height: 1.65,
    foot: 'rechts',
    teamMemberSince: '30.01.2020',
    club: 'Wolverhampton Wanderers',
    previousClub: 'Olympiakos Piräus',
    uid: '055c58f3-24cd-4208-92f6-094556b262e8',
    position: 'Linksaußen',
    league: 'Premier League',
    marketValue: 20000000,
  },
  {
    jerseyNumber: 20,
    name: 'Chiquinho',
    dateOfBirth: '05.02.2000',
    nationality: 'Portugal',
    height: 1.79,
    foot: 'rechts',
    teamMemberSince: '17.01.2022',
    club: 'Wolverhampton Wanderers',
    previousClub: 'GD Estoril Praia',
    uid: 'd435d832-478c-438f-9ad4-712157b7449b',
    position: 'Linksaußen',
    league: 'Premier League',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 7,
    name: 'Pedro Neto',
    dateOfBirth: '09.03.2000',
    nationality: 'Portugal',
    height: 1.72,
    foot: 'links',
    teamMemberSince: '02.08.2019',
    club: 'Wolverhampton Wanderers',
    previousClub: ': Ablöse 17,90 Mio. €',
    uid: '8e766230-c574-42c0-b355-f427669d30b9',
    position: 'Rechtsaußen',
    league: 'Premier League',
    marketValue: 32000000,
  },
  {
    jerseyNumber: 21,
    name: 'Pablo Sarabia',
    dateOfBirth: '11.05.1992',
    nationality: 'Spanien',
    height: 1.74,
    foot: 'links',
    teamMemberSince: '17.01.2023',
    club: 'Wolverhampton Wanderers',
    previousClub: 'FC Paris Saint-Germain',
    uid: '52c799b5-2cef-46af-9ecf-3e15cc21b2fd',
    position: 'Rechtsaußen',
    league: 'Premier League',
    marketValue: 16000000,
  },
  {
    jerseyNumber: 37,
    name: 'Adama Traoré',
    dateOfBirth: '25.01.1996',
    nationality: 'Spanien',
    height: 1.78,
    foot: 'rechts',
    teamMemberSince: '08.08.2018',
    club: 'Wolverhampton Wanderers',
    previousClub: 'FC Middlesbrough',
    uid: '64aae6f3-7945-4004-bb70-a8928d307919',
    position: 'Rechtsaußen',
    league: 'Premier League',
    marketValue: 12000000,
  },
  {
    jerseyNumber: 12,
    name: 'Matheus Cunha',
    dateOfBirth: '27.05.1999',
    nationality: 'Brasilien',
    height: 1.83,
    foot: 'rechts',
    teamMemberSince: '01.01.2023',
    club: 'Wolverhampton Wanderers',
    previousClub: 'Atlético Madrid',
    uid: '2d58ab60-83c5-4ed5-b6d2-20f3b36c29dd',
    position: 'Mittelstürmer',
    league: 'Premier League',
    marketValue: 30000000,
  },
  {
    jerseyNumber: 18,
    name: 'Sasa Kalajdzic',
    dateOfBirth: '07.07.1997',
    nationality: 'Österreich',
    height: 2,
    foot: 'rechts',
    teamMemberSince: '31.08.2022',
    club: 'Wolverhampton Wanderers',
    previousClub: 'VfB Stuttgart',
    uid: '4cf44bb9-d313-4b7f-b77d-c9ab004642de',
    position: 'Mittelstürmer',
    league: 'Premier League',
    marketValue: 18000000,
  },
  {
    jerseyNumber: 11,
    name: 'Hee-chan Hwang',
    dateOfBirth: '26.01.1996',
    nationality: 'Südkorea',
    height: 1.77,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'Wolverhampton Wanderers',
    previousClub: 'RasenBallsport Leipzig',
    uid: '100ffb56-1016-4cd7-becb-12278e5d7862',
    position: 'Mittelstürmer',
    league: 'Premier League',
    marketValue: 12000000,
  },
  {
    jerseyNumber: 9,
    name: 'Raúl Jiménez',
    dateOfBirth: '05.05.1991',
    nationality: 'Mexiko',
    height: 1.88,
    foot: 'rechts',
    teamMemberSince: '01.07.2019',
    club: 'Wolverhampton Wanderers',
    previousClub: 'Benfica Lissabon',
    uid: '19640e56-6af9-4c19-a5c3-0e3b751f7742',
    position: 'Mittelstürmer',
    league: 'Premier League',
    marketValue: 9000000,
  },
  {
    jerseyNumber: 29,
    name: 'Diego Costa',
    dateOfBirth: '07.10.1988',
    nationality: 'Spanien',
    height: 1.86,
    foot: 'rechts',
    teamMemberSince: '12.09.2022',
    club: 'Wolverhampton Wanderers',
    previousClub: 'Vereinslos',
    uid: '97a908d6-2ea9-48a3-a366-3dbb619c1585',
    position: 'Mittelstürmer',
    league: 'Premier League',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 31,
    name: 'Gavin Bazunu',
    dateOfBirth: '20.02.2002',
    nationality: 'Irland',
    height: 1.88,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'FC Southampton',
    previousClub: 'Manchester City U21',
    uid: 'a0b5ef50-3eac-4cac-baa3-1836d47d49a2',
    position: 'Torwart',
    league: 'Premier League',
    marketValue: 18000000,
  },
  {
    jerseyNumber: 1,
    name: 'Alex McCarthy',
    dateOfBirth: '03.12.1989',
    nationality: 'England',
    height: 1.93,
    foot: 'rechts',
    teamMemberSince: '01.08.2016',
    club: 'FC Southampton',
    previousClub: 'Crystal Palace',
    uid: 'b502b51d-fb22-4898-963a-07e99385e256',
    position: 'Torwart',
    league: 'Premier League',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 13,
    name: 'Willy Caballero',
    dateOfBirth: '28.09.1981',
    nationality: 'Argentinien',
    height: 1.86,
    foot: 'rechts',
    teamMemberSince: '06.12.2021',
    club: 'FC Southampton',
    previousClub: 'Vereinslos',
    uid: '1e4a2c36-c550-4186-a70c-3f5bafa95d54',
    position: 'Torwart',
    league: 'Premier League',
    marketValue: 200000,
  },
  {
    jerseyNumber: 37,
    name: 'Armel Bella-Kotchap',
    dateOfBirth: '11.12.2001',
    nationality: 'Deutschland',
    height: 1.9,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'FC Southampton',
    previousClub: 'VfL Bochum',
    uid: '1a1f57df-d956-40cb-901d-20cc69c5b79b',
    position: 'Innenverteidiger',
    league: 'Premier League',
    marketValue: 20000000,
  },
  {
    jerseyNumber: 22,
    name: 'Mohammed Salisu',
    dateOfBirth: '17.04.1999',
    nationality: 'Ghana',
    height: 1.88,
    foot: 'links',
    teamMemberSince: '12.08.2020',
    club: 'FC Southampton',
    previousClub: ': Ablöse 12,00 Mio. €',
    uid: 'fb2e3950-a1c9-48e5-a672-14990fb548ab',
    position: 'Innenverteidiger',
    league: 'Premier League',
    marketValue: 18000000,
  },
  {
    jerseyNumber: 6,
    name: 'Duje Caleta-Car',
    dateOfBirth: '17.09.1996',
    nationality: 'Kroatien',
    height: 1.92,
    foot: 'rechts',
    teamMemberSince: '01.09.2022',
    club: 'FC Southampton',
    previousClub: 'Olympique Marseille',
    uid: '6f2795a6-30ef-4266-bef6-8dfdc06fd878',
    position: 'Innenverteidiger',
    league: 'Premier League',
    marketValue: 14000000,
  },
  {
    jerseyNumber: 35,
    name: 'Jan Bednarek',
    dateOfBirth: '12.04.1996',
    nationality: 'Polen',
    height: 1.89,
    foot: 'rechts',
    teamMemberSince: '01.07.2017',
    club: 'FC Southampton',
    previousClub: 'Lech Posen',
    uid: '04d7f6c1-f500-47ec-a07a-ac07d29b9726',
    position: 'Innenverteidiger',
    league: 'Premier League',
    marketValue: 14000000,
  },
  {
    jerseyNumber: 4,
    name: 'Lyanco',
    dateOfBirth: '01.02.1997',
    nationality: 'Brasilien',
    height: 1.87,
    foot: 'rechts',
    teamMemberSince: '25.08.2021',
    club: 'FC Southampton',
    previousClub: 'FC Turin',
    uid: 'ca180c5a-48a2-47f0-8c21-0d0b6aa66c98',
    position: 'Innenverteidiger',
    league: 'Premier League',
    marketValue: 4000000,
  },
  {
    jerseyNumber: 15,
    name: 'Romain Perraud',
    dateOfBirth: '22.09.1997',
    nationality: 'Frankreich',
    height: 1.73,
    foot: 'links',
    teamMemberSince: '02.07.2021',
    club: 'FC Southampton',
    previousClub: 'Stade Brest 29',
    uid: 'ae121ea9-496e-48b6-8104-ded376931732',
    position: 'Linker Verteidiger',
    league: 'Premier League',
    marketValue: 12000000,
  },
  {
    jerseyNumber: 28,
    name: 'Juan Larios',
    dateOfBirth: '12.01.2004',
    nationality: 'Spanien',
    height: 1.7,
    foot: 'links',
    teamMemberSince: '01.09.2022',
    club: 'FC Southampton',
    previousClub: 'Manchester City U21',
    uid: '228e30b2-77b0-4b7e-b890-0c3f43cbe0a9',
    position: 'Linker Verteidiger',
    league: 'Premier League',
    marketValue: 7000000,
  },
  {
    jerseyNumber: 2,
    name: 'Kyle Walker-Peters',
    dateOfBirth: '13.04.1997',
    nationality: 'England',
    height: 1.73,
    foot: 'rechts',
    teamMemberSince: '11.08.2020',
    club: 'FC Southampton',
    previousClub: 'Tottenham Hotspur',
    uid: 'a4b2f2b0-dd39-47bb-abd0-9df4a3d85458',
    position: 'Rechter Verteidiger',
    league: 'Premier League',
    marketValue: 25000000,
  },
  {
    jerseyNumber: 21,
    name: 'Tino Livramento',
    dateOfBirth: '12.11.2002',
    nationality: 'England',
    height: 1.73,
    foot: 'rechts',
    teamMemberSince: '02.08.2021',
    club: 'FC Southampton',
    previousClub: 'FC Chelsea U23',
    uid: '641ef213-71db-48c5-93b3-6ba1e180a76a',
    position: 'Rechter Verteidiger',
    league: 'Premier League',
    marketValue: 25000000,
  },
  {
    jerseyNumber: 14,
    name: 'James Bree',
    dateOfBirth: '11.12.1997',
    nationality: 'England',
    height: 1.78,
    foot: 'rechts',
    teamMemberSince: '26.01.2023',
    club: 'FC Southampton',
    previousClub: 'Luton Town',
    uid: '7fc8c1e1-1d4f-49b1-ad6d-e10e45097ff7',
    position: 'Rechter Verteidiger',
    league: 'Premier League',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 45,
    name: 'Roméo Lavia',
    dateOfBirth: '06.01.2004',
    nationality: 'Belgien',
    height: 1.81,
    foot: 'rechts',
    teamMemberSince: '06.07.2022',
    club: 'FC Southampton',
    previousClub: 'Manchester City U21',
    uid: 'e6cdb724-6c03-4ae3-b603-61747bf34b2f',
    position: 'Defensives Mittelfeld',
    league: 'Premier League',
    marketValue: 25000000,
  },
  {
    jerseyNumber: 27,
    name: 'Ibrahima Diallo',
    dateOfBirth: '08.03.1999',
    nationality: 'Frankreich',
    height: 1.79,
    foot: 'rechts',
    teamMemberSince: '04.10.2020',
    club: 'FC Southampton',
    previousClub: 'Stade Brest 29',
    uid: '47a99a9e-cf8d-4fe9-9769-8166a09baa5e',
    position: 'Defensives Mittelfeld',
    league: 'Premier League',
    marketValue: 8000000,
  },
  {
    jerseyNumber: 8,
    name: 'James Ward-Prowse',
    dateOfBirth: '01.11.1994',
    nationality: 'England',
    height: 1.77,
    foot: 'rechts',
    teamMemberSince: '01.07.2012',
    club: 'FC Southampton',
    previousClub: 'FC Southampton U18',
    uid: '2ac04861-c2ac-4d4c-bc60-dccea9b8a772',
    position: 'Zentrales Mittelfeld',
    league: 'Premier League',
    marketValue: 38000000,
  },
  {
    jerseyNumber: 26,
    name: 'Carlos Alcaraz',
    dateOfBirth: '30.11.2002',
    nationality: 'Argentinien',
    height: 1.83,
    foot: 'rechts',
    teamMemberSince: '11.01.2023',
    club: 'FC Southampton',
    previousClub: 'Racing Club',
    uid: '7841fac5-c487-4032-8578-f68816e3a440',
    position: 'Zentrales Mittelfeld',
    league: 'Premier League',
    marketValue: 12000000,
  },
  {
    jerseyNumber: 17,
    name: 'Stuart Armstrong',
    dateOfBirth: '30.03.1992',
    nationality: 'Schottland',
    height: 1.83,
    foot: 'rechts',
    teamMemberSince: '01.07.2018',
    club: 'FC Southampton',
    previousClub: 'Celtic Glasgow',
    uid: '5a1286c1-5fce-4315-b24b-ffba96f19559',
    position: 'Zentrales Mittelfeld',
    league: 'Premier League',
    marketValue: 6000000,
  },
  {
    jerseyNumber: 3,
    name: 'Ainsley Maitland-Niles',
    dateOfBirth: '29.08.1997',
    nationality: 'England',
    height: 1.8,
    foot: 'rechts',
    teamMemberSince: '01.09.2022',
    club: 'FC Southampton',
    previousClub: 'FC Arsenal',
    uid: 'bed3dcc6-907c-4fb2-9181-0e028fb6bbfa',
    position: 'Rechtes Mittelfeld',
    league: 'Premier League',
    marketValue: 8000000,
  },
  {
    jerseyNumber: 7,
    name: 'Joe Aribo',
    dateOfBirth: '21.07.1996',
    nationality: 'Nigeria',
    height: 1.83,
    foot: 'links',
    teamMemberSince: '09.07.2022',
    club: 'FC Southampton',
    previousClub: 'Glasgow Rangers',
    uid: '8728dfea-de23-4a73-9283-8b0c5123eb12',
    position: 'Offensives Mittelfeld',
    league: 'Premier League',
    marketValue: 15000000,
  },
  {
    jerseyNumber: 20,
    name: 'Kamaldeen Sulemana',
    dateOfBirth: '15.02.2002',
    nationality: 'Ghana',
    height: 1.75,
    foot: 'rechts',
    teamMemberSince: '31.01.2023',
    club: 'FC Southampton',
    previousClub: 'FC Stade Rennes',
    uid: 'd8a90a9f-f969-41ad-a937-ec436afb5906',
    position: 'Linksaußen',
    league: 'Premier League',
    marketValue: 22000000,
  },
  {
    jerseyNumber: 24,
    name: 'Mohamed Elyounoussi',
    dateOfBirth: '04.08.1994',
    nationality: 'Norwegen',
    height: 1.78,
    foot: 'rechts',
    teamMemberSince: '01.07.2018',
    club: 'FC Southampton',
    previousClub: ': Ablöse 18,00 Mio. €',
    uid: '3691a802-6060-4195-a2bb-656b5d0cbefa',
    position: 'Linksaußen',
    league: 'Premier League',
    marketValue: 12000000,
  },
  {
    jerseyNumber: 19,
    name: 'Moussa Djenepo',
    dateOfBirth: '15.06.1998',
    nationality: 'Mali',
    height: 1.77,
    foot: 'rechts',
    teamMemberSince: '01.07.2019',
    club: 'FC Southampton',
    previousClub: 'Standard Lüttich',
    uid: '4558650a-6835-46f0-b79e-71bae4655c01',
    position: 'Linksaußen',
    league: 'Premier League',
    marketValue: 10000000,
  },
  {
    jerseyNumber: 11,
    name: 'Mislav Orsic',
    dateOfBirth: '29.12.1992',
    nationality: 'Kroatien',
    height: 1.79,
    foot: 'rechts',
    teamMemberSince: '06.01.2023',
    club: 'FC Southampton',
    previousClub: 'GNK Dinamo Zagreb',
    uid: '9ef72810-4878-4185-8556-c13d10950873',
    position: 'Linksaußen',
    league: 'Premier League',
    marketValue: 7000000,
  },
  {
    jerseyNumber: 23,
    name: 'Samuel Edozie',
    dateOfBirth: '28.01.2003',
    nationality: 'England',
    height: 1.81,
    foot: 'rechts',
    teamMemberSince: '01.09.2022',
    club: 'FC Southampton',
    previousClub: 'Manchester City U21',
    uid: 'f859f755-4cad-401b-b8e7-4b2885e09dda',
    position: 'Linksaußen',
    league: 'Premier League',
    marketValue: 7000000,
  },
  {
    jerseyNumber: 32,
    name: 'Theo Walcott',
    dateOfBirth: '16.03.1989',
    nationality: 'England',
    height: 1.75,
    foot: 'beidfüßig',
    teamMemberSince: '01.07.2021',
    club: 'FC Southampton',
    previousClub: 'FC Everton',
    uid: '15d6f907-26f5-4f61-baba-601b61da25a9',
    position: 'Rechtsaußen',
    league: 'Premier League',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 10,
    name: 'Ché Adams',
    dateOfBirth: '13.07.1996',
    nationality: 'Schottland',
    height: 1.79,
    foot: 'rechts',
    teamMemberSince: '01.07.2019',
    club: 'FC Southampton',
    previousClub: 'Birmingham City',
    uid: '9bf3aec5-4335-4397-be90-c94a11718b0d',
    position: 'Mittelstürmer',
    league: 'Premier League',
    marketValue: 22000000,
  },
  {
    jerseyNumber: 12,
    name: 'Paul Onuachu',
    dateOfBirth: '28.05.1994',
    nationality: 'Nigeria',
    height: 2.01,
    foot: 'rechts',
    teamMemberSince: '31.01.2023',
    club: 'FC Southampton',
    previousClub: 'KRC Genk',
    uid: '537bbb02-ec5d-433e-9000-336053898ef2',
    position: 'Mittelstürmer',
    league: 'Premier League',
    marketValue: 17000000,
  },
  {
    jerseyNumber: 9,
    name: 'Adam Armstrong',
    dateOfBirth: '10.02.1997',
    nationality: 'England',
    height: 1.73,
    foot: 'rechts',
    teamMemberSince: '10.08.2021',
    club: 'FC Southampton',
    previousClub: 'Blackburn Rovers',
    uid: 'df6c0e63-05c8-4dc1-a51e-20824317e462',
    position: 'Mittelstürmer',
    league: 'Premier League',
    marketValue: 12000000,
  },
  {
    jerseyNumber: 18,
    name: 'Sékou Mara',
    dateOfBirth: '30.07.2002',
    nationality: 'Frankreich',
    height: 1.83,
    foot: 'rechts',
    teamMemberSince: '25.07.2022',
    club: 'FC Southampton',
    previousClub: 'FC Girondins Bordeaux',
    uid: '5ada20aa-7af9-4397-b246-1e95e59619fd',
    position: 'Mittelstürmer',
    league: 'Premier League',
    marketValue: 12000000,
  },
  {
    jerseyNumber: 1,
    name: 'Robert Sánchez',
    dateOfBirth: '18.11.1997',
    nationality: 'Spanien',
    height: 1.97,
    foot: 'rechts',
    teamMemberSince: '01.07.2020',
    club: 'Brighton & Hove Albion',
    previousClub: 'Brighton & Hove Albion U23',
    uid: '548b0b84-6013-4966-bcff-5adff7b319b5',
    position: 'Torwart',
    league: 'Premier League',
    marketValue: 32000000,
  },
  {
    jerseyNumber: 23,
    name: 'Jason Steele',
    dateOfBirth: '18.08.1990',
    nationality: 'England',
    height: 1.88,
    foot: 'rechts',
    teamMemberSince: '01.07.2018',
    club: 'Brighton & Hove Albion',
    previousClub: 'AFC Sunderland',
    uid: '7e74b0fd-5e57-4546-b95b-f273e0c71929',
    position: 'Torwart',
    league: 'Premier League',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 38,
    name: 'Tom McGill',
    dateOfBirth: '25.03.2000',
    nationality: 'Kanada',
    height: 1.85,
    foot: 'rechts',
    teamMemberSince: '29.07.2022',
    club: 'Brighton & Hove Albion',
    previousClub: 'Brighton & Hove Albion U21',
    uid: 'abebaedb-46c1-4657-b4eb-bf475ca116fb',
    position: 'Torwart',
    league: 'Premier League',
    marketValue: 200000,
  },
  {
    jerseyNumber: 4,
    name: 'Adam Webster',
    dateOfBirth: '04.01.1995',
    nationality: 'England',
    height: 1.9,
    foot: 'rechts',
    teamMemberSince: '03.08.2019',
    club: 'Brighton & Hove Albion',
    previousClub: 'Bristol City',
    uid: 'f07b07fc-779e-476c-abdd-d63480935dc1',
    position: 'Innenverteidiger',
    league: 'Premier League',
    marketValue: 22000000,
  },
  {
    jerseyNumber: 5,
    name: 'Lewis Dunk',
    dateOfBirth: '21.11.1991',
    nationality: 'England',
    height: 1.92,
    foot: 'rechts',
    teamMemberSince: '01.07.2010',
    club: 'Brighton & Hove Albion',
    previousClub: ': Ablöse -',
    uid: '36a7ea55-be9d-4f86-a8e0-631f18c204d3',
    position: 'Innenverteidiger',
    league: 'Premier League',
    marketValue: 18000000,
  },
  {
    jerseyNumber: 6,
    name: 'Levi Colwill',
    dateOfBirth: '26.02.2003',
    nationality: 'England',
    height: 1.87,
    foot: 'links',
    teamMemberSince: '05.08.2022',
    club: 'Brighton & Hove Albion',
    previousClub: 'FC Chelsea U21',
    uid: 'd3ddeef5-14d0-4350-8e6b-5f285b4f9736',
    position: 'Innenverteidiger',
    league: 'Premier League',
    marketValue: 16000000,
  },
  {
    jerseyNumber: 29,
    name: 'Jan Paul van Hecke',
    dateOfBirth: '08.06.2000',
    nationality: 'Niederlande',
    height: 1.89,
    foot: 'rechts',
    teamMemberSince: '10.09.2020',
    club: 'Brighton & Hove Albion',
    previousClub: 'NAC Breda',
    uid: 'ea5419e3-6ea2-4ceb-be06-2160ac41c09f',
    position: 'Innenverteidiger',
    league: 'Premier League',
    marketValue: 4000000,
  },
  {
    jerseyNumber: 30,
    name: 'Pervis Estupiñán',
    dateOfBirth: '21.01.1998',
    nationality: 'Ecuador',
    height: 1.75,
    foot: 'links',
    teamMemberSince: '16.08.2022',
    club: 'Brighton & Hove Albion',
    previousClub: 'FC Villarreal',
    uid: 'f7f7c133-dfa1-43e5-a58d-abeabc75aac1',
    position: 'Linker Verteidiger',
    league: 'Premier League',
    marketValue: 20000000,
  },
  {
    jerseyNumber: 2,
    name: 'Tariq Lamptey',
    dateOfBirth: '30.09.2000',
    nationality: 'Ghana',
    height: 1.63,
    foot: 'rechts',
    teamMemberSince: '31.01.2020',
    club: 'Brighton & Hove Albion',
    previousClub: 'FC Chelsea U23',
    uid: 'e3244b1b-db56-410e-ac89-80d5d8633229',
    position: 'Rechter Verteidiger',
    league: 'Premier League',
    marketValue: 15000000,
  },
  {
    jerseyNumber: 34,
    name: 'Joël Veltman',
    dateOfBirth: '15.01.1992',
    nationality: 'Niederlande',
    height: 1.84,
    foot: 'rechts',
    teamMemberSince: '29.07.2020',
    club: 'Brighton & Hove Albion',
    previousClub: 'Ajax Amsterdam',
    uid: '15755295-326c-4cac-bbea-9eb95126c966',
    position: 'Rechter Verteidiger',
    league: 'Premier League',
    marketValue: 10000000,
  },
  {
    jerseyNumber: 25,
    name: 'Moisés Caicedo',
    dateOfBirth: '02.11.2001',
    nationality: 'Ecuador',
    height: 1.78,
    foot: 'rechts',
    teamMemberSince: '01.02.2021',
    club: 'Brighton & Hove Albion',
    previousClub: ': Ablöse 5,00 Mio. €',
    uid: '413dfd0e-f329-43e8-9e00-4ca99d943e0e',
    position: 'Defensives Mittelfeld',
    league: 'Premier League',
    marketValue: 55000000,
  },
  {
    jerseyNumber: 10,
    name: 'Alexis Mac Allister',
    dateOfBirth: '24.12.1998',
    nationality: 'Argentinien',
    height: 1.74,
    foot: 'rechts',
    teamMemberSince: '24.01.2019',
    club: 'Brighton & Hove Albion',
    previousClub: 'Argentinos Juniors',
    uid: '90e0bb13-332d-44e9-8148-c4252c840719',
    position: 'Zentrales Mittelfeld',
    league: 'Premier League',
    marketValue: 42000000,
  },
  {
    jerseyNumber: 15,
    name: 'Jakub Moder',
    dateOfBirth: '07.04.1999',
    nationality: 'Polen',
    height: 1.91,
    foot: 'rechts',
    teamMemberSince: '04.10.2020',
    club: 'Brighton & Hove Albion',
    previousClub: 'Lech Posen',
    uid: 'cdd1e598-2ad8-4fa0-b529-295aac8abf00',
    position: 'Zentrales Mittelfeld',
    league: 'Premier League',
    marketValue: 12000000,
  },
  {
    jerseyNumber: 27,
    name: 'Billy Gilmour',
    dateOfBirth: '11.06.2001',
    nationality: 'Schottland',
    height: 1.7,
    foot: 'rechts',
    teamMemberSince: '01.09.2022',
    club: 'Brighton & Hove Albion',
    previousClub: 'FC Chelsea',
    uid: 'fa7cdb2d-fb84-41f4-a440-6d4ec20479f7',
    position: 'Zentrales Mittelfeld',
    league: 'Premier League',
    marketValue: 9000000,
  },
  {
    jerseyNumber: 13,
    name: 'Pascal Groß',
    dateOfBirth: '15.06.1991',
    nationality: 'Deutschland',
    height: 1.81,
    foot: 'rechts',
    teamMemberSince: '01.07.2017',
    club: 'Brighton & Hove Albion',
    previousClub: 'FC Ingolstadt 04',
    uid: '9d654ec1-317a-43dc-846d-d81e5c44143b',
    position: 'Zentrales Mittelfeld',
    league: 'Premier League',
    marketValue: 8000000,
  },
  {
    jerseyNumber: 26,
    name: 'Yasin Ayari',
    dateOfBirth: '06.10.2003',
    nationality: 'Schweden',
    height: 1.72,
    foot: 'rechts',
    teamMemberSince: '30.01.2023',
    club: 'Brighton & Hove Albion',
    previousClub: 'AIK Solna',
    uid: '23796edb-b719-4c68-86ae-88e20e6a5355',
    position: 'Zentrales Mittelfeld',
    league: 'Premier League',
    marketValue: 4000000,
  },
  {
    jerseyNumber: 40,
    name: 'Facundo Buonanotte',
    dateOfBirth: '23.12.2004',
    nationality: 'Argentinien',
    height: 1.74,
    foot: 'links',
    teamMemberSince: '01.01.2023',
    club: 'Brighton & Hove Albion',
    previousClub: 'CA Rosario Central',
    uid: 'db038b9e-5695-4942-95d5-9172a8f517e1',
    position: 'Offensives Mittelfeld',
    league: 'Premier League',
    marketValue: 9000000,
  },
  {
    jerseyNumber: 19,
    name: 'Jeremy Sarmiento',
    dateOfBirth: '16.06.2002',
    nationality: 'Ecuador',
    height: 1.78,
    foot: 'rechts',
    teamMemberSince: '02.07.2021',
    club: 'Brighton & Hove Albion',
    previousClub: 'Benfica Lissabon U19',
    uid: 'a3ecbbb1-0d55-49e7-814b-4515b5a03547',
    position: 'Offensives Mittelfeld',
    league: 'Premier League',
    marketValue: 5000000,
  },
  {
    jerseyNumber: 14,
    name: 'Adam Lallana',
    dateOfBirth: '10.05.1988',
    nationality: 'England',
    height: 1.72,
    foot: 'beidfüßig',
    teamMemberSince: '27.07.2020',
    club: 'Brighton & Hove Albion',
    previousClub: 'FC Liverpool',
    uid: '5a8543ad-3044-4e7e-b79c-6559d5d261db',
    position: 'Offensives Mittelfeld',
    league: 'Premier League',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 22,
    name: 'Kaoru Mitoma',
    dateOfBirth: '20.05.1997',
    nationality: 'Japan',
    height: 1.78,
    foot: 'rechts',
    teamMemberSince: '10.08.2021',
    club: 'Brighton & Hove Albion',
    previousClub: 'Kawasaki Frontale',
    uid: '5849e25b-23de-4c37-bbcf-0c6e0b208f94',
    position: 'Linksaußen',
    league: 'Premier League',
    marketValue: 22000000,
  },
  {
    jerseyNumber: 7,
    name: 'Solly March',
    dateOfBirth: '20.07.1994',
    nationality: 'England',
    height: 1.8,
    foot: 'links',
    teamMemberSince: '01.07.2013',
    club: 'Brighton & Hove Albion',
    previousClub: 'Brighton & Hove Albion U18',
    uid: '455df6c3-e705-48fe-b3c4-db04b7dc37fe',
    position: 'Rechtsaußen',
    league: 'Premier League',
    marketValue: 18000000,
  },
  {
    jerseyNumber: 20,
    name: 'Julio Enciso',
    dateOfBirth: '23.01.2004',
    nationality: 'Paraguay',
    height: 1.73,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'Brighton & Hove Albion',
    previousClub: 'Club Libertad Asunción',
    uid: 'dcaed503-55b2-452c-8b6e-0ac8a9eef35b',
    position: 'Mittelstürmer',
    league: 'Premier League',
    marketValue: 11000000,
  },
  {
    jerseyNumber: 28,
    name: 'Evan Ferguson',
    dateOfBirth: '19.10.2004',
    nationality: 'Irland',
    height: 1.83,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'Brighton & Hove Albion',
    previousClub: 'Brighton & Hove Albion U21',
    uid: '0665c055-f146-45ca-90d9-b2960db1ac7f',
    position: 'Mittelstürmer',
    league: 'Premier League',
    marketValue: 10000000,
  },
  {
    jerseyNumber: 18,
    name: 'Danny Welbeck',
    dateOfBirth: '26.11.1990',
    nationality: 'England',
    height: 1.85,
    foot: 'rechts',
    teamMemberSince: '18.10.2020',
    club: 'Brighton & Hove Albion',
    previousClub: 'FC Watford',
    uid: '9ebfafbd-f7d4-4d90-9978-68724f31c0f9',
    position: 'Mittelstürmer',
    league: 'Premier League',
    marketValue: 8000000,
  },
  {
    jerseyNumber: 21,
    name: 'Deniz Undav',
    dateOfBirth: '19.07.1996',
    nationality: 'Deutschland',
    height: 1.78,
    foot: 'rechts',
    teamMemberSince: '30.01.2022',
    club: 'Brighton & Hove Albion',
    previousClub: 'Royale Union Saint Gilloise',
    uid: '44626d25-d56d-4157-ae66-884db9e06311',
    position: 'Mittelstürmer',
    league: 'Premier League',
    marketValue: 6000000,
  },
  {
    jerseyNumber: 1,
    name: 'Jordan Pickford',
    dateOfBirth: '07.03.1994',
    nationality: 'England',
    height: 1.85,
    foot: 'links',
    teamMemberSince: '01.07.2017',
    club: 'FC Everton',
    previousClub: 'AFC Sunderland',
    uid: 'cfca6831-5fea-407b-a259-f40106229ee9',
    position: 'Torwart',
    league: 'Premier League',
    marketValue: 25000000,
  },
  {
    jerseyNumber: 15,
    name: 'Asmir Begovic',
    dateOfBirth: '20.06.1987',
    nationality: 'Bosnien-Herzegowina',
    height: 1.99,
    foot: 'rechts',
    teamMemberSince: '20.07.2021',
    club: 'FC Everton',
    previousClub: 'AFC Bournemouth',
    uid: '138838b1-d6ee-4044-8d31-fe1362182c3a',
    position: 'Torwart',
    league: 'Premier League',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 31,
    name: 'Andy Lonergan',
    dateOfBirth: '19.10.1983',
    nationality: 'England',
    height: 1.93,
    foot: 'links',
    teamMemberSince: '20.08.2021',
    club: 'FC Everton',
    previousClub: 'West Bromwich Albion',
    uid: 'ad5fa3fa-92ca-4356-85c8-087a5de3530b',
    position: 'Torwart',
    league: 'Premier League',
    marketValue: 200000,
  },
  {
    jerseyNumber: 2,
    name: 'James Tarkowski',
    dateOfBirth: '19.11.1992',
    nationality: 'England',
    height: 1.85,
    foot: 'rechts',
    teamMemberSince: '02.07.2022',
    club: 'FC Everton',
    previousClub: 'FC Burnley',
    uid: '00fe87e0-c611-4251-a379-69607bce2ba0',
    position: 'Innenverteidiger',
    league: 'Premier League',
    marketValue: 22000000,
  },
  {
    jerseyNumber: 30,
    name: 'Conor Coady',
    dateOfBirth: '25.02.1993',
    nationality: 'England',
    height: 1.85,
    foot: 'rechts',
    teamMemberSince: '08.08.2022',
    club: 'FC Everton',
    previousClub: 'Wolverhampton Wanderers',
    uid: '5a0fb6f4-cdf8-4e9e-8b35-ba2ed0f74ca7',
    position: 'Innenverteidiger',
    league: 'Premier League',
    marketValue: 18000000,
  },
  {
    jerseyNumber: 22,
    name: 'Ben Godfrey',
    dateOfBirth: '15.01.1998',
    nationality: 'England',
    height: 1.84,
    foot: 'rechts',
    teamMemberSince: '05.10.2020',
    club: 'FC Everton',
    previousClub: ': Ablöse 27,50 Mio. €',
    uid: 'ccc53d47-bf11-4436-8080-9dfc9e32fa48',
    position: 'Innenverteidiger',
    league: 'Premier League',
    marketValue: 15000000,
  },
  {
    jerseyNumber: 4,
    name: 'Mason Holgate',
    dateOfBirth: '22.10.1996',
    nationality: 'England',
    height: 1.87,
    foot: 'rechts',
    teamMemberSince: '13.08.2015',
    club: 'FC Everton',
    previousClub: 'FC Barnsley',
    uid: '8ff2d070-dece-4268-861c-71e03b1c8591',
    position: 'Innenverteidiger',
    league: 'Premier League',
    marketValue: 12000000,
  },
  {
    jerseyNumber: 13,
    name: 'Yerry Mina',
    dateOfBirth: '23.09.1994',
    nationality: 'Kolumbien',
    height: 1.95,
    foot: 'rechts',
    teamMemberSince: '09.08.2018',
    club: 'FC Everton',
    previousClub: 'FC Barcelona',
    uid: 'a3c1f660-b5f2-47c7-b174-9d4e35f16bc7',
    position: 'Innenverteidiger',
    league: 'Premier League',
    marketValue: 12000000,
  },
  {
    jerseyNumber: 5,
    name: 'Michael Keane',
    dateOfBirth: '11.01.1993',
    nationality: 'England',
    height: 1.88,
    foot: 'rechts',
    teamMemberSince: '03.07.2017',
    club: 'FC Everton',
    previousClub: 'FC Burnley',
    uid: '66dc64d2-3376-497b-868d-7c1686ebbab5',
    position: 'Innenverteidiger',
    league: 'Premier League',
    marketValue: 10000000,
  },
  {
    jerseyNumber: 19,
    name: 'Vitaliy Mykolenko',
    dateOfBirth: '29.05.1999',
    nationality: 'Ukraine',
    height: 1.8,
    foot: 'links',
    teamMemberSince: '01.01.2022',
    club: 'FC Everton',
    previousClub: 'Dynamo Kiew',
    uid: '6d028c38-d5f3-46ad-b0cf-fef6bb4bc726',
    position: 'Linker Verteidiger',
    league: 'Premier League',
    marketValue: 22000000,
  },
  {
    jerseyNumber: 29,
    name: 'Rúben Vinagre',
    dateOfBirth: '09.04.1999',
    nationality: 'Portugal',
    height: 1.74,
    foot: 'links',
    teamMemberSince: '27.07.2022',
    club: 'FC Everton',
    previousClub: 'Sporting Lissabon',
    uid: 'c2324047-a9c8-4ac7-962c-502a5fc83c16',
    position: 'Linker Verteidiger',
    league: 'Premier League',
    marketValue: 5000000,
  },
  {
    jerseyNumber: 3,
    name: 'Nathan Patterson',
    dateOfBirth: '16.10.2001',
    nationality: 'Schottland',
    height: 1.89,
    foot: 'rechts',
    teamMemberSince: '04.01.2022',
    club: 'FC Everton',
    previousClub: ': Ablöse 14,00 Mio. €',
    uid: '0afdeb06-7b9b-4dba-a20a-c49f429c124a',
    position: 'Rechter Verteidiger',
    league: 'Premier League',
    marketValue: 14000000,
  },
  {
    jerseyNumber: 23,
    name: 'Seamus Coleman',
    dateOfBirth: '11.10.1988',
    nationality: 'Irland',
    height: 1.77,
    foot: 'rechts',
    teamMemberSince: '01.01.2009',
    club: 'FC Everton',
    previousClub: 'Sligo Rovers',
    uid: '59369c1c-7620-441b-b846-a55a2bb48b44',
    position: 'Rechter Verteidiger',
    league: 'Premier League',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 8,
    name: 'Amadou Onana',
    dateOfBirth: '16.08.2001',
    nationality: 'Belgien',
    height: 1.95,
    foot: 'rechts',
    teamMemberSince: '09.08.2022',
    club: 'FC Everton',
    previousClub: 'LOSC Lille',
    uid: 'f6f35dc3-b3a2-40ba-9948-6d431945b749',
    position: 'Defensives Mittelfeld',
    league: 'Premier League',
    marketValue: 42000000,
  },
  {
    jerseyNumber: 37,
    name: 'James Garner',
    dateOfBirth: '13.03.2001',
    nationality: 'England',
    height: 1.82,
    foot: 'rechts',
    teamMemberSince: '01.09.2022',
    club: 'FC Everton',
    previousClub: 'Manchester United U21',
    uid: '0f5bb863-ab7a-4ee1-863b-db65ffc76180',
    position: 'Defensives Mittelfeld',
    league: 'Premier League',
    marketValue: 14000000,
  },
  {
    jerseyNumber: 16,
    name: 'Abdoulaye Doucouré',
    dateOfBirth: '01.01.1993',
    nationality: 'Mali',
    height: 1.83,
    foot: 'rechts',
    teamMemberSince: '08.09.2020',
    club: 'FC Everton',
    previousClub: 'FC Watford',
    uid: '13259c52-5beb-4851-b8dc-b050581a94e7',
    position: 'Zentrales Mittelfeld',
    league: 'Premier League',
    marketValue: 12000000,
  },
  {
    jerseyNumber: 26,
    name: 'Tom Davies',
    dateOfBirth: '30.06.1998',
    nationality: 'England',
    height: 1.8,
    foot: 'rechts',
    teamMemberSince: '01.01.2017',
    club: 'FC Everton',
    previousClub: 'FC Everton U23',
    uid: '9dca5ba5-1849-440a-8aed-d1273c12ee8a',
    position: 'Zentrales Mittelfeld',
    league: 'Premier League',
    marketValue: 12000000,
  },
  {
    jerseyNumber: 27,
    name: 'Idrissa Gueye',
    dateOfBirth: '26.09.1989',
    nationality: 'Senegal',
    height: 1.74,
    foot: 'rechts',
    teamMemberSince: '01.09.2022',
    club: 'FC Everton',
    previousClub: 'FC Paris Saint-Germain',
    uid: 'f3726d34-bf9f-4649-a40b-58218b653242',
    position: 'Zentrales Mittelfeld',
    league: 'Premier League',
    marketValue: 8000000,
  },
  {
    jerseyNumber: 17,
    name: 'Alex Iwobi',
    dateOfBirth: '03.05.1996',
    nationality: 'Nigeria',
    height: 1.83,
    foot: 'rechts',
    teamMemberSince: '08.08.2019',
    club: 'FC Everton',
    previousClub: 'FC Arsenal',
    uid: '03b92912-63e6-4044-bb96-a73f2d36d1cc',
    position: 'Offensives Mittelfeld',
    league: 'Premier League',
    marketValue: 25000000,
  },
  {
    jerseyNumber: 11,
    name: 'Demarai Gray',
    dateOfBirth: '28.06.1996',
    nationality: 'England',
    height: 1.83,
    foot: 'rechts',
    teamMemberSince: '22.07.2021',
    club: 'FC Everton',
    previousClub: 'Bayer 04 Leverkusen',
    uid: '6b2bcf2a-027c-49ae-9077-28e341ca74fc',
    position: 'Linksaußen',
    league: 'Premier League',
    marketValue: 20000000,
  },
  {
    jerseyNumber: 7,
    name: 'Dwight McNeil',
    dateOfBirth: '22.11.1999',
    nationality: 'England',
    height: 1.83,
    foot: 'links',
    teamMemberSince: '28.07.2022',
    club: 'FC Everton',
    previousClub: 'FC Burnley',
    uid: '5db49455-d9c4-41c7-9a1c-29ea3d80a1cc',
    position: 'Linksaußen',
    league: 'Premier League',
    marketValue: 18000000,
  },
  {
    jerseyNumber: 14,
    name: 'Andros Townsend',
    dateOfBirth: '16.07.1991',
    nationality: 'England',
    height: 1.81,
    foot: 'links',
    teamMemberSince: '20.07.2021',
    club: 'FC Everton',
    previousClub: 'Crystal Palace',
    uid: '97dae2b9-2e1a-4547-9eea-edc65835ca07',
    position: 'Rechtsaußen',
    league: 'Premier League',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 9,
    name: 'Dominic Calvert-Lewin',
    dateOfBirth: '16.03.1997',
    nationality: 'England',
    height: 1.89,
    foot: 'rechts',
    teamMemberSince: '31.08.2016',
    club: 'FC Everton',
    previousClub: 'Sheffield United',
    uid: 'aae0b82e-585a-420c-9c86-752b5ea9c6b2',
    position: 'Mittelstürmer',
    league: 'Premier League',
    marketValue: 25000000,
  },
  {
    jerseyNumber: 20,
    name: 'Neal Maupay',
    dateOfBirth: '14.08.1996',
    nationality: 'Frankreich',
    height: 1.73,
    foot: 'rechts',
    teamMemberSince: '26.08.2022',
    club: 'FC Everton',
    previousClub: 'Brighton & Hove Albion',
    uid: '28e1a027-a629-4b7c-bf40-ddf132812da9',
    position: 'Mittelstürmer',
    league: 'Premier League',
    marketValue: 13000000,
  },
  {
    jerseyNumber: 50,
    name: 'Ellis Simms',
    dateOfBirth: '05.01.2001',
    nationality: 'England',
    height: 1.91,
    foot: 'rechts',
    teamMemberSince: '01.01.2023',
    club: 'FC Everton',
    previousClub: 'FC Everton U21',
    uid: 'bd0062cb-8436-474f-9b7a-5240c96fca0a',
    position: 'Mittelstürmer',
    league: 'Premier League',
    marketValue: 4000000,
  },
  {
    jerseyNumber: 1,
    name: 'Dean Henderson',
    dateOfBirth: '12.03.1997',
    nationality: 'England',
    height: 1.88,
    foot: 'rechts',
    teamMemberSince: '02.07.2022',
    club: 'Nottingham Forest',
    previousClub: 'Manchester United',
    uid: '039c6be0-b11f-4644-9b70-9ced7b4e65a8',
    position: 'Torwart',
    league: 'Premier League',
    marketValue: 22000000,
  },
  {
    jerseyNumber: 12,
    name: 'Keylor Navas',
    dateOfBirth: '15.12.1986',
    nationality: 'Costa Rica',
    height: 1.85,
    foot: 'rechts',
    teamMemberSince: '31.01.2023',
    club: 'Nottingham Forest',
    previousClub: 'FC Paris Saint-Germain',
    uid: '81476b3f-2d42-43b2-b48c-98eb9e857438',
    position: 'Torwart',
    league: 'Premier League',
    marketValue: 5000000,
  },
  {
    jerseyNumber: 13,
    name: 'Wayne Hennessey',
    dateOfBirth: '24.01.1987',
    nationality: 'Wales',
    height: 1.98,
    foot: 'rechts',
    teamMemberSince: '15.07.2022',
    club: 'Nottingham Forest',
    previousClub: 'FC Burnley',
    uid: '3399afc5-3047-470d-8cdf-1f984303e239',
    position: 'Torwart',
    league: 'Premier League',
    marketValue: 500000,
  },
  {
    jerseyNumber: 19,
    name: 'Moussa Niakhaté',
    dateOfBirth: '08.03.1996',
    nationality: 'Senegal',
    height: 1.9,
    foot: 'links',
    teamMemberSince: '06.07.2022',
    club: 'Nottingham Forest',
    previousClub: '1.FSV Mainz 05',
    uid: '236c96e7-afa4-4e25-a8ea-8d6fd24f8dcc',
    position: 'Innenverteidiger',
    league: 'Premier League',
    marketValue: 18000000,
  },
  {
    jerseyNumber: 26,
    name: 'Scott McKenna',
    dateOfBirth: '12.11.1996',
    nationality: 'Schottland',
    height: 1.89,
    foot: 'links',
    teamMemberSince: '23.09.2020',
    club: 'Nottingham Forest',
    previousClub: 'Aberdeen FC',
    uid: '0c308566-7175-40e2-bcc7-31c32b17f6a4',
    position: 'Innenverteidiger',
    league: 'Premier League',
    marketValue: 10000000,
  },
  {
    jerseyNumber: 4,
    name: 'Joe Worrall',
    dateOfBirth: '10.01.1997',
    nationality: 'England',
    height: 1.9,
    foot: 'rechts',
    teamMemberSince: '01.07.2015',
    club: 'Nottingham Forest',
    previousClub: 'Nottingham Forest U18',
    uid: 'e1069354-6f80-471c-b88a-e6f9ca9fbc5b',
    position: 'Innenverteidiger',
    league: 'Premier League',
    marketValue: 8000000,
  },
  {
    jerseyNumber: 30,
    name: 'Willy Boly',
    dateOfBirth: '03.02.1991',
    nationality: 'Elfenbeinküste',
    height: 1.95,
    foot: 'rechts',
    teamMemberSince: '01.09.2022',
    club: 'Nottingham Forest',
    previousClub: 'Wolverhampton Wanderers',
    uid: '7b8166f3-ea6f-45a8-8c4d-8469fccd8662',
    position: 'Innenverteidiger',
    league: 'Premier League',
    marketValue: 4000000,
  },
  {
    jerseyNumber: 3,
    name: 'Steve Cook',
    dateOfBirth: '19.04.1991',
    nationality: 'England',
    height: 1.85,
    foot: 'rechts',
    teamMemberSince: '04.01.2022',
    club: 'Nottingham Forest',
    previousClub: 'AFC Bournemouth',
    uid: '009093fb-9728-4853-b391-f333ee3e5169',
    position: 'Innenverteidiger',
    league: 'Premier League',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 38,
    name: 'Felipe',
    dateOfBirth: '16.05.1989',
    nationality: 'Brasilien',
    height: 1.9,
    foot: 'rechts',
    teamMemberSince: '31.01.2023',
    club: 'Nottingham Forest',
    previousClub: 'Atlético Madrid',
    uid: '5743a3d8-ee03-48fd-acdd-426d4e1909ac',
    position: 'Innenverteidiger',
    league: 'Premier League',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 32,
    name: 'Renan Lodi',
    dateOfBirth: '08.04.1998',
    nationality: 'Brasilien',
    height: 1.73,
    foot: 'links',
    teamMemberSince: '29.08.2022',
    club: 'Nottingham Forest',
    previousClub: 'Atlético Madrid',
    uid: '96be5901-b9c0-4da3-b809-3c32eab470c5',
    position: 'Linker Verteidiger',
    league: 'Premier League',
    marketValue: 20000000,
  },
  {
    jerseyNumber: 27,
    name: 'Omar Richards',
    dateOfBirth: '15.02.1998',
    nationality: 'England',
    height: 1.74,
    foot: 'links',
    teamMemberSince: '10.07.2022',
    club: 'Nottingham Forest',
    previousClub: 'FC Bayern München',
    uid: 'baa43a41-7007-4577-bd6f-5cac15c5e43f',
    position: 'Linker Verteidiger',
    league: 'Premier League',
    marketValue: 7000000,
  },
  {
    jerseyNumber: 15,
    name: 'Harry Toffolo',
    dateOfBirth: '19.08.1995',
    nationality: 'England',
    height: 1.83,
    foot: 'links',
    teamMemberSince: '20.07.2022',
    club: 'Nottingham Forest',
    previousClub: 'Huddersfield Town',
    uid: '38abeaad-3a16-4bc0-ba43-533a9e37735c',
    position: 'Linker Verteidiger',
    league: 'Premier League',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 7,
    name: 'Neco Williams',
    dateOfBirth: '13.04.2001',
    nationality: 'Wales',
    height: 1.83,
    foot: 'rechts',
    teamMemberSince: '10.07.2022',
    club: 'Nottingham Forest',
    previousClub: 'FC Liverpool',
    uid: '5d9d546d-d421-4db1-80bf-7f65369b40fe',
    position: 'Rechter Verteidiger',
    league: 'Premier League',
    marketValue: 18000000,
  },
  {
    jerseyNumber: 2,
    name: 'Giulian Biancone',
    dateOfBirth: '31.03.2000',
    nationality: 'Frankreich',
    height: 1.87,
    foot: 'rechts',
    teamMemberSince: '03.07.2022',
    club: 'Nottingham Forest',
    previousClub: 'ESTAC Troyes',
    uid: '5eefe5dd-bbed-4672-b979-de801e88e430',
    position: 'Rechter Verteidiger',
    league: 'Premier League',
    marketValue: 7000000,
  },
  {
    jerseyNumber: 24,
    name: 'Serge Aurier',
    dateOfBirth: '24.12.1992',
    nationality: 'Elfenbeinküste',
    height: 1.76,
    foot: 'rechts',
    teamMemberSince: '07.09.2022',
    club: 'Nottingham Forest',
    previousClub: 'Vereinslos',
    uid: '9ef75127-70d9-42f6-88f9-685280e01c48',
    position: 'Rechter Verteidiger',
    league: 'Premier League',
    marketValue: 6000000,
  },
  {
    jerseyNumber: 28,
    name: 'Danilo',
    dateOfBirth: '29.04.2001',
    nationality: 'Brasilien',
    height: 1.77,
    foot: 'links',
    teamMemberSince: '16.01.2023',
    club: 'Nottingham Forest',
    previousClub: 'SE Palmeiras São Paulo',
    uid: 'ba863398-fda1-458d-b633-2d26444c9cef',
    position: 'Defensives Mittelfeld',
    league: 'Premier League',
    marketValue: 25000000,
  },
  {
    jerseyNumber: 21,
    name: 'Cheikhou Kouyaté',
    dateOfBirth: '21.12.1989',
    nationality: 'Senegal',
    height: 1.89,
    foot: 'rechts',
    teamMemberSince: '13.08.2022',
    club: 'Nottingham Forest',
    previousClub: 'Crystal Palace',
    uid: 'd5ac78d4-baac-4a26-aeb8-c62bc936cec0',
    position: 'Defensives Mittelfeld',
    league: 'Premier League',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 8,
    name: 'Jack Colback',
    dateOfBirth: '24.10.1989',
    nationality: 'England',
    height: 1.77,
    foot: 'links',
    teamMemberSince: '11.08.2020',
    club: 'Nottingham Forest',
    previousClub: 'Newcastle United',
    uid: '9599044d-ee54-48f3-93be-4762c7293f98',
    position: 'Defensives Mittelfeld',
    league: 'Premier League',
    marketValue: 800000,
  },
  {
    jerseyNumber: 5,
    name: 'Orel Mangala',
    dateOfBirth: '18.03.1998',
    nationality: 'Belgien',
    height: 1.78,
    foot: 'rechts',
    teamMemberSince: '31.07.2022',
    club: 'Nottingham Forest',
    previousClub: 'VfB Stuttgart',
    uid: '5c03ccc9-cf8a-4e4d-8d60-8dcb87a03428',
    position: 'Zentrales Mittelfeld',
    league: 'Premier League',
    marketValue: 12000000,
  },
  {
    jerseyNumber: 23,
    name: 'Remo Freuler',
    dateOfBirth: '15.04.1992',
    nationality: 'Schweiz',
    height: 1.8,
    foot: 'rechts',
    teamMemberSince: '14.08.2022',
    club: 'Nottingham Forest',
    previousClub: 'Atalanta Bergamo',
    uid: 'f47b7658-e766-4d26-8d5c-7c77e5566905',
    position: 'Zentrales Mittelfeld',
    league: 'Premier League',
    marketValue: 10000000,
  },
  {
    jerseyNumber: 22,
    name: 'Ryan Yates',
    dateOfBirth: '21.11.1997',
    nationality: 'England',
    height: 1.9,
    foot: 'rechts',
    teamMemberSince: '01.07.2016',
    club: 'Nottingham Forest',
    previousClub: 'Nottingham Forest U18',
    uid: '2314b773-fd05-451e-a1f6-29446948bd93',
    position: 'Zentrales Mittelfeld',
    league: 'Premier League',
    marketValue: 8000000,
  },
  {
    jerseyNumber: 6,
    name: 'Jonjo Shelvey',
    dateOfBirth: '27.02.1992',
    nationality: 'England',
    height: 1.84,
    foot: 'rechts',
    teamMemberSince: '31.01.2023',
    club: 'Nottingham Forest',
    previousClub: 'Newcastle United',
    uid: '3cce4615-c870-405c-a206-9c27ce1382f2',
    position: 'Zentrales Mittelfeld',
    league: 'Premier League',
    marketValue: 7000000,
  },
  {
    jerseyNumber: 18,
    name: 'Cafú',
    dateOfBirth: '26.02.1993',
    nationality: 'Portugal',
    height: 1.85,
    foot: 'rechts',
    teamMemberSince: '02.02.2021',
    club: 'Nottingham Forest',
    previousClub: 'Olympiakos Piräus',
    uid: '97a815f6-443c-4897-896a-6ed7983ab958',
    position: 'Zentrales Mittelfeld',
    league: 'Premier League',
    marketValue: 600000,
  },
  {
    jerseyNumber: 10,
    name: 'Morgan Gibbs-White',
    dateOfBirth: '27.01.2000',
    nationality: 'England',
    height: 1.78,
    foot: 'rechts',
    teamMemberSince: '19.08.2022',
    club: 'Nottingham Forest',
    previousClub: 'Wolverhampton Wanderers',
    uid: '56d44d48-a772-4e58-b08c-471625e09b31',
    position: 'Offensives Mittelfeld',
    league: 'Premier League',
    marketValue: 30000000,
  },
  {
    jerseyNumber: 20,
    name: 'Brennan Johnson',
    dateOfBirth: '23.05.2001',
    nationality: 'Wales',
    height: 1.79,
    foot: 'rechts',
    teamMemberSince: '01.07.2019',
    club: 'Nottingham Forest',
    previousClub: 'Nottingham Forest U18',
    uid: '993b23de-2a3f-45b7-8144-ea8194e3aa7c',
    position: 'Offensives Mittelfeld',
    league: 'Premier League',
    marketValue: 30000000,
  },
  {
    jerseyNumber: 11,
    name: 'Jesse Lingard',
    dateOfBirth: '15.12.1992',
    nationality: 'England',
    height: 1.74,
    foot: 'rechts',
    teamMemberSince: '21.07.2022',
    club: 'Nottingham Forest',
    previousClub: 'Manchester United',
    uid: 'e6527e48-4e23-400f-9e32-8247e6ffe09f',
    position: 'Offensives Mittelfeld',
    league: 'Premier League',
    marketValue: 12000000,
  },
  {
    jerseyNumber: 31,
    name: 'Gustavo Scarpa',
    dateOfBirth: '05.01.1994',
    nationality: 'Brasilien',
    height: 1.77,
    foot: 'links',
    teamMemberSince: '01.01.2023',
    club: 'Nottingham Forest',
    previousClub: 'SE Palmeiras São Paulo',
    uid: '56a664dc-f879-4deb-b837-354e08dcaaa2',
    position: 'Offensives Mittelfeld',
    league: 'Premier League',
    marketValue: 12000000,
  },
  {
    jerseyNumber: 34,
    name: 'André Ayew',
    dateOfBirth: '17.12.1989',
    nationality: 'Ghana',
    height: 1.75,
    foot: 'links',
    teamMemberSince: '02.02.2023',
    club: 'Nottingham Forest',
    previousClub: 'Al-Sadd SC',
    uid: '6d258308-6125-4e65-a39f-d321768acab7',
    position: 'Linksaußen',
    league: 'Premier League',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 17,
    name: 'Alex Mighten',
    dateOfBirth: '11.04.2002',
    nationality: 'England',
    height: 1.71,
    foot: 'rechts',
    teamMemberSince: '01.07.2020',
    club: 'Nottingham Forest',
    previousClub: 'Nottingham Forest U18',
    uid: '3efa94f9-7434-4319-9552-f65102149bab',
    position: 'Linksaußen',
    league: 'Premier League',
    marketValue: 600000,
  },
  {
    jerseyNumber: 9,
    name: 'Taiwo Awoniyi',
    dateOfBirth: '12.08.1997',
    nationality: 'Nigeria',
    height: 1.83,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'Nottingham Forest',
    previousClub: '1.FC Union Berlin',
    uid: '8520a444-2163-4869-8f89-017abff2339f',
    position: 'Mittelstürmer',
    league: 'Premier League',
    marketValue: 20000000,
  },
  {
    jerseyNumber: 25,
    name: 'Emmanuel Dennis',
    dateOfBirth: '15.11.1997',
    nationality: 'Nigeria',
    height: 1.75,
    foot: 'rechts',
    teamMemberSince: '13.08.2022',
    club: 'Nottingham Forest',
    previousClub: 'FC Watford',
    uid: 'c520bc71-6c0f-4369-8461-8aedfcc3e8ff',
    position: 'Mittelstürmer',
    league: 'Premier League',
    marketValue: 10000000,
  },
  {
    jerseyNumber: 39,
    name: 'Chris Wood',
    dateOfBirth: '07.12.1991',
    nationality: 'Neuseeland',
    height: 1.91,
    foot: 'rechts',
    teamMemberSince: '20.01.2023',
    club: 'Nottingham Forest',
    previousClub: 'Newcastle United',
    uid: 'dc9e3bcc-58e4-4a48-99f9-ad6ba22bf459',
    position: 'Mittelstürmer',
    league: 'Premier League',
    marketValue: 10000000,
  },
  {
    jerseyNumber: 16,
    name: 'Sam Surridge',
    dateOfBirth: '28.07.1998',
    nationality: 'England',
    height: 1.9,
    foot: 'rechts',
    teamMemberSince: '31.01.2022',
    club: 'Nottingham Forest',
    previousClub: 'Stoke City',
    uid: '29fe519d-37db-46a5-901d-92d1f3379c51',
    position: 'Mittelstürmer',
    league: 'Premier League',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 33,
    name: 'Lyle Taylor',
    dateOfBirth: '29.03.1990',
    nationality: 'Montserrat',
    height: 1.88,
    foot: 'rechts',
    teamMemberSince: '15.08.2020',
    club: 'Nottingham Forest',
    previousClub: 'Charlton Athletic',
    uid: '914a1d36-cd46-4588-bb76-70342a2b47bb',
    position: 'Mittelstürmer',
    league: 'Premier League',
    marketValue: 350000,
  },
  {
    jerseyNumber: 1,
    name: 'David Raya',
    dateOfBirth: '15.09.1995',
    nationality: 'Spanien',
    height: 1.83,
    foot: 'rechts',
    teamMemberSince: '06.07.2019',
    club: 'FC Brentford',
    previousClub: 'Blackburn Rovers',
    uid: '6623bb99-e879-4f39-999c-5c3d289ef0c2',
    position: 'Torwart',
    league: 'Premier League',
    marketValue: 25000000,
  },
  {
    jerseyNumber: 22,
    name: 'Thomas Strakosha',
    dateOfBirth: '19.03.1995',
    nationality: 'Albanien',
    height: 1.93,
    foot: 'rechts',
    teamMemberSince: '14.07.2022',
    club: 'FC Brentford',
    previousClub: 'Lazio Rom',
    uid: 'bc7e6281-8aed-4a0f-8602-6371487fb365',
    position: 'Torwart',
    league: 'Premier League',
    marketValue: 4000000,
  },
  {
    jerseyNumber: 20,
    name: 'Kristoffer Ajer',
    dateOfBirth: '17.04.1998',
    nationality: 'Norwegen',
    height: 1.98,
    foot: 'rechts',
    teamMemberSince: '21.07.2021',
    club: 'FC Brentford',
    previousClub: 'Celtic Glasgow',
    uid: 'c3ecb862-a3b8-4e41-9b8c-3509cebd85b6',
    position: 'Innenverteidiger',
    league: 'Premier League',
    marketValue: 16000000,
  },
  {
    jerseyNumber: 5,
    name: 'Ethan Pinnock',
    dateOfBirth: '29.05.1993',
    nationality: 'Jamaika',
    height: 1.94,
    foot: 'links',
    teamMemberSince: '02.07.2019',
    club: 'FC Brentford',
    previousClub: 'FC Barnsley',
    uid: '77d7c64c-e1e0-442a-9055-6aea9051fc11',
    position: 'Innenverteidiger',
    league: 'Premier League',
    marketValue: 12000000,
  },
  {
    jerseyNumber: 18,
    name: 'Pontus Jansson',
    dateOfBirth: '13.02.1991',
    nationality: 'Schweden',
    height: 1.96,
    foot: 'rechts',
    teamMemberSince: '08.07.2019',
    club: 'FC Brentford',
    previousClub: 'Leeds United',
    uid: '8632b048-6192-4df3-add8-2b81ce1796ad',
    position: 'Innenverteidiger',
    league: 'Premier League',
    marketValue: 5000000,
  },
  {
    jerseyNumber: 16,
    name: 'Ben Mee',
    dateOfBirth: '21.09.1989',
    nationality: 'England',
    height: 1.8,
    foot: 'links',
    teamMemberSince: '22.07.2022',
    club: 'FC Brentford',
    previousClub: 'FC Burnley',
    uid: '84976d92-8f15-48a9-ad4b-5fb2a62d91d9',
    position: 'Innenverteidiger',
    league: 'Premier League',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 13,
    name: 'Zanka',
    dateOfBirth: '23.04.1990',
    nationality: 'Dänemark',
    height: 1.89,
    foot: 'rechts',
    teamMemberSince: '09.09.2021',
    club: 'FC Brentford',
    previousClub: 'Fenerbahce',
    uid: '94277bba-dca0-43e3-bdba-94fa37a68243',
    position: 'Innenverteidiger',
    league: 'Premier League',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 3,
    name: 'Rico Henry',
    dateOfBirth: '08.07.1997',
    nationality: 'England',
    height: 1.7,
    foot: 'links',
    teamMemberSince: '31.08.2016',
    club: 'FC Brentford',
    previousClub: 'FC Walsall',
    uid: '36e8a884-8dc3-456f-948f-ab8ce391712b',
    position: 'Linker Verteidiger',
    league: 'Premier League',
    marketValue: 22000000,
  },
  {
    jerseyNumber: 2,
    name: 'Aaron Hickey',
    dateOfBirth: '10.06.2002',
    nationality: 'Schottland',
    height: 1.85,
    foot: 'beidfüßig',
    teamMemberSince: '09.07.2022',
    club: 'FC Brentford',
    previousClub: 'FC Bologna',
    uid: 'd448573e-0a71-4b5c-accb-c9300ead0ba7',
    position: 'Linker Verteidiger',
    league: 'Premier League',
    marketValue: 18000000,
  },
  {
    jerseyNumber: 30,
    name: 'Mads Roerslev',
    dateOfBirth: '24.06.1999',
    nationality: 'Dänemark',
    height: 1.84,
    foot: 'rechts',
    teamMemberSince: '07.08.2019',
    club: 'FC Brentford',
    previousClub: 'FC Kopenhagen',
    uid: 'dfe74df2-e6cc-46a3-a0b5-4a631e007a76',
    position: 'Rechter Verteidiger',
    league: 'Premier League',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 33,
    name: 'Fin Stevens',
    dateOfBirth: '10.04.2003',
    nationality: 'Wales',
    height: 1.79,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'FC Brentford',
    previousClub: 'FC Brentford B',
    uid: 'dcf24423-42b7-4fed-93d3-bb7a1ce88f53',
    position: 'Rechter Verteidiger',
    league: 'Premier League',
    marketValue: 400000,
  },
  {
    jerseyNumber: 27,
    name: 'Vitaly Janelt',
    dateOfBirth: '10.05.1998',
    nationality: 'Deutschland',
    height: 1.84,
    foot: 'links',
    teamMemberSince: '03.10.2020',
    club: 'FC Brentford',
    previousClub: 'VfL Bochum',
    uid: '14a1a456-b066-40d2-93a0-6fa75111c59d',
    position: 'Defensives Mittelfeld',
    league: 'Premier League',
    marketValue: 16000000,
  },
  {
    jerseyNumber: 6,
    name: 'Christian Nörgaard',
    dateOfBirth: '10.03.1994',
    nationality: 'Dänemark',
    height: 1.87,
    foot: 'rechts',
    teamMemberSince: '01.07.2019',
    club: 'FC Brentford',
    previousClub: ': Ablöse 3,50 Mio. €',
    uid: '12beff20-adf5-43cb-a988-b6f3e833cdb9',
    position: 'Defensives Mittelfeld',
    league: 'Premier League',
    marketValue: 14000000,
  },
  {
    jerseyNumber: 8,
    name: 'Mathias Jensen',
    dateOfBirth: '01.01.1996',
    nationality: 'Dänemark',
    height: 1.8,
    foot: 'rechts',
    teamMemberSince: '10.07.2019',
    club: 'FC Brentford',
    previousClub: 'Celta Vigo',
    uid: '498eb64c-20be-4691-9578-7d68164bb317',
    position: 'Zentrales Mittelfeld',
    league: 'Premier League',
    marketValue: 14000000,
  },
  {
    jerseyNumber: 10,
    name: 'Josh Dasilva',
    dateOfBirth: '23.10.1998',
    nationality: 'England',
    height: 1.84,
    foot: 'links',
    teamMemberSince: '21.08.2018',
    club: 'FC Brentford',
    previousClub: 'FC Arsenal U23',
    uid: 'd676e190-d33b-4c4c-9537-2295747d00ce',
    position: 'Zentrales Mittelfeld',
    league: 'Premier League',
    marketValue: 9000000,
  },
  {
    jerseyNumber: 15,
    name: 'Frank Onyeka',
    dateOfBirth: '01.01.1998',
    nationality: 'Nigeria',
    height: 1.83,
    foot: 'rechts',
    teamMemberSince: '20.07.2021',
    club: 'FC Brentford',
    previousClub: 'FC Midtjylland',
    uid: '056a7f80-f842-4027-b22c-fe0f044436b7',
    position: 'Zentrales Mittelfeld',
    league: 'Premier League',
    marketValue: 9000000,
  },
  {
    jerseyNumber: 26,
    name: 'Shandon Baptiste',
    dateOfBirth: '08.04.1998',
    nationality: 'Grenada',
    height: 1.8,
    foot: 'rechts',
    teamMemberSince: '31.01.2020',
    club: 'FC Brentford',
    previousClub: 'Oxford United',
    uid: '9a7f6bc6-3f72-425f-ae37-f35644bfb59b',
    position: 'Zentrales Mittelfeld',
    league: 'Premier League',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 14,
    name: 'Saman Ghoddos',
    dateOfBirth: '06.09.1993',
    nationality: 'Iran',
    height: 1.75,
    foot: 'rechts',
    teamMemberSince: '14.01.2021',
    club: 'FC Brentford',
    previousClub: ': Ablöse ?',
    uid: '69c566e0-7671-43f5-8b37-0e5df0c82731',
    position: 'Offensives Mittelfeld',
    league: 'Premier League',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 11,
    name: 'Yoane Wissa',
    dateOfBirth: '03.09.1996',
    nationality: 'DR Kongo',
    height: 1.76,
    foot: 'rechts',
    teamMemberSince: '10.08.2021',
    club: 'FC Brentford',
    previousClub: 'FC Lorient',
    uid: 'ec03d064-f566-4e4a-9991-40bbe5751e8b',
    position: 'Linksaußen',
    league: 'Premier League',
    marketValue: 15000000,
  },
  {
    jerseyNumber: 23,
    name: 'Keane Lewis-Potter',
    dateOfBirth: '22.02.2001',
    nationality: 'England',
    height: 1.7,
    foot: 'rechts',
    teamMemberSince: '12.07.2022',
    club: 'FC Brentford',
    previousClub: 'Hull City',
    uid: '4c97c407-e60f-4977-8c35-d5b8a88edfcb',
    position: 'Linksaußen',
    league: 'Premier League',
    marketValue: 15000000,
  },
  {
    jerseyNumber: 24,
    name: 'Mikkel Damsgaard',
    dateOfBirth: '03.07.2000',
    nationality: 'Dänemark',
    height: 1.8,
    foot: 'rechts',
    teamMemberSince: '10.08.2022',
    club: 'FC Brentford',
    previousClub: 'Sampdoria Genua',
    uid: 'e8f656c9-f8a9-4848-896f-c61c8fd9d397',
    position: 'Linksaußen',
    league: 'Premier League',
    marketValue: 15000000,
  },
  {
    jerseyNumber: 19,
    name: 'Bryan Mbeumo',
    dateOfBirth: '07.08.1999',
    nationality: 'Kamerun',
    height: 1.71,
    foot: 'links',
    teamMemberSince: '05.08.2019',
    club: 'FC Brentford',
    previousClub: 'ESTAC Troyes',
    uid: '579350b8-6607-4cb6-a092-36dbdc541941',
    position: 'Rechtsaußen',
    league: 'Premier League',
    marketValue: 28000000,
  },
  {
    jerseyNumber: 9,
    name: 'Kevin Schade',
    dateOfBirth: '27.11.2001',
    nationality: 'Deutschland',
    height: 1.85,
    foot: 'rechts',
    teamMemberSince: '04.01.2023',
    club: 'FC Brentford',
    previousClub: 'SC Freiburg',
    uid: 'f39e0244-99c0-4aca-a246-32e526d54b23',
    position: 'Rechtsaußen',
    league: 'Premier League',
    marketValue: 20000000,
  },
  {
    jerseyNumber: 17,
    name: 'Ivan Toney',
    dateOfBirth: '16.03.1996',
    nationality: 'England',
    height: 1.85,
    foot: 'rechts',
    teamMemberSince: '01.09.2020',
    club: 'FC Brentford',
    previousClub: 'Peterborough United',
    uid: 'fc3c833c-781f-47cf-91cc-4dde08b87ab2',
    position: 'Mittelstürmer',
    league: 'Premier League',
    marketValue: 50000000,
  },
  {
    jerseyNumber: 1,
    name: 'Illan Meslier',
    dateOfBirth: '02.03.2000',
    nationality: 'Frankreich',
    height: 1.97,
    foot: 'links',
    teamMemberSince: '27.07.2020',
    club: 'Leeds United',
    previousClub: 'FC Lorient',
    uid: '29b48cf7-b97f-42d7-97fe-7a51bb328f29',
    position: 'Torwart',
    league: 'Premier League',
    marketValue: 22000000,
  },
  {
    jerseyNumber: 13,
    name: 'Kristoffer Klaesson',
    dateOfBirth: '27.11.2000',
    nationality: 'Norwegen',
    height: 1.89,
    foot: 'links',
    teamMemberSince: '31.07.2021',
    club: 'Leeds United',
    previousClub: 'Vålerenga Fotball',
    uid: 'eca51b96-aae7-43c1-bd8a-425cf4624834',
    position: 'Torwart',
    league: 'Premier League',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 22,
    name: 'Joel Robles',
    dateOfBirth: '17.06.1990',
    nationality: 'Spanien',
    height: 1.97,
    foot: 'rechts',
    teamMemberSince: '09.08.2022',
    club: 'Leeds United',
    previousClub: 'Real Betis Sevilla',
    uid: 'c221ab1a-65b4-4769-880c-b53c2bce503c',
    position: 'Torwart',
    league: 'Premier League',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 5,
    name: 'Robin Koch',
    dateOfBirth: '17.07.1996',
    nationality: 'Deutschland',
    height: 1.91,
    foot: 'rechts',
    teamMemberSince: '29.08.2020',
    club: 'Leeds United',
    previousClub: 'SC Freiburg',
    uid: '0077740d-013c-49ef-ba2a-a7de5fb68358',
    position: 'Innenverteidiger',
    league: 'Premier League',
    marketValue: 18000000,
  },
  {
    jerseyNumber: 39,
    name: 'Maximilian Wöber',
    dateOfBirth: '04.02.1998',
    nationality: 'Österreich',
    height: 1.88,
    foot: 'links',
    teamMemberSince: '03.01.2023',
    club: 'Leeds United',
    previousClub: 'Red Bull Salzburg',
    uid: '2e2d81a0-aed5-4e4a-b5a5-87e12e7c4177',
    position: 'Innenverteidiger',
    league: 'Premier League',
    marketValue: 12000000,
  },
  {
    jerseyNumber: 6,
    name: 'Liam Cooper',
    dateOfBirth: '30.08.1991',
    nationality: 'Schottland',
    height: 1.86,
    foot: 'links',
    teamMemberSince: '13.08.2014',
    club: 'Leeds United',
    previousClub: 'FC Chesterfield',
    uid: 'd0aa7580-7d3b-43aa-8bfd-9c23c9b0c39b',
    position: 'Innenverteidiger',
    league: 'Premier League',
    marketValue: 5000000,
  },
  {
    jerseyNumber: 21,
    name: 'Pascal Struijk',
    dateOfBirth: '11.08.1999',
    nationality: 'Niederlande',
    height: 1.9,
    foot: 'links',
    teamMemberSince: '01.01.2020',
    club: 'Leeds United',
    previousClub: 'Leeds United U21',
    uid: '9c549808-c3c6-4f6c-9a36-691a4ea4fffe',
    position: 'Linker Verteidiger',
    league: 'Premier League',
    marketValue: 20000000,
  },
  {
    jerseyNumber: 3,
    name: 'Junior Firpo',
    dateOfBirth: '22.08.1996',
    nationality: 'Dominikanische Republik',
    height: 1.84,
    foot: 'links',
    teamMemberSince: '06.07.2021',
    club: 'Leeds United',
    previousClub: 'FC Barcelona',
    uid: 'f7338c64-1f5d-45c9-854a-ed99ef598b5b',
    position: 'Linker Verteidiger',
    league: 'Premier League',
    marketValue: 12000000,
  },
  {
    jerseyNumber: 25,
    name: 'Rasmus Kristensen',
    dateOfBirth: '11.07.1997',
    nationality: 'Dänemark',
    height: 1.87,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'Leeds United',
    previousClub: 'Red Bull Salzburg',
    uid: '4a4b6b82-e84f-47fa-93c2-b6a1561eb393',
    position: 'Rechter Verteidiger',
    league: 'Premier League',
    marketValue: 15000000,
  },
  {
    jerseyNumber: 2,
    name: 'Luke Ayling',
    dateOfBirth: '25.08.1991',
    nationality: 'England',
    height: 1.83,
    foot: 'rechts',
    teamMemberSince: '11.08.2016',
    club: 'Leeds United',
    previousClub: 'Bristol City',
    uid: '3aab5446-8847-478c-82ca-2815a831b83f',
    position: 'Rechter Verteidiger',
    league: 'Premier League',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 15,
    name: 'Stuart Dallas',
    dateOfBirth: '19.04.1991',
    nationality: 'Nordirland',
    height: 1.83,
    foot: 'rechts',
    teamMemberSince: '04.08.2015',
    club: 'Leeds United',
    previousClub: ': Ablöse 1,90 Mio. €',
    uid: '3e4f1ac7-c14f-44eb-8af0-bc0e7dd80e76',
    position: 'Rechter Verteidiger',
    league: 'Premier League',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 12,
    name: 'Tyler Adams',
    dateOfBirth: '14.02.1999',
    nationality: 'Vereinigte Staaten',
    height: 1.75,
    foot: 'rechts',
    teamMemberSince: '06.07.2022',
    club: 'Leeds United',
    previousClub: 'RasenBallsport Leipzig',
    uid: '7b671397-43e5-425c-aba4-c30f3ebff21b',
    position: 'Defensives Mittelfeld',
    league: 'Premier League',
    marketValue: 20000000,
  },
  {
    jerseyNumber: 8,
    name: 'Marc Roca',
    dateOfBirth: '26.11.1996',
    nationality: 'Spanien',
    height: 1.84,
    foot: 'links',
    teamMemberSince: '01.07.2022',
    club: 'Leeds United',
    previousClub: 'FC Bayern München',
    uid: 'c6081faa-61c9-4190-9f29-131413d5afdd',
    position: 'Defensives Mittelfeld',
    league: 'Premier League',
    marketValue: 15000000,
  },
  {
    jerseyNumber: 28,
    name: 'Weston McKennie',
    dateOfBirth: '28.08.1998',
    nationality: 'Vereinigte Staaten',
    height: 1.85,
    foot: 'rechts',
    teamMemberSince: '30.01.2023',
    club: 'Leeds United',
    previousClub: 'Juventus Turin',
    uid: '99d54fa0-1eda-46b4-a470-5cddb0ab34c6',
    position: 'Zentrales Mittelfeld',
    league: 'Premier League',
    marketValue: 21000000,
  },
  {
    jerseyNumber: 4,
    name: 'Adam Forshaw',
    dateOfBirth: '08.10.1991',
    nationality: 'England',
    height: 1.75,
    foot: 'rechts',
    teamMemberSince: '18.01.2018',
    club: 'Leeds United',
    previousClub: 'FC Middlesbrough',
    uid: 'f3be087b-c555-4e60-b094-57866c739f57',
    position: 'Zentrales Mittelfeld',
    league: 'Premier League',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 7,
    name: 'Brenden Aaronson',
    dateOfBirth: '22.10.2000',
    nationality: 'Vereinigte Staaten',
    height: 1.77,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'Leeds United',
    previousClub: 'Red Bull Salzburg',
    uid: 'e32cfe88-57e7-43f8-9257-0f0fe7ae35be',
    position: 'Offensives Mittelfeld',
    league: 'Premier League',
    marketValue: 30000000,
  },
  {
    jerseyNumber: 42,
    name: 'Sam Greenwood',
    dateOfBirth: '26.01.2002',
    nationality: 'England',
    height: 1.8,
    foot: 'rechts',
    teamMemberSince: '01.01.2022',
    club: 'Leeds United',
    previousClub: 'Leeds United U21',
    uid: '43e65af2-b732-4bf5-aa33-d385e3f904b9',
    position: 'Offensives Mittelfeld',
    league: 'Premier League',
    marketValue: 4000000,
  },
  {
    jerseyNumber: 11,
    name: 'Jack Harrison',
    dateOfBirth: '20.11.1996',
    nationality: 'England',
    height: 1.75,
    foot: 'links',
    teamMemberSince: '02.07.2021',
    club: 'Leeds United',
    previousClub: 'Manchester City',
    uid: 'b750a716-cd0b-4ab8-9a74-686573c839a0',
    position: 'Linksaußen',
    league: 'Premier League',
    marketValue: 22000000,
  },
  {
    jerseyNumber: 23,
    name: 'Luis Sinisterra',
    dateOfBirth: '17.06.1999',
    nationality: 'Kolumbien',
    height: 1.72,
    foot: 'rechts',
    teamMemberSince: '07.07.2022',
    club: 'Leeds United',
    previousClub: 'Feyenoord Rotterdam',
    uid: '9fc9e481-1076-4aa7-b3b0-f3ab2cc13bf6',
    position: 'Linksaußen',
    league: 'Premier League',
    marketValue: 22000000,
  },
  {
    jerseyNumber: 29,
    name: 'Wilfried Gnonto',
    dateOfBirth: '05.11.2003',
    nationality: 'Italien',
    height: 1.7,
    foot: 'rechts',
    teamMemberSince: '01.09.2022',
    club: 'Leeds United',
    previousClub: 'FC Zürich',
    uid: '5c7a218e-39d2-4bd6-b8b7-ef0639c6a8e8',
    position: 'Linksaußen',
    league: 'Premier League',
    marketValue: 18000000,
  },
  {
    jerseyNumber: 10,
    name: 'Crysencio Summerville',
    dateOfBirth: '30.10.2001',
    nationality: 'Niederlande',
    height: 1.74,
    foot: 'rechts',
    teamMemberSince: '01.07.2021',
    club: 'Leeds United',
    previousClub: 'Leeds United U21',
    uid: 'dce7018a-4979-43fc-80f0-01e68fae5425',
    position: 'Rechtsaußen',
    league: 'Premier League',
    marketValue: 12000000,
  },
  {
    jerseyNumber: 24,
    name: 'Georginio Rutter',
    dateOfBirth: '20.04.2002',
    nationality: 'Frankreich',
    height: 1.82,
    foot: 'links',
    teamMemberSince: '14.01.2023',
    club: 'Leeds United',
    previousClub: 'TSG 1899 Hoffenheim',
    uid: 'e43f8fbe-c976-4ec1-8ea2-a42b3d50ca97',
    position: 'Mittelstürmer',
    league: 'Premier League',
    marketValue: 22000000,
  },
  {
    jerseyNumber: 9,
    name: 'Patrick Bamford',
    dateOfBirth: '05.09.1993',
    nationality: 'England',
    height: 1.85,
    foot: 'links',
    teamMemberSince: '31.07.2018',
    club: 'Leeds United',
    previousClub: 'FC Middlesbrough',
    uid: 'c276649c-bcf1-41c4-9d3b-fa8bb32b64bc',
    position: 'Mittelstürmer',
    league: 'Premier League',
    marketValue: 10000000,
  },
  {
    jerseyNumber: 19,
    name: 'Rodrigo',
    dateOfBirth: '06.03.1991',
    nationality: 'Spanien',
    height: 1.82,
    foot: 'links',
    teamMemberSince: '29.08.2020',
    club: 'Leeds United',
    previousClub: 'FC Valencia',
    uid: '4e80fc34-97b2-4836-936e-49985ed6e281',
    position: 'Mittelstürmer',
    league: 'Premier League',
    marketValue: 9000000,
  },
  {
    jerseyNumber: 21,
    name: 'Sam Johnstone',
    dateOfBirth: '25.03.1993',
    nationality: 'England',
    height: 1.93,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'Crystal Palace',
    previousClub: 'West Bromwich Albion',
    uid: '201441e7-77b3-4cba-a532-eb99ed460d4c',
    position: 'Torwart',
    league: 'Premier League',
    marketValue: 4000000,
  },
  {
    jerseyNumber: 13,
    name: 'Vicente Guaita',
    dateOfBirth: '10.01.1987',
    nationality: 'Spanien',
    height: 1.9,
    foot: 'rechts',
    teamMemberSince: '01.07.2018',
    club: 'Crystal Palace',
    previousClub: 'FC Getafe',
    uid: 'b0c073c6-d440-4a2a-8a15-58292cad5e4a',
    position: 'Torwart',
    league: 'Premier League',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 41,
    name: 'Joe Whitworth',
    dateOfBirth: '29.02.2004',
    nationality: 'England',
    height: 1.79,
    foot: 'rechts',
    teamMemberSince: '11.01.2023',
    club: 'Crystal Palace',
    previousClub: 'Crystal Palace U21',
    uid: '436091f2-593b-45a3-b20b-04886e030770',
    position: 'Torwart',
    league: 'Premier League',
    marketValue: 250000,
  },
  {
    jerseyNumber: 6,
    name: 'Marc Guéhi',
    dateOfBirth: '13.07.2000',
    nationality: 'England',
    height: 1.82,
    foot: 'rechts',
    teamMemberSince: '18.07.2021',
    club: 'Crystal Palace',
    previousClub: 'FC Chelsea',
    uid: '4cb40e8d-0305-40e2-bda8-8cbc01f5c6c2',
    position: 'Innenverteidiger',
    league: 'Premier League',
    marketValue: 35000000,
  },
  {
    jerseyNumber: 16,
    name: 'Joachim Andersen',
    dateOfBirth: '31.05.1996',
    nationality: 'Dänemark',
    height: 1.92,
    foot: 'rechts',
    teamMemberSince: '28.07.2021',
    club: 'Crystal Palace',
    previousClub: ': Ablöse 17,50 Mio. €',
    uid: '66603c57-322c-4422-9df8-d5accd837235',
    position: 'Innenverteidiger',
    league: 'Premier League',
    marketValue: 32000000,
  },
  {
    jerseyNumber: 26,
    name: 'Chris Richards',
    dateOfBirth: '28.03.2000',
    nationality: 'Vereinigte Staaten',
    height: 1.88,
    foot: 'rechts',
    teamMemberSince: '27.07.2022',
    club: 'Crystal Palace',
    previousClub: 'FC Bayern München',
    uid: 'bf3c0ebe-8b30-4819-aee8-c9ee23b54aea',
    position: 'Innenverteidiger',
    league: 'Premier League',
    marketValue: 10000000,
  },
  {
    jerseyNumber: 36,
    name: 'Nathan Ferguson',
    dateOfBirth: '06.10.2000',
    nationality: 'England',
    height: 1.84,
    foot: 'rechts',
    teamMemberSince: '27.07.2020',
    club: 'Crystal Palace',
    previousClub: 'West Bromwich Albion',
    uid: 'a133bcad-e805-47cd-9531-c42be7e98ed1',
    position: 'Innenverteidiger',
    league: 'Premier League',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 5,
    name: 'James Tomkins',
    dateOfBirth: '29.03.1989',
    nationality: 'England',
    height: 1.92,
    foot: 'rechts',
    teamMemberSince: '05.07.2016',
    club: 'Crystal Palace',
    previousClub: 'West Ham United',
    uid: 'da57fe58-9a7c-4eff-b9b8-0b098a63078e',
    position: 'Innenverteidiger',
    league: 'Premier League',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 3,
    name: 'Tyrick Mitchell',
    dateOfBirth: '01.09.1999',
    nationality: 'England',
    height: 1.75,
    foot: 'links',
    teamMemberSince: '01.07.2020',
    club: 'Crystal Palace',
    previousClub: 'Crystal Palace U21',
    uid: '698a16bd-6cf6-4c74-b5a8-51aa5a187b8f',
    position: 'Linker Verteidiger',
    league: 'Premier League',
    marketValue: 28000000,
  },
  {
    jerseyNumber: 17,
    name: 'Nathaniel Clyne',
    dateOfBirth: '05.04.1991',
    nationality: 'England',
    height: 1.75,
    foot: 'rechts',
    teamMemberSince: '14.10.2020',
    club: 'Crystal Palace',
    previousClub: 'FC Liverpool',
    uid: '4de617a0-855e-4305-85da-f0f0f781bebd',
    position: 'Rechter Verteidiger',
    league: 'Premier League',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 2,
    name: 'Joel Ward',
    dateOfBirth: '29.10.1989',
    nationality: 'England',
    height: 1.86,
    foot: 'rechts',
    teamMemberSince: '01.07.2012',
    club: 'Crystal Palace',
    previousClub: ': Ablöse 650 Tsd. €',
    uid: '6ee8524c-ebeb-4c8d-a156-3d709966df2e',
    position: 'Rechter Verteidiger',
    league: 'Premier League',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 28,
    name: 'Cheick Doucouré',
    dateOfBirth: '08.01.2000',
    nationality: 'Mali',
    height: 1.8,
    foot: 'rechts',
    teamMemberSince: '11.07.2022',
    club: 'Crystal Palace',
    previousClub: 'RC Lens',
    uid: '690c2fe4-171d-44b5-a703-2ff185eeaa82',
    position: 'Defensives Mittelfeld',
    league: 'Premier League',
    marketValue: 30000000,
  },
  {
    jerseyNumber: 44,
    name: 'Jairo Riedewald',
    dateOfBirth: '09.09.1996',
    nationality: 'Niederlande',
    height: 1.82,
    foot: 'links',
    teamMemberSince: '24.07.2017',
    club: 'Crystal Palace',
    previousClub: 'Ajax Amsterdam',
    uid: 'abe55a4d-a0a8-4853-b4ed-c6f0cedbf69c',
    position: 'Defensives Mittelfeld',
    league: 'Premier League',
    marketValue: 4000000,
  },
  {
    jerseyNumber: 4,
    name: 'Luka Milivojevic',
    dateOfBirth: '07.04.1991',
    nationality: 'Serbien',
    height: 1.86,
    foot: 'rechts',
    teamMemberSince: '31.01.2017',
    club: 'Crystal Palace',
    previousClub: 'Olympiakos Piräus',
    uid: '5856f39f-136c-4e4c-bff7-87b395401f05',
    position: 'Defensives Mittelfeld',
    league: 'Premier League',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 8,
    name: 'Albert Sambi Lokonga',
    dateOfBirth: '22.10.1999',
    nationality: 'Belgien',
    height: 1.83,
    foot: 'rechts',
    teamMemberSince: '31.01.2023',
    club: 'Crystal Palace',
    previousClub: 'FC Arsenal',
    uid: '13e30a92-3ff9-4408-944e-a0ee853366f4',
    position: 'Zentrales Mittelfeld',
    league: 'Premier League',
    marketValue: 15000000,
  },
  {
    jerseyNumber: 29,
    name: 'Naouirou Ahamada',
    dateOfBirth: '29.03.2002',
    nationality: 'Frankreich',
    height: 1.83,
    foot: 'rechts',
    teamMemberSince: '31.01.2023',
    club: 'Crystal Palace',
    previousClub: 'VfB Stuttgart',
    uid: '8e4da904-7106-4e92-b063-40c190c54dfb',
    position: 'Zentrales Mittelfeld',
    league: 'Premier League',
    marketValue: 10000000,
  },
  {
    jerseyNumber: 19,
    name: 'Will Hughes',
    dateOfBirth: '17.04.1995',
    nationality: 'England',
    height: 1.85,
    foot: 'links',
    teamMemberSince: '28.08.2021',
    club: 'Crystal Palace',
    previousClub: 'FC Watford',
    uid: '2bb7187b-9023-4d51-abf6-71bcc015070a',
    position: 'Zentrales Mittelfeld',
    league: 'Premier League',
    marketValue: 5000000,
  },
  {
    jerseyNumber: 18,
    name: 'James McArthur',
    dateOfBirth: '07.10.1987',
    nationality: 'Schottland',
    height: 1.78,
    foot: 'beidfüßig',
    teamMemberSince: '01.09.2014',
    club: 'Crystal Palace',
    previousClub: 'Wigan Athletic',
    uid: '2254dab4-451f-4649-90ad-ac5330671b51',
    position: 'Zentrales Mittelfeld',
    league: 'Premier League',
    marketValue: 700000,
  },
  {
    jerseyNumber: 15,
    name: 'Jeffrey Schlupp',
    dateOfBirth: '23.12.1992',
    nationality: 'Ghana',
    height: 1.78,
    foot: 'links',
    teamMemberSince: '13.01.2017',
    club: 'Crystal Palace',
    previousClub: 'Leicester City',
    uid: 'f5a85588-3b9e-4817-b764-f63829795181',
    position: 'Linkes Mittelfeld',
    league: 'Premier League',
    marketValue: 8000000,
  },
  {
    jerseyNumber: 10,
    name: 'Eberechi Eze',
    dateOfBirth: '29.06.1998',
    nationality: 'England',
    height: 1.78,
    foot: 'rechts',
    teamMemberSince: '28.08.2020',
    club: 'Crystal Palace',
    previousClub: 'Queens Park Rangers',
    uid: '45fea6b2-1fe7-412d-a536-5a8a840fcb76',
    position: 'Offensives Mittelfeld',
    league: 'Premier League',
    marketValue: 30000000,
  },
  {
    jerseyNumber: 11,
    name: 'Wilfried Zaha',
    dateOfBirth: '10.11.1992',
    nationality: 'Elfenbeinküste',
    height: 1.8,
    foot: 'rechts',
    teamMemberSince: '02.02.2015',
    club: 'Crystal Palace',
    previousClub: 'Manchester United',
    uid: '84501e15-0f08-44a6-97d0-8e7a91f5df5f',
    position: 'Linksaußen',
    league: 'Premier League',
    marketValue: 27000000,
  },
  {
    jerseyNumber: 7,
    name: 'Michael Olise',
    dateOfBirth: '12.12.2001',
    nationality: 'Frankreich',
    height: 1.84,
    foot: 'links',
    teamMemberSince: '08.07.2021',
    club: 'Crystal Palace',
    previousClub: 'FC Reading',
    uid: '2efb2306-2b2d-4642-95b6-f584019975b9',
    position: 'Rechtsaußen',
    league: 'Premier League',
    marketValue: 27000000,
  },
  {
    jerseyNumber: 22,
    name: 'Odsonne Edouard',
    dateOfBirth: '16.01.1998',
    nationality: 'Frankreich',
    height: 1.87,
    foot: 'rechts',
    teamMemberSince: '31.08.2021',
    club: 'Crystal Palace',
    previousClub: 'Celtic Glasgow',
    uid: '56032bce-a68d-41b9-9d91-cac48923671d',
    position: 'Mittelstürmer',
    league: 'Premier League',
    marketValue: 17000000,
  },
  {
    jerseyNumber: 14,
    name: 'Jean-Philippe Mateta',
    dateOfBirth: '28.06.1997',
    nationality: 'Frankreich',
    height: 1.92,
    foot: 'rechts',
    teamMemberSince: '31.01.2022',
    club: 'Crystal Palace',
    previousClub: '1.FSV Mainz 05',
    uid: '25a560fe-ea53-444f-8b24-589162998171',
    position: 'Mittelstürmer',
    league: 'Premier League',
    marketValue: 9000000,
  },
  {
    jerseyNumber: 9,
    name: 'Jordan Ayew',
    dateOfBirth: '11.09.1991',
    nationality: 'Ghana',
    height: 1.82,
    foot: 'rechts',
    teamMemberSince: '25.07.2019',
    club: 'Crystal Palace',
    previousClub: ': Ablöse 2,80 Mio. €',
    uid: 'a81122dc-c69b-4a0c-804e-e376052be591',
    position: 'Mittelstürmer',
    league: 'Premier League',
    marketValue: 6000000,
  },
  {
    jerseyNumber: 17,
    name: 'Bernd Leno',
    dateOfBirth: '04.03.1992',
    nationality: 'Deutschland',
    height: 1.9,
    foot: 'rechts',
    teamMemberSince: '02.08.2022',
    club: 'FC Fulham',
    previousClub: 'FC Arsenal',
    uid: '44d29612-fb6e-49b6-b629-7ec4eeeb4a55',
    position: 'Torwart',
    league: 'Premier League',
    marketValue: 10000000,
  },
  {
    jerseyNumber: 1,
    name: 'Marek Rodak',
    dateOfBirth: '13.12.1996',
    nationality: 'Slowakei',
    height: 1.94,
    foot: 'rechts',
    teamMemberSince: '01.07.2019',
    club: 'FC Fulham',
    previousClub: 'FC Fulham U21',
    uid: '2bb7c580-3818-417f-aba4-9b3a4ba053d3',
    position: 'Torwart',
    league: 'Premier League',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 31,
    name: 'Issa Diop',
    dateOfBirth: '09.01.1997',
    nationality: 'Frankreich',
    height: 1.94,
    foot: 'rechts',
    teamMemberSince: '10.08.2022',
    club: 'FC Fulham',
    previousClub: 'West Ham United',
    uid: '256ec4fe-118e-42ef-afa6-19b8186b0932',
    position: 'Innenverteidiger',
    league: 'Premier League',
    marketValue: 18000000,
  },
  {
    jerseyNumber: 4,
    name: 'Tosin Adarabioyo',
    dateOfBirth: '24.09.1997',
    nationality: 'England',
    height: 1.96,
    foot: 'rechts',
    teamMemberSince: '05.10.2020',
    club: 'FC Fulham',
    previousClub: 'Manchester City U23',
    uid: '82091776-44f1-4a78-bdad-70c1d41ace03',
    position: 'Innenverteidiger',
    league: 'Premier League',
    marketValue: 15000000,
  },
  {
    jerseyNumber: 5,
    name: 'Shane Duffy',
    dateOfBirth: '01.01.1992',
    nationality: 'Irland',
    height: 1.93,
    foot: 'rechts',
    teamMemberSince: '31.01.2023',
    club: 'FC Fulham',
    previousClub: 'Brighton & Hove Albion',
    uid: 'c31ad638-98ae-458d-bfca-5a83322c5121',
    position: 'Innenverteidiger',
    league: 'Premier League',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 13,
    name: 'Tim Ream',
    dateOfBirth: '05.10.1987',
    nationality: 'Vereinigte Staaten',
    height: 1.86,
    foot: 'links',
    teamMemberSince: '20.08.2015',
    club: 'FC Fulham',
    previousClub: 'Bolton Wanderers',
    uid: 'a6df6513-b4f4-49ee-9d8e-34db5be2270c',
    position: 'Innenverteidiger',
    league: 'Premier League',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 33,
    name: 'Antonee Robinson',
    dateOfBirth: '08.08.1997',
    nationality: 'Vereinigte Staaten',
    height: 1.83,
    foot: 'links',
    teamMemberSince: '20.08.2020',
    club: 'FC Fulham',
    previousClub: 'Wigan Athletic',
    uid: '560733ce-6cbd-48a1-80b1-8bce522ea77c',
    position: 'Linker Verteidiger',
    league: 'Premier League',
    marketValue: 12000000,
  },
  {
    jerseyNumber: 3,
    name: 'Layvin Kurzawa',
    dateOfBirth: '04.09.1992',
    nationality: 'Frankreich',
    height: 1.82,
    foot: 'links',
    teamMemberSince: '01.09.2022',
    club: 'FC Fulham',
    previousClub: 'FC Paris Saint-Germain',
    uid: '972eca31-a299-4940-add7-dd862d9d7b4e',
    position: 'Linker Verteidiger',
    league: 'Premier League',
    marketValue: 4000000,
  },
  {
    jerseyNumber: 2,
    name: 'Kenny Tete',
    dateOfBirth: '09.10.1995',
    nationality: 'Niederlande',
    height: 1.8,
    foot: 'rechts',
    teamMemberSince: '10.09.2020',
    club: 'FC Fulham',
    previousClub: ': Ablöse 3,20 Mio. €',
    uid: '545c87dc-ab0b-4637-8cf0-4d2c56d0e649',
    position: 'Rechter Verteidiger',
    league: 'Premier League',
    marketValue: 12000000,
  },
  {
    jerseyNumber: 12,
    name: 'Cédric Soares',
    dateOfBirth: '31.08.1991',
    nationality: 'Portugal',
    height: 1.71,
    foot: 'rechts',
    teamMemberSince: '31.01.2023',
    club: 'FC Fulham',
    previousClub: 'FC Arsenal',
    uid: '96ef18b0-c98e-4a3b-be4d-93d4b56dc4e3',
    position: 'Rechter Verteidiger',
    league: 'Premier League',
    marketValue: 4000000,
  },
  {
    jerseyNumber: 26,
    name: 'João Palhinha',
    dateOfBirth: '09.07.1995',
    nationality: 'Portugal',
    height: 1.9,
    foot: 'rechts',
    teamMemberSince: '04.07.2022',
    club: 'FC Fulham',
    previousClub: 'Sporting Lissabon',
    uid: '2f60917f-dae9-4287-8f5e-121e8411b9f4',
    position: 'Defensives Mittelfeld',
    league: 'Premier League',
    marketValue: 35000000,
  },
  {
    jerseyNumber: 6,
    name: 'Harrison Reed',
    dateOfBirth: '27.01.1995',
    nationality: 'England',
    height: 1.81,
    foot: 'rechts',
    teamMemberSince: '30.08.2020',
    club: 'FC Fulham',
    previousClub: 'FC Southampton',
    uid: '022cb06e-3a01-4745-8ea1-4b1733bb6e2b',
    position: 'Zentrales Mittelfeld',
    league: 'Premier League',
    marketValue: 15000000,
  },
  {
    jerseyNumber: 28,
    name: 'Sasa Lukic',
    dateOfBirth: '13.08.1996',
    nationality: 'Serbien',
    height: 1.83,
    foot: 'rechts',
    teamMemberSince: '31.01.2023',
    club: 'FC Fulham',
    previousClub: 'FC Turin',
    uid: '9001a7b0-b11e-4356-bb23-efc8336bbdff',
    position: 'Zentrales Mittelfeld',
    league: 'Premier League',
    marketValue: 13000000,
  },
  {
    jerseyNumber: 10,
    name: 'Tom Cairney',
    dateOfBirth: '20.01.1991',
    nationality: 'Schottland',
    height: 1.85,
    foot: 'links',
    teamMemberSince: '01.07.2015',
    club: 'FC Fulham',
    previousClub: 'Blackburn Rovers',
    uid: 'fef369f5-fd70-4117-8bbf-734dcf373e6d',
    position: 'Zentrales Mittelfeld',
    league: 'Premier League',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 35,
    name: 'Tyrese Francois',
    dateOfBirth: '16.07.2000',
    nationality: 'Australien',
    height: 1.73,
    foot: 'links',
    teamMemberSince: '01.07.2021',
    club: 'FC Fulham',
    previousClub: 'FC Fulham U21',
    uid: 'cdf8d119-f1a3-4ad4-9bc6-68709f06983f',
    position: 'Zentrales Mittelfeld',
    league: 'Premier League',
    marketValue: 500000,
  },
  {
    jerseyNumber: 18,
    name: 'Andreas Pereira',
    dateOfBirth: '01.01.1996',
    nationality: 'Brasilien',
    height: 1.78,
    foot: 'rechts',
    teamMemberSince: '11.07.2022',
    club: 'FC Fulham',
    previousClub: 'Manchester United',
    uid: 'cde83820-a0f9-4a66-99ed-e82651ce5714',
    position: 'Offensives Mittelfeld',
    league: 'Premier League',
    marketValue: 16000000,
  },
  {
    jerseyNumber: 14,
    name: 'Bobby De Cordova-Reid',
    dateOfBirth: '02.02.1993',
    nationality: 'Jamaika',
    height: 1.7,
    foot: 'rechts',
    teamMemberSince: '24.01.2020',
    club: 'FC Fulham',
    previousClub: 'Cardiff City',
    uid: '11382968-0345-4c04-bd62-b8612cc0e6f3',
    position: 'Offensives Mittelfeld',
    league: 'Premier League',
    marketValue: 7000000,
  },
  {
    jerseyNumber: 11,
    name: 'Manor Solomon',
    dateOfBirth: '24.07.1999',
    nationality: 'Israel',
    height: 1.7,
    foot: 'rechts',
    teamMemberSince: '25.07.2022',
    club: 'FC Fulham',
    previousClub: 'Shakhtar Donetsk',
    uid: 'bae2cb49-5bca-449b-b724-fa2be83e4fde',
    position: 'Linksaußen',
    league: 'Premier League',
    marketValue: 18000000,
  },
  {
    jerseyNumber: 7,
    name: 'Neeskens Kebano',
    dateOfBirth: '10.03.1992',
    nationality: 'DR Kongo',
    height: 1.7,
    foot: 'rechts',
    teamMemberSince: '26.08.2016',
    club: 'FC Fulham',
    previousClub: 'KRC Genk',
    uid: '630a19a0-0bca-4432-8f72-e5808cea45fe',
    position: 'Linksaußen',
    league: 'Premier League',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 20,
    name: 'Willian',
    dateOfBirth: '09.08.1988',
    nationality: 'Brasilien',
    height: 1.75,
    foot: 'rechts',
    teamMemberSince: '01.09.2022',
    club: 'FC Fulham',
    previousClub: 'Corinthians São Paulo',
    uid: '0158a10c-0a99-4dbd-88e0-a8c2ca4ddab7',
    position: 'Linksaußen',
    league: 'Premier League',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 8,
    name: 'Harry Wilson',
    dateOfBirth: '22.03.1997',
    nationality: 'Wales',
    height: 1.73,
    foot: 'links',
    teamMemberSince: '24.07.2021',
    club: 'FC Fulham',
    previousClub: 'FC Liverpool',
    uid: '5d565d05-d053-4a08-ada6-27f6f7070f59',
    position: 'Rechtsaußen',
    league: 'Premier League',
    marketValue: 13000000,
  },
  {
    jerseyNumber: 21,
    name: 'Daniel James',
    dateOfBirth: '10.11.1997',
    nationality: 'Wales',
    height: 1.71,
    foot: 'rechts',
    teamMemberSince: '01.09.2022',
    club: 'FC Fulham',
    previousClub: 'Leeds United',
    uid: '43a4859d-fae2-4a73-bfde-d04f10d7e960',
    position: 'Rechtsaußen',
    league: 'Premier League',
    marketValue: 13000000,
  },
  {
    jerseyNumber: 9,
    name: 'Aleksandar Mitrović',
    dateOfBirth: '16.09.1994',
    nationality: 'Serbien',
    height: 1.89,
    foot: 'rechts',
    teamMemberSince: '30.07.2018',
    club: 'FC Fulham',
    previousClub: 'Newcastle United',
    uid: 'b91943d3-81a2-4260-bbdb-9cdacf0ce476',
    position: 'Mittelstürmer',
    league: 'Premier League',
    marketValue: 28000000,
  },
  {
    jerseyNumber: 30,
    name: 'Carlos Vinícius',
    dateOfBirth: '25.03.1995',
    nationality: 'Brasilien',
    height: 1.9,
    foot: 'links',
    teamMemberSince: '01.09.2022',
    club: 'FC Fulham',
    previousClub: 'Benfica Lissabon',
    uid: '08adcf5d-0cf0-4334-a4e6-ba66cee8c6bf',
    position: 'Mittelstürmer',
    league: 'Premier League',
    marketValue: 5000000,
  },
  {
    jerseyNumber: 13,
    name: 'Neto',
    dateOfBirth: '19.07.1989',
    nationality: 'Brasilien',
    height: 1.9,
    foot: 'rechts',
    teamMemberSince: '07.08.2022',
    club: 'AFC Bournemouth',
    previousClub: 'FC Barcelona',
    uid: '497c0f9d-26c0-479f-94f2-5368f2110ccb',
    position: 'Torwart',
    league: 'Premier League',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 1,
    name: 'Mark Travers',
    dateOfBirth: '18.05.1999',
    nationality: 'Irland',
    height: 1.91,
    foot: 'rechts',
    teamMemberSince: '01.07.2020',
    club: 'AFC Bournemouth',
    previousClub: 'AFC Bournemouth U21',
    uid: '05ad4797-38f8-40e2-99ba-1d4c43254786',
    position: 'Torwart',
    league: 'Premier League',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 12,
    name: 'Darren Randolph',
    dateOfBirth: '12.05.1987',
    nationality: 'Irland',
    height: 1.87,
    foot: 'rechts',
    teamMemberSince: '26.01.2023',
    club: 'AFC Bournemouth',
    previousClub: 'West Ham United',
    uid: '4a25f73b-06dd-414b-8eb5-295c391d8f47',
    position: 'Torwart',
    league: 'Premier League',
    marketValue: 500000,
  },
  {
    jerseyNumber: 27,
    name: 'Ilya Zabarnyi',
    dateOfBirth: '01.09.2002',
    nationality: 'Ukraine',
    height: 1.89,
    foot: 'rechts',
    teamMemberSince: '31.01.2023',
    club: 'AFC Bournemouth',
    previousClub: 'Dynamo Kiew',
    uid: '987c29ec-7814-4adc-a612-4cebf10eaa8b',
    position: 'Innenverteidiger',
    league: 'Premier League',
    marketValue: 18000000,
  },
  {
    jerseyNumber: 25,
    name: 'Marcos Senesi',
    dateOfBirth: '10.05.1997',
    nationality: 'Argentinien',
    height: 1.85,
    foot: 'links',
    teamMemberSince: '08.08.2022',
    club: 'AFC Bournemouth',
    previousClub: 'Feyenoord Rotterdam',
    uid: 'ff8d5976-524f-4f25-b684-0fd69ae5bd70',
    position: 'Innenverteidiger',
    league: 'Premier League',
    marketValue: 17000000,
  },
  {
    jerseyNumber: 5,
    name: 'Lloyd Kelly',
    dateOfBirth: '06.10.1998',
    nationality: 'England',
    height: 1.9,
    foot: 'links',
    teamMemberSince: '01.07.2019',
    club: 'AFC Bournemouth',
    previousClub: 'Bristol City',
    uid: 'd34f8081-7238-4f32-9434-68e44e951e1b',
    position: 'Innenverteidiger',
    league: 'Premier League',
    marketValue: 15000000,
  },
  {
    jerseyNumber: 6,
    name: 'Chris Mepham',
    dateOfBirth: '05.11.1997',
    nationality: 'Wales',
    height: 1.9,
    foot: 'rechts',
    teamMemberSince: '22.01.2019',
    club: 'AFC Bournemouth',
    previousClub: 'FC Brentford',
    uid: '1b871ca8-aa7c-4875-94a1-9fb57f215e34',
    position: 'Innenverteidiger',
    league: 'Premier League',
    marketValue: 6000000,
  },
  {
    jerseyNumber: 3,
    name: 'Jack Stephens',
    dateOfBirth: '27.01.1994',
    nationality: 'England',
    height: 1.85,
    foot: 'rechts',
    teamMemberSince: '01.09.2022',
    club: 'AFC Bournemouth',
    previousClub: 'FC Southampton',
    uid: 'f43160c3-5893-4808-9410-86aeaa98f149',
    position: 'Innenverteidiger',
    league: 'Premier League',
    marketValue: 5000000,
  },
  {
    jerseyNumber: 18,
    name: 'Matías Viña',
    dateOfBirth: '09.11.1997',
    nationality: 'Uruguay',
    height: 1.75,
    foot: 'links',
    teamMemberSince: '30.01.2023',
    club: 'AFC Bournemouth',
    previousClub: 'AS Rom',
    uid: '4cc49ed7-5d9a-4420-b705-f4fae8a18c1e',
    position: 'Linker Verteidiger',
    league: 'Premier League',
    marketValue: 6000000,
  },
  {
    jerseyNumber: 33,
    name: 'Jordan Zemura',
    dateOfBirth: '14.11.1999',
    nationality: 'Simbabwe',
    height: 1.73,
    foot: 'links',
    teamMemberSince: '01.08.2020',
    club: 'AFC Bournemouth',
    previousClub: 'AFC Bournemouth U21',
    uid: 'ba20a532-b749-4ca7-8df2-7c0682a2be57',
    position: 'Linker Verteidiger',
    league: 'Premier League',
    marketValue: 4000000,
  },
  {
    jerseyNumber: 17,
    name: 'Jack Stacey',
    dateOfBirth: '06.04.1996',
    nationality: 'England',
    height: 1.8,
    foot: 'rechts',
    teamMemberSince: '08.07.2019',
    club: 'AFC Bournemouth',
    previousClub: 'Luton Town',
    uid: '11b75994-9e30-4b1c-8c21-c4f02b436c93',
    position: 'Rechter Verteidiger',
    league: 'Premier League',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 2,
    name: 'Ryan Fredericks',
    dateOfBirth: '10.10.1992',
    nationality: 'England',
    height: 1.81,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'AFC Bournemouth',
    previousClub: 'West Ham United',
    uid: 'b9f127b7-7592-4290-bfe6-79216c62fa87',
    position: 'Rechter Verteidiger',
    league: 'Premier League',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 15,
    name: 'Adam Smith',
    dateOfBirth: '29.04.1991',
    nationality: 'England',
    height: 1.74,
    foot: 'rechts',
    teamMemberSince: '28.01.2014',
    club: 'AFC Bournemouth',
    previousClub: 'Tottenham Hotspur',
    uid: '7439568a-d4ca-4c59-9e4c-73440d654e9f',
    position: 'Rechter Verteidiger',
    league: 'Premier League',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 8,
    name: 'Jefferson Lerma',
    dateOfBirth: '25.10.1994',
    nationality: 'Kolumbien',
    height: 1.79,
    foot: 'rechts',
    teamMemberSince: '07.08.2018',
    club: 'AFC Bournemouth',
    previousClub: 'UD Levante',
    uid: 'a3c175b9-01b0-4ff1-8ffb-2c1760c59ba9',
    position: 'Defensives Mittelfeld',
    league: 'Premier League',
    marketValue: 15000000,
  },
  {
    jerseyNumber: 29,
    name: 'Philip Billing',
    dateOfBirth: '11.06.1996',
    nationality: 'Dänemark',
    height: 1.93,
    foot: 'links',
    teamMemberSince: '29.07.2019',
    club: 'AFC Bournemouth',
    previousClub: 'Huddersfield Town',
    uid: '58d2cf8e-0079-43c3-9719-4fffd840026c',
    position: 'Zentrales Mittelfeld',
    league: 'Premier League',
    marketValue: 18000000,
  },
  {
    jerseyNumber: 4,
    name: 'Lewis Cook',
    dateOfBirth: '03.02.1997',
    nationality: 'England',
    height: 1.75,
    foot: 'rechts',
    teamMemberSince: '08.07.2016',
    club: 'AFC Bournemouth',
    previousClub: 'Leeds United',
    uid: '14a1fa4c-38d0-4c65-9821-80e45af28a3e',
    position: 'Zentrales Mittelfeld',
    league: 'Premier League',
    marketValue: 10000000,
  },
  {
    jerseyNumber: 14,
    name: 'Joe Rothwell',
    dateOfBirth: '11.01.1995',
    nationality: 'England',
    height: 1.85,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'AFC Bournemouth',
    previousClub: 'Blackburn Rovers',
    uid: '6b51ef2a-f1a4-4b56-8456-4cb6f08ff396',
    position: 'Zentrales Mittelfeld',
    league: 'Premier League',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 16,
    name: 'Marcus Tavernier',
    dateOfBirth: '22.03.1999',
    nationality: 'England',
    height: 1.78,
    foot: 'links',
    teamMemberSince: '01.08.2022',
    club: 'AFC Bournemouth',
    previousClub: 'FC Middlesbrough',
    uid: '435ed8b9-805d-42b1-8d82-b1798e690adb',
    position: 'Linkes Mittelfeld',
    league: 'Premier League',
    marketValue: 15000000,
  },
  {
    jerseyNumber: 22,
    name: 'Hamed Junior Traorè',
    dateOfBirth: '16.02.2000',
    nationality: 'Elfenbeinküste',
    height: 1.77,
    foot: 'rechts',
    teamMemberSince: '31.01.2023',
    club: 'AFC Bournemouth',
    previousClub: 'US Sassuolo',
    uid: '26951467-4d5c-4428-b33b-a1b64e99e871',
    position: 'Offensives Mittelfeld',
    league: 'Premier League',
    marketValue: 22000000,
  },
  {
    jerseyNumber: 10,
    name: 'Ryan Christie',
    dateOfBirth: '22.02.1995',
    nationality: 'Schottland',
    height: 1.78,
    foot: 'links',
    teamMemberSince: '31.08.2021',
    club: 'AFC Bournemouth',
    previousClub: 'Celtic Glasgow',
    uid: '4156d9da-e2e2-492b-a1f4-0710ba69fa2a',
    position: 'Offensives Mittelfeld',
    league: 'Premier League',
    marketValue: 9000000,
  },
  {
    jerseyNumber: 32,
    name: 'Jaidon Anthony',
    dateOfBirth: '01.12.1999',
    nationality: 'England',
    height: 1.83,
    foot: 'rechts',
    teamMemberSince: '01.08.2020',
    club: 'AFC Bournemouth',
    previousClub: 'AFC Bournemouth U21',
    uid: '5d6ceab1-00eb-4cda-b017-30c3d7b6d87b',
    position: 'Linksaußen',
    league: 'Premier League',
    marketValue: 5000000,
  },
  {
    jerseyNumber: 19,
    name: 'Junior Stanislas',
    dateOfBirth: '26.11.1989',
    nationality: 'England',
    height: 1.83,
    foot: 'rechts',
    teamMemberSince: '01.07.2014',
    club: 'AFC Bournemouth',
    previousClub: ': Ablöse ablösefrei',
    uid: 'ae3a3000-3fb3-4e2f-977f-6307b897f5ce',
    position: 'Linksaußen',
    league: 'Premier League',
    marketValue: 700000,
  },
  {
    jerseyNumber: 11,
    name: 'Dango Ouattara',
    dateOfBirth: '11.02.2002',
    nationality: 'Burkina Faso',
    height: 1.77,
    foot: 'links',
    teamMemberSince: '19.01.2023',
    club: 'AFC Bournemouth',
    previousClub: 'FC Lorient',
    uid: 'c320d3a2-d28d-413b-b689-0f7dd2c6ca7d',
    position: 'Rechtsaußen',
    league: 'Premier League',
    marketValue: 20000000,
  },
  {
    jerseyNumber: 7,
    name: 'David Brooks',
    dateOfBirth: '08.07.1997',
    nationality: 'Wales',
    height: 1.73,
    foot: 'links',
    teamMemberSince: '01.07.2018',
    club: 'AFC Bournemouth',
    previousClub: 'Sheffield United',
    uid: '3a2f5336-9512-4aee-a946-1959bf316545',
    position: 'Rechtsaußen',
    league: 'Premier League',
    marketValue: 12000000,
  },
  {
    jerseyNumber: 9,
    name: 'Dominic Solanke',
    dateOfBirth: '14.09.1997',
    nationality: 'England',
    height: 1.86,
    foot: 'rechts',
    teamMemberSince: '04.01.2019',
    club: 'AFC Bournemouth',
    previousClub: 'FC Liverpool',
    uid: '5f3b6a38-7f4f-4fb3-a2bc-a1d0c1c02903',
    position: 'Mittelstürmer',
    league: 'Premier League',
    marketValue: 18000000,
  },
  {
    jerseyNumber: 24,
    name: 'Antoine Semenyo',
    dateOfBirth: '07.01.2000',
    nationality: 'Ghana',
    height: 1.85,
    foot: 'rechts',
    teamMemberSince: '27.01.2023',
    club: 'AFC Bournemouth',
    previousClub: 'Bristol City',
    uid: 'cd0e5e46-8005-4f75-bf26-93e07769de53',
    position: 'Mittelstürmer',
    league: 'Premier League',
    marketValue: 9000000,
  },
  {
    jerseyNumber: 21,
    name: 'Kieffer Moore',
    dateOfBirth: '08.08.1992',
    nationality: 'Wales',
    height: 1.96,
    foot: 'rechts',
    teamMemberSince: '31.01.2022',
    club: 'AFC Bournemouth',
    previousClub: 'Cardiff City',
    uid: 'ddf93fcb-5104-4221-a254-ec1f5fe387ba',
    position: 'Mittelstürmer',
    league: 'Premier League',
    marketValue: 4000000,
  },
  {
    jerseyNumber: 1,
    name: 'Manuel Neuer',
    dateOfBirth: '27.03.1986',
    nationality: 'Deutschland',
    height: 1.93,
    foot: 'rechts',
    teamMemberSince: '01.07.2011',
    club: 'FC Bayern München',
    previousClub: 'FC Schalke 04',
    uid: 'ccc70276-fc05-4652-9d47-6f85d340d53a',
    position: 'Torwart',
    league: 'Bundesliga',
    marketValue: 7000000,
  },
  {
    jerseyNumber: 27,
    name: 'Yann Sommer',
    dateOfBirth: '17.12.1988',
    nationality: 'Schweiz',
    height: 1.83,
    foot: 'rechts',
    teamMemberSince: '19.01.2023',
    club: 'FC Bayern München',
    previousClub: 'Borussia Mönchengladbach',
    uid: 'c0ef9145-89a3-450c-a0ff-7fafc4e2e1bc',
    position: 'Torwart',
    league: 'Bundesliga',
    marketValue: 5000000,
  },
  {
    jerseyNumber: 26,
    name: 'Sven Ulreich',
    dateOfBirth: '03.08.1988',
    nationality: 'Deutschland',
    height: 1.92,
    foot: 'rechts',
    teamMemberSince: '01.07.2021',
    club: 'FC Bayern München',
    previousClub: 'Hamburger SV',
    uid: 'e9c49719-4935-43dc-82d5-4723d4c7403c',
    position: 'Torwart',
    league: 'Bundesliga',
    marketValue: 900000,
  },
  {
    jerseyNumber: 4,
    name: 'Matthijs de Ligt',
    dateOfBirth: '12.08.1999',
    nationality: 'Niederlande',
    height: 1.89,
    foot: 'rechts',
    teamMemberSince: '19.07.2022',
    club: 'FC Bayern München',
    previousClub: 'Juventus Turin',
    uid: 'ca165ecd-4778-4889-80fd-10b46711583a',
    position: 'Innenverteidiger',
    league: 'Bundesliga',
    marketValue: 75000000,
  },
  {
    jerseyNumber: 2,
    name: 'Dayot Upamecano',
    dateOfBirth: '27.10.1998',
    nationality: 'Frankreich',
    height: 1.86,
    foot: 'rechts',
    teamMemberSince: '05.07.2021',
    club: 'FC Bayern München',
    previousClub: 'RasenBallsport Leipzig',
    uid: '342797f5-c548-4088-8e60-f7b99af9248f',
    position: 'Innenverteidiger',
    league: 'Bundesliga',
    marketValue: 60000000,
  },
  {
    jerseyNumber: 21,
    name: 'Lucas Hernández',
    dateOfBirth: '14.02.1996',
    nationality: 'Frankreich',
    height: 1.84,
    foot: 'links',
    teamMemberSince: '01.07.2019',
    club: 'FC Bayern München',
    previousClub: 'Atlético Madrid',
    uid: 'f0edfa0c-a548-4f2f-8b88-02c8f8f490de',
    position: 'Innenverteidiger',
    league: 'Bundesliga',
    marketValue: 50000000,
  },
  {
    jerseyNumber: 19,
    name: 'Alphonso Davies',
    dateOfBirth: '02.11.2000',
    nationality: 'Kanada',
    height: 1.85,
    foot: 'links',
    teamMemberSince: '01.01.2019',
    club: 'FC Bayern München',
    previousClub: 'Vancouver Whitecaps FC',
    uid: 'e7fe9cfc-2637-4e61-9dd8-23e4b1245e9c',
    position: 'Linker Verteidiger',
    league: 'Bundesliga',
    marketValue: 70000000,
  },
  {
    jerseyNumber: 23,
    name: 'Daley Blind',
    dateOfBirth: '09.03.1990',
    nationality: 'Niederlande',
    height: 1.8,
    foot: 'links',
    teamMemberSince: '05.01.2023',
    club: 'FC Bayern München',
    previousClub: 'Ajax Amsterdam',
    uid: 'd516d67c-8f94-4360-ad01-2d142a317048',
    position: 'Linker Verteidiger',
    league: 'Bundesliga',
    marketValue: 6000000,
  },
  {
    jerseyNumber: 22,
    name: 'João Cancelo',
    dateOfBirth: '27.05.1994',
    nationality: 'Portugal',
    height: 1.82,
    foot: 'rechts',
    teamMemberSince: '31.01.2023',
    club: 'FC Bayern München',
    previousClub: 'Manchester City',
    uid: '57f09e9d-0130-490a-a979-56e9444c1ee8',
    position: 'Rechter Verteidiger',
    league: 'Bundesliga',
    marketValue: 60000000,
  },
  {
    jerseyNumber: 5,
    name: 'Benjamin Pavard',
    dateOfBirth: '28.03.1996',
    nationality: 'Frankreich',
    height: 1.86,
    foot: 'rechts',
    teamMemberSince: '01.07.2019',
    club: 'FC Bayern München',
    previousClub: 'VfB Stuttgart',
    uid: 'f83ae321-9192-46e0-a996-7b8d2a120df1',
    position: 'Rechter Verteidiger',
    league: 'Bundesliga',
    marketValue: 35000000,
  },
  {
    jerseyNumber: 40,
    name: 'Noussair Mazraoui',
    dateOfBirth: '14.11.1997',
    nationality: 'Marokko',
    height: 1.83,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'FC Bayern München',
    previousClub: 'Ajax Amsterdam',
    uid: 'ab0c1985-9446-454b-8533-5c053ae790ee',
    position: 'Rechter Verteidiger',
    league: 'Bundesliga',
    marketValue: 28000000,
  },
  {
    jerseyNumber: 44,
    name: 'Josip Stanisic',
    dateOfBirth: '02.04.2000',
    nationality: 'Kroatien',
    height: 1.87,
    foot: 'beidfüßig',
    teamMemberSince: '01.08.2021',
    club: 'FC Bayern München',
    previousClub: 'FC Bayern München II',
    uid: '34d5d844-852e-4e95-b78d-39d608f320e5',
    position: 'Rechter Verteidiger',
    league: 'Bundesliga',
    marketValue: 12000000,
  },
  {
    jerseyNumber: 20,
    name: 'Bouna Sarr',
    dateOfBirth: '31.01.1992',
    nationality: 'Senegal',
    height: 1.77,
    foot: 'rechts',
    teamMemberSince: '05.10.2020',
    club: 'FC Bayern München',
    previousClub: 'Olympique Marseille',
    uid: '7b89b6bc-f068-4bb9-8de9-218cd59d84e5',
    position: 'Rechter Verteidiger',
    league: 'Bundesliga',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 6,
    name: 'Joshua Kimmich',
    dateOfBirth: '08.02.1995',
    nationality: 'Deutschland',
    height: 1.77,
    foot: 'rechts',
    teamMemberSince: '02.07.2015',
    club: 'FC Bayern München',
    previousClub: 'VfB Stuttgart',
    uid: 'dfdea9d1-fd5d-4e01-a798-ded3c9a4d72a',
    position: 'Defensives Mittelfeld',
    league: 'Bundesliga',
    marketValue: 80000000,
  },
  {
    jerseyNumber: 8,
    name: 'Leon Goretzka',
    dateOfBirth: '06.02.1995',
    nationality: 'Deutschland',
    height: 1.89,
    foot: 'rechts',
    teamMemberSince: '01.07.2018',
    club: 'FC Bayern München',
    previousClub: 'FC Schalke 04',
    uid: 'b7fa8405-adc2-4132-a723-81774b5b75d8',
    position: 'Zentrales Mittelfeld',
    league: 'Bundesliga',
    marketValue: 65000000,
  },
  {
    jerseyNumber: 38,
    name: 'Ryan Gravenberch',
    dateOfBirth: '16.05.2002',
    nationality: 'Niederlande',
    height: 1.9,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'FC Bayern München',
    previousClub: 'Ajax Amsterdam',
    uid: '7f1c3183-3a3e-4e21-9816-2d1a7ee91b61',
    position: 'Zentrales Mittelfeld',
    league: 'Bundesliga',
    marketValue: 30000000,
  },
  {
    jerseyNumber: 42,
    name: 'Jamal Musiala',
    dateOfBirth: '26.02.2003',
    nationality: 'Deutschland',
    height: 1.84,
    foot: 'rechts',
    teamMemberSince: '01.07.2020',
    club: 'FC Bayern München',
    previousClub: 'FC Bayern München U19',
    uid: '8ebf5aa2-db90-4f54-89b7-700316c4295f',
    position: 'Offensives Mittelfeld',
    league: 'Bundesliga',
    marketValue: 110000000,
  },
  {
    jerseyNumber: 14,
    name: 'Paul Wanner',
    dateOfBirth: '23.12.2005',
    nationality: 'Deutschland',
    height: 1.85,
    foot: 'links',
    teamMemberSince: '31.01.2022',
    club: 'FC Bayern München',
    previousClub: 'FC Bayern München U19',
    uid: '99ec79b5-5243-4291-8bbf-5a7f725c3f8d',
    position: 'Offensives Mittelfeld',
    league: 'Bundesliga',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 46,
    name: 'Arijon Ibrahimovic',
    dateOfBirth: '11.12.2005',
    nationality: 'Deutschland',
    height: 1.8,
    foot: 'rechts',
    teamMemberSince: '17.01.2023',
    club: 'FC Bayern München',
    previousClub: 'FC Bayern München U19',
    uid: 'c8cddd08-c1fd-41a2-a3c8-47d4d6bbf94b',
    position: 'Offensives Mittelfeld',
    league: 'Bundesliga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 11,
    name: 'Kingsley Coman',
    dateOfBirth: '13.06.1996',
    nationality: 'Frankreich',
    height: 1.81,
    foot: 'rechts',
    teamMemberSince: '01.07.2017',
    club: 'FC Bayern München',
    previousClub: ': Ablöse 21,00 Mio. €',
    uid: 'f3c66d3d-0c77-4a94-a59b-0bd1f4876b56',
    position: 'Linksaußen',
    league: 'Bundesliga',
    marketValue: 65000000,
  },
  {
    jerseyNumber: 17,
    name: 'Sadio Mané',
    dateOfBirth: '10.04.1992',
    nationality: 'Senegal',
    height: 1.74,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'FC Bayern München',
    previousClub: 'FC Liverpool',
    uid: 'dbeba139-1e5c-4629-a0a1-b6450b8257ab',
    position: 'Linksaußen',
    league: 'Bundesliga',
    marketValue: 45000000,
  },
  {
    jerseyNumber: 10,
    name: 'Leroy Sané',
    dateOfBirth: '11.01.1996',
    nationality: 'Deutschland',
    height: 1.83,
    foot: 'links',
    teamMemberSince: '15.07.2020',
    club: 'FC Bayern München',
    previousClub: 'Manchester City',
    uid: 'c8b9a78a-a91d-42d0-9820-27f4ca35845b',
    position: 'Rechtsaußen',
    league: 'Bundesliga',
    marketValue: 70000000,
  },
  {
    jerseyNumber: 7,
    name: 'Serge Gnabry',
    dateOfBirth: '14.07.1995',
    nationality: 'Deutschland',
    height: 1.76,
    foot: 'rechts',
    teamMemberSince: '01.07.2017',
    club: 'FC Bayern München',
    previousClub: 'SV Werder Bremen',
    uid: '9fcb4c89-7d5b-40bd-96e2-f23dab68a835',
    position: 'Rechtsaußen',
    league: 'Bundesliga',
    marketValue: 55000000,
  },
  {
    jerseyNumber: 25,
    name: 'Thomas Müller',
    dateOfBirth: '13.09.1989',
    nationality: 'Deutschland',
    height: 1.85,
    foot: 'rechts',
    teamMemberSince: '01.07.2009',
    club: 'FC Bayern München',
    previousClub: ': Ablöse -',
    uid: '4f1d982c-622b-4822-b85e-8213a4c4d82e',
    position: 'Hängende Spitze',
    league: 'Bundesliga',
    marketValue: 18000000,
  },
  {
    jerseyNumber: 39,
    name: 'Mathys Tel',
    dateOfBirth: '27.04.2005',
    nationality: 'Frankreich',
    height: 1.83,
    foot: 'rechts',
    teamMemberSince: '26.07.2022',
    club: 'FC Bayern München',
    previousClub: 'FC Stade Rennes',
    uid: '8725d9f9-09f9-44d4-90e8-27313eebd2a7',
    position: 'Mittelstürmer',
    league: 'Bundesliga',
    marketValue: 20000000,
  },
  {
    jerseyNumber: 13,
    name: 'Eric Maxim Choupo-Moting',
    dateOfBirth: '23.03.1989',
    nationality: 'Kamerun',
    height: 1.91,
    foot: 'rechts',
    teamMemberSince: '05.10.2020',
    club: 'FC Bayern München',
    previousClub: 'FC Paris Saint-Germain',
    uid: 'fda55e2e-2c87-4f24-8cb0-490c248286be',
    position: 'Mittelstürmer',
    league: 'Bundesliga',
    marketValue: 6000000,
  },
  {
    jerseyNumber: 1,
    name: 'Gregor Kobel',
    dateOfBirth: '06.12.1997',
    nationality: 'Schweiz',
    height: 1.94,
    foot: 'rechts',
    teamMemberSince: '01.07.2021',
    club: 'Borussia Dortmund',
    previousClub: 'VfB Stuttgart',
    uid: '16f230c3-a1cc-4a89-b93a-88366cc8d456',
    position: 'Torwart',
    league: 'Bundesliga',
    marketValue: 35000000,
  },
  {
    jerseyNumber: 35,
    name: 'Marcel Lotka',
    dateOfBirth: '25.05.2001',
    nationality: 'Deutschland',
    height: 1.9,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'Borussia Dortmund',
    previousClub: 'Hertha BSC',
    uid: '03658716-079d-488b-9a2d-d6e07ff9e2f6',
    position: 'Torwart',
    league: 'Bundesliga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 33,
    name: 'Alexander Meyer',
    dateOfBirth: '13.04.1991',
    nationality: 'Deutschland',
    height: 1.95,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'Borussia Dortmund',
    previousClub: 'SSV Jahn Regensburg',
    uid: 'c7fa6be9-b324-4662-8090-36f1a52bd676',
    position: 'Torwart',
    league: 'Bundesliga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 38,
    name: 'Luca Unbehaun',
    dateOfBirth: '27.02.2001',
    nationality: 'Deutschland',
    height: 1.85,
    foot: 'rechts',
    teamMemberSince: '01.07.2019',
    club: 'Borussia Dortmund',
    previousClub: 'Borussia Dortmund U19',
    uid: '7edf3645-5154-4cb0-ade7-dc01f5ea5c8e',
    position: 'Torwart',
    league: 'Bundesliga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 4,
    name: 'Nico Schlotterbeck',
    dateOfBirth: '01.12.1999',
    nationality: 'Deutschland',
    height: 1.91,
    foot: 'links',
    teamMemberSince: '01.07.2022',
    club: 'Borussia Dortmund',
    previousClub: 'SC Freiburg',
    uid: '216f966f-da40-4ba5-a990-f8d258e9e08d',
    position: 'Innenverteidiger',
    league: 'Bundesliga',
    marketValue: 40000000,
  },
  {
    jerseyNumber: 25,
    name: 'Niklas Süle',
    dateOfBirth: '03.09.1995',
    nationality: 'Deutschland',
    height: 1.95,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'Borussia Dortmund',
    previousClub: 'FC Bayern München',
    uid: 'a0657f25-2852-45a7-8231-69c7a0f37ef9',
    position: 'Innenverteidiger',
    league: 'Bundesliga',
    marketValue: 35000000,
  },
  {
    jerseyNumber: 15,
    name: 'Mats Hummels',
    dateOfBirth: '16.12.1988',
    nationality: 'Deutschland',
    height: 1.91,
    foot: 'rechts',
    teamMemberSince: '01.07.2019',
    club: 'Borussia Dortmund',
    previousClub: 'FC Bayern München',
    uid: 'aa2c506e-647a-4dd9-9243-1f8900926814',
    position: 'Innenverteidiger',
    league: 'Bundesliga',
    marketValue: 6000000,
  },
  {
    jerseyNumber: 44,
    name: 'Soumaïla Coulibaly',
    dateOfBirth: '14.10.2003',
    nationality: 'Frankreich',
    height: 1.91,
    foot: 'links',
    teamMemberSince: '01.07.2021',
    club: 'Borussia Dortmund',
    previousClub: 'FC Paris Saint-Germain U19',
    uid: 'ad1f3ab4-df9c-4dab-b991-d7a50e0c2c53',
    position: 'Innenverteidiger',
    league: 'Bundesliga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 13,
    name: 'Raphaël Guerreiro',
    dateOfBirth: '22.12.1993',
    nationality: 'Portugal',
    height: 1.7,
    foot: 'links',
    teamMemberSince: '01.07.2016',
    club: 'Borussia Dortmund',
    previousClub: 'FC Lorient',
    uid: '16ffa511-aa5a-44ca-8b9a-53b20085377a',
    position: 'Linker Verteidiger',
    league: 'Bundesliga',
    marketValue: 20000000,
  },
  {
    jerseyNumber: 36,
    name: 'Tom Rothe',
    dateOfBirth: '29.10.2004',
    nationality: 'Deutschland',
    height: 1.93,
    foot: 'links',
    teamMemberSince: '01.07.2022',
    club: 'Borussia Dortmund',
    previousClub: 'Borussia Dortmund U19',
    uid: '16da927f-b53c-4170-9ced-21d9fcd0fc90',
    position: 'Linker Verteidiger',
    league: 'Bundesliga',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 14,
    name: 'Nico Schulz',
    dateOfBirth: '01.04.1993',
    nationality: 'Deutschland',
    height: 1.8,
    foot: 'links',
    teamMemberSince: '01.07.2019',
    club: 'Borussia Dortmund',
    previousClub: 'TSG 1899 Hoffenheim',
    uid: '496ac1ff-2030-4232-a9af-860fedb77fb2',
    position: 'Linker Verteidiger',
    league: 'Bundesliga',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 26,
    name: 'Julian Ryerson',
    dateOfBirth: '17.11.1997',
    nationality: 'Norwegen',
    height: 1.83,
    foot: 'rechts',
    teamMemberSince: '17.01.2023',
    club: 'Borussia Dortmund',
    previousClub: '1.FC Union Berlin',
    uid: '9f2025b1-1e63-42a7-87d6-46560d8ac360',
    position: 'Rechter Verteidiger',
    league: 'Bundesliga',
    marketValue: 10000000,
  },
  {
    jerseyNumber: 17,
    name: 'Marius Wolf',
    dateOfBirth: '27.05.1995',
    nationality: 'Deutschland',
    height: 1.87,
    foot: 'rechts',
    teamMemberSince: '02.07.2018',
    club: 'Borussia Dortmund',
    previousClub: 'Eintracht Frankfurt',
    uid: '298d9392-afae-4ee4-9110-d7c0e5d4f4b4',
    position: 'Rechter Verteidiger',
    league: 'Bundesliga',
    marketValue: 9000000,
  },
  {
    jerseyNumber: 24,
    name: 'Thomas Meunier',
    dateOfBirth: '12.09.1991',
    nationality: 'Belgien',
    height: 1.91,
    foot: 'rechts',
    teamMemberSince: '01.07.2020',
    club: 'Borussia Dortmund',
    previousClub: 'FC Paris Saint-Germain',
    uid: '5743c962-f551-4732-86d0-65887ea39193',
    position: 'Rechter Verteidiger',
    league: 'Bundesliga',
    marketValue: 6000000,
  },
  {
    jerseyNumber: 2,
    name: 'Mateu Morey Bauzà',
    dateOfBirth: '02.03.2000',
    nationality: 'Spanien',
    height: 1.73,
    foot: 'rechts',
    teamMemberSince: '01.07.2019',
    club: 'Borussia Dortmund',
    previousClub: 'FC Barcelona U19',
    uid: '3de4c29c-d100-47f7-b9df-17c9f0fcc039',
    position: 'Rechter Verteidiger',
    league: 'Bundesliga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 30,
    name: 'Felix Passlack',
    dateOfBirth: '29.05.1998',
    nationality: 'Deutschland',
    height: 1.7,
    foot: 'rechts',
    teamMemberSince: '04.01.2016',
    club: 'Borussia Dortmund',
    previousClub: 'Borussia Dortmund U19',
    uid: 'b17c5440-7e00-480b-a10f-60afd56989db',
    position: 'Rechter Verteidiger',
    league: 'Bundesliga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 6,
    name: 'Salih Özcan',
    dateOfBirth: '11.01.1998',
    nationality: 'Türkei',
    height: 1.83,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'Borussia Dortmund',
    previousClub: '1.FC Köln',
    uid: 'c2c09078-90d4-462c-88e2-3af24f8f32dd',
    position: 'Defensives Mittelfeld',
    league: 'Bundesliga',
    marketValue: 17000000,
  },
  {
    jerseyNumber: 23,
    name: 'Emre Can',
    dateOfBirth: '12.01.1994',
    nationality: 'Deutschland',
    height: 1.86,
    foot: 'rechts',
    teamMemberSince: '01.07.2020',
    club: 'Borussia Dortmund',
    previousClub: 'Juventus Turin',
    uid: '585263b8-0b40-4276-bd97-13034873cbc3',
    position: 'Defensives Mittelfeld',
    league: 'Bundesliga',
    marketValue: 14000000,
  },
  {
    jerseyNumber: 22,
    name: 'Jude Bellingham',
    dateOfBirth: '29.06.2003',
    nationality: 'England',
    height: 1.86,
    foot: 'rechts',
    teamMemberSince: '23.07.2020',
    club: 'Borussia Dortmund',
    previousClub: 'Birmingham City',
    uid: 'c1390bbf-8123-43d7-a581-a532b63d61d6',
    position: 'Zentrales Mittelfeld',
    league: 'Bundesliga',
    marketValue: 120000000,
  },
  {
    jerseyNumber: 8,
    name: 'Mahmoud Dahoud',
    dateOfBirth: '01.01.1996',
    nationality: 'Deutschland',
    height: 1.78,
    foot: 'rechts',
    teamMemberSince: '01.07.2017',
    club: 'Borussia Dortmund',
    previousClub: 'Borussia Mönchengladbach',
    uid: '7fddf782-a608-4c1b-a665-450c756d40f4',
    position: 'Zentrales Mittelfeld',
    league: 'Bundesliga',
    marketValue: 12000000,
  },
  {
    jerseyNumber: 19,
    name: 'Julian Brandt',
    dateOfBirth: '02.05.1996',
    nationality: 'Deutschland',
    height: 1.85,
    foot: 'rechts',
    teamMemberSince: '01.07.2019',
    club: 'Borussia Dortmund',
    previousClub: 'Bayer 04 Leverkusen',
    uid: '88e6df76-7b05-4dcd-90df-ab7121fefb6b',
    position: 'Offensives Mittelfeld',
    league: 'Bundesliga',
    marketValue: 40000000,
  },
  {
    jerseyNumber: 7,
    name: 'Giovanni Reyna',
    dateOfBirth: '13.11.2002',
    nationality: 'Vereinigte Staaten',
    height: 1.85,
    foot: 'rechts',
    teamMemberSince: '12.01.2020',
    club: 'Borussia Dortmund',
    previousClub: 'Borussia Dortmund U19',
    uid: '8198b5b7-d770-41c6-bf61-6b49c81c58a1',
    position: 'Offensives Mittelfeld',
    league: 'Bundesliga',
    marketValue: 28000000,
  },
  {
    jerseyNumber: 11,
    name: 'Marco Reus',
    dateOfBirth: '31.05.1989',
    nationality: 'Deutschland',
    height: 1.8,
    foot: 'rechts',
    teamMemberSince: '01.07.2012',
    club: 'Borussia Dortmund',
    previousClub: 'Borussia Mönchengladbach',
    uid: 'd5bcbc1c-ded3-4c4b-95a2-d6de2af3afff',
    position: 'Offensives Mittelfeld',
    league: 'Bundesliga',
    marketValue: 9000000,
  },
  {
    jerseyNumber: 27,
    name: 'Karim Adeyemi',
    dateOfBirth: '18.01.2002',
    nationality: 'Deutschland',
    height: 1.8,
    foot: 'links',
    teamMemberSince: '01.07.2022',
    club: 'Borussia Dortmund',
    previousClub: 'Red Bull Salzburg',
    uid: 'b7d09f69-6ffd-4574-9922-c9f8167d8832',
    position: 'Linksaußen',
    league: 'Bundesliga',
    marketValue: 35000000,
  },
  {
    jerseyNumber: 43,
    name: 'Jamie Bynoe-Gittens',
    dateOfBirth: '08.08.2004',
    nationality: 'England',
    height: 1.77,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'Borussia Dortmund',
    previousClub: 'Borussia Dortmund U19',
    uid: '7aedb9e9-1004-4fd1-95e3-be201eaa12f5',
    position: 'Linksaußen',
    league: 'Bundesliga',
    marketValue: 14000000,
  },
  {
    jerseyNumber: 21,
    name: 'Donyell Malen',
    dateOfBirth: '19.01.1999',
    nationality: 'Niederlande',
    height: 1.78,
    foot: 'rechts',
    teamMemberSince: '27.07.2021',
    club: 'Borussia Dortmund',
    previousClub: 'PSV Eindhoven',
    uid: '2493b13a-55c5-423f-9181-843d266b5879',
    position: 'Rechtsaußen',
    league: 'Bundesliga',
    marketValue: 16000000,
  },
  {
    jerseyNumber: 16,
    name: 'Julien Duranville',
    dateOfBirth: '05.05.2006',
    nationality: 'Belgien',
    height: 1.7,
    foot: 'rechts',
    teamMemberSince: '27.01.2023',
    club: 'Borussia Dortmund',
    previousClub: 'RSC Anderlecht',
    uid: '2e3faf96-7904-4aa9-948b-92385347a9b3',
    position: 'Rechtsaußen',
    league: 'Bundesliga',
    marketValue: 5000000,
  },
  {
    jerseyNumber: 9,
    name: 'Sébastien Haller',
    dateOfBirth: '22.06.1994',
    nationality: 'Elfenbeinküste',
    height: 1.91,
    foot: 'rechts',
    teamMemberSince: '06.07.2022',
    club: 'Borussia Dortmund',
    previousClub: 'Ajax Amsterdam',
    uid: '649fbfa2-97d8-4414-a6b4-684f9b7c4eaa',
    position: 'Mittelstürmer',
    league: 'Bundesliga',
    marketValue: 30000000,
  },
  {
    jerseyNumber: 18,
    name: 'Youssoufa Moukoko',
    dateOfBirth: '20.11.2004',
    nationality: 'Deutschland',
    height: 1.79,
    foot: 'links',
    teamMemberSince: '29.07.2020',
    club: 'Borussia Dortmund',
    previousClub: 'Borussia Dortmund U19',
    uid: '83afe55d-2405-402c-8671-329d06d5d201',
    position: 'Mittelstürmer',
    league: 'Bundesliga',
    marketValue: 30000000,
  },
  {
    jerseyNumber: 20,
    name: 'Anthony Modeste',
    dateOfBirth: '14.04.1988',
    nationality: 'Frankreich',
    height: 1.88,
    foot: 'rechts',
    teamMemberSince: '08.08.2022',
    club: 'Borussia Dortmund',
    previousClub: '1.FC Köln',
    uid: 'd00446c0-e508-4ab4-884d-0fa87369ab87',
    position: 'Mittelstürmer',
    league: 'Bundesliga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 1,
    name: 'Péter Gulácsi',
    dateOfBirth: '06.05.1990',
    nationality: 'Ungarn',
    height: 1.9,
    foot: 'rechts',
    teamMemberSince: '01.07.2015',
    club: 'RasenBallsport Leipzig',
    previousClub: ': Ablöse 3,00 Mio. €',
    uid: 'bc0489f5-15c9-43a2-9e38-fc2f881ac442',
    position: 'Torwart',
    league: 'Bundesliga',
    marketValue: 6000000,
  },
  {
    jerseyNumber: 21,
    name: 'Janis Blaswich',
    dateOfBirth: '02.05.1991',
    nationality: 'Deutschland',
    height: 1.93,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'RasenBallsport Leipzig',
    previousClub: 'Heracles Almelo',
    uid: 'b74c8fb8-0676-460f-a316-b11dc11c0343',
    position: 'Torwart',
    league: 'Bundesliga',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 13,
    name: 'Örjan Nyland',
    dateOfBirth: '10.09.1990',
    nationality: 'Norwegen',
    height: 1.92,
    foot: 'rechts',
    teamMemberSince: '09.10.2022',
    club: 'RasenBallsport Leipzig',
    previousClub: 'Vereinslos',
    uid: '7101bed4-7092-46c3-8d1e-a7901ec72693',
    position: 'Torwart',
    league: 'Bundesliga',
    marketValue: 600000,
  },
  {
    jerseyNumber: 32,
    name: 'Josko Gvardiol',
    dateOfBirth: '23.01.2002',
    nationality: 'Kroatien',
    height: 1.85,
    foot: 'links',
    teamMemberSince: '01.07.2021',
    club: 'RasenBallsport Leipzig',
    previousClub: 'GNK Dinamo Zagreb',
    uid: '92bfb3cd-396a-484d-88b0-acfae295b8e2',
    position: 'Innenverteidiger',
    league: 'Bundesliga',
    marketValue: 75000000,
  },
  {
    jerseyNumber: 2,
    name: 'Mohamed Simakan',
    dateOfBirth: '03.05.2000',
    nationality: 'Frankreich',
    height: 1.87,
    foot: 'rechts',
    teamMemberSince: '01.07.2021',
    club: 'RasenBallsport Leipzig',
    previousClub: 'RC Straßburg Alsace',
    uid: '31ab3d33-e78c-46e3-914e-fcd119aee73e',
    position: 'Innenverteidiger',
    league: 'Bundesliga',
    marketValue: 28000000,
  },
  {
    jerseyNumber: 37,
    name: 'Abdou Diallo',
    dateOfBirth: '04.05.1996',
    nationality: 'Senegal',
    height: 1.87,
    foot: 'links',
    teamMemberSince: '01.09.2022',
    club: 'RasenBallsport Leipzig',
    previousClub: 'FC Paris Saint-Germain',
    uid: '895ed441-3e53-4fae-8f00-9b807e17533a',
    position: 'Innenverteidiger',
    league: 'Bundesliga',
    marketValue: 15000000,
  },
  {
    jerseyNumber: 4,
    name: 'Willi Orbán',
    dateOfBirth: '03.11.1992',
    nationality: 'Ungarn',
    height: 1.86,
    foot: 'rechts',
    teamMemberSince: '01.07.2015',
    club: 'RasenBallsport Leipzig',
    previousClub: '1.FC Kaiserslautern',
    uid: '363f107f-9f4a-4d70-ac9a-52e78873107a',
    position: 'Innenverteidiger',
    league: 'Bundesliga',
    marketValue: 10000000,
  },
  {
    jerseyNumber: 22,
    name: 'David Raum',
    dateOfBirth: '22.04.1998',
    nationality: 'Deutschland',
    height: 1.8,
    foot: 'links',
    teamMemberSince: '31.07.2022',
    club: 'RasenBallsport Leipzig',
    previousClub: 'TSG 1899 Hoffenheim',
    uid: '7ceba1ca-883a-4ed5-add6-c4b586f1babd',
    position: 'Linker Verteidiger',
    league: 'Bundesliga',
    marketValue: 20000000,
  },
  {
    jerseyNumber: 23,
    name: 'Marcel Halstenberg',
    dateOfBirth: '27.09.1991',
    nationality: 'Deutschland',
    height: 1.88,
    foot: 'links',
    teamMemberSince: '31.08.2015',
    club: 'RasenBallsport Leipzig',
    previousClub: 'FC St. Pauli',
    uid: 'cf15c44f-9b66-4837-9424-f1cecadaab87',
    position: 'Linker Verteidiger',
    league: 'Bundesliga',
    marketValue: 4000000,
  },
  {
    jerseyNumber: 25,
    name: 'Sanoussy Ba',
    dateOfBirth: '05.01.2004',
    nationality: 'Deutschland',
    height: 1.84,
    foot: 'beidfüßig',
    teamMemberSince: '01.07.2022',
    club: 'RasenBallsport Leipzig',
    previousClub: 'RasenBallsport Leipzig U19',
    uid: 'd9d9fe32-ae18-46ee-b003-7ff11b329300',
    position: 'Linker Verteidiger',
    league: 'Bundesliga',
    marketValue: 500000,
  },
  {
    jerseyNumber: 39,
    name: 'Benjamin Henrichs',
    dateOfBirth: '23.02.1997',
    nationality: 'Deutschland',
    height: 1.85,
    foot: 'rechts',
    teamMemberSince: '01.07.2021',
    club: 'RasenBallsport Leipzig',
    previousClub: 'AS Monaco',
    uid: '402f01eb-84ad-4500-a9ec-6070002e999f',
    position: 'Rechter Verteidiger',
    league: 'Bundesliga',
    marketValue: 15000000,
  },
  {
    jerseyNumber: 16,
    name: 'Lukas Klostermann',
    dateOfBirth: '03.06.1996',
    nationality: 'Deutschland',
    height: 1.89,
    foot: 'rechts',
    teamMemberSince: '22.08.2014',
    club: 'RasenBallsport Leipzig',
    previousClub: 'VfL Bochum',
    uid: 'bafe3e88-2be6-4b49-b590-81b251b7d813',
    position: 'Rechter Verteidiger',
    league: 'Bundesliga',
    marketValue: 12000000,
  },
  {
    jerseyNumber: 27,
    name: 'Konrad Laimer',
    dateOfBirth: '27.05.1997',
    nationality: 'Österreich',
    height: 1.8,
    foot: 'rechts',
    teamMemberSince: '01.07.2017',
    club: 'RasenBallsport Leipzig',
    previousClub: ': Ablöse 7,00 Mio. €',
    uid: 'cf75ef9f-6aeb-425c-b42b-9873c952be67',
    position: 'Zentrales Mittelfeld',
    league: 'Bundesliga',
    marketValue: 28000000,
  },
  {
    jerseyNumber: 24,
    name: 'Xaver Schlager',
    dateOfBirth: '28.09.1997',
    nationality: 'Österreich',
    height: 1.74,
    foot: 'links',
    teamMemberSince: '01.07.2022',
    club: 'RasenBallsport Leipzig',
    previousClub: 'VfL Wolfsburg',
    uid: '80fbbbc3-c4e5-463b-8a37-fe448b99c9bf',
    position: 'Zentrales Mittelfeld',
    league: 'Bundesliga',
    marketValue: 22000000,
  },
  {
    jerseyNumber: 8,
    name: 'Amadou Haidara',
    dateOfBirth: '31.01.1998',
    nationality: 'Mali',
    height: 1.75,
    foot: 'rechts',
    teamMemberSince: '01.01.2019',
    club: 'RasenBallsport Leipzig',
    previousClub: ': Ablöse 19,00 Mio. €',
    uid: '9cb9ddbf-2e80-4936-a954-b6b0806b5a32',
    position: 'Zentrales Mittelfeld',
    league: 'Bundesliga',
    marketValue: 17000000,
  },
  {
    jerseyNumber: 44,
    name: 'Kevin Kampl',
    dateOfBirth: '09.10.1990',
    nationality: 'Slowenien',
    height: 1.78,
    foot: 'rechts',
    teamMemberSince: '31.08.2017',
    club: 'RasenBallsport Leipzig',
    previousClub: 'Bayer 04 Leverkusen',
    uid: '07da0f82-4258-42cc-9e73-74ef21507443',
    position: 'Zentrales Mittelfeld',
    league: 'Bundesliga',
    marketValue: 5000000,
  },
  {
    jerseyNumber: 7,
    name: 'Dani Olmo',
    dateOfBirth: '07.05.1998',
    nationality: 'Spanien',
    height: 1.79,
    foot: 'rechts',
    teamMemberSince: '25.01.2020',
    club: 'RasenBallsport Leipzig',
    previousClub: 'GNK Dinamo Zagreb',
    uid: '2a002fd8-ead2-4c1b-93cf-55e38cb9e37e',
    position: 'Offensives Mittelfeld',
    league: 'Bundesliga',
    marketValue: 40000000,
  },
  {
    jerseyNumber: 17,
    name: 'Dominik Szoboszlai',
    dateOfBirth: '25.10.2000',
    nationality: 'Ungarn',
    height: 1.87,
    foot: 'rechts',
    teamMemberSince: '01.01.2021',
    club: 'RasenBallsport Leipzig',
    previousClub: 'Red Bull Salzburg',
    uid: '034bcc2a-d62a-499a-b1ff-e3fb85ab3ee0',
    position: 'Offensives Mittelfeld',
    league: 'Bundesliga',
    marketValue: 40000000,
  },
  {
    jerseyNumber: 10,
    name: 'Emil Forsberg',
    dateOfBirth: '23.10.1991',
    nationality: 'Schweden',
    height: 1.77,
    foot: 'rechts',
    teamMemberSince: '07.01.2015',
    club: 'RasenBallsport Leipzig',
    previousClub: 'Malmö FF',
    uid: '8b99b59b-5abe-4ae8-bcc9-f2f0c00e55b3',
    position: 'Offensives Mittelfeld',
    league: 'Bundesliga',
    marketValue: 9000000,
  },
  {
    jerseyNumber: 28,
    name: 'Caden Clark',
    dateOfBirth: '27.05.2003',
    nationality: 'Vereinigte Staaten',
    height: 1.8,
    foot: 'rechts',
    teamMemberSince: '01.07.2021',
    club: 'RasenBallsport Leipzig',
    previousClub: 'New York Red Bulls',
    uid: '995d2d4f-71db-407b-af88-acd8c32fe30f',
    position: 'Offensives Mittelfeld',
    league: 'Bundesliga',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 18,
    name: 'Christopher Nkunku',
    dateOfBirth: '14.11.1997',
    nationality: 'Frankreich',
    height: 1.78,
    foot: 'rechts',
    teamMemberSince: '18.07.2019',
    club: 'RasenBallsport Leipzig',
    previousClub: 'FC Paris Saint-Germain',
    uid: '8f4d2dfa-b648-48c8-9e13-c5f36f257460',
    position: 'Hängende Spitze',
    league: 'Bundesliga',
    marketValue: 80000000,
  },
  {
    jerseyNumber: 11,
    name: 'Timo Werner',
    dateOfBirth: '06.03.1996',
    nationality: 'Deutschland',
    height: 1.8,
    foot: 'rechts',
    teamMemberSince: '09.08.2022',
    club: 'RasenBallsport Leipzig',
    previousClub: 'FC Chelsea',
    uid: '3ad77b3e-e2a7-4fb2-ac00-0081903857b1',
    position: 'Mittelstürmer',
    league: 'Bundesliga',
    marketValue: 25000000,
  },
  {
    jerseyNumber: 19,
    name: 'André Silva',
    dateOfBirth: '06.11.1995',
    nationality: 'Portugal',
    height: 1.85,
    foot: 'rechts',
    teamMemberSince: '02.07.2021',
    club: 'RasenBallsport Leipzig',
    previousClub: 'Eintracht Frankfurt',
    uid: '5f9132bc-868d-4150-a6b6-9e7166bb60f2',
    position: 'Mittelstürmer',
    league: 'Bundesliga',
    marketValue: 20000000,
  },
  {
    jerseyNumber: 9,
    name: 'Yussuf Poulsen',
    dateOfBirth: '15.06.1994',
    nationality: 'Dänemark',
    height: 1.92,
    foot: 'rechts',
    teamMemberSince: '04.07.2013',
    club: 'RasenBallsport Leipzig',
    previousClub: 'Lyngby BK',
    uid: '26acd6d4-e192-4bc5-b81b-510e2dfe8388',
    position: 'Mittelstürmer',
    league: 'Bundesliga',
    marketValue: 10000000,
  },
  {
    jerseyNumber: 1,
    name: 'Lukas Hradecky',
    dateOfBirth: '24.11.1989',
    nationality: 'Finnland',
    height: 1.92,
    foot: 'rechts',
    teamMemberSince: '01.07.2018',
    club: 'Bayer 04 Leverkusen',
    previousClub: 'Eintracht Frankfurt',
    uid: '4b772281-7b54-4fcf-aa18-3b494c38722a',
    position: 'Torwart',
    league: 'Bundesliga',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 28,
    name: 'Patrick Pentz',
    dateOfBirth: '02.01.1997',
    nationality: 'Österreich',
    height: 1.82,
    foot: 'links',
    teamMemberSince: '27.01.2023',
    club: 'Bayer 04 Leverkusen',
    previousClub: 'Stade Reims',
    uid: '5bc6ec6f-02cc-42fc-b7a3-c47c71a0e023',
    position: 'Torwart',
    league: 'Bundesliga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 40,
    name: 'Andrey Lunev',
    dateOfBirth: '13.11.1991',
    nationality: 'Russland',
    height: 1.9,
    foot: 'rechts',
    teamMemberSince: '10.07.2021',
    club: 'Bayer 04 Leverkusen',
    previousClub: 'Zenit St. Petersburg',
    uid: '0e7e356a-f73d-43b4-96be-c07709092f51',
    position: 'Torwart',
    league: 'Bundesliga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 36,
    name: 'Niklas Lomb',
    dateOfBirth: '28.07.1993',
    nationality: 'Deutschland',
    height: 1.86,
    foot: 'rechts',
    teamMemberSince: '01.07.2012',
    club: 'Bayer 04 Leverkusen',
    previousClub: 'Bayer 04 Leverkusen U19',
    uid: 'f970e61f-5c2c-42cb-a553-f9af13e6d766',
    position: 'Torwart',
    league: 'Bundesliga',
    marketValue: 250000,
  },
  {
    jerseyNumber: 12,
    name: 'Edmond Tapsoba',
    dateOfBirth: '02.02.1999',
    nationality: 'Burkina Faso',
    height: 1.94,
    foot: 'rechts',
    teamMemberSince: '31.01.2020',
    club: 'Bayer 04 Leverkusen',
    previousClub: 'Vitória Guimarães SC',
    uid: '49323c64-bdd1-44dc-a8aa-56bbd0c3cdff',
    position: 'Innenverteidiger',
    league: 'Bundesliga',
    marketValue: 30000000,
  },
  {
    jerseyNumber: 3,
    name: 'Piero Hincapié',
    dateOfBirth: '09.01.2002',
    nationality: 'Ecuador',
    height: 1.83,
    foot: 'links',
    teamMemberSince: '16.08.2021',
    club: 'Bayer 04 Leverkusen',
    previousClub: 'Club Atlético Talleres',
    uid: '85ac3c38-4f3c-4d05-9bfe-20e7c82fab90',
    position: 'Innenverteidiger',
    league: 'Bundesliga',
    marketValue: 25000000,
  },
  {
    jerseyNumber: 4,
    name: 'Jonathan Tah',
    dateOfBirth: '11.02.1996',
    nationality: 'Deutschland',
    height: 1.95,
    foot: 'rechts',
    teamMemberSince: '16.07.2015',
    club: 'Bayer 04 Leverkusen',
    previousClub: ': Ablöse 7,50 Mio. €',
    uid: '18f017ce-1492-4cbc-b8d1-b34b051d6181',
    position: 'Innenverteidiger',
    league: 'Bundesliga',
    marketValue: 20000000,
  },
  {
    jerseyNumber: 6,
    name: 'Odilon Kossounou',
    dateOfBirth: '04.01.2001',
    nationality: 'Elfenbeinküste',
    height: 1.91,
    foot: 'rechts',
    teamMemberSince: '22.07.2021',
    club: 'Bayer 04 Leverkusen',
    previousClub: 'FC Brügge',
    uid: '82f91a2c-bf1d-4bfc-904d-3466145ad268',
    position: 'Innenverteidiger',
    league: 'Bundesliga',
    marketValue: 20000000,
  },
  {
    jerseyNumber: 5,
    name: 'Mitchel Bakker',
    dateOfBirth: '20.06.2000',
    nationality: 'Niederlande',
    height: 1.85,
    foot: 'links',
    teamMemberSince: '12.07.2021',
    club: 'Bayer 04 Leverkusen',
    previousClub: 'FC Paris Saint-Germain',
    uid: '4f60ee3b-2afd-4ae6-8972-6945cf57c831',
    position: 'Linker Verteidiger',
    league: 'Bundesliga',
    marketValue: 9000000,
  },
  {
    jerseyNumber: 22,
    name: 'Daley Sinkgraven',
    dateOfBirth: '04.07.1995',
    nationality: 'Niederlande',
    height: 1.79,
    foot: 'links',
    teamMemberSince: '01.07.2019',
    club: 'Bayer 04 Leverkusen',
    previousClub: 'Ajax Amsterdam',
    uid: '85ae29ca-da14-4bf9-9d67-eea3f8b4313f',
    position: 'Linker Verteidiger',
    league: 'Bundesliga',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 30,
    name: 'Jeremie Frimpong',
    dateOfBirth: '10.12.2000',
    nationality: 'Niederlande',
    height: 1.71,
    foot: 'rechts',
    teamMemberSince: '27.01.2021',
    club: 'Bayer 04 Leverkusen',
    previousClub: 'Celtic Glasgow',
    uid: '0ec1d8ff-66df-438a-99e1-5a30e90adc9c',
    position: 'Rechter Verteidiger',
    league: 'Bundesliga',
    marketValue: 35000000,
  },
  {
    jerseyNumber: 24,
    name: 'Timothy Fosu-Mensah',
    dateOfBirth: '02.01.1998',
    nationality: 'Niederlande',
    height: 1.85,
    foot: 'rechts',
    teamMemberSince: '13.01.2021',
    club: 'Bayer 04 Leverkusen',
    previousClub: 'Manchester United',
    uid: 'c8e33313-d3b2-4c0f-b5ae-5afca3c8683a',
    position: 'Rechter Verteidiger',
    league: 'Bundesliga',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 8,
    name: 'Robert Andrich',
    dateOfBirth: '22.09.1994',
    nationality: 'Deutschland',
    height: 1.87,
    foot: 'rechts',
    teamMemberSince: '16.08.2021',
    club: 'Bayer 04 Leverkusen',
    previousClub: '1.FC Union Berlin',
    uid: '9962b3f9-54ce-4f68-969e-5fc44e1847a6',
    position: 'Defensives Mittelfeld',
    league: 'Bundesliga',
    marketValue: 11000000,
  },
  {
    jerseyNumber: 18,
    name: 'Noah Mbamba',
    dateOfBirth: '05.01.2005',
    nationality: 'Belgien',
    height: 1.87,
    foot: 'rechts',
    teamMemberSince: '14.01.2023',
    club: 'Bayer 04 Leverkusen',
    previousClub: 'FC Brügge',
    uid: 'bd60374f-6976-44ed-a6d0-0c423a09ec86',
    position: 'Defensives Mittelfeld',
    league: 'Bundesliga',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 35,
    name: 'Joshua Eze',
    dateOfBirth: '20.03.2003',
    nationality: 'Deutschland',
    height: 1.83,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'Bayer 04 Leverkusen',
    previousClub: 'Bayer 04 Leverkusen U19',
    uid: '53ccd713-c82e-442c-9add-cd21fa23f3f5',
    position: 'Defensives Mittelfeld',
    league: 'Bundesliga',
    marketValue: 200000,
  },
  {
    jerseyNumber: 25,
    name: 'Exequiel Palacios',
    dateOfBirth: '05.10.1998',
    nationality: 'Argentinien',
    height: 1.77,
    foot: 'rechts',
    teamMemberSince: '01.01.2020',
    club: 'Bayer 04 Leverkusen',
    previousClub: ': Ablöse 17,00 Mio. €',
    uid: 'f080cc28-3644-4da8-af77-7b273b10bfcb',
    position: 'Zentrales Mittelfeld',
    league: 'Bundesliga',
    marketValue: 15000000,
  },
  {
    jerseyNumber: 10,
    name: 'Kerem Demirbay',
    dateOfBirth: '03.07.1993',
    nationality: 'Deutschland',
    height: 1.83,
    foot: 'links',
    teamMemberSince: '01.07.2019',
    club: 'Bayer 04 Leverkusen',
    previousClub: 'TSG 1899 Hoffenheim',
    uid: '2b628124-e351-43a0-934c-c013d34b5411',
    position: 'Zentrales Mittelfeld',
    league: 'Bundesliga',
    marketValue: 9000000,
  },
  {
    jerseyNumber: 11,
    name: 'Nadiem Amiri',
    dateOfBirth: '27.10.1996',
    nationality: 'Deutschland',
    height: 1.8,
    foot: 'rechts',
    teamMemberSince: '30.07.2019',
    club: 'Bayer 04 Leverkusen',
    previousClub: 'TSG 1899 Hoffenheim',
    uid: '1709c023-8d7e-4fda-81d3-61d32f1be909',
    position: 'Zentrales Mittelfeld',
    league: 'Bundesliga',
    marketValue: 6000000,
  },
  {
    jerseyNumber: 27,
    name: 'Florian Wirtz',
    dateOfBirth: '03.05.2003',
    nationality: 'Deutschland',
    height: 1.76,
    foot: 'rechts',
    teamMemberSince: '01.07.2020',
    club: 'Bayer 04 Leverkusen',
    previousClub: 'Bayer 04 Leverkusen U19',
    uid: '04abf038-a217-482f-98d5-25f7f10c79f1',
    position: 'Offensives Mittelfeld',
    league: 'Bundesliga',
    marketValue: 85000000,
  },
  {
    jerseyNumber: 17,
    name: 'Callum Hudson-Odoi',
    dateOfBirth: '07.11.2000',
    nationality: 'England',
    height: 1.77,
    foot: 'rechts',
    teamMemberSince: '30.08.2022',
    club: 'Bayer 04 Leverkusen',
    previousClub: 'FC Chelsea',
    uid: 'f5b0e916-553a-48e5-b273-8f0e26fe6642',
    position: 'Linksaußen',
    league: 'Bundesliga',
    marketValue: 17000000,
  },
  {
    jerseyNumber: 21,
    name: 'Amine Adli',
    dateOfBirth: '10.05.2000',
    nationality: 'Frankreich',
    height: 1.74,
    foot: 'links',
    teamMemberSince: '26.08.2021',
    club: 'Bayer 04 Leverkusen',
    previousClub: 'FC Toulouse',
    uid: '36bf5b90-067c-4086-84ba-c111c4e928fe',
    position: 'Linksaußen',
    league: 'Bundesliga',
    marketValue: 15000000,
  },
  {
    jerseyNumber: 19,
    name: 'Moussa Diaby',
    dateOfBirth: '07.07.1999',
    nationality: 'Frankreich',
    height: 1.7,
    foot: 'links',
    teamMemberSince: '01.07.2019',
    club: 'Bayer 04 Leverkusen',
    previousClub: 'FC Paris Saint-Germain',
    uid: 'f9897164-b1c7-492d-8b7b-7b2478a840f3',
    position: 'Rechtsaußen',
    league: 'Bundesliga',
    marketValue: 50000000,
  },
  {
    jerseyNumber: 38,
    name: 'Karim Bellarabi',
    dateOfBirth: '08.04.1990',
    nationality: 'Deutschland',
    height: 1.84,
    foot: 'rechts',
    teamMemberSince: '01.07.2011',
    club: 'Bayer 04 Leverkusen',
    previousClub: ': Ablöse ablösefrei',
    uid: '963ef27c-621b-4585-a5cd-5ae0c029063c',
    position: 'Rechtsaußen',
    league: 'Bundesliga',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 14,
    name: 'Patrik Schick',
    dateOfBirth: '24.01.1996',
    nationality: 'Tschechien',
    height: 1.91,
    foot: 'links',
    teamMemberSince: '08.09.2020',
    club: 'Bayer 04 Leverkusen',
    previousClub: 'AS Rom',
    uid: '0f33ac94-48c3-4a4a-8614-14ebde9105fb',
    position: 'Mittelstürmer',
    league: 'Bundesliga',
    marketValue: 30000000,
  },
  {
    jerseyNumber: 23,
    name: 'Adam Hlozek',
    dateOfBirth: '25.07.2002',
    nationality: 'Tschechien',
    height: 1.88,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'Bayer 04 Leverkusen',
    previousClub: 'AC Sparta Prag',
    uid: '1260735d-676e-46af-8ed2-4c7913a80cc5',
    position: 'Mittelstürmer',
    league: 'Bundesliga',
    marketValue: 15000000,
  },
  {
    jerseyNumber: 9,
    name: 'Sardar Azmoun',
    dateOfBirth: '01.01.1995',
    nationality: 'Iran',
    height: 1.86,
    foot: 'beidfüßig',
    teamMemberSince: '30.01.2022',
    club: 'Bayer 04 Leverkusen',
    previousClub: 'Zenit St. Petersburg',
    uid: 'f35f6017-4b4b-4882-ae12-58b393e8d7d3',
    position: 'Mittelstürmer',
    league: 'Bundesliga',
    marketValue: 12000000,
  },
  {
    jerseyNumber: 1,
    name: 'Kevin Trapp',
    dateOfBirth: '08.07.1990',
    nationality: 'Deutschland',
    height: 1.89,
    foot: 'rechts',
    teamMemberSince: '07.08.2019',
    club: 'Eintracht Frankfurt',
    previousClub: 'FC Paris Saint-Germain',
    uid: 'b072877c-a65f-433e-a6d8-d7c8ecf9c6f3',
    position: 'Torwart',
    league: 'Bundesliga',
    marketValue: 8000000,
  },
  {
    jerseyNumber: 40,
    name: 'Diant Ramaj',
    dateOfBirth: '19.09.2001',
    nationality: 'Deutschland',
    height: 1.89,
    foot: 'beidfüßig',
    teamMemberSince: '01.07.2021',
    club: 'Eintracht Frankfurt',
    previousClub: '1.FC Heidenheim 1846',
    uid: '2faaa348-0496-40f1-afc5-65f801b92646',
    position: 'Torwart',
    league: 'Bundesliga',
    marketValue: 750000,
  },
  {
    jerseyNumber: 31,
    name: 'Jens Grahl',
    dateOfBirth: '22.09.1988',
    nationality: 'Deutschland',
    height: 1.92,
    foot: 'rechts',
    teamMemberSince: '19.07.2021',
    club: 'Eintracht Frankfurt',
    previousClub: 'VfB Stuttgart',
    uid: '6c61324f-13c9-44be-978d-8bfb42bd6e4c',
    position: 'Torwart',
    league: 'Bundesliga',
    marketValue: 200000,
  },
  {
    jerseyNumber: 41,
    name: 'Simon Simoni',
    dateOfBirth: '14.07.2004',
    nationality: 'Albanien',
    height: 1.94,
    foot: 'rechts',
    teamMemberSince: '01.01.2023',
    club: 'Eintracht Frankfurt',
    previousClub: 'FK Dinamo',
    uid: '9e17f2b5-3b53-49e1-9e0c-a1fed500a73a',
    position: 'Torwart',
    league: 'Bundesliga',
    marketValue: 200000,
  },
  {
    jerseyNumber: 2,
    name: 'Evan Ndicka',
    dateOfBirth: '20.08.1999',
    nationality: 'Frankreich',
    height: 1.92,
    foot: 'links',
    teamMemberSince: '05.07.2018',
    club: 'Eintracht Frankfurt',
    previousClub: 'AJ Auxerre',
    uid: 'e1a06003-d9a9-4d6c-be84-76cd5b651fda',
    position: 'Innenverteidiger',
    league: 'Bundesliga',
    marketValue: 32000000,
  },
  {
    jerseyNumber: 35,
    name: 'Tuta',
    dateOfBirth: '04.07.1999',
    nationality: 'Brasilien',
    height: 1.85,
    foot: 'rechts',
    teamMemberSince: '31.01.2019',
    club: 'Eintracht Frankfurt',
    previousClub: 'FC São Paulo U20',
    uid: '71d40656-ec04-4f01-ab3e-cb30fc16679b',
    position: 'Innenverteidiger',
    league: 'Bundesliga',
    marketValue: 15000000,
  },
  {
    jerseyNumber: 5,
    name: 'Hrvoje Smolcic',
    dateOfBirth: '17.08.2000',
    nationality: 'Kroatien',
    height: 1.85,
    foot: 'links',
    teamMemberSince: '01.07.2022',
    club: 'Eintracht Frankfurt',
    previousClub: 'HNK Rijeka',
    uid: 'd1e1a3e9-f1d4-4109-b526-7012f05b9039',
    position: 'Innenverteidiger',
    league: 'Bundesliga',
    marketValue: 5000000,
  },
  {
    jerseyNumber: 18,
    name: 'Almamy Touré',
    dateOfBirth: '28.04.1996',
    nationality: 'Mali',
    height: 1.83,
    foot: 'rechts',
    teamMemberSince: '31.01.2019',
    club: 'Eintracht Frankfurt',
    previousClub: 'AS Monaco',
    uid: '2a8faaf3-8b35-43ca-9750-9972f6e6d5c6',
    position: 'Innenverteidiger',
    league: 'Bundesliga',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 20,
    name: 'Makoto Hasebe',
    dateOfBirth: '18.01.1984',
    nationality: 'Japan',
    height: 1.8,
    foot: 'rechts',
    teamMemberSince: '01.07.2014',
    club: 'Eintracht Frankfurt',
    previousClub: '1.FC Nürnberg',
    uid: '57d96781-8aff-4545-a9c2-d45dd5dc1472',
    position: 'Innenverteidiger',
    league: 'Bundesliga',
    marketValue: 800000,
  },
  {
    jerseyNumber: 32,
    name: 'Philipp Max',
    dateOfBirth: '30.09.1993',
    nationality: 'Deutschland',
    height: 1.77,
    foot: 'links',
    teamMemberSince: '31.01.2023',
    club: 'Eintracht Frankfurt',
    previousClub: 'PSV Eindhoven',
    uid: '4b45a197-9e77-4a6a-a785-66934f77bfe1',
    position: 'Linker Verteidiger',
    league: 'Bundesliga',
    marketValue: 6000000,
  },
  {
    jerseyNumber: 25,
    name: 'Christopher Lenz',
    dateOfBirth: '22.09.1994',
    nationality: 'Deutschland',
    height: 1.8,
    foot: 'links',
    teamMemberSince: '01.07.2021',
    club: 'Eintracht Frankfurt',
    previousClub: '1.FC Union Berlin',
    uid: 'ef26950f-9737-4771-9c7d-505ccfdb66c8',
    position: 'Linker Verteidiger',
    league: 'Bundesliga',
    marketValue: 5000000,
  },
  {
    jerseyNumber: 49,
    name: 'Jan Schröder',
    dateOfBirth: '15.04.2003',
    nationality: 'Deutschland',
    height: 1.82,
    foot: 'links',
    teamMemberSince: '20.08.2021',
    club: 'Eintracht Frankfurt',
    previousClub: 'Eintracht Frankfurt U19',
    uid: '34f7d2c8-861b-4251-9be7-120a3ee8fd43',
    position: 'Linker Verteidiger',
    league: 'Bundesliga',
    marketValue: 200000,
  },
  {
    jerseyNumber: 24,
    name: 'Aurélio Buta',
    dateOfBirth: '10.02.1997',
    nationality: 'Portugal',
    height: 1.72,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'Eintracht Frankfurt',
    previousClub: 'Royal Antwerpen FC',
    uid: '62818c4a-6046-4263-97d4-84642b8c8969',
    position: 'Rechter Verteidiger',
    league: 'Bundesliga',
    marketValue: 6000000,
  },
  {
    jerseyNumber: 22,
    name: 'Timothy Chandler',
    dateOfBirth: '29.03.1990',
    nationality: 'Vereinigte Staaten',
    height: 1.86,
    foot: 'rechts',
    teamMemberSince: '01.07.2014',
    club: 'Eintracht Frankfurt',
    previousClub: '1.FC Nürnberg',
    uid: '7cd23897-48d7-43e5-8f8f-99d27751b9ed',
    position: 'Rechter Verteidiger',
    league: 'Bundesliga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 6,
    name: 'Kristijan Jakic',
    dateOfBirth: '14.05.1997',
    nationality: 'Kroatien',
    height: 1.81,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'Eintracht Frankfurt',
    previousClub: 'GNK Dinamo Zagreb',
    uid: 'fb43c634-567d-434a-9c61-13dd9a36cecb',
    position: 'Defensives Mittelfeld',
    league: 'Bundesliga',
    marketValue: 9000000,
  },
  {
    jerseyNumber: 8,
    name: 'Djibril Sow',
    dateOfBirth: '06.02.1997',
    nationality: 'Schweiz',
    height: 1.84,
    foot: 'rechts',
    teamMemberSince: '01.07.2019',
    club: 'Eintracht Frankfurt',
    previousClub: 'BSC Young Boys',
    uid: 'f5fed255-0f0e-452d-aa1a-b16906fc4a85',
    position: 'Zentrales Mittelfeld',
    league: 'Bundesliga',
    marketValue: 22000000,
  },
  {
    jerseyNumber: 17,
    name: 'Sebastian Rode',
    dateOfBirth: '11.10.1990',
    nationality: 'Deutschland',
    height: 1.79,
    foot: 'rechts',
    teamMemberSince: '27.07.2019',
    club: 'Eintracht Frankfurt',
    previousClub: 'Borussia Dortmund',
    uid: 'de741a4f-3588-42b4-bea2-17d47835c3a4',
    position: 'Zentrales Mittelfeld',
    league: 'Bundesliga',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 28,
    name: 'Marcel Wenig',
    dateOfBirth: '04.05.2004',
    nationality: 'Deutschland',
    height: 1.88,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'Eintracht Frankfurt',
    previousClub: 'FC Bayern München U19',
    uid: '96dcd490-0e92-4eba-ae0b-11a313fe6437',
    position: 'Zentrales Mittelfeld',
    league: 'Bundesliga',
    marketValue: 800000,
  },
  {
    jerseyNumber: 26,
    name: 'Junior Dina Ebimbe',
    dateOfBirth: '21.11.2000',
    nationality: 'Frankreich',
    height: 1.84,
    foot: 'rechts',
    teamMemberSince: '21.08.2022',
    club: 'Eintracht Frankfurt',
    previousClub: 'FC Paris Saint-Germain',
    uid: '9bbbad54-92bc-4925-9580-4990830bd945',
    position: 'Rechtes Mittelfeld',
    league: 'Bundesliga',
    marketValue: 7000000,
  },
  {
    jerseyNumber: 15,
    name: 'Daichi Kamada',
    dateOfBirth: '05.08.1996',
    nationality: 'Japan',
    height: 1.84,
    foot: 'rechts',
    teamMemberSince: '01.07.2017',
    club: 'Eintracht Frankfurt',
    previousClub: 'Sagan Tosu',
    uid: '01e30b36-32b8-42e0-a347-25784ed17a9d',
    position: 'Offensives Mittelfeld',
    league: 'Bundesliga',
    marketValue: 30000000,
  },
  {
    jerseyNumber: 29,
    name: 'Jesper Lindström',
    dateOfBirth: '29.02.2000',
    nationality: 'Dänemark',
    height: 1.82,
    foot: 'rechts',
    teamMemberSince: '11.07.2021',
    club: 'Eintracht Frankfurt',
    previousClub: 'Bröndby IF',
    uid: 'a62eac0b-4599-4db9-8958-95109b24f1be',
    position: 'Offensives Mittelfeld',
    league: 'Bundesliga',
    marketValue: 28000000,
  },
  {
    jerseyNumber: 27,
    name: 'Mario Götze',
    dateOfBirth: '03.06.1992',
    nationality: 'Deutschland',
    height: 1.76,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'Eintracht Frankfurt',
    previousClub: 'PSV Eindhoven',
    uid: '0282194a-4f07-44e9-a1c0-660ea3fe4698',
    position: 'Offensives Mittelfeld',
    league: 'Bundesliga',
    marketValue: 13000000,
  },
  {
    jerseyNumber: 30,
    name: 'Paxten Aaronson',
    dateOfBirth: '26.08.2003',
    nationality: 'Vereinigte Staaten',
    height: 1.75,
    foot: 'rechts',
    teamMemberSince: '01.01.2023',
    club: 'Eintracht Frankfurt',
    previousClub: 'Philadelphia Union',
    uid: '7f83e6e2-6094-4d90-96c6-577ca6a6f246',
    position: 'Offensives Mittelfeld',
    league: 'Bundesliga',
    marketValue: 4000000,
  },
  {
    jerseyNumber: 11,
    name: 'Faride Alidou',
    dateOfBirth: '18.07.2001',
    nationality: 'Deutschland',
    height: 1.86,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'Eintracht Frankfurt',
    previousClub: 'Hamburger SV',
    uid: '7be048d7-c42a-4c51-892c-aa6299db4f5a',
    position: 'Linksaußen',
    league: 'Bundesliga',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 36,
    name: 'Ansgar Knauff',
    dateOfBirth: '10.01.2002',
    nationality: 'Deutschland',
    height: 1.8,
    foot: 'rechts',
    teamMemberSince: '20.01.2022',
    club: 'Eintracht Frankfurt',
    previousClub: 'Borussia Dortmund',
    uid: '5da1ac72-1e03-4d26-b415-99f2f2183eac',
    position: 'Rechtsaußen',
    league: 'Bundesliga',
    marketValue: 10000000,
  },
  {
    jerseyNumber: 9,
    name: 'Randal Kolo Muani',
    dateOfBirth: '05.12.1998',
    nationality: 'Frankreich',
    height: 1.87,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'Eintracht Frankfurt',
    previousClub: 'FC Nantes',
    uid: 'cf8749fa-a6b1-4657-87c8-4e999b31b584',
    position: 'Mittelstürmer',
    league: 'Bundesliga',
    marketValue: 65000000,
  },
  {
    jerseyNumber: 19,
    name: 'Rafael Borré',
    dateOfBirth: '15.09.1995',
    nationality: 'Kolumbien',
    height: 1.74,
    foot: 'rechts',
    teamMemberSince: '05.07.2021',
    club: 'Eintracht Frankfurt',
    previousClub: ': Ablöse ablösefrei',
    uid: 'a11b1c60-3787-4413-a1c4-1732c88295f3',
    position: 'Mittelstürmer',
    league: 'Bundesliga',
    marketValue: 12000000,
  },
  {
    jerseyNumber: 21,
    name: 'Lucas Alario',
    dateOfBirth: '08.10.1992',
    nationality: 'Argentinien',
    height: 1.85,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'Eintracht Frankfurt',
    previousClub: 'Bayer 04 Leverkusen',
    uid: 'e3a5e5dd-d073-4b7d-9d6a-1462e4c64d88',
    position: 'Mittelstürmer',
    league: 'Bundesliga',
    marketValue: 4000000,
  },
  {
    jerseyNumber: 1,
    name: 'Jonas Omlin',
    dateOfBirth: '10.01.1994',
    nationality: 'Schweiz',
    height: 1.9,
    foot: 'rechts',
    teamMemberSince: '19.01.2023',
    club: 'Borussia Mönchengladbach',
    previousClub: 'HSC Montpellier',
    uid: 'edb8bfc3-d99d-493f-8b81-320145c5de30',
    position: 'Torwart',
    league: 'Bundesliga',
    marketValue: 7000000,
  },
  {
    jerseyNumber: 41,
    name: 'Jan Olschowsky',
    dateOfBirth: '18.11.2001',
    nationality: 'Deutschland',
    height: 1.84,
    foot: 'rechts',
    teamMemberSince: '13.07.2020',
    club: 'Borussia Mönchengladbach',
    previousClub: 'Borussia Mönchengladbach II',
    uid: '96e4c568-6765-4e0b-bb8a-5281498f6dbb',
    position: 'Torwart',
    league: 'Bundesliga',
    marketValue: 500000,
  },
  {
    jerseyNumber: 21,
    name: 'Tobias Sippel',
    dateOfBirth: '22.03.1988',
    nationality: 'Deutschland',
    height: 1.83,
    foot: 'rechts',
    teamMemberSince: '01.07.2015',
    club: 'Borussia Mönchengladbach',
    previousClub: '1.FC Kaiserslautern',
    uid: '1bc06c82-6bcf-4532-b953-d76e12c9db55',
    position: 'Torwart',
    league: 'Bundesliga',
    marketValue: 400000,
  },
  {
    jerseyNumber: 30,
    name: 'Nico Elvedi',
    dateOfBirth: '30.09.1996',
    nationality: 'Schweiz',
    height: 1.89,
    foot: 'rechts',
    teamMemberSince: '01.07.2015',
    club: 'Borussia Mönchengladbach',
    previousClub: ': Ablöse 4,00 Mio. €',
    uid: 'e44d5fb2-e1f0-445e-ab64-cfac1fd24385',
    position: 'Innenverteidiger',
    league: 'Bundesliga',
    marketValue: 20000000,
  },
  {
    jerseyNumber: 3,
    name: 'Ko Itakura',
    dateOfBirth: '27.01.1997',
    nationality: 'Japan',
    height: 1.88,
    foot: 'rechts',
    teamMemberSince: '02.07.2022',
    club: 'Borussia Mönchengladbach',
    previousClub: 'Manchester City',
    uid: '472a725f-505e-4eca-9fb0-b75aefdc088a',
    position: 'Innenverteidiger',
    league: 'Bundesliga',
    marketValue: 12000000,
  },
  {
    jerseyNumber: 5,
    name: 'Marvin Friedrich',
    dateOfBirth: '13.12.1995',
    nationality: 'Deutschland',
    height: 1.93,
    foot: 'rechts',
    teamMemberSince: '11.01.2022',
    club: 'Borussia Mönchengladbach',
    previousClub: '1.FC Union Berlin',
    uid: 'c0a82ed8-2d09-4b1b-88a6-f329bcfcca5a',
    position: 'Innenverteidiger',
    league: 'Bundesliga',
    marketValue: 5000000,
  },
  {
    jerseyNumber: 24,
    name: 'Tony Jantschke',
    dateOfBirth: '07.04.1990',
    nationality: 'Deutschland',
    height: 1.77,
    foot: 'rechts',
    teamMemberSince: '01.07.2009',
    club: 'Borussia Mönchengladbach',
    previousClub: 'Borussia Mönchengladbach U19',
    uid: '0143f8de-72e8-485d-b09a-cfffdf3915a0',
    position: 'Innenverteidiger',
    league: 'Bundesliga',
    marketValue: 800000,
  },
  {
    jerseyNumber: 4,
    name: 'Mamadou Doucouré',
    dateOfBirth: '21.05.1998',
    nationality: 'Frankreich',
    height: 1.83,
    foot: 'links',
    teamMemberSince: '01.07.2016',
    club: 'Borussia Mönchengladbach',
    previousClub: 'FC Paris Saint-Germain B',
    uid: '116e2a27-135a-4a3a-b016-fbb25223fbb2',
    position: 'Innenverteidiger',
    league: 'Bundesliga',
    marketValue: 100000,
  },
  {
    jerseyNumber: 25,
    name: 'Ramy Bensebaini',
    dateOfBirth: '16.04.1995',
    nationality: 'Algerien',
    height: 1.87,
    foot: 'links',
    teamMemberSince: '14.08.2019',
    club: 'Borussia Mönchengladbach',
    previousClub: 'FC Stade Rennes',
    uid: 'e49a4eba-0bc4-4c2d-bf34-947a760a34c0',
    position: 'Linker Verteidiger',
    league: 'Bundesliga',
    marketValue: 20000000,
  },
  {
    jerseyNumber: 20,
    name: 'Luca Netz',
    dateOfBirth: '15.05.2003',
    nationality: 'Deutschland',
    height: 1.84,
    foot: 'links',
    teamMemberSince: '06.08.2021',
    club: 'Borussia Mönchengladbach',
    previousClub: 'Hertha BSC',
    uid: '226ad403-58df-4ab0-b9b6-f70c1b484859',
    position: 'Linker Verteidiger',
    league: 'Bundesliga',
    marketValue: 6000000,
  },
  {
    jerseyNumber: 29,
    name: 'Joe Scally',
    dateOfBirth: '31.12.2002',
    nationality: 'Vereinigte Staaten',
    height: 1.84,
    foot: 'rechts',
    teamMemberSince: '01.01.2021',
    club: 'Borussia Mönchengladbach',
    previousClub: 'New York City FC',
    uid: '7cf74680-fc70-4381-8841-04326987d858',
    position: 'Rechter Verteidiger',
    league: 'Bundesliga',
    marketValue: 12000000,
  },
  {
    jerseyNumber: 18,
    name: 'Stefan Lainer',
    dateOfBirth: '27.08.1992',
    nationality: 'Österreich',
    height: 1.75,
    foot: 'rechts',
    teamMemberSince: '01.07.2019',
    club: 'Borussia Mönchengladbach',
    previousClub: 'Red Bull Salzburg',
    uid: '95c7e693-2d6d-4bbe-bb16-55a4deb59e9e',
    position: 'Rechter Verteidiger',
    league: 'Bundesliga',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 8,
    name: 'Julian Weigl',
    dateOfBirth: '08.09.1995',
    nationality: 'Deutschland',
    height: 1.86,
    foot: 'rechts',
    teamMemberSince: '01.09.2022',
    club: 'Borussia Mönchengladbach',
    previousClub: 'Benfica Lissabon',
    uid: 'b4cb8a87-078d-4235-bdf5-e14fae2f7860',
    position: 'Defensives Mittelfeld',
    league: 'Bundesliga',
    marketValue: 15000000,
  },
  {
    jerseyNumber: 6,
    name: 'Christoph Kramer',
    dateOfBirth: '19.02.1991',
    nationality: 'Deutschland',
    height: 1.91,
    foot: 'rechts',
    teamMemberSince: '01.07.2016',
    club: 'Borussia Mönchengladbach',
    previousClub: 'Bayer 04 Leverkusen',
    uid: '71859cfc-92a2-45c3-89e8-9a1f1fbe6f33',
    position: 'Defensives Mittelfeld',
    league: 'Bundesliga',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 17,
    name: 'Manu Koné',
    dateOfBirth: '17.05.2001',
    nationality: 'Frankreich',
    height: 1.85,
    foot: 'rechts',
    teamMemberSince: '21.01.2021',
    club: 'Borussia Mönchengladbach',
    previousClub: 'FC Toulouse',
    uid: '8eb23f4a-b3aa-4f72-bf02-6ef91cb5397f',
    position: 'Zentrales Mittelfeld',
    league: 'Bundesliga',
    marketValue: 30000000,
  },
  {
    jerseyNumber: 32,
    name: 'Florian Neuhaus',
    dateOfBirth: '16.03.1997',
    nationality: 'Deutschland',
    height: 1.85,
    foot: 'rechts',
    teamMemberSince: '01.07.2017',
    club: 'Borussia Mönchengladbach',
    previousClub: 'TSV 1860 München',
    uid: '7d873ae7-c728-418d-af51-363537dc366b',
    position: 'Zentrales Mittelfeld',
    league: 'Bundesliga',
    marketValue: 15000000,
  },
  {
    jerseyNumber: 22,
    name: 'Oscar Fraulo',
    dateOfBirth: '06.12.2003',
    nationality: 'Dänemark',
    height: 1.8,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'Borussia Mönchengladbach',
    previousClub: 'FC Midtjylland',
    uid: '2c657495-8560-40e8-b58f-2f0121bd7ef0',
    position: 'Zentrales Mittelfeld',
    league: 'Bundesliga',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 13,
    name: 'Lars Stindl',
    dateOfBirth: '26.08.1988',
    nationality: 'Deutschland',
    height: 1.81,
    foot: 'rechts',
    teamMemberSince: '01.07.2015',
    club: 'Borussia Mönchengladbach',
    previousClub: ': Ablöse 3,00 Mio. €',
    uid: '718e5a21-1d66-41f2-b1de-f78eb907758f',
    position: 'Offensives Mittelfeld',
    league: 'Bundesliga',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 34,
    name: 'Conor Noß',
    dateOfBirth: '01.01.2001',
    nationality: 'Deutschland',
    height: 1.81,
    foot: 'beidfüßig',
    teamMemberSince: '01.07.2021',
    club: 'Borussia Mönchengladbach',
    previousClub: 'Borussia Mönchengladbach II',
    uid: '0d18bd72-d92c-46f1-bb97-b314eefae457',
    position: 'Offensives Mittelfeld',
    league: 'Bundesliga',
    marketValue: 700000,
  },
  {
    jerseyNumber: 14,
    name: 'Alassane Plea',
    dateOfBirth: '10.03.1993',
    nationality: 'Frankreich',
    height: 1.81,
    foot: 'rechts',
    teamMemberSince: '13.07.2018',
    club: 'Borussia Mönchengladbach',
    previousClub: 'OGC Nizza',
    uid: '10c6695f-6b06-4464-ad99-9a56e3942473',
    position: 'Linksaußen',
    league: 'Bundesliga',
    marketValue: 10000000,
  },
  {
    jerseyNumber: 11,
    name: 'Hannes Wolf',
    dateOfBirth: '16.04.1999',
    nationality: 'Österreich',
    height: 1.79,
    foot: 'links',
    teamMemberSince: '01.07.2021',
    club: 'Borussia Mönchengladbach',
    previousClub: 'RasenBallsport Leipzig',
    uid: '39f2c9bd-a9fd-4cd9-9588-e247b399bf7a',
    position: 'Linksaußen',
    league: 'Bundesliga',
    marketValue: 4000000,
  },
  {
    jerseyNumber: 38,
    name: 'Yvandro Borges Sanches',
    dateOfBirth: '24.05.2004',
    nationality: 'Luxemburg',
    height: 1.75,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'Borussia Mönchengladbach',
    previousClub: 'Borussia Mönchengladbach U19',
    uid: 'b09bfe5e-d5be-428c-93bd-ba51e9d0f1a3',
    position: 'Linksaußen',
    league: 'Bundesliga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 23,
    name: 'Jonas Hofmann',
    dateOfBirth: '14.07.1992',
    nationality: 'Deutschland',
    height: 1.76,
    foot: 'rechts',
    teamMemberSince: '01.01.2016',
    club: 'Borussia Mönchengladbach',
    previousClub: 'Borussia Dortmund',
    uid: 'd8bfebcd-93df-4c6a-90be-77dbf58e0663',
    position: 'Rechtsaußen',
    league: 'Bundesliga',
    marketValue: 13000000,
  },
  {
    jerseyNumber: 19,
    name: 'Nathan Ngoumou',
    dateOfBirth: '14.03.2000',
    nationality: 'Frankreich',
    height: 1.83,
    foot: 'rechts',
    teamMemberSince: '30.08.2022',
    club: 'Borussia Mönchengladbach',
    previousClub: 'FC Toulouse',
    uid: 'bece2edd-40a6-4da5-86df-4ab8b6a68343',
    position: 'Rechtsaußen',
    league: 'Bundesliga',
    marketValue: 5000000,
  },
  {
    jerseyNumber: 7,
    name: 'Patrick Herrmann',
    dateOfBirth: '12.02.1991',
    nationality: 'Deutschland',
    height: 1.79,
    foot: 'rechts',
    teamMemberSince: '01.01.2010',
    club: 'Borussia Mönchengladbach',
    previousClub: 'Borussia Mönchengladbach U19',
    uid: '888601c5-949d-4e1f-b8fa-41ac70d8a471',
    position: 'Rechtsaußen',
    league: 'Bundesliga',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 10,
    name: 'Marcus Thuram',
    dateOfBirth: '06.08.1997',
    nationality: 'Frankreich',
    height: 1.92,
    foot: 'rechts',
    teamMemberSince: '22.07.2019',
    club: 'Borussia Mönchengladbach',
    previousClub: 'EA Guingamp',
    uid: '87d635cd-4ca3-4322-b021-2e28fa5fed84',
    position: 'Mittelstürmer',
    league: 'Bundesliga',
    marketValue: 32000000,
  },
  {
    jerseyNumber: 1,
    name: 'Koen Casteels',
    dateOfBirth: '25.06.1992',
    nationality: 'Belgien',
    height: 1.97,
    foot: 'links',
    teamMemberSince: '21.01.2015',
    club: 'VfL Wolfsburg',
    previousClub: 'TSG 1899 Hoffenheim',
    uid: '90a89d9d-10ce-4149-9a92-22f00a875cf7',
    position: 'Torwart',
    league: 'Bundesliga',
    marketValue: 8000000,
  },
  {
    jerseyNumber: 12,
    name: 'Pavao Pervan',
    dateOfBirth: '13.11.1987',
    nationality: 'Österreich',
    height: 1.94,
    foot: 'beidfüßig',
    teamMemberSince: '01.07.2018',
    club: 'VfL Wolfsburg',
    previousClub: ': Ablöse 500 Tsd. €',
    uid: '9885d327-955e-4c0a-aa9f-0ba033624cb8',
    position: 'Torwart',
    league: 'Bundesliga',
    marketValue: 400000,
  },
  {
    jerseyNumber: 30,
    name: 'Niklas Klinger',
    dateOfBirth: '13.10.1995',
    nationality: 'Deutschland',
    height: 1.87,
    foot: 'rechts',
    teamMemberSince: '01.07.2019',
    club: 'VfL Wolfsburg',
    previousClub: 'VfL Wolfsburg II',
    uid: '785ccdc4-a8e9-4a4c-b4fd-d63a282fc250',
    position: 'Torwart',
    league: 'Bundesliga',
    marketValue: 100000,
  },
  {
    jerseyNumber: 4,
    name: 'Maxence Lacroix',
    dateOfBirth: '06.04.2000',
    nationality: 'Frankreich',
    height: 1.9,
    foot: 'rechts',
    teamMemberSince: '25.08.2020',
    club: 'VfL Wolfsburg',
    previousClub: 'FC Sochaux-Montbéliard',
    uid: 'b18ff983-6a25-4124-9da0-e45fea014bc0',
    position: 'Innenverteidiger',
    league: 'Bundesliga',
    marketValue: 20000000,
  },
  {
    jerseyNumber: 5,
    name: 'Micky van de Ven',
    dateOfBirth: '19.04.2001',
    nationality: 'Niederlande',
    height: 1.93,
    foot: 'links',
    teamMemberSince: '31.08.2021',
    club: 'VfL Wolfsburg',
    previousClub: 'FC Volendam',
    uid: '12cd1b34-7917-4946-ac6c-a81d8008c0e0',
    position: 'Innenverteidiger',
    league: 'Bundesliga',
    marketValue: 18000000,
  },
  {
    jerseyNumber: 3,
    name: 'Sebastiaan Bornauw',
    dateOfBirth: '22.03.1999',
    nationality: 'Belgien',
    height: 1.91,
    foot: 'rechts',
    teamMemberSince: '16.07.2021',
    club: 'VfL Wolfsburg',
    previousClub: '1.FC Köln',
    uid: '2e21ecfd-59e2-4267-8692-30b2253a60ee',
    position: 'Innenverteidiger',
    league: 'Bundesliga',
    marketValue: 8000000,
  },
  {
    jerseyNumber: 6,
    name: 'Paulo Otávio',
    dateOfBirth: '23.11.1994',
    nationality: 'Brasilien',
    height: 1.73,
    foot: 'links',
    teamMemberSince: '01.07.2019',
    club: 'VfL Wolfsburg',
    previousClub: 'FC Ingolstadt 04',
    uid: 'f9aad52f-57de-43f1-9dc7-87a403762604',
    position: 'Linker Verteidiger',
    league: 'Bundesliga',
    marketValue: 6000000,
  },
  {
    jerseyNumber: 8,
    name: 'Nicolas Cozza',
    dateOfBirth: '08.01.1999',
    nationality: 'Frankreich',
    height: 1.78,
    foot: 'links',
    teamMemberSince: '25.01.2023',
    club: 'VfL Wolfsburg',
    previousClub: 'HSC Montpellier',
    uid: 'bc5df8d2-1f93-435f-9476-f0b3d1c7706d',
    position: 'Linker Verteidiger',
    league: 'Bundesliga',
    marketValue: 5000000,
  },
  {
    jerseyNumber: 20,
    name: 'Ridle Baku',
    dateOfBirth: '08.04.1998',
    nationality: 'Deutschland',
    height: 1.76,
    foot: 'rechts',
    teamMemberSince: '01.10.2020',
    club: 'VfL Wolfsburg',
    previousClub: '1.FSV Mainz 05',
    uid: 'e2eb18ad-e8dd-444f-a0e7-a34223ac8274',
    position: 'Rechter Verteidiger',
    league: 'Bundesliga',
    marketValue: 13000000,
  },
  {
    jerseyNumber: 2,
    name: 'Kilian Fischer',
    dateOfBirth: '12.10.2000',
    nationality: 'Deutschland',
    height: 1.82,
    foot: 'beidfüßig',
    teamMemberSince: '01.07.2022',
    club: 'VfL Wolfsburg',
    previousClub: '1.FC Nürnberg',
    uid: 'e99891e4-a167-4b62-907a-7d99140f7f97',
    position: 'Rechter Verteidiger',
    league: 'Bundesliga',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 38,
    name: 'Bartol Franjic',
    dateOfBirth: '14.01.2000',
    nationality: 'Kroatien',
    height: 1.88,
    foot: 'links',
    teamMemberSince: '01.07.2022',
    club: 'VfL Wolfsburg',
    previousClub: 'GNK Dinamo Zagreb',
    uid: '9cd01672-a0ea-4b55-89d1-cb4e6760b442',
    position: 'Defensives Mittelfeld',
    league: 'Bundesliga',
    marketValue: 4000000,
  },
  {
    jerseyNumber: 29,
    name: 'Josuha Guilavogui',
    dateOfBirth: '19.09.1990',
    nationality: 'Frankreich',
    height: 1.88,
    foot: 'rechts',
    teamMemberSince: '01.07.2016',
    club: 'VfL Wolfsburg',
    previousClub: ': Ablöse 3,00 Mio. €',
    uid: '4cab481c-af5c-49c6-9d49-be5c32cc98d1',
    position: 'Defensives Mittelfeld',
    league: 'Bundesliga',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 27,
    name: 'Maximilian Arnold',
    dateOfBirth: '27.05.1994',
    nationality: 'Deutschland',
    height: 1.84,
    foot: 'links',
    teamMemberSince: '01.01.2012',
    club: 'VfL Wolfsburg',
    previousClub: 'VfL Wolfsburg U19',
    uid: 'f8ea2d2e-6fba-4c27-9940-ec95c93cd688',
    position: 'Zentrales Mittelfeld',
    league: 'Bundesliga',
    marketValue: 17000000,
  },
  {
    jerseyNumber: 32,
    name: 'Mattias Svanberg',
    dateOfBirth: '05.01.1999',
    nationality: 'Schweden',
    height: 1.85,
    foot: 'rechts',
    teamMemberSince: '16.07.2022',
    club: 'VfL Wolfsburg',
    previousClub: 'FC Bologna',
    uid: 'fa198497-5201-4736-9d0e-e1626a57275f',
    position: 'Zentrales Mittelfeld',
    league: 'Bundesliga',
    marketValue: 15000000,
  },
  {
    jerseyNumber: 31,
    name: 'Yannick Gerhardt',
    dateOfBirth: '13.03.1994',
    nationality: 'Deutschland',
    height: 1.84,
    foot: 'links',
    teamMemberSince: '01.07.2016',
    club: 'VfL Wolfsburg',
    previousClub: '1.FC Köln',
    uid: 'baa4979e-5265-42d9-b5e2-0ec36851d76c',
    position: 'Zentrales Mittelfeld',
    league: 'Bundesliga',
    marketValue: 7000000,
  },
  {
    jerseyNumber: 40,
    name: 'Kevin Paredes',
    dateOfBirth: '07.05.2003',
    nationality: 'Vereinigte Staaten',
    height: 1.7,
    foot: 'links',
    teamMemberSince: '28.01.2022',
    club: 'VfL Wolfsburg',
    previousClub: 'D.C. United',
    uid: '24196c53-e52c-4cf8-84cd-b386ed385461',
    position: 'Linkes Mittelfeld',
    league: 'Bundesliga',
    marketValue: 5000000,
  },
  {
    jerseyNumber: 22,
    name: 'Felix Nmecha',
    dateOfBirth: '10.10.2000',
    nationality: 'Deutschland',
    height: 1.9,
    foot: 'rechts',
    teamMemberSince: '21.07.2021',
    club: 'VfL Wolfsburg',
    previousClub: 'Manchester City U23',
    uid: '3e866ac0-5514-4fb6-ac08-0bf7dc3ec3ea',
    position: 'Offensives Mittelfeld',
    league: 'Bundesliga',
    marketValue: 8000000,
  },
  {
    jerseyNumber: 16,
    name: 'Jakub Kaminski',
    dateOfBirth: '05.06.2002',
    nationality: 'Polen',
    height: 1.79,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'VfL Wolfsburg',
    previousClub: 'Lech Posen',
    uid: '28581e9b-53a3-4583-ac40-76c61f8e03f7',
    position: 'Linksaußen',
    league: 'Bundesliga',
    marketValue: 10000000,
  },
  {
    jerseyNumber: 7,
    name: 'Luca Waldschmidt',
    dateOfBirth: '19.05.1996',
    nationality: 'Deutschland',
    height: 1.81,
    foot: 'links',
    teamMemberSince: '22.08.2021',
    club: 'VfL Wolfsburg',
    previousClub: 'Benfica Lissabon',
    uid: '4932bacf-1a72-47f2-8ddd-f323f0792800',
    position: 'Hängende Spitze',
    league: 'Bundesliga',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 10,
    name: 'Lukas Nmecha',
    dateOfBirth: '14.12.1998',
    nationality: 'Deutschland',
    height: 1.85,
    foot: 'rechts',
    teamMemberSince: '16.07.2021',
    club: 'VfL Wolfsburg',
    previousClub: 'Manchester City',
    uid: 'd40296a9-5bd5-4c93-850d-a8adb4e9a12c',
    position: 'Mittelstürmer',
    league: 'Bundesliga',
    marketValue: 14000000,
  },
  {
    jerseyNumber: 23,
    name: 'Jonas Wind',
    dateOfBirth: '07.02.1999',
    nationality: 'Dänemark',
    height: 1.9,
    foot: 'rechts',
    teamMemberSince: '31.01.2022',
    club: 'VfL Wolfsburg',
    previousClub: 'FC Kopenhagen',
    uid: '15a9d72f-f505-4905-a73c-e32eb9e589f9',
    position: 'Mittelstürmer',
    league: 'Bundesliga',
    marketValue: 14000000,
  },
  {
    jerseyNumber: 33,
    name: 'Omar Marmoush',
    dateOfBirth: '07.02.1999',
    nationality: 'Ägypten',
    height: 1.83,
    foot: 'rechts',
    teamMemberSince: '01.07.2020',
    club: 'VfL Wolfsburg',
    previousClub: 'VfL Wolfsburg II',
    uid: 'a7ad1d8d-5129-43d8-b3f2-799984f40d53',
    position: 'Mittelstürmer',
    league: 'Bundesliga',
    marketValue: 6000000,
  },
  {
    jerseyNumber: 18,
    name: 'Dzenan Pejcinovic',
    dateOfBirth: '15.02.2005',
    nationality: 'Deutschland',
    height: 1.88,
    foot: 'links',
    teamMemberSince: '14.07.2022',
    club: 'VfL Wolfsburg',
    previousClub: 'FC Augsburg U19',
    uid: 'b0549e6f-7966-43dc-9218-a52bd6197368',
    position: 'Mittelstürmer',
    league: 'Bundesliga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 26,
    name: 'Mark Flekken',
    dateOfBirth: '13.06.1993',
    nationality: 'Niederlande',
    height: 1.95,
    foot: 'beidfüßig',
    teamMemberSince: '01.07.2018',
    club: 'SC Freiburg',
    previousClub: ': Ablöse 2,00 Mio. €',
    uid: '31eb6650-def2-411b-bbf2-3cfeda22f577',
    position: 'Torwart',
    league: 'Bundesliga',
    marketValue: 7000000,
  },
  {
    jerseyNumber: 21,
    name: 'Noah Atubolu',
    dateOfBirth: '25.05.2002',
    nationality: 'Deutschland',
    height: 1.9,
    foot: 'rechts',
    teamMemberSince: '01.07.2021',
    club: 'SC Freiburg',
    previousClub: 'SC Freiburg II',
    uid: '330733cc-c104-4a0e-9314-f05a07a35028',
    position: 'Torwart',
    league: 'Bundesliga',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 1,
    name: 'Benjamin Uphoff',
    dateOfBirth: '08.08.1993',
    nationality: 'Deutschland',
    height: 1.92,
    foot: 'rechts',
    teamMemberSince: '05.08.2020',
    club: 'SC Freiburg',
    previousClub: 'Karlsruher SC',
    uid: 'd3b09a48-3d4d-445d-b3b8-38486f70cf1d',
    position: 'Torwart',
    league: 'Bundesliga',
    marketValue: 500000,
  },
  {
    jerseyNumber: 3,
    name: 'Philipp Lienhart',
    dateOfBirth: '11.07.1996',
    nationality: 'Österreich',
    height: 1.89,
    foot: 'rechts',
    teamMemberSince: '01.07.2018',
    club: 'SC Freiburg',
    previousClub: 'Real Madrid',
    uid: 'b870c20e-07ee-48d2-9bad-67c449979686',
    position: 'Innenverteidiger',
    league: 'Bundesliga',
    marketValue: 20000000,
  },
  {
    jerseyNumber: 28,
    name: 'Matthias Ginter',
    dateOfBirth: '19.01.1994',
    nationality: 'Deutschland',
    height: 1.91,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'SC Freiburg',
    previousClub: 'Borussia Mönchengladbach',
    uid: 'ec8aa6fe-3724-49c2-8404-4f41ac570fcc',
    position: 'Innenverteidiger',
    league: 'Bundesliga',
    marketValue: 20000000,
  },
  {
    jerseyNumber: 5,
    name: 'Manuel Gulde',
    dateOfBirth: '12.02.1991',
    nationality: 'Deutschland',
    height: 1.84,
    foot: 'rechts',
    teamMemberSince: '01.07.2016',
    club: 'SC Freiburg',
    previousClub: 'Karlsruher SC',
    uid: '9bed74ba-6681-4114-812e-959269e1d55e',
    position: 'Innenverteidiger',
    league: 'Bundesliga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 35,
    name: 'Kenneth Schmidt',
    dateOfBirth: '03.06.2002',
    nationality: 'Deutschland',
    height: 1.87,
    foot: 'links',
    teamMemberSince: '01.01.2023',
    club: 'SC Freiburg',
    previousClub: 'SC Freiburg II',
    uid: 'bae02891-16cf-44ef-a6fa-9f8bc97bb170',
    position: 'Innenverteidiger',
    league: 'Bundesliga',
    marketValue: 750000,
  },
  {
    jerseyNumber: 30,
    name: 'Christian Günter',
    dateOfBirth: '28.02.1993',
    nationality: 'Deutschland',
    height: 1.84,
    foot: 'links',
    teamMemberSince: '01.07.2012',
    club: 'SC Freiburg',
    previousClub: 'SC Freiburg U19',
    uid: 'e96892a4-a308-4155-aa26-733ad7a3f289',
    position: 'Linker Verteidiger',
    league: 'Bundesliga',
    marketValue: 10000000,
  },
  {
    jerseyNumber: 24,
    name: 'Kimberly Ezekwem',
    dateOfBirth: '19.06.2001',
    nationality: 'Deutschland',
    height: 1.87,
    foot: 'links',
    teamMemberSince: '01.07.2021',
    club: 'SC Freiburg',
    previousClub: 'SC Freiburg II',
    uid: '1e8c947f-74fe-4474-bbed-e4337fb40d61',
    position: 'Linker Verteidiger',
    league: 'Bundesliga',
    marketValue: 350000,
  },
  {
    jerseyNumber: 25,
    name: 'Kiliann Sildillia',
    dateOfBirth: '16.05.2002',
    nationality: 'Frankreich',
    height: 1.86,
    foot: 'rechts',
    teamMemberSince: '01.07.2021',
    club: 'SC Freiburg',
    previousClub: 'SC Freiburg II',
    uid: 'c3b88af0-459a-47fa-aae5-38bc02a08992',
    position: 'Rechter Verteidiger',
    league: 'Bundesliga',
    marketValue: 12000000,
  },
  {
    jerseyNumber: 17,
    name: 'Lukas Kübler',
    dateOfBirth: '30.08.1992',
    nationality: 'Deutschland',
    height: 1.82,
    foot: 'rechts',
    teamMemberSince: '01.07.2015',
    club: 'SC Freiburg',
    previousClub: ': Ablöse ablösefrei',
    uid: 'd1e238cb-c44e-4c00-9f1c-cef84776c5cd',
    position: 'Rechter Verteidiger',
    league: 'Bundesliga',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 7,
    name: 'Jonathan Schmid',
    dateOfBirth: '22.06.1990',
    nationality: 'Frankreich',
    height: 1.79,
    foot: 'rechts',
    teamMemberSince: '01.07.2019',
    club: 'SC Freiburg',
    previousClub: 'FC Augsburg',
    uid: '94b15314-e402-44e7-a54a-4402e7ff372f',
    position: 'Rechter Verteidiger',
    league: 'Bundesliga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 14,
    name: 'Yannik Keitel',
    dateOfBirth: '15.02.2000',
    nationality: 'Deutschland',
    height: 1.86,
    foot: 'beidfüßig',
    teamMemberSince: '16.04.2020',
    club: 'SC Freiburg',
    previousClub: 'SC Freiburg II',
    uid: '7d8cace2-5bec-4a26-8936-7926cd708a46',
    position: 'Defensives Mittelfeld',
    league: 'Bundesliga',
    marketValue: 4000000,
  },
  {
    jerseyNumber: 27,
    name: 'Nicolas Höfler',
    dateOfBirth: '09.03.1990',
    nationality: 'Deutschland',
    height: 1.81,
    foot: 'rechts',
    teamMemberSince: '01.07.2010',
    club: 'SC Freiburg',
    previousClub: 'SC Freiburg II',
    uid: '48c9d6fa-f0d5-419f-9f83-f90518ee5597',
    position: 'Defensives Mittelfeld',
    league: 'Bundesliga',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 8,
    name: 'Maximilian Eggestein',
    dateOfBirth: '08.12.1996',
    nationality: 'Deutschland',
    height: 1.81,
    foot: 'rechts',
    teamMemberSince: '18.08.2021',
    club: 'SC Freiburg',
    previousClub: 'SV Werder Bremen',
    uid: '3c6a6367-f6f6-433f-9bb3-1d5b33d24033',
    position: 'Zentrales Mittelfeld',
    league: 'Bundesliga',
    marketValue: 8000000,
  },
  {
    jerseyNumber: 34,
    name: 'Merlin Röhl',
    dateOfBirth: '05.07.2002',
    nationality: 'Deutschland',
    height: 1.92,
    foot: 'beidfüßig',
    teamMemberSince: '17.08.2022',
    club: 'SC Freiburg',
    previousClub: 'FC Ingolstadt 04',
    uid: '8cfd9330-d9dc-44b8-af08-e0711223a7c6',
    position: 'Zentrales Mittelfeld',
    league: 'Bundesliga',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 23,
    name: 'Robert Wagner',
    dateOfBirth: '14.07.2003',
    nationality: 'Deutschland',
    height: 1.81,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'SC Freiburg',
    previousClub: 'SC Freiburg II',
    uid: '5fc7fd11-80f2-47b8-a4bb-3405ac50f0df',
    position: 'Zentrales Mittelfeld',
    league: 'Bundesliga',
    marketValue: 900000,
  },
  {
    jerseyNumber: 11,
    name: 'Daniel-Kofi Kyereh',
    dateOfBirth: '08.03.1996',
    nationality: 'Ghana',
    height: 1.79,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'SC Freiburg',
    previousClub: 'FC St. Pauli',
    uid: 'b379a8fd-5cd5-421b-a9d0-66b93a16b61e',
    position: 'Offensives Mittelfeld',
    league: 'Bundesliga',
    marketValue: 8000000,
  },
  {
    jerseyNumber: 32,
    name: 'Vincenzo Grifo',
    dateOfBirth: '07.04.1993',
    nationality: 'Italien',
    height: 1.8,
    foot: 'rechts',
    teamMemberSince: '02.09.2019',
    club: 'SC Freiburg',
    previousClub: 'TSG 1899 Hoffenheim',
    uid: '784c6b5f-5283-4144-803a-ccffe38253b3',
    position: 'Linksaußen',
    league: 'Bundesliga',
    marketValue: 12000000,
  },
  {
    jerseyNumber: 33,
    name: 'Noah Weißhaupt',
    dateOfBirth: '20.09.2001',
    nationality: 'Deutschland',
    height: 1.81,
    foot: 'rechts',
    teamMemberSince: '01.07.2021',
    club: 'SC Freiburg',
    previousClub: 'SC Freiburg II',
    uid: '512d84b6-b1e6-47c6-9f01-60ce172a4f60',
    position: 'Linksaußen',
    league: 'Bundesliga',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 42,
    name: 'Ritsu Doan',
    dateOfBirth: '16.06.1998',
    nationality: 'Japan',
    height: 1.72,
    foot: 'links',
    teamMemberSince: '05.07.2022',
    club: 'SC Freiburg',
    previousClub: 'PSV Eindhoven',
    uid: '51b2940b-e2c8-4e82-bfd2-d505b5f62710',
    position: 'Rechtsaußen',
    league: 'Bundesliga',
    marketValue: 15000000,
  },
  {
    jerseyNumber: 22,
    name: 'Roland Sallai',
    dateOfBirth: '22.05.1997',
    nationality: 'Ungarn',
    height: 1.83,
    foot: 'beidfüßig',
    teamMemberSince: '31.08.2018',
    club: 'SC Freiburg',
    previousClub: 'APOEL Nikosia',
    uid: 'ff381501-1493-4a69-bd1a-1ab7014458a7',
    position: 'Rechtsaußen',
    league: 'Bundesliga',
    marketValue: 10000000,
  },
  {
    jerseyNumber: 29,
    name: 'Woo-yeong Jeong',
    dateOfBirth: '20.09.1999',
    nationality: 'Südkorea',
    height: 1.79,
    foot: 'rechts',
    teamMemberSince: '01.07.2019',
    club: 'SC Freiburg',
    previousClub: 'FC Bayern München II',
    uid: 'd71e25ba-a186-4bee-9bb6-457829dbc8e7',
    position: 'Hängende Spitze',
    league: 'Bundesliga',
    marketValue: 4000000,
  },
  {
    jerseyNumber: 38,
    name: 'Michael Gregoritsch',
    dateOfBirth: '18.04.1994',
    nationality: 'Österreich',
    height: 1.93,
    foot: 'links',
    teamMemberSince: '08.07.2022',
    club: 'SC Freiburg',
    previousClub: 'FC Augsburg',
    uid: 'c4a0a697-1506-4a9f-84e5-e66f3e0ccb00',
    position: 'Mittelstürmer',
    league: 'Bundesliga',
    marketValue: 8000000,
  },
  {
    jerseyNumber: 9,
    name: 'Lucas Höler',
    dateOfBirth: '10.07.1994',
    nationality: 'Deutschland',
    height: 1.84,
    foot: 'rechts',
    teamMemberSince: '01.01.2018',
    club: 'SC Freiburg',
    previousClub: ': Ablöse 2,00 Mio. €',
    uid: '50a51b65-7d84-4d8f-b786-770efff24882',
    position: 'Mittelstürmer',
    league: 'Bundesliga',
    marketValue: 5000000,
  },
  {
    jerseyNumber: 18,
    name: 'Nils Petersen',
    dateOfBirth: '06.12.1988',
    nationality: 'Deutschland',
    height: 1.88,
    foot: 'rechts',
    teamMemberSince: '01.07.2015',
    club: 'SC Freiburg',
    previousClub: 'SV Werder Bremen',
    uid: '00d890ca-8105-422c-aeae-5da076634867',
    position: 'Mittelstürmer',
    league: 'Bundesliga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 1,
    name: 'Oliver Baumann',
    dateOfBirth: '02.06.1990',
    nationality: 'Deutschland',
    height: 1.87,
    foot: 'rechts',
    teamMemberSince: '01.07.2014',
    club: 'TSG 1899 Hoffenheim',
    previousClub: 'SC Freiburg',
    uid: '23add28e-6b57-4cbc-80c6-326dc36114f0',
    position: 'Torwart',
    league: 'Bundesliga',
    marketValue: 4000000,
  },
  {
    jerseyNumber: 12,
    name: 'Philipp Pentke',
    dateOfBirth: '01.05.1985',
    nationality: 'Deutschland',
    height: 1.91,
    foot: 'rechts',
    teamMemberSince: '01.07.2019',
    club: 'TSG 1899 Hoffenheim',
    previousClub: 'SSV Jahn Regensburg',
    uid: 'd567d41e-b76f-4c1a-aa70-9fd0fee85abc',
    position: 'Torwart',
    league: 'Bundesliga',
    marketValue: 300000,
  },
  {
    jerseyNumber: 36,
    name: 'Nahuel Noll',
    dateOfBirth: '17.03.2003',
    nationality: 'Deutschland',
    height: 1.88,
    foot: 'beidfüßig',
    teamMemberSince: '01.07.2021',
    club: 'TSG 1899 Hoffenheim',
    previousClub: 'TSG 1899 Hoffenheim U19',
    uid: '1ca231a6-4581-47e7-acef-75469b2ce26a',
    position: 'Torwart',
    league: 'Bundesliga',
    marketValue: 250000,
  },
  {
    jerseyNumber: 5,
    name: 'Ozan Kabak',
    dateOfBirth: '25.03.2000',
    nationality: 'Türkei',
    height: 1.87,
    foot: 'rechts',
    teamMemberSince: '23.07.2022',
    club: 'TSG 1899 Hoffenheim',
    previousClub: 'FC Schalke 04',
    uid: 'a74a0780-b986-40c7-89af-8b04269dbfc4',
    position: 'Innenverteidiger',
    league: 'Bundesliga',
    marketValue: 10000000,
  },
  {
    jerseyNumber: 34,
    name: 'Stanley Nsoki',
    dateOfBirth: '09.04.1999',
    nationality: 'Frankreich',
    height: 1.84,
    foot: 'links',
    teamMemberSince: '03.08.2022',
    club: 'TSG 1899 Hoffenheim',
    previousClub: 'FC Brügge',
    uid: 'cb2aebd4-1fc4-46ae-9ba9-d85556c64b2b',
    position: 'Innenverteidiger',
    league: 'Bundesliga',
    marketValue: 8000000,
  },
  {
    jerseyNumber: 25,
    name: 'Kevin Akpoguma',
    dateOfBirth: '19.04.1995',
    nationality: 'Nigeria',
    height: 1.92,
    foot: 'rechts',
    teamMemberSince: '01.07.2013',
    club: 'TSG 1899 Hoffenheim',
    previousClub: ': Ablöse 1,00 Mio. €',
    uid: 'b5ddc69b-1132-4a31-924a-e4ac9ceb99ce',
    position: 'Innenverteidiger',
    league: 'Bundesliga',
    marketValue: 4000000,
  },
  {
    jerseyNumber: 22,
    name: 'Kevin Vogt',
    dateOfBirth: '23.09.1991',
    nationality: 'Deutschland',
    height: 1.94,
    foot: 'rechts',
    teamMemberSince: '01.07.2016',
    club: 'TSG 1899 Hoffenheim',
    previousClub: '1.FC Köln',
    uid: '1458b6ec-d98f-48ad-8af7-4f9963684f2d',
    position: 'Innenverteidiger',
    league: 'Bundesliga',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 23,
    name: 'John Anthony Brooks',
    dateOfBirth: '28.01.1993',
    nationality: 'Vereinigte Staaten',
    height: 1.94,
    foot: 'links',
    teamMemberSince: '26.01.2023',
    club: 'TSG 1899 Hoffenheim',
    previousClub: 'Benfica Lissabon',
    uid: '56ce0cd4-b396-44a5-b4a9-9305778082a0',
    position: 'Innenverteidiger',
    league: 'Bundesliga',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 26,
    name: 'Eduardo Quaresma',
    dateOfBirth: '02.03.2002',
    nationality: 'Portugal',
    height: 1.85,
    foot: 'rechts',
    teamMemberSince: '04.08.2022',
    club: 'TSG 1899 Hoffenheim',
    previousClub: 'Sporting Lissabon',
    uid: '4cfd780a-6402-411e-9bf0-7bb969f42bc3',
    position: 'Innenverteidiger',
    league: 'Bundesliga',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 4,
    name: 'Ermin Bicakcic',
    dateOfBirth: '24.01.1990',
    nationality: 'Bosnien-Herzegowina',
    height: 1.85,
    foot: 'rechts',
    teamMemberSince: '01.07.2014',
    club: 'TSG 1899 Hoffenheim',
    previousClub: ': Ablöse 400 Tsd. €',
    uid: '0336800b-1f74-4292-b099-d119dbe7f798',
    position: 'Innenverteidiger',
    league: 'Bundesliga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 11,
    name: 'Angeliño',
    dateOfBirth: '04.01.1997',
    nationality: 'Spanien',
    height: 1.71,
    foot: 'links',
    teamMemberSince: '08.08.2022',
    club: 'TSG 1899 Hoffenheim',
    previousClub: 'RasenBallsport Leipzig',
    uid: 'd614f47e-35b8-4892-8612-5e67ef7e9ad5',
    position: 'Linker Verteidiger',
    league: 'Bundesliga',
    marketValue: 14000000,
  },
  {
    jerseyNumber: 3,
    name: 'Pavel Kaderabek',
    dateOfBirth: '25.04.1992',
    nationality: 'Tschechien',
    height: 1.82,
    foot: 'rechts',
    teamMemberSince: '01.07.2015',
    club: 'TSG 1899 Hoffenheim',
    previousClub: ': Ablöse 3,50 Mio. €',
    uid: 'cdd378cf-c4d9-4c89-af2a-32dd690685b2',
    position: 'Rechter Verteidiger',
    league: 'Bundesliga',
    marketValue: 4000000,
  },
  {
    jerseyNumber: 24,
    name: 'Justin Che',
    dateOfBirth: '18.11.2003',
    nationality: 'Vereinigte Staaten',
    height: 1.85,
    foot: 'rechts',
    teamMemberSince: '18.01.2023',
    club: 'TSG 1899 Hoffenheim',
    previousClub: 'TSG 1899 Hoffenheim II',
    uid: 'c571694d-f2cb-4457-9b8d-7a3676e7a8aa',
    position: 'Rechter Verteidiger',
    league: 'Bundesliga',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 13,
    name: 'Angelo Stiller',
    dateOfBirth: '04.04.2001',
    nationality: 'Deutschland',
    height: 1.83,
    foot: 'links',
    teamMemberSince: '01.07.2021',
    club: 'TSG 1899 Hoffenheim',
    previousClub: 'FC Bayern München II',
    uid: 'a3894cdf-f2f1-4432-acfe-e4b88b2f64be',
    position: 'Defensives Mittelfeld',
    league: 'Bundesliga',
    marketValue: 6000000,
  },
  {
    jerseyNumber: 16,
    name: 'Sebastian Rudy',
    dateOfBirth: '28.02.1990',
    nationality: 'Deutschland',
    height: 1.8,
    foot: 'rechts',
    teamMemberSince: '01.07.2021',
    club: 'TSG 1899 Hoffenheim',
    previousClub: 'FC Schalke 04',
    uid: '5d23e427-1bca-4412-b383-3ed01100f657',
    position: 'Defensives Mittelfeld',
    league: 'Bundesliga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 8,
    name: 'Dennis Geiger',
    dateOfBirth: '10.06.1998',
    nationality: 'Deutschland',
    height: 1.73,
    foot: 'rechts',
    teamMemberSince: '01.07.2016',
    club: 'TSG 1899 Hoffenheim',
    previousClub: 'TSG 1899 Hoffenheim U19',
    uid: 'fd856235-bb7a-4219-8fc4-39e218a37cb3',
    position: 'Zentrales Mittelfeld',
    league: 'Bundesliga',
    marketValue: 9000000,
  },
  {
    jerseyNumber: 6,
    name: 'Grischa Prömel',
    dateOfBirth: '09.01.1995',
    nationality: 'Deutschland',
    height: 1.84,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'TSG 1899 Hoffenheim',
    previousClub: '1.FC Union Berlin',
    uid: 'f15d324a-ec34-4c36-97e0-b903d157d5fd',
    position: 'Zentrales Mittelfeld',
    league: 'Bundesliga',
    marketValue: 7000000,
  },
  {
    jerseyNumber: 17,
    name: 'Thomas Delaney',
    dateOfBirth: '03.09.1991',
    nationality: 'Dänemark',
    height: 1.82,
    foot: 'links',
    teamMemberSince: '30.01.2023',
    club: 'TSG 1899 Hoffenheim',
    previousClub: 'FC Sevilla',
    uid: '62d17409-8db1-4115-a364-206a90cc0731',
    position: 'Zentrales Mittelfeld',
    league: 'Bundesliga',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 20,
    name: 'Finn Ole Becker',
    dateOfBirth: '08.06.2000',
    nationality: 'Deutschland',
    height: 1.77,
    foot: 'links',
    teamMemberSince: '01.07.2022',
    club: 'TSG 1899 Hoffenheim',
    previousClub: 'FC St. Pauli',
    uid: '68b63442-02e4-4680-b6e0-fc4a43cd78f1',
    position: 'Zentrales Mittelfeld',
    league: 'Bundesliga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 40,
    name: 'Umut Tohumcu',
    dateOfBirth: '11.08.2004',
    nationality: 'Deutschland',
    height: 1.75,
    foot: 'beidfüßig',
    teamMemberSince: '18.01.2023',
    club: 'TSG 1899 Hoffenheim',
    previousClub: 'TSG 1899 Hoffenheim II',
    uid: 'a7d86dd7-c212-4e7a-a87c-42935b5b9c8d',
    position: 'Zentrales Mittelfeld',
    league: 'Bundesliga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 29,
    name: 'Robert Skov',
    dateOfBirth: '20.05.1996',
    nationality: 'Dänemark',
    height: 1.85,
    foot: 'links',
    teamMemberSince: '29.07.2019',
    club: 'TSG 1899 Hoffenheim',
    previousClub: 'FC Kopenhagen',
    uid: '71a41db0-93b7-4fd6-8a8d-fa3740fa1886',
    position: 'Rechtes Mittelfeld',
    league: 'Bundesliga',
    marketValue: 9000000,
  },
  {
    jerseyNumber: 14,
    name: 'Christoph Baumgartner',
    dateOfBirth: '01.08.1999',
    nationality: 'Österreich',
    height: 1.8,
    foot: 'rechts',
    teamMemberSince: '15.01.2019',
    club: 'TSG 1899 Hoffenheim',
    previousClub: 'TSG 1899 Hoffenheim II',
    uid: '840d9728-64da-4a4a-ac47-56e485e7279a',
    position: 'Offensives Mittelfeld',
    league: 'Bundesliga',
    marketValue: 20000000,
  },
  {
    jerseyNumber: 39,
    name: 'Tom Bischof',
    dateOfBirth: '28.06.2005',
    nationality: 'Deutschland',
    height: 1.76,
    foot: 'links',
    teamMemberSince: '01.07.2021',
    club: 'TSG 1899 Hoffenheim',
    previousClub: 'TSG 1899 Hoffenheim U17',
    uid: '3ddd543d-8a15-46a3-a792-b699096ae875',
    position: 'Offensives Mittelfeld',
    league: 'Bundesliga',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 35,
    name: 'Muhammed Damar',
    dateOfBirth: '09.04.2004',
    nationality: 'Deutschland',
    height: 1.85,
    foot: 'beidfüßig',
    teamMemberSince: '01.07.2022',
    club: 'TSG 1899 Hoffenheim',
    previousClub: 'Eintracht Frankfurt U19',
    uid: '80b88f81-e5b9-472e-a49d-ab92bd95f234',
    position: 'Offensives Mittelfeld',
    league: 'Bundesliga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 7,
    name: 'Jacob Bruun Larsen',
    dateOfBirth: '19.09.1998',
    nationality: 'Dänemark',
    height: 1.83,
    foot: 'rechts',
    teamMemberSince: '31.01.2020',
    club: 'TSG 1899 Hoffenheim',
    previousClub: 'Borussia Dortmund',
    uid: 'a4f0f781-b6ed-47af-a9f5-2ea06138ab62',
    position: 'Linksaußen',
    league: 'Bundesliga',
    marketValue: 4000000,
  },
  {
    jerseyNumber: 19,
    name: 'Kasper Dolberg',
    dateOfBirth: '06.10.1997',
    nationality: 'Dänemark',
    height: 1.87,
    foot: 'rechts',
    teamMemberSince: '02.01.2023',
    club: 'TSG 1899 Hoffenheim',
    previousClub: 'OGC Nizza',
    uid: '3f6f092d-26c6-45c6-9c9f-a7e200d66f00',
    position: 'Mittelstürmer',
    league: 'Bundesliga',
    marketValue: 9000000,
  },
  {
    jerseyNumber: 27,
    name: 'Andrej Kramaric',
    dateOfBirth: '19.06.1991',
    nationality: 'Kroatien',
    height: 1.77,
    foot: 'rechts',
    teamMemberSince: '01.07.2016',
    club: 'TSG 1899 Hoffenheim',
    previousClub: 'Leicester City',
    uid: '79a77122-6523-4868-b0a8-496c88b07d63',
    position: 'Mittelstürmer',
    league: 'Bundesliga',
    marketValue: 9000000,
  },
  {
    jerseyNumber: 9,
    name: 'Ihlas Bebou',
    dateOfBirth: '23.04.1994',
    nationality: 'Togo',
    height: 1.83,
    foot: 'rechts',
    teamMemberSince: '01.07.2019',
    club: 'TSG 1899 Hoffenheim',
    previousClub: ': Ablöse 8,50 Mio. €',
    uid: 'd4da71b1-b6dd-44b1-8189-b751c563dd0f',
    position: 'Mittelstürmer',
    league: 'Bundesliga',
    marketValue: 7000000,
  },
  {
    jerseyNumber: 10,
    name: 'Munas Dabbur',
    dateOfBirth: '14.05.1992',
    nationality: 'Israel',
    height: 1.82,
    foot: 'rechts',
    teamMemberSince: '07.01.2020',
    club: 'TSG 1899 Hoffenheim',
    previousClub: 'FC Sevilla',
    uid: '3ba94d98-ddb8-4a7f-96cd-53df7ef3f38e',
    position: 'Mittelstürmer',
    league: 'Bundesliga',
    marketValue: 4000000,
  },
  {
    jerseyNumber: 44,
    name: 'Fisnik Asllani',
    dateOfBirth: '08.08.2002',
    nationality: 'Deutschland',
    height: 1.88,
    foot: 'beidfüßig',
    teamMemberSince: '04.01.2022',
    club: 'TSG 1899 Hoffenheim',
    previousClub: 'TSG 1899 Hoffenheim II',
    uid: '78a33785-ce63-4b19-a791-c567d4f108fe',
    position: 'Mittelstürmer',
    league: 'Bundesliga',
    marketValue: 800000,
  },
  {
    jerseyNumber: 1,
    name: 'Frederik Rönnow',
    dateOfBirth: '04.08.1992',
    nationality: 'Dänemark',
    height: 1.88,
    foot: 'rechts',
    teamMemberSince: '20.07.2021',
    club: '1.FC Union Berlin',
    previousClub: 'Eintracht Frankfurt',
    uid: 'fad7b7ef-178e-482e-802f-b4626ddbbc64',
    position: 'Torwart',
    league: 'Bundesliga',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 37,
    name: 'Lennart Grill',
    dateOfBirth: '25.01.1999',
    nationality: 'Deutschland',
    height: 1.92,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: '1.FC Union Berlin',
    previousClub: 'Bayer 04 Leverkusen',
    uid: '3d0d39f9-c93f-4835-8f24-919d0813e5c0',
    position: 'Torwart',
    league: 'Bundesliga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 12,
    name: 'Jakob Busk',
    dateOfBirth: '12.09.1993',
    nationality: 'Dänemark',
    height: 1.89,
    foot: 'rechts',
    teamMemberSince: '15.01.2016',
    club: '1.FC Union Berlin',
    previousClub: 'FC Kopenhagen',
    uid: '942723c1-726d-4bfa-9efa-fcc58bdd9332',
    position: 'Torwart',
    league: 'Bundesliga',
    marketValue: 250000,
  },
  {
    jerseyNumber: 4,
    name: 'Diogo Leite',
    dateOfBirth: '23.01.1999',
    nationality: 'Portugal',
    height: 1.9,
    foot: 'links',
    teamMemberSince: '16.07.2022',
    club: '1.FC Union Berlin',
    previousClub: 'FC Porto',
    uid: '7d4ef6b8-c5b5-4d03-8f8a-efd9a383d7ad',
    position: 'Innenverteidiger',
    league: 'Bundesliga',
    marketValue: 12000000,
  },
  {
    jerseyNumber: 5,
    name: 'Danilho Doekhi',
    dateOfBirth: '30.06.1998',
    nationality: 'Niederlande',
    height: 1.9,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: '1.FC Union Berlin',
    previousClub: 'Vitesse Arnheim',
    uid: '55544d72-fb00-48b0-8dee-7f254044d15c',
    position: 'Innenverteidiger',
    league: 'Bundesliga',
    marketValue: 12000000,
  },
  {
    jerseyNumber: 3,
    name: 'Paul Jaeckel',
    dateOfBirth: '22.07.1998',
    nationality: 'Deutschland',
    height: 1.86,
    foot: 'rechts',
    teamMemberSince: '01.07.2021',
    club: '1.FC Union Berlin',
    previousClub: 'SpVgg Greuther Fürth',
    uid: '04018688-cc3f-48eb-931d-8d1b9cd6af2a',
    position: 'Innenverteidiger',
    league: 'Bundesliga',
    marketValue: 5000000,
  },
  {
    jerseyNumber: 31,
    name: 'Robin Knoche',
    dateOfBirth: '22.05.1992',
    nationality: 'Deutschland',
    height: 1.89,
    foot: 'rechts',
    teamMemberSince: '04.08.2020',
    club: '1.FC Union Berlin',
    previousClub: 'VfL Wolfsburg',
    uid: '31e5736d-c017-45e8-a5a7-64c5bafeb37e',
    position: 'Innenverteidiger',
    league: 'Bundesliga',
    marketValue: 5000000,
  },
  {
    jerseyNumber: 25,
    name: 'Timo Baumgartl',
    dateOfBirth: '04.03.1996',
    nationality: 'Deutschland',
    height: 1.9,
    foot: 'rechts',
    teamMemberSince: '01.07.2021',
    club: '1.FC Union Berlin',
    previousClub: 'PSV Eindhoven',
    uid: '8c2197b6-ce29-4ec6-aef3-d0e7085af952',
    position: 'Innenverteidiger',
    league: 'Bundesliga',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 26,
    name: 'Jérôme Roussillon',
    dateOfBirth: '06.01.1993',
    nationality: 'Guadeloupe',
    height: 1.72,
    foot: 'links',
    teamMemberSince: '12.01.2023',
    club: '1.FC Union Berlin',
    previousClub: 'VfL Wolfsburg',
    uid: '0f2c0547-1fb8-4cc1-b5cd-4651d5a3f891',
    position: 'Linker Verteidiger',
    league: 'Bundesliga',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 23,
    name: 'Niko Gießelmann',
    dateOfBirth: '26.09.1991',
    nationality: 'Deutschland',
    height: 1.82,
    foot: 'links',
    teamMemberSince: '15.07.2020',
    club: '1.FC Union Berlin',
    previousClub: 'Fortuna Düsseldorf',
    uid: '2165e812-75eb-4fd5-b9b0-6681c0153167',
    position: 'Linker Verteidiger',
    league: 'Bundesliga',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 18,
    name: 'Josip Juranovic',
    dateOfBirth: '16.08.1995',
    nationality: 'Kroatien',
    height: 1.73,
    foot: 'rechts',
    teamMemberSince: '22.01.2023',
    club: '1.FC Union Berlin',
    previousClub: 'Celtic Glasgow',
    uid: '420a203f-03e8-421c-bf8a-aaf7b0c50cc9',
    position: 'Rechter Verteidiger',
    league: 'Bundesliga',
    marketValue: 9000000,
  },
  {
    jerseyNumber: 28,
    name: 'Christopher Trimmel',
    dateOfBirth: '24.02.1987',
    nationality: 'Österreich',
    height: 1.89,
    foot: 'rechts',
    teamMemberSince: '01.07.2014',
    club: '1.FC Union Berlin',
    previousClub: 'SK Rapid Wien',
    uid: '6418c176-478b-40de-9a4e-2ca4f486ccdf',
    position: 'Rechter Verteidiger',
    league: 'Bundesliga',
    marketValue: 800000,
  },
  {
    jerseyNumber: 8,
    name: 'Rani Khedira',
    dateOfBirth: '27.01.1994',
    nationality: 'Deutschland',
    height: 1.89,
    foot: 'rechts',
    teamMemberSince: '01.07.2021',
    club: '1.FC Union Berlin',
    previousClub: 'FC Augsburg',
    uid: '778eef66-6c0e-4cac-aff9-dcba90cba759',
    position: 'Defensives Mittelfeld',
    league: 'Bundesliga',
    marketValue: 8000000,
  },
  {
    jerseyNumber: 2,
    name: 'Morten Thorsby',
    dateOfBirth: '05.05.1996',
    nationality: 'Norwegen',
    height: 1.88,
    foot: 'rechts',
    teamMemberSince: '19.07.2022',
    club: '1.FC Union Berlin',
    previousClub: 'Sampdoria Genua',
    uid: 'c8ed6aa4-d705-40c4-8d49-9e1bfd17b2de',
    position: 'Zentrales Mittelfeld',
    league: 'Bundesliga',
    marketValue: 7000000,
  },
  {
    jerseyNumber: 19,
    name: 'Janik Haberer',
    dateOfBirth: '02.04.1994',
    nationality: 'Deutschland',
    height: 1.86,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: '1.FC Union Berlin',
    previousClub: 'SC Freiburg',
    uid: 'a1f29d1b-9ca3-42ce-af59-172da2a4d7fd',
    position: 'Zentrales Mittelfeld',
    league: 'Bundesliga',
    marketValue: 7000000,
  },
  {
    jerseyNumber: 13,
    name: 'András Schäfer',
    dateOfBirth: '13.04.1999',
    nationality: 'Ungarn',
    height: 1.78,
    foot: 'beidfüßig',
    teamMemberSince: '21.01.2022',
    club: '1.FC Union Berlin',
    previousClub: 'DAC Dunajska Streda',
    uid: '3ccce6ca-fca7-4e56-bf85-4e0047eef0cc',
    position: 'Zentrales Mittelfeld',
    league: 'Bundesliga',
    marketValue: 5000000,
  },
  {
    jerseyNumber: 20,
    name: 'Aïssa Laïdouni',
    dateOfBirth: '13.12.1996',
    nationality: 'Tunesien',
    height: 1.81,
    foot: 'rechts',
    teamMemberSince: '27.01.2023',
    club: '1.FC Union Berlin',
    previousClub: 'Ferencvárosi TC',
    uid: '4509576f-eac9-45fc-b3fd-14978614885c',
    position: 'Zentrales Mittelfeld',
    league: 'Bundesliga',
    marketValue: 4000000,
  },
  {
    jerseyNumber: 14,
    name: 'Paul Seguin',
    dateOfBirth: '29.03.1995',
    nationality: 'Deutschland',
    height: 1.85,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: '1.FC Union Berlin',
    previousClub: 'SpVgg Greuther Fürth',
    uid: '5bc492a1-a4f4-4286-80b9-003ff1077408',
    position: 'Zentrales Mittelfeld',
    league: 'Bundesliga',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 7,
    name: 'Levin Öztunali',
    dateOfBirth: '15.03.1996',
    nationality: 'Deutschland',
    height: 1.84,
    foot: 'rechts',
    teamMemberSince: '01.07.2021',
    club: '1.FC Union Berlin',
    previousClub: '1.FSV Mainz 05',
    uid: 'eaa5f9d8-fbc6-4e5f-b4e3-b611459943db',
    position: 'Zentrales Mittelfeld',
    league: 'Bundesliga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 30,
    name: 'Kevin Möhwald',
    dateOfBirth: '03.07.1993',
    nationality: 'Deutschland',
    height: 1.82,
    foot: 'rechts',
    teamMemberSince: '30.08.2021',
    club: '1.FC Union Berlin',
    previousClub: 'SV Werder Bremen',
    uid: '3e15c0a5-fdcf-4be4-87fb-d9bb6f334681',
    position: 'Zentrales Mittelfeld',
    league: 'Bundesliga',
    marketValue: 700000,
  },
  {
    jerseyNumber: 32,
    name: 'Milos Pantovic',
    dateOfBirth: '07.07.1996',
    nationality: 'Serbien',
    height: 1.86,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: '1.FC Union Berlin',
    previousClub: 'VfL Bochum',
    uid: 'ec4353fb-7858-4088-ae1d-2ae2e31b1738',
    position: 'Offensives Mittelfeld',
    league: 'Bundesliga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 16,
    name: 'Tim Maciejewski',
    dateOfBirth: '05.03.2001',
    nationality: 'Deutschland',
    height: 1.86,
    foot: 'rechts',
    teamMemberSince: '01.07.2020',
    club: '1.FC Union Berlin',
    previousClub: '1.FC Union Berlin U19',
    uid: '202045c3-9223-496f-9f1e-255936db2a69',
    position: 'Rechtsaußen',
    league: 'Bundesliga',
    marketValue: 350000,
  },
  {
    jerseyNumber: 27,
    name: 'Sheraldo Becker',
    dateOfBirth: '09.02.1995',
    nationality: 'Suriname',
    height: 1.8,
    foot: 'rechts',
    teamMemberSince: '01.07.2019',
    club: '1.FC Union Berlin',
    previousClub: 'ADO Den Haag',
    uid: '622a538e-7a02-4c3a-9611-a43e1842c95a',
    position: 'Hängende Spitze',
    league: 'Bundesliga',
    marketValue: 15000000,
  },
  {
    jerseyNumber: 40,
    name: 'Jamie Leweling',
    dateOfBirth: '26.02.2001',
    nationality: 'Deutschland',
    height: 1.84,
    foot: 'beidfüßig',
    teamMemberSince: '01.07.2022',
    club: '1.FC Union Berlin',
    previousClub: 'SpVgg Greuther Fürth',
    uid: '72993203-bf9b-4e64-b750-cc1c1376d0e3',
    position: 'Hängende Spitze',
    league: 'Bundesliga',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 45,
    name: 'Jordan',
    dateOfBirth: '26.04.1996',
    nationality: 'Vereinigte Staaten',
    height: 1.91,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: '1.FC Union Berlin',
    previousClub: 'BSC Young Boys',
    uid: '7c0c5a6e-094a-46dd-99e6-0ba197ffade6',
    position: 'Mittelstürmer',
    league: 'Bundesliga',
    marketValue: 9000000,
  },
  {
    jerseyNumber: 17,
    name: 'Kevin Behrens',
    dateOfBirth: '03.02.1991',
    nationality: 'Deutschland',
    height: 1.85,
    foot: 'beidfüßig',
    teamMemberSince: '01.07.2021',
    club: '1.FC Union Berlin',
    previousClub: 'SV Sandhausen',
    uid: '1d3dbd5e-ea03-4726-90bb-6a5a6112594d',
    position: 'Mittelstürmer',
    league: 'Bundesliga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 11,
    name: 'Sven Michel',
    dateOfBirth: '15.07.1990',
    nationality: 'Deutschland',
    height: 1.77,
    foot: 'links',
    teamMemberSince: '31.01.2022',
    club: '1.FC Union Berlin',
    previousClub: 'SC Paderborn 07',
    uid: 'b3319c37-0ebf-4135-ad30-c84a98b7a76b',
    position: 'Mittelstürmer',
    league: 'Bundesliga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 1,
    name: 'Rafal Gikiewicz',
    dateOfBirth: '26.10.1987',
    nationality: 'Polen',
    height: 1.9,
    foot: 'rechts',
    teamMemberSince: '01.07.2020',
    club: 'FC Augsburg',
    previousClub: '1.FC Union Berlin',
    uid: '8d11f405-3ede-4143-866c-7d2155a51d75',
    position: 'Torwart',
    league: 'Bundesliga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 40,
    name: 'Tomas Koubek',
    dateOfBirth: '26.08.1992',
    nationality: 'Tschechien',
    height: 1.97,
    foot: 'rechts',
    teamMemberSince: '06.08.2019',
    club: 'FC Augsburg',
    previousClub: 'FC Stade Rennes',
    uid: 'fc67b8de-18f8-4888-be61-8758dcaa149c',
    position: 'Torwart',
    league: 'Bundesliga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 25,
    name: 'Daniel Klein',
    dateOfBirth: '13.03.2001',
    nationality: 'Deutschland',
    height: 1.93,
    foot: 'rechts',
    teamMemberSince: '02.07.2021',
    club: 'FC Augsburg',
    previousClub: 'TSG 1899 Hoffenheim II',
    uid: '051c295f-0e71-4a99-a9a1-5c9348246982',
    position: 'Torwart',
    league: 'Bundesliga',
    marketValue: 300000,
  },
  {
    jerseyNumber: 39,
    name: 'Benjamin Leneis',
    dateOfBirth: '08.03.1999',
    nationality: 'Deutschland',
    height: 1.95,
    foot: 'beidfüßig',
    teamMemberSince: '24.10.2018',
    club: 'FC Augsburg',
    previousClub: 'FC Augsburg II',
    uid: 'ff0a39e6-8f8a-42f1-9dad-eebe12e5c797',
    position: 'Torwart',
    league: 'Bundesliga',
    marketValue: 150000,
  },
  {
    jerseyNumber: 19,
    name: 'Felix Uduokhai',
    dateOfBirth: '09.09.1997',
    nationality: 'Deutschland',
    height: 1.92,
    foot: 'links',
    teamMemberSince: '01.07.2020',
    club: 'FC Augsburg',
    previousClub: 'VfL Wolfsburg',
    uid: 'a2d4a20d-813b-4b50-aaa9-ff5d2418ef5b',
    position: 'Innenverteidiger',
    league: 'Bundesliga',
    marketValue: 10000000,
  },
  {
    jerseyNumber: 4,
    name: 'Reece Oxford',
    dateOfBirth: '16.12.1998',
    nationality: 'England',
    height: 1.9,
    foot: 'rechts',
    teamMemberSince: '02.08.2019',
    club: 'FC Augsburg',
    previousClub: 'West Ham United',
    uid: 'b6b53fbf-48ad-40e5-86fa-25e72e5ebaf9',
    position: 'Innenverteidiger',
    league: 'Bundesliga',
    marketValue: 9000000,
  },
  {
    jerseyNumber: 6,
    name: 'Jeffrey Gouweleeuw',
    dateOfBirth: '10.07.1991',
    nationality: 'Niederlande',
    height: 1.87,
    foot: 'rechts',
    teamMemberSince: '09.01.2016',
    club: 'FC Augsburg',
    previousClub: 'AZ Alkmaar',
    uid: '5df6da9b-f630-4d1c-a614-0ed4cccc5710',
    position: 'Innenverteidiger',
    league: 'Bundesliga',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 23,
    name: 'Maximilian Bauer',
    dateOfBirth: '09.02.2000',
    nationality: 'Deutschland',
    height: 1.89,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'FC Augsburg',
    previousClub: 'SpVgg Greuther Fürth',
    uid: '7fd986a1-3aae-4f17-8e37-2bad111ae17b',
    position: 'Innenverteidiger',
    league: 'Bundesliga',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 22,
    name: 'Iago',
    dateOfBirth: '23.03.1997',
    nationality: 'Brasilien',
    height: 1.81,
    foot: 'links',
    teamMemberSince: '01.07.2019',
    club: 'FC Augsburg',
    previousClub: 'SC Internacional Porto Alegre',
    uid: '51a992ee-a12c-4370-be99-82fef747fab9',
    position: 'Linker Verteidiger',
    league: 'Bundesliga',
    marketValue: 5000000,
  },
  {
    jerseyNumber: 3,
    name: 'Mads Pedersen',
    dateOfBirth: '01.09.1996',
    nationality: 'Dänemark',
    height: 1.74,
    foot: 'links',
    teamMemberSince: '01.07.2019',
    club: 'FC Augsburg',
    previousClub: 'FC Nordsjaelland',
    uid: '1bb0f7af-5d64-4560-b422-e697e175601a',
    position: 'Linker Verteidiger',
    league: 'Bundesliga',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 38,
    name: 'David Colina',
    dateOfBirth: '19.07.2000',
    nationality: 'Kroatien',
    height: 1.74,
    foot: 'links',
    teamMemberSince: '14.01.2023',
    club: 'FC Augsburg',
    previousClub: 'HNK Hajduk Split',
    uid: '293e9d61-6fb6-4376-8d87-4855f5858d25',
    position: 'Linker Verteidiger',
    league: 'Bundesliga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 42,
    name: 'Aaron Zehnter',
    dateOfBirth: '31.10.2004',
    nationality: 'Deutschland',
    height: 1.8,
    foot: 'links',
    teamMemberSince: '01.07.2022',
    club: 'FC Augsburg',
    previousClub: 'FC Augsburg U19',
    uid: '19614046-af28-4004-96b2-d6baf77329fa',
    position: 'Linker Verteidiger',
    league: 'Bundesliga',
    marketValue: 300000,
  },
  {
    jerseyNumber: 2,
    name: 'Robert Gumny',
    dateOfBirth: '04.06.1998',
    nationality: 'Polen',
    height: 1.82,
    foot: 'rechts',
    teamMemberSince: '02.09.2020',
    club: 'FC Augsburg',
    previousClub: 'Lech Posen',
    uid: '1febede1-c092-43a7-8fad-13773bfe4970',
    position: 'Rechter Verteidiger',
    league: 'Bundesliga',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 30,
    name: 'Niklas Dorsch',
    dateOfBirth: '15.01.1998',
    nationality: 'Deutschland',
    height: 1.78,
    foot: 'rechts',
    teamMemberSince: '08.07.2021',
    club: 'FC Augsburg',
    previousClub: 'KAA Gent',
    uid: '17b495ca-56b0-4253-ae20-f80f2654e1c5',
    position: 'Defensives Mittelfeld',
    league: 'Bundesliga',
    marketValue: 8000000,
  },
  {
    jerseyNumber: 8,
    name: 'Renato Veiga',
    dateOfBirth: '29.07.2003',
    nationality: 'Portugal',
    height: 1.9,
    foot: 'links',
    teamMemberSince: '31.01.2023',
    club: 'FC Augsburg',
    previousClub: 'Sporting Lissabon B',
    uid: 'f3e1a9d1-7485-413e-9992-b50890a19d12',
    position: 'Defensives Mittelfeld',
    league: 'Bundesliga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 5,
    name: 'Tobias Strobl',
    dateOfBirth: '12.05.1990',
    nationality: 'Deutschland',
    height: 1.88,
    foot: 'rechts',
    teamMemberSince: '01.07.2020',
    club: 'FC Augsburg',
    previousClub: 'Borussia Mönchengladbach',
    uid: '0e3b16d0-8181-490b-a36a-25b8603dfcf5',
    position: 'Defensives Mittelfeld',
    league: 'Bundesliga',
    marketValue: 700000,
  },
  {
    jerseyNumber: 14,
    name: 'Julian Baumgartlinger',
    dateOfBirth: '02.01.1988',
    nationality: 'Österreich',
    height: 1.84,
    foot: 'rechts',
    teamMemberSince: '16.08.2022',
    club: 'FC Augsburg',
    previousClub: 'Bayer 04 Leverkusen',
    uid: 'c5ad9872-8f3a-45e6-84e2-502d93cd9297',
    position: 'Defensives Mittelfeld',
    league: 'Bundesliga',
    marketValue: 600000,
  },
  {
    jerseyNumber: 10,
    name: 'Arne Maier',
    dateOfBirth: '08.01.1999',
    nationality: 'Deutschland',
    height: 1.86,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'FC Augsburg',
    previousClub: 'Hertha BSC',
    uid: '226df74c-15ae-4959-981e-4dc6a921c020',
    position: 'Zentrales Mittelfeld',
    league: 'Bundesliga',
    marketValue: 5000000,
  },
  {
    jerseyNumber: 13,
    name: 'Elvis Rexhbecaj',
    dateOfBirth: '01.11.1997',
    nationality: 'Deutschland',
    height: 1.82,
    foot: 'links',
    teamMemberSince: '27.07.2022',
    club: 'FC Augsburg',
    previousClub: 'VfL Wolfsburg',
    uid: 'cd0d6b0b-9189-44ce-a2f3-ed55ce7f310a',
    position: 'Zentrales Mittelfeld',
    league: 'Bundesliga',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 27,
    name: 'Arne Engels',
    dateOfBirth: '08.09.2003',
    nationality: 'Belgien',
    height: 1.85,
    foot: 'rechts',
    teamMemberSince: '03.01.2023',
    club: 'FC Augsburg',
    previousClub: 'Club NXT',
    uid: '248f1923-eda7-4e0c-ad7a-ad21432f0973',
    position: 'Zentrales Mittelfeld',
    league: 'Bundesliga',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 24,
    name: 'Fredrik Jensen',
    dateOfBirth: '09.09.1997',
    nationality: 'Finnland',
    height: 1.83,
    foot: 'rechts',
    teamMemberSince: '01.07.2018',
    club: 'FC Augsburg',
    previousClub: 'FC Twente Enschede',
    uid: '430657ed-837f-430b-a9e4-05bfd9867b20',
    position: 'Offensives Mittelfeld',
    league: 'Bundesliga',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 16,
    name: 'Rubén Vargas',
    dateOfBirth: '05.08.1998',
    nationality: 'Schweiz',
    height: 1.79,
    foot: 'rechts',
    teamMemberSince: '01.07.2019',
    club: 'FC Augsburg',
    previousClub: 'FC Luzern',
    uid: '1a66f048-d8a5-4052-947a-9d9559cacf6f',
    position: 'Linksaußen',
    league: 'Bundesliga',
    marketValue: 6000000,
  },
  {
    jerseyNumber: 34,
    name: 'Nathanaël Mbuku',
    dateOfBirth: '16.03.2002',
    nationality: 'Frankreich',
    height: 1.7,
    foot: 'links',
    teamMemberSince: '30.01.2023',
    club: 'FC Augsburg',
    previousClub: 'Stade Reims',
    uid: 'e12104d2-9d20-4106-8854-15cca7d263ec',
    position: 'Linksaußen',
    league: 'Bundesliga',
    marketValue: 4000000,
  },
  {
    jerseyNumber: 28,
    name: 'André Hahn',
    dateOfBirth: '13.08.1990',
    nationality: 'Deutschland',
    height: 1.85,
    foot: 'rechts',
    teamMemberSince: '01.07.2018',
    club: 'FC Augsburg',
    previousClub: ': Ablöse 3,00 Mio. €',
    uid: 'ba8b7dd3-485e-4b9d-beef-872651203971',
    position: 'Rechtsaußen',
    league: 'Bundesliga',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 17,
    name: 'Noah Joel Sarenren Bazee',
    dateOfBirth: '21.08.1996',
    nationality: 'Deutschland',
    height: 1.83,
    foot: 'rechts',
    teamMemberSince: '01.07.2019',
    club: 'FC Augsburg',
    previousClub: ': Ablöse 1,70 Mio. €',
    uid: 'aaff9bbd-b4f4-43ee-87b6-912e25ee885b',
    position: 'Rechtsaußen',
    league: 'Bundesliga',
    marketValue: 800000,
  },
  {
    jerseyNumber: 20,
    name: 'Daniel Caligiuri',
    dateOfBirth: '15.01.1988',
    nationality: 'Italien',
    height: 1.82,
    foot: 'rechts',
    teamMemberSince: '01.07.2020',
    club: 'FC Augsburg',
    previousClub: 'FC Schalke 04',
    uid: '6d97eca0-7da5-4fc6-b170-050c5b3f1130',
    position: 'Rechtsaußen',
    league: 'Bundesliga',
    marketValue: 600000,
  },
  {
    jerseyNumber: 9,
    name: 'Ermedin Demirovic',
    dateOfBirth: '25.03.1998',
    nationality: 'Bosnien-Herzegowina',
    height: 1.85,
    foot: 'rechts',
    teamMemberSince: '08.07.2022',
    club: 'FC Augsburg',
    previousClub: 'SC Freiburg',
    uid: 'a2322d30-9568-473f-adbf-fe0d11186601',
    position: 'Mittelstürmer',
    league: 'Bundesliga',
    marketValue: 12000000,
  },
  {
    jerseyNumber: 11,
    name: 'Mergim Berisha',
    dateOfBirth: '11.05.1998',
    nationality: 'Deutschland',
    height: 1.88,
    foot: 'rechts',
    teamMemberSince: '31.08.2022',
    club: 'FC Augsburg',
    previousClub: 'Fenerbahce',
    uid: '490bb175-8111-42dc-bf61-0b87c7830d32',
    position: 'Mittelstürmer',
    league: 'Bundesliga',
    marketValue: 12000000,
  },
  {
    jerseyNumber: 7,
    name: 'Dion Beljo',
    dateOfBirth: '01.03.2002',
    nationality: 'Kroatien',
    height: 1.95,
    foot: 'links',
    teamMemberSince: '13.01.2023',
    club: 'FC Augsburg',
    previousClub: 'NK Osijek',
    uid: '8d935ff5-b937-4d91-97a4-66a460d6278f',
    position: 'Mittelstürmer',
    league: 'Bundesliga',
    marketValue: 6000000,
  },
  {
    jerseyNumber: 45,
    name: 'Kelvin Yeboah',
    dateOfBirth: '06.05.2000',
    nationality: 'Italien',
    height: 1.85,
    foot: 'rechts',
    teamMemberSince: '18.01.2023',
    club: 'FC Augsburg',
    previousClub: 'Genua CFC',
    uid: 'e28e89a2-eda0-4e7d-bb2b-b855e43d79b8',
    position: 'Mittelstürmer',
    league: 'Bundesliga',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 48,
    name: 'Irvin Cardona',
    dateOfBirth: '08.08.1997',
    nationality: 'Frankreich',
    height: 1.85,
    foot: 'rechts',
    teamMemberSince: '18.01.2023',
    club: 'FC Augsburg',
    previousClub: 'Stade Brest 29',
    uid: 'beb2d741-f4f6-49b3-993c-3c7a4eb5285e',
    position: 'Mittelstürmer',
    league: 'Bundesliga',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 1,
    name: 'Florian Müller',
    dateOfBirth: '13.11.1997',
    nationality: 'Deutschland',
    height: 1.9,
    foot: 'links',
    teamMemberSince: '01.07.2021',
    club: 'VfB Stuttgart',
    previousClub: '1.FSV Mainz 05',
    uid: 'c3945701-2d3c-406b-97b5-f24a23076152',
    position: 'Torwart',
    league: 'Bundesliga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 33,
    name: 'Fabian Bredlow',
    dateOfBirth: '02.03.1995',
    nationality: 'Deutschland',
    height: 1.9,
    foot: 'beidfüßig',
    teamMemberSince: '01.07.2019',
    club: 'VfB Stuttgart',
    previousClub: '1.FC Nürnberg',
    uid: '36b45789-11a9-4d63-926c-2700a83a0a06',
    position: 'Torwart',
    league: 'Bundesliga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 42,
    name: 'Florian Schock',
    dateOfBirth: '22.05.2001',
    nationality: 'Deutschland',
    height: 2,
    foot: 'rechts',
    teamMemberSince: '07.09.2021',
    club: 'VfB Stuttgart',
    previousClub: 'VfB Stuttgart II',
    uid: 'd8803177-9e8f-4ec2-8cca-2ac47e989597',
    position: 'Torwart',
    league: 'Bundesliga',
    marketValue: 200000,
  },
  {
    jerseyNumber: 5,
    name: 'Konstantinos Mavropanos',
    dateOfBirth: '11.12.1997',
    nationality: 'Griechenland',
    height: 1.94,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'VfB Stuttgart',
    previousClub: 'FC Arsenal',
    uid: '61d9ca7a-9715-47e1-aa6f-c0a55b4aa5d8',
    position: 'Innenverteidiger',
    league: 'Bundesliga',
    marketValue: 15000000,
  },
  {
    jerseyNumber: 21,
    name: 'Hiroki Ito',
    dateOfBirth: '12.05.1999',
    nationality: 'Japan',
    height: 1.88,
    foot: 'links',
    teamMemberSince: '01.07.2022',
    club: 'VfB Stuttgart',
    previousClub: 'Júbilo Iwata',
    uid: '02765abb-3029-4596-b34b-ebaa55824028',
    position: 'Innenverteidiger',
    league: 'Bundesliga',
    marketValue: 7000000,
  },
  {
    jerseyNumber: 2,
    name: 'Waldemar Anton',
    dateOfBirth: '20.07.1996',
    nationality: 'Deutschland',
    height: 1.89,
    foot: 'rechts',
    teamMemberSince: '28.07.2020',
    club: 'VfB Stuttgart',
    previousClub: ': Ablöse 4,00 Mio. €',
    uid: 'dcf4faf9-9d44-44e2-92cf-bef19b6f4dd0',
    position: 'Innenverteidiger',
    league: 'Bundesliga',
    marketValue: 4000000,
  },
  {
    jerseyNumber: 23,
    name: 'Dan-Axel Zagadou',
    dateOfBirth: '03.06.1999',
    nationality: 'Frankreich',
    height: 1.96,
    foot: 'links',
    teamMemberSince: '19.09.2022',
    club: 'VfB Stuttgart',
    previousClub: 'Vereinslos',
    uid: 'bc5c6a33-05fa-4bc4-a901-22a57f246023',
    position: 'Innenverteidiger',
    league: 'Bundesliga',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 37,
    name: 'Antonis Aidonis',
    dateOfBirth: '22.05.2001',
    nationality: 'Deutschland',
    height: 1.86,
    foot: 'rechts',
    teamMemberSince: '24.07.2020',
    club: 'VfB Stuttgart',
    previousClub: 'VfB Stuttgart II',
    uid: 'd9eb1438-ce5c-4ed5-a6dc-c6f70a973594',
    position: 'Innenverteidiger',
    league: 'Bundesliga',
    marketValue: 350000,
  },
  {
    jerseyNumber: 24,
    name: 'Borna Sosa',
    dateOfBirth: '21.01.1998',
    nationality: 'Kroatien',
    height: 1.87,
    foot: 'links',
    teamMemberSince: '01.07.2018',
    club: 'VfB Stuttgart',
    previousClub: 'GNK Dinamo Zagreb',
    uid: 'b0615085-2268-4ae5-af55-4a43c79855c8',
    position: 'Linker Verteidiger',
    league: 'Bundesliga',
    marketValue: 15000000,
  },
  {
    jerseyNumber: 4,
    name: 'Josha Vagnoman',
    dateOfBirth: '11.12.2000',
    nationality: 'Deutschland',
    height: 1.9,
    foot: 'rechts',
    teamMemberSince: '09.07.2022',
    club: 'VfB Stuttgart',
    previousClub: 'Hamburger SV',
    uid: '88655760-5ac5-4499-abfe-3a20b2385a82',
    position: 'Rechter Verteidiger',
    league: 'Bundesliga',
    marketValue: 5000000,
  },
  {
    jerseyNumber: 15,
    name: 'Pascal Stenzel',
    dateOfBirth: '20.03.1996',
    nationality: 'Deutschland',
    height: 1.83,
    foot: 'rechts',
    teamMemberSince: '01.07.2020',
    club: 'VfB Stuttgart',
    previousClub: 'SC Freiburg',
    uid: '4619f379-ae09-4dce-9259-bf15d42ac2a4',
    position: 'Rechter Verteidiger',
    league: 'Bundesliga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 3,
    name: 'Wataru Endo',
    dateOfBirth: '09.02.1993',
    nationality: 'Japan',
    height: 1.78,
    foot: 'rechts',
    teamMemberSince: '01.07.2020',
    club: 'VfB Stuttgart',
    previousClub: 'VV St. Truiden',
    uid: '168eb297-680a-4e3c-948f-ccbcbfa26b66',
    position: 'Defensives Mittelfeld',
    league: 'Bundesliga',
    marketValue: 6000000,
  },
  {
    jerseyNumber: 16,
    name: 'Atakan Karazor',
    dateOfBirth: '13.10.1996',
    nationality: 'Deutschland',
    height: 1.91,
    foot: 'rechts',
    teamMemberSince: '01.07.2019',
    club: 'VfB Stuttgart',
    previousClub: 'Holstein Kiel',
    uid: 'f7171e7c-4421-43f5-b379-53fa0b33fa19',
    position: 'Defensives Mittelfeld',
    league: 'Bundesliga',
    marketValue: 4000000,
  },
  {
    jerseyNumber: 8,
    name: 'Enzo Millot',
    dateOfBirth: '17.07.2002',
    nationality: 'Frankreich',
    height: 1.74,
    foot: 'links',
    teamMemberSince: '14.08.2021',
    club: 'VfB Stuttgart',
    previousClub: 'AS Monaco',
    uid: 'c73bb21b-3002-4f49-affb-ab2e072463e2',
    position: 'Zentrales Mittelfeld',
    league: 'Bundesliga',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 28,
    name: 'Nikolas Nartey',
    dateOfBirth: '22.02.2000',
    nationality: 'Dänemark',
    height: 1.85,
    foot: 'links',
    teamMemberSince: '29.08.2019',
    club: 'VfB Stuttgart',
    previousClub: '1.FC Köln',
    uid: 'cc6da13b-58ed-440f-9a5d-02e3c4d04ad4',
    position: 'Zentrales Mittelfeld',
    league: 'Bundesliga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 17,
    name: 'Genki Haraguchi',
    dateOfBirth: '09.05.1991',
    nationality: 'Japan',
    height: 1.78,
    foot: 'rechts',
    teamMemberSince: '30.01.2023',
    club: 'VfB Stuttgart',
    previousClub: '1.FC Union Berlin',
    uid: '1acd1c0c-e6fd-4a78-9899-8ed3dcc4747d',
    position: 'Offensives Mittelfeld',
    league: 'Bundesliga',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 25,
    name: 'Lilian Egloff',
    dateOfBirth: '20.08.2002',
    nationality: 'Deutschland',
    height: 1.82,
    foot: 'rechts',
    teamMemberSince: '01.07.2020',
    club: 'VfB Stuttgart',
    previousClub: 'VfB Stuttgart U19',
    uid: '1853dbfd-6b5a-4873-be11-cae778461b79',
    position: 'Offensives Mittelfeld',
    league: 'Bundesliga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 30,
    name: 'Ömer Beyaz',
    dateOfBirth: '29.08.2003',
    nationality: 'Türkei',
    height: 1.71,
    foot: 'links',
    teamMemberSince: '01.07.2021',
    club: 'VfB Stuttgart',
    previousClub: 'Fenerbahce',
    uid: '4529bd20-fe54-4f29-9dd3-c178aab743ce',
    position: 'Offensives Mittelfeld',
    league: 'Bundesliga',
    marketValue: 700000,
  },
  {
    jerseyNumber: 36,
    name: 'Laurin Ulrich',
    dateOfBirth: '31.01.2005',
    nationality: 'Deutschland',
    height: 1.8,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'VfB Stuttgart',
    previousClub: 'VfB Stuttgart U17',
    uid: 'a399ccac-4633-418f-99df-fd16043e4469',
    position: 'Offensives Mittelfeld',
    league: 'Bundesliga',
    marketValue: 500000,
  },
  {
    jerseyNumber: 14,
    name: 'Silas',
    dateOfBirth: '06.10.1998',
    nationality: 'DR Kongo',
    height: 1.89,
    foot: 'rechts',
    teamMemberSince: '13.08.2019',
    club: 'VfB Stuttgart',
    previousClub: 'Paris FC',
    uid: 'a853da1e-96a0-4ca5-bc02-3feb28333931',
    position: 'Rechtsaußen',
    league: 'Bundesliga',
    marketValue: 10000000,
  },
  {
    jerseyNumber: 22,
    name: 'Chris Führich',
    dateOfBirth: '09.01.1998',
    nationality: 'Deutschland',
    height: 1.81,
    foot: 'rechts',
    teamMemberSince: '19.07.2021',
    club: 'VfB Stuttgart',
    previousClub: 'SC Paderborn 07',
    uid: 'f1e44b94-2705-4ea3-952b-e5804edc4495',
    position: 'Rechtsaußen',
    league: 'Bundesliga',
    marketValue: 4000000,
  },
  {
    jerseyNumber: 7,
    name: 'Tanguy Coulibaly',
    dateOfBirth: '18.02.2001',
    nationality: 'Frankreich',
    height: 1.78,
    foot: 'links',
    teamMemberSince: '02.07.2019',
    club: 'VfB Stuttgart',
    previousClub: 'FC Paris Saint-Germain U19',
    uid: '077d234c-a1f5-4f99-9e0e-08526662d7a9',
    position: 'Rechtsaußen',
    league: 'Bundesliga',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 31,
    name: 'Gil Dias',
    dateOfBirth: '28.09.1996',
    nationality: 'Portugal',
    height: 1.86,
    foot: 'links',
    teamMemberSince: '30.01.2023',
    club: 'VfB Stuttgart',
    previousClub: 'Benfica Lissabon',
    uid: '126975f8-aff7-433d-9725-d87bf16807d8',
    position: 'Rechtsaußen',
    league: 'Bundesliga',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 9,
    name: 'Serhou Guirassy',
    dateOfBirth: '12.03.1996',
    nationality: 'Guinea',
    height: 1.87,
    foot: 'rechts',
    teamMemberSince: '01.09.2022',
    club: 'VfB Stuttgart',
    previousClub: 'FC Stade Rennes',
    uid: '911cd6d7-2721-4e81-8fa6-0172fe0e9ea8',
    position: 'Mittelstürmer',
    league: 'Bundesliga',
    marketValue: 10000000,
  },
  {
    jerseyNumber: 10,
    name: 'Tiago Tomás',
    dateOfBirth: '16.06.2002',
    nationality: 'Portugal',
    height: 1.8,
    foot: 'rechts',
    teamMemberSince: '30.01.2022',
    club: 'VfB Stuttgart',
    previousClub: 'Sporting Lissabon',
    uid: '12066455-23d3-4ed1-940b-a2fa103d2775',
    position: 'Mittelstürmer',
    league: 'Bundesliga',
    marketValue: 7000000,
  },
  {
    jerseyNumber: 11,
    name: 'Juan José Perea',
    dateOfBirth: '23.02.2000',
    nationality: 'Kolumbien',
    height: 1.8,
    foot: 'rechts',
    teamMemberSince: '08.07.2022',
    club: 'VfB Stuttgart',
    previousClub: 'PAS Giannina',
    uid: 'b59a1edf-bb97-403a-a840-e93cc529812a',
    position: 'Mittelstürmer',
    league: 'Bundesliga',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 20,
    name: 'Luca Pfeiffer',
    dateOfBirth: '20.08.1996',
    nationality: 'Deutschland',
    height: 1.96,
    foot: 'rechts',
    teamMemberSince: '02.08.2022',
    club: 'VfB Stuttgart',
    previousClub: 'FC Midtjylland',
    uid: 'bf43888d-e590-415c-babe-8492ee9af566',
    position: 'Mittelstürmer',
    league: 'Bundesliga',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 27,
    name: 'Alou Kuol',
    dateOfBirth: '05.07.2001',
    nationality: 'Australien',
    height: 1.81,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'VfB Stuttgart',
    previousClub: 'VfB Stuttgart II',
    uid: '5c9664a6-2e47-44b8-8802-2c6046e59fbc',
    position: 'Mittelstürmer',
    league: 'Bundesliga',
    marketValue: 300000,
  },
  {
    jerseyNumber: 27,
    name: 'Robin Zentner',
    dateOfBirth: '28.10.1994',
    nationality: 'Deutschland',
    height: 1.95,
    foot: 'rechts',
    teamMemberSince: '01.07.2014',
    club: '1.FSV Mainz 05',
    previousClub: ': Ablöse -',
    uid: '7e5471a0-82de-474b-89a3-8007584b17fa',
    position: 'Torwart',
    league: 'Bundesliga',
    marketValue: 5000000,
  },
  {
    jerseyNumber: 1,
    name: 'Finn Dahmen',
    dateOfBirth: '27.03.1998',
    nationality: 'Deutschland',
    height: 1.88,
    foot: 'rechts',
    teamMemberSince: '01.07.2018',
    club: '1.FSV Mainz 05',
    previousClub: ': Ablöse -',
    uid: 'c0d35911-fa91-460d-89ad-d7125bf0bc2d',
    position: 'Torwart',
    league: 'Bundesliga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 32,
    name: 'Lasse Rieß',
    dateOfBirth: '27.07.2001',
    nationality: 'Deutschland',
    height: 1.91,
    foot: 'rechts',
    teamMemberSince: '01.07.2021',
    club: '1.FSV Mainz 05',
    previousClub: '1.FSV Mainz 05 II',
    uid: '48cd0528-7c8f-418a-842f-1951f9c48eb4',
    position: 'Torwart',
    league: 'Bundesliga',
    marketValue: 200000,
  },
  {
    jerseyNumber: 5,
    name: 'Maxim Leitsch',
    dateOfBirth: '18.05.1998',
    nationality: 'Deutschland',
    height: 1.89,
    foot: 'links',
    teamMemberSince: '01.07.2022',
    club: '1.FSV Mainz 05',
    previousClub: 'VfL Bochum',
    uid: 'ff636eeb-2a32-47ab-9346-c36d1177c7e1',
    position: 'Innenverteidiger',
    league: 'Bundesliga',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 25,
    name: 'Andreas Hanche-Olsen',
    dateOfBirth: '17.01.1997',
    nationality: 'Norwegen',
    height: 1.85,
    foot: 'rechts',
    teamMemberSince: '13.01.2023',
    club: '1.FSV Mainz 05',
    previousClub: 'KAA Gent',
    uid: '19f4a6fb-6a79-4b60-b059-2139c4ecc912',
    position: 'Innenverteidiger',
    league: 'Bundesliga',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 16,
    name: 'Stefan Bell',
    dateOfBirth: '24.08.1991',
    nationality: 'Deutschland',
    height: 1.92,
    foot: 'rechts',
    teamMemberSince: '01.07.2010',
    club: '1.FSV Mainz 05',
    previousClub: ': Ablöse -',
    uid: 'c48c9e28-0546-4151-aa26-76999a7d197c',
    position: 'Innenverteidiger',
    league: 'Bundesliga',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 42,
    name: 'Alexander Hack',
    dateOfBirth: '08.09.1993',
    nationality: 'Deutschland',
    height: 1.93,
    foot: 'links',
    teamMemberSince: '30.11.2015',
    club: '1.FSV Mainz 05',
    previousClub: ': Ablöse -',
    uid: '134eb108-d669-458d-90ae-5d2dd82f7b0b',
    position: 'Innenverteidiger',
    league: 'Bundesliga',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 45,
    name: 'Philipp Schulz',
    dateOfBirth: '25.05.2005',
    nationality: 'Deutschland',
    height: 1.88,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: '1.FSV Mainz 05',
    previousClub: '1.FSV Mainz 05 U19',
    uid: '073905ca-2551-4965-8828-f5c672d308f3',
    position: 'Innenverteidiger',
    league: 'Bundesliga',
    marketValue: 300000,
  },
  {
    jerseyNumber: 19,
    name: 'Anthony Caci',
    dateOfBirth: '01.07.1997',
    nationality: 'Frankreich',
    height: 1.84,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: '1.FSV Mainz 05',
    previousClub: 'RC Straßburg Alsace',
    uid: '2fb33225-caf7-4d92-a3ff-e947e60206d8',
    position: 'Linker Verteidiger',
    league: 'Bundesliga',
    marketValue: 8000000,
  },
  {
    jerseyNumber: 3,
    name: 'Aarón Martín',
    dateOfBirth: '22.04.1997',
    nationality: 'Spanien',
    height: 1.8,
    foot: 'links',
    teamMemberSince: '01.07.2019',
    club: '1.FSV Mainz 05',
    previousClub: 'Espanyol Barcelona',
    uid: '001c9568-020a-4f1a-84d2-88541a71f6ef',
    position: 'Linker Verteidiger',
    league: 'Bundesliga',
    marketValue: 7000000,
  },
  {
    jerseyNumber: 30,
    name: 'Silvan Widmer',
    dateOfBirth: '05.03.1993',
    nationality: 'Schweiz',
    height: 1.83,
    foot: 'rechts',
    teamMemberSince: '09.07.2021',
    club: '1.FSV Mainz 05',
    previousClub: 'FC Basel 1893',
    uid: '43a8fc7d-03ee-4863-b489-ba0c3b200cdc',
    position: 'Rechter Verteidiger',
    league: 'Bundesliga',
    marketValue: 5000000,
  },
  {
    jerseyNumber: 21,
    name: 'Danny da Costa',
    dateOfBirth: '13.07.1993',
    nationality: 'Deutschland',
    height: 1.87,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: '1.FSV Mainz 05',
    previousClub: 'Eintracht Frankfurt',
    uid: '322a0fe7-6aec-47ca-9089-38421c0c3566',
    position: 'Rechter Verteidiger',
    league: 'Bundesliga',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 6,
    name: 'Anton Stach',
    dateOfBirth: '15.11.1998',
    nationality: 'Deutschland',
    height: 1.94,
    foot: 'beidfüßig',
    teamMemberSince: '31.07.2021',
    club: '1.FSV Mainz 05',
    previousClub: 'SpVgg Greuther Fürth',
    uid: '092c865e-2723-4de6-af7f-7b3effa8c83e',
    position: 'Defensives Mittelfeld',
    league: 'Bundesliga',
    marketValue: 15000000,
  },
  {
    jerseyNumber: 8,
    name: 'Leandro Barreiro',
    dateOfBirth: '03.01.2000',
    nationality: 'Luxemburg',
    height: 1.74,
    foot: 'rechts',
    teamMemberSince: '01.07.2018',
    club: '1.FSV Mainz 05',
    previousClub: ': Ablöse -',
    uid: '07b2dd24-85fa-4cca-8b61-383b3bac0c99',
    position: 'Zentrales Mittelfeld',
    league: 'Bundesliga',
    marketValue: 10000000,
  },
  {
    jerseyNumber: 31,
    name: 'Dominik Kohr',
    dateOfBirth: '31.01.1994',
    nationality: 'Deutschland',
    height: 1.83,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: '1.FSV Mainz 05',
    previousClub: 'Eintracht Frankfurt',
    uid: '9aecb0b0-4265-41b0-b2eb-9c93928ea2be',
    position: 'Zentrales Mittelfeld',
    league: 'Bundesliga',
    marketValue: 4000000,
  },
  {
    jerseyNumber: 20,
    name: 'Edimilson Fernandes',
    dateOfBirth: '15.04.1996',
    nationality: 'Schweiz',
    height: 1.9,
    foot: 'rechts',
    teamMemberSince: '01.07.2019',
    club: '1.FSV Mainz 05',
    previousClub: 'West Ham United',
    uid: 'e9a92aa1-e74e-45ba-9bf2-c1833f6e6bb4',
    position: 'Zentrales Mittelfeld',
    league: 'Bundesliga',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 4,
    name: 'Aymen Barkok',
    dateOfBirth: '21.05.1998',
    nationality: 'Marokko',
    height: 1.89,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: '1.FSV Mainz 05',
    previousClub: 'Eintracht Frankfurt',
    uid: 'f7daa965-41dc-4641-b15f-a8b772495d19',
    position: 'Zentrales Mittelfeld',
    league: 'Bundesliga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 41,
    name: 'Eniss Shabani',
    dateOfBirth: '29.05.2003',
    nationality: 'Deutschland',
    height: 1.8,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: '1.FSV Mainz 05',
    previousClub: '1.FSV Mainz 05 II',
    uid: '108b9ee2-7e2c-4a20-a6b6-e90f539e1700',
    position: 'Zentrales Mittelfeld',
    league: 'Bundesliga',
    marketValue: 200000,
  },
  {
    jerseyNumber: 7,
    name: 'Jae-sung Lee',
    dateOfBirth: '10.08.1992',
    nationality: 'Südkorea',
    height: 1.8,
    foot: 'links',
    teamMemberSince: '08.07.2021',
    club: '1.FSV Mainz 05',
    previousClub: 'Holstein Kiel',
    uid: 'd8400b45-2f34-4e83-93f3-af23ba6490e0',
    position: 'Offensives Mittelfeld',
    league: 'Bundesliga',
    marketValue: 4000000,
  },
  {
    jerseyNumber: 37,
    name: 'Delano Burgzorg',
    dateOfBirth: '07.11.1998',
    nationality: 'Niederlande',
    height: 1.86,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: '1.FSV Mainz 05',
    previousClub: 'Heracles Almelo',
    uid: '0d355e58-9baa-431c-b815-2ae4b65fb60f',
    position: 'Linksaußen',
    league: 'Bundesliga',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 43,
    name: 'Brajan Gruda',
    dateOfBirth: '31.05.2004',
    nationality: 'Deutschland',
    height: 1.78,
    foot: 'links',
    teamMemberSince: '18.11.2022',
    club: '1.FSV Mainz 05',
    previousClub: '1.FSV Mainz 05 U19',
    uid: '317362a8-77ee-4a89-a67e-db3b8146dddd',
    position: 'Rechtsaußen',
    league: 'Bundesliga',
    marketValue: 500000,
  },
  {
    jerseyNumber: 29,
    name: 'Jonathan Burkardt',
    dateOfBirth: '11.07.2000',
    nationality: 'Deutschland',
    height: 1.83,
    foot: 'rechts',
    teamMemberSince: '01.07.2018',
    club: '1.FSV Mainz 05',
    previousClub: ': Ablöse -',
    uid: '6e99a521-1ddf-4741-86af-066defb29bf7',
    position: 'Mittelstürmer',
    league: 'Bundesliga',
    marketValue: 12000000,
  },
  {
    jerseyNumber: 17,
    name: 'Ludovic Ajorque',
    dateOfBirth: '25.02.1994',
    nationality: 'Frankreich',
    height: 1.96,
    foot: 'links',
    teamMemberSince: '24.01.2023',
    club: '1.FSV Mainz 05',
    previousClub: 'RC Straßburg Alsace',
    uid: 'b8f5e42c-a34d-46b7-bf1c-dbe83a42b94b',
    position: 'Mittelstürmer',
    league: 'Bundesliga',
    marketValue: 9000000,
  },
  {
    jerseyNumber: 11,
    name: 'Marcus Ingvartsen',
    dateOfBirth: '04.01.1996',
    nationality: 'Dänemark',
    height: 1.87,
    foot: 'links',
    teamMemberSince: '01.07.2022',
    club: '1.FSV Mainz 05',
    previousClub: '1.FC Union Berlin',
    uid: '32ff34f2-db1e-4bf8-9692-fb23e078eb45',
    position: 'Mittelstürmer',
    league: 'Bundesliga',
    marketValue: 4000000,
  },
  {
    jerseyNumber: 9,
    name: 'Karim Onisiwo',
    dateOfBirth: '17.03.1992',
    nationality: 'Österreich',
    height: 1.88,
    foot: 'rechts',
    teamMemberSince: '05.01.2016',
    club: '1.FSV Mainz 05',
    previousClub: 'SV Mattersburg',
    uid: '67993481-0d03-44be-b609-e61d2d16b2a1',
    position: 'Mittelstürmer',
    league: 'Bundesliga',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 44,
    name: 'Nelson Weiper',
    dateOfBirth: '17.03.2005',
    nationality: 'Deutschland',
    height: 1.92,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: '1.FSV Mainz 05',
    previousClub: '1.FSV Mainz 05 U19',
    uid: 'a2c7fc62-7a81-4594-b166-c8be4d6ccd31',
    position: 'Mittelstürmer',
    league: 'Bundesliga',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 36,
    name: 'Marlon Mustapha',
    dateOfBirth: '24.05.2001',
    nationality: 'Österreich',
    height: 1.85,
    foot: 'rechts',
    teamMemberSince: '01.07.2020',
    club: '1.FSV Mainz 05',
    previousClub: '1.FSV Mainz 05 U19',
    uid: '341e61f9-7689-4a41-90f2-db429919db52',
    position: 'Mittelstürmer',
    league: 'Bundesliga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 20,
    name: 'Marvin Schwäbe',
    dateOfBirth: '25.04.1995',
    nationality: 'Deutschland',
    height: 1.9,
    foot: 'rechts',
    teamMemberSince: '01.07.2021',
    club: '1.FC Köln',
    previousClub: 'Bröndby IF',
    uid: '1d0232b6-562d-4c40-8848-52798093041d',
    position: 'Torwart',
    league: 'Bundesliga',
    marketValue: 5000000,
  },
  {
    jerseyNumber: 1,
    name: 'Timo Horn',
    dateOfBirth: '12.05.1993',
    nationality: 'Deutschland',
    height: 1.92,
    foot: 'links',
    teamMemberSince: '01.07.2011',
    club: '1.FC Köln',
    previousClub: '1.FC Köln II',
    uid: 'e5897c25-7130-4df2-a433-33e889b2b8b1',
    position: 'Torwart',
    league: 'Bundesliga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 44,
    name: 'Matthias Köbbing',
    dateOfBirth: '28.05.1997',
    nationality: 'Deutschland',
    height: 1.96,
    foot: 'beidfüßig',
    teamMemberSince: '08.03.2022',
    club: '1.FC Köln',
    previousClub: '1.FC Köln II',
    uid: 'cb538409-0a7b-4030-83cd-55dd573ba8d5',
    position: 'Torwart',
    league: 'Bundesliga',
    marketValue: 25000,
  },
  {
    jerseyNumber: 4,
    name: 'Timo Hübers',
    dateOfBirth: '20.07.1996',
    nationality: 'Deutschland',
    height: 1.9,
    foot: 'rechts',
    teamMemberSince: '01.07.2021',
    club: '1.FC Köln',
    previousClub: 'Hannover 96',
    uid: '8551466d-02f1-4a15-9353-7a4fa87cfad0',
    position: 'Innenverteidiger',
    league: 'Bundesliga',
    marketValue: 7000000,
  },
  {
    jerseyNumber: 15,
    name: 'Luca Kilian',
    dateOfBirth: '01.09.1999',
    nationality: 'Deutschland',
    height: 1.92,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: '1.FC Köln',
    previousClub: '1.FSV Mainz 05',
    uid: '54ee2a30-1ff6-4ae1-88ec-564b07ea80c3',
    position: 'Innenverteidiger',
    league: 'Bundesliga',
    marketValue: 4000000,
  },
  {
    jerseyNumber: 24,
    name: 'Julian Chabot',
    dateOfBirth: '12.02.1998',
    nationality: 'Deutschland',
    height: 1.95,
    foot: 'links',
    teamMemberSince: '26.01.2022',
    club: '1.FC Köln',
    previousClub: 'Sampdoria Genua',
    uid: 'da147034-59df-425a-a6dd-5dd258ed0724',
    position: 'Innenverteidiger',
    league: 'Bundesliga',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 5,
    name: 'Nikola Soldo',
    dateOfBirth: '25.01.2001',
    nationality: 'Kroatien',
    height: 1.89,
    foot: 'rechts',
    teamMemberSince: '01.09.2022',
    club: '1.FC Köln',
    previousClub: 'NK Lokomotiva Zagreb',
    uid: '5b90fcc3-5464-4434-acf9-15109af57aa7',
    position: 'Innenverteidiger',
    league: 'Bundesliga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 14,
    name: 'Jonas Hector',
    dateOfBirth: '27.05.1990',
    nationality: 'Deutschland',
    height: 1.85,
    foot: 'links',
    teamMemberSince: '01.07.2012',
    club: '1.FC Köln',
    previousClub: '1.FC Köln II',
    uid: '7abe5a5d-2257-431c-ac39-fd34e05f5ade',
    position: 'Linker Verteidiger',
    league: 'Bundesliga',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 3,
    name: 'Kristian Pedersen',
    dateOfBirth: '04.08.1994',
    nationality: 'Dänemark',
    height: 1.89,
    foot: 'links',
    teamMemberSince: '01.07.2022',
    club: '1.FC Köln',
    previousClub: 'Birmingham City',
    uid: 'f10d3f88-da21-46d8-834c-bc0c5f1c5849',
    position: 'Linker Verteidiger',
    league: 'Bundesliga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 2,
    name: 'Benno Schmitz',
    dateOfBirth: '17.11.1994',
    nationality: 'Deutschland',
    height: 1.82,
    foot: 'rechts',
    teamMemberSince: '01.07.2018',
    club: '1.FC Köln',
    previousClub: ': Ablöse 200 Tsd. €',
    uid: 'fcc225fe-1691-4c42-ab35-cba044a98ded',
    position: 'Rechter Verteidiger',
    league: 'Bundesliga',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 28,
    name: 'Ellyes Skhiri',
    dateOfBirth: '10.05.1995',
    nationality: 'Tunesien',
    height: 1.85,
    foot: 'rechts',
    teamMemberSince: '29.07.2019',
    club: '1.FC Köln',
    previousClub: 'HSC Montpellier',
    uid: '78b0d5ad-c4bd-41fd-a3bf-84b98b4bc300',
    position: 'Defensives Mittelfeld',
    league: 'Bundesliga',
    marketValue: 13000000,
  },
  {
    jerseyNumber: 7,
    name: 'Dejan Ljubicic',
    dateOfBirth: '08.10.1997',
    nationality: 'Österreich',
    height: 1.87,
    foot: 'rechts',
    teamMemberSince: '01.07.2021',
    club: '1.FC Köln',
    previousClub: 'SK Rapid Wien',
    uid: 'b2ec06e8-8b6c-4ddc-a2d4-6b359f86abbb',
    position: 'Defensives Mittelfeld',
    league: 'Bundesliga',
    marketValue: 10000000,
  },
  {
    jerseyNumber: 6,
    name: 'Eric Martel',
    dateOfBirth: '29.04.2002',
    nationality: 'Deutschland',
    height: 1.88,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: '1.FC Köln',
    previousClub: 'RasenBallsport Leipzig',
    uid: 'b3c291a2-6bba-4426-90ea-09b243bdbfae',
    position: 'Defensives Mittelfeld',
    league: 'Bundesliga',
    marketValue: 6000000,
  },
  {
    jerseyNumber: 8,
    name: 'Denis Huseinbasic',
    dateOfBirth: '03.07.2001',
    nationality: 'Deutschland',
    height: 1.84,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: '1.FC Köln',
    previousClub: 'Kickers Offenbach',
    uid: '40b4e5c8-01a0-419c-a9ec-685f02d1e6b1',
    position: 'Zentrales Mittelfeld',
    league: 'Bundesliga',
    marketValue: 5000000,
  },
  {
    jerseyNumber: 47,
    name: 'Mathias Olesen',
    dateOfBirth: '21.03.2001',
    nationality: 'Luxemburg',
    height: 1.88,
    foot: 'beidfüßig',
    teamMemberSince: '04.02.2022',
    club: '1.FC Köln',
    previousClub: '1.FC Köln II',
    uid: '7db3110d-3a41-4c03-acf8-8915ca1592ce',
    position: 'Zentrales Mittelfeld',
    league: 'Bundesliga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 17,
    name: 'Kingsley Schindler',
    dateOfBirth: '12.07.1993',
    nationality: 'Ghana',
    height: 1.83,
    foot: 'rechts',
    teamMemberSince: '01.07.2019',
    club: '1.FC Köln',
    previousClub: 'Holstein Kiel',
    uid: 'dde45b4d-231f-4314-9061-ad63e7b97501',
    position: 'Rechtes Mittelfeld',
    league: 'Bundesliga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 11,
    name: 'Florian Kainz',
    dateOfBirth: '24.10.1992',
    nationality: 'Österreich',
    height: 1.76,
    foot: 'beidfüßig',
    teamMemberSince: '18.01.2019',
    club: '1.FC Köln',
    previousClub: 'SV Werder Bremen',
    uid: '0372a77e-c051-4b78-ab9a-27ab43f2f5e1',
    position: 'Linksaußen',
    league: 'Bundesliga',
    marketValue: 4000000,
  },
  {
    jerseyNumber: 29,
    name: 'Jan Thielmann',
    dateOfBirth: '26.05.2002',
    nationality: 'Deutschland',
    height: 1.78,
    foot: 'rechts',
    teamMemberSince: '17.01.2020',
    club: '1.FC Köln',
    previousClub: '1.FC Köln U19',
    uid: '51922ced-4db9-41ea-ac87-cd1bb48dc371',
    position: 'Rechtsaußen',
    league: 'Bundesliga',
    marketValue: 7000000,
  },
  {
    jerseyNumber: 37,
    name: 'Linton Maina',
    dateOfBirth: '23.06.1999',
    nationality: 'Deutschland',
    height: 1.8,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: '1.FC Köln',
    previousClub: 'Hannover 96',
    uid: 'c1070e2f-1150-482b-8b0c-61ef11f44d69',
    position: 'Rechtsaußen',
    league: 'Bundesliga',
    marketValue: 4000000,
  },
  {
    jerseyNumber: 19,
    name: 'Dimitrios Limnios',
    dateOfBirth: '27.05.1998',
    nationality: 'Griechenland',
    height: 1.78,
    foot: 'rechts',
    teamMemberSince: '21.09.2020',
    club: '1.FC Köln',
    previousClub: 'PAOK Thessaloniki',
    uid: '39e4914e-e0e1-4807-b76e-38f7379fff78',
    position: 'Rechtsaußen',
    league: 'Bundesliga',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 21,
    name: 'Steffen Tigges',
    dateOfBirth: '31.07.1998',
    nationality: 'Deutschland',
    height: 1.94,
    foot: 'links',
    teamMemberSince: '01.07.2022',
    club: '1.FC Köln',
    previousClub: 'Borussia Dortmund',
    uid: 'f6884b12-3f9f-4816-b1bf-f59287236946',
    position: 'Mittelstürmer',
    league: 'Bundesliga',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 13,
    name: 'Mark Uth',
    dateOfBirth: '24.08.1991',
    nationality: 'Deutschland',
    height: 1.85,
    foot: 'links',
    teamMemberSince: '01.07.2021',
    club: '1.FC Köln',
    previousClub: 'FC Schalke 04',
    uid: '51e3fd91-fe26-4112-8ae7-42f3836ec717',
    position: 'Mittelstürmer',
    league: 'Bundesliga',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 23,
    name: 'Sargis Adamyan',
    dateOfBirth: '23.05.1993',
    nationality: 'Armenien',
    height: 1.84,
    foot: 'rechts',
    teamMemberSince: '05.07.2022',
    club: '1.FC Köln',
    previousClub: 'TSG 1899 Hoffenheim',
    uid: '7a7c2c70-92db-44e1-aeb6-cb8ab75fb783',
    position: 'Mittelstürmer',
    league: 'Bundesliga',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 25,
    name: 'Tim Lemperle',
    dateOfBirth: '05.02.2002',
    nationality: 'Deutschland',
    height: 1.89,
    foot: 'rechts',
    teamMemberSince: '01.07.2020',
    club: '1.FC Köln',
    previousClub: '1.FC Köln U19',
    uid: '0beeb537-10f1-4453-ad8e-35569a5b0316',
    position: 'Mittelstürmer',
    league: 'Bundesliga',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 27,
    name: 'Davie Selke',
    dateOfBirth: '20.01.1995',
    nationality: 'Deutschland',
    height: 1.95,
    foot: 'rechts',
    teamMemberSince: '02.01.2023',
    club: '1.FC Köln',
    previousClub: 'Hertha BSC',
    uid: 'ae1ffbea-0db5-4cd7-a893-88081d506cde',
    position: 'Mittelstürmer',
    league: 'Bundesliga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 9,
    name: 'Sebastian Andersson',
    dateOfBirth: '15.07.1991',
    nationality: 'Schweden',
    height: 1.9,
    foot: 'rechts',
    teamMemberSince: '15.09.2020',
    club: '1.FC Köln',
    previousClub: '1.FC Union Berlin',
    uid: '1c7296a4-3432-4bc6-917a-26042212af10',
    position: 'Mittelstürmer',
    league: 'Bundesliga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 33,
    name: 'Florian Dietz',
    dateOfBirth: '03.08.1998',
    nationality: 'Deutschland',
    height: 1.9,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: '1.FC Köln',
    previousClub: '1.FC Köln II',
    uid: '00f48ec3-a4a5-4daa-ab58-83a73edbfcba',
    position: 'Mittelstürmer',
    league: 'Bundesliga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 1,
    name: 'Oliver Christensen',
    dateOfBirth: '22.03.1999',
    nationality: 'Dänemark',
    height: 1.9,
    foot: 'rechts',
    teamMemberSince: '26.08.2021',
    club: 'Hertha BSC',
    previousClub: 'Odense Boldklub',
    uid: 'f01b6211-4097-44b0-b862-a731cf78928f',
    position: 'Torwart',
    league: 'Bundesliga',
    marketValue: 4000000,
  },
  {
    jerseyNumber: 12,
    name: 'Tjark Ernst',
    dateOfBirth: '15.03.2003',
    nationality: 'Deutschland',
    height: 1.93,
    foot: 'beidfüßig',
    teamMemberSince: '01.09.2022',
    club: 'Hertha BSC',
    previousClub: 'Hertha BSC II',
    uid: 'fd019563-0d8c-461a-8095-8fc64776fea8',
    position: 'Torwart',
    league: 'Bundesliga',
    marketValue: 400000,
  },
  {
    jerseyNumber: 22,
    name: 'Rune Jarstein',
    dateOfBirth: '29.09.1984',
    nationality: 'Norwegen',
    height: 1.92,
    foot: 'rechts',
    teamMemberSince: '01.01.2014',
    club: 'Hertha BSC',
    previousClub: 'Viking FK',
    uid: '81af52bf-5459-49df-b914-4db041f2efa6',
    position: 'Torwart',
    league: 'Bundesliga',
    marketValue: 300000,
  },
  {
    jerseyNumber: 20,
    name: 'Marc Oliver Kempf',
    dateOfBirth: '28.01.1995',
    nationality: 'Deutschland',
    height: 1.86,
    foot: 'links',
    teamMemberSince: '25.01.2022',
    club: 'Hertha BSC',
    previousClub: 'VfB Stuttgart',
    uid: 'cd0eac4d-4f52-4c0b-9a27-c24884ca1963',
    position: 'Innenverteidiger',
    league: 'Bundesliga',
    marketValue: 4000000,
  },
  {
    jerseyNumber: 31,
    name: 'Márton Dárdai',
    dateOfBirth: '12.02.2002',
    nationality: 'Deutschland',
    height: 1.88,
    foot: 'links',
    teamMemberSince: '01.07.2020',
    club: 'Hertha BSC',
    previousClub: 'Hertha BSC II',
    uid: 'bf374f9a-677b-4622-97b7-7754444e4078',
    position: 'Innenverteidiger',
    league: 'Bundesliga',
    marketValue: 4000000,
  },
  {
    jerseyNumber: 3,
    name: 'Agustín Rogel',
    dateOfBirth: '17.10.1997',
    nationality: 'Uruguay',
    height: 1.91,
    foot: 'rechts',
    teamMemberSince: '31.08.2022',
    club: 'Hertha BSC',
    previousClub: 'Club Estudiantes de La Plata',
    uid: 'f3520c49-f535-44b7-877e-f7024210d436',
    position: 'Innenverteidiger',
    league: 'Bundesliga',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 5,
    name: 'Filip Uremovic',
    dateOfBirth: '11.02.1997',
    nationality: 'Kroatien',
    height: 1.84,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'Hertha BSC',
    previousClub: 'Rubin Kazan',
    uid: '4478ceb5-dcac-4190-bdb1-24d6fe7012c2',
    position: 'Innenverteidiger',
    league: 'Bundesliga',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 17,
    name: 'Maximilian Mittelstädt',
    dateOfBirth: '18.03.1997',
    nationality: 'Deutschland',
    height: 1.8,
    foot: 'links',
    teamMemberSince: '19.08.2015',
    club: 'Hertha BSC',
    previousClub: ': Ablöse -',
    uid: '2b600794-3e23-47d1-bab8-5f4801612917',
    position: 'Linker Verteidiger',
    league: 'Bundesliga',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 21,
    name: 'Marvin Plattenhardt',
    dateOfBirth: '26.01.1992',
    nationality: 'Deutschland',
    height: 1.81,
    foot: 'links',
    teamMemberSince: '01.07.2014',
    club: 'Hertha BSC',
    previousClub: '1.FC Nürnberg',
    uid: '1125384c-5f5e-40c8-80ac-f07b3cc0eef1',
    position: 'Linker Verteidiger',
    league: 'Bundesliga',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 16,
    name: 'Jonjoe Kenny',
    dateOfBirth: '15.03.1997',
    nationality: 'England',
    height: 1.76,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'Hertha BSC',
    previousClub: 'FC Everton',
    uid: 'df2d7d43-173e-44c6-bc31-069b158e19c2',
    position: 'Rechter Verteidiger',
    league: 'Bundesliga',
    marketValue: 4000000,
  },
  {
    jerseyNumber: 2,
    name: 'Peter Pekarik',
    dateOfBirth: '30.10.1986',
    nationality: 'Slowakei',
    height: 1.77,
    foot: 'rechts',
    teamMemberSince: '31.08.2012',
    club: 'Hertha BSC',
    previousClub: 'VfL Wolfsburg',
    uid: '3f7cead3-2b89-4345-8286-10ea90046d4d',
    position: 'Rechter Verteidiger',
    league: 'Bundesliga',
    marketValue: 500000,
  },
  {
    jerseyNumber: 42,
    name: 'Julian Eitschberger',
    dateOfBirth: '05.03.2004',
    nationality: 'Deutschland',
    height: 1.79,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'Hertha BSC',
    previousClub: 'Hertha BSC U19',
    uid: 'a994ef4f-f045-4d2e-aded-96a69a040806',
    position: 'Rechter Verteidiger',
    league: 'Bundesliga',
    marketValue: 350000,
  },
  {
    jerseyNumber: 29,
    name: 'Lucas Tousart',
    dateOfBirth: '29.04.1997',
    nationality: 'Frankreich',
    height: 1.85,
    foot: 'rechts',
    teamMemberSince: '27.01.2020',
    club: 'Hertha BSC',
    previousClub: ': Ablöse 25,00 Mio. €',
    uid: '66fe4871-a944-484a-9484-dff043c1ec16',
    position: 'Defensives Mittelfeld',
    league: 'Bundesliga',
    marketValue: 11000000,
  },
  {
    jerseyNumber: 34,
    name: 'Ivan Sunjic',
    dateOfBirth: '09.10.1996',
    nationality: 'Kroatien',
    height: 1.85,
    foot: 'rechts',
    teamMemberSince: '05.07.2022',
    club: 'Hertha BSC',
    previousClub: 'Birmingham City',
    uid: '2adb3515-3bf4-486a-846d-2201cbe3c2d8',
    position: 'Defensives Mittelfeld',
    league: 'Bundesliga',
    marketValue: 4000000,
  },
  {
    jerseyNumber: 6,
    name: 'Tolga Cigerci',
    dateOfBirth: '23.03.1992',
    nationality: 'Türkei',
    height: 1.85,
    foot: 'rechts',
    teamMemberSince: '31.01.2023',
    club: 'Hertha BSC',
    previousClub: 'MKE Ankaragücü',
    uid: '02fa09d0-1fdb-45c5-b478-7a9e15162ef3',
    position: 'Defensives Mittelfeld',
    league: 'Bundesliga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 8,
    name: 'Suat Serdar',
    dateOfBirth: '11.04.1997',
    nationality: 'Deutschland',
    height: 1.84,
    foot: 'rechts',
    teamMemberSince: '01.07.2021',
    club: 'Hertha BSC',
    previousClub: 'FC Schalke 04',
    uid: '2d66d58e-2784-44a8-98c4-e5affd5c2c0d',
    position: 'Zentrales Mittelfeld',
    league: 'Bundesliga',
    marketValue: 6000000,
  },
  {
    jerseyNumber: 10,
    name: 'Jean-Paul Boëtius',
    dateOfBirth: '22.03.1994',
    nationality: 'Niederlande',
    height: 1.78,
    foot: 'rechts',
    teamMemberSince: '08.08.2022',
    club: 'Hertha BSC',
    previousClub: '1.FSV Mainz 05',
    uid: '78cb8099-5fc8-47dc-b998-43c4951dac0b',
    position: 'Offensives Mittelfeld',
    league: 'Bundesliga',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 27,
    name: 'Kevin-Prince Boateng',
    dateOfBirth: '06.03.1987',
    nationality: 'Ghana',
    height: 1.86,
    foot: 'rechts',
    teamMemberSince: '01.07.2021',
    club: 'Hertha BSC',
    previousClub: 'AC Monza',
    uid: '769357bc-6786-452c-adf5-6af3037ae413',
    position: 'Offensives Mittelfeld',
    league: 'Bundesliga',
    marketValue: 700000,
  },
  {
    jerseyNumber: 40,
    name: 'Chidera Ejuke',
    dateOfBirth: '02.01.1998',
    nationality: 'Nigeria',
    height: 1.76,
    foot: 'rechts',
    teamMemberSince: '13.07.2022',
    club: 'Hertha BSC',
    previousClub: 'ZSKA Moskau',
    uid: '38e8f6a4-9ee9-49e4-87c7-050e60d2e0fb',
    position: 'Linksaußen',
    league: 'Bundesliga',
    marketValue: 7000000,
  },
  {
    jerseyNumber: 14,
    name: 'Dodi Lukébakio',
    dateOfBirth: '24.09.1997',
    nationality: 'Belgien',
    height: 1.87,
    foot: 'links',
    teamMemberSince: '01.08.2019',
    club: 'Hertha BSC',
    previousClub: 'FC Watford',
    uid: '0932678a-6e9b-465f-b52d-a16c4bc05314',
    position: 'Rechtsaußen',
    league: 'Bundesliga',
    marketValue: 12000000,
  },
  {
    jerseyNumber: 23,
    name: 'Marco Richter',
    dateOfBirth: '24.11.1997',
    nationality: 'Deutschland',
    height: 1.76,
    foot: 'rechts',
    teamMemberSince: '09.08.2021',
    club: 'Hertha BSC',
    previousClub: 'FC Augsburg',
    uid: '3c1d2581-1587-475f-99b9-64819a1278ac',
    position: 'Rechtsaußen',
    league: 'Bundesliga',
    marketValue: 6000000,
  },
  {
    jerseyNumber: 28,
    name: 'Kelian Nsona',
    dateOfBirth: '11.05.2002',
    nationality: 'Frankreich',
    height: 1.89,
    foot: 'rechts',
    teamMemberSince: '31.01.2022',
    club: 'Hertha BSC',
    previousClub: 'SM Caen',
    uid: '2057fa50-e00e-414f-ae55-e50eea39f231',
    position: 'Rechtsaußen',
    league: 'Bundesliga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 24,
    name: 'Jessic Ngankam',
    dateOfBirth: '20.07.2000',
    nationality: 'Deutschland',
    height: 1.84,
    foot: 'rechts',
    teamMemberSince: '02.07.2022',
    club: 'Hertha BSC',
    previousClub: 'SpVgg Greuther Fürth',
    uid: '363e9107-6345-464a-a24e-4f5d2c53d8a0',
    position: 'Mittelstürmer',
    league: 'Bundesliga',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 18,
    name: 'Wilfried Kanga',
    dateOfBirth: '21.02.1998',
    nationality: 'Elfenbeinküste',
    height: 1.89,
    foot: 'rechts',
    teamMemberSince: '30.07.2022',
    club: 'Hertha BSC',
    previousClub: 'BSC Young Boys',
    uid: '389dd5bb-489e-4a0c-8186-a53a832e746a',
    position: 'Mittelstürmer',
    league: 'Bundesliga',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 7,
    name: 'Florian Niederlechner',
    dateOfBirth: '24.10.1990',
    nationality: 'Deutschland',
    height: 1.87,
    foot: 'rechts',
    teamMemberSince: '18.01.2023',
    club: 'Hertha BSC',
    previousClub: 'FC Augsburg',
    uid: '7ae57175-254a-41cb-b0d9-2d88c3c62b09',
    position: 'Mittelstürmer',
    league: 'Bundesliga',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 19,
    name: 'Stevan Jovetic',
    dateOfBirth: '02.11.1989',
    nationality: 'Montenegro',
    height: 1.83,
    foot: 'rechts',
    teamMemberSince: '27.07.2021',
    club: 'Hertha BSC',
    previousClub: 'AS Monaco',
    uid: '93b7df09-841a-4bff-9c99-1a44b4f44afd',
    position: 'Mittelstürmer',
    league: 'Bundesliga',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 39,
    name: 'Derry Scherhant',
    dateOfBirth: '10.11.2002',
    nationality: 'Deutschland',
    height: 1.85,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'Hertha BSC',
    previousClub: 'Hertha BSC II',
    uid: '247c06b2-b744-4e70-aa84-69c2f034fb7b',
    position: 'Mittelstürmer',
    league: 'Bundesliga',
    marketValue: 700000,
  },
  {
    jerseyNumber: 1,
    name: 'Jiri Pavlenka',
    dateOfBirth: '14.04.1992',
    nationality: 'Tschechien',
    height: 1.96,
    foot: 'rechts',
    teamMemberSince: '01.07.2017',
    club: 'SV Werder Bremen',
    previousClub: ': Ablöse 3,00 Mio. €',
    uid: 'a75e1eea-0fc4-4f82-9730-7f6f2c266998',
    position: 'Torwart',
    league: 'Bundesliga',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 30,
    name: 'Michael Zetterer',
    dateOfBirth: '12.07.1995',
    nationality: 'Deutschland',
    height: 1.87,
    foot: 'beidfüßig',
    teamMemberSince: '27.01.2015',
    club: 'SV Werder Bremen',
    previousClub: 'SpVgg Unterhaching',
    uid: 'bd97a962-4d04-40a4-aaff-9a467db95c0d',
    position: 'Torwart',
    league: 'Bundesliga',
    marketValue: 500000,
  },
  {
    jerseyNumber: 38,
    name: 'Dudu',
    dateOfBirth: '10.02.1999',
    nationality: 'Deutschland',
    height: 1.96,
    foot: 'rechts',
    teamMemberSince: '01.07.2020',
    club: 'SV Werder Bremen',
    previousClub: 'SV Werder Bremen II',
    uid: 'a3a34d4e-023c-4f51-aa0c-0ce7918db854',
    position: 'Torwart',
    league: 'Bundesliga',
    marketValue: 200000,
  },
  {
    jerseyNumber: 32,
    name: 'Marco Friedl',
    dateOfBirth: '16.03.1998',
    nationality: 'Österreich',
    height: 1.87,
    foot: 'links',
    teamMemberSince: '01.07.2019',
    club: 'SV Werder Bremen',
    previousClub: 'FC Bayern München',
    uid: 'ac7d99c6-2a3f-4d56-9aed-7b49b14e14cc',
    position: 'Innenverteidiger',
    league: 'Bundesliga',
    marketValue: 6000000,
  },
  {
    jerseyNumber: 5,
    name: 'Amos Pieper',
    dateOfBirth: '17.01.1998',
    nationality: 'Deutschland',
    height: 1.92,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'SV Werder Bremen',
    previousClub: 'Arminia Bielefeld',
    uid: '7e895dee-30bc-4342-8381-d7314b71c7bd',
    position: 'Innenverteidiger',
    league: 'Bundesliga',
    marketValue: 6000000,
  },
  {
    jerseyNumber: 4,
    name: 'Niklas Stark',
    dateOfBirth: '14.04.1995',
    nationality: 'Deutschland',
    height: 1.9,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'SV Werder Bremen',
    previousClub: 'Hertha BSC',
    uid: '14c799df-4be5-4a08-9fe1-a6f39d1097d6',
    position: 'Innenverteidiger',
    league: 'Bundesliga',
    marketValue: 5000000,
  },
  {
    jerseyNumber: 13,
    name: 'Milos Veljkovic',
    dateOfBirth: '26.09.1995',
    nationality: 'Serbien',
    height: 1.88,
    foot: 'rechts',
    teamMemberSince: '01.02.2016',
    club: 'SV Werder Bremen',
    previousClub: 'Tottenham Hotspur U21',
    uid: '2959f7c7-5713-4860-89bf-50f2f5016d1c',
    position: 'Innenverteidiger',
    league: 'Bundesliga',
    marketValue: 5000000,
  },
  {
    jerseyNumber: 39,
    name: 'Fabio Chiarodia',
    dateOfBirth: '05.06.2005',
    nationality: 'Italien',
    height: 1.86,
    foot: 'links',
    teamMemberSince: '07.10.2021',
    club: 'SV Werder Bremen',
    previousClub: 'SV Werder Bremen U19',
    uid: 'aa019d60-07c7-4b7c-8d11-513d61d43f61',
    position: 'Innenverteidiger',
    league: 'Bundesliga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 26,
    name: 'Lee Buchanan',
    dateOfBirth: '07.03.2001',
    nationality: 'England',
    height: 1.79,
    foot: 'links',
    teamMemberSince: '04.07.2022',
    club: 'SV Werder Bremen',
    previousClub: 'Derby County',
    uid: '7ab2450c-f5d7-448c-8e78-339ad5bdd941',
    position: 'Linker Verteidiger',
    league: 'Bundesliga',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 3,
    name: 'Anthony Jung',
    dateOfBirth: '03.11.1991',
    nationality: 'Deutschland',
    height: 1.86,
    foot: 'links',
    teamMemberSince: '01.07.2021',
    club: 'SV Werder Bremen',
    previousClub: 'Bröndby IF',
    uid: 'af63d2af-5ca5-4500-90f2-7a67b2d06aaa',
    position: 'Linker Verteidiger',
    league: 'Bundesliga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 8,
    name: 'Mitchell Weiser',
    dateOfBirth: '21.04.1994',
    nationality: 'Deutschland',
    height: 1.77,
    foot: 'rechts',
    teamMemberSince: '15.07.2022',
    club: 'SV Werder Bremen',
    previousClub: 'Bayer 04 Leverkusen',
    uid: 'eb472f64-0a24-48fa-a0e3-fe0f9c17bf94',
    position: 'Rechter Verteidiger',
    league: 'Bundesliga',
    marketValue: 5000000,
  },
  {
    jerseyNumber: 27,
    name: 'Felix Agu',
    dateOfBirth: '27.09.1999',
    nationality: 'Deutschland',
    height: 1.8,
    foot: 'rechts',
    teamMemberSince: '01.07.2020',
    club: 'SV Werder Bremen',
    previousClub: 'VfL Osnabrück',
    uid: '563d76d3-d198-4c48-9a6a-fabaf043f47f',
    position: 'Rechter Verteidiger',
    league: 'Bundesliga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 28,
    name: 'Ilia Gruev',
    dateOfBirth: '06.05.2000',
    nationality: 'Bulgarien',
    height: 1.85,
    foot: 'links',
    teamMemberSince: '01.07.2019',
    club: 'SV Werder Bremen',
    previousClub: 'SV Werder Bremen U19',
    uid: '0d081370-e955-48f1-83a1-5bf687c7a060',
    position: 'Defensives Mittelfeld',
    league: 'Bundesliga',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 36,
    name: 'Christian Groß',
    dateOfBirth: '08.02.1989',
    nationality: 'Deutschland',
    height: 1.82,
    foot: 'beidfüßig',
    teamMemberSince: '01.08.2020',
    club: 'SV Werder Bremen',
    previousClub: 'SV Werder Bremen II',
    uid: '5e51a6b9-cba7-4310-a278-de8c2d60e13a',
    position: 'Defensives Mittelfeld',
    league: 'Bundesliga',
    marketValue: 500000,
  },
  {
    jerseyNumber: 19,
    name: 'Dikeni Salifou',
    dateOfBirth: '08.06.2003',
    nationality: 'Togo',
    height: 1.91,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'SV Werder Bremen',
    previousClub: 'FC Augsburg U19',
    uid: 'fbc4c011-602a-4b9f-a17a-eefeea29504d',
    position: 'Defensives Mittelfeld',
    league: 'Bundesliga',
    marketValue: 200000,
  },
  {
    jerseyNumber: 6,
    name: 'Jens Stage',
    dateOfBirth: '08.11.1996',
    nationality: 'Dänemark',
    height: 1.87,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'SV Werder Bremen',
    previousClub: 'FC Kopenhagen',
    uid: '75bd6e02-0376-4665-99b1-8c54b1493680',
    position: 'Zentrales Mittelfeld',
    league: 'Bundesliga',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 34,
    name: 'Jean Manuel Mbom',
    dateOfBirth: '24.02.2000',
    nationality: 'Deutschland',
    height: 1.85,
    foot: 'rechts',
    teamMemberSince: '01.07.2018',
    club: 'SV Werder Bremen',
    previousClub: 'SV Werder Bremen U19',
    uid: '5f3f2200-6134-4e6c-aa67-5a6a087c4640',
    position: 'Zentrales Mittelfeld',
    league: 'Bundesliga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 20,
    name: 'Romano Schmid',
    dateOfBirth: '27.01.2000',
    nationality: 'Österreich',
    height: 1.68,
    foot: 'rechts',
    teamMemberSince: '03.01.2019',
    club: 'SV Werder Bremen',
    previousClub: ': Ablöse 1,00 Mio. €',
    uid: '05ea1f42-6302-4010-b840-abebc851b816',
    position: 'Offensives Mittelfeld',
    league: 'Bundesliga',
    marketValue: 4000000,
  },
  {
    jerseyNumber: 10,
    name: 'Leonardo Bittencourt',
    dateOfBirth: '19.12.1993',
    nationality: 'Deutschland',
    height: 1.71,
    foot: 'rechts',
    teamMemberSince: '15.07.2020',
    club: 'SV Werder Bremen',
    previousClub: 'TSG 1899 Hoffenheim',
    uid: '419826bc-6314-4713-8555-890bd5caec04',
    position: 'Offensives Mittelfeld',
    league: 'Bundesliga',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 22,
    name: 'Niklas Schmidt',
    dateOfBirth: '01.03.1998',
    nationality: 'Deutschland',
    height: 1.84,
    foot: 'beidfüßig',
    teamMemberSince: '01.07.2017',
    club: 'SV Werder Bremen',
    previousClub: 'SV Werder Bremen II',
    uid: '9ffabe56-45a8-4059-b9f2-18f8ccdf0579',
    position: 'Offensives Mittelfeld',
    league: 'Bundesliga',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 17,
    name: 'Maximilian Philipp',
    dateOfBirth: '01.03.1994',
    nationality: 'Deutschland',
    height: 1.83,
    foot: 'rechts',
    teamMemberSince: '30.01.2023',
    club: 'SV Werder Bremen',
    previousClub: 'VfL Wolfsburg',
    uid: '1eac4ced-7e3e-4acd-ab9b-158bdd5c574e',
    position: 'Hängende Spitze',
    league: 'Bundesliga',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 11,
    name: 'Niclas Füllkrug',
    dateOfBirth: '09.02.1993',
    nationality: 'Deutschland',
    height: 1.89,
    foot: 'rechts',
    teamMemberSince: '01.07.2019',
    club: 'SV Werder Bremen',
    previousClub: ': Ablöse 6,50 Mio. €',
    uid: '4b3fe943-e8a0-423d-a221-22dc6334d93e',
    position: 'Mittelstürmer',
    league: 'Bundesliga',
    marketValue: 13000000,
  },
  {
    jerseyNumber: 7,
    name: 'Marvin Ducksch',
    dateOfBirth: '07.03.1994',
    nationality: 'Deutschland',
    height: 1.88,
    foot: 'rechts',
    teamMemberSince: '25.08.2021',
    club: 'SV Werder Bremen',
    previousClub: 'Hannover 96',
    uid: 'a9459e10-8332-4292-bd0e-e98a0cb898c3',
    position: 'Mittelstürmer',
    league: 'Bundesliga',
    marketValue: 6000000,
  },
  {
    jerseyNumber: 21,
    name: 'Eren Dinkci',
    dateOfBirth: '13.12.2001',
    nationality: 'Deutschland',
    height: 1.88,
    foot: 'rechts',
    teamMemberSince: '01.01.2021',
    club: 'SV Werder Bremen',
    previousClub: 'SV Werder Bremen II',
    uid: '9d382476-886a-4600-9310-b2f45b93241c',
    position: 'Mittelstürmer',
    league: 'Bundesliga',
    marketValue: 700000,
  },
  {
    jerseyNumber: 13,
    name: 'Alexander Schwolow',
    dateOfBirth: '02.06.1992',
    nationality: 'Deutschland',
    height: 1.9,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'FC Schalke 04',
    previousClub: 'Hertha BSC',
    uid: '54ad6192-e1fc-4fc3-b26e-5c0d7ee8bb45',
    position: 'Torwart',
    league: 'Bundesliga',
    marketValue: 900000,
  },
  {
    jerseyNumber: 1,
    name: 'Ralf Fährmann',
    dateOfBirth: '27.09.1988',
    nationality: 'Deutschland',
    height: 1.97,
    foot: 'rechts',
    teamMemberSince: '01.07.2011',
    club: 'FC Schalke 04',
    previousClub: 'Eintracht Frankfurt',
    uid: '2baa2e1c-82bf-47a3-9508-2ec30758b983',
    position: 'Torwart',
    league: 'Bundesliga',
    marketValue: 400000,
  },
  {
    jerseyNumber: 28,
    name: 'Justin Heekeren',
    dateOfBirth: '27.11.2000',
    nationality: 'Deutschland',
    height: 1.96,
    foot: 'beidfüßig',
    teamMemberSince: '01.07.2022',
    club: 'FC Schalke 04',
    previousClub: 'Rot-Weiß Oberhausen',
    uid: '5fb1264b-9031-43ff-b332-a33ef830f613',
    position: 'Torwart',
    league: 'Bundesliga',
    marketValue: 250000,
  },
  {
    jerseyNumber: 34,
    name: 'Michael Langer',
    dateOfBirth: '06.01.1985',
    nationality: 'Österreich',
    height: 1.95,
    foot: 'rechts',
    teamMemberSince: '04.08.2017',
    club: 'FC Schalke 04',
    previousClub: 'IFK Norrköping',
    uid: '71e7d91a-193a-40e3-a730-1120d2b4efda',
    position: 'Torwart',
    league: 'Bundesliga',
    marketValue: 50000,
  },
  {
    jerseyNumber: 25,
    name: 'Moritz Jenz',
    dateOfBirth: '30.04.1999',
    nationality: 'Deutschland',
    height: 1.9,
    foot: 'rechts',
    teamMemberSince: '26.01.2023',
    club: 'FC Schalke 04',
    previousClub: 'FC Lorient',
    uid: 'e45287d6-00a4-4706-9f12-374f5a039639',
    position: 'Innenverteidiger',
    league: 'Bundesliga',
    marketValue: 5000000,
  },
  {
    jerseyNumber: 5,
    name: 'Sepp van den Berg',
    dateOfBirth: '20.12.2001',
    nationality: 'Niederlande',
    height: 1.92,
    foot: 'rechts',
    teamMemberSince: '30.08.2022',
    club: 'FC Schalke 04',
    previousClub: 'FC Liverpool',
    uid: 'ca03cda6-b00a-4842-9c6a-7b8cb8ef86a4',
    position: 'Innenverteidiger',
    league: 'Bundesliga',
    marketValue: 5000000,
  },
  {
    jerseyNumber: 3,
    name: 'Leo Greiml',
    dateOfBirth: '03.07.2001',
    nationality: 'Österreich',
    height: 1.87,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'FC Schalke 04',
    previousClub: 'SK Rapid Wien',
    uid: '468e038f-90b4-47f4-8115-d6c80d1a33a7',
    position: 'Innenverteidiger',
    league: 'Bundesliga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 4,
    name: 'Maya Yoshida',
    dateOfBirth: '24.08.1988',
    nationality: 'Japan',
    height: 1.89,
    foot: 'rechts',
    teamMemberSince: '05.07.2022',
    club: 'FC Schalke 04',
    previousClub: 'Sampdoria Genua',
    uid: '0158b15a-d3f2-4435-9e7d-5222ac94dce2',
    position: 'Innenverteidiger',
    league: 'Bundesliga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 35,
    name: 'Marcin Kaminski',
    dateOfBirth: '15.01.1992',
    nationality: 'Polen',
    height: 1.92,
    foot: 'links',
    teamMemberSince: '01.07.2021',
    club: 'FC Schalke 04',
    previousClub: 'VfB Stuttgart',
    uid: '33a5d909-0b39-48a0-a23c-2f783f8e2410',
    position: 'Innenverteidiger',
    league: 'Bundesliga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 41,
    name: 'Henning Matriciani',
    dateOfBirth: '14.03.2000',
    nationality: 'Deutschland',
    height: 1.87,
    foot: 'rechts',
    teamMemberSince: '28.09.2021',
    club: 'FC Schalke 04',
    previousClub: 'FC Schalke 04 II',
    uid: 'c6eb0e66-2665-4b90-ae6d-57c89ab867a4',
    position: 'Innenverteidiger',
    league: 'Bundesliga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 15,
    name: 'Timothée Kolodziejczak',
    dateOfBirth: '01.10.1991',
    nationality: 'Frankreich',
    height: 1.83,
    foot: 'links',
    teamMemberSince: '21.10.2022',
    club: 'FC Schalke 04',
    previousClub: 'Vereinslos',
    uid: '60d6d45c-a49f-409f-a1d7-ce77131ebdfb',
    position: 'Innenverteidiger',
    league: 'Bundesliga',
    marketValue: 700000,
  },
  {
    jerseyNumber: 22,
    name: 'Ibrahima Cissé',
    dateOfBirth: '15.02.2001',
    nationality: 'Mali',
    height: 1.96,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'FC Schalke 04',
    previousClub: 'Jong KAA Gent',
    uid: 'f21b8f37-ebb7-4454-938b-dd165e582973',
    position: 'Innenverteidiger',
    league: 'Bundesliga',
    marketValue: 200000,
  },
  {
    jerseyNumber: 2,
    name: 'Thomas Ouwejan',
    dateOfBirth: '30.09.1996',
    nationality: 'Niederlande',
    height: 1.83,
    foot: 'links',
    teamMemberSince: '01.07.2022',
    club: 'FC Schalke 04',
    previousClub: 'AZ Alkmaar',
    uid: '797f7024-daa8-4c87-94d2-e965d315c656',
    position: 'Linker Verteidiger',
    league: 'Bundesliga',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 18,
    name: 'Jere Uronen',
    dateOfBirth: '13.07.1994',
    nationality: 'Finnland',
    height: 1.77,
    foot: 'links',
    teamMemberSince: '07.01.2023',
    club: 'FC Schalke 04',
    previousClub: 'Stade Brest 29',
    uid: '9d7ae479-9099-4149-a7b0-d357341a76a3',
    position: 'Linker Verteidiger',
    league: 'Bundesliga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 27,
    name: 'Cédric Brunner',
    dateOfBirth: '17.02.1994',
    nationality: 'Schweiz',
    height: 1.8,
    foot: 'rechts',
    teamMemberSince: '07.07.2022',
    club: 'FC Schalke 04',
    previousClub: 'Arminia Bielefeld',
    uid: '9d2c5479-e552-4cc7-8fb9-d66efe4114af',
    position: 'Rechter Verteidiger',
    league: 'Bundesliga',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 23,
    name: 'Mehmet Aydin',
    dateOfBirth: '09.02.2002',
    nationality: 'Türkei',
    height: 1.8,
    foot: 'rechts',
    teamMemberSince: '01.07.2021',
    club: 'FC Schalke 04',
    previousClub: 'FC Schalke 04 U19',
    uid: '126dc1d2-b858-48f8-bc3e-45b070005463',
    position: 'Rechter Verteidiger',
    league: 'Bundesliga',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 30,
    name: 'Alex Kral',
    dateOfBirth: '19.05.1998',
    nationality: 'Tschechien',
    height: 1.86,
    foot: 'rechts',
    teamMemberSince: '14.07.2022',
    club: 'FC Schalke 04',
    previousClub: 'Spartak Moskau',
    uid: '42500524-0d1e-4f04-a91a-f678969dd28e',
    position: 'Defensives Mittelfeld',
    league: 'Bundesliga',
    marketValue: 5000000,
  },
  {
    jerseyNumber: 33,
    name: 'Éder Balanta',
    dateOfBirth: '28.02.1993',
    nationality: 'Kolumbien',
    height: 1.8,
    foot: 'links',
    teamMemberSince: '31.01.2023',
    club: 'FC Schalke 04',
    previousClub: 'FC Brügge',
    uid: 'ea30bcb6-a4fd-4621-ab0f-1be761a20d53',
    position: 'Defensives Mittelfeld',
    league: 'Bundesliga',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 21,
    name: 'Niklas Tauer',
    dateOfBirth: '17.02.2001',
    nationality: 'Deutschland',
    height: 1.83,
    foot: 'rechts',
    teamMemberSince: '01.01.2023',
    club: 'FC Schalke 04',
    previousClub: '1.FSV Mainz 05',
    uid: 'e9235998-576d-4f94-b82a-c5ee3cd08122',
    position: 'Defensives Mittelfeld',
    league: 'Bundesliga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 6,
    name: 'Tom Krauß',
    dateOfBirth: '22.06.2001',
    nationality: 'Deutschland',
    height: 1.82,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'FC Schalke 04',
    previousClub: 'RasenBallsport Leipzig',
    uid: '1d530eaa-fc75-478c-b0f0-89499fa75aff',
    position: 'Zentrales Mittelfeld',
    league: 'Bundesliga',
    marketValue: 6000000,
  },
  {
    jerseyNumber: 8,
    name: 'Danny Latza',
    dateOfBirth: '07.12.1989',
    nationality: 'Deutschland',
    height: 1.79,
    foot: 'rechts',
    teamMemberSince: '01.07.2021',
    club: 'FC Schalke 04',
    previousClub: '1.FSV Mainz 05',
    uid: 'a7b3186d-5d94-42dd-8d03-f7bd6a4c6dbe',
    position: 'Zentrales Mittelfeld',
    league: 'Bundesliga',
    marketValue: 600000,
  },
  {
    jerseyNumber: 38,
    name: 'Soichiro Kozuki',
    dateOfBirth: '22.12.2000',
    nationality: 'Japan',
    height: 1.8,
    foot: 'rechts',
    teamMemberSince: '01.01.2023',
    club: 'FC Schalke 04',
    previousClub: 'FC Schalke 04 II',
    uid: '95f8803d-a79f-495c-a462-34ed63b26a9f',
    position: 'Rechtes Mittelfeld',
    league: 'Bundesliga',
    marketValue: 600000,
  },
  {
    jerseyNumber: 29,
    name: 'Tobias Mohr',
    dateOfBirth: '24.08.1995',
    nationality: 'Deutschland',
    height: 1.83,
    foot: 'links',
    teamMemberSince: '01.07.2022',
    club: 'FC Schalke 04',
    previousClub: '1.FC Heidenheim 1846',
    uid: '731eb9ff-f5b1-4cc0-a904-fde7b8f5fb71',
    position: 'Linkes Mittelfeld',
    league: 'Bundesliga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 10,
    name: 'Rodrigo Zalazar',
    dateOfBirth: '12.08.1999',
    nationality: 'Uruguay',
    height: 1.78,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'FC Schalke 04',
    previousClub: 'Eintracht Frankfurt',
    uid: '39ec8d05-8b6c-417e-bb1b-e7ed5e57215c',
    position: 'Offensives Mittelfeld',
    league: 'Bundesliga',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 24,
    name: 'Dominick Drexler',
    dateOfBirth: '26.05.1990',
    nationality: 'Deutschland',
    height: 1.83,
    foot: 'rechts',
    teamMemberSince: '21.07.2021',
    club: 'FC Schalke 04',
    previousClub: '1.FC Köln',
    uid: 'ea4b554a-8ce5-4c7f-a772-4a1a5cc2c9cf',
    position: 'Offensives Mittelfeld',
    league: 'Bundesliga',
    marketValue: 900000,
  },
  {
    jerseyNumber: 11,
    name: 'Marius Bülter',
    dateOfBirth: '29.03.1993',
    nationality: 'Deutschland',
    height: 1.88,
    foot: 'rechts',
    teamMemberSince: '01.07.2021',
    club: 'FC Schalke 04',
    previousClub: '1.FC Union Berlin',
    uid: 'cabc5253-60a7-4d23-9130-4ad0ab40c4d7',
    position: 'Linksaußen',
    league: 'Bundesliga',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 20,
    name: 'Tim Skarke',
    dateOfBirth: '07.09.1996',
    nationality: 'Deutschland',
    height: 1.81,
    foot: 'rechts',
    teamMemberSince: '23.01.2023',
    club: 'FC Schalke 04',
    previousClub: '1.FC Union Berlin',
    uid: 'bf574419-e6c1-48d4-b2ca-da376736e01c',
    position: 'Rechtsaußen',
    league: 'Bundesliga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 26,
    name: 'Michael Frey',
    dateOfBirth: '19.07.1994',
    nationality: 'Schweiz',
    height: 1.88,
    foot: 'rechts',
    teamMemberSince: '20.01.2023',
    club: 'FC Schalke 04',
    previousClub: 'Royal Antwerpen FC',
    uid: 'd9a47e1a-9243-4c89-bb8c-e85e5725c820',
    position: 'Mittelstürmer',
    league: 'Bundesliga',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 40,
    name: 'Sebastian Polter',
    dateOfBirth: '01.04.1991',
    nationality: 'Deutschland',
    height: 1.92,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'FC Schalke 04',
    previousClub: 'VfL Bochum',
    uid: '403c523b-10d1-411d-83cd-5921c34204af',
    position: 'Mittelstürmer',
    league: 'Bundesliga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 19,
    name: 'Kenan Karaman',
    dateOfBirth: '05.03.1994',
    nationality: 'Türkei',
    height: 1.89,
    foot: 'rechts',
    teamMemberSince: '01.09.2022',
    club: 'FC Schalke 04',
    previousClub: 'Besiktas JK',
    uid: 'bb098dcc-659d-4c08-8e69-48b396c3ceca',
    position: 'Mittelstürmer',
    league: 'Bundesliga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 9,
    name: 'Simon Terodde',
    dateOfBirth: '02.03.1988',
    nationality: 'Deutschland',
    height: 1.92,
    foot: 'rechts',
    teamMemberSince: '01.07.2021',
    club: 'FC Schalke 04',
    previousClub: 'Hamburger SV',
    uid: 'b492dec0-0b3d-4ad4-9981-8d14fc03181b',
    position: 'Mittelstürmer',
    league: 'Bundesliga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 1,
    name: 'Manuel Riemann',
    dateOfBirth: '09.09.1988',
    nationality: 'Deutschland',
    height: 1.86,
    foot: 'rechts',
    teamMemberSince: '01.07.2015',
    club: 'VfL Bochum',
    previousClub: ': Ablöse ablösefrei',
    uid: '9591ac29-6db8-4d18-b95e-9c2ca686a9cb',
    position: 'Torwart',
    league: 'Bundesliga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 26,
    name: 'Marko Johansson',
    dateOfBirth: '25.08.1998',
    nationality: 'Schweden',
    height: 1.94,
    foot: 'rechts',
    teamMemberSince: '01.09.2022',
    club: 'VfL Bochum',
    previousClub: 'Hamburger SV',
    uid: '98d362ba-7a9a-4aa0-9863-ae07a29dd1b6',
    position: 'Torwart',
    league: 'Bundesliga',
    marketValue: 500000,
  },
  {
    jerseyNumber: 21,
    name: 'Michael Esser',
    dateOfBirth: '22.11.1987',
    nationality: 'Deutschland',
    height: 1.98,
    foot: 'rechts',
    teamMemberSince: '01.07.2021',
    club: 'VfL Bochum',
    previousClub: 'Hannover 96',
    uid: '91db5c90-1bed-4b0b-bee1-3dce13b2e78e',
    position: 'Torwart',
    league: 'Bundesliga',
    marketValue: 400000,
  },
  {
    jerseyNumber: 34,
    name: 'Paul Grave',
    dateOfBirth: '10.04.2001',
    nationality: 'Deutschland',
    height: 1.93,
    foot: 'rechts',
    teamMemberSince: '02.03.2019',
    club: 'VfL Bochum',
    previousClub: 'VfL Bochum U19',
    uid: 'b130079b-8e0a-445e-a596-dad97997517b',
    position: 'Torwart',
    league: 'Bundesliga',
    marketValue: 100000,
  },
  {
    jerseyNumber: 4,
    name: 'Erhan Masovic',
    dateOfBirth: '22.11.1998',
    nationality: 'Serbien',
    height: 1.89,
    foot: 'beidfüßig',
    teamMemberSince: '05.10.2020',
    club: 'VfL Bochum',
    previousClub: 'FC Brügge',
    uid: 'd31c7370-7659-47b1-be52-af567e589bf0',
    position: 'Innenverteidiger',
    league: 'Bundesliga',
    marketValue: 5000000,
  },
  {
    jerseyNumber: 20,
    name: 'Ivan Ordets',
    dateOfBirth: '08.07.1992',
    nationality: 'Ukraine',
    height: 1.95,
    foot: 'rechts',
    teamMemberSince: '10.07.2022',
    club: 'VfL Bochum',
    previousClub: 'Dinamo Moskau',
    uid: '3c58345b-6aac-4f8f-8373-af0b9943bb76',
    position: 'Innenverteidiger',
    league: 'Bundesliga',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 31,
    name: 'Keven Schlotterbeck',
    dateOfBirth: '28.04.1997',
    nationality: 'Deutschland',
    height: 1.89,
    foot: 'links',
    teamMemberSince: '02.01.2023',
    club: 'VfL Bochum',
    previousClub: 'SC Freiburg',
    uid: '81a2fa40-e5d7-4a28-9523-ac8aee13ef0b',
    position: 'Innenverteidiger',
    league: 'Bundesliga',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 30,
    name: 'Dominique Heintz',
    dateOfBirth: '15.08.1993',
    nationality: 'Deutschland',
    height: 1.88,
    foot: 'links',
    teamMemberSince: '11.08.2022',
    club: 'VfL Bochum',
    previousClub: '1.FC Union Berlin',
    uid: '328ad6bd-2404-4752-81f3-e29faf54804d',
    position: 'Innenverteidiger',
    league: 'Bundesliga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 24,
    name: 'Vasilios Lampropoulos',
    dateOfBirth: '31.03.1990',
    nationality: 'Griechenland',
    height: 1.85,
    foot: 'rechts',
    teamMemberSince: '14.08.2020',
    club: 'VfL Bochum',
    previousClub: 'Deportivo La Coruna',
    uid: '17601d5e-6c05-4821-8e59-dcc7b50f6143',
    position: 'Innenverteidiger',
    league: 'Bundesliga',
    marketValue: 500000,
  },
  {
    jerseyNumber: 25,
    name: 'Mohammed Tolba',
    dateOfBirth: '19.07.2004',
    nationality: 'Deutschland',
    height: 1.85,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'VfL Bochum',
    previousClub: 'VfL Bochum U19',
    uid: 'c252c2dd-aa58-4b54-99d8-e473bff9667a',
    position: 'Innenverteidiger',
    league: 'Bundesliga',
    marketValue: 200000,
  },
  {
    jerseyNumber: 3,
    name: 'Danilo Soares',
    dateOfBirth: '29.10.1991',
    nationality: 'Brasilien',
    height: 1.7,
    foot: 'links',
    teamMemberSince: '01.07.2017',
    club: 'VfL Bochum',
    previousClub: 'TSG 1899 Hoffenheim',
    uid: '58528058-9651-4161-9a5f-cdde42c1bb4d',
    position: 'Linker Verteidiger',
    league: 'Bundesliga',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 16,
    name: 'Konstantinos Stafylidis',
    dateOfBirth: '02.12.1993',
    nationality: 'Griechenland',
    height: 1.78,
    foot: 'links',
    teamMemberSince: '06.07.2022',
    club: 'VfL Bochum',
    previousClub: 'TSG 1899 Hoffenheim',
    uid: 'c66f7785-6628-4710-9cdf-aef46e2b0ce4',
    position: 'Linker Verteidiger',
    league: 'Bundesliga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 18,
    name: 'Jordi Osei-Tutu',
    dateOfBirth: '02.10.1998',
    nationality: 'England',
    height: 1.76,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'VfL Bochum',
    previousClub: 'FC Arsenal U21',
    uid: 'd310d6fa-bc28-4767-85d5-1021ef6066fb',
    position: 'Rechter Verteidiger',
    league: 'Bundesliga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 23,
    name: 'Saidy Janko',
    dateOfBirth: '22.10.1995',
    nationality: 'Gambia',
    height: 1.81,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'VfL Bochum',
    previousClub: 'Real Valladolid',
    uid: '3c4d86d1-36c5-4a7b-8422-0cca648e62ed',
    position: 'Rechter Verteidiger',
    league: 'Bundesliga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 2,
    name: 'Cristian Gamboa',
    dateOfBirth: '24.10.1989',
    nationality: 'Costa Rica',
    height: 1.75,
    foot: 'rechts',
    teamMemberSince: '27.08.2019',
    club: 'VfL Bochum',
    previousClub: 'Celtic Glasgow',
    uid: '8dccabed-29b5-4f5b-9916-9204566526e1',
    position: 'Rechter Verteidiger',
    league: 'Bundesliga',
    marketValue: 700000,
  },
  {
    jerseyNumber: 5,
    name: 'Jacek Goralski',
    dateOfBirth: '21.09.1992',
    nationality: 'Polen',
    height: 1.72,
    foot: 'rechts',
    teamMemberSince: '01.07.2022',
    club: 'VfL Bochum',
    previousClub: 'Kairat Almaty',
    uid: '694cfc53-089a-484d-84d9-00b27c73aed6',
    position: 'Defensives Mittelfeld',
    league: 'Bundesliga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 8,
    name: 'Anthony Losilla',
    dateOfBirth: '10.03.1986',
    nationality: 'Frankreich',
    height: 1.85,
    foot: 'rechts',
    teamMemberSince: '01.07.2014',
    club: 'VfL Bochum',
    previousClub: 'SG Dynamo Dresden',
    uid: 'd3c5b0e8-04f9-4b14-8b1f-d640b2b2447c',
    position: 'Defensives Mittelfeld',
    league: 'Bundesliga',
    marketValue: 500000,
  },
  {
    jerseyNumber: 6,
    name: 'Patrick Osterhage',
    dateOfBirth: '01.02.2000',
    nationality: 'Deutschland',
    height: 1.86,
    foot: 'links',
    teamMemberSince: '01.07.2021',
    club: 'VfL Bochum',
    previousClub: 'Borussia Dortmund II',
    uid: '4a2da5c2-e43d-40ae-b89d-7ceb430a956c',
    position: 'Zentrales Mittelfeld',
    league: 'Bundesliga',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 28,
    name: 'Pierre Kunde',
    dateOfBirth: '26.07.1995',
    nationality: 'Kamerun',
    height: 1.8,
    foot: 'rechts',
    teamMemberSince: '06.01.2023',
    club: 'VfL Bochum',
    previousClub: 'Olympiakos Piräus',
    uid: '1b9b53a1-243c-4ac1-91a3-e63fab206d9d',
    position: 'Zentrales Mittelfeld',
    league: 'Bundesliga',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 7,
    name: 'Kevin Stöger',
    dateOfBirth: '27.08.1993',
    nationality: 'Österreich',
    height: 1.75,
    foot: 'links',
    teamMemberSince: '01.07.2022',
    club: 'VfL Bochum',
    previousClub: '1.FSV Mainz 05',
    uid: 'de13620d-48fa-4ba5-aacb-88acc611fd55',
    position: 'Offensives Mittelfeld',
    league: 'Bundesliga',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 10,
    name: 'Philipp Förster',
    dateOfBirth: '04.02.1995',
    nationality: 'Deutschland',
    height: 1.88,
    foot: 'links',
    teamMemberSince: '01.07.2022',
    club: 'VfL Bochum',
    previousClub: 'VfB Stuttgart',
    uid: '5e255706-ac8a-479c-ae36-c6d00199ec0d',
    position: 'Offensives Mittelfeld',
    league: 'Bundesliga',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 17,
    name: 'Gerrit Holtmann',
    dateOfBirth: '25.03.1995',
    nationality: 'Philippinen',
    height: 1.85,
    foot: 'links',
    teamMemberSince: '24.08.2020',
    club: 'VfL Bochum',
    previousClub: '1.FSV Mainz 05',
    uid: '6d81b78f-36e2-4774-b8b9-bab1a2fe80ce',
    position: 'Linksaußen',
    league: 'Bundesliga',
    marketValue: 3000000,
  },
  {
    jerseyNumber: 22,
    name: 'Christopher Antwi-Adjei',
    dateOfBirth: '07.02.1994',
    nationality: 'Ghana',
    height: 1.74,
    foot: 'rechts',
    teamMemberSince: '01.07.2021',
    club: 'VfL Bochum',
    previousClub: 'SC Paderborn 07',
    uid: '107a1449-5a56-4745-baad-eeac046072ea',
    position: 'Linksaußen',
    league: 'Bundesliga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 11,
    name: 'Takuma Asano',
    dateOfBirth: '10.11.1994',
    nationality: 'Japan',
    height: 1.73,
    foot: 'rechts',
    teamMemberSince: '01.07.2021',
    club: 'VfL Bochum',
    previousClub: 'Vereinslos',
    uid: '85147365-01eb-46c8-af04-0af49fb4c25d',
    position: 'Rechtsaußen',
    league: 'Bundesliga',
    marketValue: 4000000,
  },
  {
    jerseyNumber: 33,
    name: 'Philipp Hofmann',
    dateOfBirth: '30.03.1993',
    nationality: 'Deutschland',
    height: 1.95,
    foot: 'links',
    teamMemberSince: '01.07.2022',
    club: 'VfL Bochum',
    previousClub: 'Karlsruher SC',
    uid: '2b2c5bdd-4d25-49d3-98fb-5d6dd38e93e2',
    position: 'Mittelstürmer',
    league: 'Bundesliga',
    marketValue: 2000000,
  },
  {
    jerseyNumber: 9,
    name: 'Simon Zoller',
    dateOfBirth: '26.06.1991',
    nationality: 'Deutschland',
    height: 1.79,
    foot: 'rechts',
    teamMemberSince: '01.01.2019',
    club: 'VfL Bochum',
    previousClub: '1.FC Köln',
    uid: '77be322e-a99a-4848-9720-dd47e002d6eb',
    position: 'Mittelstürmer',
    league: 'Bundesliga',
    marketValue: 1000000,
  },
  {
    jerseyNumber: 35,
    name: 'Silvère Ganvoula',
    dateOfBirth: '29.06.1996',
    nationality: 'Kongo',
    height: 1.91,
    foot: 'rechts',
    teamMemberSince: '05.07.2019',
    club: 'VfL Bochum',
    previousClub: 'RSC Anderlecht',
    uid: '33592f9c-d133-4931-b338-70101246bacd',
    position: 'Mittelstürmer',
    league: 'Bundesliga',
    marketValue: 750000,
  },
  {
    jerseyNumber: 29,
    name: 'Moritz Broschinski',
    dateOfBirth: '23.09.2000',
    nationality: 'Deutschland',
    height: 1.9,
    foot: 'rechts',
    teamMemberSince: '22.01.2023',
    club: 'VfL Bochum',
    previousClub: 'Borussia Dortmund II',
    uid: '9485f241-9914-4667-93aa-0ff822be7f65',
    position: 'Mittelstürmer',
    league: 'Bundesliga',
    marketValue: 500000,
  },
];
