import { ChangeDetectionStrategy, Component, Input, OnChanges, signal, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AnimationOptions, LottieComponent } from 'ngx-lottie';
import { AnimationItem } from 'lottie-web';

@Component({
  selector: 'kiq-lottie-animation',
  standalone: true,
  imports: [CommonModule, LottieComponent],
  templateUrl: './lottie-animation.component.html',
  styleUrl: './lottie-animation.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LottieAnimationComponent implements OnChanges {
  protected DEFAULT_HEIGHT = '80px';
  protected DEFAULT_WIDTH = '80px';
  private animationItem: AnimationItem | null = null;

  @Input() height: string | undefined;
  @Input() width: string | undefined;
  @Input() restart: any;

  @Input({ required: true }) set options(options: AnimationOptions) {
    this._options.update((oldOptions) => ({ ...oldOptions, ...options }));
  }

  protected _options = signal<AnimationOptions | null>(null);

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.['restart']) {
      this.restartAnimation();
    }
  }

  animationCreated(animationItem: AnimationItem) {
    this.animationItem = animationItem;
  }

  private restartAnimation() {
    if (this.animationItem) {
      this.animationItem.stop();
      this.animationItem.play();
      this.restart = false;
    }
  }
}
