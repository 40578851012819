import { Trophy } from '@kiq/shared/types';
import { TrophyRank } from '@kiq/shared/enums';

export const trophyData: Array<Trophy> = [
  {
    id: 'assets/icons/trophies/kikkz-trophy.svg',
    title: 'KIKKZ-Trophäe',
    description:
      'Eine Auszeichnung, die dich als neues Mitglied der KIKKZ-Community willkommen heißt und deine Registrierung ehrt',
    imageUrl: 'assets/icons/trophies/kikkz-trophy.svg',
  },
  {
    id: 'assets/icons/trophies/monthly-trophy-gold.svg',
    title: 'Platz 1 - September 2024',
    description:
      'Belohnt deine Dominanz und Spitzenleistung, indem du den Monat September 2024 als Nummer 1 in BOX TO BOX abgeschlossen hast',
    rank: TrophyRank.GOLD,
    rankDistances: new Map<TrophyRank, number>([
      [TrophyRank.BRONZE, 1],
      [TrophyRank.SILVER, 10],
      [TrophyRank.GOLD, 100],
      [TrophyRank.PLATINUM, 300],
    ]),
    currentRankCount: 150,
    imageUrl: 'assets/icons/trophies/monthly-trophy-gold.svg',
    dateBadge: 'SEP 2024',

    ownedTrophyRanks: [
      {
        id: 'assets/icons/trophies/monthly-trophy-bronze.svg',
        rank: TrophyRank.BRONZE,
        imageUrl: 'assets/icons/trophies/monthly-trophy-bronze.svg',
        dateBadge: 'SEP 2024',
      },
      {
        id: 'assets/icons/trophies/monthly-trophy-silver.svg',
        rank: TrophyRank.SILVER,
        imageUrl: 'assets/icons/trophies/monthly-trophy-silver.svg',
        dateBadge: 'SEP 2024',
      },
      {
        id: 'assets/icons/trophies/monthly-trophy-gold.svg',
        rank: TrophyRank.GOLD,
        imageUrl: 'assets/icons/trophies/monthly-trophy-gold.svg',
        dateBadge: 'SEP 2024',
      },
      {
        id: 'assets/icons/trophies/monthly-trophy-platinum.svg',
        rank: TrophyRank.PLATINUM,
        imageUrl: 'assets/icons/trophies/monthly-trophy-platinum.svg',
        comingSoon: true,
        dateBadge: 'SEP 2024',
      },
    ],
  },
  {
    id: 'assets/icons/trophies/monthly-trophy-gold.svg',
    title: 'Platz 1 - September 2024',
    description:
      'Belohnt deine Dominanz und Spitzenleistung, indem du den Monat September 2024 als Nummer 1 in BOX TO BOX abgeschlossen hast',
    rank: TrophyRank.PLATINUM,
    rankDistances: new Map<TrophyRank, number>([
      [TrophyRank.BRONZE, 1],
      [TrophyRank.SILVER, 10],
      [TrophyRank.GOLD, 100],
      [TrophyRank.PLATINUM, 300],
    ]),
    currentRankCount: 300,
    imageUrl: 'assets/icons/trophies/year-trophy-platinum.svg',
    dateBadge: '2024',

    ownedTrophyRanks: [
      {
        id: 'assets/icons/trophies/year-trophy-bronze.svg',
        rank: TrophyRank.BRONZE,
        imageUrl: 'assets/icons/trophies/year-trophy-bronze.svg',
        dateBadge: '2024',
      },
      {
        id: 'assets/icons/trophies/year-trophy-silver.svg',
        rank: TrophyRank.SILVER,
        imageUrl: 'assets/icons/trophies/year-trophy-silver.svg',
        dateBadge: '2024',
      },
      {
        id: 'assets/icons/trophies/year-trophy-gold.svg',
        rank: TrophyRank.GOLD,
        imageUrl: 'assets/icons/trophies/year-trophy-gold.svg',
        dateBadge: '2024',
      },
      {
        id: 'assets/icons/trophies/year-trophy-platinum.svg',
        rank: TrophyRank.PLATINUM,
        imageUrl: 'assets/icons/trophies/year-trophy-platinum.svg',
        dateBadge: '2024',
      },
    ],
  },
  {
    id: 'assets/icons/trophies/star-trophy.svg',
    title: 'Platzhalter Trophäe',
    description: '',
    imageUrl: 'assets/icons/trophies/star-trophy.svg',
    comingSoon: true,
  },
  {
    id: 'assets/icons/trophies/star-trophy.svg',
    title: 'Platzhalter Trophäe',
    description: '',
    imageUrl: 'assets/icons/trophies/star-trophy.svg',
    comingSoon: true,
  },
  {
    id: 'assets/icons/trophies/star-trophy.svg',
    title: 'Platzhalter Trophäe',
    description: '',
    imageUrl: 'assets/icons/trophies/star-trophy.svg',
    comingSoon: true,
  },
  {
    id: 'assets/icons/trophies/star-trophy.svg',
    title: 'Platzhalter Trophäe',
    description: '',
    imageUrl: 'assets/icons/trophies/star-trophy.svg',
    comingSoon: true,
  },
  {
    id: 'assets/icons/trophies/star-trophy.svg',
    title: 'Platzhalter Trophäe',
    description: '',
    imageUrl: 'assets/icons/trophies/star-trophy.svg',
    comingSoon: true,
  },
  {
    id: 'assets/icons/trophies/star-trophy.svg',
    title: 'Platzhalter Trophäe',
    description: '',
    imageUrl: 'assets/icons/trophies/star-trophy.svg',
    comingSoon: true,
  },
  {
    id: 'assets/icons/trophies/star-trophy.svg',
    title: 'Platzhalter Trophäe',
    description: '',
    imageUrl: 'assets/icons/trophies/star-trophy.svg',
    comingSoon: true,
  },
];
