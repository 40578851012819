import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { Trophy } from '@kiq/shared/types';

export const TrophyActions = createActionGroup({
  source: 'Trophy',
  events: {
    'get trophies start': props<{ userId: string | null | undefined }>(),
    'get trophies success': props<{ trophies: Array<Trophy> }>(),
    'get trophies fail': emptyProps(),

    'get trophy count for user': emptyProps(),
    'get trophy count for user success': props<{ trophies: Array<Trophy> }>(),
    'get trophy count for user fail': emptyProps(),
  },
});
