import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { TrophyActions } from './trophy.actions';
import { selectTrophies, selectTrophyCounter } from './trophy.reducer';
import { Observable } from 'rxjs';

@Injectable()
export class TrophyFacade {
  private store = inject(Store);
  selectTrophiesList$ = this.store.select(selectTrophies);
  trophyCounter$: Observable<number | null> = this.store.select(selectTrophyCounter);

  getTrophiesList(userId: string | null | undefined) {
    this.store.dispatch(TrophyActions.getTrophiesStart({ userId }));
  }

  getTrophyCountForUser() {
    this.store.dispatch(TrophyActions.getTrophyCountForUser());
  }
}
