import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { delay, Observable, of } from 'rxjs';
import { playersData } from '../data/players-data';
import { Game } from '../interfaces/game';
import { gameData } from '../data/game-data';
import { Player } from '@kiq/shared/interfaces';
import { GameDifficulty } from '@kiq/shared/enums';

@Injectable({ providedIn: 'root' })
export class GameDataService {
  http = inject(HttpClient);

  getAllPlayers$(): Observable<Array<Player>> {
    return of(playersData).pipe(delay(300));
  }

  getPlayerById$(id: string): Observable<Player | null> {
    return of(playersData?.find((player) => player.uid === id) ?? null).pipe(delay(300));
  }

  getRandomPlayer$(selectedDifficulty: GameDifficulty): Observable<Player> {
    const playersDataBasedOnDifficulty = playersData.filter((player) => {
      if (selectedDifficulty === GameDifficulty.EASY) {
        return player.marketValue > 40000000;
      } else if (selectedDifficulty === GameDifficulty.MEDIUM) {
        return player.marketValue > 3000000;
      } else {
        return player;
      }
    });

    return of(playersDataBasedOnDifficulty[Math.floor(Math.random() * playersDataBasedOnDifficulty.length)]).pipe(
      delay(100),
    );
  }

  searchPlayer$(searchTerm: string): Observable<Array<Player>> {
    return of(
      playersData.filter((player) => {
        return player.name.toLowerCase().includes(searchTerm.toLowerCase());
      }),
    ).pipe(delay(300));
  }

  getAllGames$(): Observable<Array<Game>> {
    return of(gameData).pipe(delay(1500));
  }
}
