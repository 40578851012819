import { Game } from '../interfaces/game';
import { GameType } from '@kiq/shared/enums';
import { GameMode } from '@kiq/shared/types';

export const gameData: Array<Game> = [
  {
    name: 'Wer bin ich?',
    id: 'who-is-it',
    icon: 'game1',
    type: GameType.WII,
  },
  {
    name: 'Wer bin ich? EM Modus',
    id: 'who-is-it',
    icon: 'game1',
    type: GameType.WII,
    gameMode: GameMode.EURO_24,
  },
  {
    name: 'Die falsche 9',
    id: 'tic-tac-toe',
    icon: 'game1',
    type: GameType.TTT,
    gameMode: GameMode.STANDARD_EDITION,
  },
  {
    name: 'Die falsche 9 Transfer',
    id: 'tic-tac-toe',
    icon: 'game1',
    type: GameType.TTT,
    gameMode: GameMode.TRANSFER_EDITION,
  },
  {
    name: 'Die falsche 9 Nationen Modus',
    id: 'tic-tac-toe',
    icon: 'game1',
    type: GameType.TTT,
    gameMode: GameMode.TOP_NATIONS_EDITION,
  },
  {
    name: 'Siegesserie',
    id: 'topscorer',
    icon: 'game1',
    type: GameType.WS,
  },
  {
    name: 'Wer weiß mehr?',
    id: 'who-knows-more',
    icon: 'game1',
    disabled: true,
    type: GameType.WII,
  },
];
