import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Injector,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  signal,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { BaseInputComponent } from '../../base-input.component';

@Component({
  selector: 'kiq-text-input',
  templateUrl: './text-input.component.html',
  styleUrls: ['./text-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TextInputComponent),
      multi: true,
    },
  ],
})
export class TextInputComponent extends BaseInputComponent implements OnInit, OnDestroy, OnChanges {
  @Input() prefixIconName: string | undefined;
  @Input() placeholder: string | undefined;
  @Input() inputType: 'password' | 'email' | 'text' | 'number' = 'text';
  @Input() autocomplete?: string;
  @Input() shouldFocus = false;
  @Input() blur = false;
  @Input() clearInput: boolean | undefined;

  errorTypes = ['required', 'email', 'number', 'username', 'password'];
  @Output() clearButtonClicked = new EventEmitter<void>();
  focused = signal(false);

  @ViewChild('input', { static: true }) input: ElementRef | undefined;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private renderer2: Renderer2,
    injector: Injector,
  ) {
    super(injector);
  }

  override ngOnInit() {
    super.ngOnInit();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['shouldFocus'] && changes['shouldFocus'].currentValue) {
      this.input?.nativeElement.focus();
    }
    if (changes['blur'] && changes['blur'].currentValue) {
      this.input?.nativeElement.blur();
    }

    if (changes['clearInput'] && changes['clearInput'].currentValue) {
      if (changes['clearInput'].currentValue === true) {
        this.onClearButtonClick();
      }
    }
  }

  override ngOnDestroy() {
    super.ngOnDestroy();
  }

  onClearButtonClick() {
    this.control?.patchValue('');
    this.clearButtonClicked.emit();
  }
}
