import { InjectionToken } from '@angular/core';

export const BACKEND_TOKEN = new InjectionToken('BACKEND');
export const TTT_FEATURE_FLAG_TOKEN = new InjectionToken<boolean>('TTT_FEATURE_FLAG_TOKEN');
export const WII_FEATURE_FLAG_TOKEN = new InjectionToken<boolean>('WII_FEATURE_FLAG_TOKEN');
export const TOPSCORER_FEATURE_FLAG_TOKEN = new InjectionToken<boolean>('TOPSCORER_FEATURE_FLAG_TOKEN');
export const MIXED_ZONE_FEATURE_FLAG_TOKEN = new InjectionToken<boolean>('MIXED_ZONE_FEATURE_FLAG_TOKEN');
export const FAKE_OR_FACT_FEATURE_FLAG_TOKEN = new InjectionToken<boolean>('FAKE_OR_FACT_FEATURE_FLAG_TOKEN');
export const NATIONS_FEATURE_FLAG_TOKEN = new InjectionToken<boolean>('NATIONS_FEATURE_FLAG_TOKEN');
export const AD_LOADING_SCREEN_FEATURE_FLAG_TOKEN = new InjectionToken<boolean>('AD_LOADING_SCREEN_FEATURE_FLAG_TOKEN');
export const AD_GAMES_OVERVIEW_FEATURE_FLAG_TOKEN = new InjectionToken<boolean>('AD_LOADING_SCREEN_FEATURE_FLAG_TOKEN');
export const BULI_LICENSE_FEATURE_FLAG_TOKEN = new InjectionToken<boolean>('BULI_LICENSE_FEATURE_FLAG');
export const TROPHIES_FEATURE_FLAG_TOKEN = new InjectionToken<boolean>('TROPHIES_FEATURE_FLAG_TOKEN');

