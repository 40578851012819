import { Component, inject } from '@angular/core';
import { ModalRef } from '@kiq/shared/types';
import { ConfigFacade } from '@kiq/client/data-access/config';

@Component({
  selector: 'kiq-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  private readonly configFacade = inject(ConfigFacade);
  private modalRef: ModalRef<any> | undefined;
  isModalOpen = false;
}
