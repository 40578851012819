import { Authentication } from './authentication.interface';
import { from, of, switchMap } from 'rxjs';
import { inject, Injectable } from '@angular/core';
import { Auth, signInWithCustomToken, user } from '@angular/fire/auth';
import { UserFacade } from '@kiq/client/data-access/user';
import { NativeAppsService } from '@kiq/shared/data-access/native-apps';

@Injectable()
export class AppAuthenticationService implements Authentication {
  private readonly nativeAppService = inject(NativeAppsService);
  private readonly userFacade = inject(UserFacade);
  private readonly auth = inject(Auth);
  token$ = user(this.auth).pipe(switchMap((user) => (user ? from(user.getIdToken()) : of(null))));

  login(): void {
    this.nativeAppService.openLoginModal();
  }

  logout(): void {
    this.nativeAppService.logout();
    this.userFacade.logout();
  }

  register(): void {
    this.nativeAppService.openRegisterModal();
  }

  setToken(token: string) {
    signInWithCustomToken(this.auth, token).then(() => this.userFacade.getBackendUser());
  }
}
