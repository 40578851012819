import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectSelectedDifficulty } from './games-overview.reducer';
import { GamesOverviewActions } from './games-overview.actions';
import { GameDifficultyLevel } from '@kiq/shared/enums';

// @Injectable({ providedIn: 'any' })
@Injectable()
export class GamesOverviewFacade {
  private store = inject(Store);

  selectedGameDifficulty$ = this.store.select(selectSelectedDifficulty);

  setDifficulty(difficulty: GameDifficultyLevel) {
    this.store.dispatch(GamesOverviewActions.setDifficulty({ difficulty }));
  }
}
