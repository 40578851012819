<ng-container *ngrxLet="{ loading: allClubsLoading$, filteredClubs: filteredClubs$ } as vm">
  <div class="flex flex-col h-full bg-surfaceSecondary relative">
    <div class="px-4 py-2 gap-x-4 flex items-center bg-zinc-950">
      <span class=""><shared-icon class="icon-wrapper" [name]="'magnifying-glass'"></shared-icon></span>
      <kiq-text-input
        (keydown)="onKeyDown($event, vm.filteredClubs)"
        class="w-full"
        #input
        #clubSearch="ngModel"
        [shouldFocus]="shouldFocus()"
        ngModel
        placeholder="Lieblingsverein suchen..."
        type="text" (clearButtonClicked)="selectedIndex = undefined"></kiq-text-input>
      <client-button class="h-12" [buttonType]="'tertiary'" [size]="'medium'" (click)="closeDialog()">
        <p>Abbrechen</p>
      </client-button>
    </div>

    @if (vm.loading || vm.filteredClubs !== null && vm.filteredClubs.length > 0) {
      <div class="flex-grow p-4 bg-surfaceSecondary overflow-y-auto text-textPrimary" #listContainer>
        @if (!vm.loading) {
          <ng-container *ngIf="searchLongerThanTwo() && vm.filteredClubs && vm.filteredClubs.length > 0">
            <div
              class="suggestions flex flex-col text-textPrimary"
              *ngIf="vm.filteredClubs && vm.filteredClubs.length > 0">
              <ng-container *ngFor="let suggestion of vm.filteredClubs; let i = index">

                <div
                  class="suggestion flex items-center space-between h-12 py-2.5 px-2 cursor-pointer border-b border-grey18 hover:bg-grey20 active:bg-grey18"
                  [class.hover]="i === selectedIndex"
                  (click)="selectClub(suggestion)">
                  <div class="name flex items-center grow gap-2">
                    <h5 class="line-clamp-1">{{ suggestion.name }}</h5>
                  </div>
                  <div class="select shrink-0">
                    <shared-icon class="icon-wrapper" name="chevron-right"></shared-icon>
                  </div>
                </div>

              </ng-container>
            </div>
          </ng-container>
        } @else {
          <ng-template #loading> loading</ng-template>
        }
      </div>
    }
    @if (searchLongerThanTwo() && (vm.filteredClubs === null || vm.filteredClubs.length === 0)) {
      <ng-container>
        <div class="text-textPrimary p-4 text-center">keine Resultate</div>
      </ng-container>
    }
    @if ((vm.filteredClubs === null || vm.filteredClubs.length === 0) && !searchLongerThanTwo()) {
      <div class="flex grow justify-center items-center w-full custom-height text-white text-center">
        <div>
          Suche nach deinem Lieblingsverein
        </div>
      </div>
    }
  </div>
</ng-container>
