<!--<svg-->
<!--  id="club-icon"-->
<!--  [style.width]="iconSize"-->
<!--  [style.height]="iconSize"-->
<!--  viewBox="0 0 60 71"-->
<!--  fill="none"-->
<!--  xmlns="http://www.w3.org/2000/svg">-->
<!--  <g clip-path="url(#clip0_2701_9838)">-->
<!--    <path-->
<!--      [style.fill]="'#' + fillColor2"-->
<!--      d="M1.5 9.75282C1.5 8.97042 2.10137 8.31942 2.88133 8.25752L9.76755 7.711C14.3429 7.34787 18.8436 6.33712 23.1348 4.709L29.4679 2.30621C29.8107 2.17615 30.1893 2.17615 30.5321 2.30621L36.8652 4.709C41.1564 6.33713 45.6571 7.34787 50.2325 7.711L57.1187 8.25752C57.8986 8.31942 58.5 8.97041 58.5 9.75282V29.3372C58.5 34.7851 56.9914 40.1267 54.1414 44.7697L52.6118 47.2615C48.6151 53.7728 43.317 59.3888 37.0493 63.7577L30.2859 68.4722C30.1141 68.592 29.8859 68.592 29.7141 68.4722L22.9507 63.7577C16.683 59.3888 11.3849 53.7728 7.38816 47.2615L5.85859 44.7696C3.0086 40.1267 1.5 34.7852 1.5 29.3372V9.75282Z"-->
<!--      stroke="#999999"-->
<!--      stroke-width="3" />-->
<!--  </g>-->
<!--  <g clip-path="url(#clip1_2701_9838)">-->
<!--    <path-->
<!--      [style.fill]="'#' + fillColor1"-->
<!--      d="M58.5 9.75282C58.5 8.97042 57.8986 8.31942 57.1187 8.25752L50.2324 7.711C45.6571 7.34787 41.1564 6.33712 36.8652 4.709L30.5321 2.30621C30.1893 2.17615 29.8107 2.17615 29.4679 2.30621L23.1348 4.709C18.8436 6.33713 14.3429 7.34787 9.76755 7.711L2.88132 8.25752C2.10137 8.31942 1.5 8.97041 1.5 9.75282V29.3372C1.5 34.7851 3.00861 40.1267 5.85859 44.7697L7.38816 47.2615C11.3849 53.7728 16.683 59.3888 22.9507 63.7577L29.7141 68.4722C29.8859 68.592 30.1141 68.592 30.2859 68.4722L37.0493 63.7577C43.317 59.3888 48.6151 53.7728 52.6118 47.2615L54.1414 44.7696C56.9914 40.1267 58.5 34.7852 58.5 29.3372V9.75282Z"-->
<!--      stroke="#999999"-->
<!--      stroke-width="3" />-->
<!--  </g>-->
<!--  <defs>-->
<!--    <clipPath id="clip0_2701_9838">-->
<!--      <rect width="30" height="70" fill="white" transform="translate(30 0.5)" />-->
<!--    </clipPath>-->
<!--    <clipPath id="clip1_2701_9838">-->
<!--      <rect width="30" height="70" fill="white" transform="matrix(-1 0 0 1 30 0.5)" />-->
<!--    </clipPath>-->
<!--  </defs>-->
<!--</svg>-->

<svg
  [attr.width]="iconSize"
  [attr.height]="iconSize"
  viewBox="0 0 81 80"
  fill="none"
  xmlns="http://www.w3.org/2000/svg">
  <path
    d="M55.5269 4.39622L55.4849 4.38168L55.4417 4.37092C51.5441 3.40017 49.5662 3.38461 47.5467 3.63931C47.0886 3.69709 46.6399 3.76639 46.1722 3.83863C44.6462 4.07432 42.9178 4.34128 40 4.34128C37.0791 4.34128 35.2736 4.07349 33.684 3.83771C33.1974 3.76555 32.7311 3.69638 32.2593 3.63881C30.1833 3.38553 28.2024 3.39834 24.5422 4.37509L24.5072 4.38441L24.4731 4.39622L4.04888 11.4614C2.21625 12.0953 1.1023 13.9525 1.40585 15.8677L3.49984 29.08C3.78457 30.8765 5.24557 32.2547 7.05563 32.4343L16.5352 33.3748C16.625 33.3861 16.7023 33.396 16.7708 33.4052C16.7715 33.4822 16.7716 33.5697 16.7716 33.6729V33.7232L16.7766 33.7732C18.2805 48.682 18.0921 57.3101 16.1377 71.0281C15.8291 73.1946 17.3129 75.2326 19.4913 75.5663C36.2564 78.1345 43.6979 78.1504 60.439 75.5791C62.6556 75.2386 64.1397 73.1391 63.7787 70.9466C61.4952 57.0797 61.4362 48.4747 63.2211 33.7936L63.2302 33.7184L63.228 33.6428C63.2254 33.5565 63.2231 33.4822 63.2216 33.4162C63.297 33.4032 63.3822 33.3893 63.4821 33.3731L72.9444 32.4343C74.7544 32.2547 76.2154 30.8765 76.5002 29.08L78.5942 15.8677C78.8977 13.9525 77.7838 12.0953 75.9511 11.4614L55.5269 4.39622ZM17.0899 33.461C17.0898 33.4611 17.0874 33.4604 17.083 33.4588C17.0878 33.4602 17.09 33.461 17.0899 33.461ZM16.7562 33.0896C16.7551 33.0848 16.7546 33.0821 16.7547 33.082C16.7548 33.0819 16.7554 33.0843 16.7562 33.0896ZM63.2263 33.0938C63.2264 33.0939 63.2261 33.096 63.2254 33.0998C63.2259 33.0957 63.2263 33.0938 63.2263 33.0938ZM62.9142 33.4812C62.9093 33.4832 62.9066 33.4841 62.9065 33.4841C62.9064 33.484 62.9088 33.483 62.9142 33.4812Z"
    fill="#F5F5F5"
    stroke="#707070"
    stroke-width="2" />
  <g clip-path="url(#clip0_2509_7025)">
    <mask id="mask0_2509_7025" style="mask-type: alpha" maskUnits="userSpaceOnUse" x="2" y="4" width="76" height="73">
      <path
        d="M55.1998 5.20871C47.5997 3.30869 47.7913 5.20871 39.9997 5.20871C32.2081 5.20871 31.893 3.3087 24.7996 5.20871L4.37015 12.3023C2.99809 12.7787 2.16437 14.1695 2.3909 15.6042L4.48829 28.8877C4.70109 30.2354 5.79657 31.2698 7.1543 31.405L16.647 32.3504C17.768 32.4929 17.7712 32.4929 17.7712 33.6471C19.2834 48.6946 19.0934 57.4376 17.1253 71.2992C16.8938 72.9294 18.0112 74.4554 19.6387 74.7057C36.3084 77.269 43.6473 77.2847 60.291 74.7185C61.9458 74.4634 63.0654 72.8916 62.7942 71.2394C60.4913 57.2076 60.4326 48.4729 62.2282 33.6471C62.195 32.5388 62.195 32.5388 63.3524 32.3504L72.8451 31.405C74.2029 31.2698 75.2983 30.2354 75.5111 28.8877L77.6085 15.6042C77.835 14.1695 77.0013 12.7787 75.6293 12.3023L55.1998 5.20871Z"
        fill="#F5F5F5" />
    </mask>
    <g mask="url(#mask0_2509_7025)">
      <path [style.fill]="hexFillColor1 ?? '#707070'" d="M-1.7998 2.0249H7.82811V82.0249H-1.7998V2.0249Z" />
      <path [style.fill]="hexFillColor2 ?? '#999999'" d="M7.82812 2.0249H17.0201V82.0249H7.82812V2.0249Z" />
      <path [style.fill]="hexFillColor1 ?? '#707070'" d="M17.02 2.0249H26.212V82.0249H17.02V2.0249Z" />
      <path [style.fill]="hexFillColor2 ?? '#999999'" d="M26.2119 2.0249H35.4039V82.0249H26.2119V2.0249Z" />
      <path [style.fill]="hexFillColor1 ?? '#707070'" d="M35.4043 2.0249H44.5963V82.0249H35.4043V2.0249Z" />
      <path [style.fill]="hexFillColor2 ?? '#999999'" d="M44.5962 2.0249H53.7882V82.0249H44.5962V2.0249Z" />
      <path [style.fill]="hexFillColor1 ?? '#707070'" d="M53.7881 2.0249H62.9801V82.0249H53.7881V2.0249Z" />
      <path [style.fill]="hexFillColor2 ?? '#999999'" d="M62.9805 2.0249H72.1725V82.0249H62.9805V2.0249Z" />
      <path [style.fill]="hexFillColor1 ?? '#707070'" d="M72.1724 2.0249H81.8003V82.0249H72.1724V2.0249Z" />
    </g>
  </g>
  <rect width="34.1958" height="11.1297" transform="translate(22.9185 31)" fill="#0C0C0C" />
  <path
    d="M47.291 39.1297L47.4029 38.5332L50.2734 35.2302H48.1634L48.3796 34H53.1141L53.0097 34.589L50.1317 37.8995H52.4804L52.2641 39.1297H47.291Z"
    fill="#F5F5F5" />
  <path
    d="M41.645 39.1297L42.5546 34H44.2024L43.8296 36.0951L45.962 34H48.0721L45.8576 36.11L47.1997 39.1297H45.3134L44.5528 37.1986L43.4792 38.1232L43.2928 39.1297H41.645Z"
    fill="#F5F5F5" />
  <path
    d="M35.5835 39.1297L36.4931 34H38.1409L37.7681 36.0951L39.9005 34H42.0105L39.7961 36.11L41.1382 39.1297H39.2518L38.4913 37.1986L37.4177 38.1232L37.2313 39.1297H35.5835Z"
    fill="#F5F5F5" />
  <path d="M32.9805 39.1297L33.8901 34H35.5379L34.6282 39.1297H32.9805Z" fill="#F5F5F5" />
  <path
    d="M26.9185 39.1297L27.8281 34H29.4759L29.1031 36.0951L31.2355 34H33.3455L31.1311 36.11L32.4732 39.1297H30.5868L29.8263 37.1986L28.7526 38.1232L28.5662 39.1297H26.9185Z"
    fill="#F5F5F5" />
  <rect width="7.99767" height="3.17442" transform="translate(49.7998 17.5)" fill="#0C0C0C" />
  <path
    d="M55.4644 19.6744L55.49 19.5378L56.1471 18.7817H55.6641L55.7136 18.5H56.7975L56.7736 18.6349L56.1147 19.3927H56.6524L56.6029 19.6744H55.4644Z"
    fill="#F5F5F5" />
  <path
    d="M54.1709 19.6744L54.3791 18.5H54.7564L54.671 18.9797L55.1592 18.5H55.6423L55.1353 18.9831L55.4426 19.6744H55.0107L54.8366 19.2323L54.5908 19.444L54.5481 19.6744H54.1709Z"
    fill="#F5F5F5" />
  <path
    d="M52.7837 19.6744L52.9919 18.5H53.3692L53.2838 18.9797L53.772 18.5H54.2551L53.7481 18.9831L54.0554 19.6744H53.6235L53.4494 19.2323L53.2036 19.444L53.1609 19.6744H52.7837Z"
    fill="#F5F5F5" />
  <path d="M52.1875 19.6744L52.3958 18.5H52.773L52.5647 19.6744H52.1875Z" fill="#F5F5F5" />
  <path
    d="M50.7998 19.6744L51.0081 18.5H51.3853L51.2999 18.9797L51.7881 18.5H52.2712L51.7642 18.9831L52.0715 19.6744H51.6396L51.4655 19.2323L51.2197 19.444L51.177 19.6744H50.7998Z"
    fill="#F5F5F5" />
  <defs>
    <clipPath id="clip0_2509_7025">
      <rect width="76" height="76" fill="white" transform="translate(2 2.5)" />
    </clipPath>
  </defs>
</svg>
