import { APP_INITIALIZER, isDevMode, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule, TitleStrategy } from '@angular/router';
import { AppComponent } from './app.component';
import { appRoutes } from './app.routes';
import { StoreModule } from '@ngrx/store';
import { Actions, EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import {
  AD_GAMES_OVERVIEW_FEATURE_FLAG_TOKEN,
  AD_LOADING_SCREEN_FEATURE_FLAG_TOKEN,
  BACKEND_TOKEN,
  BULI_LICENSE_FEATURE_FLAG_TOKEN,
  FAKE_OR_FACT_FEATURE_FLAG_TOKEN,
  MIXED_ZONE_FEATURE_FLAG_TOKEN,
  NATIONS_FEATURE_FLAG_TOKEN,
  TOPSCORER_FEATURE_FLAG_TOKEN,
  TROPHIES_FEATURE_FLAG_TOKEN,
  TTT_FEATURE_FLAG_TOKEN,
  WII_FEATURE_FLAG_TOKEN,
} from '@kiq/client/util/config';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { Auth, getAuth, provideAuth } from '@angular/fire/auth';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { initializeApplication } from './app-initializer';
import { ClientDataAccessUserModule, UserFacade } from '@kiq/client/data-access/user';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { provideLottieOptions } from 'ngx-lottie';
import { environment } from '../environments/environment';
import { ConfigFacade, CustomTitleStrategyService } from '@kiq/client/data-access/config';
import { AuthInterceptorService } from '@kiq/client/feature/shell';
import { AppAuthenticationService, AUTH_SERVICE, AuthenticationService } from 'authentication';
import { NativeAppsService } from 'native-apps';
import { ModalService } from '@kiq/shared/util/modal';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(appRoutes, { initialNavigation: 'enabledBlocking', scrollPositionRestoration: 'enabled' }),
    StoreModule.forRoot(
      {},
      {
        metaReducers: [],
        runtimeChecks: {
          strictActionImmutability: true,
          strictStateImmutability: true,
        },
      },
    ),
    EffectsModule.forRoot([]),
    StoreRouterConnectingModule.forRoot(),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: !isDevMode(),
      connectInZone: true,
      actionsBlocklist: ['time tick'],
    }),
    provideFirebaseApp(() => initializeApp(environment.firebaseConfig)),
    provideAuth(() => getAuth()),
    ClientDataAccessUserModule,
  ],
  providers: [
    {
      provide: AUTH_SERVICE,
      useFactory: () => {
        const isInNativeApp = navigator.userAgent.includes('-KIKKZ-app-android') || (navigator.userAgent.includes('-KIKKZ-app-ios'));
        if (isInNativeApp) {
          return new AppAuthenticationService();
        }
        else return new AuthenticationService();
      },
      deps: [NativeAppsService, ConfigFacade, Auth, UserFacade, ModalService]
    },
    { provide: BACKEND_TOKEN, useValue: environment.API.BASE },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApplication,
      deps: [Actions, UserFacade, Auth, AUTH_SERVICE],
      multi: true,
    },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptorService, multi: true },
    provideLottieOptions({
      player: () => import('lottie-web'),
    }),
    {
      provide: TitleStrategy,
      useClass: CustomTitleStrategyService,
    },
    { provide: TTT_FEATURE_FLAG_TOKEN, useValue: environment.API.TTT_FEATURE_FLAG },
    { provide: WII_FEATURE_FLAG_TOKEN, useValue: environment.API.WII_FEATURE_FLAG },
    { provide: TOPSCORER_FEATURE_FLAG_TOKEN, useValue: environment.API.TOPSCORER_FEATURE_FLAG },
    { provide: MIXED_ZONE_FEATURE_FLAG_TOKEN, useValue: environment.API.MIXED_ZONE_FEATURE_FLAG_TOKEN },
    { provide: FAKE_OR_FACT_FEATURE_FLAG_TOKEN, useValue: environment.API.FAKE_OR_FACT_FEATURE_FLAG_TOKEN },
    { provide: AD_LOADING_SCREEN_FEATURE_FLAG_TOKEN, useValue: environment.API.AD.LOADING_SCREEN_FEATURE_FLAG },
    { provide: AD_GAMES_OVERVIEW_FEATURE_FLAG_TOKEN, useValue: environment.API.AD.GAMES_OVERVIEW_FEATURE_FLAG },
    { provide: NATIONS_FEATURE_FLAG_TOKEN, useValue: environment.API.NATIONS_FEATURE_FLAG },
    { provide: BULI_LICENSE_FEATURE_FLAG_TOKEN, useValue: environment.API.BULI_LICENSE_FEATURE_FLAG },
    { provide: TROPHIES_FEATURE_FLAG_TOKEN, useValue: environment.API.TROPHIES_FEATURE_FLAG },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
