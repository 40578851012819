import { GameTopscorerConfig } from './game-topscorer-config';
import { GameEndReason } from './game-end-reason';
import { GameTopscorerQuestion } from './game-topscorer-question';

export type GameTopscorerState = {
  gameId: string;
  config: GameTopscorerConfig;
  currentQuestion: GameTopscorerQuestion;
  maxTurnDurationSeconds?: number;
  turnTimeoutTimestamp?: Date;
  gameEnded: boolean;
  gameEndReason?: GameEndReason;
  turnEnded?: boolean;
  userScore?: number;
};
