<div
  class="flex text-gray-200 items-center border border-grey14 hover:border-white rounded bg-surfaceSecondary px-3 py-3.5 gap-2">
  <span class="prefix pl-4" *ngIf="prefixIconName"><shared-icon class="icon-wrapper" [name]="prefixIconName" /></span>
  <input
    class="bg-surfaceSecondary w-full text-white text-base font-normal placeholder:text-neutral-400 placeholder:text-base placeholder:font-normal active:outline-none focus:outline-none"
    #input
    [formControl]="control"
    [autocomplete]="autocomplete"
    [type]="inputType"
    [placeholder]="placeholder"
    (focusin)="focused.set(true)"
    (focusout)="focused.set(false)" />
  <span class="suffix flex pr-4">
    <button
      class="p-0"
      *ngIf="control.dirty && (control.value.length ?? 0) > 0"
      [disabled]="control.disabled"
      (click)="onClearButtonClick()"
      type="button"
      tabindex="-1">
      <shared-icon class="icon-wrapper" name="close"></shared-icon>
    </button>
  </span>
</div>

<label class="label" *ngIf="(hint?.length ?? 0) > 0"
  ><span class="label-text-alt text-white">{{ hint }}</span></label
>

<label
  *ngIf="
    errorTypes.includes('required') && control?.hasError('required') && control?.dirty && control?.touched && !focused()
  ">
  <span class="label-text-alt text-red-600">{{ "Dies ist eine Pflichtangabe" }}</span>
</label>
<label
  *ngIf="
    errorTypes.includes('email') && control?.hasError('email') && control?.dirty && control?.touched && !focused()
  ">
  <span class="label-text-alt text-red-600">{{ "Bitte verwende eine valide E-Mail-Adresse" }}</span>
</label>
<label
  *ngIf="
    errorTypes.includes('username') &&
    control?.hasError('invalidUsername') &&
    control?.dirty &&
    control?.touched &&
    !focused()
  ">
  <span class="label-text-alt text-red-600">{{ "Verwende nur Zahlen, Buchstaben, Unterstriche oder Punkte." }}</span>
</label>
<label
  *ngIf="
    inputType === 'password' && control?.hasError('minlength') && control?.dirty && control?.touched && !focused()
  ">
  <span class="label-text-alt text-red-600">{{ "Dein Passwort muss zwischen 6 und 32 Zeichen lang sein." }}</span>
</label>
<label
  *ngIf="
    inputType === 'text' &&
    errorTypes.includes('username') &&
    control?.hasError('maxlength') &&
    control?.dirty &&
    control?.touched &&
    !focused()
  ">
  <span class="label-text-alt text-red-600">{{ "Dein Benutzername muss zwischen 3 und 32 Zeichen lang sein." }}</span>
</label>
<label
  *ngIf="
    inputType === 'text' &&
    errorTypes.includes('username') &&
    control?.hasError('minlength') &&
    control?.dirty && control?.touched && !focused()
  ">
  <span class="label-text-alt text-red-600">{{ "Dein Benutzername muss zwischen 3 und 32 Zeichen lang sein." }}</span>
</label>
