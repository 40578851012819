import { Authentication } from './authentication.interface';
import { InGameModalConfig, ModalConfig } from '@kiq/shared/types';
import { LoginComponent, RegisterComponent } from '@kiq/client/feature/auth';
import { inject, Injectable } from '@angular/core';
import { ConfigFacade } from '@kiq/client/data-access/config';
import { InGameModalService, ModalService } from '@kiq/shared/util/modal';
import { BehaviorSubject, concatMap, from, map, of, Subject, switchMap } from 'rxjs';
import { Auth, user } from '@angular/fire/auth';
import { UserFacade } from '@kiq/client/data-access/user';

@Injectable()
export class AuthenticationService implements Authentication {
  private configFacade = inject(ConfigFacade);
  private modalService = inject(ModalService);
  private userFacade = inject(UserFacade);
  private auth = inject(Auth);

  token$ = user(this.auth).pipe(switchMap((user) => (user ? from(user.getIdToken()) : of(null))));

  login(inGameModalService?: InGameModalService): void {
    if (inGameModalService?.hasModalOpen) {
      const inGameModalConfig: InGameModalConfig = {
        component: LoginComponent,
        isFullModalHeight: true,
      };

      inGameModalService?.updateInGameModal(inGameModalConfig);
    } else {
      // const config = this.modalConfig$.getValue();
      //
      // config.component = LoginComponent;
      // this.modalService.openNestedModal(config);

      const modalConfig: ModalConfig = {
        component: LoginComponent,
        useFullscreenModal: this.configFacade.useFullscreenModal(),
      };

      this.modalService.openNestedModal(modalConfig);
    }

    // const modalConfig: ModalConfig = {
    //   component: LoginComponent,
    //   useFullscreenModal: this.configFacade.useFullscreenModal(),
    // };
    //
    // this.modalService.openNestedModal(modalConfig);
  }

  register(inGameModalService?: InGameModalService): void {
    // const modalConfig: ModalConfig = {
    //   component: RegisterComponent,
    //   useFullscreenModal: this.configFacade.useFullscreenModal(),
    // };
    //
    // this.modalService.openNestedModal(modalConfig);

    if (inGameModalService?.hasModalOpen) {
      const inGameModalConfig: InGameModalConfig = {
        component: RegisterComponent,
        isFullModalHeight: true,
      };

      inGameModalService?.updateInGameModal(inGameModalConfig);
    } else {
      // const config = this.modalConfig$.getValue();
      //
      // config.component = RegisterComponent;
      //
      // this.modalService.openNestedModal(config);
      const modalConfig: ModalConfig = {
        component: RegisterComponent,
        useFullscreenModal: this.configFacade.useFullscreenModal(),
      };

      this.modalService.openNestedModal(modalConfig);
    }
  }

  logout(): void {
    this.userFacade.logout();
  }
}
