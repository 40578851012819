import { ActivatedRouteSnapshot, RouterStateSnapshot, TitleStrategy } from '@angular/router';
import { inject, Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Injectable({ providedIn: 'root' })
export class CustomTitleStrategyService extends TitleStrategy {
  title = inject(Title);

  updateTitle(snapshot: RouterStateSnapshot) {
    const title: string[] = [];
    const deepestChild = this.getDeepestChild(snapshot.root);
    const routeTitleData = deepestChild?.data?.['title'];

    if (routeTitleData) {
      title.push(routeTitleData);
    }

    title.push('KIKKZ');
    this.title.setTitle(title.join(' | '));
  }

  setTitle(newTitle: string) {
    const title: string[] = [];
    title.push(newTitle);
    title.push('KIKKZ');
    const finalTitle = title.join(' | ');
    this.title.setTitle(finalTitle);
    this.setEtrackerTitle(finalTitle);
  }

  setEtrackerTitle(eTrackerTitle: string) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (typeof _etracker === 'object') {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      et_eC_Wrapper({
        et_et: 'z4skWx',
        et_pagename: eTrackerTitle,
      });
    }
  }

  sendEtrackerEvent(eTrackerObject: string, eTrackerCategory?: string, eTrackerAction?: string, eTrackerType?: string) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (typeof _etracker === 'object') {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      _etracker.sendEvent(new et_UserDefinedEvent(eTrackerObject, eTrackerCategory, eTrackerAction, eTrackerType));
    }
  }

  private getDeepestChild(node: ActivatedRouteSnapshot): ActivatedRouteSnapshot {
    if (!node.children || node.children.length === 0) {
      return node;
    }

    return this.getDeepestChild(node.children[0]);
  }
}
