import { Component, Input } from '@angular/core';

@Component({
  selector: 'shared-club-icon',
  templateUrl: './club-icon.component.html',
  styleUrls: ['./club-icon.component.scss'],
})
export class ClubIconComponent {
  @Input() hexFillColor1: string | undefined;
  @Input() hexFillColor2: string | undefined;
  @Input() iconSize = 80;
}
