import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'shared-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss'],
})
export class IconComponent implements OnChanges {
  @Input() name: string | undefined;

  iconPath?: string;

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.['name'] || changes?.['type']) {
      this.iconPath = `url('/assets/icons/${this.name}.svg')`;
    }
  }
}
