import { inject, Injectable } from '@angular/core';
import { ConfigFacade } from '@kiq/client/data-access/config';
import { NativeAppType } from '@kiq/shared/enums';
import { BehaviorSubject, Observable } from 'rxjs';
type FirebaseUser = import('firebase/auth').User;

@Injectable({
  providedIn: 'root',
})
export class NativeAppsService {
  private readonly configFacade = inject(ConfigFacade);
  nativeAppType = this.configFacade.nativeAppType;
  private readonly userAgent: string;
  private firebaseUser = new BehaviorSubject<FirebaseUser | null>(null);
  firebaseUser$: Observable<FirebaseUser | null> = this.firebaseUser.asObservable();

  constructor() {
    this.userAgent = navigator.userAgent;
  }

  openLoginModal() {
    if (this.nativeAppType()) {
      switch (this.nativeAppType()) {
        case NativeAppType.AndroidApp:
          console.log('open login modal on android');
          (window as any).Android.openLogin();
          break;
        case NativeAppType.IosApp:
          console.log('open login modal on ios');
          (window as any).webkit.messageHandlers.authHandler.postMessage('openLogin');
          break;
      }
    }
  }

  openRegisterModal() {
    if (this.nativeAppType()) {
      switch (this.nativeAppType()) {
        case NativeAppType.AndroidApp:
          console.log('open register modal on android');
          (window as any).Android.openRegister();
          break;
        case NativeAppType.IosApp:
          console.log('open register modal on ios');
          (window as any).webkit.messageHandlers.authHandler.postMessage('openRegister');
          break;
      }
    }
  }

  logout() {
    if (this.nativeAppType()) {
      switch (this.nativeAppType()) {
        case NativeAppType.AndroidApp:
          console.log('logout on android');
          (window as any).Android.logout();
          break;
        case NativeAppType.IosApp:
          console.log('logout on ios');
          (window as any).webkit.messageHandlers.authHandler.postMessage('logout');
          break;
      }
    }
  }

  getFirebaseUser() {
    if (this.nativeAppType()) {
      switch (this.nativeAppType()) {
        case NativeAppType.AndroidApp:
          console.log('get Firebase user from Android App');
          (window as any).Android.getFirebaseUser();
          break;
        case NativeAppType.IosApp:
          console.log('get Firebase user from ios App');
          (window as any).webkit.messageHandler.authHandler.postMessage('getFirebaseUser');
          break;
      }
    }
  }

  loginByApp(firebaseUser: FirebaseUser) {
    this.firebaseUser.next(firebaseUser)
  }
}
