import { createFeature, createReducer } from '@ngrx/store';
import { immerOn } from 'ngrx-immer/store';
import { OnlineStatisticsActions } from './online-statistics.actions';
import {
  UserGameStatisticOpponentDetail,
  UserGameStatisticOpponentOverview,
  UserGameStatisticOverview,
} from '@kiq/shared/types';
import { BasicUser, User } from '@kiq/shared/classes';

export interface OnlineStatisticsState {
  loading: boolean;
  userGameStatisticOverview: UserGameStatisticOverview | null;
  allUserGameStatisticOpponentOverviews: Array<UserGameStatisticOpponentOverview> | null;
  userGameStatisticOpponentDetails: Array<UserGameStatisticOpponentDetail> | null;
  otherUser: User | BasicUser | null;
  userGameStatisticOpponentOverview: UserGameStatisticOverview | null;
}

export const initialOnlineStatisticsState: OnlineStatisticsState = {
  loading: false,
  userGameStatisticOverview: null,
  allUserGameStatisticOpponentOverviews: null,
  userGameStatisticOpponentDetails: null,
  otherUser: null,
  userGameStatisticOpponentOverview: null
};

export const onlineStatisticsFeature = createFeature({
  name: 'onlineStatistics',

  reducer: createReducer(
    initialOnlineStatisticsState,
    //Add immerOn
    immerOn(OnlineStatisticsActions.getOwnUserGameStatisticOverviewStart, (state) => {
      state.loading = true;
    }),
    immerOn(OnlineStatisticsActions.getOwnUserGameStatisticOverviewSuccess, (state, { userGameStatisticOverview }) => {
      state.userGameStatisticOverview = userGameStatisticOverview;
      state.loading = false;
    }),
    immerOn(OnlineStatisticsActions.getOwnUserGameStatisticOverviewFail, (state) => {
      state.loading = false;
    }),

    immerOn(OnlineStatisticsActions.getOtherUserGameStatisticOverviewStart, (state) => {
      state.loading = true;
    }),
    immerOn(OnlineStatisticsActions.getOtherUserGameStatisticOverviewSuccess, (state, { userGameStatisticOpponentOverview }) => {
      state.userGameStatisticOpponentOverview = userGameStatisticOpponentOverview;
      state.loading = false;
    }),
    immerOn(OnlineStatisticsActions.getOtherUserGameStatisticOverviewFail, (state) => {
      state.loading = false;
    }),

    immerOn(OnlineStatisticsActions.getAllUserGameStatisticOpponentOverviewsStart, (state) => {
      state.loading = true;
    }),
    immerOn(OnlineStatisticsActions.getAllUserGameStatisticOpponentOverviewsSuccess, (state, { allUserGameStatisticOpponentOverviews }) => {
      state.allUserGameStatisticOpponentOverviews = allUserGameStatisticOpponentOverviews;
      state.loading = false;
    }),
    immerOn(OnlineStatisticsActions.getAllUserGameStatisticOpponentOverviewsFail, (state) => {
      state.loading = false;
    }),

    immerOn(OnlineStatisticsActions.getUserGameStatisticOpponentDetailsStart, (state, {otherUser}) => {
      state.loading = true;
    }),
    immerOn(OnlineStatisticsActions.getUserGameStatisticOpponentDetailsSuccess, (state, { userGameStatisticOpponentDetails }) => {
      state.userGameStatisticOpponentDetails = userGameStatisticOpponentDetails;
      state.loading = false;
    }),
    immerOn(OnlineStatisticsActions.getUserGameStatisticOpponentDetailsFail, (state) => {
      state.loading = false;
    }),

    immerOn(OnlineStatisticsActions.getOtherUserStart, (state, {userName}) => {
      state.loading = true;
      state.otherUser = null;
    }),
    immerOn(OnlineStatisticsActions.getOtherUserSuccess, (state, { otherUser }) => {
      state.otherUser = otherUser;
      state.loading = false;
    }),
    immerOn(OnlineStatisticsActions.getOtherUserFail, (state) => {
      state.otherUser = null;
      state.loading = false;
    }),
  ),
});

export const { selectLoading, selectUserGameStatisticOverview, selectAllUserGameStatisticOpponentOverviews, selectUserGameStatisticOpponentDetails, selectUserGameStatisticOpponentOverview, selectOtherUser } = onlineStatisticsFeature;
