<div
  class="p-2 flex gap-x-2 items-center !shadow-transparent"
  [ngClass]="{
    'success-snackbar': data.type === 'success',
    'error-snackbar': data.type === 'error',
    'loading-error-snackbar': data.type === 'loadingError'
  }">
  <div class="flex items-center flex-grow gap-2">
    <ng-container *ngIf="data.type === 'success'">
      <shared-icon class="icon-wrapper" name="thumbs-up-icon"></shared-icon>
    </ng-container>
    <ng-container *ngIf="data.type === 'error'">
      <shared-icon class="icon-fill" name="thumbs-down-icon"></shared-icon>
    </ng-container>
    {{ data.message }}
  </div>
  <ng-container *ngIf="data.type === 'error' || data.type === 'success'">
    <button class="" (click)="close()">
      <shared-icon class="icon-wrapper" name="close"></shared-icon>
    </button>
  </ng-container>
  <ng-container *ngIf="data.type === 'loadingError'">
    <div class="h-[30px]">
      <client-button [buttonType]="'primary'"><p class="text-sm">Seite aktualisieren</p></client-button>
    </div>
  </ng-container>
</div>
