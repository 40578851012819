import { inject, Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { UserFacade } from 'libs/client/data-access/user/src';
import { OnlineStatisticsActions } from './online-statistics.actions';
import { catchError, concatMap, map, of } from 'rxjs';
import { OnlineStatisticsService } from '../service/online-statistics.service';
import { SnackbarService } from 'shared/util/snackbar';
import { HttpErrorResponse } from '@angular/common/http';
import { OtherUserService } from 'other-user';

@Injectable()
export class OnlineStatisticsEffects {
  private readonly actions = inject(Actions);
  private readonly userFacade = inject(UserFacade);
  private readonly snackbarService = inject(SnackbarService);
  private readonly onlineStatisticsService = inject(OnlineStatisticsService);
  private readonly otherUserService = inject(OtherUserService);

  getOwnOverallOnlineStatistics$ = createEffect(() => {
    return this.actions.pipe(
      ofType(OnlineStatisticsActions.getOwnUserGameStatisticOverviewStart),
      concatLatestFrom(() => this.userFacade.user$),
      concatMap(([, user]) => {
        return this.onlineStatisticsService.getOwnUserStatisticOverview(user?.id ?? '').pipe(
          map((ownOverallOnlineStatistic) => OnlineStatisticsActions.getOwnUserGameStatisticOverviewSuccess({ userGameStatisticOverview: ownOverallOnlineStatistic })),
          catchError((errorRes: HttpErrorResponse) => {
            const errorCode = errorRes.error.errorCode;
            return of(OnlineStatisticsActions.getOwnUserGameStatisticOverviewFail());
          }),
        );
      }),
    );
  });

  getOtherUsersOverallOnlineStatistics$ = createEffect(() => {
    return this.actions.pipe(
      ofType(OnlineStatisticsActions.getOtherUserGameStatisticOverviewStart),
      concatMap((otherUser) => {
        return this.onlineStatisticsService.getUserGameStatisticOpponentOverview(otherUser.otherUser.id).pipe(
          map((otherUsersOverallOnlineStatistic) => OnlineStatisticsActions.getOtherUserGameStatisticOverviewSuccess({ userGameStatisticOpponentOverview: otherUsersOverallOnlineStatistic })),
          catchError((errorRes: HttpErrorResponse) => {
            const errorCode = errorRes.error.errorCode;
            return of(OnlineStatisticsActions.getOtherUserGameStatisticOverviewFail());
          }),
        );
      }),
    );
  });

  getAllOnlineStatistics$ = createEffect(() => {
    return this.actions.pipe(
      ofType(OnlineStatisticsActions.getAllUserGameStatisticOpponentOverviewsStart),
      concatMap(() => {
        return this.onlineStatisticsService.getAllUserGameStatisticOpponentOverviews().pipe(
          map((allUserGameStatisticOpponentOverviews) => OnlineStatisticsActions.getAllUserGameStatisticOpponentOverviewsSuccess({ allUserGameStatisticOpponentOverviews })),
          catchError((errorRes: HttpErrorResponse) => {
            const errorCode = errorRes.error.errorCode;
            return of(OnlineStatisticsActions.getAllUserGameStatisticOpponentOverviewsFail());
          }),
        );
      }),
    );
  });

  getOnlineStatisticsVersusUser$ = createEffect(() => {
    return this.actions.pipe(
      ofType(OnlineStatisticsActions.getOtherUserSuccess, OnlineStatisticsActions.getUserGameStatisticOpponentDetailsStart),
      concatMap((otherUser) => {
        return this.onlineStatisticsService.getAllUserGameStatisticOpponentDetails(otherUser.otherUser.id).pipe(
          map((userGameStatisticOpponentDetails) =>
            OnlineStatisticsActions.getUserGameStatisticOpponentDetailsSuccess({ userGameStatisticOpponentDetails }),
          ),
          catchError((errorRes: HttpErrorResponse) => {
            const errorCode = errorRes.error.errorCode;
            return of(OnlineStatisticsActions.getUserGameStatisticOpponentDetailsFail());
          }),
        );
      }),
    );
  });

  getOtherUser$ = createEffect(() => {
    return this.actions.pipe(
      ofType(OnlineStatisticsActions.getOtherUserStart),
      concatMap((userName) => {
        return this.otherUserService.getOtherUserFromUsername$(userName.userName).pipe(
          map((otherUser) =>
            OnlineStatisticsActions.getOtherUserSuccess({ otherUser }),
          ),
          catchError((errorRes: HttpErrorResponse) => {
            const errorCode = errorRes.error.errorCode;
            return of(OnlineStatisticsActions.getOtherUserFail());
          }),
        );
      }),
    );
  });
}
