import { AdvertisementAsset } from '@kiq/shared/interfaces';

export const advertisementData: Array<AdvertisementAsset> = [
  {
    id: '1',
    url: 'assets/img/advertisement-placeholder.svg',
  },
  {
    id: '2',
    url: 'assets/img/itsquare-logo-bg-white.svg',
  },
  {
    id: '3',
    url: 'assets/img/kikkz-logo.svg',
  },
];
