import { Route } from '@angular/router';
import { clientRoutes } from '@kiq/client/util/routing';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { VerifyComponent } from './verify/verify.component';

export const clientFeatureAuthRoutes: Route[] = [
  {
    path: '',
    component: VerifyComponent,
  },
  {
    path: `${clientRoutes.AUTH.LOGIN}`,
    component: LoginComponent,
  },
  {
    path: `${clientRoutes.AUTH.REGISTER}`,
    component: RegisterComponent,
  },
];
