import { GameDifficultyLevel } from '@kiq/shared/enums';
import { createFeature, createReducer } from '@ngrx/store';
import { immerOn } from 'ngrx-immer/store';
import { GamesOverviewActions } from './games-overview.actions';

export interface GamesOverviewState {
  selectedDifficulty: GameDifficultyLevel | null;
}

export const initialGamesOverviewState: GamesOverviewState = {
  selectedDifficulty: GameDifficultyLevel.easy,
};

export const gamesOverviewFeature = createFeature({
  name: 'gamesOverview',

  reducer: createReducer(
    initialGamesOverviewState,

    immerOn(GamesOverviewActions.setDifficulty, (state, { difficulty }) => {
      state.selectedDifficulty = difficulty;
    }),
  ),
});

export const {
  name, // feature name
  reducer, // feature reducer
  selectSelectedDifficulty,
} = gamesOverviewFeature;
