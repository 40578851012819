import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { CreateUserDto } from '@kiq/shared/interfaces';
import { UserCredential } from '@angular/fire/auth';
import { User } from '@kiq/shared/classes';
import { FootballTeam } from '@kiq/shared/types';

export const UserActions = createActionGroup({
  source: 'User',
  events: {
    'create new firebase user start': props<{
      email: string;
      password: string;
      username: string;
      favouriteClub: FootballTeam;
    }>(),
    'create new firebase user success': props<{
      user: UserCredential;
      username: string;
      favouriteClub?: FootballTeam;
    }>(),
    'create new firebase user failure': props<{
      error: string;
    }>(),

    'create new user with google start': emptyProps(),
    'create new user with apple start': emptyProps(),

    'login user with google start': emptyProps(),
    'login user with apple start': emptyProps(),

    'login user with popup success': props<{
      userCredential: UserCredential;
    }>(),
    'login user with popup fail': props<{
      error: string;
    }>(),

    'delete firebase user start': props<{
      error: string;
    }>(),
    'delete firebase user success': props<{
      error: string;
    }>(),
    'delete firebase user fail': props<{
      error: string;
    }>(),

    'create new backend user': props<{
      user: CreateUserDto;
    }>(),
    'create new backend user success': props<{
      user: User;
    }>(),
    'create new backend user failure': props<{
      error: string;
    }>(),

    'login start': props<{
      email: string;
      password: string;
    }>(),
    'login success': emptyProps(),
    'login failure': props<{
      error: string;
    }>(),

    'get backend user after popup login': props<{
      userCredential: UserCredential;
    }>(),

    'get backend user': emptyProps(),
    'get backend user success': props<{
      user: User;
    }>(),
    'get backend user failure': props<{
      error: string;
    }>(),

    // 'get backend user failure after popup login': emptyProps(),

    'get backend user failure after popup login': props<{
      userCredential: UserCredential;
      // username: string;
    }>(),

    'get backend user failure after popup login fail': props<{
      error: string;
    }>(),

    'logout firebase user start': emptyProps(),
    'logout firebase user success': emptyProps(),
    'logout firebase user fail': props<{
      error: string;
    }>(),

    'update username start': props<{
      username: string;
    }>(),
    'update username success': props<{
      user: User;
    }>(),
    'update username fail': props<{
      error: string;
    }>(),

    'update favourite club start': props<{
      favClubId: FootballTeam;
    }>(),
    'update favourite club success': props<{
      user: User;
    }>(),
    'update favourite club fail': props<{
      error: string;
    }>(),

    'update profile image start': props<{ imageUrl: string }>(),
    'update profile image success': props<{ imageUrl: string }>(),
    'update profile image fail': props<{ error: string }>(),

    'delete profile image start': emptyProps(),
    'delete profile image success': emptyProps(),
    'delete profile image fail': props<{ error: string }>(),

    'update email address start': props<{
      email: string;
    }>(),
    'update email address success': props<{
      user: User;
    }>(),
    'update email address fail': props<{
      error: string;
    }>(),

    'update password start': props<{
      oldPassword: string;
      newPassword: string;
    }>(),
    'update password success': emptyProps(),
    'update password fail': props<{
      error: string;
    }>(),

    'delete account start': emptyProps(),
    'delete account success': emptyProps(),
    'delete account fail': props<{
      error: string;
    }>(),

    'upload profile image start': props<{
      file: File;
    }>(),
    'upload profile image success': props<{
      imageUrl: string;
    }>(),
    'upload profile image fail': props<{
      error: string;
    }>(),

    'reset password start': props<{
      email: string;
    }>(),
    'reset password success': emptyProps(),
    'reset password fail': emptyProps(),

    'verify and confirm password reset start': props<{
      oobCode: string;
      password: string;
    }>(),
    'verify and confirm password reset success': emptyProps(),
    'verify and confirm password reset fail': emptyProps(),

    'update backend user start': props<{
      user: CreateUserDto;
    }>(),
    'update backend user success': props<{
      user: User;
    }>(),
    'update backend user fail': props<{
      error: string;
    }>(),

    'reauthenticate user with token start': emptyProps(),
    'reauthenticate user with token success': emptyProps(),
    'reauthenticate user with token fail': props<{
      error: string;
    }>(),

    'set profile notification': props<{profileNotification: boolean}>(),
    'set profile notification success': emptyProps(),

    'get profile notification': emptyProps(),
    'get profile notification success': props<{profileNotification: boolean}>(),
  },
});
