import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  selectIsLoggedIn,
  selectLoading,
  selectProfileNotification,
  selectUser,
  UserState,
  userVm,
} from './user.reducer';
import { UserActions } from './user.actions';
import { CreateUserDto } from '@kiq/shared/interfaces';
import { FootballTeam } from '@kiq/shared/types';

@Injectable({ providedIn: 'root' })
export class UserFacade {
  private store: Store<UserState> = inject(Store);

  readonly loading$ = this.store.select(selectLoading);
  readonly user$ = this.store.select(selectUser);
  readonly isLoggedIn$ = this.store.select(selectIsLoggedIn);
  readonly profileNotification$ = this.store.select(selectProfileNotification);
  readonly vm$ = this.store.select(userVm);

  readonly getBackendUserSuccess = UserActions.getBackendUserSuccess;
  readonly createNewBackendUserSuccess = UserActions.createNewBackendUserSuccess;
  readonly getBackendUserFail = UserActions.getBackendUserFailure;

  createNewUser(email: string, password: string, username: string, favouriteClub: FootballTeam) {
    this.store.dispatch(UserActions.createNewFirebaseUserStart({ email, password, username, favouriteClub }));
  }

  login(email: string, password: string) {
    this.store.dispatch(UserActions.loginStart({ email, password }));
  }

  logout() {
    this.store.dispatch(UserActions.logoutFirebaseUserStart());
  }

  getBackendUser() {
    this.store.dispatch(UserActions.getBackendUser());
  }

  loginWithGoogle() {
    this.store.dispatch(UserActions.loginUserWithGoogleStart());
  }

  loginWithApple() {
    this.store.dispatch(UserActions.loginUserWithAppleStart());
  }

  createUserWithGoogle() {
    this.store.dispatch(UserActions.createNewUserWithGoogleStart());
  }

  createUserWithApple() {
    this.store.dispatch(UserActions.createNewUserWithAppleStart());
  }

  uploadeProfileImage(file: File) {
    this.store.dispatch(UserActions.uploadProfileImageStart({ file }));
  }

  resetPassword(email: string) {
    this.store.dispatch(UserActions.resetPasswordStart({ email }));
  }

  verifyAndConfirmPasswordReset(oobCode: string, password: string) {
    this.store.dispatch(UserActions.verifyAndConfirmPasswordResetStart({ oobCode, password }));
  }

  updateUsername(username: string) {
    this.store.dispatch(UserActions.updateUsernameStart({ username }));
  }

  updateFavouriteClub(favClubId: FootballTeam) {
    this.store.dispatch(UserActions.updateFavouriteClubStart({ favClubId }));
  }

  updateUser(user: CreateUserDto) {
    this.store.dispatch(UserActions.updateBackendUserStart({ user }));
  }

  updatePassword(oldPassword: string, newPassword: string) {
    this.store.dispatch(UserActions.updatePasswordStart({ oldPassword, newPassword }));
  }
  deleteAccount() {
    this.store.dispatch(UserActions.deleteAccountStart());
  }

  deleteProfileImage() {
    this.store.dispatch(UserActions.deleteProfileImageStart());
  }

  setProfileNotification(profileNotification: boolean) {
    this.store.dispatch(UserActions.setProfileNotification({ profileNotification }));
  }

  getProfileNotification() {
    this.store.dispatch(UserActions.getProfileNotification());
  }
}
