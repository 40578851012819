import { GameType } from '@kiq/shared/enums';
import { GameMode } from '@kiq/shared/types';

export interface Game {
  id: string;
  name: string;
  icon: string;
  disabled?: boolean;
  type: GameType;
  gameMode?: GameMode;
}
