import { AttributeType, FootballPlayerAgeType, FootballPlayerPosition, PreferredFoot } from '@kiq/shared/enums';
import { FootballTeam } from './football-team';
import { NationalTeam } from './national-team';
import { FootballPlayerNationality } from './football-player-nationality';

export type AxisAttribute = {
  type: AttributeType;
  team?: FootballTeam;
  nationalTeam?: NationalTeam;
  position?: FootballPlayerPosition;
  preferredFoot?: PreferredFoot;
  nationality?: FootballPlayerNationality;
  ageType?: FootballPlayerAgeType;

  //TODO: fit naming to backend when its implemented
  olderThan28?: boolean;
};
