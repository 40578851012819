import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'kiq-challengers-loader',
  templateUrl: './challengers-loader.component.html',
  styleUrl: './challengers-loader.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChallengersLoaderComponent {
  @Input() loaderColor = 'rgb(var(--text-primary))';
}
