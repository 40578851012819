import { Component, Input } from '@angular/core';

@Component({
  selector: 'shared-previous-club-icon',
  templateUrl: './previous-club-icon.component.html',
  styleUrls: ['./previous-club-icon.component.scss'],
})
export class PreviousClubIconComponent {
  @Input() hexFillColor1: string | undefined;
  @Input() hexFillColor2: string | undefined;
  @Input() iconSize = '5rem';
}
