import { inject, Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { Game, GameDataService } from '@kiq/client/game-data';
import { switchMap, tap } from 'rxjs';
import { GameDifficulty } from '@kiq/shared/enums';

export interface GamesOverviewStoreState {
  games: Array<Game> | null;
  loading: boolean;
  selectedDifficulty: GameDifficulty | null;
}

const initialState: GamesOverviewStoreState = {
  games: null,
  loading: false,
  selectedDifficulty: GameDifficulty.EASY,
};

@Injectable({ providedIn: 'root' })
export class GamesOverviewStoreStore extends ComponentStore<GamesOverviewStoreState> {
  gamesDataService = inject(GameDataService);

  constructor() {
    super(initialState);
  }

  readonly games$ = this.select((state) => state?.games);
  readonly loading$ = this.select((state) => state?.loading);
  readonly selectedDifficulty$ = this.select((state) => state?.selectedDifficulty);

  readonly viewModel$ = this.select((state) => {
    return {
      games: state.games,
      loading: state.loading,
    };
  });

  readonly setSelectedDifficulty = this.updater((state, difficulty: GameDifficulty) => {
    return {
      ...state,
      selectedDifficulty: difficulty,
    };
  });

  readonly loadAllGames = this.effect((params$) => {
    return params$.pipe(
      tap(() => {
        this.setState((state) => {
          return {
            ...state,
            loading: true,
          };
        });
      }),
      switchMap(() => {
        return this.gamesDataService.getAllGames$().pipe(
          tap((response: Array<Game>) => {
            this.setState((state) => {
              return {
                ...state,
                games: response,
                loading: false,
              };
            });
          }),
        );
      }),
    );
  });
}
