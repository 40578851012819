import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, createUrlTreeFromSnapshot, RouterStateSnapshot } from '@angular/router';
import { UserFacade } from '@kiq/client/data-access/user';
import { clientRoutes } from '@kiq/client/util/routing';
import { map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  private readonly userFacade = inject(UserFacade);
  private readonly isLoggedIn$ = this.userFacade.isLoggedIn$;

  private readonly CLIENT_ROUTES = clientRoutes;

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.isLoggedIn$.pipe(
      map((isLoggedIn) => {
        if (isLoggedIn) {
          return isLoggedIn;
        }

        // console.log(createUrlTreeFromSnapshot(route, [`/${this.CLIENT_ROUTES.GAMES.BASE}`]))
        // Send user to route if not authorized
        return createUrlTreeFromSnapshot(route, [`/${this.CLIENT_ROUTES.GAMES.BASE}`]);
      }),
    );
  }
}
