export enum GameTttMultiplayerEventType {
  stateChanged = 'stateChanged',
  gameStarted = 'gameStarted',
  gameEnded = 'gameEnded',
  clientLeft = 'clientLeft',
  clientJoined = 'clientJoined',
  clientDropped = 'clientDropped',
  error = 'error',
  actionSuccessful = 'actionSuccessful',
  actionFailed = 'actionFailed',
}
