import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ViewportService } from './service/viewport.service';
import { CustomTitleStrategyService } from './service/custom-title-strategy.service';

@NgModule({
  imports: [CommonModule],
  providers: [ViewportService, CustomTitleStrategyService],
})
export class ClientDataAccessConfigModule {}
