import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { gamesOverviewFeature } from './+state/games-overview.reducer';
import { GamesOverviewEffects } from './+state/games-overview.effects';
import { GamesOverviewFacade } from './+state/games-overview.facade';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(gamesOverviewFeature),
    EffectsModule.forFeature([GamesOverviewEffects]),
  ],
  providers: [GamesOverviewFacade],
})
export class ClientDataAccessGamesOverviewModule {}
