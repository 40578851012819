import { FootballLeague, PlayerMode } from '@kiq/shared/enums';
import { GameTttWinMode } from './game-ttt-win-mode';
import { GameMode } from './game-mode';

export type GameTttConfig = {
  gameMode: GameMode;
  playerMode: PlayerMode;
  gameStartDelaySeconds: number;
  league?: FootballLeague;
  multiplayerWinMode?: GameTttWinMode;
  allowPlayerHints?: boolean;
  maxGameDurationSeconds?: number;
  maxTurnDurationSeconds?: number;
  stealsPerPlayer?: number;
  allowPlayerSteals?: boolean;
};
