import {
  AfterViewInit,
  Component,
  DestroyRef,
  ElementRef,
  inject,
  OnInit,
  Renderer2,
  signal,
  ViewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedUiIconModule } from 'shared/ui/icon';
import { CountryFlagPipe } from '@kiq/shared/pipes';
import { FormsModule, NgModel, ReactiveFormsModule } from '@angular/forms';
import { LetDirective } from '@ngrx/component';
import { ClubSearchComponentStore } from 'client/data-access/club-search';
import { MODAL_REF } from '@kiq/shared/util/modal';
import { debounceTime, Observable } from 'rxjs';
import { FootballTeam } from '@kiq/shared/types';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { SharedUiButtonModule } from '@kiq/shared/ui/button';
import { SharedUiInputsModule } from '@kiq/shared/ui/inputs';
import { FlagFallbackDirective } from '@kiq/shared/directives';

@Component({
  selector: 'lib-club-search',
  standalone: true,
  imports: [
    CommonModule,
    SharedUiButtonModule,
    SharedUiIconModule,
    SharedUiInputsModule,
    CountryFlagPipe,
    FlagFallbackDirective,
    FormsModule,
    LetDirective,
    ReactiveFormsModule,
  ],
  templateUrl: './club-search.component.html',
  styleUrl: './club-search.component.scss',
  providers: [ClubSearchComponentStore],
})
export class ClubSearchComponent implements OnInit, AfterViewInit {
  readonly DEFAULT_LIST_ITEM_HEIGHT = 48;
  readonly SCROLL_CONTAINER_PADDING = 32;

  store = inject(ClubSearchComponentStore);
  modalRef = inject(MODAL_REF);
  renderer = inject(Renderer2);

  allClubs$: Observable<FootballTeam[] | null> = this.store.allClubs$;
  allClubsLoading$: Observable<boolean> = this.store.allClubsLoading$;
  filteredClubs$: Observable<FootballTeam[] | null> = this.store.filteredClubs$;

  keyEvent: KeyboardEvent | undefined;
  selectedIndex: number | undefined;

  shouldFocus = signal(true);
  searchLongerThanTwo = signal(false);

  @ViewChild('clubSearch', { static: false }) clubSearch: NgModel | undefined;
  @ViewChild('input', { static: true }) clubSearchInput: ElementRef | undefined;
  @ViewChild('listContainer') listContainer: ElementRef | undefined;

  destroyRef = inject(DestroyRef);

  ngOnInit(): void {
    this.store.getAllClubs();
    this.filteredClubs$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((filteredClubs) => {
      filteredClubs?.forEach((club) => {
        console.log(club.name);
      });
    });
  }

  ngAfterViewInit() {
    this.clubSearch?.valueChanges
      ?.pipe(debounceTime(300), takeUntilDestroyed(this.destroyRef))
      .subscribe((value: string) => {
        if ((value?.length ?? 0) > 2) {
          this.searchLongerThanTwo.set(true);
          this.store.getFilteredClubs(value);
        }
        if ((value.length ?? 0) === 0) {
          if (this.searchLongerThanTwo()) {
            this.searchLongerThanTwo.set(false);
            this.store.getFilteredClubs(value);
          }
        }
      });
  }

  selectClub(club: FootballTeam) {
    this.closeDialog(club);
  }

  closeDialog(club?: FootballTeam) {
    this.modalRef.close(club);
  }

  onKeyDown(event: KeyboardEvent, filteredClubs: FootballTeam[] | null): void {
    this.keyEvent = event;
    if (filteredClubs) {
      if ((event.key === 'ArrowUp' || event.key === 'ArrowDown' || event.key === 'Enter') && filteredClubs) {
        event.preventDefault(); // Prevent default arrow key behavior
      }
      switch (event.key) {
        case 'ArrowUp':
          this.selectedIndex = Math.max(0, (this.selectedIndex ?? filteredClubs.length) - 1);
          if (this.selectedIndex === filteredClubs.length - 1) {
            this.jumpToListItem();
          } else {
            this.scrollToListItem(true);
          }
          break;
        case 'ArrowDown':
          this.selectedIndex = Math.min(filteredClubs.length - 1, (this.selectedIndex ?? -1) + 1);
          this.scrollToListItem(false);
          break;
        case 'Enter': {
          if (this.selectedIndex) {
            this.selectClub(filteredClubs[this.selectedIndex]);
          } else {
            this.closeDialog();
          }
          break;
        }
      }
    }
  }

  jumpToListItem() {
    if (this.listContainer && this.selectedIndex !== undefined) {
      const itemTop = this.selectedIndex * this.DEFAULT_LIST_ITEM_HEIGHT;
      this.renderer.setProperty(this.listContainer.nativeElement, 'scrollTop', itemTop);
    }
  }

  scrollToListItem(scrollsUp: boolean): void {
    if (this.listContainer && this.selectedIndex !== undefined) {
      const itemTop = this.selectedIndex * this.DEFAULT_LIST_ITEM_HEIGHT;
      const listContainerHeight = this.listContainer.nativeElement.clientHeight - this.SCROLL_CONTAINER_PADDING;
      const containerScrollTop = this.listContainer.nativeElement.scrollTop;
      if (!scrollsUp && itemTop >= listContainerHeight) {
        this.listContainer.nativeElement.scrollBy({ top: this.DEFAULT_LIST_ITEM_HEIGHT, behavior: 'smooth' });
      }
      if (scrollsUp && containerScrollTop > itemTop) {
        this.listContainer.nativeElement.scrollBy({ top: -this.DEFAULT_LIST_ITEM_HEIGHT, behavior: 'smooth' });
      }
    }
  }
}
