import { inject, Injectable, Injector } from '@angular/core';
import { ConnectedPosition, Overlay } from '@angular/cdk/overlay';
import { BehaviorSubject } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';
import { ComponentPortal } from '@angular/cdk/portal';
import { SnackbarRef } from '../model/snackbar-ref';
import { SnackbarData } from '../type/snackbar-data';
import { SNACKBAR_DATA, SNACKBAR_REF } from '../injection-token/snackbar-injection-tokens';
import { SnackbarComponent } from 'shared/ui/snackbar';

@Injectable({
  providedIn: 'root',
})
export class SnackbarService {
  private readonly overlay = inject(Overlay);
  private readonly injector = inject(Injector);
  private activeSnackbar$ = new BehaviorSubject<SnackbarRef[]>([]);

  private readonly MIN_PADDING_TO_TOP = 8;
  private readonly FIRST_PADDING_TO_TOP = 72;
  private readonly DEFAULT_CONNECTED_POSITIONS: ConnectedPosition[] = [
    {
      overlayY: 'top',
      overlayX: 'center',
      originY: 'bottom',
      originX: 'center',
      offsetY: this.MIN_PADDING_TO_TOP,
    },
  ];

  show(snackbarData: SnackbarData) {
    const id = uuidv4();

    // Calculate the position of the snackbar based on the number of active snackbars
    const positionStrategy = this.getPositionStrategy(id);

    const overlayRef = this.overlay.create({ positionStrategy });

    const snackbarRef = new SnackbarRef(id, overlayRef, this.closeSnackbar.bind(this, id));

    const injector = this.createInjector(snackbarData, snackbarRef);

    const snackbarPortal = new ComponentPortal(SnackbarComponent, null, injector);

    overlayRef.attach(snackbarPortal);

    this.activeSnackbar$.next([...this.activeSnackbar$.getValue(), snackbarRef]);

    return snackbarRef;
  }

  private createInjector(data: SnackbarData, snackbarRef: SnackbarRef) {
    return Injector.create({
      parent: this.injector,
      providers: [
        {
          provide: SNACKBAR_DATA,
          useValue: data,
        },
        {
          provide: SNACKBAR_REF,
          useValue: snackbarRef,
        },
      ],
    });
  }

  private closeSnackbar(id: string) {
    let activeSnackbar = this.activeSnackbar$.getValue();
    const snackbarRef = activeSnackbar.find((snackbar) => snackbar.id === id);

    if (snackbarRef) {
      snackbarRef.overlayRef.dispose();
      activeSnackbar = activeSnackbar.filter((snackbar) => snackbar.id !== id);
      this.activeSnackbar$.next(activeSnackbar);
    }

    this.recalculateSnackbarPositions(activeSnackbar);
  }

  private recalculateSnackbarPositions(activeSnackbar: SnackbarRef[]) {
    activeSnackbar.forEach((snackbar) => {
      const positionStrategy = this.getPositionStrategy(snackbar.id);
      snackbar.overlayRef.updatePositionStrategy(positionStrategy);
    });
  }

  private getPositionStrategy(id: string) {
    const activeSnackbar = this.activeSnackbar$.getValue();
    const currentSnackbarIndex = activeSnackbar.findIndex((snackbarRef) => snackbarRef.id === id);

    // If there is only one snackbar or this is the first, position it at the bottom
    if (currentSnackbarIndex === 0 || activeSnackbar.length === 0) {
      return this.overlay
        .position()
        .global()
        .top(this.FIRST_PADDING_TO_TOP + 'px')
        .centerHorizontally();
    }

    let overlayElement: HTMLElement;

    // If the snackbar is new, position it above the last snackbar
    if (currentSnackbarIndex === -1) {
      overlayElement = activeSnackbar[activeSnackbar.length - 1].overlayRef.overlayElement;
    } else {
      overlayElement = activeSnackbar[currentSnackbarIndex - 1].overlayRef.overlayElement;
    }

    return this.overlay.position().flexibleConnectedTo(overlayElement).withPositions(this.DEFAULT_CONNECTED_POSITIONS);
  }
}
