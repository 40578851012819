export enum GamePlayerGuessingHintType {
  age = 'age',
  inCurrentTeamSince = 'inCurrentTeamSince',
  nationality = 'nationality',
  height = 'height',
  jerseyNumber = 'jerseyNumber',
  position = 'position',
  previousTeam = 'previousTeam',
  preferredFoot = 'preferredFoot',
  currentTeam = 'currentTeam',
}
