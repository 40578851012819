import { OverlayRef } from '@angular/cdk/overlay';

export class SnackbarRef {
  constructor(
    readonly id: string,
    readonly overlayRef: OverlayRef,
    private closeFn: (id: string) => void,
  ) {}

  close() {
    this.closeFn(this.id);
  }
}
