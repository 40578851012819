export enum FootballPlayerPosition {
  Goalkeeper = 'goalkeeper',
  Defender = 'defender',
  Midfielder = 'midfielder',
  Attacker = 'attacker',
  CenterBack = 'centerBack',
  DefensiveMidfied = 'defensiveMidfied',
  AttackingMidfied = 'attackingMidfied',
  CenterForward = 'centerForward',
  LeftWing = 'leftWing',
  CentralMidfied = 'centralMidfied',
  RightBack = 'rightBack',
  LeftBack = 'leftBack',
  RightWing = 'rightWing',
  LeftMidfield = 'leftMidfield',
  RightMidfield = 'rightMidfield',
  SecondaryStriker = 'secondaryStriker',
}
