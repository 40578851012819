import { inject, Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { UserActions } from './user.actions';
import { catchError, concatMap, map, of, switchMap, tap } from 'rxjs';
import { AuthService } from '../service/auth.service';
import { Auth, UserCredential } from '@angular/fire/auth';
import { CreateUserDto } from '@kiq/shared/interfaces';
import { Router } from '@angular/router';
import { UserBackendService, UserFacade } from '@kiq/client/data-access/user';
import { clientRoutes } from '@kiq/client/util/routing';
import { Store } from '@ngrx/store';
import { selectUser } from './user.reducer';
import { SnackbarService, SnackbarType } from 'shared/util/snackbar';

@Injectable()
export class UserEffects {
  private action = inject(Actions);
  private authService = inject(AuthService);
  private userService = inject(UserBackendService);
  private router = inject(Router);
  public snackbar = inject(SnackbarService);
  public store = inject(Store);
  public userFacade = inject(UserFacade);

  private readonly MAX_FILE_SIZE = 2000000;

  createNewFirebaseUser$ = createEffect(() => {
    return this.action.pipe(
      ofType(UserActions.createNewFirebaseUserStart),
      concatMap(({ email, password, username, favouriteClub }) => {
        return this.authService.signUpWithEmail$(email, password).pipe(
          switchMap((userCredential) => {
            const user = JSON.parse(JSON.stringify(userCredential));
            //TODO: use structural clone? Not working like this
            // const user = structuredClone(userCredential);
            return of(UserActions.createNewFirebaseUserSuccess({ user, username, favouriteClub }));
          }),
          catchError((error: string) => {
            this.handleError('Fehler beim Anlegen des Benutzers in Firebase');
            return of(UserActions.createNewFirebaseUserFailure({ error }));
          }),
        );
      }),
    );
  });

  createNewUserWithGoogle$ = createEffect(() => {
    return this.action.pipe(
      ofType(UserActions.createNewUserWithGoogleStart),
      concatMap(() => {
        return this.authService.signUpWithGoogle$().pipe(
          switchMap((userCredential: UserCredential) => {
            const user: UserCredential = JSON.parse(JSON.stringify(userCredential));
            return of(UserActions.createNewFirebaseUserSuccess({ user, username: '' }));
          }),
          catchError((error: string) => {
            this.handleError('Fehler beim Anlegen des Benutzers mit Google');
            return of(UserActions.createNewFirebaseUserFailure({ error }));
          }),
        );
      }),
    );
  });

  createNewUserWithApple$ = createEffect(() => {
    return this.action.pipe(
      ofType(UserActions.createNewUserWithAppleStart),
      concatMap(() => {
        return this.authService.signUpWithApple$().pipe(
          map((userCredential: UserCredential) => {
            return UserActions.createNewFirebaseUserSuccess({
              user: userCredential,
              username: userCredential.user.displayName ?? '',
            });
          }),
          catchError((error: string) => {
            this.handleError('Fehler beim Anlegen des Benutzers mit Apple');
            return of(UserActions.createNewFirebaseUserFailure({ error }));
          }),
        );
      }),
    );
  });

  onFireBaseUserCreateSuccess$ = createEffect(() => {
    return this.action.pipe(
      ofType(UserActions.createNewFirebaseUserSuccess),
      map(({ user, username, favouriteClub }) => {
        if (!user || !user.user || !user.user.email) {
          return UserActions.createNewBackendUserFailure({ error: 'no user data available' });
        }

        const userDto: CreateUserDto = {
          email: user.user.email,
          username: username,
          favoriteFootballTeam: favouriteClub,
        };

        return UserActions.createNewBackendUser({ user: userDto });
      }),
    );
  });

  loginUserWithGoogle$ = createEffect(() => {
    return this.action.pipe(
      ofType(UserActions.loginUserWithGoogleStart),
      concatMap(() => {
        return this.authService.signUpWithGoogle$().pipe(
          map((userCredential: UserCredential) => {
            const userCredentialsCopy: UserCredential = JSON.parse(JSON.stringify(userCredential));
            return UserActions.loginUserWithPopupSuccess({ userCredential: userCredentialsCopy });
          }),
          catchError((error: string) => {
            this.handleError('Fehler beim Einloggen des Benutzers mit Google');
            return of(UserActions.loginUserWithPopupFail({ error }));
          }),
        );
      }),
    );
  });

  loginUserWithApple$ = createEffect(() => {
    return this.action.pipe(
      ofType(UserActions.loginUserWithAppleStart),
      concatMap(() => {
        return this.authService.signUpWithApple$().pipe(
          map((userCredential: UserCredential) => {
            return UserActions.loginUserWithPopupSuccess({ userCredential });
          }),
          tap(() => {
            this.router.navigate([`/${clientRoutes.LANDING}/${clientRoutes.PROFILE.BASE}`]);
          }),
          catchError((error: string) => {
            this.handleError('Fehler beim Einloggen des Benutzers mit Apple');
            return of(UserActions.loginUserWithPopupFail({ error }));
          }),
        );
      }),
    );
  });

  onLoginWithPopupSuccess$ = createEffect(() => {
    return this.action.pipe(
      ofType(UserActions.loginUserWithPopupSuccess),
      concatMap((userCredential) => {
        return of(UserActions.getBackendUserAfterPopupLogin(userCredential));
      }),
    );
  });

  getBackendUserAfterPopUpLogin$ = createEffect(() => {
    return this.action.pipe(
      ofType(UserActions.getBackendUserAfterPopupLogin),
      concatMap((userCredential) => {
        return this.userService.getUser$().pipe(
          map((user) => {
            return UserActions.getBackendUserSuccess({ user });
          }),
          catchError((error: string) => {
            return of(UserActions.getBackendUserFailureAfterPopupLogin(userCredential));
          }),
        );
      }),
    );
  });

  getBackendUserFailedAfterPopupLogin$ = createEffect(() => {
    return this.action.pipe(
      ofType(UserActions.getBackendUserFailureAfterPopupLogin),
      concatMap((userCredential) => {
        if (userCredential.userCredential.user.email) {
          const userDto: CreateUserDto = {
            email: userCredential.userCredential.user.email,
            username: '',
          };
          return of(UserActions.createNewBackendUser({ user: userDto }));
        }
        return of(UserActions.getBackendUserFailureAfterPopupLoginFail({ error: 'backend user not created' }));
      }),
    );
  });

  createNewBackendUser$ = createEffect(() => {
    return this.action.pipe(
      ofType(UserActions.createNewBackendUser),
      concatMap(({ user }) => {
        return this.userService.createUser$(user).pipe(
          map((user) => {
            return UserActions.createNewBackendUserSuccess({ user });
          }),
          tap(() => {
            this.router.navigate([`/${clientRoutes.PROFILE.BASE}/${clientRoutes.PROFILE.PROFILE_SETTINGS}`]);
          }),
          catchError((error: string) => {
            this.handleError('Fehler beim Anlegen des Benutzers');
            return of(UserActions.createNewBackendUserFailure({ error }));
          }),
        );
      }),
    );
  });

  loginFirebaseUser$ = createEffect(() => {
    return this.action.pipe(
      ofType(UserActions.loginStart),
      concatMap(({ email, password }) => {
        return this.authService.loginWithEmail$(email, password).pipe(
          map((userCredential) => {
            return UserActions.loginSuccess();
          }),
          catchError((error: string) => {
            this.handleError('Fehler beim Login des Benutzers');
            return of(UserActions.loginFailure({ error }));
          }),
        );
      }),
    );
  });

  onLoginSuccess$ = createEffect(() => {
    return this.action.pipe(
      ofType(UserActions.loginSuccess),
      concatMap(() => {
        return of(UserActions.getBackendUser());
      }),
    );
  });

  getBackendUser$ = createEffect(() => {
    return this.action.pipe(
      ofType(UserActions.getBackendUser),
      concatMap(() => {
        return this.userService.getUser$().pipe(
          map((user) => {
            return UserActions.getBackendUserSuccess({ user });
          }),
          catchError((error: string) => {
            this.handleError('Fehler beim Laden der Benutzerdaten');
            return of(UserActions.getBackendUserFailure({ error }));
          }),
        );
      }),
    );
  });

  onGetBackendUserSuccess$ = createEffect(() => {
    return this.action.pipe(
      ofType(UserActions.getBackendUserSuccess),
      concatMap(() => {
        return of(UserActions.setProfileNotification({profileNotification: false}))
      }),
    );
  });

  updateProfileImage$ = createEffect(() => {
    return this.action.pipe(
      ofType(UserActions.uploadProfileImageStart),
      concatMap(({ file }) => {
        if (file.size > this.MAX_FILE_SIZE) {
          const errorMesage = 'Die Datei ist zu groß, bitte wähle eine Datei mit maximal 2MB aus.';

          this.snackbar.show({ message: errorMesage, type: SnackbarType.ERROR });

          return of(
            UserActions.uploadProfileImageFail({
              error: 'Die Datei ist zu groß, bitte wähle eine Datei mit maximal 2MB aus.',
            }),
          );
        }

        const formData = new FormData();
        const filename = file.name;
        formData.append('profileImage', file, filename);

        return this.userService.updateImage$(formData).pipe(
          map((imageUrl: string) => {
            this.handleSuccess('Ihr Profilbild wurde erfolgreich aktualisiert!');
            return UserActions.uploadProfileImageSuccess({ imageUrl });
          }),
          catchError((error) => {
            this.handleError('Fehler beim aktualisieren des Profilbildes');
            return of(UserActions.uploadProfileImageFail({ error }));
          }),
        );
      }),
    );
  });

  onUploadImageSuccess$ = createEffect(() => {
    return this.action.pipe(
      ofType(UserActions.uploadProfileImageSuccess),
      concatMap(() => {
        return of(UserActions.getBackendUser());
      }),
    );
  });

  logoutFirebaseUser$ = createEffect(() => {
    return this.action.pipe(
      ofType(UserActions.logoutFirebaseUserStart),
      concatMap(() => {
        return this.authService.logoutFirebaseUser$().pipe(
          map(() => {
            this.handleSuccess('Erfolgreich ausgeloggt');
            return UserActions.logoutFirebaseUserSuccess();
          }),
          tap(() => {
            this.router.navigate([`/${clientRoutes.PROFILE.BASE}`]);
          }),
          catchError((error: string) => {
            this.handleError('Fehler beim Ausloggen des Benutzers');
            return of(UserActions.logoutFirebaseUserFail({ error }));
          }),
        );
      }),
    );
  });

  resetPassword = createEffect(() => {
    return this.action.pipe(
      ofType(UserActions.resetPasswordStart),
      concatMap(({ email }) => {
        return this.userService.resetPassword$(email).pipe(
          map(() => {
            this.handleSuccess('Passwort zurückgesetzt. Überprüfe deine Mails.');
            return UserActions.resetPasswordSuccess();
          }),
          catchError((error) => {
            this.handleError('Fehler beim Zurücksetzen des Passwortes. Bitte versuchen Sie es später erneut.', error);
            return of(UserActions.resetPasswordFail());
          }),
        );
      }),
    );
  });

  verifyAndConfirmPasswordAfterReset = createEffect(() => {
    return this.action.pipe(
      ofType(UserActions.verifyAndConfirmPasswordResetStart),
      concatMap(({ oobCode, password }) => {
        return this.authService.verifyAndConfirmPassword$(oobCode, password).pipe(
          map(() => {
            this.handleSuccess('Änderung erfolgreich gespeichert.');
            this.router.navigate(['']);
            return UserActions.verifyAndConfirmPasswordResetSuccess();
          }),
          catchError((error) => {
            this.handleError('Fehler beim Verifizieren des Passwort Resets', error);
            return of(UserActions.verifyAndConfirmPasswordResetFail());
          }),
        );
      }),
    );
  });

  updateUsername = createEffect(() => {
    return this.action.pipe(
      ofType(UserActions.updateUsernameStart),
      concatLatestFrom(() => [this.store.select(selectUser)]),
      concatMap(([{ username }, currentUser]) => {
        if (!currentUser) {
          this.handleError('Kein User zum updaten vorhanden.');
          return of(UserActions.updateUsernameFail({ error: 'Kein User zum updaten vorhanden.' }));
        }

        const updatedUser: CreateUserDto = {
          ...currentUser,
          username: username,
        };

        return this.userService.updateUser$(updatedUser).pipe(
          map((user) => {
            this.handleSuccess('Benutzername erfolgreich geändert.');
            return UserActions.updateUsernameSuccess({ user });
          }),
          catchError((error) => {
            if (error.error.errorCode === 30003) {
              this.handleError('Benutzername bereits vergeben.');
            } else {
              this.handleError('Fehler beim Aktualisieren des Benutzernamens', error);
            }
            return of(UserActions.updateUsernameFail({ error }));
          }),
        );
      }),
    );
  });

  updateFavouriteClub = createEffect(() => {
    return this.action.pipe(
      ofType(UserActions.updateFavouriteClubStart),
      concatLatestFrom(() => [this.store.select(selectUser)]),
      concatMap(([{ favClubId }, currentUser]) => {
        if (!currentUser) {
          this.handleError('Kein User zum updaten vorhanden.');
          return of(UserActions.updateUsernameFail({ error: 'Kein User zum updaten vorhanden.' }));
        }

        const updatedUser: CreateUserDto = {
          ...currentUser,
          favoriteFootballTeam: favClubId,
        };

        return this.userService.updateUser$(updatedUser).pipe(
          map((user) => {
            this.handleSuccess('Lieblingsverein erfolgreich geändert.');
            return UserActions.updateUsernameSuccess({ user });
          }),
          catchError((error) => {
            if (error.error.errorCode === 30003) {
              this.handleError('Benutzername bereits vergeben.');
            } else {
              this.handleError('Fehler beim Aktualisieren des Lieblingsvereins', error);
            }
            return of(UserActions.updateUsernameFail({ error }));
          }),
        );
      }),
    );
  });

  updateBackendUser = createEffect(() => {
    return this.action.pipe(
      ofType(UserActions.updateBackendUserStart),
      concatLatestFrom(() => [this.store.select(selectUser)]),
      concatMap(([{ user }, currentUser]) => {
        if (!currentUser) {
          this.handleError('Keine Benutzerdaten zum updaten vorhanden.');
          return of(UserActions.updateBackendUserFail({ error: 'Keine Benutzerdaten zum updaten vorhanden.' }));
        }

        const updatedUser: CreateUserDto = {
          ...currentUser,
          ...user,
        };
        return this.userService.updateUser$(updatedUser).pipe(
          map((user) => {
            if (user.email !== currentUser.email) {
              this.handleSuccess(
                'E-Mail-Adresse erfolgreich aktualisiert! Bitte verifiziere deine neue E-Mail-Adresse und logge dich anschließend neu ein.',
              );
              this.router.navigate(['']);
            } else {
              this.handleSuccess('Profil erfolgreich aktualisiert.');
            }
            return UserActions.updateBackendUserSuccess({ user });
          }),
          catchError((error) => {
            this.handleError('Fehler beim aktualisieren der Nutzerdaten', error);
            return of(UserActions.updateBackendUserFail({ error }));
          }),
        );
      }),
    );
  });

  updatePassword = createEffect(() => {
    return this.action.pipe(
      ofType(UserActions.updatePasswordStart),
      concatMap(({ oldPassword, newPassword }) =>
        this.authService.reauthenticateWithCredential$(oldPassword).pipe(
          concatMap(() =>
            this.authService.updatePassword$(newPassword).pipe(
              map(() => {
                this.handleSuccess('Änderung erfolgreich gespeichert');
                return UserActions.updatePasswordSuccess();
              }),
              catchError((error) => {
                this.handleError('Fehler beim aktualisieren des Passworts');
                return of(UserActions.updatePasswordFail({ error }));
              }),
            ),
          ),
          catchError((error) => {
            this.handleError('Fehler beim Aktualisieren des Passworts. Bitte überprüfe deine Eingabe.');
            return of(UserActions.updatePasswordFail({ error }));
          }),
        ),
      ),
    );
  });

  deleteAccount = createEffect(() => {
    return this.action.pipe(
      ofType(UserActions.deleteAccountStart),
      concatMap(() => {
        return this.userService.deleteUser$().pipe(
          map(() => {
            this.handleSuccess('Account erfolgreich gelöscht.');
            this.router.navigate(['']);
            return UserActions.deleteAccountSuccess();
          }),
          catchError((error) => {
            this.handleError('Fehler beim Löschen des Accounts.', error);
            return of(UserActions.deleteAccountFail({ error }));
          }),
        );
      }),
    );
  });

  deleteProfileImage = createEffect(() => {
    return this.action.pipe(
      ofType(UserActions.deleteProfileImageStart),
      concatMap(() => {
        return this.userService.deleteImage$().pipe(
          map(() => {
            this.handleSuccess('Profilfoto erfolgreich gelöscht.');
            this.router.navigate([`/${clientRoutes.LANDING}/${clientRoutes.PROFILE.BASE}`]);
            return UserActions.deleteProfileImageSuccess();
          }),
          catchError((error) => {
            this.handleError('Fehler beim Löschen des Profilfotos.', error);
            return of(UserActions.deleteProfileImageFail({ error }));
          }),
        );
      }),
    );
  });

  onDeleteImageSuccess$ = createEffect(() => {
    return this.action.pipe(
      ofType(UserActions.deleteProfileImageSuccess),
      concatMap(() => {
        return of(UserActions.getBackendUser());
      }),
    );
  });

  onSetProfileNotification$ = createEffect(() => {
    return this.action.pipe(
      ofType(UserActions.setProfileNotification),
      concatMap((profileNot) => {
        localStorage.setItem('alreadyVisitedKIKKZ', profileNot ? 'true' : 'false');
        return of(UserActions.setProfileNotificationSuccess());
      }),
    );
  });

  onGetProfileNotification$ = createEffect(() => {
    return this.action.pipe(
      ofType(UserActions.getProfileNotification),
      concatMap(() => {
        const alreadyVisitedSite = localStorage.getItem('alreadyVisitedKIKKZ');
        let profileNotification = true;
        if (alreadyVisitedSite) {
          if (alreadyVisitedSite === 'true') {
            profileNotification = false
          }
        }
        return of(UserActions.getProfileNotificationSuccess({profileNotification}));
      }),
    );
  });

  sendEmailVerification = createEffect(() => {
    return this.action.pipe(
      ofType(UserActions.sendEmailVerification),
      concatMap(() => {
        return this.userService.sendUserEmailVerificationMessage$().pipe(
          map(() => {
            this.handleSuccess('Bestätigungs-E-Mail erfolgreich versendet.');
            return UserActions.sendEmailVerificationSuccess();
          }),
          catchError((error) => {
            this.handleError('Fehler beim Senden der Bestätigungs-E-Mail. Versuche es später erneut.', error);
            return of(UserActions.sendEmailVerificationFail(error));
          }),
        );
      }),
    );
  });

  onSetNewUserInvitationLink$ = createEffect(() => {
    return this.action.pipe(
      ofType(UserActions.setNewUserInvitationLink),
      concatMap(() => {
        return this.userService.setNewUserInvitationLink$().pipe(
          map(user => {
            return UserActions.setNewUserInvitationLinkSuccess({user});
          }),
          catchError((error) => {
            this.handleError(('Fehler beim aktualieren des Einladungslinks'))
            return of(UserActions.setNewUserInvitationLinkFail({error}));
          })
        );
      }),
    );
  });

  private handleError(message: string, error?: unknown): void {
    this.snackbar.show({ message, type: SnackbarType.ERROR });
    if (error) {
      console.error(error);
    }
  }

  private handleSuccess(message: string): void {
    this.snackbar.show({ message, type: SnackbarType.SUCCESS });
  }
}
