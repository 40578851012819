import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconComponent } from './icon/icon.component';
import { ClubIconComponent } from './club-icon/club-icon.component';
import { PreviousClubIconComponent } from './previous-club-icon/previous-club-icon.component';

@NgModule({
  imports: [CommonModule],
  declarations: [IconComponent, ClubIconComponent, PreviousClubIconComponent],
  exports: [IconComponent, ClubIconComponent, PreviousClubIconComponent],
})
export class SharedUiIconModule {}
