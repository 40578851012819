<form [formGroup]="pwResetForm">
  <div class="background-stripes with-dark-gradient bg-cover bg-no-repeat h-full" id="password-reset">
    <div class="bg-primary border-b border-surfaceSecondary">
      <div class="p-4 h-12 max-w-default flex items-center">
        <button class="h-6 w-6" (click)="closeDialog()" type="button">
          <shared-icon class="icon-wrapper" name="close-round"></shared-icon>
        </button>
        <p class="text-textPrimary mx-auto brand text-base">Passwort vergessen</p>
      </div>
    </div>

    <div class="p-4 bg-no-repeat bg-cover bg-center">
      <div class="h-full flex flex-col gap-6 container max-w-default text-white py-6">
        <div class="flex flex-col gap-6 h-full">
          <p>
            Nenn uns die E-Mail Adresse, mit der du dich bei KIKKZ registriert hast. Wir schicken dir anschließend einen
            Link, über den du dir ein neues Passwort erstellen kannst.
          </p>

          <kiq-text-input
            class="w-full"
            [formControlName]="'email'"
            [placeholder]="'Aktuelle E-Mail-Adresse deines KIKKZ-Kontos'"></kiq-text-input>
        </div>

        <div class="w-full h-16">
          <client-button [buttonType]="'secondary'" [disabled]="pwResetForm.invalid" (click)="resetPassword()"
          ><p class="large"> Passwort zurücksetzen</p>
          </client-button>
        </div>
      </div>
    </div>
  </div>
</form>
