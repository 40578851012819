import { animate, style, transition, trigger } from '@angular/animations';

export const sideSlideOut = trigger('slideSideChild', [
  transition(':leave', [
    style({ opacity: 1, transform: 'translateX(0)' }), //apply default styles before animation starts
    animate('300ms ease-in-out', style({ transform: 'translateX(-100%)' })),
  ]),
]);

export const slideUpChild = trigger('slideUpChild', [
  transition(':enter', [
    style({ transform: 'translateY(100%)' }), //apply default styles before animation starts
    animate('300ms ease-in-out', style({ transform: 'translateY(0)' })),
  ]),
  transition(':leave', [
    style({ opacity: 1, transform: 'translateY(0)' }), //apply default styles before animation starts
    animate('300ms ease-in-out', style({ transform: 'translateY(100%)' })),
  ]),
]);

export const slideInAndOut = trigger('slideInAndOut', [
  transition(':enter', [
    style({ transform: 'translateY(100%)' }), //apply default styles before animation starts
    animate('300ms ease-in-out', style({ transform: 'translateY(0)' })),
  ]),
  transition(':leave', [
    style({ opacity: 1, transform: 'translateY(0)' }), //apply default styles before animation starts
    animate('300ms ease-in-out', style({ transform: 'translateY(100%)' })),
  ]),
]);

export const slideInChild = trigger('slideInChild', [
  transition(':enter', [
    style({ transform: 'translateY(-25%)', opacity: 0 }), //apply default styles before animation starts
    animate('500ms 300ms ease-in-out', style({ transform: 'translateY(0)', opacity: 1 })),
  ]),
]);

export const slideInFromLeft = trigger('slideInFromLeft', [
  transition(':enter', [
    style({ transform: 'translateX(-150%)' }), //apply default styles before animation starts
    animate('500ms 300ms ease-in-out', style({ transform: 'translateX(0)' })),
  ]),
]);

export const slideInFromRight = trigger('slideInFromRight', [
  transition(':enter', [
    style({ transform: 'translateX(150%)' }), //apply default styles before animation starts
    animate('500ms 300ms ease-in-out', style({ transform: 'translateX(0)' })),
  ]),
]);

export const slideInAnswers = trigger('slideInAnswers', [
  transition(':enter', [
    style({ transform: 'translateY(-200%)' }), //apply default styles before animation starts
    animate('500ms 300ms', style({ transform: 'translateY(0)' })),
  ]),
]);

export const slideInScoreboard = trigger('slideInScoreboard', [
  transition(':enter', [
    style({ transform: 'translateY(-150%)' }), //apply default styles before animation starts
    animate('300ms', style({ transform: 'translateY(0)' })),
  ]),
]);

export const slideInRankings = trigger('slideInRankings', [
  transition(':enter', [
    style({ transform: 'translateY(-20px)', opacity: 0 }), //apply default styles before animation starts
    animate('500ms', style({ transform: 'translateY(0)', opacity: 1 })),
  ]),
]);

export const fadeInInitialGameScreen = trigger('fadeInInitialGameScreen', [
  transition(':enter', [
    style({ opacity: 0 }), //apply default styles before animation starts
    animate('300ms', style({ opacity: 1 })),
  ]),
]);

export const fadeOutInitialGameScreen = trigger('fadeOutInitialGameScreen', [
  transition(':leave', [
    style({ opacity: 1 }), //apply default styles before animation starts
    animate('300ms', style({ opacity: 0 })),
  ]),
]);

export const fadeInGame = trigger('fadeInGame', [
  transition(':enter', [
    style({ opacity: 0 }), //apply default styles before animation starts
    animate('300ms', style({ opacity: 1 })),
  ]),
]);

export const fadeOutGameBoard = trigger('fadeOutGameBoard', [
  transition(':leave', [
    style({ opacity: 1 }), //apply default styles before animation starts
    animate('300ms ease-in-out', style({ opacity: 0 })),
  ]),
]);

export const slideInFromBottom = trigger('slideInFromBottom', [
  transition(':enter', [
    style({ transform: 'translateY(25%)', opacity: 0 }), //apply default styles before animation starts
    animate('500ms 1000ms ease-in-out', style({ transform: 'translateY(0)', opacity: 1 })),
  ]),
]);
