import { Component, Input } from '@angular/core';

export type ButtonType = 'primary' | 'secondary' | 'tertiary' | 'quaternary' | 'comingSoon' | 'playNow';
export type ButtonSize = 'small' | 'medium' | 'big' | 'small-wide';

@Component({
  selector: 'client-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent {
  @Input() buttonType: ButtonType | undefined;
  @Input() loading: boolean | undefined;
  @Input() disabled: boolean | undefined;
  @Input() size: ButtonSize = 'big';
}
