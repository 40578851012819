export enum ChangeSettings {
  username= 'username',
  email = 'email',
  password = 'password',
  firstname = 'firstname',
  lastname = 'lastname',
  userFootballRole = 'userFootballRole',
  userFootballPlayerPosition = 'userFootballPlayerPosition',
  yearOfBirth = 'yearOfBirth'
}
