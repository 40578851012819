<div class="background-stripes with-dark-gradient bg-no-repeat bg-cover bg-center custom-height" id="verify">
  <div class="container relative flex flex-col custom-height max-w-default text-textPrimary">
    <ng-container *ngIf="!authError; else error">
      <ng-container *ngIf="mode === 'verifyEmail' || mode === 'verifyAndChangeEmail'">
        <div class="flex flex-col h-full gap-12 items-center justify-center">
          <div class="flex flex-col items-center justify-center gap-3">
            <img class="w-20 h-20" [src]="MAIL_ICON">
            <div class="text-surfaceBrand text-2xl font-medium text-center">E-Mail-Adresse bestätigt</div>
            <div class="text-textSecondary text-base font-medium text-center">
              Du kannst jetzt Punkte sammeln und dich mit der Community messen
            </div>
          </div>
          <div class="w-full h-14">
            <client-button [buttonType]="'secondary'" [routerLink]="[clientRoutes.HOME]">Zur Startseite</client-button>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="mode === 'resetPassword'">
        <form class="w-full h-full" [formGroup]="resetPwForm">
          <div class="flex flex-col justify-between h-full py-6">
            <div>
              <div class="flex flex-col justify-center gap-6 w-full">
                <p
                >Dein Passwort wurde erfolgreich zurückgesetzt. Bitte denke dir nun ein neues Passwort aus und
                  bestätige dieses anschließend.</p
                >
                <kiq-text-input
                  class="w-full"
                  [formControlName]="'newPassword'"
                  [inputType]="'password'"
                  placeholder="neues Password"></kiq-text-input>
                <kiq-text-input
                  class="w-full"
                  [formControlName]="'confirmPassword'"
                  [inputType]="'password'"
                  placeholder="neues Password bestätigen"></kiq-text-input>
              </div>
              <label *ngIf="resetPwForm?.hasError('passwordMismatch') && (resetPwForm?.dirty || resetPwForm?.touched)">
                <span class="label-text-alt text-red-600">{{
                    "Die Passwörter stimmen nicht überein. Bitte überprüfe deine Eingabe."
                  }}</span>
              </label>
            </div>

            <div class="w-full h-14">
              <client-button [buttonType]="'secondary'" [disabled]="resetPwForm.invalid" (click)="submitNewPassword()">
                Speichern
              </client-button>
            </div>
          </div>
        </form>
      </ng-container>
    </ng-container>

    <ng-template #error>
      <p>{{ authError }}</p>
    </ng-template>
  </div>
</div>
