import { TemplateRef } from '@angular/core';
import { ComponentType, OverlayConfig } from '@angular/cdk/overlay';

export type ModalConfig = {
  templateRef?: TemplateRef<unknown>;

  // Template to be rendered in the header of the modal. If this is set, the headline will be ignored.
  header?: TemplateRef<unknown>;
  headline?: string;
  withoutHeader?: boolean;
  useFullscreenModal: boolean;
  notFullscreen?: boolean;
  overlayConfig?: OverlayConfig;
  enableBackdropClick?: boolean;
  disableClose?: boolean;

  // Component to be rendered inside the modal. If this is set, the templateRef will be ignored.
  component?: ComponentType<unknown>;

  // Data which should be passed to the component via injection token.
  data?: unknown;

  // Data to be passed to the component. Keep in mind that this data is not reactive and will not be updated if the data changes.
  // Make sure that the property names match the input names of the component.
  inputs?: Record<string, unknown>;
};
