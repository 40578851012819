import { PlayerNumber } from '@kiq/shared/enums';

export type GridContent = {
  x: number;
  y: number;
  footballPlayerId: string | null;
  footballPlayerName: string | null;
  resolvedBy: PlayerNumber | null;
  pastResolvedPlayerIds: Array<string> | null;
};
