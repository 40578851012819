import { ChangeDetectionStrategy, Component, DestroyRef, inject, Injector, OnInit, signal } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { UserFacade } from '@kiq/client/data-access/user';
import { PasswordResetDialogComponent } from '../password-reset-dialog/password-reset-dialog.component';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { InGameModalService, MODAL_CONFIG, MODAL_REF, ModalService } from '@kiq/shared/util/modal';
import { InGameModalConfig, ModalConfig } from '@kiq/shared/types';
import { Actions, ofType } from '@ngrx/effects';
import { ConfigFacade, CustomTitleStrategyService } from '@kiq/client/data-access/config';
import { RegisterComponent } from '../register/register.component';

@Component({
  selector: 'kiq-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginComponent implements OnInit {
  private readonly fb = inject(FormBuilder);
  private readonly userFacade = inject(UserFacade);
  private readonly destroyRef = inject(DestroyRef);
  private readonly modalRef = inject(MODAL_REF);
  private readonly modalConfig$ = inject(MODAL_CONFIG);
  private readonly inGameModalService: InGameModalService | null;
  private readonly modalService = inject(ModalService);
  private readonly actions = inject(Actions);
  private readonly titleService = inject(CustomTitleStrategyService);
  configFacade = inject(ConfigFacade);
  private readonly isDesktop = this.configFacade.isDesktop;
  protected readonly GOOGLE_LOGO = 'assets/icons/google-logo.svg';
  loading$ = this.userFacade.loading$;


  loginForm = this.fb.group({
    email: ['', [Validators.required, Validators.email]],
    password: ['', Validators.required],
  });

  focus = signal(0);
  focusOut = signal(false);

  constructor(injector: Injector) {
    this.inGameModalService = injector.get(InGameModalService, null);
  }

  ngOnInit() {
    this.titleService.setTitle('Login Dialog');
    this.actions
      .pipe(
        ofType(this.userFacade.getBackendUserSuccess, this.userFacade.createNewBackendUserSuccess),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe(() => {
        this.closeDialog();
      });
  }

  login() {
    const { email, password } = this.loginForm.value;

    if (!this.loginForm.valid || !email || !password) {
      return;
    }
    this.userFacade.login(email, password);
  }

  closeDialog() {
    this.modalRef.close();
  }

  openRegister() {
    // this.authenticationService.register();
    if (this.inGameModalService?.hasModalOpen) {
      const inGameModalConfig: InGameModalConfig = {
        component: RegisterComponent,
        isFullModalHeight: true,
      };

      this.inGameModalService?.updateInGameModal(inGameModalConfig);
    } else {
      // const config = this.modalConfig$.getValue();
      //
      // config.component = RegisterComponent;
      //
      // this.modalService.openNestedModal(config);
      const modalConfig: ModalConfig = {
        component: RegisterComponent,
        useFullscreenModal: this.configFacade.useFullscreenModal(),
      };

      this.modalService.openNestedModal(modalConfig);
    }
  }

  loginWithGoogle() {
    this.userFacade.loginWithGoogle();
  }

  openPasswordResetDialog() {
    if (this.inGameModalService?.hasModalOpen) {
      const inGameModalConfig: InGameModalConfig = {
        component: PasswordResetDialogComponent,
        isFullModalHeight: true,
      };

      this.inGameModalService?.updateInGameModal(inGameModalConfig);
    } else {
      const config = this.modalConfig$.getValue();

      config.component = PasswordResetDialogComponent;

      this.modalService.openNestedModal(config);
    }
  }
}
