import { AbstractControl } from '@angular/forms';

export class UsernameValidators {
  static username(control: AbstractControl) {
    const val = control.value;
    const regex = /^[a-zA-Z0-9_\-.]*$/;
    if (val === null || val === '') return null;
    if (!val.toString().match(regex)) return { invalidUsername: true };
    return null;
  }
}
