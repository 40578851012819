import { inject, Injectable } from '@angular/core';
import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { exhaustMap, from, take } from 'rxjs';
import { BACKEND_TOKEN } from '@kiq/client/util/config';
import { AUTH_SERVICE } from 'authentication';

@Injectable()
export class AuthInterceptorService implements HttpInterceptor {
  private readonly BACKEND_BASE_URL = inject(BACKEND_TOKEN) as string;
  authenticationService = inject(AUTH_SERVICE);

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    return this.authenticationService.token$.pipe(
      take(1),
      exhaustMap((token) => {
        console.log(token);
        if (!token) {
          return next.handle(req);
        } else {
          if (!token || !req.url.includes(this.BACKEND_BASE_URL)) {
            return next.handle(req);
          }
          const modifiedReq = req.clone({
            headers: req.headers.set('Authorization', `Bearer ${token}`),
          });
          return next.handle(modifiedReq);
        }
      }),
    );
  }
}
