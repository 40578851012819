import { TttGrid } from './ttt-grid';
import { GridContent } from './grid-content';
import { PlayerNumber } from '@kiq/shared/enums';
import { GameTttConfig } from './game-ttt-config';
import { GameEndReason } from './game-end-reason';

export type GameTttState = {
  config: GameTttConfig;
  gameEndReason?: GameEndReason;
  gameId: string;
  grid: TttGrid;
  gridContent: GridContent[];
  gameEnded: boolean;
  gameWinner?: PlayerNumber;
  maxGameDurationSeconds?: number;
  gameTimeoutTimestamp?: Date;
  maxTurnDurationSeconds?: number;
  playerAtTurn?: PlayerNumber;
  turnTimeoutTimestamp?: Date;
  numberOfLifesPerPlayer?: Map<PlayerNumber, number>;
  numberOfStealsPerPlayer?: Map<PlayerNumber, number>;
  scoreByPlayer?: Map<PlayerNumber, number>;
  secondPlayerAlreadyJoined: boolean;
  gameStarted: boolean;
  playerOneId: string;
  playerTwoId?: string;
  playerTwoSessionId?: string;
};
