import { Component, inject } from '@angular/core';
import { ModalRef } from '@kiq/shared/types';
import { ConfigFacade } from '@kiq/client/data-access/config';
import { ModalService } from '@kiq/shared/util/modal';

@Component({
  selector: 'kiq-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  private readonly configFacade = inject(ConfigFacade);
  private readonly modalService = inject(ModalService);
  private modalRef: ModalRef<any> | undefined;
  isModalOpen = false;

  // constructor() {
  //   effect(
  //     () => {
  //       if (this.configFacade.isLandscape() && !this.isModalOpen) {
  //         const modalConfig: ModalConfig = {
  //           isMobile: !this.configFacade.isDesktop(),
  //         };
  //         modalConfig.component = LandscapeDialogComponent;
  //         this.modalRef = this.modalService.openNestedModal(modalConfig);
  //         this.isModalOpen = true;
  //       } else {
  //         this.modalRef?.close(true);
  //         this.isModalOpen = false;
  //       }
  //     },
  //     { allowSignalWrites: true },
  //   );
  // }
}
