import { inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BACKEND_TOKEN } from '@kiq/client/util/config';
import { BasicUser } from '@kiq/shared/classes';

@Injectable()
export class OtherUserService {
  private readonly BACKEND_BASE_URL = inject(BACKEND_TOKEN);
  private readonly httpClient = inject(HttpClient);

  private readonly BACKEND_URL = `${this.BACKEND_BASE_URL}/api/v1/user`;

  private getHeader(jwtToken?: string, withoutContentType = false): { headers: HttpHeaders } {
    const headers = {
      headers: new HttpHeaders({}),
    };

    if (jwtToken) {
      headers.headers = headers.headers.append('Authorization', `Bearer ${jwtToken}`);
    }
    if (!withoutContentType) {
      headers.headers = headers.headers.append('Content-Type', 'application/json');
    }
    return headers;
  }

  getOtherUserFromUsername$(userName: string) {
    const httpOptions = this.getHeader();
    const url = this.BACKEND_URL + '/username/' + userName;
    return this.httpClient.get<BasicUser>(url, { ...httpOptions });
  }
}
