<svg [attr.width]="iconSize"
     [attr.height]="iconSize" viewBox="0 0 68 61" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g clip-path="url(#clip0_7742_87227)" filter="url(#filter0_d_7742_87227)">
    <g filter="url(#filter1_d_7742_87227)">
      <path [style.fill]="hexFillColor1 ?? '#707070'" d="M61.0692 15.653L53.297 7.8808C51.1117 5.6955 48.2082 4.39378 45.1412 4.20528C44.8839 4.18942 44.6255 5.10893 44.3662 5.10893H23.6338C23.3745 5.10893 23.1159 4.18942 22.8587 4.20528C19.7917 4.39378 16.8882 5.6955 14.7029 7.8808L6.93066 15.653L10.4416 21.6797L15.3509 24.0733L17.8568 21.5674C18.0581 21.3661 18.4021 21.5088 18.4021 21.7933V53.498C18.4021 53.6745 18.5452 53.8175 18.7215 53.8175H22.8586L24.9232 52.6505L27.3151 53.8175H31.7715L34.2813 52.6505L36.228 53.8175H40.6845L43.112 52.6505L45.141 53.8175H49.278C49.4545 53.8175 49.5975 53.6744 49.5975 53.498V21.7933C49.5975 21.5088 49.9416 21.3661 50.1428 21.5674L52.6487 24.0733L61.0692 15.653Z"/>
    </g>
    <path [style.fill]="hexFillColor2 ?? '#999999'" d="M36.9175 20.6152C33.6718 20.6152 31.0312 23.2558 31.0312 26.5016V32.2147C31.0312 35.4604 33.6718 38.101 36.9175 38.101C40.1632 38.101 42.8038 35.4604 42.8038 32.2147V26.5016C42.8039 23.2558 40.1632 20.6152 36.9175 20.6152ZM39.8864 32.2146C39.8864 33.8516 38.5545 35.1833 36.9175 35.1833C35.2805 35.1833 33.9487 33.8515 33.9487 32.2146V26.5014C33.9487 24.8644 35.2806 23.5327 36.9175 23.5327C38.5545 23.5327 39.8864 24.8645 39.8864 26.5014V32.2146ZM26.655 38.1009C25.8494 38.1009 25.1963 37.4477 25.1963 36.6422V22.074C25.1963 21.2683 25.8494 20.6152 26.655 20.6152C27.4607 20.6152 28.1137 21.2684 28.1137 22.074V36.6422C28.1137 37.4477 27.4606 38.1009 26.655 38.1009Z"/>
    <path [style.fill]="'white'" [style.stroke]="'black'" d="M36.9175 20.1152C33.3956 20.1152 30.5312 22.9797 30.5312 26.5016V32.2147C30.5312 35.7365 33.3956 38.601 36.9175 38.601C40.4394 38.601 43.3038 35.7365 43.3038 32.2147V26.5016C43.3039 22.9797 40.4394 20.1152 36.9175 20.1152ZM39.3864 32.2146C39.3864 33.5754 38.2784 34.6833 36.9175 34.6833C35.5566 34.6833 34.4487 33.5753 34.4487 32.2146V26.5014C34.4487 25.1406 35.5567 24.0327 36.9175 24.0327C38.2784 24.0327 39.3864 25.1407 39.3864 26.5014V32.2146ZM24.6963 36.6422C24.6963 37.7238 25.5732 38.6009 26.655 38.6009C27.7367 38.6009 28.6137 37.7238 28.6137 36.6422V22.074C28.6137 20.9923 27.7368 20.1152 26.655 20.1152C25.5732 20.1152 24.6963 20.9922 24.6963 22.074V36.6422Z" stroke-opacity="0.9"/>
    <path [style.fill]="hexFillColor2 ?? '#999999'" d="M36.1915 16.5973V5.21094C35.483 5.31539 34.7355 5.37124 33.9633 5.37124C33.1911 5.37124 32.4436 5.31539 31.7351 5.21094V16.5974C31.7351 16.673 31.7963 16.7343 31.8719 16.7343H36.0547C36.1303 16.7341 36.1915 16.673 36.1915 16.5973Z"/>
    <path [style.fill]="hexFillColor2 ?? '#999999'" d="M45.0274 17.5699V4.2152C44.77 4.19945 44.5116 4.19141 44.2523 4.19141H42.1003C42.1003 5.12462 41.5337 4.51256 40.5709 5.21773V17.57C40.5709 17.6456 40.6322 17.7069 40.7078 17.7069H44.8906C44.9661 17.7068 45.0274 17.6456 45.0274 17.5699Z"/>
    <path [style.fill]="hexFillColor2 ?? '#999999'" d="M27.3159 17.5699V5.16823C26.3531 4.46305 25.7864 5.12462 25.7864 4.19141H23.6344C23.3751 4.19141 23.1166 4.19945 22.8594 4.2152V17.57C22.8594 17.6456 22.9207 17.7069 22.9962 17.7069H27.179C27.2546 17.7068 27.3159 17.6456 27.3159 17.5699Z"/>
    <path [style.fill]="hexFillColor2 ?? '#999999'" d="M22.8594 41.1447V53.8078H27.3159V41.1447C27.3159 41.0691 27.2546 41.0078 27.179 41.0078H22.9962C22.9207 41.0078 22.8594 41.069 22.8594 41.1447Z"/>
    <path [style.fill]="hexFillColor2 ?? '#999999'" d="M40.5709 41.1447V53.8078H45.0274V41.1447C45.0274 41.0691 44.9661 41.0078 44.8906 41.0078H40.7078C40.6322 41.0078 40.5709 41.069 40.5709 41.1447Z"/>
    <path [style.fill]="hexFillColor2 ?? '#999999'" d="M31.7351 41.1447V53.8078H36.1915V41.1447C36.1915 41.0691 36.1303 41.0078 36.0547 41.0078H31.8719C31.7963 41.0078 31.7351 41.069 31.7351 41.1447Z"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M61.0689 15.6531L53.2966 7.88092C51.6964 6.28071 49.7109 5.15424 47.5583 4.5918C47.9311 4.88825 48.2886 5.20691 48.6287 5.54698L54.9397 11.858C56.8861 13.8044 56.8861 16.9602 54.9397 18.9066L51.5416 22.3047C51.4797 22.3666 51.4149 22.4238 51.3478 22.4764V12.1609C51.3478 11.6775 50.9559 11.2857 50.4725 11.2857C49.9892 11.2857 49.5973 11.6775 49.5973 12.1609V21.7939C49.5973 21.5093 49.9414 21.3667 50.1427 21.568L51.3478 22.7731V22.7726L52.6487 24.0736L58.2363 21.1972L61.0689 15.6531ZM48.5455 22.3048C47.211 20.9703 44.9291 21.9155 44.9291 23.8027L44.9291 41.0182V45.1073C44.9291 45.1271 44.9291 45.1469 44.9289 45.1667C44.9287 45.1802 44.9286 45.1938 44.9284 45.2074C44.8748 48.6829 42.0406 51.4839 38.5523 51.4839H18.4017V53.4984C18.4017 53.6749 18.5448 53.8179 18.7212 53.8179H22.8581V53.8181H27.3146V53.8178L27.3147 53.8179H31.7712L31.7713 53.8178V53.8181H36.2278V53.8179H40.6842L40.6842 53.8182H45.1406V53.8178L45.1408 53.8179H49.2778C49.4543 53.8179 49.5972 53.6748 49.5972 53.4984V22.8779C49.2117 22.7952 48.8449 22.6042 48.5455 22.3048ZM17.5266 11.2857C17.0432 11.2857 16.6514 11.6775 16.6514 12.1609V22.7731L17.8565 21.568C18.0578 21.3667 18.4018 21.5093 18.4018 21.7939V12.1609C18.4018 11.6775 18.0099 11.2857 17.5266 11.2857Z" fill="black" fill-opacity="0.2"/>
    <path fill="#665E66" fill-rule="evenodd" clip-rule="evenodd" d="M42.214 4.18164C42.214 6.55919 38.5364 8.48655 33.9999 8.48655C29.4633 8.48655 25.7857 6.55907 25.7857 4.18164H42.214ZM6.93062 15.6533L5.09354 17.4904C4.96882 17.6152 4.96882 17.8175 5.09354 17.9422L13.0621 25.9108C13.1868 26.0355 13.3891 26.0355 13.5138 25.9108L15.3509 24.0737L6.93062 15.6533ZM61.0691 15.6533L62.9062 17.4903C63.031 17.6151 63.031 17.8174 62.9062 17.9421L54.9377 25.9106C54.813 26.0354 54.6106 26.0354 54.4859 25.9106L52.6488 24.0736L61.0691 15.6533Z"/>
  </g>
  <defs>
    <filter id="filter0_d_7742_87227" x="0" y="-3" width="68" height="68" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
      <feFlood flood-opacity="0" result="BackgroundImageFix"/>
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
      <feOffset dy="2"/>
      <feGaussianBlur stdDeviation="2.5"/>
      <feComposite in2="hardAlpha" operator="out"/>
      <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0"/>
      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_7742_87227"/>
      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_7742_87227" result="shape"/>
    </filter>
    <filter id="filter1_d_7742_87227" x="1.93066" y="1.20508" width="64.1387" height="59.6133" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
      <feFlood flood-opacity="0" result="BackgroundImageFix"/>
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
      <feOffset dy="2"/>
      <feGaussianBlur stdDeviation="2.5"/>
      <feComposite in2="hardAlpha" operator="out"/>
      <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0"/>
      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_7742_87227"/>
      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_7742_87227" result="shape"/>
    </filter>
    <clipPath id="clip0_7742_87227">
      <rect width="58" height="58" fill="white" transform="translate(5)"/>
    </clipPath>
  </defs>
</svg>

