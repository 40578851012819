import { AsyncDuellAnswer, AsyncQuestion } from '@kiq/shared/types';
import { PlayerNumber } from '@kiq/shared/enums';

export type AsyncGameRound = {
  categoryId: string,
  playerAtTurn: PlayerNumber,
  maxRoundDurationSeconds: number,
  roundTimeoutTimestamp?: string,
  currentQuestionIndex?: number,
  currentQuestion?: AsyncQuestion
  currentQuestionTimeoutTimestamp?: Date,
  playerAnswers: Map<PlayerNumber, Array<AsyncDuellAnswer>>
};
