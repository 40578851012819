import { inject, Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { SnackbarService, SnackbarType } from 'shared/util/snackbar';
import { catchError, concatMap, map, of } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { TrophyActions } from './trophy.actions';
import { UserFacade } from '@kiq/client/data-access/user';
import { TrophyService } from '../service/trophy.service';

@Injectable()
export class TrophyEffects {
  private readonly actions = inject(Actions);
  private readonly snackbarService = inject(SnackbarService);
  private readonly trophyService = inject(TrophyService);
  private readonly userFacade = inject(UserFacade);

  getTrophiesList$ = createEffect(() => {
    return this.actions.pipe(
      ofType(TrophyActions.getTrophiesStart),
      concatMap(({ userId }) => {
        if (!userId) {
          this.snackbarService.show({
            message: 'No Trophies for this User found. Please make sure that you are logged in correctly.',
            type: SnackbarType.ERROR,
          });
          return of(TrophyActions.getTrophiesFail());
        }
        return this.trophyService.getTrophies$(userId).pipe(
          map((trophies) => TrophyActions.getTrophiesSuccess({ trophies })),
          catchError((errorRes: HttpErrorResponse) => {
            this.snackbarService.show({
              message: 'Error while getting the user trophies. Please try again later.',
              type: SnackbarType.ERROR,
            });
            return of(TrophyActions.getTrophiesFail());
          }),
        );
      }),
    );
  });

  getTrophyCountForUser$ = createEffect(() => {
    return this.actions.pipe(
      ofType(TrophyActions.getTrophyCountForUser, TrophyActions.getTrophiesSuccess),
      concatLatestFrom(() => this.userFacade.user$),
      concatMap(([trophies , user]) => {
        if (!user) {
          return of(TrophyActions.getTrophyCountForUserFail());
        }

        return this.trophyService.getTrophies$(user.id).pipe(
          map((trophies) => TrophyActions.getTrophyCountForUserSuccess({ trophies })),
          catchError((errorRes: HttpErrorResponse) => {
            this.snackbarService.show({
              message: 'Error while getting the user trophies. Please try again later.',
              type: SnackbarType.ERROR,
            });
            return of(TrophyActions.getTrophyCountForUserFail());
          }),
        );
      }),
    );
  });
}
