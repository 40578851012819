import { AsyncMultiplayerGame, GameTopscorerQuestionOption } from '@kiq/shared/types';
import { AsyncMultiplayerActionType } from '@kiq/shared/enums';
import { FootballPlayer } from '@kiq/shared/interfaces';

export type AsyncAction = {
  currentState: AsyncMultiplayerGame,
  type: AsyncMultiplayerActionType,
  categoryId?: string,
  answerOptionTicTacToe?: FootballPlayer,
  answerOptionTopscorer?: GameTopscorerQuestionOption,
  roundTimeoutExceeded?: boolean
}
