import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  HostBinding,
  inject,
  Injector,
  OnInit,
  signal,
  ViewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { CdkPortalOutlet } from '@angular/cdk/portal';
import { DynamicChildLoaderDirective } from '../../directives/load-child.directive';
import { ModalConfig, ModalRef } from '@kiq/shared/types';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { slideInAndOut } from '@kiq/shared/ui/animations';
import { MODAL_CONFIG, MODAL_DATA, MODAL_REF } from '../../token/modal-data.token';

@Component({
  selector: 'modal-wrapper',
  standalone: true,
  imports: [CommonModule, CdkPortalOutlet, DynamicChildLoaderDirective],
  templateUrl: './modal-wrapper.component.html',
  styleUrl: './modal-wrapper.component.scss',
  animations: [slideInAndOut],
})
export class ModalWrapperComponent implements OnInit, AfterViewInit {
  @HostBinding('@slideInAndOut') get hasAnimation() {
    return this.withAnimation();
  }
  @HostBinding('@.disabled') get hasAnimationDisabled() {
    return this.animationDisabled();
  }

  private animationDisabled = signal(true);
  private withAnimation = signal(false);

  @ViewChild(DynamicChildLoaderDirective, { static: true }) dynamicChildLoader!: DynamicChildLoaderDirective;

  private readonly modalRef: ModalRef<unknown> = inject(MODAL_REF);
  private readonly injector = inject(Injector);
  private readonly cdr = inject(ChangeDetectorRef);
  private readonly destroyRef = inject(DestroyRef);
  modalConfig: ModalConfig | undefined;

  modalConfig$ = inject(MODAL_CONFIG);

  ngOnInit() {
    this.modalConfig$
      .asObservable()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((config) => {
        this.modalConfig = config;

        if (this.modalConfig.useFullscreenModal) {
          this.withAnimation.set(true);
          this.animationDisabled.set(false);
        }

        if (config?.component) {
          this.createComponent(config);
        }
      });
  }

  ngAfterViewInit() {
    // Currently @disable disables the animation for the parent and all its children. But we want to disable
    // only the enter / leave animation of the parent and allow the children to animate.
    if (!this.modalConfig?.useFullscreenModal) {
      this.animationDisabled.set(true);
    }
  }

  close() {
    if (!this.modalConfig?.useFullscreenModal) {
      this.animationDisabled.set(true);
    }

    this.modalRef.close();
  }

  createComponent(config: ModalConfig) {
    if (!config) return;

    this.dynamicChildLoader?.viewContainerRef.clear();

    const injector = Injector.create({
      parent: this.injector,
      providers: [
        {
          provide: MODAL_DATA,
          useValue: config?.data,
        },
      ],
    });
    const componentRef = this.dynamicChildLoader.viewContainerRef.createComponent(config.component!, {
      injector,
    });

    // Pass inputs to the component. Ths is useful if the component requires inputs. You can pass them in the modal config.
    // Inputs are not required for the modal to work. You can also pass data via the data injection token.
    if (config?.inputs) {
      Object.keys(config.inputs).forEach((key) => {
        componentRef.setInput(key, config.inputs![key]);
      });
    }

    this.cdr.detectChanges();
  }
}
