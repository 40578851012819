import { AsyncGameEndReason, PlayerNumber } from '@kiq/shared/enums';
import { AsyncGameRoundCategory } from './async-game-round-category';
import { AsyncGameRound } from './async-game-round';

export type AsyncMultiplayerGame = {
  gameId: string,
  playerIds: Map<PlayerNumber, string>,
  numberOfRounds: number,
  categories: Array<AsyncGameRoundCategory>,
  playerScores: Map<PlayerNumber, number>;
  currentRound?: AsyncGameRound,
  playedRounds: Array<AsyncGameRound>,
  gameEnded: boolean,
  gameEndReason?: AsyncGameEndReason,
  gameWinner?: PlayerNumber
};
