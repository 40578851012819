import { inject, Injectable } from '@angular/core';
import { ConfigFacade } from '@kiq/client/data-access/config';
import { NativeAppType } from '@kiq/shared/enums';

@Injectable({
  providedIn: 'root',
})
export class NativeAppsService {
  private readonly configFacade = inject(ConfigFacade);
  nativeAppType = this.configFacade.nativeAppType;

  openLoginModal() {
    if (this.nativeAppType()) {
      switch (this.nativeAppType()) {
        case NativeAppType.AndroidApp:
          (window as any).Android.openLogin();
          break;
        case NativeAppType.IosApp:
          (window as any).webkit.messageHandlers.authHandler.postMessage('openLogin');
          break;
      }
    }
  }

  openRegisterModal() {
    if (this.nativeAppType()) {
      switch (this.nativeAppType()) {
        case NativeAppType.AndroidApp:
          (window as any).Android.openRegister();
          break;
        case NativeAppType.IosApp:
          (window as any).webkit.messageHandlers.authHandler.postMessage('openRegister');
          break;
      }
    }
  }

  logout() {
    if (this.nativeAppType()) {
      switch (this.nativeAppType()) {
        case NativeAppType.AndroidApp:
          (window as any).Android.logout();
          break;
        case NativeAppType.IosApp:
          (window as any).webkit.messageHandlers.authHandler.postMessage('logout');
          break;
      }
    }
  }

  goToUrl(url: string) {
    if (this.nativeAppType()) {
      switch (this.nativeAppType()) {
        case NativeAppType.AndroidApp:
          (window as any).Android.goToUrl(url);
          break;
        case NativeAppType.IosApp:
          (window as any).webkit.messageHandler.urlHandler.postMessage(url);
          break;
      }
    }
  }
}
