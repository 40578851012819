import { AsyncTicTacToeQuestion, GameTopscorerQuestion } from '@kiq/shared/types';

export type AsyncQuestion = {
  maxAnswerDurationSeconds: number,

  // TTT gameType
  ticTacToe?: AsyncTicTacToeQuestion

  //Topscore gametype
  topscorer?: GameTopscorerQuestion
}
