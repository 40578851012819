import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { clientFeatureAuthRoutes } from './lib.routes';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { ClientDataAccessUserModule } from '@kiq/client/data-access/user';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedUiIconModule } from 'shared/ui/icon';
import { VerifyComponent } from './verify/verify.component';
import { DialogModule } from '@angular/cdk/dialog';
import { PasswordResetDialogComponent } from './password-reset-dialog/password-reset-dialog.component';
import { AuthModule } from '@angular/fire/auth';
import { LayoutModule } from '@angular/cdk/layout';
import { SharedUiButtonModule } from '@kiq/shared/ui/button';
import { CheckboxComponent, SharedUiInputsModule } from '@kiq/shared/ui/inputs';

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(clientFeatureAuthRoutes),
    ClientDataAccessUserModule,
    ReactiveFormsModule,
    SharedUiButtonModule,
    SharedUiIconModule,
    SharedUiInputsModule,
    FormsModule,
    DialogModule,
    AuthModule,
    LayoutModule,
    CheckboxComponent,
  ],
  declarations: [LoginComponent, RegisterComponent, VerifyComponent, PasswordResetDialogComponent],

  exports: [LoginComponent, RegisterComponent],
})
export class ClientFeatureAuthModule {}
